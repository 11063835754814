var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", { staticClass: "form-section-title first" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.string.organizerSettings._eventSettingsHeader) +
            "\n    "
        ),
        _c("small", [
          _vm._v(_vm._s(_vm.string.organizerSettings._eventSettingsSubtitle)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "smallseparator" }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "loader-section",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: { "loading-cover-msg": _vm.loadingMessage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "add-new-identity",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [_c("TeamCustomFieldsManager")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }