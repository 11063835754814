var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "visibility-section" }, [
    !_vm.isActive
      ? _c("div", [
          _c(
            "a",
            {
              staticClass: "btn visibility-button",
              on: { click: _vm.toggleVisibilityTrue },
            },
            [_c("h3", [_vm._v(_vm._s(_vm.string.visibilityManager._publish))])]
          ),
        ])
      : _c("div", [
          _c(
            "a",
            {
              staticClass: "btn visibility-button",
              on: { click: _vm.toggleVisibilityFalse },
            },
            [_c("h3", [_vm._v(_vm._s(_vm.string.visibilityManager._hidden))])]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }