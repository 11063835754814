<template>
  <div></div>
</template>

<script>
import routes from '../router';

export default {
    props: {
        href: String,
        startRedirect: Boolean
    },
    mounted (){
        //console.log(this.startRedirect);
        if (this.startRedirect){
            this.go(this.href);
        }
    },
    methods: {
        go (event) {
            this.notify();
            this.$root.currentRoute = this.href;
            window.history.pushState(
                null,
                routes[this.href],
                this.href
            );
        },
        notify: function () {
            this.$emit('vlinkclickevent', true);
        }
    }
};
</script>
