var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c("div", { staticClass: "privacyPolicy" }, [
      !_vm.newPolicy
        ? _c("table", { staticClass: "table table-striped" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", [_vm._v("Title")]),
                _vm._v(" "),
                _c("th", [_vm._v("Description")]),
                _vm._v(" "),
                _c("th", [_vm._v("Mandatory")]),
                _vm._v(" "),
                _c("th", [_vm._v("Actions")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.policies, function (policy) {
                return _c("tr", { key: policy.id }, [
                  _c("td", [_vm._v(_vm._s(policy.name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(policy.title))]),
                  _vm._v(" "),
                  _c("td", { domProps: { innerHTML: _vm._s(policy.txt) } }),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(policy.mandatory == 1 ? "Yes" : "No")),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-center" }, [
                    !policy.privacypolicy_id
                      ? _c("span", {
                          staticClass: "actionIcon glyphicon glyphicon-edit",
                          on: {
                            click: function ($event) {
                              return _vm.editPrivacyPolicy(policy)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !policy.privacypolicy_id
                      ? _c("span", {
                          staticClass:
                            "actionIcon glyphicon glyphicon-remove-sign",
                          on: {
                            click: function ($event) {
                              return _vm.deletePrivacyPolicy(policy.id)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "actionIcon glyphicon glyphicon-list",
                      on: {
                        click: function ($event) {
                          return _vm.downloadPrivacyPolicyProspects(policy.id)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.newPolicy
        ? _c("div", [
            _c("table", { staticClass: "table newPolicy" }, [
              _c("tr", [
                _c("th", [_vm._v("Policy Name:")]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.newPolicy.name,
                        expression: "newPolicy.name",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.newPolicy.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newPolicy,
                          "name",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Policy Title:")]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.newPolicy.title,
                        expression: "newPolicy.title",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text" },
                    domProps: { value: _vm.newPolicy.title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newPolicy,
                          "title",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Policy Content:")]),
                _vm._v(" "),
                _c("td"),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Mandatory:")]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    {
                      staticClass: "squaredThree",
                      staticStyle: { display: "inline" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newPolicy.mandatory,
                            expression: "newPolicy.mandatory",
                          },
                        ],
                        staticStyle: { width: "19px" },
                        attrs: { id: "one", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.newPolicy.mandatory)
                            ? _vm._i(_vm.newPolicy.mandatory, null) > -1
                            : _vm.newPolicy.mandatory,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.newPolicy.mandatory,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.newPolicy,
                                    "mandatory",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.newPolicy,
                                    "mandatory",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.newPolicy, "mandatory", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "one" } }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: { click: _vm.submit },
                    },
                    [_vm._v("\n              Save\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          _vm.newPolicy = false
                        },
                      },
                    },
                    [_vm._v("\n              Cancel\n            ")]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.newPolicy,
              expression: "!newPolicy",
            },
          ],
          staticClass: "btn btn-success",
          staticStyle: { "margin-bottom": "50px" },
          on: {
            click: function ($event) {
              return _vm.downloadAllProspects()
            },
          },
        },
        [
          _vm._v("\n      Download Full List "),
          _c("span", {
            staticClass: "actionIcon glyphicon glyphicon-download-alt",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.newPolicy,
              expression: "!newPolicy",
            },
          ],
          staticClass: "btn btn-primary",
          staticStyle: { "margin-bottom": "50px" },
          on: {
            click: function ($event) {
              _vm.newPolicy = []
            },
          },
        },
        [_vm._v("\n      Add\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }