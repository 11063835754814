<template xmlns:v-model="http://www.w3.org/1999/xhtml">
  <div class="guestlist-component">
    <transition name="component-fade-fast" mode="out-in" appear>
      <LoadingCover
        v-if="loadingEffectshow"
        :loading-cover-msg="string.listUpload._loadingMessage"
      />
    </transition>
    <h4 class="form-section-title second">
      {{ string.guestlist._title }}
      <small class="textSmall">
        {{ string.guestlist._subtitle }}
      </small>
    </h4>
    <div class="smallseparator"></div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-6">
        <a class="btn import-man" :title="string.guestlist._add" @click="toggleInsertSingle">
          <i class="fa fa-plus" aria-hidden="true" />
          {{ string.guestlist._add }}
        </a>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-6">
        <a class="btn upload" :title="string.guestlist._upload" @click="toggleUploadList">
          <i class="fa fa-upload" aria-hidden="true" />
          {{ string.guestlist._upload }}
        </a>
      </div>
    </div>

    <!-- Tab Content -->
    <div class="row tabs-content">
      <transition name="component-fade-fast" mode="out-in">
        <div v-if="tabValue === 'uploadlist'" key="uploadlist">
          <ListUpload
            :event-id="event.id"
            event-type="private"
            :father-name="componentName"
            @component_event="listUploadItemEvent"
          />
        </div>
        <div v-if="tabValue === 'insertsingle'" key="insertsingle">
          <ListSingleItem
            :event-id="event.id"
            :father-name="componentName"
            @component_event="listSingleItemEvent"
          />
        </div>
      </transition>
    </div>

    <div class="datatable-container">
      <!-- START NO DATA -->
      <div
        v-if="!gridData.length > 0 && tabValue !== 'uploadlist' && tabValue !== 'insertsingle'"
        class="datatable no-data"
      >
        <div class="row">
          <div v-if="uploadStatus === 'NULL'" class="col-sm-12 col-md-12 col-lg-12">
            {{ string.guestlist._nodata }}
          </div>
          <!-- <div v-else class="col-sm-12 col-md-12 col-lg-12">{{ string.guestlist._loadInProgress }}</div> -->
          <h4 v-if="uploadStatus !== 'NULL'" class="form-section-title second progress-title">
            {{ uploadStatusMessage }} <loading-icon v-if="uploadStatus !== 'PENDING'" />
            <small />
          </h4>
          <el-progress
            v-if="uploadStatus !== 'NULL'"
            class="col-sm-12 col-md-12 col-lg-12 progress-container"
            :stroke-width="24"
            :percentage="uploadProgress"
            :show-text="false"
            :color="'#f7a30c'"
            :format="formatProgress"
          />
        </div>
      </div>
      <!-- END NO DATA -->

      <div v-else class="datatable data">
        <div class="row guestList_links" style="margin: 0;">
          <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0;">
            <h4 v-if="uploadStatus !== 'NULL'" class="form-section-title second progress-title">
              {{ uploadStatusMessage }}<loading-icon v-if="uploadStatus === 'PENDING'" />
              <small />
            </h4>
            <el-progress
              v-if="uploadStatus !== 'NULL'"
              class="col-sm-12 col-md-12 col-lg-12 progress-container"
              :stroke-width="24"
              :percentage="uploadProgress"
              :show-text="false"
              :color="'#f7a30c'"
              :format="formatProgress"
            />

            <h4 class="form-section-title second">
              {{ string.guestlist._list }}
            </h4>
            <div class="smallseparator"></div>
            <div class="downloads_style">
              <i class="fa fa-users" aria-hidden="true" style="margin-right:15px;" />
              {{ string.guestlist._guestnumber
              }}<strong>{{ gridData ? gridData.length : 0 }}</strong>
            </div>

            <div class="downloads_style">
              <i class="fa fa-cloud-download" aria-hidden="true" style="margin-right:15px;" />
              <vue-excel-xlsx
                class="linkStyle"
                :data="reportData"
                :columns="reportColumns"
                filename="guest_list_report"
                sheetname="Sheet 1"
              >
                {{ string.guestlist._downloadlistAll }}
              </vue-excel-xlsx>
            </div>

            <div class="downloads_style">
              <i class="fa fa-cloud-download" aria-hidden="true" style="margin-right:15px;" />
              <vue-excel-xlsx
                class="linkStyle"
                :data="
                  this.reportData.filter(row => {
                    if (row.status.toLowerCase().includes('check')) {
                      return row;
                    }
                  })
                "
                :columns="reportColumns"
                filename="guest_list_report"
                sheetname="Sheet 1"
              >
                {{ string.guestlist._downloadlist }}
              </vue-excel-xlsx>
            </div>
            <div class="downloads_style">
              <div class="guest-delete-all">
                <i class="fa fa-times" aria-hidden="true" style="margin-right:20px;" />
                <a class="simple" @click="deleteListAllFunction">
                  {{ string.guestlist._removelist }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="refresh-table refresh-stats-button">
          <a class="simple" @click="refreshTable">
            <i aria-hidden="true" class="fa fa-refresh" />
            {{ string.guestlist._refresh_table }} {{ hasChanges ? " *" : " " }}
          </a>
        </div> -->
        <!-- <el-button class="refresh-table" v-on:click="refreshTable"
          >{{ string.guestlist._refresh_table }} {{ hasChanges ? " *" : " " }}</el-button
        > -->

        <div class="guest-partners-max">
          <label class="el-form-item_label"> {{ string.guestlist._attendeePartnersMax }}: </label>
          <NumericFieldInput
            :initial-value="this.event.maxGuestsPerMember"
            @change="setPartnerEventMax"
          />
        </div>
        <div class="table">
          <div class="filter-field">
            <input
              v-model="searchKey"
              aria-label
              type="text"
              :placeholder="searchPlaceholder"
              class="datagridsSearchBar"
            />
          </div>
          <DataGridInt
            :event="event"
            :data="paginatedData"
            :columns="gridColumns"
            :filter-key="gridFilterKey"
            :is-private="isPrivate"
            @updateGuest="onBeforeUpdateGuest"
            @deleteListItem="deleteListItemFunction"
          />

          <Paginate
            :page-count="pageCount"
            :click-handler="changeDaPage"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
          ></Paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<style scoped>
.el-progress__text {
  color: #0b0b0b !important;
}
.progress-title {
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.progress-container {
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}
label.el-form-item_label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 20px;
  color: #fff;
  line-height: 1;
  padding: 11px 12px 11px 0;
  box-sizing: border-box;
}

.guest-partners-max {
  text-align: right;
  position: absolute;
  right: 26px;
  margin-top: 6px;
}
.refresh-table {
  text-align: left;
  position: absolute;
  left: 26px;
  margin-top: 6px;
  /* background-color: rgba(255, 255, 255, 0.3) !important; */
  /* color: white !important; */
}

.data-grid-container tbody {
  font-size: 15px !important;
}

.row.tabs-content h4 {
  text-align: center;
}

.list-upload a {
  text-align: center !important;
}

.table {
  margin-top: 50px;
  font-size: 14px;
}

a.simple {
  cursor: pointer;
  color: #333 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.simple:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}

.data {
  font-size: 20px;
}

.no-data {
  padding: 0 30px 30px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  color: #e8e8e8;
}

a.btn {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  margin-bottom: 30px;
  font-family: inherit;
  font-weight: 400;
}

.datatable-container {
  margin-top: 30px;
}

.btn:hover {
  background-color: #dadada !important;
  color: #333;
  cursor: pointer;
  border: 0 !important;
}

.guestlist-component {
  margin-top: 50px;
}

.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #f7a30c;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
small.textSmall {
  font-size: 12px;
}
.downloads_style {
  padding-left: 15px;
}
.downloads_style > i {
  padding-right: 15px;
}
.linkStyle {
  display: inline;
  cursor: pointer;
}
button.linkStyle {
  background: transparent !important;
  border: transparent !important;
  cursor: pointer;
  padding: 1px 0;
}
.row.guestList_links > div > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.row.guestList_links > button > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.downloads_style > div > a:hover {
  color: #f7a30c !important;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import ListSingleItem from "../components/ListSingleItem.vue";
import ListUpload from "../components/ListUpload.vue";
import DataGridInt from "../components/DataGridInt.vue";
import NumericFieldInput from "../components/NumericFieldInput.vue";
import LoadingIcon from "../components/LoadingIcon.vue";
import Paginate from "vuejs-paginate";
import {
  DELETE_ONE_GUEST,
  DELETE_ALL,
  UPDATE_EVENT,
  UPDATE_GUEST_LIST_MAX_ACQUAINTANCE,
  UPDATE_MEMBER_BY_ID,
  UPDATE_GUEST_BY_ID
} from "@/graphql";
export default {
  components: {
    ListSingleItem,
    ListUpload,
    DataGridInt,
    LoadingCover,
    NumericFieldInput,
    Paginate,
    LoadingIcon
  },
  props: {
    eventId: String,
    isPrivate: Boolean,
    event: Object,
    data: {
      type: Array,
      required: false,
      default: []
    },
    size: {
      type: Number,
      required: false,
      default: 100
    },
    uploadStatus: {
      type: String,
      required: false,
      default: "NULL"
    },
    uploadStatusMessage: {
      type: String,
      required: false,
      default: ""
    },
    uploadProgress: {
      type: Number,
      required: false,
      default: 0
    },
    hasChanges: {
      type: Boolean,
      required: false,
      default: false
    },
    confirmationPlusOne: Number
  },
  data() {
    return {
      componentName: "guest-list",
      string: {},
      // DataGrid
      gridFilterKey: "",
      gridColumns: [
        { key: "firstname", label: "firstname" },
        { key: "surname", label: "surname" },
        { key: "email", label: "Primary Email" },
        { key: "listEmails", label: "Emails" },
        { key: "telephone", label: "tel" },
        { key: "acquaintance", label: "+1" },
        { key: "deliveryStatus", label: "campaign" },
        { key: "status", label: "status" },
        { key: "virtualCheckInAt", label: "Virtual CheckIn" },
        {
          key: "isRegistered",
          label: "App"
        }
      ],
      gridData: [],
      reportData: [],
      gridDataCheckin: [],

      tabVisible: false,
      tabValue: "",

      loadingProgress: false,
      loadingEffectshow: false,

      partnersMax: 0,
      pageNumber: 0,
      searchPlaceholder: "",
      searchKey: "",
      searchResultsPageNumber: 0,
      pageCount: 0,
      // Guest list upload tracking
      reportColumns: [
        {
          label: "Event",
          field: "eventId",
          dataFormat: value => {
            return this.event.name;
          }
        },
        {
          label: "Name",
          field: "member",
          dataFormat: value => {
            return value["name"];
          }
        },
        {
          label: "Surname",
          field: "member",
          dataFormat: value => {
            return value["surname"];
          }
        },
        {
          label: "Email",
          field: "member",
          dataFormat: value => {
            return value["email"].includes("internal.penguinpass.it") ? "" : value["email"];
          }
        },
        {
          label: "Alternative Emails",
          field: "member",
          dataFormat: value => {
            let output = "";
            const emailList = value["listEmails"];
            for (const [index, email] of emailList.entries()) {
              if (index !== emailList.length - 1) {
                if (email && email.trim() !== "") {
                  output += email + ", ";
                }
              } else {
                if (email && email.trim() !== "") {
                  output += email;
                } else {
                  // Remove previous comma because last one failed
                  output = output.slice(0, output.length - 2);
                }
              }
            }
            return output;
          }
        },
        {
          label: "Tel",
          field: "member",
          dataFormat: value => {
            return value["telephone"];
          }
        },
        {
          label: "Actual +1",
          field: "acquaintance"
        },
        {
          label: "Forecasted +1",
          field: "maxAcquaintance"
        },
        {
          label: "+1 Info",
          field: "acquaintanceInfoList",
          dataFormat: values => {
            let result = "";
            for (const value of values) {
              result += `${value.name ? value.name : ""}, ${value.surname ? value.surname : ""}, ${
                value.email ? value.email : ""
              }; `;
            }
            return result;
          }
        },
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Campaign Status",
          field: "deliveryStatus",
          dataFormat: value => {
            return value && value !== "DRAFT" ? value : "-";
          }
        },
        {
          label: "Rsvp Date",
          field: "rsvpDate",
          dataFormat: value => {
            return value ? new Date(value).format() : "";
          }
        },
        {
          label: "Checkin Date",
          field: "activity",
          dataFormat: value => {
            if (value && value.length) {
              for (const activity of value) {
                if (activity.activity === "CHECK_IN") {
                  return new Date(activity.createdAt).format();
                }
              }
            }
            return "";
          }
        },
        {
          label: "Is Registered",
          field: "member",
          dataFormat: value => {
            return value["isRegistered"] ? "yes" : "no";
          }
        },
        {
          label: "Added From Check-in App",
          field: "fromCheckinApp",
          dataFormat: value => {
            return value ? "yes" : "no";
          }
        }
      ],
      customFieldObject: {}
    };
  },
  computed: {
    // pageCount() {
    //     const l = this.gridData.length;
    //     const s = this.size;
    //     return Math.ceil(l / s);
    // },
    paginatedData() {
      const searchKey = this.searchKey && this.searchKey.trim().toLowerCase();
      let tempData = this.gridData;
      if (searchKey && searchKey.length >= 3) {
        tempData = tempData.filter(row => {
          let tobeSearched = "";
          if (row.member) {
            tobeSearched += row.member.name ? row.member.name.toLowerCase() : "";
            tobeSearched += row.member.surname ? row.member.surname.toLowerCase() : "";
            tobeSearched += row.member.email ? row.member.email.toLowerCase() : "";
          }
          tobeSearched += row.status ? row.status.toLowerCase() : "";
          tobeSearched += row.deliveryStatus ? row.deliveryStatus.toLowerCase() : "";
          if (tobeSearched.includes(searchKey)) {
            return row;
          }
        });
        this.pageCount = Math.ceil(tempData.length / this.size);
        const start = this.searchResultsPageNumber * this.size;
        const end = start + this.size;
        return tempData.slice(start, end);
      }
      this.pageCount = Math.ceil(this.gridData.length / this.size);
      this.searchResultsPageNumber = 0;
      const start = this.pageNumber * this.size;
      const end = start + this.size;
      return this.gridData.slice(start, end);
    }
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        this.gridData = newVal || [];
        this.gridDataCheckin = this.gridData.filter(row => {
          if (row.status.toLowerCase().includes("check-in")) {
            return row;
          }
        });
        this.pageCount = Math.ceil(this.gridData.length / this.size);

        this.addToReportColumns();
        this.addToReportData();
        this.sendNewListToCampaign(this.gridData);
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.partnersMax = this.event.maxGuestsPerMember;
    const context = this;
    this.string = this.$root.strings;
    this.searchPlaceholder = this.string.datagrid._search;

    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
      context.searchPlaceholder = context.string.datagrid._search;
    });
  },
  methods: {
    toggleInsertSingle: function() {
      this.tabVisible = true;
      this.tabValue = "insertsingle";
    },
    toggleUploadList: function() {
      this.tabVisible = true;
      this.tabValue = "uploadlist";
    },
    sendNewListToCampaign: function(p) {
      /** Trigger update for email marketing guest list */
      this.$root.bus.emit("guestsLoaded", { guests: p });
    },
    refreshTable: function(p) {
      this.$emit("triggerRefreshTable", {});
    },
    apiDeleteItem: function(guestId) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: DELETE_ONE_GUEST,
          variables: {
            eventId: this.eventId,
            memberListId: guestId
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.unsetItemFromGridData(guestId);
            context.$emit("guestDataChanged", {});
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.guestlist._itemCancellato);
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", error);
          context.loadingEffectshow = false;
        });
    },
    setPartnerEventMax: function(maxAcquaintance) {
      const context = this;
      context.loadingEffectshow = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: context.eventId,
            updateEventInput: {
              maxGuestsPerMember: maxAcquaintance
            }
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            if (value.data && value.data.updateEvent) {
              context.$apollo
                .mutate({
                  mutation: UPDATE_GUEST_LIST_MAX_ACQUAINTANCE,
                  variables: {
                    eventId: context.eventId,
                    maxAcquaintance: maxAcquaintance
                  }
                })
                .then(value => {
                  console.log(value);
                  if (value.errors) {
                    console.log("error " + value.errors);
                    context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
                  } else {
                    if (value.data.setGuestListMaxAcquaintance === "SUCCESS") {
                      context.loadingEffectshow = false;
                      context.$emit("guestDataChanged", { refreshData: true });
                      context.$root.bus.emit(
                        "simpleToast",
                        context.string.eventPad._successmessage
                      );
                    }
                  }
                });
            }
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
    apiDeleteAll: function() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: DELETE_ALL,
          variables: {
            eventId: this.eventId
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.guestlist._itemCancellato);
            context.$emit("guestListDeleted", {});
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", error);
        });
    },
    listUploadItemEvent: function(e) {
      /** List is uploaded successfully, close the tab and wait for progress of processing guest list*/
      this.tabVisible = false;
      this.tabValue = "";
    },
    listSingleItemEvent: function(guestAdded) {
      this.tabVisible = false;
      this.tabValue = "";
    },
    deleteListItemFunction: function(data) {
      if (confirm(this.string.guestlist._sureForSingleItem)) {
        this.apiDeleteItem(data.id);
      }
    },
    deleteListAllFunction: function() {
      if (confirm(this.string.guestlist._sure)) {
        this.apiDeleteAll();
      }
    },
    onBeforeUpdateGuest: function(item) {
      if (
        item.controlLimit &&
        this.event.maxGuests > 0 &&
        this.confirmationPlusOne >= this.event.maxGuests
      ) {
        return this.$root.bus.emit("simpleToast", this.string.guestlist._maxAttendeeExceeded);
      }
      return this.onUpdateGuest(item);
    },
    unsetItemFromGridData: function(guestId) {
      for (let i = 0; i < this.gridData.length; i++) {
        if (this.gridData[i].id === guestId) {
          this.gridData = this.gridData.splice(i, 1);
          // console.log(i);
          break;
        }
      }
    },
    onUpdateGuest: function(item) {
      const context = this;
      context.loadingEffectshow = true;

      return this.$apollo
        .mutate({
          mutation: UPDATE_MEMBER_BY_ID,
          variables: {
            memberId: item.memberId,
            memberInput: item.memberUpdates
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
          } else {
            context.$apollo
              .mutate({
                mutation: UPDATE_GUEST_BY_ID,
                variables: {
                  guestId: item.guestId,
                  eventId: context.eventId,
                  guestInput: item.guestUpdates
                }
              })
              .then(value => {
                if (value.errors) {
                  console.log("error " + value.errors);
                } else {
                  context.loadingEffectshow = false;
                  context.$root.bus.emit("simpleToast", context.string.guestlist._guestupdated);
                  context.$emit("guestDataChanged", {});
                }
              });
          }
        })
        .catch(error => {
          context.loadingEffectshow = false;
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].message === "MEMBER_EXISTS"
          ) {
            context.$root.bus.emit(
              "simpleToast",
              this.string.listSingleItem._emailAlreadyPresentError
            );
          }
        });
    },
    changeDaPage(e) {
      if (this.searchKey && this.searchKey.length >= 3) {
        this.searchResultsPageNumber = e - 1;
      } else {
        this.pageNumber = e - 1;
      }
    },
    formatProgress(percentage) {
      return `${percentage}%` + (this.uploadStatusMessage ? " " + this.uploadStatusMessage : " ");
    },
    // Custom Fields Management
    addToReportColumns: function() {
      const tempColumn = this.gridColumns;
      this.customFieldObject = this.event.customFieldMap;
      for (const [slug, cField] of Object.entries(this.customFieldObject)) {
        let notExist = true;
        this.reportColumns.map(col => {
          if (col.field === slug) {
            notExist = false;
          }
        });
        if (notExist) {
          this.reportColumns.push({ field: slug, label: cField.label });
          tempColumn.push({ key: slug, label: cField.label });
        }
      }
      this.gridColumns = tempColumn;
    },
    addToReportData: function() {
      this.reportData = this.gridData;
      if (this.customFieldObject && Object.keys(this.customFieldObject).length) {
        this.reportData.map(guest => {
          for (const [slug, cField] of Object.entries(this.customFieldObject)) {
            guest[slug] = guest.customFieldDataMap[slug] ? guest.customFieldDataMap[slug] : "";
          }
        });
      }
    }
  }
};
</script>
