<template>
  <div class="use-existing-component">
    <div class="policies-list">
      <div
        v-for="policy in policies"
        v-if="policies.length > 0"
        class="policy">
        <div class="policy-recap">
          <div class="field policy-name">
            <strong> {{ string.useExistingPolicy._name }}</strong>
            <strong>{{ policy.name }}</strong>
          </div>
          <div class="field policy-mandatory">
              <strong v-if="(policyEventsByPolicyId[policy.id]) ? policyEventsByPolicyId[policy.id].mandatory : false">
                  {{ string.useExistingPolicy._labelMandatory }}
              </strong>
              <strong v-else>
                  {{ string.useExistingPolicy._labelNotMandatory }}
              </strong>
          </div>
          <div class="field policy-edit">
            <div
              class="policy-edit-action"
              @click="archivePolicy(policy.id)">
              <i
                class="fa fa-trash"
                aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="policy-action">
          <div class="field actions ">
            <div class="single-action">
              <label class="control control-checkbox">
                {{ string.useExistingPolicy._labelActive }}
                <input
                  :checked="(policyEventsByPolicyId[policy.id]) ? policyEventsByPolicyId[policy.id].active : false"
                  type="checkbox"
                  @change="toggleActive(policy.id, (typeof policyEventsByPolicyId[policy.id] !== 'undefined') ? !policyEventsByPolicyId[policy.id].active: true)" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div
              v-if="policyEventsByPolicyId[policy.id] && policyEventsByPolicyId[policy.id].active"
              class="single-action">
              <label class="control control-checkbox">
                {{ string.useExistingPolicy._labelMandatory }}
                <input
                  :checked="(policyEventsByPolicyId[policy.id])?policyEventsByPolicyId[policy.id].mandatory:false"
                  type="checkbox"
                  @change="toggleMandatory(policy.id, (typeof policyEventsByPolicyId[policy.id] !== 'undefined')?!policyEventsByPolicyId[policy.id].mandatory:true)" />
                <div class="control_indicator"></div>
              </label>
            </div>
            <div
              v-if="policyEventsByPolicyId[policy.id] && policyEventsByPolicyId[policy.id].active"
              class="single-action">
              <label class="control control-checkbox">
                {{ string.useExistingPolicy._labelOnlyThisEvent }}
                <input
                  :checked="(policyEventsByPolicyId[policy.id])?policyEventsByPolicyId[policy.id].limited:false"
                  type="checkbox"
                  @change="toggleLimitedToEvent(policy.id, (typeof policyEventsByPolicyId[policy.id] !== 'undefined')?!policyEventsByPolicyId[policy.id].limited:true)" />
                <div class="control_indicator"></div>
              </label>
            </div>
          </div>
          <div
            class="field text privacy-content"
            v-html="policy.text"></div>
        </div>
      </div>
      <div
        v-else
        class="no-policies">
        {{ string.useExistingPolicy._noPolicies }}
      </div>
      <!--<div class="policy-pagination">-->
      <!--<paginate-->
      <!--:page-count="pageCount"-->
      <!--:click-handler="changeDaPage"-->
      <!--:prev-text="'Prev'"-->
      <!--:next-text="'Next'"-->
      <!--:container-class="'pagination'">-->
      <!--</paginate>-->
      <!--</div>-->
      <el-row class="policy-result-download">
        <el-col :span="18" :offset="3">
        <vue-excel-xlsx
          v-if="this.acceptedPolicies !== null"
          class="save"
          style="width: 100%;"
          :data="this.acceptedPolicies.data"
          :columns="this.acceptedPolicies.columns"
          filename="guest_list_report"
          sheetname="Sheet 1">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> {{ downloadPolicyLabel }}
        </vue-excel-xlsx>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>

import userdata from './../../../../userdata.js';
import Paginate from 'vuejs-paginate';
import {
  GET_TEAM_PRIVACY_POLICY,
  DELETE_TEAM_PRIVACY_POLICY,
  CREATE_EVENT_PRIVACY_POLICY,
  UPDATE_EVENT_PRIVACY_POLICY,
  GET_EVENT_PRIVACY_POLICY,
  GET_EVENT_CHECKED_PRIVACY_POLICY
} from "@/graphql";

export default {
    name: 'UseExistingPolicy',
    components : {
        Paginate
    },
    props: {
        event: Object,
    },
    data (){
        return {
            inputValue : false,
            policies : [],
            policyEventsByPolicyId: {},
            downloadPolicyLabel : 'Download accepted policies',
            pageCount : 10,
            string : {},
            acceptedPolicies: null
        };
    },
    watch : {
        event : function () {
          if (this.event.id){
            this.loadPolicies();
            this.loadEventPolicies();
            this.downloadCheckedPrivacyPolicies();
          }
        }
    },
    created () {
        const context = this;

        this.string = this.$root.strings;

        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    mounted () {
      this.loadPolicies();
      if (this.event.id){
        this.loadEventPolicies();
        this.downloadCheckedPrivacyPolicies();
      }
    },
    methods : {
      archivePolicy : function (value){
        const context = this;

        const policy = {
            'id' : value
        };

        if (confirm(context.string.urlPrivacyPolicy._sure)) {
          this.$apollo.mutate({
            mutation: DELETE_TEAM_PRIVACY_POLICY,
            variables: {
              teamPrivacyPolicyId: policy.id
            }
          }).then(response => {
            if (response.errors){
              console.log(response.errors);
              return;
            }
            if(response.data) {
              context.policyEventsByPolicyId[policy.id] = response.data.deleteTeamPrivacyPolicy;
              context.loadPolicies();
              context.loadEventPolicies();
              context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);
            }
          }).catch((error) => {
            context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
          });
        }



      },
      toggleActive : function (id, value){
        const context = this;

        const policy = {
            'id' : id,
            'active' : value,
        };

        if(typeof context.policyEventsByPolicyId[policy.id] !== 'undefined') { //already exist so update
          this.$apollo.mutate({
            mutation: UPDATE_EVENT_PRIVACY_POLICY,
            variables: {
              updateEventPrivacyPolicyInput: {
                policyEventId: context.policyEventsByPolicyId[policy.id].id,
                active: policy.active
              }
            }
          }).then(response => {
            if (response.errors){
              console.log(response.errors);
              return;
            }
            if(response.data && response.data.updateEventPrivacyPolicy) {
              context.policyEventsByPolicyId[policy.id] = response.data.updateEventPrivacyPolicy;
                context.loadPolicies();
                context.loadEventPolicies();
              context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);
            }
          }).catch((error) => {
            context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
          });
        } else { //doesn't exist so create
          this.$apollo.mutate({
            mutation: CREATE_EVENT_PRIVACY_POLICY,
            variables: {
              createEventPrivacyPolicyInput: {
                eventId: this.event.id,
                teamPrivacyPolicyId: policy.id,
                active: policy.active,
                mandatory: false,
                limited: false
              }
            }
          }).then(response => {
            if (response.errors){
              console.log(response.errors);
              return;
            }
            if(response.data && response.data.createEventPrivacyPolicy) {
              context.policyEventsByPolicyId[policy.id] = response.data.createEventPrivacyPolicy;
              context.loadPolicies();
            }
          }).catch((error) => {
            context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
          });
        }
      },
      toggleMandatory : function (id, value){
        const context = this;

        const policy = {
          'id' : id,
          'mandatory' : value,
        };

        this.$apollo.mutate({
          mutation: UPDATE_EVENT_PRIVACY_POLICY,
          variables: {
            updateEventPrivacyPolicyInput: {
              policyEventId: context.policyEventsByPolicyId[policy.id].id,
              mandatory: policy.mandatory
            }
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response.data && response.data.updateEventPrivacyPolicy) {
              context.policyEventsByPolicyId[policy.id] = response.data.updateEventPrivacyPolicy;
              context.loadPolicies();
              context.loadEventPolicies();
              context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);
          }
        }).catch((error) => {
          context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
        });
      },
      toggleLimitedToEvent : function (id, value){
        const context = this;

        const policy = {
          'id' : id,
          'limited_to_event' : value,
        };

        this.$apollo.mutate({
          mutation: UPDATE_EVENT_PRIVACY_POLICY,
          variables: {
            updateEventPrivacyPolicyInput: {
              policyEventId: context.policyEventsByPolicyId[policy.id].id,
              limited: policy.limited_to_event
            }
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response.data && response.data.updateEventPrivacyPolicy) {
              context.policyEventsByPolicyId[policy.id] = response.data.updateEventPrivacyPolicy;
              context.loadPolicies();
              context.loadEventPolicies();
              context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);
          }
        }).catch((error) => {
          context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
        });
      },
      loadPolicies: function () {
        const context = this;
        this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_TEAM_PRIVACY_POLICY
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response.data && response.data.teamPrivacyPolicies) {
            context.policies = response.data.teamPrivacyPolicies
          }
        }).catch((error) => {
          context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
        });
      },
      loadEventPolicies: function () {
        const context = this;
        this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_EVENT_PRIVACY_POLICY,
          variables: {
            eventId: this.event.id
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response.data && response.data.policyEvents) {
            context.policyEventsByPolicyId = {};

            for(let policy of response.data.policyEvents) {
              context.policyEventsByPolicyId[policy.teamPrivacyPolicyId] = policy
            }
          }
        }).catch((error) => {
          context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
        });
      },
      downloadCheckedPrivacyPolicies: function () {
        const context = this;
        this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: GET_EVENT_CHECKED_PRIVACY_POLICY,
          variables: {
            eventId: this.event.id
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            return;
          }
          if(response && response.data && response.data.checkedPolicyEvents) {
            // response.data.checkedPolicyEvents.result.subscribe(function(result){
            //   console.log("result", result)
            // })
            context.acceptedPolicies = JSON.parse(response.data.checkedPolicyEvents.result)
          }
        }).catch((error) => {
          context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);
        });
      }
    }
};
</script>

<style scoped>
    .use-existing-component {
        width: 100%;
    }
    .use-existing-component .field{
        padding: 10px 0 10px 0;
        margin: 0 0 5px 0;
    }
    .use-existing-component .field.policy-name{
        border-right: 1px solid #ddd;
        padding-right: 40px;
    }
    .use-existing-component .field.policy-mandatory{
        padding-left: 40px;
    }
    .use-existing-component .field i{
        font-size: 20px;
    }
    .use-existing-component .field i:hover{
        cursor: pointer;
        color: #f7a30c;
    }
    .use-existing-component .policy{
        margin-bottom: 40px;
    }
    .use-existing-component .policy-recap{
        border-bottom: 1px solid #dddddd;
    }
    .use-existing-component .policy-name{
        display: inline-block;
        width: 29%;
    }
    .use-existing-component .policy-mandatory{
        display: inline-block;
        width: 59%;
        text-align: left;
    }
    .use-existing-component .policy-edit{
        display: inline-block;
        width: 10%;
        text-align: right;
    }
    .use-existing-component .actions{
        display: inline-block;
        width: 29%;
        text-align: left;
        vertical-align: top;
        padding-right: 40px;
    }
    .use-existing-component .text{
        display: inline-block;
        width: 59%;
    }
    .use-existing-component .policy-pagination{
        text-align: center;
    }
    .use-existing-component .policy-pagination .pagination li a{
        color: white !important;
    }
    .use-existing-component .policy-result-download{
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
    }
    .use-existing-component .policy-result-download .btn{
        text-transform: uppercase;
        color: #ffffff;
    }

    /*pagination color*/

    .pagination>li>a, .pagination>li>span {
        color: white !important;
    }


    /*checkbox*/

    .control {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;
    }
    .control input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }
    .control_indicator {
        position: absolute;
        top: 2px;
        left: 0;
        height: 20px;
        width: 20px;
        background: #e6e6e6;
        border: 0px solid #000000;
    }
    .control-radio .control_indicator {
        border-radius: 0;
    }

    .control:hover input ~ .control_indicator,
    .control input:focus ~ .control_indicator {
        background: #cccccc;
    }

    .control input:checked ~ .control_indicator {
        background: #000;
    }
    .control:hover input:not([disabled]):checked ~ .control_indicator,
    .control input:checked:focus ~ .control_indicator {
        background: #000;
    }
    .control input:disabled ~ .control_indicator {
        background: #e6e6e6;
        opacity: 0.6;
        pointer-events: none;
    }
    .control_indicator:after {
        box-sizing: unset;
        content: '';
        position: absolute;
        display: none;
    }
    .control input:checked ~ .control_indicator:after {
        display: block;
    }
    .control-checkbox .control_indicator:after {
        left: 8px;
        top: 4px;
        width: 3px;
        height: 8px;
        border: solid #ffffff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    .control-checkbox input:disabled ~ .control_indicator:after {
        border-color: #7b7b7b;
    }
</style>
<style>
    .privacy-content {
        margin-top: 10px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        border: 1px solid #f7a30c;
    }
    .privacy-content a {
        background: transparent !important;
        text-decoration: underline !important;
        text-decoration-color: #f7a30c !important;
        color: #f7a30c !important;
        text-transform: lowercase !important;
    }
</style>
