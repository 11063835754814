<template>
  <div class="report-component">
    <h4 class="form-section-title">
      {{ string.report._title }} <small>{{ string.report._subtitle }}</small>
    </h4>
    <div class="smallseparator"></div>

    <!-- PRINTED REPORT -->
    <div id="print-analytics-panel" class="print-analytics-panel-class">
      <div class="row">
        <h1 class="main-title">
          {{ event.name }}
        </h1>
        <h2 class="main-title">
          {{ string.report._subtitle }}
        </h2>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="title-graph">
            <h2>Total Attendee Status</h2>
          </div>
          <div id="total-attendee-image" class="img-placeholder first-img-placeholder"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="title-graph">
            <h2>Timeline Attendee Checked</h2>
          </div>
          <div id="timeline-checked-image" class="img-placeholder"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="title-graph">
            <h2>Timeline of Attendee Confirmation</h2>
          </div>
          <div id="timeline-attendee-confirmation-image" class="img-placeholder"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="title-graph">
            <h2>{{ string.report._attendeeChart }}</h2>
          </div>
          <div>
            <table id="attendee-report-table">
              <thead>
                <th><h3>Name</h3></th>
                <th><h3>Status</h3></th>
                <th><h3>Check-in Time</h3></th>
              </thead>
              <tbody>
                <!-- <tr v-for="item in reportChartData" :key="item.id">
                  <td>{{ item.name }} {{ item.surname }}</td>
                  <td>{{ item.status }}</td>
                  <td>{{ item.activityDate }}</td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- VISIBLE REPORT -->
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="row data-export-icon">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button :disabled="exportingReport" class="print-button button btn-warning" @click="fetchDataAndPrint">
              <i class="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="row data-export-excel-icon">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
             <button :disabled="exportingReport" class="print-button button btn-warning" @click="fetchDataAndExportReport">
              <i class="fa fa-file-excel-o" aria-hidden="true"></i>
            </button>
          </div>
        </div>
        <div v-if="reportEnabled">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="content-panel">
                <h4>{{ string.report._totalAttendeeStatus }}</h4>
                <div class="panel-body text-center">
                  <div id="canvasHolderTotalAttendee" style="width:100%">
                    <canvas ref="totalAttendees"></canvas>
                    <!--                  <span style="position: absolute;left: 700px;top: 120px;font-size: 15px;">COMING SOON</span>-->
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="content-panel">
                <h4>{{ string.report._timeLineCheckInTitle }}</h4>
                <div class="panel-body text-center">
                  <div class="cont-didascalia">
                    <div class="didascalia well well-sm">
                      {{ string.report._timeLineCheckinChartLegend }}
                    </div>
                  </div>
                  <div id="canvasHolderTimeline" style="width:100%">
                    <canvas ref="timelineChecked"></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="content-panel">
                <h4>{{ string.report._timeLineAttendeeConfirmationTitle }}</h4>
                <div class="panel-body text-center">
                  <div class="cont-didascalia">
                    <div class="didascalia well well-sm">
                      {{ string.report._attendeeConfirmationChartLegend }}
                    </div>
                  </div>
                  <div id="canvasHolderConfirmation" style="width:100%">
                    <canvas ref="timelineAttendeesConfirmation"></canvas>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="coming-soon">
          Coming soon
        </div>

        <div class="row data-export">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <a :disabled="exportingReport" class="button print-button btn-warning" @click="fetchDataAndPrint">
              <i class="fa fa-download" aria-hidden="true"></i>
              {{ string.report._export }} PDF
            </a>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <a :disabled="exportingReport" class="button print-button btn-warning" @click="fetchDataAndExportReport">
              <i class="fa fa-download" aria-hidden="true"></i>
              {{ string.report._export }} XLSX
            </a>
          </div>
        </div>

        <div class="row mt">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="title-graph">
              <h4>{{ $root.strings.virtualRoomSettings._title }}</h4>
            </div>
            <div id="timeline-attendee-confirmation-image" class="img-placeholder"></div>
          </div>
        </div>

        <div class="row data-export">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <a :disabled="exportingReport" class="button print-button btn-warning" @click="downloadJoinLogs">
              <i class="fa fa-download" aria-hidden="true"></i>
              {{ $root.strings.virtualRoomSettings._downloadlogs }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
#analytics-panel .data-export div {
  margin-top: 15px;
  margin-bottom: 20px;
}
#analytics-panel .data-export .button {
  width: 100%;
}
#analytics-panel .data-export-icon .button {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #f0ad4e;
  color: #ffffff;
  border-radius: 50% !important;
  position: absolute;
  right: 20px;
  z-index: 1;
}
#analytics-panel .data-export-icon .button i {
  position: absolute;
  top: 26%;
  left: 26%;
  font-size: 20px;
  margin: 0 auto;
  color: #ffffff;
  z-index: 99;
}
#analytics-panel .data-export-icon .button:hover {
  background-color: #ec971f;
}
#analytics-panel .didascalia {
  color: #333333;
  font-size: small;
}

@import "../assets/css/report.css";
#print-analytics-panel {
  display: none;
}
</style>
<style scoped>
.coming-soon {
  text-align: center;
  text-transform: uppercase;
  min-height: 200px;
  line-height: 200px;
  padding: 15px !important;
  font-size: 24px;
  margin: 15px !important;
}
.print-button {
  transition: 1s ease;
  background-color: rgba(255, 255, 255, 0.3);
  border-color: transparent;
  padding: 15px;
  margin-top: 20px;
  color: #fff !important;
  font-size: 16px;
  text-transform: uppercase;
}
.print-button:hover {
  background-color: #f7a30c;
  color: #ffffff !important;
  padding: 15px;
  margin-top: 20px;
  border-color: transparent;
  text-decoration: none;
  font-size: 16px;
  text-transform: uppercase;
}
.panel-default {
  cursor: pointer;
  border-color: #fff !important;
  border-radius: 0;
  /*background: transparent !important;*/
}

.content-panel .panel-body {
  padding-left: 240px !important;
  padding-right: 240px !important;
}

.content-panel {
  box-shadow: none !important;
}

.report-component {
  margin-top: 50px;
}

.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #f7a30c;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}

.report-component .data-export-excel-icon .button {
  width: 40px;
  height: 40px;
  background-color: Green;
  color: #ffffff;
  border-radius: 50% !important;
  position: absolute;
  right: 20px;
  z-index: 1;
  padding: 8px;
}

.report-component .data-export-icon .button {
  width: 40px;
  height: 40px;
  padding: 0;
  background-color: #f0ad4e;
  color: #ffffff;
  border-radius: 50% !important;
  position: absolute;
  right: 70px;
  z-index: 1;
}

.report-component .data-export .button {
  width: 100%;
}
</style>
<script>
import $ from "jquery";
import { GET_EVENT_ANALYTICS, GUEST_LIST_FOR_REPORT } from "@/graphql";
import { customFieldAnswerToString } from "../utils";
import { mapGetters } from 'vuex';

Date.prototype.format = function() {
  const add0 = t => (t < 10 ? "0" + t.toString() : t.toString());
  const year = this.getFullYear().toString();
  const month = add0(this.getMonth() + 1);
  const date = add0(this.getDate());
  const hours = add0(this.getHours());
  const minutes = add0(this.getMinutes());
  return year + "-" + month + "-" + date + " " + hours + ":" + minutes;
};
export default {
  components: {},
  props: {
    eventId: {
      type: String,
      required: true
    },
    event: {
      type: Object,
      required: true,
      default: () => {}
    },
    reloadNumber: {
      type: Number,
      required: false,
      default: 0
    },
  },
  data() {
    return {
      componentName: "report",
      reportEnabled: true,
      printTableData: [],
      reportChartData: [],
      string: {
        /*
              report: {
                  _title : "Statistiche",
                  _subtitle : "Analisi dei dati raccolti",
              }
              */
      },
      loadingMessage: {
        fillStyle: "white",
        font: "bold 16px Helvetica",
        text: "Loading..."
      },
      ctx_total_attendee: Object,
      ctx_timeline_checked: Object,
      ctx_timeline_attendee_confirmation: Object,
      configTotalAttendeeChart: {
        type: "doughnut",
        data: {
          labels: ["Pending", "Waiting for verification", "Confirmed", "Check in", "Will be back", "Check out", "Decline"],
          datasets: [],
          tooltips: ["Pending", "Waiting for verification", "Confirmed", "Check in", "Will be back", "Check out", "Decline"]
        },
        options: {
          responsive: true,
          legend: {
            position: "left",
            labels: {
              fontColor: "#999999"
            }
          },
          title: {
            display: true,
            text: "Total Attendee(s)",
            fontColor: "#999999"
          },
          tooltips: {
            enabled: true,
            callbacks: {
              label: (model, data) => {
                return data.tooltips[model.index];
              }
            }
          },
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 1000,
            easing: "easeOutExpo",
            onAnimationComplete: "onAnimationComplete"
          }
        }
      },
      defaultTotalAttendeeData: {
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: [
          "#555555", //"Pending",
          "#E6A23C", // "Waiting for verification"    
          "#2980b9", // "Confirmed",
          "#ff9c00", // "Check in",
          "#9b59b6", // "Will be back",
          "#1abc9c", // "Check out",
          "#DC143C" // "Decline"
        ],
        state: ["Pending", "Waiting for verification", "Confirmed", "Check in", "Will be back", "Check out", "Decline"]
      },
      configTimeLineAttendeeConfirmation: {
        type: "bar",
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: "top"
          },
          title: {
            display: false,
            text: "TotalAttendee"
          },
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 1000,
            easing: "easeOutExpo",
            onAnimationComplete: "onAnimationComplete"
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                  fontColor: "#999999"
                },
                scaleLabel: {
                  display: true,
                  labelString: "Number of Rsvp",
                  fontColor: "#999999"
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  labelString: "giorni",
                  fontColor: "#999999"
                },
                ticks: {
                  fontColor: "#999999"
                }
              }
            ]
          }
        }
      },
      defaultTimeLineAttendeeConfirmation: {
        label: "Attendee Confirmation last days",
        backgroundColor: "rgba(236, 151, 31, 0.4)",
        borderColor: "rgba(236, 151, 31, 1)",
        borderWidth: 3,
        data: []
      },
      configTimeLineChecked: {
        type: "line",
        data: {
          datasets: [],
          labels: [
            "00-01",
            "01-02",
            "02-03",
            "03-04",
            "04-05",
            "05-06",
            "06-07",
            "07-08",
            "08-09",
            "09-10",
            "10-11",
            "11-12",
            "12-13",
            "13-14",
            "14-15",
            "15-16",
            "16-17",
            "17-18",
            "18-19",
            "19-20",
            "20-21",
            "21-22",
            "22-23",
            "23-00"
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            hidden: true
          },
          title: {
            display: false,
            text: "TotalAttendee"
          },
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 1000,
            easing: "easeOutExpo",
            onAnimationComplete: "onAnimationComplete"
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                  fontColor: "#999999"
                },
                scaleLabel: {
                  display: true,
                  labelString: "Guest",
                  fontColor: "#999999"
                }
              }
            ]
          }
        }
      },
      defaultTimeLineCheckedData: {
        lineTension: 0,
        label: "guest",
        backgroundColor: "rgba(236, 151, 31, 0.4)",
        borderColor: "rgba(236, 151, 31, 1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(236, 151, 31, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBorderColor: "rgba(236, 151, 31, 1)",
        pointHoverBackgroundColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 1,
        data: []
      },
      totalAttendeesChart: Object,
      timelineCheckedChart: Object,
      timelineAttendeeConfirmationChart: Object,
      reportColumns: [
        {
          label: "Event",
          field: "eventId",
          dataFormat: value => {
            return this.event.name;
          }
        },
        {
          label: "Name",
          field: "member",
          dataFormat: value => {
            return value["name"];
          }
        },
        {
          label: "Surname",
          field: "member",
          dataFormat: value => {
            return value["surname"];
          }
        },
        {
          label: "Email",
          field: "member",
          dataFormat: value => {
            return value["email"].includes("internal.penguinpass.it") ? "" : value["email"];
          }
        },
        {
          label: "Alternative Emails",
          field: "member",
          dataFormat: value => {
            let output = "";
            const emailList = value["listEmails"];
            for (const [index, email] of emailList.entries()) {
              if (index !== emailList.length - 1) {
                if (email && email.trim() !== "") {
                  output += email + ", ";
                }
              } else {
                if (email && email.trim() !== "") {
                  output += email;
                } else {
                  // Remove previous comma because last one failed
                  output = output.slice(0, output.length - 2);
                }
              }
            }
            return output;
          }
        },
        {
          label: "Tel",
          field: "member",
          dataFormat: value => {
            return value.telephone;
          }
        },
        {
          label: "Actual +1",
          field: "acquaintance"
        },
        {
          label: "Forecasted +1",
          field: "maxAcquaintance"
        },
        {
          label: "+1 Info",
          field: "acquaintanceInfoList",
          dataFormat: values => {
            let result = "";
            for (const value of values) {
              result += `${value.name ? value.name : ""}, ${value.surname ? value.surname : ""}, ${
                value.email ? value.email : ""
              }; `;
            }
            return result;
          }
        },
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Campaign Status",
          field: "deliveryStatus",
          dataFormat: value => {
            return value && value !== "DRAFT" ? value : "-";
          }
        },
        {
          label: "Rsvp Date",
          field: "rsvpDate",
          dataFormat: value => {
            return value ? new Date(value).format() : "";
          }
        },
        {
          label: "Checkin Date",
          field: "activity",
          dataFormat: value => {
            if (value && value.length) {
              for (const activity of value) {
                if (activity.activity === "CHECK_IN") {
                  return new Date(activity.createdAt).format();
                }
              }
            }
            return "";
          }
        },
        {
          label: "Virtual Check-in",
          field: "virtualCheckInAt",
          dataFormat: value => {
            return value ? new Date(value).format() : "";
          }
        },
        {
          label: "Is Registered",
          field: "member",
          dataFormat: value => {
            return value["isRegistered"] ? "yes" : "no";
          }
        },
        {
          label: "Added From Check-in App",
          field: "fromCheckinApp",
          dataFormat: value => {
            return value ? "yes" : "no";
          }
        }
      ],
      reportData:[],
      exportingReport: false
    };
  },
  computed: {
    ...mapGetters(["memberCustomFieldMap, memberCustomFieldMapArray"]),
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
  },
  mounted() {
    this.printPlugin();
    this.ctx_total_attendee = this.$refs["totalAttendees"].getContext("2d");
    this.ctx_timeline_checked = this.$refs["timelineChecked"].getContext("2d");
    this.ctx_timeline_attendee_confirmation = this.$refs[
      "timelineAttendeesConfirmation"
    ].getContext("2d");
    this.loadAllReport();
  },
  methods: {
    // Custom Fields Management
    addToReportColumns: function() {
      const memberColumns = this.memberCustomFieldMap ? Object.entries(this.memberCustomFieldMap) : [];
      const customColumns = [...memberColumns,...(this.event.customFieldMap ? Object.entries(this.event.customFieldMap) : [])];
      for (const [slug, cField] of customColumns) {
        let notExist = true;
        this.reportColumns.map(col => {
          if (col.cFieldSlug === slug) {
            notExist = false;
          }
        });
        if (notExist) {
          if (cField.source === "CONTACT") {
            this.reportColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "member",
              dataFormat: member => ((member && member.memberCustomFieldDataMap) ? customFieldAnswerToString(member.memberCustomFieldDataMap[slug]) : "")
            });
          } else {
            this.reportColumns.push({
              cFieldSlug: slug,
              label: cField.options.question,
              field: "customFieldDataMap",
              dataFormat: customFieldDataMap => customFieldAnswerToString(customFieldDataMap[slug])
            });
          }
        }
      }
    },
    printPlugin: function() {
      // A nice closure for our definitions
      function getjQueryObject(string) {
        // Make string a vaild jQuery thing
        let jqObj = $("");
        try {
          jqObj = $(string).clone();
        } catch (e) {
          jqObj = $("<span />").html(string);
        }
        return jqObj;
      }

      function printFrame(frameWindow, content, options) {
        // Print the selected window/iframe
        const def = $.Deferred();
        try {
          frameWindow = frameWindow.contentWindow || frameWindow.contentDocument || frameWindow;
          const wdoc = frameWindow.document || frameWindow.contentDocument || frameWindow;
          if (options.doctype) {
            wdoc.write(options.doctype);
          }
          wdoc.write(content);
          wdoc.close();
          let printed = false;
          const callPrint = function() {
            if (printed) {
              return;
            }
            // Fix for IE : Allow it to render the iframe
            frameWindow.focus();
            try {
              // Fix for IE11 - printng the whole page instead of the iframe content
              if (!frameWindow.document.execCommand("print", false, null)) {
                // document.execCommand returns false if it failed -http://stackoverflow.com/a/21336448/937891
                frameWindow.print();
              }
            } catch (e) {
              frameWindow.print();
            }
            frameWindow.close();
            printed = true;
            def.resolve();
          };
          // Print once the frame window loads - seems to work for the new-window option but unreliable for the iframe
          $(frameWindow).on("load", callPrint);
          // Fallback to printing directly if the frame doesn't fire the load event for whatever reason
          setTimeout(callPrint, options.timeout);
        } catch (err) {
          def.reject(err);
        }
        return def;
      }

      function printContentInIFrame(content, options) {
        let $iframe = $(options.iframe + "");
        const iframeCount = $iframe.length;
        if (iframeCount === 0) {
          // Create a new iFrame if none is given
          $iframe = $('<iframe height="0" width="0" border="0" wmode="Opaque"/>')
            .prependTo("body")
            .css({
              position: "absolute",
              top: -999,
              left: -999
            });
        }
        const frameWindow = $iframe.get(0);
        return printFrame(frameWindow, content, options)
          .done(() => {
            // Success
            setTimeout(() => {
              // Wait for IE
              if (iframeCount === 0) {
                // Destroy the iframe if created here
                $iframe.remove();
              }
            }, 100);
          })
          .fail(err => {
            // Use the pop-up method if iframe fails for some reason
            console.error("Failed to print from iframe", err);
            printContentInNewWindow(content, options);
          })
          .always(() => {
            try {
              options.deferred.resolve();
            } catch (err) {
              console.warn("Error notifying deferred", err);
            }
          });
      }

      function printContentInNewWindow(content, options) {
        // Open a new window and print selected content
        const frameWindow = window.open();
        return printFrame(frameWindow, content, options).always(() => {
          try {
            options.deferred.resolve();
          } catch (err) {
            console.warn("Error notifying deferred", err);
          }
        });
      }

      function isNode(o) {
        /* http://stackoverflow.com/a/384380/937891 */
        return !!(typeof Node === "object"
          ? o instanceof Node
          : o &&
            typeof o === "object" &&
            typeof o.nodeType === "number" &&
            typeof o.nodeName === "string");
      }

      $.print = $.fn.print = function() {
        // Print a given set of elements
        let options;
        let $this;
        let self = this;
        console.log("Printing", this, arguments);
        if (self instanceof $) {
          // Get the node if it is a jQuery object
          self = self.get(0);
        }
        if (isNode(self)) {
          // If `this` is a HTML element, i.e. for
          // $(selector).print()
          $this = $(self);
          if (arguments.length > 0) {
            options = arguments[0];
          }
        } else {
          if (arguments.length > 0) {
            // $.print(selector,options)
            $this = $(arguments[0]);
            if (isNode($this[0])) {
              if (arguments.length > 1) {
                options = arguments[1];
              }
            } else {
              // $.print(options)
              options = arguments[0];
              $this = $("html");
            }
          } else {
            // $.print()
            $this = $("html");
          }
        }
        // Default options
        const defaults = {
          globalStyles: true,
          mediaPrint: false,
          stylesheet: null,
          noPrintSelector: ".no-print",
          iframe: true,
          append: null,
          prepend: null,
          manuallyCopyFormValues: true,
          deferred: $.Deferred(),
          timeout: 750,
          title: "analytics-report",
          doctype: "<!doctype html>"
        };
        // Merge with user-options
        options = $.extend({}, defaults, options || {});
        let $styles = $("");
        if (options.globalStyles) {
          // Apply the stlyes from the current sheet to the printed page
          $styles = $("style, link, meta, base, title");
        } else if (options.mediaPrint) {
          // Apply the media-print stylesheet
          $styles = $("link[media=print]");
        }
        if (options.stylesheet) {
          // Add a custom stylesheet if given
          $styles = $.merge(
            $styles,
            $('<link rel="stylesheet" href="' + options.stylesheet + '">')
          );
        }
        // Create a copy of the element to print
        let copy = $this.clone();
        // Wrap it in a span to get the HTML markup string
        copy = $("<span/>").append(copy);
        // Remove unwanted elements
        copy.find(options.noPrintSelector).remove();
        // Add in the styles
        copy.append($styles.clone());
        // Update title
        if (options.title) {
          let title = $("title", copy);
          if (title.length === 0) {
            title = $("<title />");
            copy.append(title);
          }
          title.text(options.title);
        }
        // Appedned content
        copy.append(getjQueryObject(options.append));
        // Prepended content
        copy.prepend(getjQueryObject(options.prepend));
        if (options.manuallyCopyFormValues) {
          // Manually copy form values into the HTML for printing user-modified input fields
          // http://stackoverflow.com/a/26707753
          copy.find("input").each(function() {
            const $field = $(this);
            if ($field.is("[type='radio']") || $field.is("[type='checkbox']")) {
              if ($field.prop("checked")) {
                $field.attr("checked", "checked");
              }
            } else {
              $field.attr("value", $field.val());
            }
          });
          copy.find("select").each(function() {
            const $field = $(this);
            $field.find(":selected").attr("selected", "selected");
          });
          copy.find("textarea").each(function() {
            // Fix for https://github.com/DoersGuild/jQuery.print/issues/18#issuecomment-96451589
            const $field = $(this);
            $field.text($field.val());
          });
        }
        // Get the HTML markup string
        const content = copy.html();
        // Notify with generated markup & cloned elements - useful for logging, etc
        try {
          options.deferred.notify("generated_markup", content, copy);
        } catch (err) {
          console.warn("Error notifying deferred", err);
        }
        // Destroy the copy
        copy.remove();
        if (options.iframe) {
          // Use an iframe for printing
          try {
            printContentInIFrame(content, options);
          } catch (e) {
            // Use the pop-up method if iframe fails for some reason
            console.error("Failed to print from iframe", e.stack, e.message);
            printContentInNewWindow(content, options);
          }
        } else {
          // Use a new window for printing
          printContentInNewWindow(content, options);
        }
        return this;
      };
    },
    async fetchDataAndExportReport(){
      const context = this;
      this.exportingReport = true;
      this.$root.bus.emit('simpleToast',  this.string.report._export_request_received);
      await this.loadReportData().then(_ => {
        if (context.reportData.length > 0) {
          const c = context.$createElement(
            "vue-excel-xlsx",
            {
              class: ["discardStyle"],
              props: {
                columns: context.reportColumns,
                data: context.reportData,
                filename:"guest_list_report",
                sheetname:"Sheet 1"
              }
            },
            context.string.report._export_request_finished
          );
          context.$notify({
            title: "Success!",
            message: c,
            type: "success",
            duration: 0,
            offset: 100
          });
        } else {
          context.$root.bus.emit('simpleToast', context.string.report._export_empty);
        }
     }).catch(error => {
        console.log(error.message);
        this.$root.bus.emit('simpleToast',  this.string.report._export_failed);
      });
      this.exportingReport = false;
    },
    async fetchDataAndPrint() {
      const context = this;
      this.exportingReport = true;
      this.$root.bus.emit('simpleToast',  this.string.report._export_request_received);
      await this.loadReportData().then(_ => {
        context.printReport();
      }).catch(error => {
        console.log(error.message);
        this.$root.bus.emit('simpleToast',  this.string.report._export_failed);
      });
      this.exportingReport = false;
    },
    printReport: function() {
      const totalAttendeeImage = '<img src="' + this.totalAttendeesChart.toBase64Image() + '" />';
      $("#total-attendee-image").html(totalAttendeeImage);

      const timelineCheckedImage =
        '<img src="' + this.timelineCheckedChart.toBase64Image() + '" />';
      $("#timeline-checked-image").html(timelineCheckedImage);

      const timelineAttendeeConfirmationImage =
        '<img src="' + this.timelineAttendeeConfirmationChart.toBase64Image() + '" />';
      $("#timeline-attendee-confirmation-image").html(timelineAttendeeConfirmationImage);
      this.$htmlToPaper("print-analytics-panel");

    },
    reportFailed: function(error) {
      console.error(error);
    },
    clearCanvas: function(canvas) {
      canvas.clearRect(0, 0, 1000, 1000);
    },
    renderReportChart: function() {
      const instance = this;
      return new Promise((resolve, reject) => {
        const tempData = [];
        $('#attendee-report-table tbody tr').remove();
        for (const guest of instance.reportData) {
          const name = guest.member && guest.member.name ? guest.member.name : "";
          const surname = guest.member && guest.member.surname ? guest.member.surname : "";
          const status = guest.status;
          if (guest.activity && guest.activity.length) {
            for (const [index, activity] of guest.activity.entries()) {
              if (activity.activity === "CHECK_IN") {
                const activityDate = new Date(activity.createdAt).format();
                tempData.push({
                  id: guest.id +"-"+ index,
                  name,
                  surname,
                  status,
                  activityDate
                });
                $('#attendee-report-table tbody').append(
                    '<tr>' +
                    '<td>'+name+' '+surname+'</td>' +
                    '<td>'+status+'</td>' +
                    '<td>'+activityDate+'</td>' +
                    '</tr>'
                );
                break;
              }
            }
          } else {
            tempData.push({
              id: guest.id,
              name,
              surname,
              status,
              activityDate: ""
            });
            $('#attendee-report-table tbody').append(
                '<tr>' +
                '<td>'+name+' '+surname+'</td>' +
                '<td>'+status+'</td>' +
                '<td>'+''+'</td>' +
                '</tr>'
            );
          }
        }
        resolve(tempData);
      });
    },
    putLoading: function() {
      this.clearCanvas(this.ctx_total_attendee);
      this.ctx_total_attendee.fillStyle = this.loadingMessage.fillStyle;
      this.ctx_total_attendee.font = this.loadingMessage.font;
      this.ctx_total_attendee.fillText(this.loadingMessage.text, 100, 100);

      this.clearCanvas(this.ctx_timeline_checked);
      this.ctx_timeline_checked.fillStyle = this.loadingMessage.fillStyle;
      this.ctx_timeline_checked.font = this.loadingMessage.font;
      this.ctx_timeline_checked.fillText(this.loadingMessage.text, 100, 100);

      this.clearCanvas(this.ctx_timeline_attendee_confirmation);
      this.ctx_timeline_attendee_confirmation.fillStyle = this.loadingMessage.fillStyle;
      this.ctx_timeline_attendee_confirmation.font = this.loadingMessage.font;
      this.ctx_timeline_attendee_confirmation.fillText(this.loadingMessage.text, 100, 100);
    },
    loadAllReport() {
      this.putLoading();
      this.loadReport("PIE_TOTAL_ATTENDEE", this.renderReportTotalAttendee, this.reportFailed);
      this.loadReport("CURVE_CHECKIN", this.renderReportTimeLineChecked, this.reportFailed);
      this.loadReport(
        "BAR_ATTENDEE_CONFIRMATION",
        this.renderReportTimeLineAttendeeConfirmation,
        this.reportFailed
      );
      // // this.loadReport(this.renderReportChart, this.reportFailed, 'purchaseChart');
    },
    loadReport: function(analysis, reportSuccess, reportFailed) {
      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_EVENT_ANALYTICS,
          variables: {
            input: {
              eventId: context.eventId,
              type: analysis
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            reportFailed(response.errors);
            return;
          }
          if (response.data && response.data.getEventAnalytics) {
            const payload = response.data.getEventAnalytics;
            reportSuccess(payload);
          }
        })
        .catch(error => {
          console.log(error);
          reportFailed(error);
        });
    },
    writeReportFailed: function(canvas) {
      this.clearCanvas(canvas);
      canvas.fillText("No Data", 100, 100);
    },
    renderReportTotalAttendee: function(data) {
      if (data && data.data && data.data.length === 7) {
        const newChartDataWrapper = this.configTotalAttendeeChart.data;
        for (const [index, label] of newChartDataWrapper.labels.entries()) {
          newChartDataWrapper.labels[index] =
            newChartDataWrapper.tooltips[index] + ": " + data.data[index];
        }
        const newChartInnerDataWrapper = this.defaultTotalAttendeeData;
        newChartInnerDataWrapper.data = data.data;
        newChartDataWrapper.datasets = [newChartInnerDataWrapper];
        this.configTotalAttendeeChart.options.title.text =
          "Total Attendee(s)" + ":  " + data.totalAttendee;
        this.configTotalAttendeeChart = Object.assign(this.configTotalAttendeeChart, {
          data: newChartDataWrapper
        });
        this.totalAttendeesChart = new Chart(
          this.ctx_total_attendee,
          this.configTotalAttendeeChart
        );
      } else {
        this.writeReportFailed(this.ctx_total_attendee);
      }
    },
    renderReportTimeLineChecked: function(data) {
      const canvas = this.ctx_timeline_checked;
      if (data && data.data) {
        const newChartDataWrapper = this.configTimeLineChecked.data;
        const newChartInnerDataWrapper = this.defaultTimeLineCheckedData;
        newChartInnerDataWrapper.data = data.data;
        newChartDataWrapper.datasets = [newChartInnerDataWrapper];
        this.configTimeLineChecked = Object.assign(this.configTimeLineChecked, {
          data: newChartDataWrapper
        });
        this.timelineCheckedChart = new Chart(canvas, this.configTimeLineChecked);
      } else {
        this.writeReportFailed(canvas);
      }
    },
    renderReportTimeLineAttendeeConfirmation: function(data) {
      const canvas = this.ctx_timeline_attendee_confirmation;
      if (data && data.data) {
        const newChartDataWrapper = this.configTimeLineAttendeeConfirmation.data;
        const newChartInnerDataWrapper = this.defaultTimeLineAttendeeConfirmation;
        newChartInnerDataWrapper.data = data.data;
        newChartDataWrapper.datasets = [newChartInnerDataWrapper];
        newChartDataWrapper.labels = data.labels;
        this.configTimeLineAttendeeConfirmation = Object.assign(
          this.configTimeLineAttendeeConfirmation,
          { data: newChartDataWrapper }
        );
        this.timelineAttendeeConfirmationChart = new Chart(
          canvas,
          this.configTimeLineAttendeeConfirmation
        );
      } else {
        this.writeReportFailed(canvas);
      }
    },
    downloadJoinLogs() {
      this.$store
        .dispatch("getJoinLogs", {
          eventId: this.event.id,
        })
        .then((result) => {
          if (result.status === "SUCCESS" && result.csv) {
            const byteNumbers = new Array(result.csv.length);
            for (let i = 0; i < result.csv.length; i++) {
              byteNumbers[i] = result.csv.charCodeAt(i);
            }
            const arr = new Uint8Array(byteNumbers);
            const blob = new Blob([arr], { type: "text/csv" });
            const href = window.URL.createObjectURL(blob, { type: "text/csv" });
            const theLink = document.createElement("a");
            theLink.href = href;
            theLink.download = "join_logs.csv";
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$root.bus.emit("simpleToast", error.data.message);
          }
        });
    },
    async loadReportData() {
      const context = this;
      await this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GUEST_LIST_FOR_REPORT,
          variables: { eventId: this.eventId }
        })
        .then(async response => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.Event) {
            context.reportData = response.data.Event.guestList;
            await context.renderReportChart().then(data => this.reportChartData);
            this.addToReportColumns();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
