<template>
  <MainLayout>
    <el-container direction="vertical" class="page-container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-12">
          <h1 style="font-weight: 300">
            {{ $root.strings.notify._page_title }}
          </h1>
          <h4 style="margin-top: 10px">
            {{ $root.strings.notify._subtitle }}
          </h4>
          <div class="smallseparator" style="margin-top: 25px !important"></div>
        </div>
      </div>
      <section-content :has-icon="true">
        <section-title :title="$root.strings.notify._create_section_title" />
        <transition name="component-fade-fast" mode="out-in" appear>
          <button
            v-if="inputVisible === false"
            class="button save"
            style="width: 100% !important"
            @click="createNotification"
          >
            {{ $root.strings.notify._create }}
          </button>
          <div v-else class="">
            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.notify._title }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <custom-input
                  v-model.trim="inputName"
                  :placeholder="$root.strings.notify._title_placeholder"
                  @change="() => {}"
                />
                <div class="suggestion-row">
                  <div class="suggestion">
                    {{ $root.strings.notify._title_recommendation }}
                  </div>
                  <div class="suggestion right">{{ inputName.length }} / {{ maxChars }}</div>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.notify._body }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <textarea
                  v-model.trim="inputBody"
                  class="form-control"
                  rows="5"
                  :placeholder="$root.strings.notify._body_placeholder"
                >
                </textarea>
              </el-col>
            </el-row>
          </div>
        </transition>
      </section-content>

      <!--SELECT USER GROUP -->
      <section-content v-if="inputVisible" :has-icon="true">
        <section-title :title="$root.strings.notify._user_group_section_title" />
        <h4 style="margin-top: 10px">
          {{ $root.strings.notify._subtitle_user_group }}
        </h4>
        <custom-checkbox @change="toggleAll" />
        {{ $root.strings.notify._all_users }}
        <div class="group-manager-members-list">
          <transition name="component-fade-fast" mode="out-in" appear>
            <data-table
              v-show="!all"
              key="events-table"
              ref="tableEvents"
              :show-pagination="true"
              :page-size="25"
              :get-data="getDataEvent"
              :columns="columnsEvent"
              :selection-enabled="true"
              :on-selection-change="onSelectionChange"
              :filter-enabled="false"
              :no-data-text="$root.strings.notify._no_notifications"
            >
              <span slot="name" slot-scope="{ row }">
                {{ row.name }}
              </span>
              <span slot="startDate" slot-scope="{ row }">
                {{ row["startDate"] ? new Date(row["startDate"]).format() : " - " }}
              </span>
              <span slot="endDate" slot-scope="{ row }">
                {{ row["endDate"] ? new Date(row["endDate"]).format() : " - " }}
              </span>
              <span slot="id" slot-scope="{ row }">
                {{ row.id }}
              </span>
            </data-table>
          </transition>
        </div>

        <button class="button save" style="width: 100% !important" @click="sendNotification">
          {{ $root.strings.notify._submit }}
        </button>
      </section-content>

      <!-- RECAP    -->
      <section-content :has-icon="true">
        <section-title :title="$root.strings.notify._recap_section_title" />
        <div class="group-manager-members-list">
          <PulseLoader v-show="loadingRecap" color="#f7a30c" size="15px"></PulseLoader>

          <data-table
            v-show="!loadingRecap"
            key="recap-table"
            ref="recapTable"
            :get-data="getNotifications"
            :columns="columnsRecap"
            :selection-enabled="false"
            :filter-enabled="false"
            :no-data-text="$root.strings.notify._no_notifications"
          >
            <span slot="title" slot-scope="{ row }">
              {{ row.title }}
            </span>
            <span slot="description" slot-scope="{ row }">
              <el-tooltip effect="dark" :content="row.description" placement="bottom">
                <span>
                  {{
                    row.description
                      ? (row.description.length > 10
                        ? row.description.substring(0, 10) + "..."
                        : row.description)
                      : "-"
                  }}
                  </span
                >
              </el-tooltip>
            </span>
            <span slot="total" slot-scope="{ row }">
              {{ row.total }}
            </span>
            <span slot="queued" slot-scope="{ row }">
              {{ row.queued }}
            </span>
            <span slot="send" slot-scope="{ row }">
              {{ row.send }}
            </span>
            <span slot="failed" slot-scope="{ row }">
              {{ row.failed }}
            </span>
            <span slot="scheduledTo" slot-scope="{ row }">
              {{ row["scheduledTo"] ? new Date(row["scheduledTo"]).format() : " - " }}
            </span>
            <span slot="sentAt" slot-scope="{ row }">
              {{ row["sentAt"] ? new Date(row["sentAt"]).format() : " - " }}
            </span>
          </data-table>
        </div>
      </section-content>
    </el-container>
  </MainLayout>
</template>

<style scoped>
.form-field-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.suggestion {
  margin: 10px;
  flex: 1;
}

.suggestion-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.right {
  text-align: end;
}
</style>


<script>
import MainLayout from "../layouts/Main.vue";
import DataTable from "../components/DataTable";
import store from "../store";
import CustomInput from "../components/ui/CustomInput";
import SectionTitle from "../components/ui/SectionTitle";
import SectionContent from "../components/ui/SectionContent";
import CustomCheckbox from "../components/ui/CustomCheckbox";
import {
  PUSH_NOTIFICATIONS,
  MY_PAGINATED_EVENTS,
  SEND_PUSH_NOTIFICATION,
  SEND_CAMPAIGN,
} from "@/graphql";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { subject } from "@casl/ability";


export default {
  components: {
    MainLayout,
    DataTable,
    CustomInput,
    SectionTitle,
    SectionContent,
    CustomCheckbox,
    PulseLoader,
  },
  data() {
    return {
      columnsRecap: [
        {
          prop: "title",
          label: "Title",
          minWidth: "80",
          sortable: true,
        },
        {
          prop: "description",
          label: "Description",
          minWidth: "80",
          sortable: true,
        },
        {
          prop: "total",
          label: "Total",
          sortable: true,
        },
        {
          prop: "queued",
          label: "Queued",
          sortable: true,
        },
        {
          prop: "send",
          label: "Sent",
          sortable: true,
        },
        {
          prop: "failed",
          label: "Failed",
          sortable: true,
        },
        {
          prop: "scheduledTo",
          label: "Scheduled To",
          sortable: true,
        },
        {
          prop: "sentAt",
          label: "Date",
          sortable: true,
        },
      ],
      columnsEvent: [
        {
          prop: "name",
          label: "Event Title",
          minWidth: "80",
          sortable: true,
        },
        {
        prop: "startDate",
        label: "Start Date",
        minWidth: "80",
        sortable: true,
        },
          {
          prop: "endDate",
          label: "End Date",
          minWidth: "80",
          sortable: true,
        },
        {
          prop: "id",
          label: "Event ID",
          minWidth: "80",
          sortable: true,
        },
      ],
      inputVisible: false,
      loadingRecap: true,
      loadingEvents: true,
      selectedEvents: [],
      inputName: "",
      inputBody: "",
      maxChars: 100,
      all: false,
    };
  },
  computed: {},
  created() {},
  beforeRouteEnter(to, from, next) {
    if (
      store.getters.features &&
      store.getters.features.pushNotification &&
      store.getters.ability.can(
        "access",
        subject("push_notifications_page", { ...store.getters.team })
      )
    ) {
      next();
    } else {
      next("/");
    }
  },
  methods: {
    async getDataEvent({ filter, page, query, sortParams, pageSize  }) {
      this.loadingEvents = true;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "createdAt";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_PAGINATED_EVENTS,
          variables: {
            statuses: ["ACTIVE"],
            filter: filter && filter,
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"]
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.error(errors);
            return;
          }
          if (data && data.myPaginatedEvents) {
            this.loadingEvents = false;
            return {
              data: data.myPaginatedEvents.data,
              total: data.myPaginatedEvents.total,
            };
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    async getNotifications({ filter, page, query, sortParams, pageSize }) {
      this.loadingRecap = true;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "createdAt";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: PUSH_NOTIFICATIONS,
          variables: {
            filter: filter && filter,
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"]
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.pushNotifications) {
            console.log(data);
            this.loadingRecap = false;
            return {
              data: data.pushNotifications.data,
              total: data.pushNotifications.total,
            };
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },

    async sendNotification() {
      const context = this;
      if (this.inputName.length > this.maxChars) {
        context.$root.bus.emit("simpleToast", "Maximum character exceeded");
        return;
      }

      if (this.inputName.length <= 0 || this.inputBody.length <= 0) {
        context.$root.bus.emit("simpleToast", "Empty body or content");
        return;
      }

      const h = this.$createElement;
      this.$msgbox({
        message: h("p", null, [
          h("span", null, this.$root.strings.notify._warnBeforeSend),
          h("br"),
          h("br"),
          h("span", null, this.$root.strings.notify._title + ": "),
          h("strong", null, this.inputName),
          h("br"),
          h("span", null, this.$root.strings.notify._body + ": "),
          h("strong", null, this.inputBody),
          h("br"),
        ]),
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = this.$root.strings.notify._sending;
            context.$apollo
              .query({
                fetchPolicy: "no-cache",
                query: SEND_PUSH_NOTIFICATION,
                variables: {
                  input: {
                    title: this.inputName,
                    description: this.inputBody,
                    eventIds: this.selectedEvents,
                    scheduledTo: undefined, //todo
                  },
                },
              })

              .then(({ errors, data }) => {
                if (errors) {
                  console.log(errors);
                  instance.confirmButtonLoading = false;
                  done();
                  return;
                }
                if (data && data.sendPushNotification) {
                  this.$refs.recapTable.getTableData();
                }
                instance.confirmButtonLoading = false;
                done();
                context.$root.bus.emit(
                  "simpleToast",
                  context.$root.strings.directEmailMarketing._campaign_sent
                );
                this.inputVisible = false;
                this.inputBody = "";
                this.inputName = "";
              })
              .catch((error) => {
                console.log(error);
                instance.confirmButtonLoading = false;
                done();
              });
          } else {
            done();
          }
        },
      })
        .then((action) => {})
        .catch((_) => {});
    },
    createNotification() {
      const context = this;
      context.inputVisible = true;
    },

    onSelectionChange(selectedRows) {
      this.selectedEvents = selectedRows;
    },
    toggleAll() {
      this.all = !this.all;
    },
  },
  metaInfo: {
    title: "Penguinpass - Push Notifications",
    bodyAttrs: {
      class: "dashboard",
    },
  },
};
</script>
