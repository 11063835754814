<template>
  <footer class="site-footer">
    <div class="text-center">
      {{ `© ${new Date().getFullYear()} All rights reserved. Penguinpass Srl P.IVA 08866100962 - Milan, Italy` }}
      <span class="privacypolicy">
        &nbsp;-&nbsp;<a
          target="_blank"
          :href="strings.termsAndConditions._privacypolicyURL"
          title="privacy">
          <span>{{ strings.sidebar._privacypolicy }}</span>
        </a>
      </span>
      <span class="termsandcond">
        &nbsp;-&nbsp;<a
          target="_blank"
          :href="strings.termsAndConditions._linkTerms"
          title="terms">
          <span>{{ strings.sidebar._terms }}</span>
        </a>
      </span>
    </div>
  </footer>
</template>
<style>
    .site-footer a{
        color: white;
        text-transform: lowercase;
        text-decoration: underline;
    }
    .site-footer a:hover{
        color: white;
        text-transform: lowercase;
    }
</style>
<script>
export default {
    components: {},
    data (){
        return {
            componentName:'footer',
            strings: {
                /*
                    sidebar : {
                        _dashboard: "Dashboard",
                        _newevent: "Nuovo Evento",
                        _downloadpenguinpass: "Scarica Penguinpass",
                        _privacypolicy: "Privacy Policy",
                        _contacts: "Contatti",
                        _allevents: "Archivio Eventi"
                    }
                    */
            }

        };
    },
    created (){
        const context = this;
        this.strings = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.strings = context.$root.strings;
        });
    },
    mounted () {
    },
    methods: {},
    events: {},
    ready (){}
};
</script>
