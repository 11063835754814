var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "direct-email-marketing-component-container" },
    [
      _c(
        "section-content",
        {
          attrs: {
            "has-icon": true,
            "icon-progress": _vm.steps.campaign.progress,
            "icon-text": _vm.steps.campaign.progressLabel,
          },
        },
        [
          _vm.type === "sms"
            ? _c("section-title", {
                attrs: {
                  title: _vm.$root.strings.textMarketing._title,
                  subtitle: _vm.$root.strings.textMarketing._subtitle,
                },
              })
            : _c("section-title", {
                attrs: {
                  title: _vm.$root.strings.textMarketing._titleWA,
                  subtitle: _vm.$root.strings.textMarketing._subtitleWA,
                },
              }),
          _vm._v(" "),
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.loading === true
                ? _c("PulseLoader", {
                    attrs: {
                      loading: _vm.loading,
                      color: "#f7a30c",
                      size: "15px",
                    },
                  })
                : !_vm.campaignCreated === true && _vm.loading === false
                ? _c(
                    "button",
                    {
                      staticClass: "button save",
                      staticStyle: { width: "100% !important" },
                      on: { click: _vm.createCampaign },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$root.strings.textMarketing._create) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm.loadingEffectshow
                ? _c("LoadingCover", {
                    attrs: { "loading-cover-msg": _vm.loadingMessage },
                  })
                : _c("div", {}, [
                    _vm.type === "sms"
                      ? _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "form-field-row zero-margin-row",
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-label",
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$root.strings.textMarketing
                                              ._header
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-field",
                                    attrs: { span: 21 },
                                  },
                                  [
                                    !_vm.templateCreated
                                      ? _c("custom-input", {
                                          attrs: {
                                            placeholder:
                                              _vm.$root.strings.textMarketing
                                                ._header,
                                          },
                                          model: {
                                            value: _vm.title,
                                            callback: function ($$v) {
                                              _vm.title =
                                                typeof $$v === "string"
                                                  ? $$v.trim()
                                                  : $$v
                                            },
                                            expression: "title",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.title) +
                                              "\n              "
                                          ),
                                        ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                staticClass: "form-field-row zero-margin-row",
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-label",
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$root.strings.textMarketing
                                              ._body
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-field",
                                    attrs: { span: 21 },
                                  },
                                  [
                                    !_vm.templateCreated
                                      ? _c("textarea", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model:value",
                                              value: _vm.body,
                                              expression: "body",
                                              arg: "value",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            rows: "5",
                                            placeholder:
                                              _vm.$root.strings.textMarketing
                                                ._body,
                                          },
                                          domProps: { value: _vm.body },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.body = $event.target.value
                                            },
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.body) +
                                              "\n              "
                                          ),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "form-field-row zero-margin-row",
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-label",
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$root.strings.textMarketing
                                              ._header
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-field",
                                    attrs: { span: 21 },
                                  },
                                  [_c("span", [_vm._v(" SAVE THE DATE ")])]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "el-row",
                              {
                                staticClass: "form-field-row zero-margin-row",
                                attrs: { gutter: 20 },
                              },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-label",
                                    attrs: { span: 3 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$root.strings.textMarketing
                                              ._body
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "form-field-col-field",
                                    attrs: { span: 21 },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                Ciao, {1} ha il piacere diinvitarti all'evento {2} che si terrà il giorno {3} alle\n                ore {4} in {5}."
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                Clicca qui per confermare\n              "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _vm._v(" "),
                    _vm.type === "sms"
                      ? _c("div", [
                          !_vm.templateCreated
                            ? _c(
                                "button",
                                {
                                  staticClass: "button save",
                                  staticStyle: { width: "100% !important" },
                                  on: {
                                    click: function () {
                                      _vm.onChanges(false)
                                      _vm.createTemplate({
                                        eventId: _vm.eventId,
                                        title: _vm.title,
                                        body: _vm.body,
                                      })
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.textMarketing
                                          ._sendToApprove
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _c(
                                "button",
                                {
                                  staticClass: "button save",
                                  staticStyle: { width: "100% !important" },
                                  on: {
                                    click: function () {
                                      _vm.templateCreated = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.textMarketing._edit
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button save",
                        staticStyle: { width: "100% !important" },
                        on: {
                          click: function () {
                            _vm.swapType()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.type === "sms"
                                ? "Swap to Whatsapp"
                                : "Swap to SMS"
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section-content",
        {
          attrs: {
            "has-icon": true,
            "icon-progress": _vm.steps.confirmationEmail.progress,
            "icon-text": _vm.steps.confirmationEmail.progressLabel,
          },
        },
        [
          _c("section-title", {
            attrs: {
              title: _vm.$root.strings.textMarketing._additionalSettingsTitle,
            },
          }),
          _vm._v(" "),
          _vm.loading === false
            ? _c(
                "section-content",
                [
                  _vm.isPrivate
                    ? _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.textMarketing._setRsvp
                                    ) +
                                    ": "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              staticStyle: {
                                "align-self": "center",
                                "vertical-align": "middle",
                                "text-align": "center",
                              },
                              attrs: { span: 1 },
                            },
                            [
                              _vm.type === "sms"
                                ? _c("custom-checkbox", {
                                    on: {
                                      change: function () {
                                        _vm.updateRsvpOptions()
                                      },
                                    },
                                    model: {
                                      value: _vm.withRsvp,
                                      callback: function ($$v) {
                                        _vm.withRsvp = $$v
                                      },
                                      expression: "withRsvp",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "p",
                                { staticStyle: { margin: "0", padding: "0" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.textMarketing
                                          ._setRsvp_explain
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPrivate && _vm.withRsvp
                    ? _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._setpartnermax
                                    ) +
                                    ": "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              staticStyle: {
                                "align-self": "center",
                                "vertical-align": "middle",
                                "text-align": "center",
                              },
                              attrs: { span: 1 },
                            },
                            [
                              _c("custom-checkbox", {
                                on: {
                                  change: function () {
                                    return _vm.updateRsvpOptions()
                                  },
                                },
                                model: {
                                  value: _vm.setMaxAcquaintanceOnJoin,
                                  callback: function ($$v) {
                                    _vm.setMaxAcquaintanceOnJoin = $$v
                                  },
                                  expression: "setMaxAcquaintanceOnJoin",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "p",
                                { staticStyle: { margin: "0", padding: "0" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.directEmailMarketing
                                          ._setpartnermax_explain
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPrivate && _vm.withRsvp
                    ? _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._setnote
                                    ) +
                                    ": "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              staticStyle: {
                                "align-self": "center",
                                "vertical-align": "middle",
                                "text-align": "center",
                              },
                              attrs: { span: 1 },
                            },
                            [
                              _c("custom-checkbox", {
                                on: {
                                  change: function () {
                                    return _vm.updateRsvpOptions()
                                  },
                                },
                                model: {
                                  value: _vm.setAcquaintanceInfoOnJoin,
                                  callback: function ($$v) {
                                    _vm.setAcquaintanceInfoOnJoin = $$v
                                  },
                                  expression: "setAcquaintanceInfoOnJoin",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "p",
                                { staticStyle: { margin: "0", padding: "0" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.directEmailMarketing
                                          ._setnote_explain
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isPrivate && _vm.withRsvp
                    ? _c(
                        "el-row",
                        {
                          staticClass: "form-field-row zero-margin-row",
                          staticStyle: { "margin-bottom": "30px" },
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-label",
                              attrs: { span: 3 },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$root.strings.textMarketing
                                        ._setConfirmation
                                    ) +
                                    ": "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              staticStyle: {
                                "align-self": "center",
                                "vertical-align": "middle",
                                "text-align": "center",
                              },
                              attrs: { span: 1 },
                            },
                            [
                              _c("custom-checkbox", {
                                on: {
                                  change: function () {
                                    return _vm.updateRsvpOptions()
                                  },
                                },
                                model: {
                                  value: _vm.withConfirmation,
                                  callback: function ($$v) {
                                    _vm.withConfirmation = $$v
                                  },
                                  expression: "withConfirmation",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "form-field-col-field",
                              attrs: { span: 20 },
                            },
                            [
                              _c(
                                "p",
                                { staticStyle: { margin: "0", padding: "0" } },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$root.strings.textMarketing
                                          ._setConfirmation_explain
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.campaignCreated === true && _vm.loading === false
        ? _c(
            "section-content",
            {
              attrs: {
                "has-icon": true,
                "icon-progress": _vm.steps.audience.progress,
                "icon-text": _vm.steps.audience.progressLabel,
              },
            },
            [
              _c("section-title", {
                attrs: {
                  title: _vm.$root.strings.directEmailMarketing._audience_title,
                  subtitle: _vm.$root.strings.textMarketing._audience_subtitle,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group-manager-members-list" },
                [
                  _c("i", {
                    staticClass: "fa fa-users",
                    staticStyle: {
                      "margin-right": "15px",
                      "font-size": "20px",
                      "margin-bottom": "20px",
                    },
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "margin-bottom": "20px",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$root.strings.datagrid._selected) +
                          ": " +
                          _vm._s(_vm.audienceSize) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.campaignCreated
                    ? _c(
                        "data-table",
                        {
                          ref: "table",
                          attrs: {
                            "get-data": _vm.getData,
                            columns: _vm.columns,
                            "selection-enabled":
                              _vm.audienceOption === "CUSTOM",
                            "page-size": 15,
                            "on-selection-change": _vm.onSelectionChange,
                            "bulk-options": ["clearSelection"],
                            "on-bulk-action": _vm.onBulkAction,
                            "bulk-option-placeholder": "_options",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "memberName",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(row.member.name) +
                                        "\n        "
                                    ),
                                  ])
                                },
                              },
                              {
                                key: "memberSurname",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(row.member.surname) +
                                        "\n        "
                                    ),
                                  ])
                                },
                              },
                              {
                                key: "memberTelephone",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n          " +
                                        _vm._s(row.member.telephone) +
                                        "\n        "
                                    ),
                                  ])
                                },
                              },
                              {
                                key: "textDeliveryStatus",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    [
                                      "SEND",
                                      "DELIVERED",
                                      "OPENED",
                                      "CLICKED",
                                      "BOUNCED",
                                      "REJECTED",
                                      "COMPLAINED",
                                    ].indexOf(row.textDeliveryStatus) !== -1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content:
                                                    row.textDeliveryStatus
                                                      ? row.textDeliveryStatus ===
                                                        "COMPLAINED"
                                                        ? "unsubscribed"
                                                        : row.textDeliveryStatus.toLowerCase()
                                                      : "-",
                                                  placement: "bottom",
                                                },
                                              },
                                              [
                                                _c(
                                                  "font-awesome-layers",
                                                  { staticClass: "fa-lg" },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["fas", "circle"],
                                                        transform: "grow-6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("font-awesome-icon", {
                                                      style: { color: "black" },
                                                      attrs: {
                                                        icon: _vm
                                                          .deliveryStatusIcons[
                                                          row.textDeliveryStatus
                                                        ],
                                                        transform: "shrink-4",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                            },
                                          },
                                          [_vm._v("-")]
                                        ),
                                  ])
                                },
                              },
                              {
                                key: "status",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "p-table-cell",
                                        staticStyle: {
                                          "text-align": "left !important",
                                        },
                                      },
                                      [
                                        _c(
                                          "font-awesome-layers",
                                          {
                                            staticClass: "fa-lg",
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              style:
                                                _vm.statusStyle[row.status],
                                              attrs: {
                                                icon: ["fas", "circle"],
                                                transform: "grow-6",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              style: { color: "white" },
                                              attrs: {
                                                icon: _vm.statusIcons[
                                                  row.status
                                                ],
                                                transform: "shrink-4",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.statusDescription[row.status]
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ])
                                },
                              },
                            ],
                            null,
                            false,
                            1434374542
                          ),
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "options" }, slot: "options" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn btn-gray full-column-button",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return (function () {
                                        return (_vm.filtersVisible =
                                          !_vm.filtersVisible)
                                      })($event)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: {
                                      icon: "filter",
                                      transform: "grow-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              attrs: { slot: "filterView" },
                              slot: "filterView",
                            },
                            [
                              _c("audience-option-group", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.filtersVisible,
                                    expression: "filtersVisible",
                                  },
                                ],
                                attrs: {
                                  "audience-options": _vm.audienceOptions,
                                  "event-id": _vm.eventId,
                                  "on-audience-option-changed":
                                    _vm.onAudienceOptionChanged,
                                  "initial-value": _vm.initialOption,
                                  "hide-counter": true,
                                  "stop-calculate-eligible-recipient-count": true,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { staticClass: "zero-margin-row" },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _vm.hasChanges
                      ? _c(
                          "a",
                          {
                            staticClass: "btn gray-btn",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.updateCampaign($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$root.strings.directEmailMarketing
                                    ._save_changes
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "btn gray-btn",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.sendCampaign($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$root.strings.directEmailMarketing._send
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "section-content",
        { attrs: { "has-icon": true } },
        [
          _c("section-title", {
            attrs: {
              title: _vm.$root.strings.textMarketing._recap,
              subtitle: _vm.$root.strings.textMarketing._recapsubtitle,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group-manager-members-list" },
            [
              _c("data-table", {
                ref: "campaignsTable",
                attrs: {
                  "get-data": _vm.getPastCampaigns,
                  columns: _vm.campaignGridColumns,
                  "selection-enabled": false,
                  "filter-enabled": false,
                  "no-data-text":
                    _vm.$root.strings.directEmailMarketing._noCampaigns,
                },
                scopedSlots: _vm._u([
                  {
                    key: "refresh-header",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "a",
                            {
                              staticStyle: {
                                "background-color": "transparent",
                                padding: "0",
                                margin: "0",
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.updateCampaignTableStatistics(
                                    $event
                                  )
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: {
                                  icon: "sync",
                                  spin: _vm.refreshingPastCampaigns,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    },
                  },
                  {
                    key: "refresh",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          row.status === "QUEUED"
                            ? _c("font-awesome-icon", {
                                attrs: { icon: "sync", spin: "" },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    },
                  },
                  {
                    key: "sentAt",
                    fn: function (ref) {
                      var row = ref.row
                      return _c("span", {}, [
                        row.status === "QUEUED"
                          ? _c(
                              "a",
                              {
                                staticClass: "action_link",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return (function () {
                                      return _vm.onCancelCampaign(row)
                                    })($event)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "times" },
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$root.strings.directEmailMarketing
                                        ._cancel_campaign
                                    ) +
                                    "\n          "
                                ),
                              ],
                              1
                            )
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    row.sentAt
                                      ? new Date(row.sentAt).format()
                                      : " - "
                                  ) +
                                  "\n          "
                              ),
                            ]),
                      ])
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }