<template xmlns:v-model="http://www.w3.org/1999/xhtml">
  <div class="description-component">
    <transition
      name="component-fade-fast"
      mode="out-in"
      appear>
      <!--<loading-cover v-if="loadingEffectshow" v-bind:loading-cover-msg="string.description._loadingMessage" class="higherPosition"></loading-cover>-->
      <div
        v-if="loadingEffectshow"
        id="loadingCover">
        <div class="loadingCoverMessage">
          {{ string.description._loadingMessage }}
        </div>
      </div>
    </transition>

    <!-- -->
    <div
      class="description-component-section-container"
      style="margin-bottom:20px;">
      <div
        v-if="showHiddenForm"
        class="hiddenForm">
        <div class="">
          <section-title :title="string.description._description" />
          <div class="row form-control-description">
            <div class="col-sm-12 col-md-12">
              <div v-if="this.event.descriptionIt!=''&& this.eventDescription==''">{{ event.descriptionIt }}</div>
              <textarea
                v-model:value="eventDescription"
                class="form-control"
                rows="5"
                :placeholder="string.description._descriptionFieldPlaceHolder">
                                </textarea>
            </div>
          </div>

          <div class="row form-control-event-pad form-control-event-pad-button">
            <div class="col-sm-6 col-md-6">
              <a
                class="butn delete"
                title=""
                @click="cancel">
                {{ string.description._clear }}
              </a>
            </div>
            <div class="col-sm-6 col-md-6">
              <a
                class="butn save"
                title=""
                @click="submit">
                {{ string.description._save }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <!-- -->

    <!-- -->
    <div class="description-component-section-container">
      <div
        v-if="showHiddenForm"
        class="hiddenForm">
        <div class="">
          <section-title :title="string.description._alternativeLanguageVersion" :subtitle="string.description._alternativeLanguageVersionSmall" />
          <div class="row form-control-description">
            <div class="col-sm-12 col-md-12">
              <div v-if="this.event.descriptionEn!=''&& this.eventDescription==''">{{ event.descriptionIt }}</div>
              <textarea
                v-model:value="eventDescription_En"
                class="form-control"
                rows="5"
                :placeholder="string.description._descriptionFieldPlaceHolder">
              </textarea>
            </div>
          </div>

          <div class="row form-control-event-pad form-control-event-pad-button">
            <div class="col-sm-6 col-md-6">
              <a
                class="butn delete"
                title=""
                @click="cancel">
                {{ string.description._clear }}
              </a>
            </div>
            <div class="col-sm-6 col-md-6">
              <a
                class="butn save"
                title=""
                @click="submit">
                {{ string.description._save }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <div
      v-if="!showHiddenForm"
      class="testo">
      <section-title :title="string.description._description" />
      {{ eventDescription }}
    </div>

    <div
      v-if="!showHiddenForm"
      class="testo_en">
      <section-title :title="string.description._alternativeLanguageVersion" />
      {{ eventDescription_En }}
    </div>
    <!-- -->
    <div
      v-if="!showHiddenForm"
      class="updateButtonContainer"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
      @mousedown="mouseDown">
      <transition
        name="component-fade-fast"
        mode="out-in"
        appear>
        <button
          v-if="showUpdateButton"
          type="button"
          class="btn btn-labeled btn-info">
          <span class="btn-label">
            <i
                    aria-hidden="true"
                    class="fa fa-pencil"/>
          </span>
          Update
        </button>
      </transition>
    </div>
  </div>
</template>
<style scoped>

    .testo_en{
        margin-top: 80px;
    }

    .description-component-section-container{
        position: relative;
    }

     .hiddenForm{
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
    }

    .form-control-event-pad-button{
        margin-top: 30px;
    }

    .form-control-description input{
        border-radius: 0;
        border-color: #333;
    }

    .updateButtonContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 3;
    }

    .higherPosition{
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    #loadingCover {
        z-index: 1;
        color: #fff !important;
        background-color: rgba(255, 255, 255, 0.6);
    }

    .updateButtonContainer button{
        position: absolute;
        bottom: 25px;
        right: 25px;
    }

    .updateButtonContainer button:hover{
        background-color: #fff;
        color: #333;
    }

    .description-component{
        margin-top: 50px;
        position: relative;
    }

    .butn {
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
        padding: 8px;
        color: #fff;
        text-transform: uppercase;
        background-color: #333333;
        display: block;
        text-align: center;
        cursor: pointer !important;
    }
    .butn:hover {
        color: #333333 !important;
        background-color: #dadada;
    }

    .component-fade-fast-enter-active, .component-fade-fast-leave-active {
        transition: opacity .3s ease;
    }
    .component-fade-fast-enter, .component-fade-fast-leave-active {
        opacity: 0;
    }
    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .9s ease;
    }
    .component-fade-enter, .component-fade-leave-active {
        opacity: 0;
    }
</style>
<script>
import userdata from './../userdata.js';
import { UPDATE_EVENT } from '@/graphql';
import SectionTitle from './ui/SectionTitle';
export default {
    components:{
      SectionTitle
    },
    props: {
        eventId: String,
        event: Object,
        description: String,
        descriptionEn: String,
    },
    data (){
        return {
            eventDescription : '',
            eventDescription_En: '',
            showUpdateButton : false,
            showHiddenForm : false,
            loadingEffectshow : false,
            eventObject : {},
            string: {}
        };
    },
    watch: {
        event: function (){
            this.eventObject = this.event;
            this.eventDescription_En = this.event.descriptionEn;
            this.eventDescription = this.event.descriptionIt;
        }
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    methods: {
        setValueToProp : function (){
            this.eventDescription = this.description;
            this.eventDescription_En = this.description_en;
        },
        cancel:function (){
            this.showHiddenForm = false;
        },
        submit:function (){
            const context = this;
            context.loadingEffectshow = true;
        this.$apollo
                .mutate({
                  mutation: UPDATE_EVENT,
                  variables: {
                    eventId: this.event.id,
                    updateEventInput: {
                      descriptionIt:this.eventDescription,
                      descriptionEn:this.eventDescription_En,
                    }
                  }
                })
                .then(value => {
                  if(value.errors){
                    console.log('error ' + value.errors);
                    context.$root.bus.emit('simpleToast', this.string.eventPad._creationFailed);
                  }else {
                    if(value.data && value.data.updateEvent){
                      context.$root.bus.emit('simpleToast', context.string.eventPad._successmessage);
                      //update details event string
                      this.event.descriptionIt=this.eventDescription;
                      this.event.descriptionEn=this.eventDescription_En;
                      this.$emit('event', this.eventDescription);
                      //this.$emit('event', this.eventDescription_En);
                      //
                      context.showHiddenForm = false;
                      context.loadingEffectshow = false;
                    }
                  }
                })
                .catch(reason => {
                  console.log(reason.message);
                  context.$root.bus.emit('simpleToast', this.string.eventPad._creationFailed);
                });
        },
        mouseDown: function (){
            this.update();
        },
        mouseOver: function () {
            this.showUpdateButton = true;
        },
        mouseOut: function () {
            this.showUpdateButton = false;
        },
        update : function (){
            this.showHiddenForm = true;
            this.showUpdateButton = false;
        },
    }
};
</script>
