<template>
  <div class="user-image" @mouseover="mouseOver" @mouseout="mouseOut" @mousedown="mouseDown">
    <transition name="component-fade-fast" mode="out-in" appear>
      <LoadingCover
        v-if="loadingEffectshow"
        :loading-cover-msg="string.coverImage._loadingMessage"
      />
    </transition>
    <div class="updateButtonContainer" v-if="!readOnly">
      <transition name="component-fade-fast" mode="out-in" appear>
        <button
          v-if="showUpdateButton === true"
          type="button"
          class="btn btn-labeled btn-info"
          style="float:right !important;"
        >
          <span class="btn-label">
            <i aria-hidden="true" class="fa fa-pencil" />
          </span>
          Update
        </button>
      </transition>
      <transition name="component-fade-fast" mode="out-in" appear>
        <div v-if="showUpdateButton === true" class="updateButtonContainerBg">
          <div class="imgNotices">{{ string.coverImage._imgNotices }}</div>
        </div>
      </transition>
    </div>
    <img  v-bind:src="srcImage" class="img-responsive thumbnail cover-image-constraints" alt="Event cover Image"/>
    <input id="fileinput" ref="fileinput" type="file" accept="image/*" @change="onFileChange" />
  </div>
</template>
<style scoped>
  .cover-image-constraints {
      width: 100%;
      height: 350px;
  }
.imgNotices {
  position: absolute;
  top: 40%;
  left: 30px;
  right: 30px;
  border: 1px solid #fff;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.updateButtonContainer {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

.updateButtonContainerBg {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.user-image {
  color: #333 !important;
  background-color: #333333 !important;
}

.user-image button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  color: #fff;
}

.user-image button:hover {
  background-color: #fff;
  color: #333;
}

.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import { UPDATE_EVENT } from '@/graphql';


export default {
  components: {
    LoadingCover
  },
  props: {
    eventId: String,
    src: String,
    readOnly: Boolean
  },
  data() {
    return {
      string: {},
      showUpdateButton: false,
      srcImage: "",
      fileToSelect: true,
      fileList: "",
      fileListName: "",
      loadingEffectshow: false,
      showImage: true
    };
  },
  watch: {
    src: function() {
      if (this.src != null) {
        this.srcImage = this.src;
      } else {
        this.srcImage = this.getDefaultImgUrl();
      }
    },
    loadingEffectshow: function() {
      this.showImage = !this.loadingEffectshow;
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {},
  methods: {
    getDefaultImgUrl: function()  {
      return require('../assets/img/event_default.png');
    },
    mouseDown: function() {
      this.open();
    },
    mouseOver: function() {
      this.showUpdateButton = true;
    },
    mouseOut: function() {
      this.showUpdateButton = false;
    },
    open: function() {
      if(this.readOnly) {
        return;
      }
      this.$refs.fileinput.click();
    },
    onFileChange: function(e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      if(files[0].size>1000000){
        alert(this.string.coverImage._imgSizeExceed)
      }
      else{
        this.fileList = files[0];
        this.fileListName = files[0].name;
        this.submit(); 
      }
      console.log(files);
      
    },
    emptyFields: function() {
      this.fileToSelect = true;
      document.getElementById("fileinput").value = "";
    },
    updateImage: function(path) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: { eventId: context.eventId, updateEventInput: {image: path} }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.srcImage = path;
            context.loadingEffectshow = false;
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.loadingEffectshow = false;
        });
    },
    submit: function() {
      const context = this;
      context.loadingEffectshow = true;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      if (context.fileList !== null) {
        data.append("fileToUpload", context.fileList);
      }
      data.append("eventId", context.eventId);
      return this.$root.axios
        .post("/upload/cover", data, config)
        .then(response => {
          console.log(response);
          context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
          context.emptyFields();
          context.updateImage(response.data.filepath);
        })
        .catch(error => {
          console.log(error.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed + " " + error.message );
          context.emptyFields();
        });
    }
  }
};
</script>
