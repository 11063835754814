<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Quick Start
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/en-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/en-quickstart">
              Quick Start
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to create a new event
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to upload a guest list 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to activate RSVP buttons
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              ​​How to generate a QR code when a guest confirms
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to personalize invites with the name of the guest
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to create a personalized landing page
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to create a new event
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            ​​From the Dashboard homepage, by clicking on the icon with the "+" symbol, you can create a new event.<br>
            You will need to choose whether it is a "private" or a "public" event. Regardless of the choice, to complete 
            the creation you must fill in the fields: "Category, Name, Start Date, End Date and Address", optionally the 
            "Poster and Description" fields.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to upload a guest list 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            After you have entered the “guest list” section, it’s possible to add guests to the list in 2 ways:<br>
            <b>MANUALLY </b> inserting guest by guest through the “+ add manually” button, while filling the indicated 
            fields: “Name, Last name, Email, and Phone number”. <br>
            <b>AUTOMATICALLY  </b> uploading the filled-in excel template through the “upload list” button.<br><br>
            The excel file needs to be organized according to particular characteristics, it is necessary to use the 
            “Template Import File XLSX” under the button “Choose”. Once downloaded the template, it will just be a 
            matter of following the instructions present on the first 6 lines of the excel spreadsheet:<br>
            Do not delete the first 6 lines<br>
            Delete the template fake guest “Mario Rossi” (there as an example);<br>
            Fill in the fields for your guests paying attention to the specific fields and columns<br>
            The excel file containing your guest list needs to be selected through the “Choose” button 
            and afterward to proceed to the upload, press the “Upload” button<br>
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to activate RSVP buttons
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            The rsvp and decline buttons allow your guests to accept or decline your invitations, watch the
            video
            to learn how these components are made.
          </p>
          <!--<video width="100%" controls>
            <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4"
                    type="video/mp4">
            Your browser does not support the video tag.
          </video>-->
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            ​​How to generate a QR code when a guest confirms
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            If you want to manage guest access to the event through the QR code, you will need to send a confirmation email containing the QR Code. 
            First, start by creating a confirmation email. To create a QR code, you will simply need to add a “dynamic content” module from the 
            email builder, along with everything else you would like to include in the confirmation email (for example information and event agenda).
            This way, the guests will receive a personalized email containing the QR Code, after 5-10 minutes from the moment of confirmation. 
            <br><br>
            <b>HOW TO DISPLAY THE QR CODE OR OTHER ELEMENTS IN THE CENTER OF THE EMAIL</b> <br>
            When inserting the "dynamic content" in the empty module in Email Builder, if you use a "single module" (the first in the modules section) 
            the QR code will be displayed on the left in the email. If you want it to be displayed in the center instead, you can use the module that is 
            divided into three sections (the seventh in the list of modules) and insert the dynamic content in the central one.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to personalize invites with the name of the guest
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            When generating an email campaign it is essential to make your guests feel unique and special, 
            so it is necessary to ensure that each of them receives a personalized email with their name and surname.<br>
            It’s very simple to create an email with the EMAIL BUILDER which reads the name of the guest. You will 
            simply need to enter a text module from the toolbar and when editing the text module, just click on “dynamic 
            fields” and select whether you would like to enter a first name, the last name, or both. Once the email is sent,
            the dynamic field will simply turn in each guest’s name.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to create a personalized landing page
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>To create a custom landing page you will need to go to the virtual room section..
            Here the Virtual room section that will allow you to create a landing page dedicated to your event. First 
            click on “ activate your Penguin Pass room”. Then you can choose whether to create a new landing page from 
            scratch or using a template. Once created, templates can be reused on other events, however if it is your 
            first event, it is necessary to select  "create new".
            The builder is very similar to what you find in the mail campaign section. To create the page just drag 
            the available contents from the panel on the right. We recommend using “rows” and dividers to have your 
            content in an orderly fashion.
            If you have purchased virtual meeting or webinar rooms you will find them in the dynamic contents
            once your room is ready, click on the "save" button at the top right.
            At this point, if you have not entered virtual room modules, just click on save settings and publish the 
            page. After saving the settings a table with the url address of your page appears.
            </p>
          <!--<video width="100%" controls>
            <source
              src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4"
              type="video/mp4">
            Your browser does not support the video tag.
          </video>-->
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskQuickStartEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
