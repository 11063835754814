<template>
  <svg
    :id="animationId"
    :viewBox="`0 0 ${UNITS} ${UNITS}`"
    :height="size || '100%'"
    :width="size || '100%'"
  >
    <g
      :transform="`rotate(${rotate},${UNITS / 2},${UNITS / 2}) ${
        reverse ? 'scale(1,-1) translate(0, -' + UNITS + ')' : ''
      }`"
    >
      <circle
        :cx="UNITS / 2"
        :cy="UNITS / 2"
        :r="getRadius()"
        :stroke="stroke"
        :stroke-width="getStrokeWidth(strokeWidth)"
        fill="none"
        :stroke-dasharray="getLengths()"
      />
      <path
        class="progress-path"
        :d="describeArc(UNITS / 2, UNITS / 2, getRadius(), 0, activeEnd())"
        fill="none"
        :stroke="activeStroke"
        :stroke-width="getStrokeWidth(activeWidth)"
        :stroke-dasharray="getLengths()"
      />
    </g>
    <slot name="icon" />
    <text
      v-if="!$slots.icon"
      style="font-size: 8rem"
      fill="currentColor"
      x="50%"
      y="50%"
      text-anchor="middle"
      dominant-baseline="middle"
    >
      {{ text }}
    </text>
  </svg>
</template>

<script>
import { animateCSS } from "@/utils";
export default {
  props: {
    size: {
      type: String,
      default: "40px",
    },
    text: {
      type: String,
      default: "",
    },
    progress: {
      type: Number,
      default: 30,
    },
    dashCount: {
      type: Number,
      default: 100,
    },
    strokeWidth: {
      type: Number,
      default: 4,
    },
    activeWidth: {
      type: Number,
      default: 12,
    },
    stroke: {
      type: String,
      default: "rgba(255, 255, 255, 0.2)",
    },
    activeStroke: {
      type: String,
      default: "#FF9B00",
    },
    dashSpacing: {
      type: Number,
      default: 0.01,
    },
    rotate: {
      type: Number,
      default: -90,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      animationId: "animatable-" + Math.floor(Math.random() * 100000).toString(),
    };
  },
  watch: {
    progress: {
      immediate: true,
      handler: function (newVal, oldVal) {
          if (newVal !== oldVal && (newVal === 100 || oldVal === 100)) {
              if (newVal === 100) {
                animateCSS("#" + this.animationId, "jello");
              } else {
                animateCSS("#" + this.animationId, "jackInTheBox");
              }
          }
      },
    },
  },
  beforeCreate() {
    // Arbitrary dimensions of SVG to set up user-space units
    this.UNITS = 200;
  },
  methods: {
    // Determine the 'end' angle of the path for the active dashes in degrees.
    activeEnd() {
      if (this.progress == 0) {
        return 0;
      }
      return 360 * (this.progress * this.dashPerc() + (this.progress - 1) * this.spacePerc());
    },
    // Stroke is provided as a percentage of the radius, translate into user space units
    getStrokeWidth(stroke) {
      return (stroke * this.UNITS) / 200;
    },

    // An array of the length of the dash & the length of the space between dashes
    getLengths() {
      return [
        2 * Math.PI * this.getRadius() * this.dashPerc(),
        2 * Math.PI * this.getRadius() * this.spacePerc(),
      ];
    },
    // The space beween dashes as a percentage of the total length
    spacePerc() {
      return this.dashSpacing / this.dashCount;
    },
    // The length of a dash as a percentage of the total length
    dashPerc() {
      return (1 - this.dashSpacing) / this.dashCount;
    },
    // Radius of the circle arc
    getRadius() {
      return (
        (this.UNITS -
          Math.max(this.getStrokeWidth(this.strokeWidth), this.getStrokeWidth(this.activeWidth))) /
        2
      );
    },

    // SVG path definition requires points in cartesian space
    polarToCartesian(cx, cy, radius, degrees) {
      const radians = (degrees * Math.PI) / 180.0;
      return {
        x: cx + radius * Math.cos(radians),
        y: cy + radius * Math.sin(radians),
      };
    },
    // Path definition for circular arc
    describeArc(cx, cy, radius, startDegrees, endDegrees) {
      const start = this.polarToCartesian(cx, cy, radius, startDegrees);
      const end = this.polarToCartesian(cx, cy, radius, endDegrees);
      const largeArc = Math.abs(endDegrees - startDegrees) < 180 ? 0 : 1;
      const sweep = endDegrees < startDegrees ? 0 : 1;
      return `M${start.x} ${start.y} A${radius} ${radius} 0 ${largeArc} ${sweep} ${end.x} ${end.y}`;
    },
  },
};
</script>

<style scoped>
path.progress-path {
  transition: stroke-dashoffset 0.35s;
}
circle.progress-circle {
  /* transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%; */
}
.progress-path {
  /* stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 1s ease-in alternate; */
}
</style>