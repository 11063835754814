<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.promoteEvent._title }}
    </h4>
    <div class="form-container accordion-content">
      <small>{{ string.promoteEvent._subtitle }}</small>
      <!-- Disable Classes -->
      <div
        v-if="componentDisabled"
        class="disable-layer"
        @click="disableLayerClicked">
&nbsp;
      </div>
      <div
        v-if="inAction"
        class="inaction-layer">
&nbsp;
      </div>

      <div class="inMailToggleSelection">
        <div class="form-section squaredThree">
          <input
            id="promote"
            v-model="inputValue"
            type="checkbox"
            name="promote">
          <label
            for="promote"
            class="promote-event"></label>
        </div>
        <div class="textLabel">
          {{ string.promoteEvent._label }}
        </div>
      </div>

      <div class="form-section">
        <button
          type="submit"
          class="save"
          @click="submit">
          {{ string.promoteEvent._submit }}
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
    /** Disable Classes */

    .form-container{
        position: relative;
    }

    .disable-layer{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: rgba(255, 255, 255, 0.8);*/
        background-color: #00000000;
    }

    /** /Disable Classes */

    /** In Action Layer */
    .inaction-layer{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
    }
    /** In Action Layer */

    .form-section-title{
        border-bottom: solid 1px #dddddd;
        color: #f7a30c;
        margin-bottom: 20px;
        padding: 20px 0;
        text-transform: uppercase;
    }

    .form-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }
</style>
<script>
import userdata from './../../../../userdata.js';

export default {
    components:{

    },
    props: {
        eventId: Number,
        event: Object,
    },
    data (){
        return {
            componentName : 'promoteEvent',
            string: {
                /*
                        promoteEvent:{
                            _title: "Promozione evento",
                            _subtitle: "Promuovi il tuo evento e pubblicalo sempre in cima di tutti gli eventi disponibili in ogni luogo",
                            _submit: "Salva impostazione",
                            _label: "Promuovi questo evento",
                            _updateDone: "aggiornamento eseguito",
                            _error: "errore non gestito"
                        }
                        */
            },
            inAction: false,
            componentDisabled: true, /** Disable Component */
            inputValue: false,
        };
    },
    watch: {
        event: function (){
            try {
                if (this.event.promote == 1){
                    this.inputValue = true;
                } else {
                    this.inputValue = false;
                }
            } catch (e){
                // Nothing
                this.inputValue = false;
            }
        }
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    mounted (){
        this.isComponentDisabled(); /** Disable Component */
    },
    methods: {
        /** Disable Component */
        isComponentDisabled : function (){
            if (this.$root.userPermissions.indexOf('canPromoteEvent') >= 0){
                this.componentDisabled = false;
            }
        },
        disableLayerClicked : function (){
            this.$root.bus.emit('notAuthorizedOptionToast');  /** Emit di al gestore globale */
        },
        /** Disable Component */
        submit : function (){
            const context = this;
            this.inAction = true;

            return this.$root.axios.post('/web/api/events/edit', {
                client_security_code: userdata.client_security_code,
                event_id: context.eventId,
                promote: context.inputValue,
            }).then((response) => {
                context.inAction = false;
                context.$root.bus.emit('simpleToast', context.string.promoteEvent._updateDone);  /** Emit di al gestore globale */
            }).catch((error) => {
                context.inAction = false;
                context.$root.bus.emit('simpleToast', context.string.promoteEvent._error + error );  /** Emit di al gestore globale */
            });
                
        }
    }
};
</script>
