var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      !_vm.pageLoaded
        ? _c("div", { attrs: { id: "preloader" } }, [
            _c("div", { staticClass: "pulse" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("JoinHeader", {
        attrs: {
          "join-status": _vm.joinStatus,
          "guest-name": _vm.guest.name,
          "guest-surname": _vm.guest.surname,
          "team-unique-name": _vm.teamUniqueName,
          srcImage: _vm.srcImage,
          logoEnabled: _vm.logoEnabled,
          "is-dark-mode": _vm.isDarkMode,
        },
        on: { toggleDarkMode: _vm.toggleDarkMode },
      }),
      _vm._v(" "),
      _c("JoinIcon", { attrs: { "join-status": _vm.joinStatus } }),
      _vm._v(" "),
      _c("JoinInteractions", {
        attrs: {
          "join-action": _vm.action,
          "join-status": _vm.joinStatus,
          "virtual-venue": _vm.virtualVenue,
          "virtualroom-code": _vm.virtualRoomCode,
          "privacy-policies": _vm.privacyPolicies,
          "accepted-policies": _vm.acceptedPolicies,
          "max-acquaintance": _vm.guest.maxAcquaintance,
          "acquaintance-added": _vm.guest.acquaintanceAdded,
          "set-max-acquaintance-on-join": _vm.setMaxAcquaintanceOnJoin,
          "set-acquaintance-info-on-join": _vm.setAcquaintanceInfoOnJoin,
          "custom-field-map": _vm.customFieldMap,
          "custom-field-data-map": _vm.customFieldDataMap,
          "member-custom-field-map": _vm.memberCustomFieldMap,
          "member-custom-field-data-map": _vm.memberCustomFieldDataMap,
          "time-slot-map": _vm.timeSlotMap,
          "time-slot-enabled": _vm.timeSlotEnabled,
          "is-dark-mode": _vm.isDarkMode,
        },
        on: {
          confirmAccept: _vm.confirmAccept,
          confirmDecline: _vm.confirmDecline,
          setAcquaintance: _vm.onSetAcquaintance,
        },
      }),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "en footer-text",
          staticStyle: { "margin-top": "20px" },
        },
        [
          _vm._v(
            "\n      We use Penguinpass as our marketing platform. Proceeding you are aware that information you\n      provide will be transferred to Penguinpass to be processed in accordance with their\n      "
          ),
          _c(
            "a",
            {
              staticClass: "footer-text",
              attrs: {
                href: _vm.string.termsAndConditions._privacypolicyURL,
                title: "Privacy policy",
                target: "_blank",
              },
            },
            [_vm._v("\n        Privacy policy\n      ")]
          ),
          _vm._v("\n      and\n      "),
          _c(
            "a",
            {
              staticClass: "footer-text",
              attrs: {
                href: _vm.string.termsAndConditions._linkTerms,
                title: "Terms & conditions",
                target: "_blank",
              },
            },
            [_vm._v("\n        Terms & conditions\n      ")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }