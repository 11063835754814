var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "container" }, [
      _c("div", { staticClass: "layer" }, [
        _c("section", { staticClass: "tm-margin-large-top" }, [
          _c("div", { staticClass: "uk-container uk-text-center" }, [
            _c(
              "p",
              {
                staticClass:
                  "uk-margin-remove-bottom tm-overtitle uk-margin-top uk-text-uppercase letter-spacing-l",
                attrs: {
                  "data-uk-scrollspy": "cls: uk-animation-slide-bottom-medium",
                },
              },
              [_vm._v("\n              Penguinpass support\n            ")]
            ),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass: "uk-margin-small-top uk-heading-hero",
                attrs: {
                  "data-uk-scrollspy":
                    "cls: uk-animation-slide-bottom-medium; delay: 40",
                },
              },
              [_vm._v("\n              How can we help you?\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "uk-grid-medium uk-child-width-1-3@m uk-grid-small uk-grid-match tm-margin-large-top uk-text-center uk-grid uk-scrollspy-inview uk-animation-slide-bottom-medium",
                attrs: {
                  "data-uk-scrollspy":
                    "cls: uk-animation-slide-bottom-medium; delay: 80",
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-quickstart" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: home; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("polygon", {
                                      attrs: {
                                        points:
                                          "18.65 11.35 10 2.71 1.35 11.35 0.65 10.65 10 1.29 19.35 10.65",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("polygon", {
                                      attrs: {
                                        points: "15 4 18 4 18 7 17 7 17 5 15 5",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("polygon", {
                                      attrs: {
                                        points:
                                          "3 11 4 11 4 18 7 18 7 12 12 12 12 18 16 18 16 11 17 11 17 19 11 19 11 13 8 13 8 19 3 19",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Quick Start")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-eventi" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: calendar; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        width: "1",
                                        height: "3",
                                        x: "6",
                                        y: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        width: "1",
                                        height: "3",
                                        x: "13",
                                        y: "2",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Events")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-virtualroom" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: { "data-uk-icon": "icon: tv; ratio: 2" },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "7",
                                        y: "16",
                                        width: "6",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        x: "0.5",
                                        y: "3.5",
                                        width: "19",
                                        height: "11",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Virtual Room and Webinar")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-listeinvitati" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: list; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "4",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "9",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "14",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "4",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "9",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "14",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Guest Lists")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-campagneemail" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: mail; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("polyline", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        points: "1.4,6.5 10,11 18.6,6.5",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Email Campaigns")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/en-impostazioni" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: cog; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        cx: "9.997",
                                        cy: "10",
                                        r: "3.31",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        d: "M18.488,12.285 L16.205,16.237 C15.322,15.496 14.185,15.281 13.303,15.791 C12.428,16.289 12.047,17.373 12.246,18.5 L7.735,18.5 C7.938,17.374 7.553,16.299 6.684,15.791 C5.801,15.27 4.655,15.492 3.773,16.237 L1.5,12.285 C2.573,11.871 3.317,10.999 3.317,9.991 C3.305,8.98 2.573,8.121 1.5,7.716 L3.765,3.784 C4.645,4.516 5.794,4.738 6.687,4.232 C7.555,3.722 7.939,2.637 7.735,1.5 L12.263,1.5 C12.072,2.637 12.441,3.71 13.314,4.22 C14.206,4.73 15.343,4.516 16.225,3.794 L18.487,7.714 C17.404,8.117 16.661,8.988 16.67,10.009 C16.672,11.018 17.415,11.88 18.488,12.285 L18.488,12.285 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Settings")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { ref: "toc", staticClass: "uk-margin-xlarge-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h2", { staticClass: "uk-margin-medium-bottom" }, [
          _vm._v("\n          Frequently asked questions\n        "),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              What are Dashboard, Check-inApp and GuestApp?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              What happens when I activate an event?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Where can I find the name and surname of the companions who entered my guest?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How does the QR Code work to access the event?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Can I add custom details / attributes on my guests?\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            What are dashboard, check-in APP and guestApp?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            What happens when I activate an event?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n           Where can I find the name and surname of the companions who entered my guest and how do I add them to the\n             list?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n             When the guest confirms their participation, they will have (if from the dashboard\n             the organizer allows it) the possibility of entering the "name, surname and email" of his / her\n             any accompanying persons. These names and data can be found simply by downloading\n             the excel sheet via "download the whole list" in the "guest list" section in the form of a note.\n             By taking these names and rearranging them in a new excel sheet on the appropriate format\n             "Template for xls import" and reloading it. By doing so, you can also send emails\n             to the companions of your guests if necessary, or simply they can\n             view via check-inApp.\n          '
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How does the QR code work to access the event?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n             Often there is a need or desire to manage the income to events via QR Code, this\n             it means that the guests who send their RSVPs will receive an email of theirs\n             confirmation containing additional details on the event and the personal QR Code to access.\n             According to this operating logic, it is therefore necessary to set sending from the dashboard\n             of a confirmation email, and insert the dynamic content (QR Code) in it.   \n          "
            ),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("\n            Center a QR code in the email\n          "),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '\n             When inserting "dynamic content" into the empty "form" in Emailbuilder, if you are using\n             a "single module" (the first in the modules section) the QR Code will be displayed on the left side\n             of the email, if you want it to be displayed in the center it will be necessary\n             simply use not the "single module" but the "module divided into three sections" (the seventh\n             of the module list) and insert the dynamic content in the central one.\n          '
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Can I add custom details / attributes on my guests?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n             Dashboard, Guest and Check-in app are the three tools on which the Penguinpass system is based;\n             The first is used by organizers via web access to create and manage events\n             thus having a complete and organized vision in a single instrument. "
      ),
      _c("br"),
      _vm._v(
        "\n             The two mobile apps are instead aimed at users of the service: the Check-inApp\n             allows hostesses to manage on-site access, which through this dynamic support\n             they can check and update guest lists in real time. While the GuestApp is designed and developed\n             for guests who, thanks to the app, can always stay informed e\n             updated on events and access locations easily and quickly.      \n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            When an event is activated, the organizer will be able to use the mail marketing function\n            to send invitations and communications to their guests.\n            "
      ),
      _c("br"),
      _vm._v(
        " Furthermore, once the event is activated it will be available on the GuestApps and therefore, visible only to the public\n             invited, in the case of a private event, to anyone if it is a public event.\n            "
      ),
      _c("br"),
      _vm._v(
        "\n            It is important to note that it is not possible to use the features of the Check-inApp if the event has not occurred\n             activated.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            To add a specific and custom attribute to the guest list, just edit\n            in the "template for xls import" (downloaded from the site as explained above) the "custom\n            note *** "by putting the name of the desired attribute in its place (eg. from" custom note *** "to" country\n            of origin "or" company ").'
      ),
      _c("br"),
      _vm._v(
        '\n            If you need more column-attributes, you can add more later to the “custom\n            Note***"'
      ),
      _c("br"),
      _vm._v(
        '\n            In case you forget to add the extra fields when loading guests, it is possible\n            add new columns by going to the event settings section, expanding the section "Edit custom\n            fields "it will be possible to add new attributes. Once added, simply select the Check-in item\n            app to make the field visible in the check-in app.\n          '
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }