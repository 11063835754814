<template>
  <div>
    <div class="row functionbar">
      <div v-if="!isExhibitor" class="col-md-3 col-sm-3">
        <a>
          <div
            class="box1 tooltips"
            data-placement="top"
            :data-original-title="strings.functionBar._new"
            @click="goNewEvent"
          >
            <i
              class="fa fa-plus fa-4x"
              aria-hidden="true"
            ></i>
            <h3>{{ strings.functionBar._new }}</h3>
          </div>
        </a>
      </div>

      <div class="col-md-3 col-sm-3">
        <a>
          <div
            class="box1 tooltips"
            data-placement="top"
            :data-original-title="strings.functionBar._events"
            @click="goEvents"
          >
            <i
              class="fa fa-calendar fa-4x"
              aria-hidden="true"
            ></i>
            <h3>{{ strings.functionBar._events }}</h3>
          </div>
        </a>
      </div>

      <div class="col-md-3 col-sm-3">
        <a>
          <div
            class="box1 tooltips"
            data-placement="top"
            :data-original-title="strings.functionBar._archive"
            @click="goArchive"
          >
            <i
              class="fa fa-archive fa-4x"
              aria-hidden="true"
            ></i>
            <h3>{{ strings.functionBar._archive }}</h3>
          </div>
        </a>
      </div>
      <div class="col-md-3 col-sm-3">
        <a>
          <div
            class="box1 tooltips"
            data-placement="top"
            :data-original-title="strings.functionBar._search"
            @click="goSearch"
          >
            <i
              class="fa fa-search fa-4x"
              aria-hidden="true"
            ></i>
            <h3>{{ strings.functionBar._search }}</h3>
          </div>
        </a>
      </div>
      <!--
            <div class="col-md-3 col-sm-3">
                <div class="box1 tooltips" data-placement="top" data-original-title="Reports" v-on:click="goReports">
                    <i class="fa fa-bar-chart fa-4x" aria-hidden="true"></i>
                    <h3>{{strings._report}}</h3>
                </div>
            </div>

            <div class="col-md-3 col-sm-3">
                <a>
                    <div class="box1 tooltips" data-placement="top" data-original-title="Tutorial" v-on:click="goTutorial">
                        <i class="fa fa-lightbulb-o fa-4x" aria-hidden="true"></i>
                        <h3>{{strings._tutorial}}</h3>
                    </div>
                </a>
            </div>
            -->
    </div>
  </div>
</template>
<style scoped>
    body{
        background-color:#ff0000;
    }
    .row.functionbar>div>a:hover{
      color: #ffffff
    }
</style>
<script>
import routes from '../router';
import { mapGetters } from 'vuex';
export default {

    components:{

    },
    data (){
        return {
            componentName:'function-bar',
            strings: {},
        };
    },
    computed: {
      ...mapGetters(["team", "user", "isExhibitor"])
    },
    created (){
        const context = this;
        this.strings = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.strings = context.$root.strings;
        });
    },
    methods: {
        goNewEvent (event) {
            if (this.$can('create', 'new_event')) {
              this.$root.bus.emit('component_event', this.componentName+'|newEvent');  /** Emit di al gestore globale */
            } else {
              this.$root.bus.emit("simpleToast", "Permission denied");
            }
        },
        goEvents (event) {
            event.preventDefault();
            this.$router.push({ path: '/events' }).catch(error =>{});
        },
        goArchive (event) {
            event.preventDefault();
            this.$router.push({ path: '/events/archive' }).catch(error =>{});
        },
        goSearch (event){
            event.preventDefault();
            this.$root.bus.emit('SearchButtonClicked');
            this.$router.push({ path: '/events/search' }).catch(error =>{});
        },
        goReports (event) {
            event.preventDefault();
            this.$router.push({ path: '/reports' }).catch(error =>{});
        },
        goTutorial () {
            alert('carica il tutorial');
        }
    }
};
</script>
