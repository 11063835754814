<template>
  <input
      :id="customId"
      :value="value"
      type="text"
      :placeholder="placeholder"
      class="custom-input-control"
      @input="onInput"
  />
</template>
<script>
import debounce from "lodash/debounce";

export default {

    props: {
        value: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        }
    },

    data(){
        return {
            customId: 'input-' + Math.floor(Math.random() * 100000).toString()
        };
    },
    methods: {
        onInput(event) {
            debounce((context, event) => {
                context.$emit('input', event.target.value);
                context.$emit('change', event.target.value);
            }, event.data ? 400 : 600)(this, event);
        }
    }

};
</script>

<style scoped>
input.custom-input-control {
  height: 45px;
  width: 100%;
  margin: 0 auto;
  padding: 13px 21px;
  outline: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
}
</style>