var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticStyle: {
        "min-height": "30px",
        "min-width": "30px",
        "line-height": "30px",
      },
    },
    [
      !!_vm.text
        ? _c("span", { style: { color: _vm.textColor } }, [
            _vm._v(_vm._s(_vm.text)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "font-awesome-layers",
        {
          staticClass: "fa-lg penguin-icon-layer",
          staticStyle: { "margin-right": "10px" },
        },
        [
          _c("font-awesome-icon", {
            staticClass: "penguin-icon-bg",
            style: { color: _vm.bgColor },
            attrs: {
              icon: ["fas", "circle"],
              transform: "grow-6",
              size: _vm.size,
            },
          }),
          _vm._v(" "),
          _c("font-awesome-icon", {
            staticClass: "penguin-icon-fg",
            style: { color: _vm.fgColor },
            attrs: { icon: _vm.icon, transform: "shrink-4", size: _vm.size },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }