var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _vm.registerSuccess === false
                ? _c(
                    "h2",
                    {
                      staticClass: "heading-title text-white",
                      attrs: { id: "title" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.string.registerPage._register_title) +
                          "\n          "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-5 text-gray-2 padding-left-40" }, [
          _c("h5", { staticClass: "margin-top-40 margin-bottom-20" }),
        ]),
      ]),
      _vm._v(" "),
      _vm.registerSuccess === false
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c(
                "form",
                {
                  staticClass: "margin-top-80 text-white wow fadeIn",
                  attrs: {
                    id: "contact-form",
                    autocomplete: "off",
                    "data-wow-delay": "0.3s",
                  },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.name,
                              expression: "user.name",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            type: "text",
                            "aria-autocomplete": "new-password",
                            placeholder: _vm.string.registerPage._p_name,
                            required: "",
                            autofocus: "",
                          },
                          domProps: { value: _vm.user.name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "name", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.surname,
                              expression: "user.surname",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            type: "text",
                            "aria-autocomplete": "new-password",
                            name: "organizer_surname",
                            placeholder: _vm.string.registerPage._p_surname,
                            required: "",
                          },
                          domProps: { value: _vm.user.surname },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "surname", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.email,
                              expression: "user.email",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            type: "email",
                            readonly: "",
                            "aria-autocomplete": "new-password",
                            name: "organizer_email",
                            value: "",
                            placeholder: _vm.string.registerPage._p_email,
                          },
                          domProps: { value: _vm.user.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "email", $event.target.value)
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.password,
                              expression: "user.password",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            autocomplete: "off",
                            type: "password",
                            name: "password",
                            placeholder: _vm.string.registerPage._p_password,
                            required: "",
                          },
                          domProps: { value: _vm.user.password },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.user,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-lg-4" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.passwordConfirm,
                              expression: "passwordConfirm",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            autocomplete: "off",
                            type: "password",
                            name: "password_confirmation",
                            placeholder:
                              _vm.string.registerPage._p_password_confirm,
                            required: "",
                          },
                          domProps: { value: _vm.passwordConfirm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.passwordConfirm = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "row",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [
                      _c("div", { staticClass: "col-lg-12" }, [
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.string.commonActions.passwordPolicy)
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control control-checkbox",
                        staticStyle: { "margin-bottom": "30px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.privacyCondition,
                              expression: "user.privacyCondition",
                            },
                          ],
                          attrs: { type: "checkbox", name: "disclaimer" },
                          domProps: {
                            checked: Array.isArray(_vm.user.privacyCondition)
                              ? _vm._i(_vm.user.privacyCondition, null) > -1
                              : _vm.user.privacyCondition,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.user.privacyCondition,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user,
                                      "privacyCondition",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user,
                                      "privacyCondition",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.user, "privacyCondition", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_indicator" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-policy" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.string.termsAndConditions._privacy_accept
                          ) +
                          "\n                "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.string.termsAndConditions
                              ._privacypolicyURL,
                            title: _vm.string.termsAndConditions._privacy_title,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.string.termsAndConditions._privacy_title
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.string.termsAndConditions._terms_accept) +
                          "\n                "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.string.termsAndConditions._linkTerms,
                            title: _vm.string.termsAndConditions._terms_title,
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.string.termsAndConditions._terms_title
                              ) +
                              "\n                "
                          ),
                        ]
                      ),
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.string.termsAndConditions._mandatory) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control control-checkbox",
                        staticStyle: { "margin-bottom": "30px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.dataCondition,
                              expression: "user.dataCondition",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.user.dataCondition)
                              ? _vm._i(_vm.user.dataCondition, null) > -1
                              : _vm.user.dataCondition,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.user.dataCondition,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user,
                                      "dataCondition",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user,
                                      "dataCondition",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.user, "dataCondition", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_indicator" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-policy" }, [
                      _vm._v(_vm._s(_vm.string.termsAndConditions._updates)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-lg-4" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control control-checkbox",
                        staticStyle: { "margin-bottom": "30px" },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.processingCondition,
                              expression: "user.processingCondition",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(_vm.user.processingCondition)
                              ? _vm._i(_vm.user.processingCondition, null) > -1
                              : _vm.user.processingCondition,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.user.processingCondition,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.user,
                                      "processingCondition",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.user,
                                      "processingCondition",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.user, "processingCondition", $$c)
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_indicator" }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-policy" }, [
                      _vm._v(_vm._s(_vm.string.termsAndConditions._marketing)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-primary btn-warning active btn-block btn-lg",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.register($event)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.string.registerPage._submit))]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-12" }, [
              _c("p", { staticClass: "heading-tescription lead text-gray-2" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.registerSuccessMessage) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }