export const VideoRoomFeatureEnumType = {
    external: "EXTERNAL",
    internal: "INTERNAL"
}

export const VideoRoomStatusEnumType = {
    TO_BE_CREATED: "To be created",
    CREATING: "Creating",
    CREATED: "Ready",
    TO_BE_TERMINATED: "To be terminated",
    TERMINATING: "Terminating",
    TERMINATED: "Terminated",
    ACTIVE: "Active"
}

export const VideoCallStatusEnumType = {
    PRE: "Pre call",
    OPENED: "Started",
    PAUSED: "Paused",
    ENDED: "Ended"
}

export const CFPrefix = {
    GUEST: "gcf-",
    CONTACT: "ccf-",
    USER: "ucf-"
};