<template>
  <div>
    <div ref="container">
      <div class="layer">
        <section class="tm-margin-large-top">
          <div class="uk-container uk-text-center">
            <p class="uk-margin-remove-bottom tm-overtitle uk-margin-top uk-text-uppercase letter-spacing-l"
               data-uk-scrollspy="cls: uk-animation-slide-bottom-medium">
              Penguinpass support
            </p>
            <h1 class="uk-margin-small-top uk-heading-hero"
                data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 40">
              How can we help you?
            </h1>
            <div
              class="uk-grid-medium uk-child-width-1-3@m uk-grid-small uk-grid-match tm-margin-large-top uk-text-center uk-grid uk-scrollspy-inview uk-animation-slide-bottom-medium"
              data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 80">
              <div>
                <router-link to="/helpdesk/en-quickstart"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: home; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <polygon
                          points="18.65 11.35 10 2.71 1.35 11.35 0.65 10.65 10 1.29 19.35 10.65">
                        </polygon>
                        <polygon
                          points="15 4 18 4 18 7 17 7 17 5 15 5">
                        </polygon>
                        <polygon
                          points="3 11 4 11 4 18 7 18 7 12 12 12 12 18 16 18 16 11 17 11 17 19 11 19 11 13 8 13 8 19 3 19">
                        </polygon>
                      </svg>
                    </span>
                  </span>
                  <p>Quick Start</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/en-eventi"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: calendar; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <path
                          d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z">
                        </path>
                        <rect
                          width="1" height="3" x="6"
                          y="2">
                        </rect>
                        <rect width="1" height="3" x="13"
                              y="2">
                        </rect>
                      </svg>
                    </span>
                  </span>
                  <p>Events</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/en-virtualroom"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: tv; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <rect x="7"
                              y="16"
                              width="6"
                              height="1">
                        </rect>
                        <rect
                          fill="none" stroke="#000" x="0.5"
                          y="3.5" width="19" height="11">
                        </rect>
                      </svg>
                    </span>
                  </span>
                  <p>Virtual Room and Webinar</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/en-listeinvitati"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: list; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <rect x="6" y="4"
                              width="12"
                              height="1">
                        </rect>
                        <rect x="6" y="9"
                              width="12"
                              height="1">
                        </rect>
                        <rect x="6" y="14"
                              width="12"
                              height="1">
                        </rect>
                        <rect
                          x="2" y="4" width="2"
                          height="1"></rect> <rect x="2" y="9" width="2"
                                                   height="1"></rect> <rect x="2"
                                                                            y="14"
                                                                            width="2"
                                                                            height="1"></rect>
</svg>
</span>
</span>
                  <p>Guest Lists</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/en-campagneemail"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: mail; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <polyline
                          fill="none" stroke="#000" points="1.4,6.5 10,11 18.6,6.5">
                        </polyline>
                        <path
                          d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z">
                        </path>
                      </svg>
                    </span>
                  </span>
                  <p>Email Campaigns</p>
                </router-link>
              </div>
              <!--div>
                  <a href="bigliettieallegati.html" class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                      <span class="uk-icon-forder uk-border-rounded"><--span data-uk-icon="icon: tag; ratio: 2"></span></span>
                      <p>Biglietti e Allegati</p>
                  </a>
              <div -->
              <div>
                <router-link to="/helpdesk/en-impostazioni"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: cog; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <circle
                          fill="none" stroke="#000" cx="9.997"
                          cy="10" r="3.31"></circle>
                        <path fill="none" stroke="#000"
                              d="M18.488,12.285 L16.205,16.237 C15.322,15.496 14.185,15.281 13.303,15.791 C12.428,16.289 12.047,17.373 12.246,18.5 L7.735,18.5 C7.938,17.374 7.553,16.299 6.684,15.791 C5.801,15.27 4.655,15.492 3.773,16.237 L1.5,12.285 C2.573,11.871 3.317,10.999 3.317,9.991 C3.305,8.98 2.573,8.121 1.5,7.716 L3.765,3.784 C4.645,4.516 5.794,4.738 6.687,4.232 C7.555,3.722 7.939,2.637 7.735,1.5 L12.263,1.5 C12.072,2.637 12.441,3.71 13.314,4.22 C14.206,4.73 15.343,4.516 16.225,3.794 L18.487,7.714 C17.404,8.117 16.661,8.988 16.67,10.009 C16.672,11.018 17.415,11.88 18.488,12.285 L18.488,12.285 Z">
                        </path>
                      </svg>
                    </span>
                  </span>
                  <p>Settings</p>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <section ref="toc" class="uk-margin-xlarge-top">
      <div class="uk-container">
        <h2 class="uk-margin-medium-bottom">
          Frequently asked questions
        </h2>
        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What are Dashboard, Check-inApp and GuestApp?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000"
                        x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What happens when I activate an event?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Where can I find the name and surname of the companions who entered my guest?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How does the QR Code work to access the event?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Can I add custom details / attributes on my guests?
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What are dashboard, check-in APP and guestApp?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
             Dashboard, Guest and Check-in app are the three tools on which the Penguinpass system is based;
             The first is used by organizers via web access to create and manage events
             thus having a complete and organized vision in a single instrument. <br>
             The two mobile apps are instead aimed at users of the service: the Check-inApp
             allows hostesses to manage on-site access, which through this dynamic support
             they can check and update guest lists in real time. While the GuestApp is designed and developed
             for guests who, thanks to the app, can always stay informed e
             updated on events and access locations easily and quickly.      
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What happens when I activate an event?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            When an event is activated, the organizer will be able to use the mail marketing function
            to send invitations and communications to their guests.
            <br> Furthermore, once the event is activated it will be available on the GuestApps and therefore, visible only to the public
             invited, in the case of a private event, to anyone if it is a public event.
            <br>
            It is important to note that it is not possible to use the features of the Check-inApp if the event has not occurred
             activated.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
           Where can I find the name and surname of the companions who entered my guest and how do I add them to the
             list?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
             When the guest confirms their participation, they will have (if from the dashboard
             the organizer allows it) the possibility of entering the "name, surname and email" of his / her
             any accompanying persons. These names and data can be found simply by downloading
             the excel sheet via "download the whole list" in the "guest list" section in the form of a note.
             By taking these names and rearranging them in a new excel sheet on the appropriate format
             "Template for xls import" and reloading it. By doing so, you can also send emails
             to the companions of your guests if necessary, or simply they can
             view via check-inApp.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How does the QR code work to access the event?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
             Often there is a need or desire to manage the income to events via QR Code, this
             it means that the guests who send their RSVPs will receive an email of theirs
             confirmation containing additional details on the event and the personal QR Code to access.
             According to this operating logic, it is therefore necessary to set sending from the dashboard
             of a confirmation email, and insert the dynamic content (QR Code) in it.   
          </p>
          <h3>
            Center a QR code in the email
          </h3>
          <p>
             When inserting "dynamic content" into the empty "form" in Emailbuilder, if you are using
             a "single module" (the first in the modules section) the QR Code will be displayed on the left side
             of the email, if you want it to be displayed in the center it will be necessary
             simply use not the "single module" but the "module divided into three sections" (the seventh
             of the module list) and insert the dynamic content in the central one.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Can I add custom details / attributes on my guests?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To add a specific and custom attribute to the guest list, just edit
            in the "template for xls import" (downloaded from the site as explained above) the "custom
            note *** "by putting the name of the desired attribute in its place (eg. from" custom note *** "to" country
            of origin "or" company ").<br>
            If you need more column-attributes, you can add more later to the “custom
            Note***"<br>
            In case you forget to add the extra fields when loading guests, it is possible
            add new columns by going to the event settings section, expanding the section "Edit custom
            fields "it will be possible to add new attributes. Once added, simply select the Check-in item
            app to make the field visible in the check-in app.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskHomeEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["container"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
