<template>
  <div class="direct-email-marketing-component-container">
    <section-content
      :has-icon="true"
      :icon-progress="steps.campaign.progress"
      :icon-text="steps.campaign.progressLabel"
    >
      <section-title
        v-if="type === 'sms'"
        :title="$root.strings.textMarketing._title"
        :subtitle="$root.strings.textMarketing._subtitle"
      />
      <section-title
        v-else
        :title="$root.strings.textMarketing._titleWA"
        :subtitle="$root.strings.textMarketing._subtitleWA"
      />
      <transition name="component-fade-fast" mode="out-in" appear>
        <PulseLoader
          v-if="loading === true"
          :loading="loading"
          color="#f7a30c"
          size="15px"
        ></PulseLoader>
        <button
          v-else-if="!campaignCreated === true && loading === false"
          class="button save"
          style="width: 100% !important"
          @click="createCampaign"
        >
          {{ $root.strings.textMarketing._create }}
        </button>
        <!-- <a
                id="delete-campaign-button"
                class="button btn-warning save"
                style="width: 100% !important;"
                @click="warnAndDeleteCampaign">
                {{ $root.strings.directEmailMarketing._deleteCampaign }}
              </a> -->
        <LoadingCover v-else-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />

        <div v-else class="">
          <div v-if="type === 'sms'">
            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.textMarketing._header }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <custom-input
                  v-if="!templateCreated"
                  v-model.trim="title"
                  :placeholder="$root.strings.textMarketing._header"
                />
                <span v-else>
                  {{ title }}
                </span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.textMarketing._body }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <textarea
                  v-if="!templateCreated"
                  v-model:value="body"
                  class="form-control"
                  rows="5"
                  :placeholder="$root.strings.textMarketing._body"
                >
                </textarea>
                <span v-else>
                  {{ body }}
                </span>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.textMarketing._header }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <span> SAVE THE DATE </span>
              </el-col>
            </el-row>
            <el-row :gutter="20" class="form-field-row zero-margin-row">
              <el-col :span="3" class="form-field-col-label">
                <span>
                  {{ $root.strings.textMarketing._body }}
                </span>
              </el-col>
              <el-col :span="21" class="form-field-col-field">
                <span>
                  Ciao, {1} ha il piacere diinvitarti all'evento {2} che si terrà il giorno {3} alle
                  ore {4} in {5}.<br />
                  Clicca qui per confermare
                </span>
              </el-col>
            </el-row>
          </div>
          <div v-if="type === 'sms'">
            <button
              v-if="!templateCreated"
              class="button save"
              style="width: 100% !important"
              @click="
                () => {
                  onChanges(false);
                  createTemplate({ eventId, title: title, body: body });
                }
              "
            >
              {{ $root.strings.textMarketing._sendToApprove }}
            </button>
            <button
              v-else
              class="button save"
              style="width: 100% !important"
              @click="
                () => {
                  templateCreated = false;
                }
              "
            >
              {{ $root.strings.textMarketing._edit }}
            </button>
          </div>
          <button
            class="button save"
            style="width: 100% !important"
            @click="
              () => {
                swapType();
              }
            "
          >
            {{ type === "sms" ? "Swap to Whatsapp" : "Swap to SMS" }}
          </button>
        </div>
      </transition>
    </section-content>
    <section-content
      :has-icon="true"
      :icon-progress="steps.confirmationEmail.progress"
      :icon-text="steps.confirmationEmail.progressLabel"
    >
      <section-title :title="$root.strings.textMarketing._additionalSettingsTitle" />
      <section-content v-if="loading === false">
        <el-row
          v-if="isPrivate"
          :gutter="10"
          class="form-field-row zero-margin-row"
          style="margin-bottom: 30px"
        >
          <el-col :span="3" class="form-field-col-label">
            <span> {{ $root.strings.textMarketing._setRsvp }}: </span>
          </el-col>
          <el-col
            :span="1"
            class="form-field-col-field"
            style="align-self: center; vertical-align: middle; text-align: center"
          >
            <custom-checkbox
              v-if="type === 'sms'"
              v-model="withRsvp"
              @change="
                () => {
                  updateRsvpOptions();
                }
              "
            />
          </el-col>
          <el-col :span="20" class="form-field-col-field">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.textMarketing._setRsvp_explain }}
            </p>
          </el-col>
        </el-row>
        <el-row
          v-if="isPrivate && withRsvp"
          :gutter="10"
          class="form-field-row zero-margin-row"
          style="margin-bottom: 30px"
        >
          <el-col :span="3" class="form-field-col-label">
            <span> {{ $root.strings.directEmailMarketing._setpartnermax }}: </span>
          </el-col>
          <el-col
            :span="1"
            class="form-field-col-field"
            style="align-self: center; vertical-align: middle; text-align: center"
          >
            <custom-checkbox
              v-model="setMaxAcquaintanceOnJoin"
              @change="() => updateRsvpOptions()"
            />
          </el-col>
          <el-col :span="20" class="form-field-col-field">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.directEmailMarketing._setpartnermax_explain }}
            </p>
          </el-col>
        </el-row>
        <el-row
          v-if="isPrivate && withRsvp"
          :gutter="10"
          class="form-field-row zero-margin-row"
          style="margin-bottom: 30px"
        >
          <el-col :span="3" class="form-field-col-label">
            <span> {{ $root.strings.directEmailMarketing._setnote }}: </span>
          </el-col>
          <el-col
            :span="1"
            class="form-field-col-field"
            style="align-self: center; vertical-align: middle; text-align: center"
          >
            <custom-checkbox
              v-model="setAcquaintanceInfoOnJoin"
              @change="() => updateRsvpOptions()"
            />
          </el-col>
          <el-col :span="20" class="form-field-col-field">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.directEmailMarketing._setnote_explain }}
            </p>
          </el-col>
        </el-row>
        <el-row
          v-if="isPrivate && withRsvp"
          :gutter="10"
          class="form-field-row zero-margin-row"
          style="margin-bottom: 30px"
        >
          <el-col :span="3" class="form-field-col-label">
            <span> {{ $root.strings.textMarketing._setConfirmation }}: </span>
          </el-col>
          <el-col
            :span="1"
            class="form-field-col-field"
            style="align-self: center; vertical-align: middle; text-align: center"
          >
            <custom-checkbox v-model="withConfirmation" @change="() => updateRsvpOptions()" />
          </el-col>
          <el-col :span="20" class="form-field-col-field">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.textMarketing._setConfirmation_explain }}
            </p>
          </el-col>
        </el-row>
      </section-content>
    </section-content>

    <!-- Audience Options -->
    <section-content
      v-if="campaignCreated === true && loading === false"
      :has-icon="true"
      :icon-progress="steps.audience.progress"
      :icon-text="steps.audience.progressLabel"
    >
      <section-title
        :title="$root.strings.directEmailMarketing._audience_title"
        :subtitle="$root.strings.textMarketing._audience_subtitle"
      />
      <div class="group-manager-members-list">
        <i
          class="fa fa-users"
          aria-hidden="true"
          style="margin-right: 15px; font-size: 20px; margin-bottom: 20px"
        />
        <span style="font-size: 20px; margin-bottom: 20px">
          {{ $root.strings.datagrid._selected }}: {{ audienceSize }}
        </span>
        <data-table
          v-if="campaignCreated"
          ref="table"
          :get-data="getData"
          :columns="columns"
          :selection-enabled="audienceOption === 'CUSTOM'"
          :page-size="15"
          :on-selection-change="onSelectionChange"
          :bulk-options="['clearSelection']"
          :on-bulk-action="onBulkAction"
          :bulk-option-placeholder="'_options'"
        >
          <span slot="options">
            <a
              class="btn btn-gray full-column-button"
              @click.prevent="() => (filtersVisible = !filtersVisible)"
            >
              <font-awesome-icon icon="filter" transform="grow-4" />
            </a>
          </span>
          <span slot="filterView">
            <audience-option-group
              v-show="filtersVisible"
              :audience-options="audienceOptions"
              :event-id="eventId"
              :on-audience-option-changed="onAudienceOptionChanged"
              :initial-value="initialOption"
              :hide-counter="true"
              :stop-calculate-eligible-recipient-count="true"
            />
          </span>
          <span slot="memberName" slot-scope="{ row }">
            {{ row.member.name }}
          </span>
          <span slot="memberSurname" slot-scope="{ row }">
            {{ row.member.surname }}
          </span>
          <span slot="memberTelephone" slot-scope="{ row }">
            {{ row.member.telephone }}
          </span>
          <span slot="textDeliveryStatus" slot-scope="{ row }">
            <div
              v-if="
                [
                  'SEND',
                  'DELIVERED',
                  'OPENED',
                  'CLICKED',
                  'BOUNCED',
                  'REJECTED',
                  'COMPLAINED',
                ].indexOf(row.textDeliveryStatus) !== -1
              "
              class="p-table-cell"
              style="text-align: left !important"
            >
              <el-tooltip
                effect="dark"
                :content="
                  row.textDeliveryStatus
                    ? row.textDeliveryStatus === 'COMPLAINED'
                      ? 'unsubscribed'
                      : row.textDeliveryStatus.toLowerCase()
                    : '-'
                "
                placement="bottom"
              >
                <font-awesome-layers class="fa-lg">
                  <font-awesome-icon :icon="['fas', 'circle']" transform="grow-6">
                  </font-awesome-icon>
                  <font-awesome-icon
                    :icon="deliveryStatusIcons[row.textDeliveryStatus]"
                    transform="shrink-4"
                    :style="{ color: 'black' }"
                  >
                  </font-awesome-icon>
                </font-awesome-layers>
              </el-tooltip>
            </div>
            <div v-else class="p-table-cell" style="text-align: left !important">-</div>
          </span>

          <span slot="status" slot-scope="{ row }">
            <div class="p-table-cell" style="text-align: left !important">
              <font-awesome-layers class="fa-lg" style="margin-right: 10px">
                <font-awesome-icon
                  :icon="['fas', 'circle']"
                  transform="grow-6"
                  :style="statusStyle[row.status]"
                >
                </font-awesome-icon>
                <font-awesome-icon
                  :icon="statusIcons[row.status]"
                  transform="shrink-4"
                  :style="{ color: 'white' }"
                >
                </font-awesome-icon>
              </font-awesome-layers>
              <span>{{ statusDescription[row.status] }}</span>
            </div>
          </span>
        </data-table>
      </div>
      <el-row class="zero-margin-row">
        <el-col :span="24">
          <a v-if="hasChanges" class="btn gray-btn" @click.prevent="updateCampaign">
            {{ $root.strings.directEmailMarketing._save_changes }}
          </a>
          <a v-else class="btn gray-btn" @click.prevent="sendCampaign">
            {{ $root.strings.directEmailMarketing._send }}
          </a>
        </el-col>
      </el-row>
    </section-content>
    <section-content :has-icon="true">
      <section-title
        :title="$root.strings.textMarketing._recap"
        :subtitle="$root.strings.textMarketing._recapsubtitle"
      />

      <div class="group-manager-members-list">
        <data-table
          ref="campaignsTable"
          :get-data="getPastCampaigns"
          :columns="campaignGridColumns"
          :selection-enabled="false"
          :filter-enabled="false"
          :no-data-text="$root.strings.directEmailMarketing._noCampaigns"
        >
          <div slot="refresh-header" slot-scope="{ row }" style="text-align: center">
            <a
              type="button"
              style="background-color: transparent; padding: 0; margin: 0"
              @click.prevent="updateCampaignTableStatistics"
            >
              <font-awesome-icon icon="sync" :spin="refreshingPastCampaigns" />
            </a>
          </div>
          <div slot="refresh" slot-scope="{ row }" style="text-align: center">
            <font-awesome-icon v-if="row.status === 'QUEUED'" icon="sync" spin />
          </div>
          <span slot="sentAt" slot-scope="{ row }">
            <a
              v-if="row.status === 'QUEUED'"
              class="action_link"
              style=""
              @click.prevent="() => onCancelCampaign(row)"
            >
              <font-awesome-icon icon="times" />
              {{ $root.strings.directEmailMarketing._cancel_campaign }}
            </a>
            <span v-else>
              {{ row.sentAt ? new Date(row.sentAt).format() : " - " }}
            </span>
          </span>
        </data-table>
      </div>
    </section-content>
    <!-- Campaigns sent-->
  </div>
</template>
<style>
div.domain-selector > div.el-input > input.el-input__inner {
  height: 50px;
}
.domain-selector,
.domain-selector > div > input {
  height: 45px !important;
  text-align: left;
  width: 100%;
}
span.domain-selector {
  line-height: 45px !important;
}
.domain-selector > .el-button:focus,
.el-button:hover {
  color: black !important;
  background: white !important;
}
button.el-button.domain-selector.el-button--default {
  color: white !important;
  background: #f7a30c !important;
}
/** Rotating Animation */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.el-message-box {
  background-color: #000000;
  border: 1px solid #f7a30c;
}
.el-message-box.grayscale {
  border: 1px solid #ffffff;
}
.el-message-box.grayscale .el-message-box__btns .el-button {
  color: #ffffff !important;
}
.el-message-box.grayscale .el-message-box__btns .el-button:hover {
  color: #000 !important;
}

.el-message-box__content {
  color: #ffffff;
}
.email-preview {
  text-align: left;
  border: solid 1px #000;
  padding: 0;
  background: white;
}
iframe#emailPreviewFrame,
iframe#confirmationEmailPreviewFrame {
  display: block;
  overflow: auto;
  border: 0;
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin: 0;
  cursor: pointer;
}
</style>
<style scoped>
#delete-campaign-button {
  background: rgba(247, 163, 12, 0.5);
}
.refresh-stats-button {
  margin-bottom: 15px;
}
a.simple {
  cursor: pointer;
  color: #333 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.simple:hover {
  cursor: pointer;
  color: #333;
  font-size: 20px !important;
  text-decoration: underline;
}
.pagination > li {
  color: white;
}
ul .pagination {
  background-color: white;
}

.createEmail a.btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 10px;
  margin-bottom: 0px;
}

.squaredThree {
  width: 20px;
  position: relative;
  margin: 0 auto;
}

.joincommand {
  text-align: center;
  border: solid 1px #fff;
  padding: 20px;
}

.joincommand p {
  margin-bottom: 0 !important;
}

.textLabel {
  padding: 20px 20px 0 20px;
}

.btn {
  border: 0;
}

.direct-email-marketing-component-container .form-control {
  border-radius: 0;
  border-color: #333;
}

.sendCampaignArea {
  margin-bottom: 50px;
}

.sendCampaignArea a.btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 15px;
}

.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #f7a30c;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.subject-section-title {
  color: #f7a30c;
}

.subject-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 10px;
  color: #e8e8e8;
}

.direct-email-marketing-component-container {
  margin-top: 50px;
  margin-bottom: 30px;
}

.control-label {
  text-align: right;
}
.default-email {
  text-align: start;
  font-size: medium;
}

.subject-field {
  text-align: start;
  border: solid 1px #fff;
  padding: 0px 5px;
}
.page-link {
  background: transparent !important;
  text-decoration: underline !important;
  text-underline-position: under;
  text-decoration-color: #f7a30c !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
  margin: 5px;
  padding: 0 !important;
}
.control.control-checkbox {
  display: inline;
}
.formStyle {
  padding-bottom: 10px;
}
.form-field-row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.form-field-col-label {
  align-self: center;
}

.top-right {
  position: absolute;
  top: 8px;
  right: 16px;
}
/* .form-field-col-field {
}
*/
</style>

<script>
import $ from "jquery";
import DataGridCampaign from "./DataGridCampaign.vue";
import LoadingCover from "./LoadingCover.vue";
import Paginate from "vuejs-paginate";
import DataTable from "./DataTable";
import SectionTitle from "./ui/SectionTitle";
import CustomCheckbox from "./ui/CustomCheckbox";
import SectionContent from "./ui/SectionContent";
import {
  CREATE_TEXT_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_TEXT_DRAFT_CAMPAIGN,
  UPDATE_TEXT_CAMPAIGN,
  GET_PAST_CAMPAIGNS,
  GET_MY_VERIFIED_IDENTITIES,
  SEND_CAMPAIGN,
  SEND_TEXT_CAMPAIGN,
  SEND_WA_CAMPAIGN,
  UPDATE_CAMPAIGN,
  UPDATE_RECIPIENT_LIST,
  UPDATE_PAST_CAMPAIGNS,
  GET_PAST_TEXT_CAMPAIGNS,
  SUBSCRIBE_TO_SEND_CAMPAIGN,
  GUEST_LIST,
  UPDATE_EMAIL_CONFIRMATION,
  CREATE_EMAIL_CONFIRMATION,
  DELETE_EMAIL_CONFIRMATION,
} from "@/graphql";

import { customFieldMapToMergeTagArray, findMustacheTokens, getAllTokens } from "../utils";
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import AudienceOptionGroup from "@/containers/AudienceOptionGroup.vue";
import CustomInput from "./ui/CustomInput.vue";
import ButtonTab from "./ui/ButtonTab.vue";
import LoadingIcon from "./LoadingIcon.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    DataGridCampaign,
    LoadingCover,
    Paginate,
    PulseLoader,
    DataTable,
    AudienceOptionGroup,
    SectionTitle,
    SectionContent,
    CustomCheckbox,
    CustomInput,
    ButtonTab,
    LoadingIcon,
  },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    campaignTableSize: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    const steps =
      this.event.genre === "PRIVATE"
        ? {
            campaign: { enabled: true, progress: 0, progressLabel: "1" },
            confirmationEmail: { enabled: true, progress: 0, progressLabel: "2" },
            audience: { enabled: true, progress: 0, progressLabel: "3" },
          }
        : {
            campaign: { enabled: true, progress: 0, progressLabel: "1" },
            confirmationEmail: { enabled: false, progress: 0, progressLabel: "" },
            audience: { enabled: true, progress: 0, progressLabel: "2" },
          };

    return {
      msg: "WhatsappMarketing",

      recipientGridData: [],
      recipientIds: [],

      loading: false,
      campaign: {},
      campaignId: null,
      campaignCreated: false,

      campaignEmailTemplate: null,
      campaignEmailPreview: "",

      /** Campaign default data */
      verifiedEmailIdentities: [],
      verifiedDomainIdentities: [],
      domainName: "",
      domainUser: "",
      title: "",
      body: "",
      subject: "",
      type: "sms",
      templateCreated: false,
      subjectDisabled: true,
      setMaxAcquaintanceOnJoin: false,
      setAcquaintanceInfoOnJoin: false,
      withRsvp: false,
      withConfirmation: false,

      /** Sent Campaign data */
      campaignGridColumns: [
        {
          prop: "refresh",
          minWidth: "50",
          maxWidth: "50",
          width: "50",
        },
        {
          prop: "title",
          label: "Subject",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "total",
          label: "Total",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "delivered",
          label: "Sent",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "rejected",
          label: "Rejected",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "type",
          label: "Type",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "sentAt",
          label: "Sent at",
          minWidth: "150",
          sortable: true,
        },
      ],
      refreshingPastCampaigns: false,

      confirmationEmailTabs: [
        { key: "CONFIRMATION_EMAIL_ENABLED" },
        { key: "CONFIRMATION_EMAIL_DISABLED" },
      ],
      confirmationEmailTab: "",
      confirmationEmailTemplate: null,
      confirmationEmailPreview: "",
      confirmationEmailSavedListener: null,
      confirmationEmailId: "",
      confirmationEmailSenderName: "",
      confirmationEmailSenderEmail: "",
      confirmationEmailDomainUser: "",
      confirmationEmailDomainName: "",
      confirmationEmailSubject: "",
      confirmationEmailSubjectDisabled: true,

      loadingEffectshow: false,
      loadingMessage: "",
      recipientTableColumns: [
        { key: "name", label: "Name" },
        { key: "surname", label: "Surname" },
        { key: "email", label: "Email" },
        { key: "textDeliveryStatus", label: "Campaign Status" },
        { key: "status", label: "Status" },
      ],
      currentRecipientPage: 0,
      currentCampaignPage: 0,

      /** Campaign stats */
      updatingCampaign: false,
      hasChanges: false,
      campaignUpdatedSubscription: null,
      emailSavedListener: null,

      //audience management
      audienceSize: 0,
      customFieldFilter: { question: "", answer: "", type: "", source: "" },
      initialFilter: { question: "", answer: "", type: "", source: "" },
      initialOption: "ALL_MARKETING_GUESTS",
      audienceOption: "ALL_MARKETING_GUESTS",
      audienceOptionFilter: undefined,
      audienceOptions: [
        {
          label: "_selectPending",
          value: "MARKETING_PENDING",
        },
        {
          label: "_selectConfirmed",
          value: "MARKETING_CONFIRMED",
        },
        {
          label: "_selectCheckedIn",
          value: "MARKETING_CHECK_IN",
        },
        {
          label: "_selectAllMarketingGuests",
          value: "ALL_MARKETING_GUESTS",
        },
        {
          label: "_selectCustom",
          value: "CUSTOM",
        },
        {
          label: "_selectNotSend",
          value: "NOT_SEND_TEXT",
        },
        {
          label: "_selectNotSendConfirmed",
          value: "NOT_SEND_CONFIRMED_TEXT",
        },
      ],

      columns: [
        {
          prop: "memberName",
          label: "Name",
          minWidth: "150",
          sortable: true,
        },
        {
          prop: "memberSurname",
          label: "Surname",
          minWidth: "150",
          sortable: true,
        },
        {
          prop: "memberTelephone",
          label: "Tel",
          minWidth: "250",
          sortable: true,
        },
        {
          prop: "textDeliveryStatus",
          label: "Campaign",
          minWidth: "110",
          sortable: true,
        },
        {
          prop: "status",
          label: "Status",
          minWidth: "200",
          sortable: true,
        },
      ],
      deliveryStatusIcons: {
        SEND: ["far", "paper-plane"],
        DELIVERED: ["far", "envelope"],
        OPENED: ["far", "envelope-open"],
        CLICKED: ["fas", "envelope-open-text"],
        BOUNCED: ["fas", "times"],
        REJECTED: ["fas", "times"],
        COMPLAINED: ["fas", "times"],
      },
      statusIcons: {
        NULL: "times",
        PENDING: "exclamation",
        CONFIRMED: "thumbs-up",
        CHECK_IN: "check",
        DECLINE: "times",
        WILL_BE_BACK: "undo",
        CHECKED_OUT: "sign-out-alt",
      },
      statusStyle: {
        NULL: { color: "#DC143C" },
        PENDING: { color: "#555555" },
        CONFIRMED: { color: "#2980b9" },
        CHECK_IN: { color: "#ff9c00" },
        DECLINE: { color: "#DC143C" },
        WILL_BE_BACK: { color: "#9b59b6" },
        CHECKED_OUT: { color: "#1abc9c" },
      },
      statusDescription: {
        NULL: "-",
        PENDING: "pending",
        CONFIRMED: "confirmed",
        CHECK_IN: "check-in",
        DECLINE: "declined",
        WILL_BE_BACK: "will be back",
        CHECKED_OUT: "check-out",
      },
      statusOptions: [
        { value: "PENDING", label: "pending" },
        { value: "CONFIRMED", label: "confirmed" },
        { value: "CHECK_IN", label: "check-in" },
        { value: "DECLINE", label: "declined" },
        { value: "WILL_BE_BACK", label: "will be back" },
        { value: "CHECKED_OUT", label: "check-out" },
      ],
      editEmail: false,
      editConfirmationEmail: false,
      steps,
      filtersVisible: false,
    };
  },
  computed: {
    ...mapGetters(["identity", "defaultEmail"]),
    isPrivate() {
      return this.event.genre === "PRIVATE";
    },
  },
  watch: {
    email: function () {
      this.onChanges();
    },
    event: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.timeSlotEnabled) {
          const tempArray = this.audienceOptions;
          let found = false;
          tempArray.forEach((val) => {
            if (val.value === "TIME_SLOT") {
              found = true;
            }
          });
          if (!found) {
            tempArray.push({
              label: "_selectTimeSlot",
              value: "TIME_SLOT",
              canExpandable: true,
            });
            this.audienceOptions = [...tempArray];
          }
        }
      },
    },
  },
  beforeDestroy() {
    try {
      this.unsubscribeFromUpdates();
      if (this.emailSavedListener) {
        this.emailSavedListener.remove();
        this.emailSavedListener = null;
      }
      if (this.confirmationEmailSavedListener) {
        this.confirmationEmailSavedListener.remove();
        this.confirmationEmailSavedListener = null;
      }
      this.unsubscribeFromPreviewClicks();
      this.unsubscribeFromConfirmationPreviewClicks();
    } catch {}
  },
  created() {},
  mounted() {
    this.loadDraftCampaign();
  },
  methods: {
    // UI helpers
    onFilterChanged(filter) {
      this.customFieldFilter = filter;
      if (
        (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
        filter &&
        filter.question &&
        filter.type
      ) {
        this.audienceOptionFilter = {
          customFieldFilter: { ...filter },
        };
      } else {
        this.audienceOptionFilter = undefined;
      }
      this.$refs.table.getTableData();
      this.onChanges();
    },
    onAudienceSizeChanged(size) {
      if (this.audienceOption !== "CUSTOM") {
        this.audienceSize = size;
      }
      this.onChanges();
    },
    onAudienceOptionChanged(option) {
      this.audienceOption = option;
      this.audienceOptionFilter = undefined;
      this.customFieldFilter = { question: "", answer: "", type: "", source: "" };
      this.$refs.table.getTableData();
      if (option === "CUSTOM") {
        this.audienceSize = 0;
      }
      this.onChanges();
    },
    updateSelectedRecipients(data) {
      this.recipientIds = data;
      this.onChanges();
    },
    swapType() {
      const newType = this.type === "sms" ? "wa" : "sms";
      this.type = newType;
      if (newType === "wa") {
        this.withRsvp = true;
      }
    },
    // Payload verifications
    canSendCampaign() {
      const context = this;
      if (!context.body || !context.title) {
        context.$root.bus.emit("simpleToast", "Please fill in the email title and body.");
        return false;
      }
      if (context.audienceSize < 1) {
        context.$root.bus.emit(
          "simpleToast",
          this.$root.strings.directEmailMarketing._warning_recipient
        );
        return false;
      }
      return true;
    },

    // Api calls
    updateRsvpOptions() {
      this.$apollo
        .mutate({
          mutation: UPDATE_TEXT_CAMPAIGN,
          variables: {
            campaignId: this.campaignId,
            campaign: {
              setAcquaintanceInfoOnJoin: this.setAcquaintanceInfoOnJoin,
              setMaxAcquaintanceOnJoin: this.setMaxAcquaintanceOnJoin,
              withRsvp: this.withRsvp,
              withConfirmation: this.withConfirmation,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.updateTextCampaign) {
            this.steps.confirmationEmail.progress = 100;
          }
        });
    },
    unsubscribeFromUpdates() {
      try {
        this.campaignUpdatedSubscription.unsubscribe();
        this.campaignUpdatedSubscription = null;
      } catch (e) {}
    },
    async updateCampaign() {
      this.hasChanges = false;
    },
    autoSaveCampaign: debounce((context, updates, feedback = false) => {
      context.$apollo
        .mutate({
          mutation: UPDATE_TEXT_CAMPAIGN,
          variables: {
            campaignId: context.campaignId,
            campaign: updates,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.updateCampaign) {
            context.processCampaignPayload(data.updateTextCampaign);
            if (feedback) {
              context.$root.bus.emit("simpleToast", "Campaign Updated");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    async toggleConfirmationEmail(tab) {
      this.confirmationEmailTab = tab;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: this.campaignId,
            campaignInput: {
              eventId: this.eventId,
              confirmationEmailOption: tab,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log("error " + errors);
          } else {
            if (tab === "CONFIRMATION_EMAIL_ENABLED") {
              return this.createEmailConfirmation();
            } else {
              return this.deleteEmailConfirmation();
            }
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async createEmailConfirmation() {
      return this.$apollo
        .mutate({
          mutation: CREATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationInput: {
              senderEmail: this.email,
              senderName: this.name,
              eventId: this.eventId,
              campaignId: this.campaignId,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
            return;
          }
          if (data && data.createEmailConfirmation) {
            this.processConfirmationEmail(data.createEmailConfirmation);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async deleteEmailConfirmation() {
      if (!this.confirmationEmailId) {
        this.updateProgress();
        return;
      }
      return this.$apollo
        .mutate({
          mutation: DELETE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: this.confirmationEmailId,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
            return;
          }
          if (data && data.deleteEmailConfirmation) {
            this.confirmationEmailId = null;
            this.confirmationEmailPreview = "";
            this.confirmationEmailTemplate = null;
            this.confirmationEmailSenderName = "";
            this.confirmationEmailSenderEmail = "";
            this.confirmationEmailDomainName = "";
            this.confirmationEmailDomainUser = "";
            this.confirmationEmailSubject = "";
            this.confirmationEmailSubjectDisabled = true;
            this.loadingEffectshow = false;
            this.unsubscribeFromConfirmationPreviewClicks();
            this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadsuccess);
            this.updateProgress();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async createTemplate(updates) {
      const context = this;
      context.$apollo
        .mutate({
          mutation: UPDATE_TEXT_CAMPAIGN,
          variables: {
            campaignId: context.campaignId,
            campaign: updates,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.updateTextCampaign) {
            context.$root.bus.emit("simpleToast", "Campaign Updated");
          }
          this.templateCreated = true;
          this.updateProgress();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createCampaign() {
      const context = this;
      context.loadingMessage = "Creating Campaign";
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: CREATE_TEXT_CAMPAIGN,
          variables: {
            campaign: {
              eventId: context.eventId,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.createTextCampaign) {
            this.processCampaignPayload(data.createTextCampaign);
            context.campaignCreated = true;
            context.loadingEffectshow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendCampaign() {
      const context = this;
      if (this.canSendCampaign()) {
        const h = this.$createElement;
        if (this.type === "sms") {
          this.$msgbox({
            message: h("p", null, [
              h("span", null, this.$root.strings.directEmailMarketing._warnBeforeSend),
              h("br"),
              h("br"),
              h("span", null, this.$root.strings.directEmailMarketing._recap_name),
              h("strong", null, this.title),
              h("br"),
              h("span", null, this.$root.strings.directEmailMarketing._recap_email),
              h("strong", null, this.body),
              h("br"),
              h("span", null, this.$root.strings.directEmailMarketing._recap_total_recipient),
              h("strong", null, this.audienceSize),
            ]),
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText =
                  this.$root.strings.directEmailMarketing._sending_campaign;
                context.$apollo
                  .mutate({
                    mutation: SEND_TEXT_CAMPAIGN,
                    variables: {
                      campaignId: context.campaignId,
                      recipients: Array.from(context.recipientIds),
                      audienceOption: context.audienceOption,
                    },
                  })
                  .then((response) => {
                    if (response.errors) {
                      console.log(response.errors);
                      instance.confirmButtonLoading = false;
                      done();
                      return;
                    }
                    if (response.data && response.data.sendTextCampaign) {
                      this.$refs.campaignsTable.getTableData();
                      context.campaignId = response.data.sendTextCampaign.id;
                      context.subjectDisabled = true;
                      context.unsubscribeFromPreviewClicks();
                      context.setMaxAcquaintanceOnJoin = false;
                      context.setAcquaintanceInfoOnJoin = false;
                      context.withRsvp = false;
                      context.withConfirmation = false;
                      context.confirmationEmailTab = "";
                      context.confirmationEmailTemplate = null;
                      context.confirmationEmailPreview = null;
                      context.confirmationEmailSubject = "";
                      context.confirmationEmailSubjectDisabled = true;
                      context.unsubscribeFromConfirmationPreviewClicks();
                      context.loadDraftCampaign();
                      this.onChanges(false);
                    }
                    instance.confirmButtonLoading = false;
                    done();
                    context.$root.bus.emit(
                      "simpleToast",
                      context.$root.strings.directEmailMarketing._campaign_sent
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    instance.confirmButtonLoading = false;
                    done();
                  });
              } else {
                done();
              }
            },
          })
            .then((action) => {})
            .catch((_) => {});
        } else {
          this.$msgbox({
            message: h("p", null, [
              h("span", null, this.$root.strings.directEmailMarketing._warnBeforeSend),
              h("br"),
              h("br"),
              h("span", null, this.$root.strings.directEmailMarketing._recap_name),
              h("strong", null, "SAVE THE DATE"),
              h("br"),
              h("span", null, this.$root.strings.directEmailMarketing._recap_total_recipient),
              h("strong", null, this.audienceSize),
            ]),
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            beforeClose: (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText =
                  this.$root.strings.directEmailMarketing._sending_campaign;
                context.$apollo
                  .mutate({
                    mutation: SEND_WA_CAMPAIGN,
                    variables: {
                      campaignId: context.campaignId,
                      recipients: Array.from(context.recipientIds),
                      audienceOption: context.audienceOption,
                    },
                  })
                  .then((response) => {
                    if (response.errors) {
                      console.log(response.errors);
                      instance.confirmButtonLoading = false;
                      done();
                      return;
                    }
                    if (response.data && response.data.sendTextCampaign) {
                      this.$refs.campaignsTable.getTableData();
                      context.campaignId = response.data.sendTextCampaign.id;
                      context.subjectDisabled = true;
                      context.unsubscribeFromPreviewClicks();
                      context.setMaxAcquaintanceOnJoin = false;
                      context.setAcquaintanceInfoOnJoin = false;
                      context.withRsvp = false;
                      context.withConfirmation = false;
                      context.confirmationEmailTab = "";
                      context.confirmationEmailTemplate = null;
                      context.confirmationEmailPreview = null;
                      context.confirmationEmailSubject = "";
                      context.confirmationEmailSubjectDisabled = true;
                      context.unsubscribeFromConfirmationPreviewClicks();
                      context.loadDraftCampaign();
                      this.onChanges(false);
                    }
                    instance.confirmButtonLoading = false;
                    done();
                    context.$root.bus.emit(
                      "simpleToast",
                      context.$root.strings.directEmailMarketing._campaign_sent
                    );
                  })
                  .catch((error) => {
                    console.log(error);
                    instance.confirmButtonLoading = false;
                    done();
                  });
              } else {
                done();
              }
            },
          })
            .then((action) => {})
            .catch((_) => {});
        }
      }
    },
    async updateCampaignTableStatistics() {
      if (!this.refreshingPastCampaigns) {
        this.refreshingPastCampaigns = true;
        return this.$apollo
          .mutate({
            mutation: UPDATE_PAST_CAMPAIGNS,
            variables: { eventId: this.eventId },
          })
          .then(({ errors, data }) => {
            if (errors) {
              console.log("error " + errors);
            } else {
              if (data.updatePastCampaigns === "SUCCESS") {
                this.refreshingPastCampaigns = false;
                this.$refs.campaignsTable.getTableData();
              }
            }
          })
          .catch((error) => {
            console.error("[updateCampaignTableStatistics]", error.message);
          });
      }
    },
    processCampaignPayload(campaign) {
      this.campaignId = campaign.id;
      this.title = campaign.title;
      this.body = campaign.body;
      this.initialOption = campaign.audienceOption;
      this.audienceOption = campaign.audienceOption;
      this.templateCreated = true;
      this.setAcquaintanceInfoOnJoin = campaign.setAcquaintanceInfoOnJoin;
      this.setMaxAcquaintanceOnJoin = campaign.setMaxAcquaintanceOnJoin;
      this.withRsvp = campaign.withRsvp;
      this.withConfirmation = campaign.withConfirmation;
      if (campaign.audienceOptionFilter) {
        const { customFieldFilter } = campaign.audienceOptionFilter;
        if (customFieldFilter && customFieldFilter.question && customFieldFilter.type) {
          this.initialFilter = customFieldFilter;
          this.customFieldFilter = customFieldFilter;
        }
      }
      this.recipientIds = campaign.selectedGuestIdList;
      this.updateProgress();
    },
    async loadDraftCampaign() {
      const context = this;
      context.loadingMessage = "Loading Campaign";
      context.loading = true;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_TEXT_DRAFT_CAMPAIGN,
          variables: { eventId: context.eventId, type: "sms" },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.getDraftTextCampaign) {
            const payload = response.data.getDraftTextCampaign;
            if (payload) {
              this.processCampaignPayload(payload);
              context.campaignCreated = true;
            }
            this.updateProgress();
          }
          context.loading = false;
        })
        .catch((error) => {
          console.log(error);
          context.loading = false;
        });
    },
    async getPastCampaigns({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      this.unsubscribeFromUpdates();
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "sentAt";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_PAST_TEXT_CAMPAIGNS,
          variables: {
            eventId: this.eventId,
            filter: filter && filter,
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              ...input,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.log(errors);
            context.updatingCampaign = false;
            return;
          }
          if (data && data.getTextCampaigns) {
            console.log(data.getTextCampaigns)
            const payload = data.getTextCampaigns;
            return {
              data: payload.data,
              total: payload.total,
            };
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnAndDeleteCampaign() {
      const context = this;
      this.$confirm(this.$root.strings.directEmailMarketing._warnBeforeDelete)
        .then((_) => {
          context.loadingEffectshow = true;
          return this.$apollo
            .mutate({
              mutation: DELETE_CAMPAIGN,
              variables: {
                campaignId: context.campaignId,
              },
            })
            .then((response) => {
              if (response.errors) {
                console.log(response.errors);
                return;
              }
              if (response.data && response.data.deleteCampaign) {
                context.campaignId = null;
                context.campaignCreated = false;
                context.loadingEffectshow = false;
                context.subjectDisabled = true;
                context.campaignEmailTemplate = null;
                context.campaignEmailPreview = null;
                context.unsubscribeFromPreviewClicks();
                context.unsubscribeFromConfirmationPreviewClicks();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((_) => {});
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      } else {
        input["sortField"] = "status";
        input["sortOrder"] = -1;
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GUEST_LIST,
          variables: {
            eventId: this.eventId,
            filter: { hasTelephone: true, q: filter ? filter : undefined },
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"],
            },
            customFields: [],
            audienceOption: this.audienceOption || "ALL_MARKETING_GUESTS",
            audienceOptionFilter: this.audienceOptionFilter,
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.$router.push("/").catch((_) => {});
            return;
          }
          if (response.data && response.data.guests) {
            const payload = response.data.guests;
            if (context.audienceOption !== "CUSTOM" && !filter) {
              context.audienceSize = payload.total;
            }
            return {
              data: payload.data,
              total: payload.total,
            };
          }
        })
        .catch((error) => {
          // context.$router.push("/").catch(_ => {});
          console.log(error);
        });
    },
    onSelectionChange(selectedRows) {
      this.audienceSize = selectedRows.length;
      this.recipientIds = selectedRows;
      this.onChanges();
    },
    async onCancelCampaign(row) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_CAMPAIGN,
          variables: {
            campaignId: row.id,
            campaignInput: {
              eventId: context.eventId,
              isCancelled: true,
              status: "CANCELLED",
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.updateCampaign) {
          }
        })
        .catch((error) => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    onBulkAction(action, selectedRows) {
      if (action === "clearSelection") {
        this.$refs.table.$refs.elTable.clearSelection();
      }
    },
    onChanges(hasChanges = true) {
      this.hasChanges = hasChanges;
      this.updateProgress();
    },
    updateProgress() {
      const currentProgress = {
        campaign: 0,
        confirmationEmail: 0,
        audience: 0,
      };

      if (this.campaignCreated) {
        currentProgress.campaign += 20;
      }
      if (this.title) {
        currentProgress.campaign += 30;
      }
      if (this.body) {
        currentProgress.campaign += 30;
      }
      if (this.templateCreated) {
        currentProgress.campaign += 20;
      }
      if (this.audienceSize > 0) {
        currentProgress.audience = 100;
        currentProgress.confirmationEmail = 100;
      }
      this.steps.campaign.progress = currentProgress.campaign;
      this.steps.confirmationEmail.progress = currentProgress.confirmationEmail;
      this.steps.audience.progress = currentProgress.audience;
    },
    goToSettings() {
      this.$emit("changeTab", "settings");
    },
  },
};
</script>
