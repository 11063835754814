<template>
  <div class="audience-option-group">
    <div v-if="calculatingAudience && !hideCounter" class="selected-guests">
      <div></div>
      <i class="fa fa-users" aria-hidden="true" style="margin-right:15px;" />{{
        string.datagrid._selected
      }}:<ClipLoader
        :loading="calculatingAudience"
        color="#f7a30c"
        size="15px"
        style="display: inline-block; margin-left: 5px;"
      />
    </div>
    <div v-if="!calculatingAudience && !hideCounter" class="selected-guests">
      <i class="fa fa-users" aria-hidden="true" style="margin-right:15px;" />{{
        string.datagrid._selected
      }}: {{ audienceSize }}
    </div>
    <el-radio-group
      v-model="audienceOption"
      class="audience-group"
      :disabled="calculatingAudience"
      @change="onChanged"
    >
      <el-radio-button
        v-for="option in audienceOptions"
        :key="option.value"
        :label="option.value"
        class="audience-option"
      >
      <span>
        {{ string.datagrid[option.label] }} <i v-if="option.canExpandable" :class="'fa ' + (audienceOption === option.value ? ' fa-chevron-up ' : ' fa-chevron-down ')" :style="'float:right; color: ' + (audienceOption === option.value ? '#f7a30c; ' : ' #fff; ')" aria-hidden="true" />
      </span>
      </el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import { ELIGIBLE_RECIPIENT_COUNT } from "@/graphql";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
export default {
  name: "AudienceOptionGroup",
  components: { ClipLoader },
  props: {
    audienceOptions: {
      type: Array,
      required: true
    },
    eventId: {
      type: String,
      required: true
    },
    onAudienceOptionChanged: {
      type: Function,
      required: false,
      default: audienceOption => {}
    },
    onAudienceSizeChanged: {
      type: Function,
      required: false,
      default: audienceSize => {}
    },
    customFieldFilter: {
      type: Object,
      required: false,
      default: () => undefined
    },
    initialValue: {
      type: String,
      required: false,
      default: ""
    },
    hideCounter: {
      type: Boolean,
      default: false
    },
    stopCalculateEligibleRecipientCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      string: {},
      audienceOption: "",
      customFieldArray: [],
      calculatingAudience: false,
      audienceSize: 0,
      audienceOptionFilter: undefined,
      revalidateListener: null
    };
  },
  computed: {},
  watch: {
    customFieldMap: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.customFieldArray = newVal ? Object.entries(newVal) : [];
      }
    },
    initialValue: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.audienceOption = newVal;
        this.calculateEligibleRecipientCount();
      },
      deep: true
    },
    customFieldFilter: {
      immediate: true,
      handler: function(newVal, oldVal) {
        let filter = undefined;
        if (
          (this.audienceOption === "CUSTOM_FIELD" || this.audienceOption === "TIME_SLOT") &&
          newVal &&
          newVal.question &&
          newVal.type
        ) {
          filter = {
            customFieldFilter: { ...newVal }
          };
        }
        this.audienceOptionFilter = filter;
        this.calculateEligibleRecipientCount();
      },
      deep: true
    }
  },
  created() {
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      this.string = this.$root.strings;
    });

    this.revalidateListener = this.$root.bus.addListener("revalidate-audience-count", () => {
      this.calculateEligibleRecipientCount();
    });
  },
  beforeDestroy() {
    try {
      if (this.revalidateListener) {
        this.revalidateListener.remove();
        this.revalidateListener = null;
      }
    } catch {}
  },
  methods: {
    // UI helpers
    onChanged(option) {
      this.audienceSize = 0;
      this.audienceOption = option;
      if (option !== "CUSTOM") {
        this.calculateEligibleRecipientCount();
      }
      if (this.onAudienceOptionChanged && typeof this.onAudienceOptionChanged === "function") {
        this.onAudienceOptionChanged(option);
      }
    },
    async calculateEligibleRecipientCount() {
      if (!this.calculatingAudience && !this.stopCalculateEligibleRecipientCount) {
        this.calculatingAudience = true;

        return this.$apollo
          .query({
            fetchPolicy: "no-cache",
            query: ELIGIBLE_RECIPIENT_COUNT,
            variables: {
              eventId: this.eventId,
              audienceOption: this.audienceOption,
              audienceOptionFilter: this.audienceOptionFilter
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              this.calculatingAudience = false;
              return;
            }
            if (response.data && typeof response.data.eligibleRecipientCount === "number") {
              const size = response.data.eligibleRecipientCount;
              this.audienceSize = size;
              this.calculatingAudience = false;
              if (this.onAudienceSizeChanged && typeof this.onAudienceSizeChanged === "function") {
                this.onAudienceSizeChanged(size);
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.calculatingAudience = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.selected-guests {
  font-size: 20px;
  margin-bottom: 10px;
}
.audience-option-group {
  margin: 10px 0;
}
/* .audience-option {
} */
</style>

<style>
.audience-group {
	display: flex;
	flex-flow: row wrap;
  /* place-content: space-between; */
	height: 100%;
	width: 100%;
  padding:5px; /* this */
}
.audience-option{
  flex: 1 1 auto;
  display: grid;
  margin:5px;  /* and that, will result in a 10px gap */
}
</style>