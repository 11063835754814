var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "use-existing-component" }, [
    _c(
      "div",
      { staticClass: "policies-list" },
      [
        _vm._l(_vm.policies, function (policy) {
          return _vm.policies.length > 0
            ? _c("div", { staticClass: "policy" }, [
                _c("div", { staticClass: "policy-recap" }, [
                  _c("div", { staticClass: "field policy-name" }, [
                    _c("strong", [
                      _vm._v(" " + _vm._s(_vm.string.useExistingPolicy._name)),
                    ]),
                    _vm._v(" "),
                    _c("strong", [_vm._v(_vm._s(policy.name))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field policy-mandatory" }, [
                    (
                      _vm.policyEventsByPolicyId[policy.id]
                        ? _vm.policyEventsByPolicyId[policy.id].mandatory
                        : false
                    )
                      ? _c("strong", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.string.useExistingPolicy._labelMandatory
                              ) +
                              "\n            "
                          ),
                        ])
                      : _c("strong", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.string.useExistingPolicy._labelNotMandatory
                              ) +
                              "\n            "
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "field policy-edit" }, [
                    _c(
                      "div",
                      {
                        staticClass: "policy-edit-action",
                        on: {
                          click: function ($event) {
                            return _vm.archivePolicy(policy.id)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-trash",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "policy-action" }, [
                  _c("div", { staticClass: "field actions " }, [
                    _c("div", { staticClass: "single-action" }, [
                      _c("label", { staticClass: "control control-checkbox" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.string.useExistingPolicy._labelActive) +
                            "\n              "
                        ),
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: _vm.policyEventsByPolicyId[policy.id]
                              ? _vm.policyEventsByPolicyId[policy.id].active
                              : false,
                          },
                          on: {
                            change: function ($event) {
                              _vm.toggleActive(
                                policy.id,
                                typeof _vm.policyEventsByPolicyId[policy.id] !==
                                  "undefined"
                                  ? !_vm.policyEventsByPolicyId[policy.id]
                                      .active
                                  : true
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "control_indicator" }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.policyEventsByPolicyId[policy.id] &&
                    _vm.policyEventsByPolicyId[policy.id].active
                      ? _c("div", { staticClass: "single-action" }, [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.string.useExistingPolicy._labelMandatory
                                  ) +
                                  "\n              "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: _vm.policyEventsByPolicyId[policy.id]
                                    ? _vm.policyEventsByPolicyId[policy.id]
                                        .mandatory
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.toggleMandatory(
                                      policy.id,
                                      typeof _vm.policyEventsByPolicyId[
                                        policy.id
                                      ] !== "undefined"
                                        ? !_vm.policyEventsByPolicyId[policy.id]
                                            .mandatory
                                        : true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.policyEventsByPolicyId[policy.id] &&
                    _vm.policyEventsByPolicyId[policy.id].active
                      ? _c("div", { staticClass: "single-action" }, [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.string.useExistingPolicy
                                      ._labelOnlyThisEvent
                                  ) +
                                  "\n              "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: _vm.policyEventsByPolicyId[policy.id]
                                    ? _vm.policyEventsByPolicyId[policy.id]
                                        .limited
                                    : false,
                                },
                                on: {
                                  change: function ($event) {
                                    _vm.toggleLimitedToEvent(
                                      policy.id,
                                      typeof _vm.policyEventsByPolicyId[
                                        policy.id
                                      ] !== "undefined"
                                        ? !_vm.policyEventsByPolicyId[policy.id]
                                            .limited
                                        : true
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "field text privacy-content",
                    domProps: { innerHTML: _vm._s(policy.text) },
                  }),
                ]),
              ])
            : _c("div", { staticClass: "no-policies" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.string.useExistingPolicy._noPolicies) +
                    "\n    "
                ),
              ])
        }),
        _vm._v(" "),
        _c(
          "el-row",
          { staticClass: "policy-result-download" },
          [
            _c(
              "el-col",
              { attrs: { span: 18, offset: 3 } },
              [
                this.acceptedPolicies !== null
                  ? _c(
                      "vue-excel-xlsx",
                      {
                        staticClass: "save",
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: this.acceptedPolicies.data,
                          columns: this.acceptedPolicies.columns,
                          filename: "guest_list_report",
                          sheetname: "Sheet 1",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-file-excel-o",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.downloadPolicyLabel) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }