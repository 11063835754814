<template>
  <div
          class="data-grid-container"
          style="overflow:auto;">
    <table>
      <thead>
      <tr>
        <th
                v-for="columnObject in columns"
                :class="{ active: false}"
                @mouseOver="">
          {{ columnObject.label | capitalize }}

        </th>
        <th @mouseOver="" v-if="enableActions"
            :class="{ active: false}">
          {{ "Action" | capitalize }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
              v-for="entry in filteredData"
              :id="entry['id']"
              class="tableRowItem">
        <td
                v-for="columnObject in columns"
                v-html="getKey(entry,columnObject.key)"
        />

        <td class="actions" v-if="enableActions">
          <a
                  class="actions_link delete_prospect_link"
                  data-toggle="tooltip_identity"
                  data-placement="top"
                  title="Remove"
                  @click="removeIdentity(entry['identity'])">
            <i class="fa fa-trash-o">
              &nbsp;
            </i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>

  table {
    border: 2px solid #f7a30c;
    border-radius: 3px;
    background-color: #000000;
    width: 100%;
  }

  th {
    background-color: #f7a30c;
    color: rgba(255,255,255,0.66);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  td {
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255,255,255,0.87);

  }

  th, td {
    min-width: 120px;
    padding: 10px 20px;
    text-align: left;
  }

  th.active .arrow {
    opacity: 1;
  }

  .arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
  }

</style>
<script>

  export default {
    filters: {
      capitalize: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    props: {
      data: {
        type: Array,
        default: () => []
      },
      columns: {
        type: Array,
        default: () => []
      },
      enableActions: {
        type: Boolean,
        default: () => false
      }
    },
    data: function () {
      const sortOrders = {};

      this.columns.forEach((column) => {
        sortOrders[column.key] = 1;
      });

      return {
        sortKey: '',
        sortOrders: sortOrders,
        string: {}
      };
    },
    mounted (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });
    },
    computed: {
      filteredData: function () {
        let sortKey = this.sortKey;
        const order = this.sortOrders[sortKey] || 1;
        let data = this.data;
        if (sortKey) {
          data = data.slice().sort((a, b) => {
            if(sortKey === 'status') {
              a = (typeof a.verificationStatus === 'string') ? a.verificationStatus.toLowerCase() : a.verificationStatus;
              b = (typeof b.verificationStatus === 'string') ? b.verificationStatus.toLowerCase() : b.verificationStatus;
              return (a === b ? 0 : a > b ? 1 : -1) * order;
            } else if(sortKey === 'email' || sortKey === 'domain' ) {
              a = (typeof a.identity === 'string') ? a.identity.toLowerCase() : a.identity;
              b = (typeof b.identity === 'string') ? b.identity.toLowerCase() : b.identity;
              return (a === b ? 0 : a > b ? 1 : -1) * order;
            } else if(sortKey === 'updatedAt') {
              a = (typeof a.updatedAt === 'string') ? a.updatedAt.toLowerCase() : a.updatedAt;
              b = (typeof b.updatedAt === 'string') ? b.updatedAt.toLowerCase() : b.updatedAt;
              return (a === b ? 0 : a > b ? 1 : -1) * order;
            }
          });
        }
        //console.log(data);
        return data;
      }
    },
    methods: {
      sortBy: function (key) {

        this.sortKey = key;

        this.sortOrders[key] = this.sortOrders[key] * -1;

        this.setFilteredData();

      },
      tryReturnDateAsString: function (d) {

        try {

          return new Date(d).toLocaleDateString();

        } catch (e) {

          return '';

        }

      },
      getKey: function (entry, key) {
        const statusIcons = {
          PENDING:        'glyphicon glyphicon-ok-sign invitato-penguinpass',
          SUCCESS:      'glyphicon glyphicon-ok-sign join-penguinpass',
          FAILED:        'glyphicon glyphicon-ban-circle danger declined-penguinpass',
          TEMPORARY_FAILURE:   'glyphicon glyphicon-ban-circle danger declined-penguinpass'
        };
        const statusStyle = {
          PENDING: 'color: #eea236 !important;',
          SUCCESS:'color: #0ac407 !important;',
          FAILED: 'color: crimson !important;',
          TEMPORARY_FAILURE: 'color: crimson !important;',
        };
        const statusDescription = {
          PENDING:'Pending',
          SUCCESS:'Success',
          FAILED:'Failed',
          TEMPORARY_FAILURE:'Temporary failure'
        };

        if(key.toLowerCase() === 'status'){
          return '<span class="' + statusIcons[entry['verificationStatus']] + '" style="' + statusStyle[entry['verificationStatus']] + ' margin-right:5px;"></span>' + statusDescription[entry['verificationStatus']];
        }
        if(key.toLowerCase() === 'updatedat'){
          return (new Date(entry['updatedAt'])).format();
        }
        if(key.toLowerCase() === 'createdat'){
          return (new Date(entry['createdAt'])).format();
        }
        if(key.toLowerCase() === 'email'){
          return entry['identity'];
        }
        if(key.toLowerCase() === 'memberemail'){
          return entry['memberEmail'];
        }

        if (key.toLowerCase() === 'date')
        {return this.tryReturnDateAsString(entry['sendDateTime']);}

        if (key.toLowerCase() === 'report')
        {return this.getReportField(entry);}

        return entry[key];
      },
      removeIdentity: function(identity) {
        this.$emit('deleteIdentity', { identity : identity });
      },
      getReportField : function (entry) {
        const url = 'web/api/mailing/campaign/exportXsl';

        const downloadUrl = '/' + url + '/' + 'userdata.client_security_code' + '/' + entry['id'];

        return '<a style=";background-color: transparent;margin-left:10px;color:red;" href="' + downloadUrl + '" title="Remove">' + '<i class="fa fa-remove" aria-hidden="true"></i></a>';

        // return '<a style=";background-color: white;margin-left:10px;color:green;" title="Report">' + '<i class="fa fa-file-excel-o" aria-hidden="true"></i></a>';
      },

      exportReport : function (entry) {
        const context = this;

        const url = 'web/api/mailing/campaign/exportXsl';

        const downloadUrl = '/' + url + '/' + 'userdata.client_security_code' + '/' + entry['id'];

        return this.$root.axios.get(downloadUrl)
                .then((response) => {

                  if (response.data === 'CAMPAIGN_ID_REQUIRED'){
                    context.$root.bus.emit('simpleToast', 'Internal Error');
                  } else if (response.data === 'EMPTY_LOG'){
                    context.$root.bus.emit('simpleToast', 'Nothing to download');
                  } else if (response.data === 'OK'){
                    console.log('DOWNLOADING');
                  }
                })
                .catch((error) => {

                  if (error.response.message === 'CAMPAIGN_ID_REQUIRED'){
                    context.$root.toastError.emit('modalView', 'error', 'Internal error');
                    console.log(error.response.message);
                  } else if (error.response.message === 'EMPTY_LOG'){
                    context.$root.bus.emit('simpleToast', 'Nothing to download');
                  }
                });

      }
    }

  };
</script>
