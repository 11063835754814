<template>
  <el-container direction="vertical" class="virtualroom-component">
    <section-content :has-icon="true" :icon-progress="progress.virtualRoomOption" icon-text="1">
      <section-title
        :title="$root.strings.registrationFormSettings._title"
        :subtitle="$root.strings.registrationFormSettings._subtitle"
      />
      <button-tab
        :tabs="virtualRoomOptionTabs"
        :initial-tab="virtualRoomOption"
        @tabChanged="onVirtualRoomOptionChanged"
      />
    </section-content>
    <transition name="component-fade-fast" mode="out-in" appear>
      <section-content
        v-if="virtualRoomOption === 'EXTERNAL_VIRTUAL_ROOM'"
        :has-icon="true"
        :icon-progress="progress.externalRoom"
        icon-text="2"
      >
        <section-title
          :title="$root.strings.registrationFormSettings._externalRoomTitle"
          :show-separator="false"
        />
        <custom-input
          v-model.trim="virtualVenue"
          :placeholder="$root.strings.registrationFormSettings._externalRoomSubtitle"
        />
        <a class="btn gray-btn save" @click.prevent="() => updateEvent({ virtualVenue })">
          {{ $root.strings.commonActions.save }}
        </a>
      </section-content>
      <section-content
        v-else-if="virtualRoomOption === 'INTERNAL_REGISTRATION_FORM'"
        :has-icon="true"
        :icon-progress="progress.internalRoom"
        icon-text="2"
      >
        <section-title
          :title="$root.strings.registrationFormSettings._internalRoomTitle"
          :show-separator="false"
        />
        <button-tab
          :tabs="templateTabOptionTabs"
          :initial-tab="templateTabOption"
          :persist-selection="false"
          @tabChanged="onTemplateTabChanged"
        />
        <section-title
          v-if="virtualRoomPreview"
          :title="$root.strings.registrationFormSettings._previewTitle"
          :show-separator="false"
          style="margin-top: 50px"
        />
        <el-row v-if="virtualRoomPreview" class="form-field-row">
          <el-col :span="24" class="embedded-html-container">
            <iframe
              id="virtualRoomPreview"
              class="embedded-html"
              :srcdoc="virtualRoomPreview"
            ></iframe>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="virtualRoomPreview" :span="24">
            <a class="btn gray-btn save" @click.prevent="loadTemplateBuilder">
              {{ $root.strings.commonActions.edit }}
            </a>
          </el-col>
        </el-row>
      </section-content>
    </transition>
    <transition name="component-fade-fast" mode="out-in" appear>
      <section-content
        v-if="virtualRoomPreview && virtualRoomOption === 'INTERNAL_REGISTRATION_FORM'"
        :has-icon="true"
        :icon-progress="progress.meetingTime"
        icon-text="3"
      >
        <section-title
          :title="$root.strings.registrationFormSettings._meetingTimeTitle"
          :subtitle="$root.strings.registrationFormSettings._meetingTimeSubtitle"
          :show-separator="false"
        />
        <el-row style="margin: 0">
          <el-col :span="12">
            <a class="btn gray-btn save" @click.prevent="instantMeeting">
              {{ $root.strings.registrationFormSettings._instantMeeting }}
            </a>
          </el-col>
        </el-row>
        <el-row :gutter="6" style="padding: 10px 0; margin: 0">
          <span>
            {{ $root.strings.registrationFormSettings._meetingTimeSubtitle2 }}
          </span>
        </el-row>
        <el-row :gutter="10" style="margin: 0">
          <el-col :span="6">
            <el-date-picker
              v-model="virtualRoomStartDate"
              type="date"
              class="new-form-control"
              align="left"
              size="large"
              placeholder="Start date"
              format="dd/MM/yyyy"
              value-format="dd/MM/yyyy"
              popper-class="default-popper"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="6">
            <el-time-select
              v-model="virtualRoomStartTime"
              class="new-form-control"
              :picker-options="{
                start: '08:30',
                step: '00:05',
                end: '19:30',
              }"
              placeholder="Start time"
              popper-class="default-popper"
            >
            </el-time-select>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="virtualRoomDuration"
              class="new-form-control"
              placeholder="Duration"
              popper-class="default-popper"
            >
              <el-option
                v-for="item in durationList"
                :key="item.value"
                :label="item.value + ' ' + $root.strings.registrationFormSettings[item.labelKey]"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-select
              v-model="virtualRoomTimezone"
              filterable
              class="new-form-control"
              placeholder="Timezone"
              popper-class="default-popper"
            >
              <el-option
                v-for="(item, index) in timezonesList"
                :key="'timezoneIndx' + index"
                :label="item.text"
                :value="'timezoneIndx' + index"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="24">
            <span>
              {{ $root.strings.registrationFormSettings._scheduleRoomHalfAdvance }}
            </span>
          </el-col>
        </el-row>
        <el-row style="margin: 10px 0">
          <el-col :span="24">
            <a class="btn gray-btn save" @click.prevent="scheduleRooms">
              {{ $root.strings.registrationFormSettings._scheduleRoom }}
            </a>
          </el-col>
        </el-row>
        <section-title
          :title="$root.strings.registrationFormSettings._moreSettingsTitle"
          :show-separator="false"
          style="margin-top: 50px"
        />
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._pageSlug + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="hasCustomSlug" @change="toggleCustomPageSlug" />
          </el-col>
          <el-col :span="18" class="" style="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._pageSlugExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row v-if="hasCustomSlug" :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="10" :offset="5" class="" style="padding-bottom: 10px;">
            <custom-input
              v-model.trim="pageSlug"
            />
          </el-col>
          <el-col :span="9" class="">
            <a class="btn gray-btn save" @click.prevent="setPageSlug();">{{ $root.strings.registrationFormSettings._applyPageSlug }}</a>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._authNeededLabel + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="authNeeded" @change="toggleAuthNeeded" />
          </el-col>
          <el-col :span="18" class="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._authNeededExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._moderatedChatLabel + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="roomConfig.chat.moderated" @change="toggleModeratedChat" />
          </el-col>
          <el-col :span="18" class="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._moderatedChatExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._emailRequiredLabel + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="roomConfig.email" @change="toggleEmailRequired" />
          </el-col>
          <el-col :span="18" class="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._emailRequiredExplanation }}
            </p>
          </el-col>
        </el-row> -->
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._coverImageLabel + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="hasCoverImage" @change="toggleHasCoverImage" />
          </el-col>
          <el-col :span="18" class="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._coverImageExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row v-if="hasCoverImage" :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="18" :offset="6" class="">
            <a class="btn gray-btn save" @click="openFileSelection">
              {{ $root.strings.registrationFormSettings._uploadImage }}
            </a>
            <input
              id="fileinput"
              ref="fileinputVROOM"
              type="file"
              accept="image/*"
              hidden
              @change="onFileChange"
            />
          </el-col>
        </el-row>
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._customThankYou + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="hasCustomThankYou" @change="toggleCustomThankYou" />
          </el-col>
          <el-col :span="18" class="" style="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._customThankYouExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row v-if="hasCustomThankYou" :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="15" :offset="1" class="" style="padding-bottom: 10px;">
            <custom-input
              v-model.trim="customThankYou"
              :placeholder="$root.strings.registrationFormSettings._customThankYouPlaceholder"
            />
          </el-col>
          <el-col :span="7" class="">
            <a class="btn gray-btn save" @click.prevent="updateCustomThankYou">{{ $root.strings.registrationFormSettings._applyPageSlug }}</a>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._hideLoginBar + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="hideLoginBar" @change="toggleHideLoginBar" />
          </el-col>
          <el-col :span="18" class="" style="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._hideLoginBarExplanation }}
            </p>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="zero-margin-row keep-everything-centered margin-bottom-10">
          <el-col :span="5" class="">
            <span>
              {{ $root.strings.registrationFormSettings._optionalEmail + " :" }}
            </span>
          </el-col>
          <el-col :span="1" class="" style="">
            <custom-checkbox v-model="hasOptionalEmail" @change="toggleHasOptionalEmail" />
          </el-col>
          <el-col :span="18" class="" style="">
            <p style="margin: 0; padding: 0">
              {{ $root.strings.registrationFormSettings._optionalEmailExplanation }}
            </p>
          </el-col>
        </el-row>
      </section-content>
    </transition>
    <transition name="component-fade-fast" mode="out-in" appear>
      <section-content
        v-if="virtualRoomPreview && virtualRoomOption === 'INTERNAL_REGISTRATION_FORM'"
        :has-icon="true"
        :icon-progress="progress.pagePublicity"
        icon-text="4"
      >
        <section-title
          :title="$root.strings.registrationFormSettings._createdRoomsTitle"
          :subtitle="$root.strings.registrationFormSettings._createdRoomsSubtitle"
          :show-separator="false"
        />
        <data-table
          ref="table"
          :get-data="getRoomTableData"
          :filter-enabled="false"
          :show-pagination="false"
          :columns="tableColumns"
        >
          <div slot="emptyView" style="height: 0"></div>

          <span slot="lock" slot-scope="{ row }">
            <el-button
              :icon="row.locked === true ? 'el-icon-lock' : 'el-icon-unlock'"
              circle
              @click="toggleRoomLock(row.id, !row.locked)"
            ></el-button>
          </span>
          <span slot="sessionId" slot-scope="{ row }">
            {{ row.customSessionId }} ({{ row.type }})
          </span>
          <span slot="publisherPin" slot-scope="{ row }">
            {{ (row.type !== "MEETING" && row.type !== "VIRTUALSHOWROOM") ? row.publisherPin : "" }}
          </span>
          <span slot="status" slot-scope="{ row }">
            {{ videoRoomStatus[row.status] }}
          </span>
          <span slot="callStatus" slot-scope="{ row }">
            <span
              v-if="
                isExhibitor ||
                row.callStatus === 'ENDED' ||
                row.status === 'TO_BE_CREATED' ||
                row.status === 'TERMINATED'
              "
            >
              {{ videoCallStatus[row.callStatus] }}
            </span>
            <el-select
              v-else
              v-model="videoCallStatus[row.callStatus]"
              class="new-form-control"
              popper-class="default-popper"
              size="large"
              @change="(e) => updateRoomCallStatus(row.id, e)"
            >
              <el-option
                v-for="[key, label] in Object.entries(videoCallStatus)"
                :key="key"
                :label="label"
                :value="key"
                :disabled="false"
                :hidden="false"
              >
              </el-option>
            </el-select>
          </span>
          <span slot="goToRoom" slot-scope="{ row }">
            <a :href="exhibitorUrl + row.id" target="_blank">
              {{ $root.strings.registrationFormSettings._openRoom }}
            </a>
          </span>
        </data-table>

        <el-row v-if="isExhibitor" class="zero-margin-row keep-everything-centered">
          <section-title subtitle="Contact Email" :show-separator="false" />
        </el-row>
        <el-row v-if="isExhibitor" class="zero-margin-row keep-everything-centered">
          <custom-input v-model.trim="contactEmail" placeholder="Contact Email" />
        </el-row>
        <el-row v-if="isExhibitor" class="zero-margin-row keep-everything-centered" :gutter="10">
          <el-col :span="12">
            <a class="btn gray-btn save" @click.prevent="() => updateEvent({ contactEmail })">
              {{ $root.strings.commonActions.saveSettings }}
            </a>
          </el-col>
          <el-col :span="12">
            <a class="btn gray-btn save" @click.prevent="downloadFairboothLogs">
              {{ $root.strings.registrationFormSettings._downloadlogs }}
            </a>
          </el-col>
        </el-row>
        <el-row class="zero-margin-row keep-everything-centered" :gutter="10">
          <el-col v-if="virtualRoomLink" :span="24">
            <a class="btn gray-btn save" :href="virtualRoomLink" target="_blank">
              {{ $root.strings.registrationFormSettings._goToPage }}
            </a>
          </el-col>
          <el-col :span="24">
            <a class="btn gray-btn save" @click.prevent="downloadPageLogs">
              {{ $root.strings.registrationFormSettings._downloadlogs }}
            </a>
          </el-col>
        </el-row>
        <el-row style="margin: 0">
          <el-col :span="24">
            <a
              class="btn gray-btn save"
              @click.prevent="onSaveButtonClicked"
            >
              {{
                pages[selectedPageIndex] && pages[selectedPageIndex].pageStatus === "PUBLISHED"
                  ? $root.strings.registrationFormSettings._unpublishpage
                  : $root.strings.registrationFormSettings._publishpage
              }}
            </a>
          </el-col>
        </el-row>
      </section-content>
    </transition>
  </el-container>
</template>

<script>
import SectionContent from "@/components/ui/SectionContent.vue";
import { mapGetters } from "vuex";
import SectionTitle from "@/components/ui/SectionTitle.vue";
import ButtonTab from "@/components/ui/ButtonTab.vue";
import CustomInput from "@/components/ui/CustomInput.vue";
import moment from "moment";
import timezones from "timezones.json";
import {
  GET_EVENT_VIRTUAL_ROOM_CODE,
  EVENT_VIDEO_ROOM_PAGES,
  GET_VIDEO_ROOMS,
  EVENT_ALLOCATED_VIDEO_ROOMS,
  ALLOCATE_VIDEO_ROOM,
  UPDATE_EVENT,
  UPDATE_VIDEO_ROOM_PAGE,
  UPDATE_ALLOCATED_VIDEO_ROOM,
  DELETE_VIDEO_ROOM_ALLOCATION
} from "@/graphql";
import {
  VideoRoomFeatureEnumType,
  VideoRoomStatusEnumType,
  VideoCallStatusEnumType,
} from "../../types/enums";
import DataTable from "@/components/DataTable.vue";
import { getExhibitorVirtualRoomUrl } from "../../constants";
import CustomCheckbox from "@/components/ui/CustomCheckbox.vue";

export default {
  name: "VirtualRoomContainer",
  components: {
    SectionContent,
    SectionTitle,
    ButtonTab,
    CustomInput,
    DataTable,
    CustomCheckbox,
  },
  props: {
    event: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      componentName: "registration-form-container",
      hasChanges: false,
      virtualRoomOptionTabs: [{ key: "INTERNAL_REGISTRATION_FORM" }],
      templateTabOptionTabs: [{ key: "createNewTemplate" }, { key: "useExistingTemplate" }],
      virtualVenue: "",
      virtualRoomOption: "",
      templateTabOption: "",
      virtualRoomPreview: "",
      pageBuilderListener: null,
      pages: [],
      allocatedVirtualRooms: [],
      videoRooms: [],
      selectedPageIndex: 0,
      videoRoomStatus: VideoRoomStatusEnumType,
      videoCallStatus: VideoCallStatusEnumType,
      hasActiveRooms: false,

      // 3rd section - Time management
      virtualRoomStartDate: "",
      virtualRoomStartTime: "",
      virtualRoomDuration: "",
      virtualRoomTimezone: "",
      timezonesList: timezones,
      durationList: [
        { value: 1, labelKey: "_hour" },
        { value: 2, labelKey: "_hours" },
        { value: 3, labelKey: "_hours" },
        { value: 4, labelKey: "_hours" },
        { value: 5, labelKey: "_hours" },
      ],

      virtualRoomLink: null,
      contactEmail: "",
      tableColumns: [
        {
          prop: "lock",
          label: "Locked",
        },
        {
          prop: "sessionId",
          label: "Virtual Room",
          minWidth: 150,
        },
        {
          prop: "moderatorPin",
          label: "Moderator PIN",
        },
        {
          prop: "publisherPin",
          label: "Presenter PIN",
        },
        {
          prop: "status",
          label: "Room status",
        },
        {
          prop: "callStatus",
          label: "Call status",
        },
      ],
      loading: false,
      exhibitorUrl: getExhibitorVirtualRoomUrl(),
      progress: {
        virtualRoomOption: 30,
        externalRoom: 30,
        internalRoom: 30,
        meetingTime: 30,
        pagePublicity: 30,
      },
      authNeeded: false,
      roomConfig: {
        chat: {
          moderated: false
        },
        email: false
      },
      hasCoverImage: false,
      hasCustomSlug: false,
      hasCustomThankYou: false,
      customThankYou: '',
      hideLoginBar: false,
      hasOptionalEmail: false,

      //cover upload
      fileToSelect: true,
      fileList: "",
      fileListName: "",
    };
  },
  computed: {
    ...mapGetters(["features", "user", "isExhibitor"]),
  },
  watch: {
    event: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.virtualRoomOption = newVal.virtualRoomOption;
        if (newVal.virtualRoomOption === "INTERNAL_REGISTRATION_FORM" || newVal.virtualRoomOption === "EXTERNAL_VIRTUAL_ROOM") {
          this.progress.virtualRoomOption = 100;
        } else {
          this.progress.virtualRoomOption = 30;
        }
        this.virtualVenue = newVal.virtualVenue;
        if (newVal.virtualVenue !== "") {
          this.progress.externalRoom = 100;
        } else {
          this.progress.externalRoom = 30;
        }
        this.contactEmail = newVal.contactEmail;
        this.updateTableColumns();
        this.getAllVirtualRooms();
        this.getAllVirtualRoomsPages();
        this.getAllAllocatedVirtualRooms();
      },
    },
  },
  created() {
    this.confirmPageSavedListener = this.$root.bus.addListener("confirmPageSaved", (template) => {
      if (typeof this.pages[this.selectedPageIndex] === "undefined") {
        this.pages.push({
          json: {},
          html: "",
        });
      }

      let temp = template.htmlcode;
      temp = temp.replaceAll("\n", "");
      temp = temp.replaceAll("\r", "");
      temp = temp.replaceAll("\t", "");
      this.pages[this.selectedPageIndex].json = template.jsoncode;
      this.pages[this.selectedPageIndex].html = temp;
      this.pages[this.selectedPageIndex].id = template.virtualRoomPageId;
      this.virtualRoomPreview = temp;
      this.subscribeToPreviewClicks();
    });
  },
  beforeDestroy() {
    try {
      if (this.confirmPageSavedListener) {
        this.confirmPageSavedListener.remove();
        this.confirmPageSavedListener = null;
      }
      this.unsubscribeFromPreviewClicks();
    } catch (error) {}
  },
  methods: {
    updateTableColumns() {
      if (this.isExhibitor) {
        // add exhibitor column if not exists
        let exist = false;
        this.tableColumns.map((column) => {
          if (column.prop === "goToRoom") {
            exist = true;
          }
        });
        if (!exist) {
          this.tableColumns = [...this.tableColumns, { props: "goToRoom", label: "Go To Room" }];
        }
      } else {
        // remove exhibitor column if exists
        const columns = [];
        this.tableColumns.map((column) => {
          if (column.prop !== "goToRoom") {
            columns.push(column);
          }
        });
        this.tableColumns = [...columns];
      }
    },
    subscribeToPreviewClicks() {
      const context = this;
      const iFrameId = "#virtualRoomPreview";
      const height = "500px";
      $(iFrameId).contents().find("body").css({ height });
      $(iFrameId).css({ height });
      $(iFrameId)
        .contents()
        .find("body")
        .on("mouseup", (event) => {
          context.loadTemplateBuilder();
        });
      $(document).ready(() => {
        $(iFrameId).on("load", function () {
          const contents = $(this).contents();
          $(this).css({ height });
          $(contents).find("body").css({ height });
          $(contents)
            .find("body")
            .on("mouseup", (event) => {
              context.loadTemplateBuilder();
            });
        });
      });
    },
    unsubscribeFromPreviewClicks() {
      const iFrameId = "#virtualRoomPreview";
      $(iFrameId)
        .contents()
        .find("body")
        .off("mouseup", (event) => {});
    },
    onVirtualRoomOptionChanged(tab) {
      this.virtualRoomOption = tab;
      this.updateEvent({ virtualRoomOption: tab });
    },
    onTemplateTabChanged(tab) {
      if (tab === "createNewTemplate") {
        this.loadTemplateBuilder();
      } else if (tab === "useExistingTemplate") {
        this.loadTemplateSelector();
      }
      this.templateTabOption = tab;
    },
    loadTemplateBuilder() {
      const eventId = this.event.id;
      const virtualRoomPageId = this.pages[this.selectedPageIndex]
        ? this.pages[this.selectedPageIndex].id
        : null;
      const videoRooms = this.videoRooms;
      let page = null;
      try {
        page = this.pages[this.selectedPageIndex]
          ? JSON.parse(this.pages[this.selectedPageIndex].json)
          : null;
      } catch (error) {
        console.error("VirtualRoomContainer", "loadTemplateBuilder", error.message);
      }

      this.$root.bus.emit("modalPageBuilderView", { eventId, virtualRoomPageId, videoRooms, page });
    },
    loadTemplateSelector() {
      const eventId = this.event.id;
      const virtualRoomPageId = this.pages[this.selectedPageIndex]
        ? this.pages[this.selectedPageIndex].id
        : null;
      const videoRooms = this.videoRooms;
      this.$root.bus.emit("modalTemplatePageBuilderView", {
        eventId,
        virtualRoomPageId,
        videoRooms,
      });
    },
    findAllVirtualRoomTokens(html = "") {
      const attributes = [];
      try {
        const matchResult = html.match(/{penguinroom [\w]+=".+?"}/g);
        if (matchResult !== null) {
          matchResult.map((attribute) => {
            attribute = attribute.match(/([\w-]+)="(.+?)"/);
            if (attribute !== null) {
              attributes.push({ roomId: attribute[2] });
            }
          });
        }
      } catch (error) {
        console.error("VirtualRoomContainer", "findAllVirtualRoomTokens", error.message);
      }
      return attributes;
    },
    instantMeeting() {
      if (this.hasActiveRooms) {
        this.$root.bus.emit(
          "simpleToast",
          this.$root.strings.registrationFormSettings._instantMeetingWarning
        );
        return;
      }
      const start = moment();
      const remainder = 5 - (start.minute() % 5);
      const dateTime = moment(start).add(remainder, "minutes");

      this.virtualRoomStartDate = dateTime.format("DD/MM/YYYY");
      this.virtualRoomStartTime = dateTime.format("HH:mm");
      this.virtualRoomDuration = 2;
      this.virtualRoomTimezone = "timezoneIndx40";
    },
    toggleRoomLock(allocationId, locked) {
      return this.updateAllocatedVirtualRoom(allocationId, { locked });
    },
    updateRoomCallStatus(allocationId, callStatus) {
      return this.updateAllocatedVirtualRoom(allocationId, { callStatus });
    },
    async setPageSlug() {
      if (!this.pages || this.pages.length < 1) {
        return;
      }
      await this.updateVirtualRoomPage({
        slug: this.pageSlug?this.pageSlug:this.event.id,
      });
      this.getVirtualRoomCode();
    },
    toggleAuthNeeded() {
      this.updateVirtualRoomPage({
        authNeeded: !this.pages[this.selectedPageIndex].authNeeded,
      });
    },
    toggleModeratedChat() {
      this.updateVirtualRoomPage({
        roomConfig: this.roomConfig
      });
    },
    toggleEmailRequired() {
      this.updateVirtualRoomPage({
        roomConfig: this.roomConfig
      });
    },
    toggleHasCoverImage() {
      this.updateVirtualRoomPage({
        hasCoverImage: !this.pages[this.selectedPageIndex].hasCoverImage,
      });
    },
    toggleCustomPageSlug() {
      this.updateVirtualRoomPage({
        hasCustomSlug: !this.pages[this.selectedPageIndex].hasCustomSlug,
      });
    },
    toggleCustomThankYou() {
      if (!this.hasCustomThankYou){
        this.customThankYou = "";
        this.updateVirtualRoomPage({ customThankYou: null });
      }
    },
    updateCustomThankYou() {
      this.updateVirtualRoomPage({ customThankYou: this.customThankYou });
    },
    toggleHideLoginBar() {
      this.updateVirtualRoomPage({
        hideLoginBar: !this.pages[this.selectedPageIndex].hideLoginBar,
      });
    } ,
    toggleHasOptionalEmail(){
      this.updateVirtualRoomPage({
        hasOptionalEmail: !this.pages[this.selectedPageIndex].hasOptionalEmail,
      });
    },
    onSaveButtonClicked() {
      // if (this.hasChanges) {
      //   // save
      // } else {
      if (
        this.pages[this.selectedPageIndex] &&
        this.pages[this.selectedPageIndex].pageStatus === "PUBLISHED"
      ) {
        // unpublish
        this.updateVirtualRoomPage({
          pageStatus: "DRAFT",
        });
        this.progress.pagePublicity = 30;
      } else {
        // publish
        this.updateVirtualRoomPage({
          pageStatus: "PUBLISHED",
        });
        this.progress.pagePublicity = 100;
      }
      // }
    },
    downloadFairboothLogs() {
      this.$store
        .dispatch("getFairboothLogs", {
          eventId: this.event.id,
        })
        .then((result) => {
          if (result.status === "SUCCESS" && result.csv) {
            const byteNumbers = new Array(result.csv.length);
            for (let i = 0; i < result.csv.length; i++) {
              byteNumbers[i] = result.csv.charCodeAt(i);
            }
            const arr = new Uint8Array(byteNumbers);
            const blob = new Blob([arr], { type: "text/csv" });
            const href = window.URL.createObjectURL(blob, { type: "text/csv" });
            const theLink = document.createElement("a");
            theLink.href = href;
            theLink.download = "fairbooth_logs.csv";
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$root.bus.emit("simpleToast", error.data.message);
          }
        });
    },
    downloadPageLogs() {
      this.$store
        .dispatch("getPageLogs", {
          source: this.virtualRoomLink,
        })
        .then((result) => {
          if (result.status === "SUCCESS" && result.csv) {
            const byteNumbers = new Array(result.csv.length);
            for (let i = 0; i < result.csv.length; i++) {
              byteNumbers[i] = result.csv.charCodeAt(i);
            }
            const arr = new Uint8Array(byteNumbers);
            const blob = new Blob([arr], { type: "text/csv" });
            const href = window.URL.createObjectURL(blob, { type: "text/csv" });
            const theLink = document.createElement("a");
            theLink.href = href;
            theLink.download = "page_logs.csv";
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$root.bus.emit("simpleToast", error.data.message);
          }
        });
    },
    emptyFields() {
      this.fileToSelect = true;
      document.getElementById("fileinput").value = "";
    },
    openFileSelection: function () {
      this.$refs.fileinputVROOM.click();
    },
    onFileChange: function (e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      if (files[0].size > 1000000) {
        alert(this.string.coverImage._imgSizeExceed);
      } else {
        this.fileList = files[0];
        this.fileListName = files[0].name;
        this.submitCoverImage();
      }
    },
    async submitCoverImage() {
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      if (this.fileList !== null) {
        data.append("fileToUpload", this.fileList);
      }
      data.append("eventId", this.event.id);
      return this.$root.axios
        .post("/upload/vroomcover", data, config)
        .then((response) => {
          this.$root.bus.emit("simpleToast", this.$root.strings.coverImage._updateDone);
          this.emptyFields();
          this.updateEvent({ vRoomImage: response.data.filepath });
        })
        .catch((error) => {
          console.log(error.message);
          this.$root.bus.emit(
            "simpleToast",
            this.$root.strings.attachments._uploadfailed + " " + error.message
          );
          this.emptyFields();
        });
    },
    async getVirtualRoomCode() {
      if (!this.pages || this.pages.length < 1) {
        return;
      }
      if (this.pages[this.selectedPageIndex].slug) {
        this.virtualRoomLink = `${document.location.origin}/room/${this.pages[this.selectedPageIndex].slug}`;
      }
    },
    async getAllAllocatedVirtualRooms() {
      this.loading = true;
      return this.$root.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: EVENT_ALLOCATED_VIDEO_ROOMS,
          variables: {
            eventId: this.event.id,
          },
        })
        .then(({ errors, data }) => {
          this.loading = false;
          if (errors) {
            console.error("VirtualRoomContainer", "getAllAllocatedVirtualRooms", errors);
          } else {
            //success
            if (data && data.getEventAllocatedVideoRooms) {
              this.allocatedVirtualRooms = data.getEventAllocatedVideoRooms;
              this.allocatedVirtualRooms.forEach((allocatedVideoRoom) => {
                if (allocatedVideoRoom.status === "ACTIVE") {
                  this.hasActiveRooms = true;
                }
              });
              if (this.allocatedVirtualRooms.length > this.selectedPageIndex) {
                const roomAllocation = this.allocatedVirtualRooms[this.selectedPageIndex]; //took the first cuz time is the same
                this.progress.meetingTime = 100;
                const tmpStartTime = moment(
                  roomAllocation.lifespan.start,
                  "YYYY-MM-DD h:mm:ss"
                ).add(roomAllocation.utcOffset, 'hours');

                this.virtualRoomStartDate = tmpStartTime.format("DD/MM/YYYY");
                this.virtualRoomStartTime = tmpStartTime.format("HH:mm");
                this.virtualRoomDuration = moment(
                  roomAllocation.lifespan.end,
                  "YYYY-MM-DD h:mm:ss"
                ).diff(moment(roomAllocation.lifespan.start, "YYYY-MM-DD h:mm:ss"), "hours");
                for (let i = 0; i < this.timezonesList.length; i++) {
                  const timezone = this.timezonesList[i];
                  if (timezone.offset === roomAllocation.utcOffset) {
                    this.virtualRoomTimezone = `timezoneIndx${i}`;
                    break;
                  }
                }
                const waitingForRefresh = moment(
                  this.virtualRoomStartDate + " " + this.virtualRoomStartTime,
                  "DD/MM/YYYY HH:mm"
                ).diff(moment());
                if (waitingForRefresh > 0) {
                  setTimeout(async () => {
                    this.$refs.table.getTableData();
                  }, waitingForRefresh);
                }
              }
            }
          }
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "getAllAllocatedVirtualRooms", error.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
          this.loading = false;
        });
    },
    async getAllVirtualRooms() {
      this.loading = true;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_VIDEO_ROOMS,
          variables: {},
        })
        .then(({ errors, data }) => {
          this.loading = false;
          if (errors) {
            console.error("VirtualRoomContainer", "getAllVirtualRooms", errors);
          }
          if (data && data.getVideoRooms) {
            this.videoRooms = data.getVideoRooms;
            this.videoRoomsById = {};
            for (const videoRoom of this.videoRooms) {
              this.videoRoomsById[videoRoom.id] = videoRoom;
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("VirtualRoomContainer", "getAllVirtualRooms", error.message);
        });
    },
    interceptObjects(objSource, objInterface) {
        const newObj = {};
        for (const key in objSource) {
            if (objInterface.hasOwnProperty(key)) {
                // in javascript an array is a object too.
                if (objSource[key] instanceof Object && !Array.isArray(objSource[key]) && objInterface[key] instanceof Object && !Array.isArray(objInterface[key])) {
                    newObj[key] = {};
                    newObj[key] = this.interceptObjects(objSource[key], objInterface[key])
                } else {
                    newObj[key] = objSource[key];
                }

            }
        }
        return newObj;
    },
    async getAllVirtualRoomsPages() {
      this.loading = true;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: EVENT_VIDEO_ROOM_PAGES,
          variables: { eventId: this.event.id },
        })
        .then(({ errors, data }) => {
          this.loading = false;
          if (errors) {
            console.error("VirtualRoomContainer", "getAllVirtualRoomsPages", errors);
          }
          if (data && data.getVideoRoomPages) {
            this.pages = [...data.getVideoRoomPages];
            if (
              data.getVideoRoomPages.length > this.selectedPageIndex &&
              this.pages[this.selectedPageIndex].html
            ) {
              this.virtualRoomPreview = this.pages[this.selectedPageIndex].html;
              this.pageSlug = this.pages[this.selectedPageIndex].slug?this.pages[this.selectedPageIndex].slug:this.event.id;
              this.authNeeded = this.pages[this.selectedPageIndex].authNeeded;
              if (this.pages[this.selectedPageIndex].roomConfig) {
                this.roomConfig = Object.assign({
                  chat: {
                    moderated: false
                  },
                  email: false,
                  ui: {
                    transparent: false,
                    primary_color: null,
                    bubble: {
                      bg_right: null,
                      bg_left: null,
                      text_right: null,
                      text_left: null
                    }
                  }
                }, this.pages[this.selectedPageIndex].roomConfig);
                this.roomConfig = this.interceptObjects(this.roomConfig, {
                  chat: {
                    moderated: false
                  },
                  email: false,
                  ui: {
                    transparent: false,
                    primary_color: null,
                    bubble: {
                      bg_right: null,
                      bg_left: null,
                      text_right: null,
                      text_left: null
                    }
                  }
                });
              }
              this.hasCoverImage = this.pages[this.selectedPageIndex].hasCoverImage;
              this.hasCustomSlug = this.pages[this.selectedPageIndex].hasCustomSlug;
              this.customThankYou = this.pages[this.selectedPageIndex].customThankYou;
              this.hasCustomThankYou = !!this.customThankYou;
              this.hideLoginBar = this.pages[this.selectedPageIndex].hideLoginBar;
              this.hasOptionalEmail = this.pages[this.selectedPageIndex].hasOptionalEmail;
              this.subscribeToPreviewClicks();
              this.progress.internalRoom = 100;
              if (this.pages[this.selectedPageIndex].pageStatus === "PUBLISHED") {
                this.progress.pagePublicity = 100;
              }
            }
            this.getVirtualRoomCode();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("VirtualRoomContainer", "getAllVirtualRoomsPages", error.message);
        });
    },
    async updateEvent(updates = {}) {
      if (Object.keys(updates) < 1) {
        return;
      }
      this.loading = true;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              ...updates,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.error("VirtualRoomContainer", "updateVirtualRoom", errors);
          }
          this.$emit("event-modified", null);
          this.loading = false;
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "updateVirtualRoom", error.message);
          this.loading = false;
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async updateAllocatedVirtualRoom(allocationId, updates = {}) {
      if (Object.keys(updates) < 1) {
        return;
      }
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_ALLOCATED_VIDEO_ROOM,
          variables: {
            videoRoomPageId: this.pages[this.selectedPageIndex].id,
            allocatedVideoRoomId: allocationId,
            updates,
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.error("VirtualRoomContainer", "updateAllocatedVirtualRoom", errors);
          } else {
            this.$refs.table.getTableData();
            this.$root.bus.emit(
              "simpleToast",
              this.$root.strings.registrationFormSettings._uploadsuccess
            );
          }
          return data;
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "updateAllocatedVirtualRoom", error.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async updateVirtualRoomPage(updates = {}) {
      if (Object.keys(updates) < 1) {
        return;
      }
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_VIDEO_ROOM_PAGE,
          variables: {
            videoRoomPageId: this.pages[this.selectedPageIndex].id,
            updates: {
              eventId: this.event.id,
              videoRoomType:
                VideoRoomFeatureEnumType[this.pages[this.selectedPageIndex].videoRoomType],
              ...updates,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.error("VirtualRoomContainer", "updateVirtualRoomPage", errors);
          } else {
            this.getAllVirtualRoomsPages();
            this.$root.bus.emit(
              "simpleToast",
              this.$root.strings.registrationFormSettings._uploadsuccess
            );
          }
          return data;
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "updateVirtualRoomPage", error.message);
          if (error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message && error.graphQLErrors[0].message === "SLUG_ALREADY_IN_USE") {
            this.$root.bus.emit("simpleToast", this.$root.strings.registrationFormSettings._pageSlugInUse);
            return;
          }
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async scheduleRooms() {
      if (this.loading) { return; }

      //validation: check scheduled time if not from past
      const utcOffset = this.timezonesList[this.virtualRoomTimezone.replace("timezoneIndx", "")]
        .offset;
      if (
        moment.utc().diff(moment.utc(this.virtualRoomStartDate + " " + this.virtualRoomStartTime,"DD/MM/YYYY HH:mm").utcOffset(utcOffset, true), 'minutes') > 0
      ) {
        this.$root.bus.emit("simpleToast", this.$root.strings.registrationFormSettings._pickNewerDate);
        return;
      }
      this.loading = true;
      //check for penguin rooms and allocate if needed
      const dragNdroppedRooms = this.pages[this.selectedPageIndex]
        ? this.findAllVirtualRoomTokens(this.pages[this.selectedPageIndex].html)
        : [];
      const allocatedRoomsIds = [];
      if (this.allocatedVirtualRooms.length > 0) {
        for (const allocationRoom of this.allocatedVirtualRooms) {
          await this.deleteVideoRoomAllocation(allocationRoom.id);
        }
      }
      for (const dragNdroppedRoom of dragNdroppedRooms) {
        const newAllocation = await this.createVideoRoomAllocation(dragNdroppedRoom.roomId);
        if (
          newAllocation &&
          newAllocation.createVideoRoomAllocation &&
          newAllocation.createVideoRoomAllocation.id
        ) {
          allocatedRoomsIds.push(newAllocation.createVideoRoomAllocation.id);
        }
      }
      await this.updateVirtualRoomPage({
        allocatedVideoRoomIds: allocatedRoomsIds,
      });
      if (allocatedRoomsIds.length > 0) {
        //wait for room opening then refresh the status
        const waitingForRefresh = moment(
          this.virtualRoomStartDate + " " + this.virtualRoomStartTime,
          "DD/MM/YYYY HH:mm"
        ).diff(moment());
        if (waitingForRefresh > 0) {
          setTimeout(async () => {
            this.$refs.table.getTableData();
          }, waitingForRefresh);
        }
      }
      if (this.pageSlug !== this.pages[this.selectedPageIndex].slug) {
        await this.setPageSlug();
      }
      this.$refs.table.getTableData().finally(() => {
        this.progress.meetingTime = 100;
        this.loading = false;
      });
    },
    async createVideoRoomAllocation(videoRoomId) {
      const utcOffset = this.timezonesList[this.virtualRoomTimezone.replace("timezoneIndx", "")]
        .offset;
      return this.$root.$apollo
        .mutate({
          mutation: ALLOCATE_VIDEO_ROOM,
          variables: {
            input: {
              videoRoomId,
              eventId: this.event.id,
              publisherPin: Math.floor(Math.random() * 100000).toString(),
              moderatorPin: Math.floor(Math.random() * 100000).toString(),
              lifespanStart: moment(this.virtualRoomStartDate + " " + this.virtualRoomStartTime,"DD/MM/YYYY HH:mm").utcOffset(utcOffset, true).toISOString(),
              lifespanEnd: moment(
                this.virtualRoomStartDate + " " + this.virtualRoomStartTime,
                "DD/MM/YYYY HH:mm"
              )
                .utcOffset(utcOffset, true)

                .add(this.virtualRoomDuration, "hours")
                .toISOString(),
              utcOffset,
            },
          },
        })
        .then(({ errors, data }) => {
          if (errors) {
            console.error("VirtualRoomContainer", "createVideoRoomAllocation", errors);
          }
          return data;
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "createVideoRoomAllocation", error.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async deleteVideoRoomAllocation(allocatedVideoRoomId) {
      return this.$root.$apollo
        .mutate({
          mutation: DELETE_VIDEO_ROOM_ALLOCATION,
          variables: {
            id: allocatedVideoRoomId,
          },
        })
        .then(({ errors }) => {
          if (errors) {
            console.error("VirtualRoomContainer", "deleteVideoRoomAllocation", errors);
          }
        })
        .catch((error) => {
          console.error("VirtualRoomContainer", "deleteVideoRoomAllocation", error.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.attachments._uploadfailed);
        });
    },
    async getRoomTableData() {
      return new Promise(async (resolve, reject) => {
        await this.getAllAllocatedVirtualRooms()
          .then(() => {
            const result = this.allocatedVirtualRooms.map((avr) => {
              return {
                ...avr,
                type: this.videoRoomsById[avr.videoRoomId].type,
                customSessionId: this.videoRoomsById[avr.videoRoomId].customSessionId,
              };
            });
            resolve({
              data: result,
              total: result.length,
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style scoped>
</style>
