var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "heading-title text-white",
                  attrs: { id: "title" },
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(_vm.string.unsubscribePage._title) +
                      "\n                  "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
              _vm._v(" "),
              _vm.state.action
                ? _c(
                    "p",
                    {
                      staticClass: "heading-tescription lead text-gray-2",
                      attrs: { id: "description" },
                    },
                    [
                      _vm._v(
                        "\n                      " +
                          _vm._s(
                            _vm.state.isUserInput
                              ? _vm.state.action === "unsubscribe"
                                ? _vm.string.unsubscribePage
                                    ._thank_you_for_unsubscription
                                : _vm.string.unsubscribePage._action_confirm
                              : _vm.string.unsubscribePage._action_confirm
                          ) +
                          "\n                      "
                      ),
                      _vm.state.isUserInput &&
                      _vm.state.action === "unsubscribe" &&
                      _vm.organizerEmail
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:" + _vm.organizerEmail,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(_vm.organizerEmail) +
                                  "\n                      "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.state.isUserInput && _vm.state.action
                        ? _c(
                            "a",
                            {
                              staticClass: "subscribe-button",
                              on: { click: _vm.confirmAction },
                            },
                            [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.state.action === "unsubscribe"
                                      ? _vm.string.unsubscribePage
                                          ._unsubscribe_confirm
                                      : _vm.string.unsubscribePage
                                          ._resubscribe_confirm
                                  ) +
                                  "\n                      "
                              ),
                            ]
                          )
                        : _vm.state.isUserInput && _vm.state.action
                        ? _c(
                            "a",
                            {
                              staticClass: "subscribe-button",
                              on: { click: _vm.reverseAction },
                            },
                            [
                              _vm._v(
                                "\n                          " +
                                  _vm._s(
                                    _vm.state.action === "unsubscribe"
                                      ? _vm.string.unsubscribePage
                                          ._resubscribe_confirm
                                      : _vm.string.unsubscribePage
                                          ._unsubscribe_confirm
                                  ) +
                                  "\n                      "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }