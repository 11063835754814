<template>
   <header id="header-wrap">
    <div id="header"
          class="header-md fixed-top header-dark transparent" style="padding: 15px;">
      <div id="scrollspy"
            class="container header-container">
        <div class="logo font-alter-1">
          <a v-if="enableLink" href="/">
            <h1>
              <img
                  class="item-img"
                  :src="logoSource"
                  :height="logoHeight"
                  :width="logoWidth"
                  style="object-fit: cover"
                  alt="image">
            </h1>
          </a>
          <h1 v-else>
            <img
                class="item-img"
                :src="logoSource"
                height="84"
                width="210"
                style="object-fit: cover"
                alt="image">
          </h1>
        </div>
      </div>
    </div>
  </header>
</template>

<style>

</style>

<script>
export default {
  props: {
    logoSource: {
      type: String,
      default : "https://media.penguinpass.it/static/img/penguinpasslogo.png"
    },
    enableLink: {
      type: Boolean,
      default : true
    },
    logoHeight: {
      type: String,
      default : "84"
    },
    logoWidth: {
      type: String,
      default : "184"
    }
  },
    data (){
        return {

        };
    },
    methods: {}
};
</script>
