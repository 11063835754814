var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "registration-form-widget" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _vm.data && _vm.data.isEventFull
            ? [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.string.registrationPage._eventFull)),
                ]),
              ]
            : _vm.thankYouMessage
            ? [
                _c("h2", { staticClass: "text-center" }, [
                  _vm._v(_vm._s(_vm.thankYouMessage)),
                ]),
              ]
            : _c(
                "form",
                {
                  staticClass: "text-left",
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.registerDo()
                    },
                  },
                },
                [
                  _vm.loggedInUserData && !_vm.hideLoginBar
                    ? [
                        _c("p", [
                          _vm._v(
                            _vm._s(_vm.string.registrationPage._loggedInAs) +
                              " " +
                              _vm._s(_vm.loggedInUserData.name) +
                              " " +
                              _vm._s(_vm.loggedInUserData.surname) +
                              ", "
                          ),
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.logoutDo()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.string.registrationPage._logout)
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : !_vm.loggedInUserData
                    ? [
                        !_vm.hideLoginBar
                          ? _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.string.registrationPage._alreadyAccount
                                ) + " "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.openLoginModal()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.string.registrationPage._login)
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.string.registrationPage._prefillData
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h3", { staticClass: "section-title" }, [
                          _vm._v(
                            _vm._s(
                              _vm.string.registrationPage._basicInformation
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "firstName" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.string.registrationPage._firstName) +
                                  " " +
                                  _vm._s(_vm.string.registrationPage._mandatory)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.signupForm.name,
                                expression: "signupForm.name",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "firstName",
                              name: "firstName",
                              type: "text",
                              required: "required",
                            },
                            domProps: { value: _vm.signupForm.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.signupForm,
                                  "name",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "lastName" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.string.registrationPage._lastName) +
                                  " " +
                                  _vm._s(_vm.string.registrationPage._mandatory)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.signupForm.surname,
                                expression: "signupForm.surname",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "lastName",
                              name: "lastName",
                              type: "text",
                              required: "required",
                            },
                            domProps: { value: _vm.signupForm.surname },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.signupForm,
                                  "surname",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            {
                              staticClass: "control-label",
                              attrs: { for: "email" },
                            },
                            [
                              _vm._v(
                                "Email " +
                                  _vm._s(_vm.string.registrationPage._mandatory)
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.signupForm.email,
                                expression: "signupForm.email",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "email",
                              name: "email",
                              type: "text",
                              required: "required",
                            },
                            domProps: { value: _vm.signupForm.email },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.signupForm,
                                  "email",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        !_vm.noPassword
                          ? _c("div", [
                              _c("h3", { staticClass: "section-title" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.string.registrationPage._setPassword
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "password" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.string.registrationPage._password
                                      ) +
                                        " " +
                                        _vm._s(
                                          _vm.string.registrationPage._mandatory
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "input-container" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.signupForm.password,
                                        expression: "signupForm.password",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "password",
                                      name: "password",
                                      type: "password",
                                      required: "required",
                                    },
                                    domProps: {
                                      value: _vm.signupForm.password,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.signupForm,
                                          "password",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa password-toggle-icon",
                                    class: _vm.passwordVisible
                                      ? "fa-eye"
                                      : "fa-eye-slash",
                                    attrs: { "aria-hidden": "true" },
                                    on: { click: _vm.togglePasswordVisibility },
                                  }),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.string.commonActions.passwordPolicy
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c(
                                  "label",
                                  {
                                    staticClass: "control-label",
                                    attrs: { for: "password2" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.string.registrationPage
                                          ._retypePassword
                                      ) +
                                        " " +
                                        _vm._s(
                                          _vm.string.registrationPage._mandatory
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "input-container" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.signupForm.password2,
                                        expression: "signupForm.password2",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "password2",
                                      name: "password2",
                                      type: "password",
                                      required: "required",
                                    },
                                    domProps: {
                                      value: _vm.signupForm.password2,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.signupForm,
                                          "password2",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("i", {
                                    staticClass: "fa password-toggle-icon",
                                    class: _vm.passwordVisible
                                      ? "fa-eye"
                                      : "fa-eye-slash",
                                    attrs: { "aria-hidden": "true" },
                                    on: { click: _vm.togglePasswordVisibility },
                                  }),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isMemberAlreadyJoined
                    ? [
                        _c("p", { staticClass: "error-message" }, [
                          _vm._v(
                            _vm._s(_vm.string.registrationPage._alreadyJoined)
                          ),
                        ]),
                      ]
                    : [
                        _vm.memberCustomFieldArray.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { margin: "15px 0px" } },
                              [
                                _c("h3", { staticClass: "section-title" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.string.registrationPage
                                        ._moreInformation
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.memberCustomFieldArray,
                                  function (ref) {
                                    var slug = ref[0]
                                    var cField = ref[1]
                                    return [
                                      !_vm.isMemberCustomFieldAlreadyFilled(
                                        slug
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              key: slug,
                                              staticClass: "form-group",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "control-label",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(
                                                        cField.options.question
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        cField.registrationFormMandatory
                                                          ? _vm.string
                                                              .registrationPage
                                                              ._mandatory
                                                          : ""
                                                      ) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              cField.type === "MULTIPLE_CHOICE"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      staticClass:
                                                        "select form-control",
                                                      class: {
                                                        invalid:
                                                          _vm.showErrors &&
                                                          cField.registrationFormMandatory &&
                                                          _vm.memberAnswerList[
                                                            slug
                                                          ].length < 1,
                                                      },
                                                      staticStyle: {
                                                        color:
                                                          "#555 !important",
                                                      },
                                                      attrs: {
                                                        "popper-class":
                                                          "grayscale-popper",
                                                        multiple: "",
                                                        "collapse-tags": "",
                                                        filterable: "",
                                                        "multiple-limit":
                                                          cField.options
                                                            .maxSelection,
                                                        placeholder:
                                                          cField.options
                                                            .question,
                                                      },
                                                      on: {
                                                        change: function (
                                                          value
                                                        ) {
                                                          return _vm.onUpdateMultipleChoice(
                                                            slug,
                                                            value,
                                                            "team"
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.memberAnswerList[
                                                            slug
                                                          ],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.memberAnswerList,
                                                            slug,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "memberAnswerList[slug]",
                                                      },
                                                    },
                                                    _vm._l(
                                                      Object.entries(
                                                        cField.options.answers
                                                      ),
                                                      function (ref) {
                                                        var answerSlug = ref[0]
                                                        var answer = ref[1]
                                                        return _c(
                                                          "el-option",
                                                          {
                                                            key: answerSlug,
                                                            attrs: {
                                                              hidden:
                                                                answer.disabled ||
                                                                (answer.maxLimit >
                                                                  0 &&
                                                                  answer.limit >=
                                                                    answer.maxLimit),
                                                              label:
                                                                answer.label,
                                                              value: answerSlug,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  answer.label
                                                                ) +
                                                                "\n                                    "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm
                                                            .memberCustomFieldDataMap[
                                                            slug
                                                          ],
                                                        expression:
                                                          "memberCustomFieldDataMap[slug]",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class: {
                                                      invalid:
                                                        _vm.showErrors &&
                                                        cField.registrationFormMandatory &&
                                                        !_vm
                                                          .memberCustomFieldDataMap[
                                                          slug
                                                        ],
                                                    },
                                                    attrs: {
                                                      "aria-label": "",
                                                      type: "text",
                                                      placeholder:
                                                        cField.options.question,
                                                      required:
                                                        cField.registrationFormMandatory,
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm
                                                          .memberCustomFieldDataMap[
                                                          slug
                                                        ],
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.memberCustomFieldDataMap,
                                                          slug,
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.customFieldArray.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { margin: "15px 0px" } },
                              [
                                _vm.memberCustomFieldArray.length === 0
                                  ? _c("h3", { staticClass: "section-title" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.string.registrationPage
                                            ._moreInformation
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.customFieldArray, function (ref) {
                                  var slug = ref[0]
                                  var cField = ref[1]
                                  return _c(
                                    "div",
                                    { key: slug, staticClass: "form-group" },
                                    [
                                      cField.type !== _vm.TEXT_ONLY
                                        ? _c(
                                            "label",
                                            { staticClass: "control-label" },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    cField.options.question
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    cField.registrationFormMandatory
                                                      ? _vm.string
                                                          .registrationPage
                                                          ._mandatory
                                                      : ""
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      cField.type === "MULTIPLE_CHOICE"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticClass:
                                                "select form-control",
                                              class: {
                                                invalid:
                                                  _vm.showErrors &&
                                                  cField.registrationFormMandatory &&
                                                  _vm.answerList[slug].length <
                                                    1,
                                              },
                                              staticStyle: {
                                                color: "#555 !important",
                                              },
                                              attrs: {
                                                "popper-class":
                                                  "grayscale-popper",
                                                multiple: "",
                                                "collapse-tags": "",
                                                filterable: "",
                                                "multiple-limit":
                                                  cField.options.maxSelection,
                                                placeholder:
                                                  cField.options.question,
                                              },
                                              on: {
                                                change: function (value) {
                                                  return _vm.onUpdateMultipleChoice(
                                                    slug,
                                                    value,
                                                    "event"
                                                  )
                                                },
                                              },
                                              model: {
                                                value: _vm.answerList[slug],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.answerList,
                                                    slug,
                                                    $$v
                                                  )
                                                },
                                                expression: "answerList[slug]",
                                              },
                                            },
                                            _vm._l(
                                              Object.entries(
                                                cField.options.answers
                                              ),
                                              function (ref) {
                                                var answerSlug = ref[0]
                                                var answer = ref[1]
                                                return _c(
                                                  "el-option",
                                                  {
                                                    key: answerSlug,
                                                    attrs: {
                                                      hidden:
                                                        answer.disabled ||
                                                        (answer.maxLimit > 0 &&
                                                          answer.limit >=
                                                            answer.maxLimit),
                                                      label: answer.label,
                                                      value: answerSlug,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                    " +
                                                        _vm._s(answer.label) +
                                                        "\n                                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : cField.type === _vm.TEXT_ONLY
                                        ? _c("div", {
                                            staticClass:
                                              "control-label ql-editor",
                                            style: {
                                              textAlign: cField.options
                                                .alignment
                                                ? cField.options.alignment
                                                : "left",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                "" + cField.options.content
                                              ),
                                            },
                                          })
                                        : cField.type === "DATE"
                                        ? _c("input", {
                                            staticClass: "form-control",
                                            class: {
                                              invalid:
                                                _vm.showErrors &&
                                                cField.registrationFormMandatory &&
                                                !_vm.memberCustomFieldDataMap[
                                                  slug
                                                ],
                                            },
                                            attrs: {
                                              "aria-label": "",
                                              type: "date",
                                              required:
                                                cField.registrationFormMandatory,
                                            },
                                            on: {
                                              change: function (value) {
                                                return _vm.onUpdateDateField(
                                                  slug,
                                                  value.target.value
                                                )
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.customFieldDataMap[slug],
                                                expression:
                                                  "customFieldDataMap[slug]",
                                              },
                                            ],
                                            staticClass: "form-control",
                                            class: {
                                              invalid:
                                                _vm.showErrors &&
                                                cField.registrationFormMandatory &&
                                                !_vm.customFieldDataMap[slug],
                                            },
                                            attrs: {
                                              "aria-label": "",
                                              type: "text",
                                              placeholder:
                                                cField.options.question,
                                              required:
                                                cField.registrationFormMandatory,
                                            },
                                            domProps: {
                                              value:
                                                _vm.customFieldDataMap[slug],
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.customFieldDataMap,
                                                  slug,
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.timeSlotArray && _vm.timeSlotArray.length > 0
                          ? _c(
                              "div",
                              { staticStyle: { margin: "15px 0px" } },
                              [
                                _vm.memberCustomFieldArray.length === 0
                                  ? _c("h3", { staticClass: "section-title" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.string.registrationPage
                                            ._bookTimeSlots
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.timeSlotArray, function (ref) {
                                  var slug = ref[0]
                                  var cField = ref[1]
                                  return _c(
                                    "div",
                                    { key: slug, staticClass: "single-action" },
                                    [
                                      _c("label", [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(cField.options.question) +
                                            "\n                                " +
                                            _vm._s(
                                              cField.registrationFormMandatory
                                                ? "(" +
                                                    _vm.string.joinPage
                                                      ._mandatory +
                                                    ")"
                                                : ""
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticClass:
                                            "rsvp-select form-control",
                                          class: {
                                            invalid:
                                              _vm.showErrors &&
                                              cField.registrationFormMandatory &&
                                              _vm.answerList[slug].length < 1,
                                          },
                                          staticStyle: { display: "block" },
                                          attrs: {
                                            "popper-class": "grayscale-popper",
                                            clearable: "",
                                            placeholder:
                                              cField.options.question,
                                          },
                                          on: {
                                            change: function (value) {
                                              return _vm.onUpdateTimeSlot(
                                                slug,
                                                value
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.answerList[slug],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.answerList,
                                                slug,
                                                $$v
                                              )
                                            },
                                            expression: "answerList[slug]",
                                          },
                                        },
                                        _vm._l(
                                          Object.entries(
                                            cField.options.answers
                                          ),
                                          function (ref) {
                                            var answerSlug = ref[0]
                                            var answer = ref[1]
                                            return _c(
                                              "el-option",
                                              {
                                                key: answerSlug,
                                                attrs: {
                                                  hidden:
                                                    answer.disabled ||
                                                    (answer.maxLimit > 0 &&
                                                      answer.limit >=
                                                        answer.maxLimit),
                                                  label: answer.label,
                                                  value: answerSlug,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(answer.label) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.data.privacyPolicies &&
                        _vm.data.privacyPolicies.policies.length > 0
                          ? _c(
                              "div",
                              _vm._l(
                                _vm.data.privacyPolicies.policies,
                                function (policy) {
                                  return _c(
                                    "div",
                                    {
                                      key: policy.id,
                                      staticClass:
                                        "privacy-policy single-action",
                                    },
                                    [
                                      _c(
                                        "el-checkbox",
                                        {
                                          attrs: {
                                            required: policy.mandatory,
                                            type: "checkbox",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.checkPolicy(
                                                policy._id,
                                                policy.checked
                                              )
                                            },
                                          },
                                          model: {
                                            value: policy.checked,
                                            callback: function ($$v) {
                                              _vm.$set(policy, "checked", $$v)
                                            },
                                            expression: "policy.checked",
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "privacy-text",
                                            staticStyle: {
                                              "white-space": "break-spaces",
                                            },
                                            domProps: {
                                              innerHTML: _vm._s(
                                                policy.teamPrivacyPolicyId
                                                  .text +
                                                  " " +
                                                  (policy.mandatory
                                                    ? "(" +
                                                      _vm.string.joinPage
                                                        ._mandatory +
                                                      ")"
                                                    : "")
                                              ),
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.data &&
                        _vm.data.maxGuestsPerMember &&
                        _vm.data.maxGuestsPerMember > 0
                          ? [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(
                                    _vm.string.registrationPage._takeSomeone
                                  ) +
                                    " " +
                                    _vm._s(
                                      "(Max. " +
                                        _vm.data.maxGuestsPerMember +
                                        ")"
                                    )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "form-group" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.maxAcquaintance,
                                      expression: "maxAcquaintance",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "plusOnes",
                                    name: "plusOnes",
                                    type: "number",
                                    required: "required",
                                    max: _vm.data.maxGuestsPerMember,
                                    min: "0",
                                  },
                                  domProps: { value: _vm.maxAcquaintance },
                                  on: {
                                    keyup: _vm.resizeAquaitanceInfoList,
                                    change: _vm.resizeAquaitanceInfoList,
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.maxAcquaintance = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.acquaintanceInfoList,
                                function (plusOne, index) {
                                  return [
                                    _c(
                                      "h3",
                                      {
                                        key: "person-" + index,
                                        staticClass: "section-title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.registrationPage._person
                                          ) +
                                            " " +
                                            _vm._s(index + 1)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        key: "firstName-" + index,
                                        staticClass: "form-group",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label",
                                            attrs: {
                                              for: "firstName-" + index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.string.registrationPage
                                                  ._firstName
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.string.registrationPage
                                                    ._mandatory
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.name,
                                              expression: "plusOne.name",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "firstName-" + index,
                                            name: "firstName-" + index,
                                            type: "text",
                                            required: "required",
                                          },
                                          domProps: { value: plusOne.name },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "name",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        key: "lastName-" + index,
                                        staticClass: "form-group",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label",
                                            attrs: { for: "lastName-" + index },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.string.registrationPage
                                                  ._lastName
                                              ) +
                                                " " +
                                                _vm._s(
                                                  _vm.string.registrationPage
                                                    ._mandatory
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.surname,
                                              expression: "plusOne.surname",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "lastName-" + index,
                                            name: "lastName-" + index,
                                            type: "text",
                                            required: "required",
                                          },
                                          domProps: { value: plusOne.surname },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "surname",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        key: "email-" + index,
                                        staticClass: "form-group",
                                      },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass: "control-label",
                                            attrs: { for: "email-" + index },
                                          },
                                          [
                                            _vm._v(
                                              "Email " +
                                                _vm._s(
                                                  _vm.hasOptionalEmail
                                                    ? _vm.string
                                                        .registrationPage
                                                        ._optional
                                                    : _vm.string
                                                        .registrationPage
                                                        ._mandatory
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: plusOne.email,
                                              expression: "plusOne.email",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            id: "email-" + index,
                                            name: "email-" + index,
                                            type: "text",
                                            required: !_vm.hasOptionalEmail,
                                          },
                                          domProps: { value: plusOne.email },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                plusOne,
                                                "email",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                }
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c("div", {
                            staticClass: "error-message",
                            domProps: { innerHTML: _vm._s(_vm.joinMessage) },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-submit",
                              attrs: { name: "submit", type: "submit" },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.string.registrationPage._register)
                              ),
                            ]
                          ),
                        ]),
                      ],
                ],
                2
              ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }