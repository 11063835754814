export default function fromObjectLiteralToMap(objectLiteral = {}) {
  let outputMap = new Map();
  try {
    for (const [key, value] of Object.entries(objectLiteral)) {
      outputMap.set(key, value);
    }
  } catch (error) {
    console.log(error.message);
    outputMap = new Map();
  }
  return outputMap;
}
