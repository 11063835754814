<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Quick Start
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/it-quickstart">
              Quick Start
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come creare un nuovo evento
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come caricare una lista di invitati
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come attivare pulsanti RSVP
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come generare un QR code quando un invitato conferma
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come personalizzare ogni invito con nome e cognome dell’invitato
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come creare una landing page personalizzata
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come creare un nuovo evento
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dall’homepage del Dashboard, cliccando sull’icona con il simbolo “+” è possibile creare un nuovo evento.<br>
            Sarà necessario selezionare se si tratta di un evento “privato” o “pubblico”.
            Indipendentemente dalla scelta per completare la creazione si dovrà riempire
            obbligatoriamente i campi: “Categoria, Nome, Data Inizio, Data Fine e Indirizzo”,
            facoltativamente i campi “Locandina e Descrizione”.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come caricare una lista di invitati
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dopo essere entrati nella sezione “guest list”, per popolare la lista è possibile inserire i dati degli
            invitati agendo:<br>
            <b>MANUALMENTE</b> inserendo invitato dopo invitato attraverso il button “+ aggiungi
            manualmente” riempendo i campi proposti: “Nome, Cognome, email e telefono”. <br>
            <b>AUTOMATICAMENTE</b> caricando una lista precompilata su un foglio excel attraverso il
            button “carica lista”.<br><br>
            Il foglio excel deve essere organizzato secondo particolari specifiche, infatti è necessario
            utilizzare il “template per importazione xls” proposto sotto il botton “sfoglia”. Una volta
            scaricato basterà seguire le indicazioni presenti nelle prime sei righe del foglio di lavoro excel:<br>
            non eliminare le prime sei righe; <br> eliminare l’ospite fittizio “Mario Rossi” (posto come
            esempio);<br>
            inserire i dati dei propri ospiti rispettando le colonne assegnate ai vari campi. <br>
            Il foglio excel contenente la lista dovrà essere selezionato attraverso il button “sfoglia” e poi
            in seguito per avviare il caricamento si dovrà selezionare il button “carica”
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come attivare i pulsanti RSVP
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            I bottoni di rsvp e decline permettono ai tuoi ospiti di accettare o rifiutare i tuoi inviti, guarda il
            video
            per imparare come si creano questi componenti.
          </p>
          <video width="100%" controls>
            <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4"
                    type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come generare un QR code quando un invitato conferma
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nel caso in cui si voglia gestire l’accesso degli ospiti attraverso Qr code, sarà necessario
            inviare una email di conferma contenente il QR Code necessario per accedere all’evento.
            Semplicemente dopo aver generato una email di conferma nella sezione “impostazioni”
            insieme a tutto quello che si desidera inserire nella email (es. info e event recap), andrà
            aggiunto un “contenuto dinamico” in un modulo vuoto. <br>
            Cosi facendo gli invitati riceveranno, trascorsi 5-10 minuti dal momento della conferma, una
            email personalizzata come in EMAILBUILDER in precedente e contenente il QR Code. <br><br>
            <b>COME VISUALIZZARE IL QR CODE ALCENTRO NELLA MAIL</b> <br>
            Quando si inserisce il “contenuto dinamico” nel modulo vuoto in Emailbuilder, se si
            utilizza un “modulo unico” (il primo nella sezione moduli) il Qr code verrà visualizzato decentrata a
            sinistra nell’email, se si desidera che invece venga visualizzato al centro sarà
            necessario semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in
            tre sezioni” (il settimo nella lista dei moduli) e inserire il contenuto dinamico in quella
            centrale.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come personalizzare ogni invito con nome e cognome dell’invitato
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Quando si genera una campagna email è spesso fondamentale far sentire i propri
            ospiti unici e speciali, per questo è necessario far si che ognuno di essi riceva una
            email personalizzata con il proprio Nome e Cognome. <br>
            Farlo con l’EMAILBUILDER durante la creazione dell’email è semplice e immediato,
            basta inserire in un “contenuto testo” attraverso la barra di lavoro (che si apre
            quando si interagisce con il corpo del testo) e scegliere “campi dinamici”, selezionare
            se inserire il nome, il cognome o entrambi. All’interno del testo compariranno quindi
            voci del tipo “{{ name }}”, queste si trasformeranno poi nei nomi dei singoli
            invitati quando verrà lanciata la campagna.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come creare una landing page personalizzata
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>Impara come creare la landing page personalizzata per il tuo evento in pochi semplici passi.</p>
          <video width="100%" controls>
            <source
              src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4"
              type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskQuickStartIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
