<template>
  <MainLayout>
    <div class="privacyPolicy">
      <table
        v-if="!newPolicy"
        class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Description</th>
            <th>Mandatory</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="policy in policies"
            :key="policy.id">
            <td>{{ policy.name }}</td>
            <td>{{ policy.title }}</td>
            <td v-html="policy.txt"></td>
            <td>{{ policy.mandatory == 1 ? "Yes" : "No" }}</td>
            <td class="text-center">
              <span
                v-if="!policy.privacypolicy_id"
                class="actionIcon glyphicon glyphicon-edit"
                @click="editPrivacyPolicy(policy)"></span>
              <span
                v-if="!policy.privacypolicy_id"
                class="actionIcon glyphicon glyphicon-remove-sign"
                @click="deletePrivacyPolicy(policy.id)"></span>
              <span
                class="actionIcon glyphicon glyphicon-list"
                @click="downloadPrivacyPolicyProspects(policy.id)"></span>
            </td>
          </tr>
        </tbody>
      </table>

      <div v-if="newPolicy">
        <table class="table newPolicy">
          <tr>
            <th>Policy Name:</th>
            <td>
              <input
                v-model.trim="newPolicy.name"
                class="form-control"
                type="text">
            </td>
          </tr>
          <tr>
            <th>Policy Title:</th>
            <td>
              <input
                v-model.trim="newPolicy.title"
                class="form-control"
                type="text">
            </td>
          </tr>
          <tr>
            <th>Policy Content:</th>
            <td>
              <!-- <vue-editor v-model="newPolicy.txt"></vue-editor> !-->
            </td>
          </tr>
          <tr>
            <th>Mandatory:</th>
            <td>
              <div
                class="squaredThree"
                style="display: inline">
                <input
                  id="one"
                  v-model="newPolicy.mandatory"
                  type="checkbox"
                  style="width: 19px;">
                <label for="one"></label>
              </div>
              <button
                class="btn btn-primary"
                @click="submit">
                Save
              </button>
              <button
                class="btn btn-primary"
                @click="newPolicy = false">
                Cancel
              </button>
            </td>
          </tr>
        </table>
      </div>

      <button
        v-show="!newPolicy"
        class="btn btn-success"
        style="margin-bottom: 50px;"
        @click="downloadAllProspects()">
        Download Full List <span class="actionIcon glyphicon glyphicon-download-alt"></span>
      </button>
      <button
        v-show="!newPolicy"
        class="btn btn-primary"
        style="margin-bottom: 50px;"
        @click="newPolicy = []">
        Add
      </button>
    </div>
  </MainLayout>
</template>
<style scoped>
    .privacyPolicy{
        margin: 10px 10px 60px;
    }
    .actionIcon:hover {
        cursor: pointer;
    }
    .btn:hover {
        color: #333333 !important;
        background-color: #dadada !important;
        border-color: #dadada !important;
    }

    .btn{
        border: solid 1px #333;
        text-transform: uppercase;
        letter-spacing:4px;
        font-size:12px;
        transition: 1s ease;
        background-color: #333333;
        border-color: inherit;
        float: right;
    }
    .newPolicy td {
        padding: 10px 15px;
    }
</style>
<script>
import MainLayout from '../layouts/Main.vue';
import userdata from './../userdata.js';
//import { VueEditor } from 'vue2-editor'

export default {
    name: 'PrivacyPolicies',
    components: {
        MainLayout,
        //VueEditor
    },
    data (){
        return {
            content: 'Some initial content',
            policies: [],
            inputs: [],
            newPolicy: false,
            loadInProgress: false,
            string:{
                events:{
                    _noEvents : 'Nessun evento caricato.',
                    _progress : 'Caricamento in corso...',
                }
            }
        };
    },
    computed: {
    },
    watch: {
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
        this.init();
    },
    methods: {
        addRow () {
            this.policies.push({
                one: '',
                two: ''
            });
        },
        deleteRow (index) {
            this.inputs.splice(index,1);
        },
        randomIndex: function () {
            return Math.floor(Math.random() * this.items.length);
        },
        init: function () {
            const context = this;
            const e = this.getPolicies();
            e.then((result) => {
                context.policies = result;
            });
        },
        getPolicies () {
            const context = this;
            context.loadInProgress = true;
            return this.$root.axios.post('/web/api/privacypolicies/all', {
                client_security_code: userdata.client_security_code,
            }).then((response) => {
                context.loadInProgress = false;
                return response.data.data;
            }).catch((error) => {
                context.loadInProgress = false;
                console.log(error);
            });
        },
        submit : function (){
            const context = this;
            this.inAction = true;

            if (!context.newPolicy.name){
                context.$root.bus.emit('simpleToast', 'Name is required');
            } else if (!context.newPolicy.title){
                context.$root.bus.emit('simpleToast', 'Title is required');
            } else if (!context.newPolicy.txt.trim()){
                context.$root.bus.emit('simpleToast', 'Content is required');
            } else {
                if (context.newPolicy.mandatory == null)
                {context.newPolicy.mandatory = false;}

                if (context.newPolicy.id) {
                    return this.$root.axios.post('/web/api/privacypolicies/update', {
                        client_security_code: userdata.client_security_code,
                        organizer_id: context.newPolicy.organizer_id,
                        name: context.newPolicy.name,
                        title: context.newPolicy.title,
                        txt: context.newPolicy.txt,
                        mandatory: context.newPolicy.mandatory,
                        id: context.newPolicy.id
                    }).then((response) => {
                        context.inAction = false;
                        context.init();
                        context.newPolicy = false;
                        context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);  /** Emit di al gestore globale */
                    }).catch((error) => {
                        context.inAction = false;
                        context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);  /** Emit di al gestore globale */
                    });
                } else {
                    return this.$root.axios.post('/web/api/privacypolicies/add', {
                        client_security_code: userdata.client_security_code,
                        name: context.newPolicy.name,
                        title: context.newPolicy.title,
                        txt: context.newPolicy.txt,
                        mandatory: context.newPolicy.mandatory,
                    }).then((response) => {
                        context.inAction = false;
                        context.init();
                        context.newPolicy = false;
                        context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);  /** Emit di al gestore globale */
                    }).catch((error) => {
                        context.inAction = false;
                        context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);  /** Emit di al gestore globale */
                    });
                }
            }

        },
        deletePrivacyPolicy : function (id){
            const context = this;
            this.inAction = true;

            if (confirm('Are you sure you want to delete this privacy Policy')) {
                return this.$root.axios.post('/web/api/privacypolicies/delete', {
                    client_security_code: userdata.client_security_code,
                    id: id
                }).then((response) => {
                    context.inAction = false;
                    context.init();
                    context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._updateDone);  /** Emit di al gestore globale */
                }).catch((error) => {
                    context.inAction = false;
                    context.$root.bus.emit('simpleToast', context.string.urlPrivacyPolicy._error);  /** Emit di al gestore globale */
                });
            }
        },
        editPrivacyPolicy : function (policy){
            const context = this;
            this.inAction = true;
            context.newPolicy = policy;
        },
        downloadAllProspects : function (){
            window.location.href = '/web/api/privacypolicies/prospectslistexport/' + userdata.client_security_code;
        },
        downloadPrivacyPolicyProspects : function (id){
            window.location.href = '/web/api/privacypolicies/prospectslistexport/' + userdata.client_security_code + '/' + id;
        },
    }
};
</script>
