<template>
  <div class="tickets-component">
    <h4 class="form-section-title first">
      {{ string.tickets._title }}
      <small>{{ string.tickets._subtitle }}</small>
    </h4>
    <div class="smallseparator"></div>

    <div
      class="row"
      style="position: relative">
      <div
        v-if="componentDisabled"
        class="disable-layer"
        @click="disableLayerClicked">
&nbsp;
      </div>
      <div class="hidden-sm col-md-3 col-lg-3">
&nbsp;
      </div>
      <div class="col-sm-12 col-md-6 col-lg-6">
        <a
          class="btn import-man"
          :title="string.tickets._add"
          @click="insertSingle">
          <i
            class="fa fa-plus"
            aria-hidden="true"></i>
          {{ string.tickets._add }}
        </a>
      </div>
      <div class="hidden-sm col-md-3 col-lg-3">
&nbsp;
      </div>
    </div>

    <!-- Tab Content -->
    <div class="row tabs-content">
      <transition
        name="component-fade-fast"
        mode="out-in">
        <div
          v-if="tabValue === 'insertsingle'">
          <InsertTicketForm
            :event-id="event.id.toString()"
            :father-name="componentName"
            @component_event="insertTicketForm"></InsertTicketForm>
        </div>
      </transition>
    </div>

    <!-- DataTable -->
    <div
      v-if="dataTableShow"
      class="datatable-container">
      <div
        v-if="noTickets"
        class="datatable no-data">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
            {{ noDataTxt }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="datatable data">
        <h4 class="form-section-title second">
          Lista<small></small>
        </h4><div class="smallseparator"></div>
        <div class="table">
          <DataGridTickets
            :data="gridData"
            :columns="gridColumns"
            :filter-key="gridFilterKey"
            @deleteListItem="deleteListItemFunction"></DataGridTickets>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

    .disable-layer{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background-color: rgba(255, 255, 255, 0.8);*/
        background-color: #00000000;
    }

    .no-data{
        padding: 30px;
        padding-top: 0;
        text-align: center;
        font-size: 40px;
        text-transform: uppercase;
        color: #e8e8e8;
    }

     a.btn{
        cursor: pointer;
        display: block;
        text-transform: uppercase;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        color: #ffffff;
        border: 0 !important;
        margin-bottom: 30px;
    }

    .btn:hover{
        background-color: #dadada !important;
        color: #333;
        cursor: pointer;
        border: 0 !important;
    }

    .tickets-component {
        margin-top: 50px;
    }

    .form-section-title{
        padding-bottom: 5px;
        margin-top: 20px;
        color: #f7a30c;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    .form-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }

    .component-fade-fast-enter-active, .component-fade-fast-leave-active {
        transition: opacity .3s ease;
    }
    .component-fade-fast-enter, .component-fade-fast-leave-active {
        opacity: 0;
    }
    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .9s ease;
    }
    .component-fade-enter, .component-fade-leave-active {
        opacity: 0;
    }

</style>
<script>
import DataGridTickets from '../components/DataGridTickets.vue';
import InsertTicketForm from '../components/InsertTicketForm.vue';
import userdata from './../userdata.js';

export default {
    components:{
        DataGridTickets,
        InsertTicketForm
    },
    props: {
        eventId: String,
        event: Object,
    },
    data (){
        return {
            componentName: 'tickets',
            app_settings: userdata.app_settings,
            string: {
                /*
                    tickets: {
                        _title: "Biglietti",
                        _subtitle: "Gestisci la lista di biglietti associata al tuo evento",
                        _nodata: "Evento gratuito",
                        _nodata_payable: "Evento a pagemento su altri sistemi",
                        _add: "Aggiungi un biglietto",
                        _confirmDelete: 'Vuoi veramente cancellare questo titolo',
                        _itemCancellato : "titolo rimosso",
                        _itemAdded : "titolo aggiunto"
                    }
                    */
            },
            componentDisabled : true,
            tickets: [],
            noTickets: true,
            dataTableShow: false,
            gridData: [],
            gridColumns: userdata.app_settings.app_name === 'gobella' ? [ 'name', 'price', 'availability' ] : [ 'name', 'type', 'price', 'availability' ],
            gridFilterKey: '',
            tabValue: '',
            noDataTxt: '',
            loadingEffectshow: false,
            firstTimeGone: false
        };
    },
    watch: {
        event: function (){
            // TODO REPLACE WIITH TICKET QUERY
            // this.init(this.event.ticket);
        }
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    mounted (){
        this.isComponentDisabled(); /** Disable Component */
    },
    methods:{
        init: function (tickets){
            this.setTickets(tickets);
            this.areThereTickets();
            this.dataTableShow = true;
        },
        isComponentDisabled : function (){
            if (this.$root.userPermissions.indexOf('canCreateNONFreeEvent') >= 0){
                this.componentDisabled = false;
            }
        },
        disableLayerClicked: function (){
            this.$root.bus.emit('notAuthorizedOptionToast');  /** Emit di al gestore globale */
        },
        setTickets: function (ticketList){
            this.noDataTxt = this.string.tickets._nodata;
            for (let i=0; i< ticketList.length; i++){
                if (ticketList[i].type != null && ticketList[i].type != 'payable' ){
                    this.tickets.push(ticketList[i]);
                }
                if (ticketList[i].type == 'payable' ){
                    this.noDataTxt = this.string.tickets._nodata_payable;
                }
            }
            this.gridData = this.tickets;
        },
        areThereTickets: function (){
            if (this.tickets.length > 0){
                this.noTickets = false;
                // could be not free
                this.emitEventPaymentTypeChange(false);
            } else {
                this.noTickets = true;
                // could be free
                this.emitEventPaymentTypeChange(true);
            }
        },
        emitEventPaymentTypeChange: function (isEventFree){

            if (this.firstTimeGone==true){
                console.log('emesso evento globale di cambio di stato');
                this.$root.bus.emit('eventPaymentTypeChange',{ free : isEventFree });  /** Emit di al gestore globale */
            }

            this.firstTimeGone = true;
        },
        insertSingle: function (){
            this.tabVisible = true;
            this.tabValue = 'insertsingle';
        },
        deleteListItemFunction: function (item){
            if (confirm(this.string.tickets._confirmDelete)){
                this.apiDeleteItem(item);
            }
        },
        apiDeleteItem: function (item){
            const id = item.item;
            const e = this.eventId;
            const context = this;
            context.loadingEffectshow = true; /** Nessun effetto di caricamento corrisponde a questa fn */
            // TODO ADD DELETE Ticket mutation
            // return this.$root.axios.post('/web/api/events/remove/ticket', {
            //     client_security_code: userdata.client_security_code,
            //     event_id: e,
            //     ticket_id: id,
            // }).then((response) => {
            //     const tickets = response.data.data.ticket;
            //     context.tickets = [];
            //     context.init(tickets);
            //     context.loadingEffectshow = false;
            //     context.$root.bus.emit('simpleToast', context.string.tickets._itemCancellato);  /** Emit di al gestore globale */
            // }).catch((error) => {
            //     context.loadingEffectshow = false;
            // });
        },
        insertTicketForm : function (event){
            const context = this;
            context.tickets = [];
            context.init(event.ticket);
            context.tabValue = '';
            context.$root.bus.emit('simpleToast', context.string.tickets._itemAdded);  /** Emit di al gestore globale */
        }
    }
};
</script>
