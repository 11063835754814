var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade in",
        staticStyle: { display: "block" },
        attrs: {
          id: "modalEmailBuilder",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "false",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-hidden": "true" },
                  on: { click: _vm.fadeout },
                },
                [_vm._v("\n            ×\n          ")]
              ),
              _vm._v(" "),
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [_vm._v("\n            PENGUINPASS EMAIL BUILDER\n          ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.templateSaving === true || _vm.templateUpdating === true
                      ? _c("div", { attrs: { id: "template_save_area" } }, [
                          _c(
                            "div",
                            { attrs: { id: "template-save-command-area" } },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.templateDisplayName,
                                    expression: "templateDisplayName",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  name: "template_name",
                                  placeholder:
                                    _vm.string.modalEmailBuilder._templateName,
                                },
                                domProps: { value: _vm.templateDisplayName },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.templateDisplayName =
                                      $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.templateSubject,
                                    expression: "templateSubject",
                                  },
                                ],
                                attrs: {
                                  type: "text",
                                  name: "template_subject",
                                  placeholder:
                                    _vm.string.modalEmailBuilder
                                      ._templateSubject,
                                },
                                domProps: { value: _vm.templateSubject },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.templateSubject = $event.target.value
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.templateSaving === true
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { id: "email-template-save" },
                                      on: { click: _vm.saveAsTemplateApi },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.modalEmailBuilder._save
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.templateUpdating === true
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { id: "email-template-update" },
                                      on: { click: _vm.updateTemplateApi },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.modalEmailBuilder._update
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: { id: "email-template-cancel" },
                                  on: { click: _vm.templateSavingAbort },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.string.modalEmailBuilder._cancel
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast-select-mode",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.startMenu
                      ? _c(
                          "div",
                          { attrs: { id: "start-menu" } },
                          [
                            _c("EmailBuilderSelectMode", {
                              attrs: {
                                "can-create-template": _vm.canCreateTemplate,
                              },
                              on: {
                                selectTemplate: _vm.selectTemplate,
                                newTemplate: _vm.newTemplate,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast-list-template",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.startMenu === false && _vm.templateList
                      ? _c(
                          "div",
                          { attrs: { id: "template-list" } },
                          [
                            _c("EmailBuilderSelectTemplates", {
                              attrs: {
                                lang: _vm.lang,
                                "client-security-code": _vm.clientSecurityCode,
                                "user-id": _vm.userId,
                                "can-create-template": _vm.canCreateTemplate,
                              },
                              on: {
                                templateSelected: _vm.templateSelected,
                                templateCloned: _vm.templateCloned,
                                goCreateTemplate: _vm.newTemplate,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.startMenu === false && _vm.emailBuilder
                  ? _c(
                      "div",
                      {
                        attrs: { id: "backtotemplate" },
                        on: { click: _vm.selectTemplate },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.string.modalEmailBuilder._backToTemplates
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast-email-builder",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.startMenu === false && _vm.emailBuilder
                      ? _c("div", { attrs: { id: "bee-plugin-container" } })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modalBackgroundColor",
            on: { click: _vm.warnThenClose },
          },
          [_vm._v("\n       \n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }