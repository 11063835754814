<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Virtual Room e Webinar
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/it-virtualroom">
              Virtual Room e Webinar
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come creare una landing page personalizzata
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come programmare una Virtual Room
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come funziona il modello Webinar
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Cos'è la Virtual Venue?
            </a>
          </li>
          <!--li><a href="#article-5" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come aggiungere link a pagine per eventi “content”</a></li-->
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come creare una landing page personalizzata
            <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg" ratio="1">
              <polyline fill="none" stroke="#000" stroke-width="1.2" points="1 9 9 1 17 9 "></polyline>
            </svg>
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>Impara come creare la landing page personalizzata per il tuo evento in pochi semplici passi.</p>
          <video width="100%" controls>
            <source
              src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4"
              type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come programmare una Virtual Room
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>Impara come programmare una virtual room di tipo meeting o webinar dal tuo dashboard.</p>
          <video width="100%" controls>
            <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/programmare_una_room.mp4"
                    type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come funziona il modello Webinar
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Il modello Webinar è costituito da tre tipi diversi di ruoli:<br>
            - Moderatore: può moderare la chat all'interno del Webinar, può avviare, mettere in pausa e terminare il
            Webinar
            e può infine richiedere l'attivazione di, o rimuovere, webcam e microfono ai Presenters e Guests.<br>
            -Presenter: può attivare e disattivare in autonomia la propria webcam e il proprio microfono, deve accedere
            inserendo il pin, anche prima dell'effettivo inizio del webinar.<br>
            -Guest: entra con microfono e webcam spenta, può accedere solo dopo l'inizio ufficiale del Webinar. Il Guest
            può
            "salire sul palco" attivando webcam e microfono solo se invitato da un moderatore.<br>
            Il Webinar può ospitare fino a 250 guests e fino a 3 tra presenters e moderatori. <br>
            - assicurarsi che i browser utilizzati abbiano il consenso per accedere a webcam e microfono.<br>
            - Entrate prima come Moderatori e Presenter inserendo i pin indicati nel dashboard. Dopodichè selezionate
            microfono e telecamera e accedete al webinar.<br>
            - N.B. una volta selezionata la webcam e il microfono il webinar potrebbe metterci tra i 30 e 60 secondi ad
            avviarsi.<br>
            - Quando sarete pronti a far partire il webinar cliccate sul tasto play così gli ospiti potranno accedere
            all'interno webinar.<br>
            - Quando l'evento sarà terminato, cliccate sul bottone "End Call" che terminerà manualmente il webinar. Il
            webinar si chiuderà automaticamente al termine della durata impostata in fase di programmazione.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Cos'è la Virtual Venue?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            La virtual venue è una landing page esterna, non creata su Penguinpass. Inserendo il link nell'apposito
            spazio all'interno della sezione Virtual room, i vostri ospiti dopo aver fatto RSVP e aver confermato
            vedranno un bottone "Accedi alla Virtual Venue" per accedere alla pagina collegata.<br>
          </p>
        </article>
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come aggiungere link a pagine per eventi “content”<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Nel caso di eventi “content” quando aprono un evento in “guestApp” si troveranno un
collegamento diretto ad una pagina o si aprirà direttamente un pdf, nel caso di
reindirizzamento ad una pagina web, per inserire il link, è sufficiente andare nelle
impostazioni da dashboard e inserire il link nella voce “endpoint di conferma esterno”.</p>
        </article-->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskVirtualroomIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
