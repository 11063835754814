<template>
    <div>
        <h4 class="form-section-title first">
            {{string.organizerSettings._profileHeader}}
            <small>{{string.organizerSettings._profileSubHeader}}</small>
        </h4>
        <div class="smallseparator"></div>
        <form id="user-data-form" onsubmit="return handleSaveOrganizer()">

            <h4 class="form-section-title no-border-bottom">{{string.organizerSettings._profilePersonalSection}}</h4>
            <input type="text" class="form-control" :placeholder="string.organizerSettings._placeholderName" v-model="organizer.name">
            <input type="text" class="form-control" :placeholder="string.organizerSettings._placeholderSurname" v-model="organizer.surname">


            <h4 class="form-section-title no-border-bottom">{{string.organizerSettings._profileCompanySection}}</h4>
            <small class="description">{{string.organizerSettings._showInApp}}</small>
            <input type="text" class="form-control" :placeholder="string.organizerSettings._placeholderCompanyName" v-model="organizer.company" >
            <input type="text" class="form-control" :placeholder="string.organizerSettings._placeholderVatNumber" v-model="organizer.vatNumber">
            <input type="text" class="form-control" placeholder="Website" v-model="organizer.site">
            <input type="text" class="form-control" placeholder="E-mail" v-model="organizer.email">
            <input type="text" class="form-control" :placeholder="string.organizerSettings._placeholderCity" v-model="organizer.city">

            <div class="settings-btn-area">
                <a :loading="savingChanges" id="user-data-save-btn" type="button" class="btn gray-btn save" v-on:click="(e) => {e.preventDefault();  handleSaveOrganizer();}">
                   {{string.organizerSettings._modify}}
                </a>
            </div>
            <h4 class="form-section-title no-border-bottom">{{string.organizerSettings._profileBackgroundSection}}</h4>
            <div class="Background">
              <BackgroundImage :image="organizer.backgroundImage" :organizer-id="this.organizer.id" :type="'organizer'"></BackgroundImage>
            </div>
            <h4 class="form-section-title no-border-bottom">{{string.organizerSettings._profileBannerSection}}</h4>
            <div class="banner">
              <BannerImage :image="organizer.profileImage" :organizer-id="this.organizer.id" :type="'organizer'"></BannerImage>
            </div>
        </form>
    </div>
</template>
<style scoped>

  a.settings {
    cursor: pointer;
    color: #f7a30c !important;
    font-size: 20px !important;
    text-decoration: none;
    background-color: transparent !important;
  }
  a.settings:hover {
    cursor: pointer;
    color: #f7a30c;
    font-size: 20px !important;
    text-decoration: underline;
  }

  option:disabled.settings-option{
    color: white;
  }
  .panel-body .smallseparator{
    margin-top: -10px;
  }
    .dark-layer {
        background-color: rgba(0, 7, 10, 0.8);
    }

  .data-grid-container tbody {
      font-size: 15px !important;
  }

  .row.tabs-content h4 {
      text-align: center;
  }

  small.textSmall{
      font-size: 12px;
  }
  .downloads_style{
      padding-left: 15px;
  }
  .downloads_style > i {
      padding-right: 15px;
  }
  .linkStyle {
      display: inline;
      cursor: pointer;
  }
  button.linkStyle {
      background: transparent !important;
      border: transparent !important;
      cursor: pointer;
      padding: 1px 0;
  }
  .row.guestList_links>div>.downloads_style>.linkStyle:hover {
      color: #f7a30c;
      text-decoration: underline;
  }
  .row.guestList_links>button>.downloads_style>.linkStyle:hover {
      color: #f7a30c;
      text-decoration: underline;
  }
  .downloads_style>div>a:hover{
      color: #f7a30c !important;
  }

  .banner {

      position: relative;
  }
  .background {
    position: relative;
  }

  .description{
      font-size: 12px;
      color: #a9a9a9;
  }

</style>
<script>
  import {
      USER_INFO,
      UPDATE_MY_USER
  } from '@/graphql';
import BannerImage from '../BannerImage.vue';
import BackgroundImage from '../BackgroundImage.vue';

export default {
    components: {
    BannerImage,
    BackgroundImage
},
    data (){
     return {
       name:'UserInfo',
       string: {},
       organizer: {
              id:'',
              name:'',
              surname:'',
              email:'',
              password:'',
              language:'',
              nation:'',
              city:'',
              site:'',
              company:'',
              vatNumber:'',
              privacyCondition: false,
              dataCondition: false,
              processingCondition: false,
              accountStatus:'ACTIVE',
              profileStatus:'DEFAULT'
          },
        organizerCopy: {
              name:'',
              surname:'',
              email:'',
              password:'',
              language:'',
              nation:'',
              city:'',
              site:'',
              company:'',
              vatNumber:''
          },

        loadingEffectshow: false,
        loadingMessage: '',
        savingChanges: false
     }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });

      const user = this.$store.getters.user;
      if (user && user['id']) {
          this.organizer = Object.assign({}, this.organizer, user);
          this.organizerCopy = Object.assign({}, this.organizerCopy, user);

      }else {
          this.getOrganizerInfo();
      }
    },
    methods: {
        getOrganizerInfo: function(){
        const context = this;
        if(!context.updatingIdentities) context.loadingEffectshow = true;
        context.loadingMessage = '';
        return this.$apollo.query({
          fetchPolicy: 'no-cache',
          query: USER_INFO
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if(response.data && response.data.whoAmIUser) {
              const payload = response.data.whoAmIUser;
              context.organizer = Object.assign({}, context.organizer, payload);
              context.organizerCopy = Object.assign({}, context.organizerCopy, payload);
              context.$store.commit('REFRESH_USER', payload);
              context.loadingEffectshow = false;
              context.updatingIdentities = false;
          }
        }).catch(error => {
          console.log(error);
          context.loadingEffectshow = false;
        });
      },
      handleSaveOrganizer: function (){
        const context = this;
        this.savingChanges = true;
          const updates = {};
        if(this.organizerCopy.name !== this.organizer.name) {
            updates['name'] = this.organizer.name;
        }
        if(this.organizerCopy.surname !== this.organizer.surname) {
            updates['surname'] = this.organizer.surname;
        }
        if(this.organizerCopy.email !== this.organizer.email) {
            updates['email'] = this.organizer.email;
        }
        if(this.organizerCopy.city !== this.organizer.city) {
            updates['city'] = this.organizer.city;
        }
        if(this.organizerCopy.site !== this.organizer.site) {
            updates['site'] = this.organizer.site;
        }
        if(this.organizerCopy.vatNumber !== this.organizer.vatNumber) {
            updates['vatNumber'] = this.organizer.vatNumber;
        }
        if(this.organizerCopy.company !== this.organizer.company) {
            updates['company'] = this.organizer.company;
        }
          return this.$apollo.mutate({
          mutation: UPDATE_MY_USER,
          variables: {
              updates: updates
          }
        }).then(response => {
          if (response.errors){
            console.log(response.errors);
              context.savingChanges = false;
              return;
          }
          if(response.data && response.data.updateMyUser) {
              const payload = response.data.updateMyUser;
              context.organizer = Object.assign({}, context.organizer, payload);
              context.organizerCopy = Object.assign({}, context.organizerCopy, payload);
              context.$store.commit('REFRESH_USER', payload);
              context.savingChanges = false;
              context.$root.bus.emit("simpleToast",context.string.organizerSettings._profileUpdated);
          }
        }).catch(error => {
          console.log(error);
          if(error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message === 'EMAIL_EXIST') {
              context.$root.bus.emit("simpleToast", context.string.organizerSettings._email_exists);
          }
          context.savingChanges = false;
        });
      },
    }
}
</script>