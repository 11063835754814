<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top tm-faq">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          FAQ
        </h1>
        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Quale tipo di abbonamento è migliore per me?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Cosa sono dashboard, check-inApp e guestApp?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Quando attivo un evento cosa succede?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              A cosa servono i checkbox “conferma+1” e “richiedi nominativo del +1/ o note” e come funzionano?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla
              lista?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come funziona il Qr code per accedere all’ evento?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Posso aggiungere dettagli/attributi personalizzati sui miei invitati?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come tenere basso il rischio di spam
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Quale tipo di abbonamento è migliore per me?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per scegliere l’abbonamento più adatto alle tue esigenze ti consigliamo di contattarci a
            <u>
              <a href="info@penguinpass.it">
                info@penguinpass.it
              </a>
            </u> cosi da poter individuare insieme l’offerta migliore che meglio soddisfa le tue esigenze.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Cosa sono dashboard, check-inAPP e guestApp?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dashboard, guest e check-in app sono I tre strumenti su cui si basa il sistema Penguinpass;
            Il primo è utilizzato dagli organizzatori tramite accesso web per creare e gestire gli eventi
            avendo così in unico strumento una visione completa e organizzata. <br>
            Le due app per mobile sono invece rivolte agli utilizzatori del servizio: la Check-inApp
            permette alle hostess di gestire gli accessi in loco, le quali tramite questo supporto dinamico
            possono controllare e aggiornare le liste ospiti in tempo reale. Mentre la GuestApp è
            pensata e sviluppata per gli invitati che grazie all’app possono rimanere sempre informati e
            aggiornati sugli eventi e accedere alle location con facilità e rapidità.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Quando attivo un evento cosa succede?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nel momento in cui un evento viene attivato l'organizzatore potrà utilizzare la funzione di mail marketing
            per
            inviare inviti e comunicazioni ai propri ospiti.
            <br> Inoltre una volta attivato l'evento sarà disponibile sulle GuestApp e
            quindi, visibile ai soli invitati, in caso di evento privato, a chiunque se è un evento pubblico.
            <br>
            È importante notare che non è possibile utilizzare le funzionalità della Check-inApp se
            l’evento non è stato attivato.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            A cosa servono i checkbox “conferma+1” e “richiedi nominativo del +1/ o note” e come funzionano?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Sotto la sezione nella quale si genera l’email da inviare nella campagna sono presenti due
            check- box il quale scopo, se attivati, è quello di permettere all’invitato (una volta inviato il
            RSVP) di inserire il numero e/o i nominativi dei suoi accompagnatori.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla
            lista?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Quando l’invitato confermerà la propria partecipazione avrà (se da dashboard
            l’organizzatore lo consente) la possibilià di inserire “nome, cognome e email” dei suoi
            eventuali accompagnatori. Questi nomi e dati si potranno trovare semplicemente scaricando
            il foglio excel tramite “donwload tutta la lista” nella sezione “guest list” sotto forma di nota.
            Prelevando questi nominativi e risistemandoli in un nuovo foglio excel sull’apposito formato
            “template per importazione xls” e ricaricandolo. Cosi facendo si potrà inviare email anche
            agli accompagnatori dei tuoi ospiti se necessario, oppure semplicemente si potranno
            visualizzare tramite Check-inApp.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come funziona il Qr code per accedere all’ evento?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Spesso vi è la necessità o volontà di gestire le entrate agli eventi tramite QR Code, questo
            vuol dire che gli invitati che invieranno i propri RSVP riceveranno un email della loro
            conferma contenente dettagli aggiuntivi sull’evento e il QR Code personale per accedere.
            Secondo questa logica di funzionamento, è quindi necessario impostare da dashboard l’invio
            di una mail di conferma, e inserire in essa il contenuto dinamico (QR Code). <br>
            centrare un Qr code nella e-mail<br>
            Quando si inserisce il “contenuto dinamico” nel “modulo” vuoto in Emailbuilder, se si utilizza
            un “modulo unico” (il primo nella sezione moduli) il Qr Code verrà visualizzato sul lato sinistro
            dell’email, se si desidera che invece venga visualizzato al centro sarà necessario
            semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in tre sezioni” (il settimo
            della lista moduli) e inserire il contenuto dinamico in quella centrale.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Posso aggiungere dettagli/attributi personalizzati sui miei invitati?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per inserire un attributo specifico e personalizzato alla lista degli invitati, sarà sufficiente modificare
            nel “template per importazione xls” (scaricato dal sito come sopra spiegato) la colonna “custom
            note***” ponendo al suo posto il nome dell’attributo desiderato (es. da “custom note***” a “paese
            d’origine” o “impresa”). <br>
            Se si necessita di più attributi-colonna, è possibile aggiungerne altri in seguito alla colonna “custom
            note***”<br>
            In caso ci si dimentichi di aggiungere i campi extra al momento del caricamento dei guest, è possibile
            aggiungere nuove colonne andando nella sezione impostazioni evento, espandendo la sezione "Modifica custom
            fields" sarà possibile aggiungere nuovi attributi. Una volta aggiunti basterà selezionare la voce Check-in
            app
            per rendere il campo visibile nella app di check-in.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come tenere basso il rischio di spam
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Di seguito vi diamo alcuni consigli per diminuire il rischio che le vostre campagne email vengano
            classificate come spam dai server di posta. Prima però partiamo dal ranking, che è uno dei parametri dei
            vari programmi di posta e dei server per gestire i messaggi. Inviando con Penguinpass il punteggio è di 89
            su 100 che è molto alto <br><br>

            1. I programmi di posta se leggono parole come events, save the date, rsvp etc possono classificare in
            automatico le mail come “promozioni” e le inseriscono nella cartella relativa su gmail. Quindi consigliamo
            di evitare tali parole nell’oggetto della vostra campagna. <br><br>

            2. Consigliamo che l’indirizzo di invio della mail (il mittente) non abbia parole come events, marketing
            etc. per esempio events@cliente.com. <br><br>

            3. Se ne avete la possibilità vi consigliamo di verificare il vostro dominio al posto del singolo indirizzo
            email.<br><br>

            4. Cercate di fare in modo che le mail inviate non abbiano solo un immagine (l’invito) e come testo solo il
            “gentile nome + cognome”. E' molto importante caricare in modo equilibrato testo e immagini (i software di
            posta elettronica usano anche questo algoritmo per classificare le mail, il rapporto tra testo e immagini).
            In sostanza si deve scrivere del testo, e poi le immagini. Maggiore sarà la proporzione di testo minore il
            rischio che la vostra campagna venga classificata come "spam" o "promozione".<br><br>

            5. Peso immagini: anche questo è un fattore importante. Meno pesano le immagini più velocemente si vedono e
            sono meno “sospette” ai programmi di posta che proteggono da possibili virus o malware che hanno bisogno di
            un peso minimo. L’ideale sarebbe allegare immagini/inviti tra i 50kb e i 150kb. <br><br>

            6. Si consiglia di scrivere in minuscolo, o almeno non tutto in lettere capitali. La scrittura in
            stampatello nell’oggetto condiziona + del 10% del pericolo di essere classificati come "spam".<br><br>

            7. Un ultimo suggerimento è mandare almeno la prima volta che si fa una campagna, agli indirizzi di cui non
            vedete aperta la mail o che non hanno fatto rsvp (su Penguinpass ci sono i filtri appositi), una mail a
            parte (non con Penguinpass né con altri sistemi tipo mailchimp etc), ma con un client di posta normale
            incollando gli indirizzi e che oltre a fare da reminder per l’evento li invita anche a controllare nello
            spam o nelle altre cartelle eventuali mail ricevute da voi. Una volta che l’utente, su vostra richiesta, ha
            spostato la mail in quelle ricevute il sistema di posta capisce che la volta successiva da quell’indirizzo
            la mail dev’essere visualizzata.
          </p>
        </article>

      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskFAQIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetParent.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
