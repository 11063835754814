<template>
  <footer class="public-footer bg-dark text-white">
    <div :class="fullWidth ? '' : 'container'">
      <div class="row">
        <div class="col-md-12">
          <div class="copyright small">
            <p>
              © {{ new Date().getFullYear() }} All rights reserved. Penguinpass Srl P.IVA
              08866100962 - Milan, Italy
            </p>
            <p>
              <a :href="strings.termsAndConditions._privacypolicyURL" target="_blank">
                privacy policy
              </a>
            </p>
            <p>
              <a :href="strings.termsAndConditions._linkTerms" target="_blank">
                termini e condizioni
              </a>
            </p>
          </div>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container -->
  </footer>
</template>
<style>
.public-footer {
  color: #ffffff;
  padding: 30px 0;
  width: 100%;
  font-family: 'Raleway', Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  line-height: 26px;
  font-size: 16px;
  font-weight: 300;
}
.dashboard-footer.public-footer {
  padding: 30px 15px;
}
.copyright.small > p, .copyright.small > p > a   {
  padding: 0 0 10px;
  margin: 0;
}
</style>
<script>
export default {
  components: {},
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentName: "publicFooter",
      strings: {},
    };
  },
  created() {
    const context = this;
    this.strings = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.strings = context.$root.strings;
    });
  },
  mounted() {},
  methods: {},
  events: {},
  ready() {},
};
</script>
