<template>
  <div class="default-layout">
    <div id="messages" class="messages-component">
      <div v-if="loadingEffectshow" class="higherPosition">
        <transition name="component-fade-fast" mode="out-in" appear>
          <LoadingCover
            v-if="loadingEffectshow"
            :loading-cover-msg="string.messages._loadingMessage"
          />
        </transition>
      </div>
      <div class="inmail accordion">
        <h4 class="form-section-title second accordion-toggle">
          {{ string.messages._registrationFormTitle }}
        </h4>
        <div class="accordion-content">
          <div class="inMailToggleSelection">
            <label class="control control-checkbox">
              {{ string.messages._registrationFormDescription }}
              <input
                id="checkbox"
                v-model="showEditor"
                type="checkbox"
                @change="toggleConfirmationEmailPermission"
              />
              <div class="control_indicator"></div>
            </label>
          </div>

          <!-- L'ideale sarebbe mettere il messaggio standard direttamente nel campo -->
          <div class="inmail-message-intent">
            <div v-if="showEditor" class="email-settings">
              <el-row :gutter="20" class="form-field-row zero-margin-row form-section">
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label">
                    {{ string.directEmailMarketing._name }}
                  </label>
                </el-col>
                <el-col :span="21" class="form-field-col-field" style="padding-left:0">
                  <input
                    v-model="confirmationEmailSenderName"
                    autocomplete="new-password"
                    aria-label=""
                    :placeholder="string.directEmailMarketing._name"
                    type="text"
                    class="form-control"
                    @change.prevent="autoSave"
                  />
                </el-col>
              </el-row>
              <el-row
                v-if="identity === 'DOMAIN' && verifiedDomainIdentities.length >= 1"
                :gutter="20"
                class="form-field-row zero-margin-row form-section"
              >
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label">
                    {{ string.directEmailMarketing._email }}
                  </label>
                </el-col>
                <el-col :span="5" class="form-field-col-field" style="padding-left:0">
                  <input
                    v-model.trim="confirmationEmailDomainUser"
                    autocomplete="new-password"
                    aria-label=""
                    type="text"
                    class="form-control"
                    style="text-align: right"
                    @change.prevent="autoSave"
                  />
                </el-col>

                <el-col :span="6" class="form-field-col-field">
                  <el-select
                    v-model="confirmationEmailDomainName"
                    class="domain-selector"
                    popper-class="default-popper"
                    size="large"
                    clearable
                    :placeholder="'Select your domain'"
                    @change="autoSave"
                  >
                    <el-option
                      v-for="item in verifiedDomainIdentities"
                      :key="item"
                      :label="'@' + item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row
                v-if="identity === 'EMAIL' && verifiedEmailIdentities.length >= 1"
                :gutter="20"
                class="form-field-row zero-margin-row form-section"
              >
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label">
                    {{ string.directEmailMarketing._email }}
                  </label>
                </el-col>
                <el-col :span="21" class="form-field-col-field" style="padding-left:0">
                  <select
                    id="select-email-sender"
                    v-model="confirmationEmailSenderEmail"
                    aria-label=""
                    class="form-control"
                    @change.prevent="autoSave"
                  >
                    <option value="" disabled hidden>
                      {{ string.directEmailMarketing._selectOneVerifiedEmail }}
                    </option>
                    <option
                      v-for="verifiedIdentity of verifiedEmailIdentities"
                      :key="verifiedIdentity"
                      :value="verifiedIdentity"
                    >
                      {{ verifiedIdentity }}
                    </option>
                  </select>
                </el-col>
              </el-row>
              <el-row
                v-if="identity === 'DEFAULT'"
                :gutter="20"
                class="form-field-row zero-margin-row form-section"
              >
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label">
                    {{ string.directEmailMarketing._email }}
                  </label>
                </el-col>

                <el-col :span="8" class="form-field-col-field default-email" style="padding-left:0">
                  <p class="textLabel">
                    {{ defaultEmail }}
                  </p>
                </el-col>
                <el-col :span="13" class="form-field-col-field default-email-message">
                  <p class="textLabel">
                    {{ string.directEmailMarketing._defaultEmailExplanation1 }}
                    {{ string.directEmailMarketing._defaultEmailExplanation2 }}
                    <a id="page-link-advanced" href="/settings#domain-setup">
                      Domain Setup Section
                    </a>
                  </p>
                </el-col>
              </el-row>
              <el-row
                v-if="confirmationEmailSubjectDisabled === false"
                :gutter="20"
                class="form-field-row zero-margin-row form-section"
              >
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label">
                    {{ string.directEmailMarketing._subject }}
                  </label>
                </el-col>
                <el-col :span="21" class="form-field-col-field" style="padding-left:0">
                  <div class="subject-field">
                    <h5 class="subject-section-title first">
                      {{ confirmationEmailSubject }}
                      <small> {{ string.messages._subjectExplanation }}</small>
                    </h5>
                  </div>
                </el-col>
              </el-row>
              <el-row
                v-if="confirmationEmailPreview"
                :gutter="20"
                class="form-field-row zero-margin-row form-section"
              >
                <el-col :span="3" class="form-field-col-label">
                  <label class="control-label"> Email preview </label>
                  <br/>
                  <label class="label-left">({{ confirmationEmailTemplate.displayName }})</label>
                </el-col>
                <el-col :span="21" class="form-field-col-field preview-style">
                  <iframe
                    id="emailPreviewFrameConfirmation"
                    width="100%"
                    :srcdoc="confirmationEmailPreview"
                  ></iframe>
                </el-col>
              </el-row>
              <div class="clear">&nbsp;</div>
              <el-row :gutter="20" class="form-field-row zero-margin-row form-section">
                <el-col :span="24">
                  <a
                    v-if="confirmationEmailTemplate !== null"
                    class="btn gray-btn full-column-button"
                    @click="loadTemplateBuilder"
                  >
                    {{ string.messages._editmsg }}
                  </a>
                  <a v-else class="btn gray-btn full-column-button" @click="LoadTemplates">
                    {{ string.messages._customizemsg }}
                  </a>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="form-field-row zero-margin-row form-section">
                <el-col :span="24">
                  <a
                    v-if="showEditor"
                    class="btn gray-btn full-column-button"
                    @click="updateEmailConfirmation"
                  >
                    {{ string.messages._savesettings }}
                  </a>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="form-field-row zero-margin-row form-section">
                <el-col :span="24">
                  <el-input
                    v-if="showEditor"
                    id="registrationFormCopy"
                    v-model="registrationFormId"
                    class="new-form-control"
                    readonly
                  >
                    <template slot="prepend">
                      {{ "Form id:" }}
                    </template>
                    <a slot="append" class="gray-btn" style="margin: 0" @click.prevent="copy">
                      <i class="el-icon-copy-document" />
                    </a>
                  </el-input>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="inMailToggleSelection">
            <label class="control control-checkbox">
              {{ string.messages._urlAfterRegisterDescription }}
              <input
                id="urlAfterRegisterCheckbox"
                v-model="hasUrlAfterRegister"
                type="checkbox"
                @change="toggleUrlAfterRegister"
              />
              <div class="control_indicator"></div>
            </label>
            <div v-if="hasUrlAfterRegister">
              <el-row :gutter="20" class="form-field-row zero-margin-row form-section" style="margin-top: 20px;">
                  <el-col :span="3" class="form-field-col-label">
                    <label class="control-label">
                      {{ string.messages._urlAfterRegister }}
                    </label>
                  </el-col>
                  <el-col :span="16" class="form-field-col-field" style="padding-left:0">
                    <input
                      v-model="urlAfterRegister"
                      autocomplete="new-password"
                      aria-label=""
                      type="text"
                      class="form-control"
                    />
                  </el-col>
                  <el-col :span="3" class="form-field-col-field">
                    <a class="btn gray-btn full-column-button" @click="updateUrlAfterRegister">
                      {{ string.messages._save }}
                    </a>
                  </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.preview-style {
  text-align: left;
  border: solid 1px #000;
  padding: 0;
  background: white;
  /* max-height: 500px; */
}
iframe#emailPreviewFrameConfirmation {
  display: block;
  overflow: auto;
  border: 0;
  /* width: 100%; */
  /* height: auto; */
  /* max-height: 500px; */
  margin: 0;
}
</style>
<style scoped>
.default-layout a.btn {
  width: 100%;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 15px;
  margin-bottom: 20px;
}
.gobella-layout a.btn {
  width: 100%;
  font-size: 30px;
  color: #ffffff;
  text-transform: uppercase;
  background-color: #e7b8c1 !important;
  padding: 10px;
  margin-bottom: 20px;
}
.email-settings {
  margin-top: 20px;
}

.inapp-message-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  margin-bottom: 15px;
}

.textLabel {
  padding: 20px 20px;
  font-size: 14px;
}

.higherPosition {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#loadingCover {
  z-index: 1;
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.6);
}

/*.btn{*/
/*    text-transform: uppercase;*/
/*    font-size: 16px;*/
/*    margin: 0 auto;*/
/*    display: block;*/
/*}*/

/*.btn:hover {*/
/*    color: #333333 !important;*/
/*    background-color: #dadada !important;*/
/*    border-color: transparent;*/
/*}*/

#inAppConfirmationMessageFormSubmit {
  margin: auto;
  display: block;
  font-size: 16px;
}

.inmail-message-button {
  margin-top: 20px;
}

#editor-container {
  margin-top: 20px;
  height: 200px;
}

.inmail-message {
  padding-bottom: 47px;
}

.default-layout .form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #ffffff;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.default-layout small {
  width: 100%;
  font-size: 15px;
  padding-bottom: 20px;
  text-transform: uppercase;
  color: #f7a30c;
}

input,
select {
  border-radius: 0;
  border: 1px solid #333333;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.form-section select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent
    url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat
    right center;
  border: 1px solid #333;
  background-position: 98%;
}

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  border-radius: 0 !important;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

#messages .no-visible {
  display: none;
}

#messages .list-group-item:hover .delete-attachment {
  display: inline-block;
}

#messages .list-group-item .delete-attachment {
  position: absolute;
  width: 40px;
  text-align: right;
  background: rgba(236, 236, 236, 0.9);
  top: 1px;
  right: 14px;
  font-size: 27px;
  color: #bcbcbc;
  display: none;
}

.second {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
#page-link-advanced {
  background: transparent !important;
  /*text-decoration: underline !important;*/
  text-decoration-color: #f7a30c !important;
  color: #f7a30c !important;
  text-transform: lowercase !important;
  margin: 5px;
}
.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #f7a30c;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.subject-section-title {
  color: #f7a30c;
}

.subject-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 10px;
  color: #e8e8e8;
}

.direct-email-marketing-component-container {
  margin-top: 50px;
  margin-bottom: 30px;
}

.control-label {
  text-align: right;
}
.label-left{
  text-align: left;
}
.default-email {
  text-align: start;
  font-size: medium;
}

.subject-field {
  text-align: start;
  border: solid 1px #fff;
  padding: 0px 5px;
}
/*.inMailToggleSelection .squaredThree label{
        cursor: pointer;
        width: 20px;
        height: 20px;
        top: 0;
        border-radius: 3px;
        background: #333333;
        left: 0;
        right: 0;
        margin: auto;
    }*/
</style>
<style>
.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}
.control-radio .control_indicator {
  border-radius: 0;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import {
  CREATE_EMAIL_CONFIRMATION,
  CREATE_REGISTRATION_FORM,
  DELETE_EMAIL_CONFIRMATION,
  DELETE_EMAIL_CONFIRMATION_BY_EVENT_ID,
  DELETE_REGISTRATION_FORM,
  GET_EMAIL_CONFIRMATION_REGISTRATION_FORM,
  GET_MY_VERIFIED_IDENTITIES,
  GET_REGISTRATION_FORM,
  UPDATE_EMAIL_CONFIRMATION,
  UPDATE_EVENT,
  UPDATE_REGISTRATION_FORM,
} from "@/graphql";
import { customFieldMapToMergeTagArray } from "@/utils";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    LoadingCover,
  },
  props: {
    eventId: String,
    event: Object,
  },
  data() {
    return {
      componentName: "messages",
      initValue: "prova",
      string: {},
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [["bold", "italic", "underline", "strike"]],
        },
      },
      /** Api Data **/
      // in_appmessage_selected : '',
      // in_appmessage_selected_0 : 0,
      // in_appmessage_selected_1:  0,
      loadingEffectshow: false,
      showEditor: false,

      registrationFormId: "",
      confirmationEmailTab: "",

      confirmationEmailSenderName: "",
      confirmationEmailSenderEmail: "",
      confirmationEmailDomainName: "",
      confirmationEmailDomainUser: "",
      confirmationEmailSubject: "",
      confirmationEmailId: "",
      confirmationEmailSubjectDisabled: true,
      confirmationEmailTemplate: null,
      verifiedEmailIdentities: [],
      verifiedDomainIdentities: [],
      confirmationEmailPreview: "",
      confirmEmailSavedListener: null,
      hasUrlAfterRegister: false,
      urlAfterRegister: null,
    };
  },
  computed: {
    ...mapGetters(["identity", "defaultEmail"]),
    isPrivate() {
      return this.event.genre === "PRIVATE";
    },
  },
  watch: {
    eventId: {
      immediate: true,
      handler: function () {
        if (this.eventId) {
          this.loadRegistrationForm();
        } else {
          this.showEditor = false;
        }
      },
    },
    event: {
      immediate: true,
      handler: function () {
        if (this.eventId) {
          this.getUrlAfterRegister();
        }
      },
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });

    this.confirmEmailSavedListener = this.$root.bus.addListener(
      "registrationForm.confirmationEmailSaved",
      (template) => {
        context.confirmationEmailTemplate = template;
        context.confirmationEmailSubject = template.subject;
        context.confirmationEmailSubjectDisabled = false;
        let temp = template.bodyHtml;
        temp = temp.replaceAll("\n", "");
        temp = temp.replaceAll("\r", "");
        temp = temp.replaceAll("\t", "");
        context.confirmationEmailPreview = temp;
        this.subscribeToPreviewClicks();
        context.updateTemplateOfEmailConfirmation(template);
      }
    );
    this.getMyVerifiedEmailIdentities();
    this.getMyVerifiedDomainIdentities();
  },
  beforeDestroy() {
    try {
      if (this.confirmEmailSavedListener) {
        this.confirmEmailSavedListener.remove();
        this.confirmEmailSavedListener = null;
      }
      this.unsubscribeFromPreviewClicks();
    } catch {}
  },
  methods: {
    subscribeToPreviewClicks() {
      const context = this;
      const iFrameId = "#emailPreviewFrameConfirmation";
      const height = "500px";
      $(iFrameId).contents().find("body").css({ height });
      $(iFrameId).css({ height });
      $(iFrameId)
        .contents()
        .find("body")
        .on("mouseup", (event) => {
          context.loadTemplateBuilder();
        });
      $(document).ready(() => {
        $(iFrameId).on("load", function () {
          const contents = $(this).contents();
          $(this).css({ height });
          $(contents).find("body").css({ height });
          $(contents)
            .find("body")
            .on("mouseup", (event) => {
              context.loadTemplateBuilder();
            });
        });
      });
    },
    unsubscribeFromPreviewClicks() {
      const iFrameId = "#emailPreviewFrameConfirmation";
      $(iFrameId)
        .contents()
        .find("body")
        .off("mouseup", (event) => {});
    },
    /* change existing template modal view */
    loadTemplateBuilder: function () {
      const customFieldTags = customFieldMapToMergeTagArray(this.event.allCombinedCustomFields);
      this.$root.bus.emit("modalEmailBuilderView", {
        eventId: this.eventId,
        email: this.confirmationEmailTemplate,
        customFieldTags,
        isPrivateEvent: this.isPrivate,
        isConfirmationEmail: true,
        isCampaign: false,
      });
    },
    /* Select template modal view*/
    LoadTemplates: function () {
      const customFieldTags = customFieldMapToMergeTagArray(this.event.allCombinedCustomFields);
      this.$root.bus.emit("modalTemplateEmailBuilderView", {
        eventId: this.eventId,
        customFieldTags,
        isPrivateEvent: this.isPrivate,
        isConfirmationEmail: true,
        isCampaign: false,
      });
    },
    toggleUrlAfterRegister() {
      if(!this.hasUrlAfterRegister) {
        this.urlAfterRegister = null;
        this.loadingEffectshow = true;
        return this.$root.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              eventId: this.eventId,
              updateEventInput: { urlAfterRegister: null },
            },
          }).then((value) => {
            this.loadingEffectshow = false;
            this.$root.bus.emit("simpleToast", this.string.singleEvent._updated);
          }).catch((reason) => {
            console.log(reason.message);
            this.loadingEffectshow = false;
          });
      } 
    },
    updateUrlAfterRegister() {
      this.loadingEffectshow = true;
      if(!this.isValidHttpUrl(this.urlAfterRegister)) {
        this.loadingEffectshow = false;
        return this.$root.bus.emit("simpleToast", this.string.messages._invalidUrl);
      }

      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.eventId,
            updateEventInput: { urlAfterRegister: this.urlAfterRegister.trim() },
          },
        }).then((value) => {
          this.loadingEffectshow = false;
          this.$root.bus.emit("simpleToast", this.string.singleEvent._updated);
        }).catch((reason) => {
          console.log(reason.message);
          this.loadingEffectshow = false;
        });
    },
    toggleConfirmationEmailPermission: function () {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: context.eventId,
            updateEventInput: { emailConfirmationEnabled: context.showEditor },
          },
        })
        .then((value) => {
          context.loadingEffectshow = false;
          if (value.errors) {
            console.log("error " + value.errors);
          } else {
            if (context.showEditor) {
              return context.createRegistrationForm();
            } else {
              return context.deleteRegistrationForm();
            }
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    loadRegistrationForm: async function () {
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_REGISTRATION_FORM,
          variables: {
            eventId: this.eventId,
          },
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.getRegistrationForm) {
            this.processRegistrationFormPayload(data.getRegistrationForm);
            if (data.getRegistrationForm.id && !data.getRegistrationForm.confirmationEmailId) {
              this.createEmailConfirmation();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyVerifiedEmailIdentities: async function () {
      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_MY_VERIFIED_IDENTITIES,
          variables: { identityType: "EMAIL" },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            const newArray = [];
            for (const identityObject of payload) {
              newArray.push(identityObject.identity);
            }
            context.verifiedEmailIdentities = newArray;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getMyVerifiedDomainIdentities: function () {
      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_MY_VERIFIED_IDENTITIES,
          variables: { identityType: "DOMAIN" },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            const newArray = [];
            for (const identityObject of payload) {
              newArray.push(identityObject.identity);
            }
            context.verifiedDomainIdentities = newArray;
            if (newArray.length) {
              context.confirmationEmailDomainName = newArray[0];
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUrlAfterRegister: function(){
      this.urlAfterRegister = this.event.urlAfterRegister;
      this.hasUrlAfterRegister = !!this.event.urlAfterRegister;
    },
    isValidHttpUrl: function (string) {
      try {
        const newUrl = new URL(string);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
      } catch (err) {
        return false;
      }
    },
    async updateTemplateOfEmailConfirmation(template) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: context.confirmationEmailId,
            emailConfirmationInput: {
              emailTemplateId: template.id,
              eventId: context.eventId,
              registrationFormId: context.registrationFormId,
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            return;
          }
          if (response.data && response.data.updateEmailConfirmation) {
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadsuccess);
            context.loadingEffectshow = false;
          }
          console.log("updateTemplateOfEmailConfirmation", "success");
        })
        .catch((error) => {
          console.log(error);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    async createRegistrationForm() {
      const context = this;
      context.loadingMessage = "Creating Template";
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: CREATE_REGISTRATION_FORM,
          variables: {
            input: {
              eventId: context.eventId,
              confirmationEmailOption: "CONFIRMATION_EMAIL_ENABLED",
            },
          },
        })
        .then(({ errors, data }) => {
          this.loadingEffectshow = false;
          if (errors) {
            console.log(errors);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            return;
          }
          if (data && data.createRegistrationForm) {
            this.processRegistrationFormPayload(data.createRegistrationForm);
            this.createEmailConfirmation();
          }
        })
        .catch((error) => {
          this.loadingEffectshow = false;
          console.log(error);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    async createEmailConfirmation() {
      const context = this;
      context.loadingMessage = "Creating Template";
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: CREATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationInput: {
              eventId: context.eventId,
              registrationFormId: context.registrationFormId,
            },
          },
        })
        .then((response) => {
          this.loadingEffectshow = false;
          if (response.errors) {
            console.log(response.errors);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            return;
          }
          if (response.data && response.data.createEmailConfirmation) {
            const payload = response.data.createEmailConfirmation;
            this.processConfirmationEmail(payload);
          }
        })
        .catch((error) => {
          this.loadingEffectshow = false;
          console.log(error);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    async deleteEmailConfirmation() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: DELETE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: context.confirmationEmailId,
          },
        })
        .then((response) => {
          this.loadingEffectshow = false;
          if (response.errors) {
            console.log(response.errors);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.deleteEmailConfirmation) {
            console.log("deleted confirmation email");
            context.confirmationEmailId = "";
            context.confirmationEmailTemplate = null;
            context.confirmationEmailSenderName = "";
            context.confirmationEmailSenderEmail = "";
            context.confirmationEmailDomainName = "";
            context.confirmationEmailDomainUser = "";
            context.confirmationEmailSubject = "";
            context.loadingEffectshow = false;
            context.confirmationEmailSubjectDisabled = true;
            context.confirmationEmailPreview = "";
            this.unsubscribeFromPreviewClicks();
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadsuccess);
          }
        })
        .catch((error) => {
          context.loadingEffectshow = false;
          console.log(error);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    async deleteRegistrationForm() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: DELETE_REGISTRATION_FORM,
          variables: {
            id: context.registrationFormId,
          },
        })
        .then((response) => {
          context.loadingEffectshow = false;
          if (response.errors) {
            console.log(response.errors);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            return;
          }
          if (response.data && response.data.deleteRegistrationForm) {
            context.registrationFormId = "";
            context.confirmationEmailTab = "";
            console.log("deleted registration form");
            this.deleteEmailConfirmation();
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadsuccess);
          }
        })
        .catch((error) => {
          console.log(error);
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
          context.loadingEffectshow = false;
        });
    },
    async updateEmailConfirmation() {
      const context = this;
      let inputEmail = "";
      if (context.identity === "EMAIL") {
        inputEmail =
          context.confirmationEmailSenderEmail && context.confirmationEmailSenderEmail !== ""
            ? context.confirmationEmailSenderEmail
            : context.defaultEmail;
      } else if (context.identity === "DOMAIN") {
        if (
          !context.confirmationEmailDomainUser ||
          context.confirmationEmailDomainUser === "" ||
          !context.confirmationEmailDomainName ||
          context.confirmationEmailDomainName === ""
        ) {
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", "Email Address is not valid");
          return;
        }
        inputEmail =
          context.confirmationEmailDomainUser + "@" + context.confirmationEmailDomainName;
        context.confirmationEmailSenderEmail = inputEmail;
      } else {
        inputEmail = context.defaultEmail;
      }
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: context.confirmationEmailId,
            emailConfirmationInput: {
              senderName: context.confirmationEmailSenderName,
              senderEmail: inputEmail,
              eventId: context.eventId,
            },
          },
        })
        .then((response) => {
          this.loadingEffectshow = false;
          if (response.errors) {
            console.log(response.errors);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
            return;
          }
          if (response.data && response.data.updateEmailConfirmation) {
            this.processConfirmationEmail(response.data.updateEmailConfirmation);
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadsuccess);
          }
        })
        .catch((error) => {
          console.log(error);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    autoSave() {
      this.autoSaveDebounced(this);
    },
    autoSaveDebounced: debounce((context) => {
      let inputEmail = "";
      if (context.identity === "EMAIL") {
        inputEmail =
          context.confirmationEmailSenderEmail && context.confirmationEmailSenderEmail !== ""
            ? context.confirmationEmailSenderEmail
            : context.defaultEmail;
      } else if (context.identity === "DOMAIN") {
        if (
          !context.confirmationEmailDomainUser ||
          context.confirmationEmailDomainUser === "" ||
          !context.confirmationEmailDomainName ||
          context.confirmationEmailDomainName === ""
        ) {
          context.$root.bus.emit("simpleToast", "Email Address is not valid");
          return;
        }
        inputEmail =
          context.confirmationEmailDomainUser + "@" + context.confirmationEmailDomainName;
        context.confirmationEmailSenderEmail = inputEmail;
      } else {
        inputEmail = context.defaultEmail;
      }
      return context.$apollo
        .mutate({
          mutation: UPDATE_EMAIL_CONFIRMATION,
          variables: {
            emailConfirmationId: context.confirmationEmailId,
            emailConfirmationInput: {
              senderName: context.confirmationEmailSenderName,
              senderEmail: inputEmail,
              eventId: context.eventId,
              emailTemplateId: context.confirmationEmailTemplate
                ? context.confirmationEmailTemplate.id
                : null,
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          context.processConfirmationEmail(response.data.updateEmailConfirmation);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    autoSaveRegistrationDebounced: debounce((context, updates) => {
      return context.$apollo
        .mutate({
          mutation: UPDATE_REGISTRATION_FORM,
          variables: {
            id: context.registrationFormId,
            updates: updates,
          },
        })
        .then((response) => {
          context.loadingEffectshow = false;
          if (response.errors) {
            console.log(response.errors);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500),
    processRegistrationFormPayload(registrationForm) {
      this.registrationFormId = registrationForm.id;
      this.confirmationEmailTab = registrationForm.confirmationEmailOption;
      if (registrationForm.confirmationEmailOption === "CONFIRMATION_EMAIL_ENABLED") {
        this.showEditor = true;
      } else {
        this.showEditor = false;
      }
      if (registrationForm.id && registrationForm.confirmationEmailId) {
        this.processConfirmationEmail(registrationForm.confirmationEmail);
      }
    },
    processConfirmationEmail(confirmationEmail) {
      if (confirmationEmail) {
        this.confirmationEmailId = confirmationEmail.id;
        this.confirmationEmailSenderName = confirmationEmail.senderName;

        if (confirmationEmail.senderEmail && confirmationEmail.senderEmail.includes("@")) {
          if (this.identity === "DOMAIN") {
            const parts = confirmationEmail.senderEmail.split("@");
            this.confirmationEmailDomainUser = parts[0];
            this.confirmationEmailDomainName = parts[1];
            this.confirmationEmailSenderEmail = confirmationEmail.senderEmail;
          } else if (this.identity === "EMAIL") {
            this.confirmationEmailSenderEmail = confirmationEmail.senderEmail;
          } else {
            this.confirmationEmailSenderEmail = this.defaultEmail;
          }
        }
        if (confirmationEmail.emailTemplate) {
          this.confirmationEmailTemplate = confirmationEmail.emailTemplate;
          this.confirmationEmailSubject = confirmationEmail.emailTemplate.subject;
          let temp = confirmationEmail.emailTemplate.bodyHtml;
          temp = temp.replaceAll("\n", "");
          temp = temp.replaceAll("\r", "");
          temp = temp.replaceAll("\t", "");
          this.confirmationEmailPreview = temp;
          this.confirmationEmailSubjectDisabled = false;

          this.subscribeToPreviewClicks();
        } else {
          this.confirmationEmailSubjectDisabled = true;
        }
        if (this.confirmationEmailId) {
          this.autoSaveRegistrationDebounced(this, {
            confirmationEmailId: this.confirmationEmailId,
          });
        }
      }
    },
    copy() {
      const form = document.getElementById('registrationFormCopy'); /*GET vuejs el reference here (via $ref) but how?*/
      form.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.$root.bus.emit("simpleToast", "Copied to Clipboard");
    },
  },
};
</script>
