<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Virtual Room and Webinar
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/en-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/en-virtualroom">
              Virtual Room and Webinar
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to create a personalized landing page 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to program a Virtual Room
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How do Webinars work?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              ​​What is the Virtual Venue?
            </a>
          </li>
          <!--li><a href="#article-5" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come aggiungere link a pagine per eventi “content”</a></li-->
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to create a personalized landing page 
            <svg width="18" height="10" viewBox="0 0 18 10" xmlns="http://www.w3.org/2000/svg" ratio="1">
              <polyline fill="none" stroke="#000" stroke-width="1.2" points="1 9 9 1 17 9 "></polyline>
            </svg>
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>To create a custom landing page you will need to go to the virtual room section..
          Here the Virtual room section that will allow you to create a landing page dedicated to your event. First click on “ activate your Penguin Pass room”. Then you can choose whether to create a new landing page from scratch or using a template. Once created, templates can be reused on other events, however if it is your first event, it is necessary to select  "create new".
          The builder is very similar to what you find in the mail campaign section. To create the page just drag the available contents from the panel on the right. We recommend using “rows” and dividers to have your content in an orderly fashion.
          If you have purchased virtual meeting or webinar rooms you will find them in the dynamic contents
          once your room is ready, click on the "save" button at the top right.
          At this point, if you have not entered virtual room modules, just click on save settings and publish the page. After saving the settings a table with the url address of your page appears.
          </p>
          <!--<video width="100%" controls>
            <source
              src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4"
              type="video/mp4">
            Your browser does not support the video tag.
          </video>-->
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to program a Virtual Room
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>(from inside the builder)
          First schedule a Virtual Room, after having inserted the Meeting or Webinar module on the page and saved the changes from the builder
          (from virtual room section)
          You will need to set a start date and time from the appropriate section, it will also be necessary to specify the duration of the session and the 
          time zone of the organizer, so that your guests abroad know the exact start of the meeting or webinar.
          If you want to start the session as soon as possible, by clicking on "instant meeting", the system will fill in the fields automatically, setting 
          the duration of the section to 2 hours.
          By clicking on the "upload cover image" button you can further customize your room.
          By clicking save settings a table will appear with a pin to access the room as a moderator or even presenter in case it is a webinar.
          (from landing)
          When the session start time arrives, you will see that the room in the landing page will automatically update allowing you to access it.
          </p>
          <!--<video width="100%" controls>
            <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/programmare_una_room.mp4"
                    type="video/mp4">
            Your browser does not support the video tag.
          </video>-->
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How do Webinars work?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            The Webinar template hosts three different characters:<br>
            - The Moderator can start, pause and end the Webinar and can finally request the activation of, or remove, the webcam and microphone 
            from Presenters and Guests.<br>
            -The Presenter can activate and deactivate his webcam and microphone autonomously. He must log in by inserting the pin before the beginning 
            of the webinar.<br>
            -The Guest can access the webinar with a microphone and webcam off. They also can only access the Webinar room once the webinar has begun. 
             The Guests can "go on stage" and activate the webcam and microphone only if invited by a moderator.<br>
            The Webinar can accommodate up to 250 guests and up to 3 presenters and moderators.<br>
            - Make sure that the browsers used have permission to access the webcam and microphone.<br>
            - The first people to access are the Moderators and the Presenters who need to enter the PIN indicated in the dashboard. Then they select 
            the microphone and camera and start the webinar.<br>
            - Once the webcam and microphone are selected, the webinar may take from 30 to 60 seconds to start.<br>
            - When you are ready to start the webinar click on the play button so guests can access the webinar page.<br>
            - When the event is over, click on the "End Call" button which will manually end the webinar. The webinar will also close automatically at the 
            end of the duration set during the programming phase.<br>
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            ​​What is the Virtual Venue?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            The virtual venue is an external landing page that was not created on Penguinpass. By adding to your email a button with a special link “virtual venue”, 
            your guests will be able to enter the virtual venue by simply clicking on it.<br>
          </p>
        </article>
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come aggiungere link a pagine per eventi “content”<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Nel caso di eventi “content” quando aprono un evento in “guestApp” si troveranno un
collegamento diretto ad una pagina o si aprirà direttamente un pdf, nel caso di
reindirizzamento ad una pagina web, per inserire il link, è sufficiente andare nelle
impostazioni da dashboard e inserire il link nella voce “endpoint di conferma esterno”.</p>
        </article-->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskVirtualroomEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
