var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade in",
        staticStyle: { display: "block" },
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "false",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-hidden": "true",
                  },
                  on: { click: _vm.fadeout },
                },
                [_vm._v("\n              ×\n            ")]
              ),
              _vm._v(" "),
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.modalWarning._title) +
                      "\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.string.modalWarning._warningNotImportedMessage0)
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("h4", [
                _vm._v(
                  _vm._s(_vm.string.modalWarning._itemNotImportedRecap) +
                    " : " +
                    _vm._s(_vm.itemDiscarded)
                ),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                _vm._s(_vm.string.modalWarning._warningNotImportedMessage1) +
                  ". \n            "
              ),
              _c("br"),
              _vm._v(
                _vm._s(_vm.string.modalWarning._warningNotImportedMessage2) +
                  "\n            "
              ),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _c("h5", [
                _c(
                  "a",
                  {
                    attrs: {
                      href: _vm.downloadDiscardedListUrl,
                      target: "_blank",
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.string.modalWarning
                            ._warningNotImportedLinkDownload
                        ) +
                        "\n              "
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-default modal-btn-close",
                  attrs: { type: "button" },
                  on: { click: _vm.fadeout },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.modalWarning._buttonClose) +
                      "\n            "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "modalbackgroundcolor" }, on: { click: _vm.fadeout } },
          [_vm._v("\n \n      ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }