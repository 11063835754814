var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "email-builder-select-mode-container" } }, [
    _c("div", { staticClass: "Aligner-item" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 item" }, [
          _c(
            "div",
            { staticClass: "item-content", on: { click: _vm.selectTemplate } },
            [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "title-item" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.string.emailBuilderSelectMode._selectTemplateTitle
                    ) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "subtitle-item" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.string.emailBuilderSelectMode._selectTemplateText
                    ) +
                    "\n          "
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 item" }, [
          _c(
            "div",
            { staticClass: "item-content", on: { click: _vm.newTemplate } },
            [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "title-item" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.emailBuilderSelectMode._new) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "subtitle-item" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.emailBuilderSelectMode._newText) +
                    "\n          "
                ),
              ]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-item" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/dashboard/emailbuilder/template.png"),
          alt: "List Templates",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "icon-item" }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/dashboard/emailbuilder/new.png"),
          alt: "New Template",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }