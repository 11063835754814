var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v("\n    " + _vm._s(_vm.string.c19compliance._title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container accordion-content" },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 1 } },
                [
                  _c("custom-checkbox", {
                    on: { change: _vm.toggleC19CApp },
                    model: {
                      value: _vm.event.c19c,
                      callback: function ($$v) {
                        _vm.$set(_vm.event, "c19c", $$v)
                      },
                      expression: "event.c19c",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 23 } }, [
                _c("p", { staticStyle: { margin: "0", padding: "0" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.c19compliance._description) +
                      "\n        "
                  ),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 23 } },
                [
                  _c("section-title", {
                    attrs: {
                      title: _vm.string.c19compliance.timePeriod,
                      subtitle: _vm.string.c19compliance.subTimePeriod,
                    },
                  }),
                  _vm._v(" "),
                  _c("NumericFieldInput", {
                    attrs: { "initial-value": _vm.event.c19TH },
                    on: { change: _vm.setC19TH },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.event.c19TH !== _vm.c19TH
            ? _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 18, offset: 3 } }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn gray-btn save",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.submit($event)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.string.attendeeMaxLimit._submit) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }