var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n        Campagne Email\n      "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/it-home" } }, [
                _vm._v("\n            Support\n          "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come creare una nuova e-mail e/o un template\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come inviare una campagna e-mail\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come utilizzare correttamente i filtri sulla lista dei destinatari della campagna\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come attivare i bottoni RSVP e DECLINE\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come generare un QR quando un invitato conferma\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come richiedere informazioni aggiuntive ai propri invitati\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-7")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come impostare dei timeslots selezionabili dai propri invitati attraverso la mail di invito\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-8")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come tenere basso il rischio di spam\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come creare una nuova e-mail e/o un template\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come inviare una campagna e-mail\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come utilizzare correttamente i filtri sulla lista dei destinatari della campagna\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come attivare i bottoni RSVP e DECLINE\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come generare un QR quando un invitato conferma\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come richiedere informazioni aggiuntive ai propri invitati\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(6),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-7",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come impostare dei timeslots selezionabili dai propri invitati attraverso la mail di invito\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(7),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-8",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come tenere basso il rischio di spam\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/it-campagneemail" } }, [
        _vm._v("\n            Campagne Email\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Nella sezione “email” attraverso il button “crea nuova email” si accede alla sezione in cui si deve:\n          "
      ),
      _c("br"),
      _vm._v(
        "\n          - Compilare i campi “nome (mittente), email (mittente)” che i vostri invitati si\n          ritroveranno come dati caratterizzanti le email che gli verranno inviate. "
      ),
      _c("br"),
      _vm._v(
        "\n          - Creare l’email da inviare attraverso il campo “crea mail” (“modifica email” se ne è già stata\n          salvata una in precedenza) "
      ),
      _c("br"),
      _vm._v(
        "\n          Attraverso EMAILBUILDER, si genererà il proprio invito personalizzato attraverso l’inserimento di\n          “moduli” e “contenuti”, avendo anche la possibilità di salvare alcuni modelli come Template\n          (“azioni” e poi ”salva come template”) riutilizzabili anche in futuro modificandoli caso per caso. Dopo aver\n          creato l'invito e cliccato \"salva\" dovrete inserire l'oggetto della mail che riceveranno i vostri invitati.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Finita la fase di organizazzione dell’evento è il momento di invitare i propri ospiti, per\n          farlo è sufficiente entrare nella sezione “email”, creare la mail da inviare a tutti\n          attraverso il button “crea nuova email” e salvarla, una volta effettuato queto il\n          dsahboard proporrà una preview dell’email che sarà inviata, sotto sono presenti due\n          checkbox che se attivate permetteranno all’invitato di inserire numero e nominativi dei\n          suoi accompagnatori in fase di conferma. "
      ),
      _c("br"),
      _vm._v(
        "\n          Per inviare la campagna basterà quindi selezionare i destinatari attraverso gli appositi\n          filtri, (es. tutti gli invitati, solo chi ha confermato, chi non ha ancora ricevuto nessun\n          invito, etc…) e premere sul button “invia email”. Potrete filtrare i vostri invitati anche in base alle\n          custom\n          fields create, ovvero le informazioni aggiuntive presenti nella guestlist. "
      ),
      _c("br"),
      _vm._v(
        "\n          Una volta finito il processo di invio sarà possibile avere report sullo stato della situazione\n          (vedi sezione LISTE INVITATI, Come scaricare i report RSVP e check-in).\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Prima di avviare la campagna premendo “invia email” si possono selezionare specifici destinatari\n          attraverso i filtri: "
      ),
      _c("br"),
      _vm._v(
        "\n          - “seleziona solo invitati in attesa” per inviare a coloro che non hanno ne confermato ne\n          declinato l’invito"
      ),
      _c("br"),
      _vm._v(
        "\n          - “seleziona solo gli invitati confermati” per selezionare coloro che hanno già accettato\n          l’invito"
      ),
      _c("br"),
      _vm._v(
        "\n          - “seleziona solo gli invitati che non hanno letto i precedenti invii” per coloro che hanno\n          ricevuto le mail passate ma non le hanno aperte"
      ),
      _c("br"),
      _vm._v(
        "\n          - “seleziona solo invitati non inclusi in invii precedenti” per selezionare chi non ha ancora\n          ricevuto nessuna mail"
      ),
      _c("br"),
      _vm._v(
        '\n          - "usa custom fields" per filtrare gli invitati in base ai campi extra presenti nella lista (ex. Azienda)\n          '
      ),
      _c("br"),
      _vm._v(
        "\n          In aggiunta ai filtri si può selezionare e modificare manualmente la lista dei selezionati per l’invio,\n          utilizzando le check-box a destra in ogni riga degli invitati. "
      ),
      _c("br"),
      _vm._v(
        "\n          Per utilizzare il filtro “seleziona solo gli invitati che non hanno letto i precedenti invii” è necessario\n          attendere l’aggiornamento del “mail status” questa azione può richiedere dai 30-45 minuti.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Guarda come attivare i bottoni di RSVP e Decline nelle tue mail di invito. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("video", { attrs: { width: "100%", controls: "" } }, [
        _c("source", {
          attrs: {
            src: "https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4",
            type: "video/mp4",
          },
        }),
        _vm._v(
          "\n            Your browser does not support the video tag.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Nel caso in cui si voglia gestire l’accesso degli ospiti attraverso Qr code, sarà necessario inviare\n          una email di conferma contenente il QR Code necessario per accedere all’evento. Semplicemente\n          dopo aver generato una email di conferma nella sezione “impostazioni” insieme a tutto quello\n          che si desidera inserire nella email (es. info e event recap), andrà aggiunto un “contenuto\n          dinamico” in un modulo vuoto. "
      ),
      _c("br"),
      _vm._v(
        "\n          Cosi facendo gli invitati riceveranno, trascorsi 3-10 minuti dal momento della conferma, una\n          email contenente il QR Code e strutturata come in EMAILBUILDER precedentemente.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per richiedere informazioni aggiuntive ai propri ospiti è possibile utilizzare i custom fields"
      ),
      _c("br"),
      _vm._v(
        "\n          Andando nel tab delle impostazioni, all'interno dell'evento, vedrete in fondo alla lista la sezione\n          \"Modifica\n          Custom Fields\", li vedrete i campi extra inseriti dal template al momento dell'upload della vostra guestlist\n          e\n          avrete anche la possibilità di crearne di nuovi."
      ),
      _c("br"),
      _vm._v(
        '\n          Sotto ai custom fields esistenti vedrete tre checkboxes, selezionando quella accanto a "RSVP" i vostri\n          ospiti\n          potranno compilare i dati aggiuntivi direttamente dalla mail di invito dopo aver cliccato sul bottone\n          contenente\n          il link speciale di RSVP, selezionando anche la seconda casella accanto a "RSVP" potrete rendere la\n          compilazione\n          dell\'informazione aggiuntiva obbligatoria.'
      ),
      _c("br"),
      _vm._v(
        "\n          Le informazioni compilate compariranno automaticamente nella guestlist del vostro evento.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          "Fasce Orarie" che troverete nel tab di impostazioni all\'interno del vostro evento, vi permetterà di\n          caricare\n          file .ics per generare dei timeslots che saranno poi selezionabili dai vostri ospiti dalla mail di RSVP.'
      ),
      _c("br"),
      _vm._v(
        "\n          I file .ics sono file in formato standard per calendari, esportabili da applicazioni come Google calendar o\n          da\n          calendario Yahoo. In seguito al caricamento si genererà una tabella con tutti gli slot presenti nel file.\n          Sopra\n          la tabella potrete impostare il limite massimo di persone che potranno selezionare ogni singolo slot."
      ),
      _c("br"),
      _vm._v(
        '\n          I vostri ospiti troveranno gli slot dopo aver cliccato il bottone di RSVP nella mail di invito, nella stessa\n          pagina delle informazioni aggiuntive, voi troverete gli slot selezionati dai vari ospiti tra le colonne\n          della\n          vostra guestlist. Potrete infine scaricare il file .ics di tutte le prenotazioni effettuate dal bottone\n          "Esporta\n          le tue prenotazioni come .ics" che troverai nella sezione fasce orarie.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Di seguito vi diamo alcuni consigli per diminuire il rischio che le vostre campagne email vengano\n          classificate come spam dai server di posta. Prima però partiamo dal ranking, che è uno dei parametri dei\n          vari programmi di posta e dei server per gestire i messaggi. Inviando con Penguinpass il punteggio è di 89\n          su 100 che è molto alto "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          1. I programmi di posta se leggono parole come events, save the date, rsvp etc possono classificare in\n          automatico le mail come “promozioni” e le inseriscono nella cartella relativa su gmail. Quindi consigliamo\n          di evitare tali parole nell’oggetto della vostra campagna. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          2. Consigliamo che l’indirizzo di invio della mail (il mittente) non abbia parole come events, marketing\n          etc. per esempio events@cliente.com. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          3. Se ne avete la possibilità vi consigliamo di verificare il vostro dominio al posto del singolo indirizzo\n          email."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          4. Cercate di fare in modo che le mail inviate non abbiano solo un immagine (l’invito) e come testo solo il\n          “gentile nome + cognome”. E\' molto importante caricare in modo equilibrato testo e immagini (i software di\n          posta elettronica usano anche questo algoritmo per classificare le mail, il rapporto tra testo e immagini).\n          In sostanza si deve scrivere del testo, e poi le immagini. Maggiore sarà la proporzione di testo minore il\n          rischio che la vostra campagna venga classificata come "spam" o "promozione".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          5. Peso immagini: anche questo è un fattore importante. Meno pesano le immagini più velocemente si vedono e\n          sono meno “sospette” ai programmi di posta che proteggono da possibili virus o malware che hanno bisogno di\n          un peso minimo. L’ideale sarebbe allegare immagini/inviti tra i 50kb e i 150kb. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          6. Si consiglia di scrivere in minuscolo, o almeno non tutto in lettere capitali. La scrittura in\n          stampatello nell’oggetto condiziona + del 10% del pericolo di essere classificati come "spam".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          7. Un ultimo suggerimento è mandare almeno la prima volta che si fa una campagna, agli indirizzi di cui non\n          vedete aperta la mail o che non hanno fatto rsvp (su Penguinpass ci sono i filtri appositi), una mail a\n          parte (non con Penguinpass né con altri sistemi tipo mailchimp etc), ma con un client di posta normale\n          incollando gli indirizzi e che oltre a fare da reminder per l’evento li invita anche a controllare nello\n          spam o nelle altre cartelle eventuali mail ricevute da voi. Una volta che l’utente, su vostra richiesta, ha\n          spostato la mail in quelle ricevute il sistema di posta capisce che la volta successiva da quell’indirizzo\n          la mail dev’essere visualizzata.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }