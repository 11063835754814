var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tickets-component" }, [
    _c("h4", { staticClass: "form-section-title first" }, [
      _vm._v("\n      " + _vm._s(_vm.string.tickets._title) + "\n      "),
      _c("small", [_vm._v(_vm._s(_vm.string.tickets._subtitle))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smallseparator" }),
    _vm._v(" "),
    _c("div", { staticClass: "row", staticStyle: { position: "relative" } }, [
      _vm.componentDisabled
        ? _c(
            "div",
            {
              staticClass: "disable-layer",
              on: { click: _vm.disableLayerClicked },
            },
            [_vm._v("\n \n      ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "hidden-sm col-md-3 col-lg-3" }, [
        _vm._v("\n \n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
        _c(
          "a",
          {
            staticClass: "btn import-man",
            attrs: { title: _vm.string.tickets._add },
            on: { click: _vm.insertSingle },
          },
          [
            _c("i", {
              staticClass: "fa fa-plus",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(
              "\n          " + _vm._s(_vm.string.tickets._add) + "\n        "
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "hidden-sm col-md-3 col-lg-3" }, [
        _vm._v("\n \n      "),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row tabs-content" },
      [
        _c(
          "transition",
          { attrs: { name: "component-fade-fast", mode: "out-in" } },
          [
            _vm.tabValue === "insertsingle"
              ? _c(
                  "div",
                  [
                    _c("InsertTicketForm", {
                      attrs: {
                        "event-id": _vm.event.id.toString(),
                        "father-name": _vm.componentName,
                      },
                      on: { component_event: _vm.insertTicketForm },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.dataTableShow
      ? _c("div", { staticClass: "datatable-container" }, [
          _vm.noTickets
            ? _c("div", { staticClass: "datatable no-data" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12 col-md-12 col-lg-12" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.noDataTxt) + "\n          "
                    ),
                  ]),
                ]),
              ])
            : _c("div", { staticClass: "datatable data" }, [
                _vm._m(0),
                _c("div", { staticClass: "smallseparator" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("DataGridTickets", {
                      attrs: {
                        data: _vm.gridData,
                        columns: _vm.gridColumns,
                        "filter-key": _vm.gridFilterKey,
                      },
                      on: { deleteListItem: _vm.deleteListItemFunction },
                    }),
                  ],
                  1
                ),
              ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "form-section-title second" }, [
      _vm._v("\n          Lista"),
      _c("small"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }