var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "button-tab-container" },
    _vm._l(_vm.tabs, function (tab) {
      return _c(
        "a",
        {
          key: tab.key,
          class:
            "button-tab-item btn gray-btn save " +
            (_vm.currentTab === tab.key ? " active " : ""),
          attrs: { "aria-controls": "info", role: "tab", "data-toggle": "tab" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return (function () {
                return _vm.changeTab(tab.key)
              })($event)
            },
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(_vm.$root.strings.buttonTabs[tab.key] || tab.label) +
              "\n  "
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }