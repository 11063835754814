<template>
  <div 
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.5)"
  >
    <el-row v-if="filterEnabled || bulkOptions.length > 0" :gutter="20" class="zero-margin-row" style="margin-bottom: 20px;">
      <el-col :span="bulkOptions.length > 0 ? 20 : ($slots.options ? 23 : 24 )">
        <div v-if="filterEnabled" class="filter-field">
          <custom-input
            v-model="searchKey"
            type="text"
            :placeholder="string.datagrid._search"
            class="form-control"
            style="font-size: 22px;"
          />
        </div>
      </el-col>
      <el-col v-if="bulkOptions.length > 0" :span="$slots.options ? 3 : 4">
        <el-select
          v-model="selectedAction"
          class="add-new-member"
          popper-class="default-popper"
          size="large"
          :placeholder="string.modalBulkAction[bulkOptionPlaceholder]"
          @change="handleAction"
        >
           <el-option
            v-for="option in bulkOptions"
            :key="option"
            :label="string.modalBulkAction[option]"
            :value="option"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col v-if="$slots.options" :span="1">
        <slot name="options"></slot>
      </el-col>
    </el-row>
    <template v-if="$slots.filterView">
      <slot name="filterView"></slot>
    </template>

    <empty-view v-if="tableData.length < 1 && !$slots.footer" :text="noDataText" />
    <el-table
      v-else
      ref="elTable"
      :data="tableData"
      v-bind="$attrs"
      style="width: 100%"
      :max-height="650"
      :row-class-name="rowClassName"
      :header-cell-class-name="headerCellClassName"
      :cell-class-name="cellClassName"
      :lazy="lazy"
      :span-method="spanMethod"
      row-key="id"
      v-on="listeners"
      @row-click="onRowClick"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @sort-change="onSortChange"
    >
      <slot name="columns">
        <el-table-column v-if="selectionEnabled"
        :reserve-selection="true"
        type="selection"
        :selectable="canSelectRow"
        >
        </el-table-column>
        <el-table-column
          v-for="column in columns"
          :key="column.prop"
          :sortable="'custom'"
          v-bind="column"
        >
          <template slot="header" slot-scope="scope">
            <div class="column-header" @click="handleSort(scope.column)">
              <slot :name="column.prop+'-header'" :row="scope.row">
                <span class="header-label">{{ column.label }}</span>
              </slot>
            </div>
          </template>
          <template slot-scope="{ row }" v-loading="row.loading">
            <slot :name="column.prop || column.type || column.label" :row="row">
              {{ row[column.prop] }}
            </slot>
          </template>
        </el-table-column>
      </slot>

      <template v-if="$slots.footer" slot="append">
        <slot name="footer"></slot>
      </template>
      <template v-if="$slots.emptyView" slot="empty">
        <slot name="emptyView"></slot>
      </template>
      <template v-else slot="empty">
        <empty-view :text="noDataText" />
      </template>
    </el-table>
    <slot v-if="showPagination" name="pagination" :page="total"
      :total="total"
      :pageSize="pageSize">
      <Paginate
        v-model="page"
        :page-count="pageCount"
        :click-handler="getTableData"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
      ></Paginate>
    </slot>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import EmptyView from './ui/EmptyView.vue';
import CustomInput from './ui/CustomInput.vue';
export default {
  name: "DataTable",
  components: {
    Paginate,
    EmptyView,
    CustomInput
  },
  inheritAttrs: false,
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    getData: {
      type: Function,
      default: () => Promise.resolve([])
    },
    rowClassName: {
      type: [String, Function],
      required: false,
      default: "default-row"
    },
    headerCellClassName: {
      type: [String, Function],
      required: false,
      default: "default-header"
    },
    cellClassName: {
      type: [String, Function],
      required: false,
      default: "default-cell"
    },
    selectionEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    onSelectionChange: {
      type: Function,
      required: false,
      default: (selectedRows) => {}
    },
    bulkOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    onBulkAction: {
      type: Function,
      required: false,
      default: (action, selectedRows) => {}
    },
    bulkOptionPlaceholder: {
      type: String,
      required: false,
      default: "_bulk_action"
    },
    canSelectRow: {
      type: Function,
      required: false,
      default: (row, index) => true
    },
    pageSize: {
      type: Number,
      required: false,
      default: 12,
    },
    filterEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: false,
    },
    spanMethod: {
      type: Function,
      default: ({ row, column, rowIndex, columnIndex }) => [1,1]
    },
    noDataText: {
      type: String,
      default: "No data"
    },
  },
  data() {
    return {
      tableData: [],
      page: 1,
      total: 10,
      sortParams: {},
      loading: false,
      searchKey: "",
      string: "",
      selectedRow: {},
      selectedRows: [],
      selectedAction: "",
      tableHeight: "auto"
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        ["sort-change"]: this.onSortChange
      };
    },
    pageCount(){
      return Math.ceil(this.total / this.pageSize);
    },
    ...mapGetters(["user"])
  },
  watch: {
    searchKey: {
      handler(newVal, oldVal) {
        if (newVal.length >= 3 || newVal.length < oldVal.length) {
          // trigger request
          this.getTableData();
        }
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted(){
    this.getTableData();
  },
  methods: {
    async getTableData(page) {
      this.loading = true;
      const reqPage = page || this.page;
      try {
        const response = await this.getData({
          filter: this.searchKey,
          page: reqPage,
          sortParams: this.sortParams,
          pageSize: this.pageSize
        });
        this.tableData = response.data.filter(item => {
          item.edit = false;
          item.selected = false;
          item.shouldUpdate = false;
          item.loading = false;
          return item;
        });
        this.total = response.total;
        if (this.tableData.length < 5) {
          this.tableHeight = "auto";
        } else if (this.tableData.length >= 5 && this.tableData.length < 10  ) {
          this.tableHeight = "400";
        } else {
          this.tableHeight = "650";
        }
      } catch(e){
        console.log(e);
      }finally {
        this.loading = false;
      }
    },
    onSortChange({ column, prop, order }) {
      if (prop !== null) {
        const sortOrder = order === "ascending" ? 1 : -1;
        this.sortParams = {
          [prop]: sortOrder
        };
      } else {
        this.sortParams = {};
      }
      this.getTableData();
    },
    handleSort(column) {
      const prop = column.property;
      const order = this.sortParams[prop] === 1 ? 'descending' : 'ascending';
      this.onSortChange({ column, prop, order });
    },
    onRowClick(row, column, event) {
      // this.tableData.map((item, index) => {
      //   if (item.id !== row.id && item.edit) {
      //     // item.edit = false;
      //   }
      // });
    },
    handleSelectionChange(val) {
      this.selectedRows = val.map(i => {
        return i.id;
      });
      this.onSelectionChange(this.selectedRows);
    },
    handleSelect(val, row) {
      // console.log( 'Val ' + JSON.stringify(val) + ' row ' + JSON.stringify(row))
    },
    handleAction: function(action) {
      this.onBulkAction(action, this.selectedRows);
      if (action !== "") {
        this.selectedAction = "";
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

.cell>span>span{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.dialog-title {
  color: fff;
}
.bulk-action {
  margin-bottom: 5px;
}
.bulk-action > button {
  border: 1px solid #f7a30c !important;
  background: transparent !important;
  color: white !important;
}
.bulk-action-input,
.bulk-action-input > div > input {
  height: 45px !important;
  text-align: left;
  width: 100%;
  color:white;
}
.el-table__expand-icon {
  color: white;
}
.el-table__fixed-right-patch {
  background: #f7a30c;
}
.el-table {
  border-color: #f7a30c;
  border: 2px solid #f7a30c;
  color: white;
  display: block;
}
.el-table th {
  background: #f7a30c;
  font-weight: 500;
}
.el-table,
.el-table tr {
  font-weight: 400;
  background: #f7a30c;
  border-bottom: 1px solid #f7a30c;
}
.el-table .default-header {
  background: #f7a30c;
  color: white;
  border-bottom: 1px solid #f7a30c !important;
}
.el-table-column--selection .cell {
  padding-right: 14px !important;
  padding-left: 14px !important;
}
.el-table .default-row {
  background: #000000;
  min-height: 60px;
  height: 60px;
  color: white;
}
.default-row .hover-row,
.default-row .is-hover,
.default-row:hover,
.default-row:focus {
  background-color: #595959;
  color: #fff;
}
.el-table .default-cell,
.el-table .default-cell .is-hidden {
  border-bottom: none;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  border-bottom: 1px solid #f7a30c;
}
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped.current-row > td,
.el-table__body tr.hover-row.el-table__row--striped > td,
.el-table__body tr.hover-row > td,
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #595959;
  color: #fff;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #000;
  border-color: #000;
}
.el-checkbox__inner:hover {
  border-color: #f7a30c;
}
.el-table .default-header .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #000;
  border-color: #000;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #f7a30c;
}
.el-table th > .cell.highlight {
  color: white;
}
.el-table__column-filter-trigger i {
  transform: none;
  color: white;
}
.el-table .descending .sort-caret.descending {
  border-top-color: black;
}
.el-table .ascending .sort-caret.ascending {
  border-bottom-color: black;
}
.el-table .sort-caret.descending {
  border-top-color: white;
}
.el-table .sort-caret.ascending {
  border-bottom-color: white;
}
.el-table-filter__bottom button:hover {
  color: #f7a30c;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #f7a30c;
}
.el-table__fixed-right::before, .el-table__fixed::before {
  background: #333;
}
.el-table--border::after, .el-table--group::after, .el-table::before {
  background-color: #f7a30c;
}
.el-table__empty-block {
  background: #000000;
}
.el-table__empty-text{
  width: 100%;
}

.el-table .column-header {
  display: inline-flex;
  align-items: center;
}

.el-table .caret-wrapper {
  order: -1;
  margin-right: 5px;
}

.el-table .sort-caret-wrapper {
  order: -1;
}


.el-table th > .cell {
  display: flex !important;
  align-items: center;
  white-space: normal;
  height: auto;
  line-height: 1.2;
}

.el-table .header-label {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 200px;
  word-break: break-word;
  line-height: 1.2;
}

.el-table th > .cell {
  display: flex !important;
  align-items: center;
  white-space: normal;
  height: auto;
}

.el-table .column-header {
  display: inline;
}

.el-table th.is-sortable .cell {
  padding-left: 0;
}
</style>
