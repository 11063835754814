var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row list-upload" }, [
    _c("h4", [_vm._v(_vm._s(_vm.string.listUpload._title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-12 col-lg-12" },
      [
        _c(
          "transition",
          {
            attrs: { name: "component-fade-fast", mode: "out-in", appear: "" },
          },
          [
            _vm.loadingEffectshow
              ? _c("LoadingCover", {
                  attrs: {
                    "loading-cover-msg": _vm.string.listUpload._loadingMessage,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "uploadInteractionContainer" } }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 col-md-4 col-lg-4 left" }, [
              _vm._v("\n \n          "),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12 col-md-4 col-lg-4 left" },
              [
                _c("input", {
                  ref: "fileinput",
                  attrs: {
                    id: _vm.fileInputName,
                    type: "file",
                    accept:
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                  },
                  on: { change: _vm.onFileChange },
                }),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.fileToSelect === true
                      ? _c(
                          "a",
                          {
                            attrs: { title: _vm.string.listUpload._browse },
                            on: { click: _vm.open },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-folder-open",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.string.listUpload._browse) +
                                "\n              "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fileToSelect === false
                      ? _c("div", [
                          _c("p", [_vm._v(_vm._s(_vm.fileListName))]),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "clear",
                              attrs: { title: _vm.string.listUpload._clear },
                              on: { click: _vm.clear },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-times",
                                attrs: { "aria-hidden": "true" },
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.string.listUpload._clear) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12 col-md-4 col-lg-4 left" }, [
              _vm._v("\n \n          "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 col-md-12 col-lg-12 left" }, [
              _c("div", { staticClass: "template-link-container" }, [
                _c(
                  "a",
                  {
                    staticClass: "simple",
                    attrs: { href: _vm.xlsTemplateURL },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-download",
                      staticStyle: { "margin-right": "5px" },
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.string.listUpload._downloadInstruction) +
                        " XLSX\n              "
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "formbutton" }, [
          _c(
            "a",
            {
              staticClass: "btn save",
              attrs: { title: _vm.string.listUpload._load },
              on: { click: _vm.submit },
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.string.listUpload._load) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }