<template>
  <header class="header black-bg">
    <div class="sidebar-toggle-box">
      <div
        class="fa fa-bars tooltips"
        data-placement="right"
        data-original-title="menu"
        @click="toogleSideBar"
      ></div>
    </div>
    <!-- <a
            href="/"
            class="logo"
    > -->
    <router-link :to="{ path: '/' }" tag="a" class="logo">
      <b>{{ appNameLocal }}</b>
    </router-link>

    <div class="containerHeaderRightMenu">
      <div v-if="notifications.length > 0">
        <el-dropdown :hide-on-click="false" trigger="click" style="margin-right: 20px">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style="position: relative"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="#fff"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path
                d="M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"
              />
              <path d="M9 17v1a3 3 0 0 0 6 0v-1" />
              <circle cx="16" cy="6" r="4" fill="red" stroke="red" />
            </svg>
          </div>
          <el-dropdown-menu slot="dropdown" style="min-width: 250px">
            <el-dropdown-item v-for="notification in notifications" :key="notification._id">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  position: relative;
                "
              >
                <div
                  @click="clearNotification(notification)"
                  style="position: absolute; top: 0; right: 0"
                >
                  x
                </div>
                <div>
                  <h3 v-if="notification.title">{{ notification.title }}</h3>
                  <p>{{ notification.message }}</p>
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-dropdown @command="handleCommand" trigger="click">
        <p>{{ this.langSet }}</p>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="it">{{ string.header._italian }}</el-dropdown-item>
          <el-dropdown-item command="en">{{ string.header._english }}</el-dropdown-item>
          <el-dropdown-item command="fr">{{ string.header._francaise }}</el-dropdown-item>
          <el-dropdown-item command="ar">{{ string.header._arab }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>
<style>
/*@import "~bootstrap/dist/css/bootstrap.min.css";*/
/*@import "../assets/css/dashboard/main.css";*/
.black-bg > header {
  font-family: Arial, sans-serif !important;
}

.dropdown-menu {
  color: #333 !important;
  background: #000d !important;
}
.dropdown-menu > li > a {
  color: #fff !important;
}
.dropdown-menu > li > a:hover {
  background: rgba(255, 255, 255, 0.2) !important;
}
.fa-user {
  color: #ffffff;
  background-color: transparent;
}

.menu-admin a {
  color: #000000 !important;
  cursor: pointer !important;
}

.containerHeaderRightMenu {
  display: flex !important;
  position: relative !important;
  justify-content: flex-end !important;
  align-items: center !important;
  padding: 10px;
  margin-top: 10px;
}

.containerHeaderRightMenu p {
  margin: 0 !important;
  color: #ffffff !important;
  letter-spacing: 2px !important;
  text-transform: uppercase !important;
}
.header.black-bg > a.logo:hover {
  color: #fff;
}
.el-dropdown-menu,
.el-dropdown-menu__item {
  background: rgba(0, 0, 0, 0.87) !important;
  border-color: rgba(0, 0, 0, 0.87) !important;
  cursor: pointer !important;
  color: #fff !important;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #ff9c00 !important;
  background: rgba(255, 255, 255, 0.2) !important;
}
.el-dropdown-menu__item--divided:before {
  background: rgba(255, 255, 255, 0.2) !important;
}

.el-dropdown-menu__item--divided {
  border-top: 1px solid rgba(0, 0, 0, 0.52) !important;
}
</style>
<script>
import { onLogout } from "@/vue-apollo";
import { UPDATE_MY_USER, GET_USER_NOTIFICATIONS, OPEN_USER_NOTIFICATION } from "@/graphql";
import Icon from "@/components/Icon.vue";
import { mapGetters } from "vuex";
export default {
  name: "HeaderComponent",
  components: {
    Icon,
  },
  props: {
    propAppName: {
      type: String,
      default: function () {
        return "hello";
      },
    },

    propUserName: {
      type: String,
      default: function () {
        return "hello";
      },
    },
  },
  data() {
    return {
      componentName: "header",
      appNameLocal: this.propAppName,
      notifications: [],
      // userNameLocal: '',
      string: {
        header: {
          _language: "language",
          _italian: "italiano",
          _english: "english",
          _francaise: "française",
          _arab: "arab",
          _logout: "Log Out",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["features", "team", "language", "user", "sidebarOn"]),
    teamMembersPage() {
      return this.$root.setSubject("team_members_page", { ...this.team });
    },
    userNameLocal: function () {
      return this.user && this.user.name ? this.user.name : "Organizer";
    },
    langSet: function () {
      return this.language && this.language.toUpperCase();
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.notifications = [];
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.$apollo
      .query({
        query: GET_USER_NOTIFICATIONS,
      })
      .then((res) => {
        if (res.data.getUserNotifications && res.data.getUserNotifications.length > 0) {
          this.notifications = res.data.getUserNotifications;
          console.log(this.notifications);
        }
      });
  },
  methods: {
    handleCommand: function (command) {
      if (command === "settings") {
        this.$router.push({ path: "/settings" }).catch((err) => {});
      } else if (command === "team") {
        this.$router.push({ path: "/team/members" }).catch((err) => {});
      } else if (command === "logout") {
        this.onLogout();
      } else if (command === "it") {
        this.changeLanguageToIt();
      } else if (command === "en") {
        this.changeLanguageToEn();
      } else if (command === "fr") {
        this.changeLanguageToFr();
      } else if (command === "ar") {
        this.changeLanguageToAr();
      }
    },
    clearNotification: function (notification) {
      console.log("clearNotifications");
      console.log(notification);
      this.$apollo
        .mutate({
          mutation: OPEN_USER_NOTIFICATION,
          variables: {
            id: notification._id,
          },
        })
        .then((res) => {
          if (res.data.openUserNotification) {
            this.notifications = this.notifications.filter((n) => n._id !== notification._id);
          }
        });
    },
    toogleSideBar: function () {
      this.$store.commit("switchSidebar", !this.sidebarOn);
    },
    changeLanguage: function (language) {
      this.apiChangeLanguageSettings(language);
    },
    changeLanguageToIt: function () {
      this.changeLanguage("it");
    },
    changeLanguageToEn: function () {
      this.changeLanguage("en");
    },
    changeLanguageToFr: function () {
      this.changeLanguage("fr");
    },
    changeLanguageToAr: function () {
      this.changeLanguage("ar");
    },
    apiChangeLanguageSettings: function (language) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_MY_USER,
          variables: {
            updates: {
              language: language,
            },
          },
        })
        .then((response) => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.updateMyUser) {
            context.$store.commit("SET_USER_FIELD", { language: language });
            context.$root.bus.emit("changeLanguage", { lang: language });
            context.$root.bus.emit("simpleToast", context.string.header._languagechanged);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onLogout: function () {
      // console.log('logout');
      this.$store.dispatch("logout", this.user).then(async () => {
        await onLogout(this.$apollo.getClient());
        await this.$router.push("/login").catch((error) => {});
      });
    },
  },
};
</script>
