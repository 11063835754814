var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n          Virtual Room and Webinar\n        "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/en-home" } }, [
                _vm._v("\n              Support\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to create a personalized landing page \n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to program a Virtual Room\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v("\n              How do Webinars work?\n            "),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              ​​What is the Virtual Venue?\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to create a personalized landing page \n            "
              ),
              _c(
                "svg",
                {
                  attrs: {
                    width: "18",
                    height: "10",
                    viewBox: "0 0 18 10",
                    xmlns: "http://www.w3.org/2000/svg",
                    ratio: "1",
                  },
                },
                [
                  _c("polyline", {
                    attrs: {
                      fill: "none",
                      stroke: "#000",
                      "stroke-width": "1.2",
                      points: "1 9 9 1 17 9 ",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              'To create a custom landing page you will need to go to the virtual room section..\n          Here the Virtual room section that will allow you to create a landing page dedicated to your event. First click on “ activate your Penguin Pass room”. Then you can choose whether to create a new landing page from scratch or using a template. Once created, templates can be reused on other events, however if it is your first event, it is necessary to select  "create new".\n          The builder is very similar to what you find in the mail campaign section. To create the page just drag the available contents from the panel on the right. We recommend using “rows” and dividers to have your content in an orderly fashion.\n          If you have purchased virtual meeting or webinar rooms you will find them in the dynamic contents\n          once your room is ready, click on the "save" button at the top right.\n          At this point, if you have not entered virtual room modules, just click on save settings and publish the page. After saving the settings a table with the url address of your page appears.\n          '
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to program a Virtual Room\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              '(from inside the builder)\n          First schedule a Virtual Room, after having inserted the Meeting or Webinar module on the page and saved the changes from the builder\n          (from virtual room section)\n          You will need to set a start date and time from the appropriate section, it will also be necessary to specify the duration of the session and the \n          time zone of the organizer, so that your guests abroad know the exact start of the meeting or webinar.\n          If you want to start the session as soon as possible, by clicking on "instant meeting", the system will fill in the fields automatically, setting \n          the duration of the section to 2 hours.\n          By clicking on the "upload cover image" button you can further customize your room.\n          By clicking save settings a table will appear with a pin to access the room as a moderator or even presenter in case it is a webinar.\n          (from landing)\n          When the session start time arrives, you will see that the room in the landing page will automatically update allowing you to access it.\n          '
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n            How do Webinars work?\n            "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            ​​What is the Virtual Venue?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/en-virtualroom" } }, [
        _vm._v("\n              Virtual Room and Webinar\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            The Webinar template hosts three different characters:"
      ),
      _c("br"),
      _vm._v(
        "\n            - The Moderator can start, pause and end the Webinar and can finally request the activation of, or remove, the webcam and microphone \n            from Presenters and Guests."
      ),
      _c("br"),
      _vm._v(
        "\n            -The Presenter can activate and deactivate his webcam and microphone autonomously. He must log in by inserting the pin before the beginning \n            of the webinar."
      ),
      _c("br"),
      _vm._v(
        '\n            -The Guest can access the webinar with a microphone and webcam off. They also can only access the Webinar room once the webinar has begun. \n             The Guests can "go on stage" and activate the webcam and microphone only if invited by a moderator.'
      ),
      _c("br"),
      _vm._v(
        "\n            The Webinar can accommodate up to 250 guests and up to 3 presenters and moderators."
      ),
      _c("br"),
      _vm._v(
        "\n            - Make sure that the browsers used have permission to access the webcam and microphone."
      ),
      _c("br"),
      _vm._v(
        "\n            - The first people to access are the Moderators and the Presenters who need to enter the PIN indicated in the dashboard. Then they select \n            the microphone and camera and start the webinar."
      ),
      _c("br"),
      _vm._v(
        "\n            - Once the webcam and microphone are selected, the webinar may take from 30 to 60 seconds to start."
      ),
      _c("br"),
      _vm._v(
        "\n            - When you are ready to start the webinar click on the play button so guests can access the webinar page."
      ),
      _c("br"),
      _vm._v(
        '\n            - When the event is over, click on the "End Call" button which will manually end the webinar. The webinar will also close automatically at the \n            end of the duration set during the programming phase.'
      ),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            The virtual venue is an external landing page that was not created on Penguinpass. By adding to your email a button with a special link “virtual venue”, \n            your guests will be able to enter the virtual venue by simply clicking on it."
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }