var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n          Settings\n        "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/en-home" } }, [
                _vm._v("\n              Support\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to manage the privacy policy\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to set a maximum number of registrations \n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to set-up an existing email or domain to send verified email campaigns\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-7")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to check who has unsubscribed from your mailing list\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-8")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              How to add a new team member/user\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to manage the privacy policy\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to set a maximum number of registrations \n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to set-up an existing email or domain to send verified email campaigns\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-7",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to check who has unsubscribed from your mailing list\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-8",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            How to add collaborators to my team\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/en-impostazioni" } }, [
        _vm._v("\n              Settings\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            From Dashboard, in the Settings section, you can create one or more privacy policies that will be displayed \n            to the guests before being able to RSVP. "
      ),
      _c("br"),
      _vm._v(
        '\n            A privacy policy can be active or not active, mandatory or not mandatory, and "limited to this event".'
      ),
      _c("br"),
      _vm._v(
        "\n            - If the privacy policy is mandatory, the guest must necessarily flag it to RSVP. "
      ),
      _c("br"),
      _vm._v(
        "\n            - If the privacy policy is mandatory and the guest has already flagged it, the guest will not see the privacy \n              policy in subsequent events."
      ),
      _c("br"),
      _vm._v(
        "\n            - If the privacy policy is not mandatory, the guest can decide whether to flag it or not; if the guest doesn't \n              flag it, he won't see it again at the next event."
      ),
      _c("br"),
      _vm._v(
        "\n            - If the non-mandatory privacy policy becomes mandatory, the guest will not see the privacy policy if it has \n              already been flagged; if the guest has not flagged it, they will see it and must flag it to RSVP (mandatory)."
      ),
      _c("br"),
      _vm._v(
        '\n            - If the privacy policy is "limited to this event" it will be displayed both to those who have flagged it and to \n              those who have never flagged it; the privacy policy will be re-proposed to the guest even if this has been mandatory \n              in past events.'
      ),
      _c("br"),
      _vm._v(
        '\n            - If the privacy policy is "limited to this event", it will only be relevant for the event it was created for.'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            The privacy policy tool you are using is merely a tool made available to you and to legal teams to facilitate management. \n            Penguinpass Srl assumes no responsibility for improper and/or incorrect use of the tool.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            In the "settings" section it is possible to set a maximum limit on the number of individuals who can register/purchase a ticket \n            for a public event through the appropriate item where you can set the maximum number. '
      ),
      _c("br"),
      _vm._v(
        "\n            Once the number of registrations for the event has reached the maximum set, including +1s, the system will prevent further \n            users from registering for a ticket.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            By opening the side menu of the dashboard, it is possible to access your profile settings by clicking on the \n            first icon in the list. You will find the name with which you registered to the system. By clicking on the "Domain" \n            button you can choose between "Domain" and "Email".'
      ),
      _c("br"),
      _vm._v(
        '\n            By choosing to verify the email, you will only be able to send emails from the verified address. You will have to enter\n            your email in the space provided and click on the "Verify email" button, you will receive an email to the address entered \n            with a verification link to click. You will be redirected to a Penguinpass web page with the word success, at this point \n            you just need to refresh the table that you will find in the "Domain" section of the settings and you will see that the icon \n            next to the email you’ve entered will change from orange to green. Going to any event you will see that in the Mail Marketing \n            section you can select the associated address that your guests will see as the sender of the invitation.'
      ),
      _c("br"),
      _vm._v(
        '\n            By choosing to verify the domain instead, you will be able to send emails from all the addresses associated with that domain \n            (ex. Marketing@dominio.com, info@dominio.com). To proceed with the association you must enter the domain in the appropriate space \n            (ex. Domain.com), by clicking on the "Check Domain" button, a table containing four values ​​will automatically be generated:'
      ),
      _c("br"),
      _vm._v(
        "\n            - The TXT value for sending permission to send an email on behalf of your domain."
      ),
      _c("br"),
      _vm._v(
        "\n            - The three CNAME values ​​are for your domain's DKIM settings."
      ),
      _c("br"),
      _vm._v(
        "\n            With these two configurations, you will be able to send emails using the Penguinpass Email Server without losing the ability \n            to send emails with your original mail server."
      ),
      _c("br"),
      _vm._v(
        "\n            The table values ​​will need to be added and not replaced in your domain's DNS configurations. Any replies will be sent to your original \n            email server. To reduce the spam rate, CNAME values ​​are also marked as required. After verifying your domain, you will be able to use any \n            username in your domain as the email sender such as “any_username @ yourdomain.com”. "
      ),
      _c("br"),
      _vm._v(
        "\n            Your verification request will expire after 48 hours. In such cases, a new verification request must be submitted. The domain will be \n            successfully associated and ready to use if, when refreshing the table with the values, the icon will have turned green."
      ),
      _c("br"),
      _vm._v(
        "\n            Similarly to emails, you can select the associated domain from the marketing section, the part after the at sign (@) will remain blocked but \n            you can change the part before the @ sign (ex. Info, marketing, sales, etc.).\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Opening up the menu on the left side of the dashboard, it is possible to go to your profile’s settings by \n            clicking on the first icon of the menu. Here you can find the name you have registered with. By clicking on the \n            “unsubscribers” button, you can find a table with all the contacts of your guests that have clicked on the link \n            “unsubscribe from YOUR NAME’s mailing list..” in the footer of your emails. \n             It’s possible to export this list on an excel spreadsheet, by clicking on the “download all list” button, above the table."
      ),
      _c("br"),
      _vm._v(
        "\n            Once the guests become “unsubscribers”, you will be able to include them in the guest list of your event but the system will \n            automatically exclude them from the list of the guests that can receive communications in the marketing mail section."
      ),
      _c("br"),
      _vm._v(
        "\n            Guests will be able to re-subscribe in the same way in which they unsubscribed, by clicking on the link of the footer of an \n            email they have already received.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Opening up the side menu, it is possible to access a section called “My team” where you can add new members and visualize/manage \n            those already added."
      ),
      _c("br"),
      _vm._v(
        "\n            To add a new team member it will be possible to just add their email in the field, select a role for the new member and when finished, \n            click on the “Send invite” button."
      ),
      _c("br"),
      _vm._v("\n            The roles are the following:"),
      _c("br"),
      _vm._v(
        "\n            owner: has full power and is the owner of the account. "
      ),
      _c("br"),
      _vm._v(
        "\n            staff manager: can create new events and visualize/modify all present events on the account."
      ),
      _c("br"),
      _vm._v(
        "\n            staff: pcan modify only the events that are assigned to them by the owner or staff manager."
      ),
      _c("br"),
      _vm._v(
        "\n            The person invited will receive an email and to accept the invite to the team, they will need to get registered with their name, last name, \n            and password. Finally, after accepting the privacy policies and the terms and conditions, click on the confirmation button. Once confirmed, \n            the new member will be able to access the dashboard with their credentials."
      ),
      _c("br"),
      _vm._v(
        "\n            From the table, the owners will be able to de-activate and re-activate their team members. Both staff managers and the owners will be able \n            to select the “staff” members, and by clicking on the “Bulk Action” button, they will be able to assign/delete the events that staff members \n            can view and manage from their dashboard.\n          "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }