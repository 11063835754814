var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "team-custom-fields-manager advance-settings-component-container accordion",
    },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$root.strings.teamCustomFieldsManager._title) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container accordion-content" },
        [
          _c("small", [
            _vm._v(_vm._s(_vm.$root.strings.teamCustomFieldsManager._subtitle)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "component-menu row" }, [
            _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
              _c(
                "div",
                { staticClass: "displayed_action_title" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "component-fade-fast", mode: "out-in" } },
                    [
                      !_vm.showAddNew
                        ? _c(
                            "a",
                            {
                              staticClass: "btn gray-btn save action",
                              on: {
                                click: function ($event) {
                                  return _vm.showCreateNewAction(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._createNew
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c("div", { staticClass: "label active" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$root.strings.teamCustomFieldsManager
                                    ._createNew
                                ) +
                                "\n            "
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
              _c(
                "div",
                { staticClass: "displayed_action_title" },
                [
                  _c(
                    "transition",
                    { attrs: { name: "component-fade-fast", mode: "out-in" } },
                    [
                      _vm.showAddNew
                        ? _c(
                            "a",
                            {
                              staticClass: "btn gray-btn save action",
                              on: {
                                click: function ($event) {
                                  return _vm.showCreateNewAction(false)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._chooseExisting
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _c("div", { staticClass: "label active" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$root.strings.teamCustomFieldsManager
                                    ._chooseExisting
                                ) +
                                "\n            "
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.showAddNew
            ? _c("div", { staticClass: "component-form" }, [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.newCustomFieldObj.options.question,
                        expression: "newCustomFieldObj.options.question",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: { width: "100%" },
                    attrs: {
                      "aria-label": "",
                      type: "text",
                      name: "policy_name",
                      placeholder:
                        _vm.$root.strings.teamCustomFieldsManager._label,
                    },
                    domProps: { value: _vm.newCustomFieldObj.options.question },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newCustomFieldObj.options,
                          "question",
                          $event.target.value.trim()
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { padding: "10px 0 15px" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          "popper-class": "default-popper",
                          size: "large",
                          clearable: "",
                          placeholder: "Select a custom field type",
                        },
                        model: {
                          value: _vm.newCustomFieldObj.type,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.newCustomFieldObj,
                              "type",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "newCustomFieldObj.type",
                        },
                      },
                      _vm._l(_vm.customFieldTypes, function (item) {
                        return _c("el-option", {
                          key: item,
                          attrs: {
                            label:
                              _vm.$root.strings.teamCustomFieldsManager[item],
                            value: item,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.newCustomFieldObj.type === "MULTIPLE_CHOICE"
                  ? _c(
                      "div",
                      { staticStyle: { "margin-bottom": "15px" } },
                      [
                        _c("div", { staticStyle: {} }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-sm-24 col-md-12 col-lg-12",
                              staticStyle: { padding: "0" },
                            },
                            [
                              _c(
                                "label",
                                { staticClass: "el-form-item_label" },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$root.strings
                                          .teamCustomFieldsManager._selectMax
                                      ) +
                                      ":\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("NumericFieldInput", {
                                attrs: {
                                  "initial-value": 1,
                                  min: 1,
                                  max: _vm.maxOptionNumber,
                                },
                                on: { change: _vm.onChangeMaxChoice },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-sm-24 col-md-12 col-lg-12" },
                            [
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._insertAnswers
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "row tabs-content",
                            staticStyle: { margin: "0" },
                          },
                          [
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: "component-fade-fast",
                                  mode: "out-in",
                                },
                              },
                              [
                                _c("div", { key: "uploadSingle" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.newCustomFieldAnswer,
                                        expression: "newCustomFieldAnswer",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "aria-label": "",
                                      type: "text",
                                      name: "policy_name",
                                      placeholder:
                                        _vm.$root.strings
                                          .teamCustomFieldsManager._add_csv,
                                    },
                                    domProps: {
                                      value: _vm.newCustomFieldAnswer,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.newCustomFieldAnswer =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "formbutton" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "gray-btn save",
                                        staticStyle: {
                                          width: "100%",
                                          margin: "10px 0 15px 0",
                                        },
                                        attrs: {
                                          title:
                                            _vm.$root.strings.listSingleItem
                                              ._save,
                                        },
                                        on: {
                                          click: _vm.onSubmitCustomFieldOption,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$root.strings.listSingleItem
                                                ._save
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("data-table", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.haveCFieldOptions,
                              expression: "haveCFieldOptions",
                            },
                          ],
                          ref: "tablenew",
                          attrs: {
                            "get-data": function () {
                              return _vm.getData()
                            },
                            "filter-enabled": false,
                            "show-pagination": false,
                            columns: _vm.answerListColumns,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c("span", {}, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          row.label +
                                            (row.disabled ? " (disabled)" : "")
                                        ) +
                                        "\n          "
                                    ),
                                  ])
                                },
                              },
                              {
                                key: "maxLimit",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c(
                                    "span",
                                    {},
                                    [
                                      _c("NumericFieldInput", {
                                        staticClass: "p-table-cell",
                                        staticStyle: {
                                          width: "150px !important",
                                        },
                                        attrs: {
                                          min: row.limit,
                                          "initial-value": row.maxLimit,
                                        },
                                        on: {
                                          change: function (val) {
                                            return (row.maxLimit = val)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                },
                              },
                              {
                                key: "Actions",
                                fn: function (ref) {
                                  var row = ref.row
                                  return _c(
                                    "span",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      !row.disabled
                                        ? _c(
                                            "a",
                                            {
                                              staticClass: "action_link",
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                              attrs: { title: "delete answer" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.beforeDeleteCustomFieldOption(
                                                    "tablenew",
                                                    row.key
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-trash",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                },
                              },
                            ],
                            null,
                            false,
                            2006433582
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "form-section submit" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn gray-btn save",
                      attrs: { type: "button", name: "submit" },
                      on: { click: _vm.addCustomField },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$root.strings.teamCustomFieldsManager._create
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.showAddNew
            ? _c(
                "div",
                { staticClass: "custom-fields-list" },
                _vm._l(_vm.customFieldArray, function (ref) {
                  var slug = ref[0]
                  var cField = ref[1]
                  return _c("div", { key: slug, staticClass: "custom-field" }, [
                    _c("div", { staticClass: "custom-field-recap" }, [
                      _c("div", { staticClass: "field custom-field-name" }, [
                        _c("strong", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$root.strings.teamCustomFieldsManager._name
                              )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              cField.options.question +
                                (cField.disabled ? " (disabled)" : "")
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "field custom-field-edit" }, [
                        !cField.disabled
                          ? _c(
                              "span",
                              {
                                staticClass: "custom-field-edit-action",
                                on: {
                                  click: function ($event) {
                                    return _vm.archiveCustomField(slug)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-trash",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !cField.disabled && cField.type === "MULTIPLE_CHOICE"
                          ? _c(
                              "span",
                              {
                                staticClass: "custom-field-edit-action",
                                on: {
                                  click: function ($event) {
                                    return _vm.openNewOptionDialog(slug)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { margin: "15px 0" } }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$root.strings.teamCustomFieldsManager._type
                          ) +
                          ":   " +
                          _vm._s(cField.type.formatEnum()) +
                          "\n          "
                      ),
                      cField.type === "MULTIPLE_CHOICE"
                        ? _c(
                            "span",
                            { staticStyle: { "margin-left": "15px" } },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._maxSelection
                                  ) +
                                  ":  \n            " +
                                  _vm._s(cField.options.maxSelection) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    cField.type === "MULTIPLE_CHOICE"
                      ? _c(
                          "div",
                          { staticClass: "custom-field-action" },
                          [
                            _c("data-table", {
                              ref: "table-" + slug,
                              refInFor: true,
                              attrs: {
                                "get-data": function () {
                                  return _vm.getData(slug)
                                },
                                "filter-enabled": false,
                                "show-pagination": false,
                                columns: _vm.answerListColumns,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c("span", {}, [
                                        row.edit
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model.trim",
                                                  value: row.label,
                                                  expression: "row.label",
                                                  modifiers: { trim: true },
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: { type: "text" },
                                              domProps: { value: row.label },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    row,
                                                    "label",
                                                    $event.target.value.trim()
                                                  )
                                                },
                                                blur: function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              },
                                            })
                                          : _c("div", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    row.label +
                                                      (row.disabled
                                                        ? " (disabled)"
                                                        : "")
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                      ])
                                    },
                                  },
                                  {
                                    key: "maxLimit",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        {},
                                        [
                                          row.edit
                                            ? _c("NumericFieldInput", {
                                                staticClass: "p-table-cell",
                                                staticStyle: {
                                                  width: "150px !important",
                                                },
                                                attrs: {
                                                  min: _vm.editItem.limit,
                                                  "initial-value":
                                                    _vm.editItem.maxLimit,
                                                },
                                                on: {
                                                  change: function (val) {
                                                    return (_vm.editItem.maxLimit =
                                                      val)
                                                  },
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      typeof row.maxLimit ===
                                                        "number" &&
                                                        row.maxLimit > 0
                                                        ? (row.limit || 0) +
                                                            "/" +
                                                            row.maxLimit
                                                        : "-"
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                  {
                                    key: "Actions",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          !row.disabled
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "action_link",
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                  },
                                                  attrs: {
                                                    title: row.edit
                                                      ? "save changes"
                                                      : "open editor",
                                                  },
                                                  on: {
                                                    click: function () {
                                                      return row.edit
                                                        ? _vm.saveAnswer(
                                                            row,
                                                            "table-" + slug,
                                                            row.key,
                                                            slug
                                                          )
                                                        : _vm.openAnswerEditor(
                                                            row,
                                                            "table-" + slug,
                                                            row.key,
                                                            slug
                                                          )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: row.edit
                                                      ? "fa fa-check"
                                                      : "fa fa-pencil",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !row.disabled
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "action_link",
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                  },
                                                  attrs: {
                                                    title: row.edit
                                                      ? "discard changes"
                                                      : "remove option",
                                                  },
                                                  on: {
                                                    click: function () {
                                                      row.edit
                                                        ? _vm.closeAnswer(
                                                            row,
                                                            "table-" + slug,
                                                            row.key,
                                                            slug
                                                          )
                                                        : _vm.beforeDeleteCustomFieldOption(
                                                            "table-" + slug,
                                                            row.key,
                                                            slug
                                                          )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    class: row.edit
                                                      ? "fa fa-times"
                                                      : "fa fa-trash",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          row.disabled
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass: "action_link",
                                                  staticStyle: {
                                                    "margin-right": "5px",
                                                  },
                                                  attrs: {
                                                    title: "enable option",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.enableCustomField(
                                                        "table-" + slug,
                                                        row.key,
                                                        slug
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-undo",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "custom-field-action" }, [
                      _c("div", { staticClass: "field actions " }, [
                        _c("div", { staticClass: "single-action" }, [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._checkInApp
                                  ) +
                                  "\n                "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: cField.checkinapp },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleOption(slug, "checkinapp")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "single-action" }, [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._RSVP
                                  ) +
                                  "\n                "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: cField.rsvp },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleOption(slug, "rsvp")
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: cField.rsvp && !cField.disabled,
                                  expression: "cField.rsvp && !cField.disabled",
                                },
                              ],
                              staticClass: "control control-checkbox",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$root.strings.teamCustomFieldsManager
                                      ._mandatory
                                  ) +
                                  "\n                "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: cField.rsvpMandatory },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleOption(
                                      slug,
                                      "rsvpMandatory"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "single-action" }, [
                          _c(
                            "label",
                            { staticClass: "control control-checkbox" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$root.strings.customFieldsManager
                                      ._registrationFormPage
                                  ) +
                                  "\n                "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: cField.registrationForm },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleOption(
                                      slug,
                                      "registrationForm"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    cField.registrationForm && !cField.disabled,
                                  expression:
                                    "cField.registrationForm && !cField.disabled",
                                },
                              ],
                              staticClass: "control control-checkbox",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$root.strings.customFieldsManager
                                      ._mandatory
                                  ) +
                                  "\n                "
                              ),
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: cField.registrationFormMandatory,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.toggleOption(
                                      slug,
                                      "registrationFormMandatory"
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "control_indicator" }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.user && _vm.user.role !== "STAFF"
                          ? _c("div", { staticClass: "single-action" }, [
                              _c(
                                "label",
                                { staticClass: "control control-checkbox" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$root.strings
                                          .teamCustomFieldsManager._hideStaff
                                      ) +
                                      "\n                "
                                  ),
                                  _c("input", {
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: cField.hideStaff },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleOption(
                                          slug,
                                          "hideStaff"
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "control_indicator",
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                "custom-class": "default-popper",
                "append-to-body": "",
                visible: _vm.newOptionDialogVisible,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.newOptionDialogVisible = $event
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "dialog-title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [_vm._v("\n        " + _vm._s("Add New Answer") + "\n      ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "filter-field" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newOption,
                      expression: "newOption",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    placeholder:
                      _vm.$root.strings.teamCustomFieldsManager._add_csv,
                  },
                  domProps: { value: _vm.newOption },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.newOption = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.closeNewOptionDialog } }, [
                    _vm._v("\n          Cancel\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addNewOption },
                    },
                    [_vm._v("\n          Confirm\n        ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.customFieldArray.length <= 0
            ? _c("div", { staticClass: "no-custom-fields" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$root.strings.teamCustomFieldsManager._noCustomFields
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }