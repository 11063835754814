<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Liste Invitati
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="helpdesk/it-listeinvitati">
              Liste Invitati
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come accedere e modificare una guest list
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come caricare una lista di invitati
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come scaricare i report RSVP e check-in
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span> Come impostare il numero massimo di accompagnatori per ospite
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Caso di upload di invitati già presenti nella lista
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come accedere e modificare una guest list
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per visualizzare la lista clienti si deve aprire l’evento in questione dalla sezione “eventi” e
            selezionare il button “guest”, si accede così alla “guest list” dove si
            visualizzeranno: <br>
            nome, cognome, email, telefono, event status, ospiti, custom note e email status.
            Nella sezione guest list è possibile: <br>
            - visualizzare il numero degli invitati presenti nella lista individuato dall’apposita voce
            “numero invitati presenti in lista” <br>
            - Scaricare un foglio excel degli invitati totali presenti nella lista o solo di quelli che hanno
            effettuato il check-in attraverso le apposite voci “download tutta la lista” e “download
            solo i check-in” <br>
            - Eliminare la lista tramite la voce “cancella tutti gli invitati” <br>
            - Modificare manualmente uno specifico invitato selezionandolo dalla lista e “eliminarlo
            o modificarlo” attraverso gli appositi button (a destra su ogni riga dell’invitato stesso).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come caricare una lista di invitati
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per popolare la lista è possibile inserire i dati degli
            invitati agendo:<br>
            <b>MANUALMENTE</b> <br>inserendo invitato dopo invitato attraverso il button “+ aggiungi
            manualmente” riempendo i campi proposti: “Nome, Cognome, email e telefono”. <br>
            <b>AUTOMATICAMENTE</b><br> caricando una lista precompilata su un foglio excel attraverso il
            button “carica lista”.<br><br>
            Il foglio excel deve essere organizzato secondo particolari specifiche, infatti è necessario
            utilizzare il “template per importazione xls” proposto sotto il botton “sfoglia”. Una volta
            scaricato basterà seguire le indicazioni presenti nelle prime sei righe del foglio di lavoro excel:<br>
            non eliminare le prime sei righe; <br> eliminare l’ospite fittizio “Mario Rossi” (posto come
            esempio);<br> inserire i dati dei propri ospiti rispettando le colonne assegnate ai vari campi. <br>
            Il foglio excel contenente la lista dovrà essere selezionato attraverso il button “sfoglia” e poi
            in seguito per avviare il caricamento si dovrà selezionare il button “carica” <br><br>
            <b>NEL CASO DI EVENTI PUBBLICI</b> la lista sarà di tipo dinamico quindi si popolerà man
            mano che gli ospiti stessi si registreranno/acquisteranno il ticket per l’evento, ciò comporta
            che la lista sarà vuota al momento della creazione dell’evento. Rimane comunque la
            possibilità di aggiungere manualmente degli ospiti, evitando la fase di registrazione/
            acquisto biglietto.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come scaricare i report RSVP e check-in
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Vi è la possibilità esportare dei fogli excel contenenti i dati relativi alla stato della
            situazione, in particolare è possibile ottenere la lista completa di tutti gli invitati e il
            relativo stato (es. se hanno ricevuto email, se hanno confermato etc…) attraverso la
            voce “download tutta la lista”, oppure la lista di tutti gli invitati che hanno effettuato
            il check-in attraverso l’apposita voce “download solo i check-in”. <br> Inoltre è possibile
            nella sezione “campagne email” ottenere dei report contenenti gli invitati a cui non
            è stato possibile recapitare l’email, andando in fondo alla pagina dove si ha la lista
            delle relative campagne inviate e relativi report. <br>
            In generale la raccolta dei dati riguardante la lettura o meno da parte di un invitato
            dell’email non è immediata, si raccomanda quindi di aspettare del tempo prima di
            aggiornare la pagine della “guest list” e scaricare i report.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come impostare il numero massimo di accompagnatori per ospite
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per selezionare un limite massimo di persone aggiuntive che ogni ospite può portare
            all’evento con se, dalla sezione “guest list” si può aumentare o diminuire il numero nella
            apposita voce “Numero accompagnatori per persona”. <br>
            Qualora vi siano situazioni eccezionali in cui uno specifico ospite presente sulla lista debba
            avere un numero particolare di accompagnatori differente dal resto della lista, si può
            modificare il numero massimo manualmente selezionando l’ospite nella lista e premendo su
            “modifica invitato” (a destra sulla riga dell’invitato stesso). <br><br>
            <b>COME FUNZIONA IL CONTEGGIO DEL +1</b> <br>
            Nella lista degli invitati è presente la voce “+1” che indica il numero di accompagnatori per
            persona. Il dato viene presentato inizialmente in forma es.“0/2” in base al numero limite
            che viene impostato. Il primo numero si aggiornerà automaticamente nel momento in cui
            le hostess effettueranno il check-in agli accompagnatori dell’invitato, mentre Il secondo
            numero si aggiornerà quando l’invitato comunicherà attraverso la pagina di conferma
            dell’invito il numero dei suoi accompagnatori effettivi. <br>
            (es, se viene impostato un massimo di “3” ospiti per invitato, inizialmente si troverà “0/3”,
            quando però l’invitato confermerà ed ad esempio indicherà “2” ospiti aggiuntivi nelle
            apposite voci nella pagina di conferma, il dato si aggiornerà diventando “0/2”, poi in fase di
            check-in il dato si aggiornerà diventando “1/2”o “2/2” con l’avvenuta presenza degli
            accompagnatori all’evento).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Caso di upload di invitati già presenti nella lista
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            È possibile che nella lista caricata siano presenti invitati ripetuti più volte o che si voglia
            aggiungere un invitato già presente nella lista. Al verificarsi di questa situazione il sistema
            riconoscerà i duplicati solo se presentano identica email, infatti si accettano casi di individui
            omonomi. <br>
            Se questo accade, alla fine della fase di caricamento il sito aprirà un apposito banner che
            notificherà l’avvenuta individuazione di duplicati, mostrerà il numero di individui non caricati
            e proporrà un foglio excel contenente tutti i nominativi non inseriti nella lista.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskListeInvitatiIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
