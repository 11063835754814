<template>
  <div class="visibility-section">
    <div v-if="!isActive">
      <a
        class="btn visibility-button"
        @click="toggleVisibilityTrue"
      >
        <h3>{{ string.visibilityManager._publish }}</h3>
      </a>
    </div>
    <div v-else>
      <a
        class="btn visibility-button"
        @click="toggleVisibilityFalse"
      >
        <h3>{{ string.visibilityManager._hidden }}</h3>
      </a>
    </div>
  </div>
</template>
<style scoped>
  .visibility-button {
    background-color:#f7a30c !important;
    padding: 0 20px 20px !important;
    height: 59px;
  }
.visibility-section {
  text-align: center;
  padding: 0;
  cursor: pointer;

}

.btn {
  display: block;
  width: 100%;
  cursor: pointer;
  color: #ffffff;
  border: 0 !important;
  margin: 0 !important;
  padding: 20px !important;
}

.btn:hover {
  background-color: #dadada !important;
  color: #333;
  cursor: pointer;
  border: 0 !important;
  margin: 0 !important;
}
h3 {
  text-transform: uppercase;
  margin: 0;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
}
</style>
<script>

import { UPDATE_EVENT } from '@/graphql';

export default {
  components: {},
  props: {
    // eventId: String,
    event: Object
  },
  data() {
    return {
      isActive: false,
      string: {
        /*
                    visibilityManager: {
                        _publish : "Pubblica",
                        _hidden : "Nascondi",
                        _uploadsuccess : "aggiornamento eseguito",
                        _error : "Errore: inserire i dati obbligatori",
                    }
                    */
      }
      // published : '',
    };
  },
  watch: {
    event: function() {
      this.isActive = this.event.status === 'ACTIVE';
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    toggleVisibilityTrue: function() {
      this.setVisibility(true);
    },
    toggleVisibilityFalse: function() {
      this.setVisibility(false);
    },
    setVisibility: function(v) {
      const context = this;
      const nextStatus = this.isActive ? 'INACTIVE' : 'ACTIVE';

      return this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: { eventId: this.event.id, updateEventInput: {status: nextStatus}}
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
          } else {
            context.isActive = nextStatus === 'ACTIVE';
            context.$root.bus.emit("simpleToast", context.string.visibilityManager._uploadsuccess);
            context.$emit('visibilityChanged', {status: nextStatus});
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", 'Update failed');
        });
    }
  }
};
</script>
