var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "center-it container" }, [
      _c("div", { staticClass: "error-title" }, [
        _vm._v(_vm._s(_vm.string.errorsNotFound._user_not_found)),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex", "justify-content": "center" } },
        [
          _c("router-link", { attrs: { to: { path: "/" }, tag: "a" } }, [
            _c("b", [_vm._v(_vm._s(_vm.string.errorsNotFound._go_to_home))]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }