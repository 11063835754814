<template>
  <PublicLayout>
      <div v-if="!resetDone" class="container">
        <div class="row wow fadeIn">
          <!-- Left column -->
          <div class="col-md-7">
            <!-- Begin heading -->
            <div class="heading heading-md heading-uppercase heading-hover">
              <br>
              <h2
                      id="title"
                      class="heading-title text-white">
                {{string.recoveryPassword._forgot_password_heading_text}}
              </h2>

              <!-- Begin divider -->
              <hr class="hr-width-1 hr-5x border-main">
              <!-- End divider -->

              <p
                      id="description"
                      class="heading-tescription lead text-gray-2">
                {{ string.recoveryPassword._forgot_password_reset_text }}
              </p>
            </div>
            <!-- End heading -->
          </div> <!-- /.col -->
        </div> <!-- /.row -->

        <div class="row">
          <!-- Begin contact form -->
          <div
                  id="contact-form"
                  class="margin-top-80 text-white wow fadeIn"
                  data-wow-delay="0.3s">
            <div
                    class="row"
                    style="margin: 2px;">
              <div class="col-lg-8">
                <div class="form-group">
                  <label
                          for="email"
                          class="sr-only">
                    {{ string.recoveryPassword._forgot_password_input_text }}
                  </label>
                  <input
                          id="email"
                          v-model="email"
                          type="email"
                          class="form-control no-rounded"
                          name="email"
                          value=""
                          placeholder="Email Address"
                          required
                          @keyup.enter=" (e) => { e.preventDefault(); this.resetPassword();}" />
                </div>
                <div class="form-group">
                  <button
                          @click=" (e) => { e.preventDefault(); this.resetPassword();}"
                          @keyup.enter=" (e) => { e.preventDefault(); this.resetPassword();}"
                          type="submit"
                          class="btn btn-warning active btn-block btn-lg">
                    {{string.recoveryPassword._forgot_password_button_text}}
                  </button>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
          <!-- End contact form -->
        </div> <!-- /.container -->
      </div>
        <div  v-else class="container" style="padding-bottom: 150px;">
            <div class="row wow fadeIn">
                <!-- Left column -->
                <div class="col-md-7">
                    <!-- Begin heading -->
                    <div class="heading heading-md heading-uppercase heading-hover">
                        <br>
                        <h2
                                class="heading-title text-white">
                            {{string.recoveryPassword._successRecovery_title}}
                        </h2>

                        <!-- Begin divider -->
                        <hr class="hr-width-1 hr-5x border-main">
                        <!-- End divider -->

                        <p
                                class="heading-tescription lead text-gray-2">
                            {{ string.recoveryPassword._successecovery_message }}
                            <a
                                    href="mailto:info@penguinpass.it"
                                    target="_blank">
                                info@penguinpass.it
                            </a>
                        </p>
                    </div>
                    <!-- End heading -->
                </div> <!-- /.col -->
            </div> <!-- /.row -->
        </div>
    </PublicLayout>
</template>

<script >
  import PublicLayout from '../layouts/PublicLayout';

  export default {
      components: {
          PublicLayout
      },
    data (){
      return {
        email: '',
        string: {
          recoveryPassword: {
            _forgot_password_heading_text: 'RECOVER PASSWORD',
            _forgot_password_input_text: 'Enter recovery Email-id',
            _forgot_password_reset_text: 'Enter your email below, we will send you a link to reset it, thanks.',
            _forgot_password_button_text: 'RESET',
          }
        },
        resetDone: false,
        emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      };

    },
    metaInfo: {
      title: 'Password Recovery - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      }, this);
    },
    mounted () {
    },
    methods: {
      resetPassword: function () {
          const context = this;
        // do body validation
        if(this.isDataValid()){
          this.$root.axios
                  .post('/auth/send-reset-link', {email: this.email})
                  .then(value => {
                    if(value.data.status === 'SUCCESS') {
                        context.resetDone = true;
                    }else {
                        console.log(value.data);
                        context.$notify({
                            title: 'Oops !',
                            message: value.data.message,
                            position: 'bottom-right',
                            type: 'error',
                            duration: 3000,
                        });
                    }
                  })
                  .catch(reason => {
                      context.$notify({
                          title: 'Oops !',
                          message: reason.message,
                          position: 'bottom-right',
                          type: 'error',
                          duration: 3000,
                      });
                    console.log(reason);
                  });
        }
      },
      isDataValid: function () {
        if(this.email === '' || !this.emailRegex.test(this.email)) {
            this.$notify({
                title: 'Oops !',
                message: 'email is not valid ',
                position: 'bottom-right',
                type: 'error',
                duration: 3000,
            });
          return false;
        }
        return true;
      }

    }
  };

</script>

<style >
</style>
