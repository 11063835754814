var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { ref: "section-1", staticClass: "tm-margin-large-top tm-faq" },
      [
        _c("div", { staticClass: "uk-container" }, [
          _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
            _vm._v("\n        FAQ\n      "),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "uk-margin-remove" }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
            [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-1")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Quale tipo di abbonamento è migliore per me?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-2")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Cosa sono dashboard, check-inApp e guestApp?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-3")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Quando attivo un evento cosa succede?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-4")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            A cosa servono i checkbox “conferma+1” e “richiedi nominativo del +1/ o note” e come funzionano?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-5")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla\n            lista?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-6")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Come funziona il Qr code per accedere all’ evento?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-7")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Posso aggiungere dettagli/attributi personalizzati sui miei invitati?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-8")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Come tenere basso il rischio di spam\n          "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-1",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Quale tipo di abbonamento è migliore per me?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-2",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Cosa sono dashboard, check-inAPP e guestApp?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(1),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-3",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Quando attivo un evento cosa succede?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(2),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-4",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          A cosa servono i checkbox “conferma+1” e “richiedi nominativo del +1/ o note” e come funzionano?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Sotto la sezione nella quale si genera l’email da inviare nella campagna sono presenti due\n          check- box il quale scopo, se attivati, è quello di permettere all’invitato (una volta inviato il\n          RSVP) di inserire il numero e/o i nominativi dei suoi accompagnatori.\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-5",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla\n          lista?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Quando l’invitato confermerà la propria partecipazione avrà (se da dashboard\n          l’organizzatore lo consente) la possibilià di inserire “nome, cognome e email” dei suoi\n          eventuali accompagnatori. Questi nomi e dati si potranno trovare semplicemente scaricando\n          il foglio excel tramite “donwload tutta la lista” nella sezione “guest list” sotto forma di nota.\n          Prelevando questi nominativi e risistemandoli in un nuovo foglio excel sull’apposito formato\n          “template per importazione xls” e ricaricandolo. Cosi facendo si potrà inviare email anche\n          agli accompagnatori dei tuoi ospiti se necessario, oppure semplicemente si potranno\n          visualizzare tramite Check-inApp.\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-6",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Come funziona il Qr code per accedere all’ evento?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(3),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-7",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Posso aggiungere dettagli/attributi personalizzati sui miei invitati?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(4),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-8",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Come tenere basso il rischio di spam\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(5),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per scegliere l’abbonamento più adatto alle tue esigenze ti consigliamo di contattarci a\n          "
      ),
      _c("u", [
        _c("a", { attrs: { href: "info@penguinpass.it" } }, [
          _vm._v("\n              info@penguinpass.it\n            "),
        ]),
      ]),
      _vm._v(
        " cosi da poter individuare insieme l’offerta migliore che meglio soddisfa le tue esigenze.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Dashboard, guest e check-in app sono I tre strumenti su cui si basa il sistema Penguinpass;\n          Il primo è utilizzato dagli organizzatori tramite accesso web per creare e gestire gli eventi\n          avendo così in unico strumento una visione completa e organizzata. "
      ),
      _c("br"),
      _vm._v(
        "\n          Le due app per mobile sono invece rivolte agli utilizzatori del servizio: la Check-inApp\n          permette alle hostess di gestire gli accessi in loco, le quali tramite questo supporto dinamico\n          possono controllare e aggiornare le liste ospiti in tempo reale. Mentre la GuestApp è\n          pensata e sviluppata per gli invitati che grazie all’app possono rimanere sempre informati e\n          aggiornati sugli eventi e accedere alle location con facilità e rapidità.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Nel momento in cui un evento viene attivato l'organizzatore potrà utilizzare la funzione di mail marketing\n          per\n          inviare inviti e comunicazioni ai propri ospiti.\n          "
      ),
      _c("br"),
      _vm._v(
        " Inoltre una volta attivato l'evento sarà disponibile sulle GuestApp e\n          quindi, visibile ai soli invitati, in caso di evento privato, a chiunque se è un evento pubblico.\n          "
      ),
      _c("br"),
      _vm._v(
        "\n          È importante notare che non è possibile utilizzare le funzionalità della Check-inApp se\n          l’evento non è stato attivato.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Spesso vi è la necessità o volontà di gestire le entrate agli eventi tramite QR Code, questo\n          vuol dire che gli invitati che invieranno i propri RSVP riceveranno un email della loro\n          conferma contenente dettagli aggiuntivi sull’evento e il QR Code personale per accedere.\n          Secondo questa logica di funzionamento, è quindi necessario impostare da dashboard l’invio\n          di una mail di conferma, e inserire in essa il contenuto dinamico (QR Code). "
      ),
      _c("br"),
      _vm._v("\n          centrare un Qr code nella e-mail"),
      _c("br"),
      _vm._v(
        "\n          Quando si inserisce il “contenuto dinamico” nel “modulo” vuoto in Emailbuilder, se si utilizza\n          un “modulo unico” (il primo nella sezione moduli) il Qr Code verrà visualizzato sul lato sinistro\n          dell’email, se si desidera che invece venga visualizzato al centro sarà necessario\n          semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in tre sezioni” (il settimo\n          della lista moduli) e inserire il contenuto dinamico in quella centrale.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per inserire un attributo specifico e personalizzato alla lista degli invitati, sarà sufficiente modificare\n          nel “template per importazione xls” (scaricato dal sito come sopra spiegato) la colonna “custom\n          note***” ponendo al suo posto il nome dell’attributo desiderato (es. da “custom note***” a “paese\n          d’origine” o “impresa”). "
      ),
      _c("br"),
      _vm._v(
        "\n          Se si necessita di più attributi-colonna, è possibile aggiungerne altri in seguito alla colonna “custom\n          note***”"
      ),
      _c("br"),
      _vm._v(
        '\n          In caso ci si dimentichi di aggiungere i campi extra al momento del caricamento dei guest, è possibile\n          aggiungere nuove colonne andando nella sezione impostazioni evento, espandendo la sezione "Modifica custom\n          fields" sarà possibile aggiungere nuovi attributi. Una volta aggiunti basterà selezionare la voce Check-in\n          app\n          per rendere il campo visibile nella app di check-in.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Di seguito vi diamo alcuni consigli per diminuire il rischio che le vostre campagne email vengano\n          classificate come spam dai server di posta. Prima però partiamo dal ranking, che è uno dei parametri dei\n          vari programmi di posta e dei server per gestire i messaggi. Inviando con Penguinpass il punteggio è di 89\n          su 100 che è molto alto "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          1. I programmi di posta se leggono parole come events, save the date, rsvp etc possono classificare in\n          automatico le mail come “promozioni” e le inseriscono nella cartella relativa su gmail. Quindi consigliamo\n          di evitare tali parole nell’oggetto della vostra campagna. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          2. Consigliamo che l’indirizzo di invio della mail (il mittente) non abbia parole come events, marketing\n          etc. per esempio events@cliente.com. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          3. Se ne avete la possibilità vi consigliamo di verificare il vostro dominio al posto del singolo indirizzo\n          email."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          4. Cercate di fare in modo che le mail inviate non abbiano solo un immagine (l’invito) e come testo solo il\n          “gentile nome + cognome”. E\' molto importante caricare in modo equilibrato testo e immagini (i software di\n          posta elettronica usano anche questo algoritmo per classificare le mail, il rapporto tra testo e immagini).\n          In sostanza si deve scrivere del testo, e poi le immagini. Maggiore sarà la proporzione di testo minore il\n          rischio che la vostra campagna venga classificata come "spam" o "promozione".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          5. Peso immagini: anche questo è un fattore importante. Meno pesano le immagini più velocemente si vedono e\n          sono meno “sospette” ai programmi di posta che proteggono da possibili virus o malware che hanno bisogno di\n          un peso minimo. L’ideale sarebbe allegare immagini/inviti tra i 50kb e i 150kb. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          6. Si consiglia di scrivere in minuscolo, o almeno non tutto in lettere capitali. La scrittura in\n          stampatello nell’oggetto condiziona + del 10% del pericolo di essere classificati come "spam".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          7. Un ultimo suggerimento è mandare almeno la prima volta che si fa una campagna, agli indirizzi di cui non\n          vedete aperta la mail o che non hanno fatto rsvp (su Penguinpass ci sono i filtri appositi), una mail a\n          parte (non con Penguinpass né con altri sistemi tipo mailchimp etc), ma con un client di posta normale\n          incollando gli indirizzi e che oltre a fare da reminder per l’evento li invita anche a controllare nello\n          spam o nelle altre cartelle eventuali mail ricevute da voi. Una volta che l’utente, su vostra richiesta, ha\n          spostato la mail in quelle ricevute il sistema di posta capisce che la volta successiva da quell’indirizzo\n          la mail dev’essere visualizzata.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }