<template>
  <MainLayout>
    <div class="default-layout">
      <div class="edit-page">
        <transition name="component-fade-fast" mode="out-in" appear>
          <LoadingCover
            v-if="loadingEffectshow"
            :loading-cover-msg="string.singleEvent._loadingMessage"
          />
        </transition>

        <div class="user-menu-container square">
          <div class=" user-details">
            <div class="white coralbg" :class="smartBg">
              <div class="col-sm-16 col-md-8 no-pad">
                <EventPad
                  :event="event"
                  :title="eventTitle"
                  :location="eventLocation"
                  :venue="eventVenue"
                  :start-date="eventStartDate"
                  :end-date="eventEndDate"
                  :start-time="eventStartTime"
                  :end-time="eventEndTime"
                  :start-date-object="eventStartDateObject"
                  :end-date-object="eventStartDateObject"
                  :read-only="isExhibitor"
                />
              </div>
              <div class="col-sm-16 col-md-8 no-pad bgColored">
                <CoverImage :src="eventCover" :event-id="this.eventId" :read-only="isExhibitor" />
              </div>
            </div>
            <!--            <div class="col-sm-16 col-md-8">-->

            <!--            </div>-->
          </div>
          <div class="">
            <VisibilityManager
              v-if="!isExhibitor"
              :event-id="this.eventId"
              :event="event"
              @visibilityChanged="onVisibilityChanged"
            />
          </div>
          <div class="">
            <Overview
              :event-id="eventId"
              :is-private="eventIsPrivate"
              @changeConfirmedCounter="updateCounter"
            />
          </div>
          <div class="user-menu-btns">
            <div id="responsive" class="btn-group-vertical square">
              <a
                id="description"
                class="btn btn-block btn-default active"
                style="padding: 25px !important"
                @click="
                  (e) => {
                    e.preventDefault();
                    changeTab('description');
                  }
                "
              >
                <i class="fa fa-file fa-2x" aria-hidden="true" />
              </a>

              <a
                v-if="event.genre === 'PRIVATE' && features && features.delegation"
                id="sponsor"
                class="btn btn-block btn-default"
                style="padding:25px !important;"
                @click="
                  e => {
                    e.preventDefault();
                    this.changeTab('sponsor');
                  }
                "
              >
                <i class="fa fa-address-card fa-2x" aria-hidden="true" />
              </a>
              <a
                id="guestlist"
                class="btn btn-block btn-default"
                style="padding: 25px !important"
                @click="
                  (e) => {
                    e.preventDefault();
                    changeTab('guestlist');
                  }
                "
              >
                <i class="fa fa-users fa-2x" aria-hidden="true" />
              </a>
              <!-- <a
                id="ticket"
                class="btn btn-default"
                style="padding:25px !important;"
                @click="
                  e => {
                    e.preventDefault();
                    this.changeTab('ticket');
                  }
                "
              >
                <i class="fa fa-ticket fa-2x" aria-hidden="true" />
              </a> -->
              <el-tooltip
                v-if="!showMarketingSection && !isExhibitor"
                effect="dark"
                :content="string.singleEvent._emailMarketingWarning"
                placement="bottom"
              >
                <a
                  class="btn btn-default"
                  style="padding: 25px !important"
                  @click="
                    (e) => {
                      e.preventDefault();
                    }
                  "
                >
                  <i class="fa fa-envelope-o fa-2x" aria-hidden="true" />
                </a>
              </el-tooltip>
              <a
                v-if="showMarketingSection && !isExhibitor"
                id="marketing"
                class="btn btn-default"
                style="padding: 25px !important"
                @click="
                  (e) => {
                    e.preventDefault();
                    changeTab('marketing');
                  }
                "
              >
                <i class="fa fa-envelope-o fa-2x" aria-hidden="true" />
              </a>
              <a
                v-if="features && features.textMessages && event.genre === 'PRIVATE'"
                id="whatsapp"
                class="btn btn-default"
                style="padding: 25px !important"
                @click.prevent="() => changeTab('whatsapp')"
              >
              <div style="{display:flex}">
                <i class="fa fa-commenting fa-2x" aria-hidden="true" />
                <i class="fa fa-whatsapp fa-2x" aria-hidden="true" />
              </div>
              </a>
              <!--              <a id='attachment'-->
              <!--                      v-on:click="e => {e.preventDefault(); this.changeTab('attachment');}"-->
              <!--                      class="btn btn-default"-->
              <!--                      style="padding:25px !important;">-->
              <!--                <i-->
              <!--                        class="fa fa-paperclip fa-2x"-->
              <!--                        aria-hidden="true"/>-->
              <!--              </a>-->
              <a
                v-if="features && features.virtualRoom"
                id="virtual-room"
                class="btn btn-default"
                style="padding: 25px !important"
                @click.prevent="() => changeTab('virtual-room')"
              >
                <i class="fa fa-desktop fa-2x" aria-hidden="true" />
              </a>
              <a 
                v-if="features && features.registrationForm && !features.virtualRoom"
                id="registration-form"
                class="btn btn-default"
                style="padding: 25px !important"
                @click.prevent="() => changeTab('registration-form')"
              >
                <i class="fa fa-desktop fa-2x" aria-hidden="true" />
              </a>
              <a
                id="report"
                class="btn btn-default"
                style="padding: 25px !important"
                @click="
                  (e) => {
                    e.preventDefault();
                    changeTab('report');
                  }
                "
              >
                <i class="fa fa-bar-chart fa-2x" aria-hidden="true" />
              </a>
              <a
                id="settings"
                class="btn btn-default"
                style="padding: 25px !important"
                @click="
                  (e) => {
                    e.preventDefault();
                    changeTab('settings');
                  }
                "
              >
                <i class="fa fa-cog fa-2x" aria-hidden="true" />
              </a>
            </div>
          </div>
          <div class="user-menu user-pad fix-width">
            <div id="tab-description" class="user-menu-content active">
              <Description
                :event="event"
                :event-id="eventId"
                :description="event.descriptionIt"
                :description-en="event.descriptionEn"
              />
            </div>
            <div id="tab-sponsor" class="user-menu-content">
              <SponsorListContainer
                v-if="currentSelectedTab === 'sponsor'"
                :event="event"
                :is-private="event.genre === 'PRIVATE'"
                :event-id="eventId"
                :confirmation-plus-one="counterConfirmationPlusOne"
                :custom-field-map="event.customFieldMap"
                :max-guests-per-event="event.maxGuests"
                :max-guests-per-member="event.maxGuestsPerMember"
                :event-name="event.name"
                :badge-printing-enabled="event.badgePrintingEnabled"
                :time-slot-enabled="event.timeSlotEnabled"
                :time-slot-map="event.timeSlotMap"
                :custom-field-map-filtered="event.combinedCustomFields"
                :hubilo-active="features.hubilo"
                :hubilo-event-id="event.hubiloEventId"
                @customFieldsChanged="onCustomFieldsChanged"
              />
            </div>
            <div id="tab-guestlist" class="user-menu-content">
              <GuestListContainer
                v-if="currentSelectedTab === 'guestlist'"
                :event="event"
                :is-private="event.genre === 'PRIVATE'"
                :event-id="eventId"
                :confirmation-plus-one="counterConfirmationPlusOne"
                :custom-field-map="event.customFieldMap"
                :max-guests-per-event="event.maxGuests"
                :max-guests-per-member="event.maxGuestsPerMember"
                :event-name="event.name"
                :badge-printing-enabled="event.badgePrintingEnabled"
                :time-slot-enabled="event.timeSlotEnabled"
                :time-slot-map="event.timeSlotMap"
                :custom-field-map-filtered="event.combinedCustomFields"
                :hubilo-active="features.hubilo"
                :hubilo-event-id="event.hubiloEventId"
                @customFieldsChanged="onCustomFieldsChanged"
              />
            </div>
            <!-- <div id="tab-ticket" class="user-menu-content">
              <Tickets :event="event" :event-id="this.eventId" />
            </div> -->
            <div id="tab-marketing-inactive" class="user-menu-content">
              {{ string.singleEvent._emailMarketingWarning }}
            </div>
            <div id="tab-whatsapp" class="user-menu-content">
              <TextMarketing
                v-if="currentSelectedTab === 'whatsapp' && !isExhibitor"
                :event="event"
                :event-id="eventId"
                @changeTab="changeTab"
              />
            </div>
            <div id="tab-marketing" class="user-menu-content">
              <DirectEmailMarketing
                v-if="currentSelectedTab === 'marketing' && !isExhibitor"
                :event="event"
                :event-id="eventId"
                @changeTab="changeTab"
              />
            </div>
            <!--            <div id="tab-attachment" class="user-menu-content">-->
            <!--              <Attachments-->
            <!--                      :event="event"-->
            <!--                      :event-id="this.eventId"/>-->
            <!--            </div>-->

            <!-- the order is changed, virtual room is registration form -->
            <div v-if="features && features.virtualRoom"
                 id="tab-virtual-room" class="user-menu-content">
              <RegistrationFormContainer
                v-if="currentSelectedTab === 'virtual-room'"
                :event="event"
                :event-id="eventId"
                @event-modified="onCustomFieldsChanged"
              />
            </div>
            <div v-if="features && features.registrationForm && !features.virtualRoom"
                 id="tab-registration-form" class="user-menu-content">
              <VirtualRoomContainer
                v-if="currentSelectedTab === 'registration-form'"
                :event="event"
                :event-id="eventId"
                @event-modified="onCustomFieldsChanged"
              />
            </div>
            <div id="tab-report" class="user-menu-content">
              <Report
                v-if="currentSelectedTab === 'report'"
                :event="event"
                :event-id="this.eventId"
              />
            </div>
            <div id="tab-settings" class="user-menu-content">
              <div class="advance-settings-container">
                <Messages v-if="!isExhibitor" v-show="!isPrivate" :event="event" :event-id="eventId" />
                <BadgePrinting
                  v-if="features && features.badgePrinting"
                  :event="event"
                  :event-id="this.eventId"
                />
                <!-- <VirtualRoom
                  v-if="features && features.virtualRoom"
                  :event="event"
                  :event-id="this.eventId"
                /> -->
                <!-- v-if="features && features.timeSlot" -->
                <TimeSlotManager
                  :event="event"
                  :event-id="eventId"
                  @customFieldsChanged="onCustomFieldsChanged"
                />
                <AdvanceSettings :event="event" @customFieldsChanged="onCustomFieldsChanged" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </MainLayout>
</template>
<style>
button.discardStyle {
  text-align: left;
  background: transparent;
  display: inline;
  cursor: pointer;
  border: transparent;
  color: white;
  text-decoration: none;
}
button.discardStyle:hover {
  text-align: left;
  background: transparent;
  display: inline;
  cursor: pointer;
  border: transparent;
  color: #f7a30c;
  text-decoration: underline;
}
</style>
<style scoped>
.btn-block {
  text-transform: uppercase;
}

.bgColored {
  background-color: #333333;
}

.edit-page {
  font-family: "Roboto", sans-serif;
}

.edit-page .privabg {
  background-color: rgba(247, 163, 12, 0.3);
  color: #ffffff;
}

.edit-page .publibg {
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.edit-page div.user-menu-container {
  margin-top: 0 !important;
}

.edit-page div.user-menu-container .btn-group-vertical > a {
  padding: 26px;
  background-color: rgba(255, 255, 255, 0.3) !important;
  color: white;
  border-color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1px;
  margin-right: 1px;
}

.default-layout .edit-page div.user-menu-container .btn-group-vertical > a.active {
  background-color: #f7a30c !important;
}
.default-layout .edit-page div.user-menu-container .btn-group-vertical > a:hover {
  background-color: #f7a30c !important;
}

.gobella-layout .edit-page div.user-menu-container .btn-group-vertical > a.active {
  background-color: #e7b8c1 !important;
}
.gobella-layout .edit-page div.user-menu-container .btn-group-vertical > a:hover {
  background-color: #e7b8c1 !important;
}

.user-menu-container {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}

/** MediaQuery per la gestione del titolo */

@media (min-width: 980px) {
  .eventCoverImage {
    height: 400px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .eventCoverImage {
    height: 200px;
  }
}

@media (max-width: 768px) {
  .eventCoverImage {
    /*height: 100px;*/
  }
}

@media (max-width: 320px) {
  .eventCoverImage {
    /*height: 100px;*/
  }
}

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../assets/css/dashboard/main.css";
</style>
<script>
import {
  GET_EVENT_DETAIL
} from "@/graphql";
import MainLayout from "../layouts/Main.vue";
import LoadingCover from "../components/LoadingCover.vue";
import Overview from "../components/Overview.vue";
import CoverImage from "../components/CoverImage.vue";
import Description from "../components/Description.vue";
import Attachments from "../components/Attachments.vue";
import EventPad from "../components/EventPad.vue";
import Messages from "../components/Messages.vue";
import BadgePrinting from "../components/BadgePrinting.vue";
import TimeSlotManager from "../components/TimeSlotManager.vue";
import VirtualRoom from "../components/VirtualRoom.vue";
import DirectEmailMarketing from "../components/DirectEmailMarketing.vue";
import TextMarketing from "../components/TextMarketing.vue";
import GuestList from "../components/GuestList.vue";
import VisibilityManager from "../components/VisibilityManager.vue";
import Report from "../components/Report.vue";
import Tickets from "../components/Tickets.vue";
import AdvanceSettings from "../components/event/settings/AdvanceSettings.vue";
import GuestListContainer from "../containers/event/GuestListContainer.vue";
import VirtualRoomContainer from "../containers/event/VirtualRoomContainer.vue";
import RegistrationFormContainer from "../containers/event/RegistrationFormContainer.vue";
import SponsorListContainer from "../containers/event/SponsorListContainer.vue"
import store from "../store";
import "element-ui/lib";
import $ from "jquery";
import { mapGetters } from "vuex";

Array.prototype.last = function () {
  return this[this.length - 1];
};
export default {
  components: {
    MainLayout,
    LoadingCover,
    Overview,
    CoverImage,
    EventPad,
    Description,
    Attachments,
    Messages,
    BadgePrinting,
    GuestList,
    VisibilityManager,
    Report,
    AdvanceSettings,
    Tickets,
    DirectEmailMarketing,
    TextMarketing,
    VirtualRoom,
    GuestListContainer,
    TimeSlotManager,
    VirtualRoomContainer,
    RegistrationFormContainer,
    SponsorListContainer,
  },
  props: {
    eventId: String,
  },
  data() {
    return {
      msg: "SINGLE EVENT",
      componentName: "single-event",
      event: {},
      smartBg: "coralbg",
      eventIsPrivate: true,
      showMarketingSection: false,
      eventStartDate: "",
      eventEndDate: "",
      eventStartTime: "",
      eventEndTime: "",
      eventLocation: this._insertPlace,
      eventVenue: "",
      eventCover: "",
      loadingEffectshow: false,
      styleObject: {
        color: "red",
        fontSize: "13px",
        backgroundImage: "",
        "background-size": "cover",
      },
      // app_settings: userdata.app_settings,
      string: {
        /*
              singleEvent: {
                  _event : "Evento",
                  _createString : "Crea il tuo primo elenco di invitati",
                  _prospectList : "Lista invitati caricati",
                  _add : "Aggiungi manualmente",
                  _download : "Scarica lista",
                  _upload : "Carica lista",
                  _next : "Avanti",
                  _insertPlace : "Inserisci un luogo",
                  _loadingMessage : "Attendere...",
              }
              */
      },
      hasChanges: false,
      numberToReload: 0,
      eventTitle: "",
      eventStartDateObject: "",
      guestList: [],
      uploadStatus: "NULL",
      uploadStatusMessage: "",
      uploadProgress: 0,
      // for changing tab
      isDefault: true,
      isActive: false,
      //for max attendee setting
      counterConfirmationPlusOne: 0,
      currentSelectedTab: "description",
    };
  },
  metaInfo: {
    title: "Event - Penguinpass",
    bodyAttrs: {
      class: "dashboard",
    },
  },
  computed: {
    ...mapGetters(["features", "user", "isExhibitor"]),

    isPrivate() {
      return this.event.genre === "PRIVATE";
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.loadEvent(this.eventId);
  },
  beforeRouteEnter(to,from,next) {
    store.commit("switchSidebar", false);
    next();
  },
  methods: {
    loadEvent: function (eventId) {
      if (!this.user) {
        this.$router.push("/login").catch((error) => {});
      }
      if (this.user.role !== "OWNER" && this.user.role !== "STAFF_MANAGER") {
        if (!this.user.assignedEvents || this.user.assignedEvents.indexOf(this.eventId) === -1) {
          this.$router.push("/404").catch((error) => {});
        }
      }
      this.loadingEffectshow = true;
      const context = this;
      // this.unsubscribeFromUpdates();
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_EVENT_DETAIL,
          variables: { eventId: eventId },
        })
        .then((response) => {
          if (response.errors) {
            context.loadingEffectshow = false;
            console.log(response.errors);
          } else {
            if (response.data && response.data.Event) {
              context.event = response.data.Event;
              context.setEventVisibleProperties(context.event);
              context.loadingEffectshow = false;
            }
          }
        })
        .catch((error) => {
          context.loadingEffectshow = false;
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].message === "PERMISSION_DENIED"
          ) {
            context.$router.push("/404").catch((error) => {});
            return;
          }
          console.log(error);
        });
    },
    getLocationFormatted: function (item) {
      if (!item.address) {
        return "";
      }
      const parsedAddress = JSON.parse(item.address);

      const streetNumber =
        typeof parsedAddress.street_number == "string" && parsedAddress.street_number.length > 0
          ? " " + parsedAddress.street_number
          : "";
      return (
        parsedAddress.address +
        streetNumber +
        ", " +
        parsedAddress.postalcode +
        " " +
        parsedAddress.city
      );
    },
    getDateFormatted: function (d) {
      const day = d.getDate();
      const mon = this.fixNumberPresentation(d.getMonth() + 1);
      const yea = d.getFullYear();
      return day + "/" + mon + "/" + yea;
    },
    getTimeFormatted: function (d) {
      const h = this.fixNumberPresentation(d.getHours());
      const m = this.fixNumberPresentation(d.getMinutes());
      return h + ":" + m;
    },
    fixNumberPresentation: function (n) {
      const r = "";
      if (n < 10) {
        return r.concat("0", n);
      }

      return n;
    },
    setCover: function (cover, defaultCover) {
      if (typeof cover != "undefined") {
        return cover;
      }
      return defaultCover;
    },
    setEventVisibleProperties: function (item) {
      const context = this;
      context.eventIsPrivate = item.genre === "PRIVATE";
      context.showMarketingSection = item.status === "ACTIVE";
      context.smartBg = item.genre === "PRIVATE" ? "privabg" : "publibg";
      context.eventStartDate = context.getDateFormatted(new Date(item.startDate));
      context.eventEndDate = context.getDateFormatted(new Date(item.endDate));
      context.eventEndDateObject = new Date(item.endDate);
      context.eventStartTime = context.getTimeFormatted(new Date(item.startDate));
      context.eventStartTimeObject = new Date(item.startDate);
      context.eventEndTime = context.getTimeFormatted(new Date(item.endDate));
      context.eventCover = context.setCover(item.image, context.eventCover);
      context.eventTitle = item.name;
      context.eventLocation = context.getLocationFormatted(item);
      context.eventVenue = item.venue;
      context.loadingEffectshow = false;
    },
    onCustomFieldsChanged: function () {
      this.loadEvent(this.eventId);
      // this.hasChanges = true;
    },
    onVisibilityChanged: function (data) {
      this.event.status = data.status;
      this.showMarketingSection = data.status === "ACTIVE";
      const marketingTab = $(`#tab-marketing`);
      if (marketingTab.hasClass("active")) {
        marketingTab.removeClass("active");
        $(`#tab-marketing-inactive`).addClass("active");
      }
    },
    changeTab: function (tabName) {
      this.currentSelectedTab = tabName;

      //fix highlight
      let current_tab_active = $("div.user-menu>div.user-menu-content.active").attr("id");
      current_tab_active = current_tab_active ? current_tab_active.substr(4) : "description";
      //console.log(current_tab_active);
      //
      $("div.user-menu>div.user-menu-content.active").removeClass("active");
      $(`#tab-${tabName}`).addClass("active");

      //fix highlight
      $(`#${current_tab_active}`).removeClass("active");
      $(`#${tabName}`).addClass("active");
      //
    },
    updateCounter: function (value) {
      this.counterConfirmationPlusOne = value;
    },
  },
};
</script>
