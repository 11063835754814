<template>
    <div class="user-image" @mouseover="mouseOver" @mouseout="mouseOut" @mousedown="mouseDown" style="position: relative;">
      <transition name="component-fade-fast" mode="out-in" appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.coverImage._loadingMessage"
        />
      </transition>
        <transition name="component-fade-fast" mode="out-in" appear>
          <button
            v-if="showUpdateButton === true"
            type="button"
            class="btn btn-labeled btn-info"
          >
            <span class="btn-label">
              <i aria-hidden="true" class="fa fa-pencil" />
            </span>
            Update
          </button>
        </transition>
        <transition name="component-fade-fast" mode="out-in" appear>
          <div v-if="showUpdateButton === true" class="updateButtonContainerBg">
            <div class="imgNotices">{{ string.coverImage._imgNoticesBG }}</div>
          </div>
        </transition>
      <img v-bind:src="srcImage || require('../assets/img/bg-5.jpg')" class="img-responsive thumbnail cover-image-constraints" alt="background Image"/>
       <input id="fileinputbg" ref="fileinput" type="file" accept="image/*" @change="onFileChange" />
    </div>
    
  </template>
  <style scoped>
    .cover-image-constraints {
        width: 100%;
        height: 400px;
    }
  .imgNotices {
    position: absolute;
    top: 175px;
    left: 30px;
    right: 30px;
    border: 1px solid #fff;
    color: #fff;
    padding: 20px;
    text-align: center;
  }
  
  .thumbnail{
      object-fit: cover;
  }
  
  .updateButtonContainer {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    position: relative;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
  }
  
  .updateButtonContainerBg {
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  
  
  .user-image button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;
    color: #fff;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .user-image button:hover {
    background-color: #fff;
    color: #333;
  }
  
  .component-fade-fast-enter-active,
  .component-fade-fast-leave-active {
    transition: opacity 0.3s ease;
  }
  .component-fade-fast-enter,
  .component-fade-fast-leave-active {
    opacity: 0;
  }
  .component-fade-enter-active,
  .component-fade-leave-active {
    transition: opacity 0.9s ease;
  }
  .component-fade-enter,
  .component-fade-leave-active {
    opacity: 0;
  }
  </style>
  <script>
  import LoadingCover from "./LoadingCover.vue";
  import { USER_INFO, UPDATE_MY_USER, MY_TEAM } from '@/graphql';
  
  
  export default {
    components: {
      LoadingCover
    },
    props: {
      organizerId: String,
      readOnly: Boolean
    },
    data() {
      return {
        string: {},
        showUpdateButton: false,
        srcImage: "",
        fileToSelect: true,
        fileList: "",
        fileListName: "",
        loadingEffectshow: false,
        showImage: true
      };
    },
    watch: {
      src: function() {
        if (this.src != null) {
          this.srcImage = this.src;
        } else {
          this.srcImage = this.getDefaultImgUrl();
        }
      },
      loadingEffectshow: function() {
        this.showImage = !this.loadingEffectshow;
      }
    },
    created() {
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener("setLanguage", () => {
        context.string = context.$root.strings;
      });
    },
    mounted() {
      this.$apollo.query({
        query:USER_INFO,
      }).then((res) => {
        this.srcImage = res.data.whoAmIUser.backgroundUrl;
      });
    },
    methods: {
      getDefaultImgUrl: function()  {
        return require('../assets/img/bg-5.jpg');
      },
      mouseDown: function() {
        this.open();
      },
      mouseOver: function() {
        this.showUpdateButton = true;
      },
      mouseOut: function() {
        this.showUpdateButton = false;
      },
      open: function() {
        if(this.readOnly) {
          return;
        }
        this.$refs.fileinput.click();
      },
      onFileChange: function(e) {
        this.fileToSelect = false;
        const files = e.target.files || e.dataTransfer.files;
        if (!files.length) {
          return;
        }
        if(files[0].size>1000000){
          alert(this.string.coverImage._imgSizeExceed)
        }
        else{
          this.fileList = files[0];
          this.fileListName = files[0].name;
          this.submit(); 
        }
        console.log(files);
        
      },
      emptyFields: function() {
        this.fileToSelect = true;
        document.getElementById("fileinput").value = "";
      },
      updateImage: function(path) {
        const context = this;
        return this.$apollo
          .mutate({
            mutation: UPDATE_MY_USER,            
            variables: {
                updates: {
                  backgroundUrl: path
                }
              } 
          })
          .then(value => {
            if (value.errors) {
              console.log("error " + value.errors);
              context.loadingEffectshow = false;
            } else {
              context.srcImage = path;
              context.loadingEffectshow = false;
            }
            this.$store.commit('REFRESH_USER', value.data.updateMyUser);
            const container = document.querySelector('#container');
            console.log(this.$store.state.user)
            container.style.backgroundImage = `url(${this.$store.getters.user.backgroundUrl})`;
          })
          .catch(reason => {
            console.log(reason.message);
            context.loadingEffectshow = false;
          });
      },
      submit: function() {
        const context = this;
        context.loadingEffectshow = true;
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        const data = new FormData();
        if (context.fileList !== null) {
          data.append("fileToUpload", context.fileList);
        }
        data.append("organizerId", context.organizerId); //not sure
        return this.$root.axios
          .post("/upload/bg", data, config) //same as backend?
          .then(response => {
            console.log(response);
            context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
            context.emptyFields();
            context.updateImage(response.data.filepath);
          })
          .catch(error => {
            console.log(error.message);
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed + " " + error.message );
            context.emptyFields();
          });
      }
    },
  };
  </script>
  