<template>
  <PublicLayout>
    <div class="container">
      <div class="row wow fadeIn">
        <div class="col-md-7">
          <div class="heading heading-md heading-uppercase heading-hover">
            <br>
            <h2
              id="title"
              class="heading-title text-white">
              {{string.verificationResult._success_title}}
            </h2>

            <hr class="hr-width-1 hr-5x border-main">
            <p
              id="description"
              class="heading-tescription lead text-gray-2">
              {{ string.verificationResult._success_message }}

            </p>
          </div>
        </div>

      </div>
    </div>
  </PublicLayout>
</template>

<script >
  import PublicLayout from "../layouts/PublicLayout"
  export default {
    components: {PublicLayout},
    data (){
      return {
        string: {
          verificationResult: {
            _success_title: 'SUCCESS!',
            _success_message: 'Thank you for verification. Now you can use email marketing with style by using your email address!',
          }
        },
        pageLoaded: false
      };

    },
    metaInfo: {
      title: 'Verification - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      }, this);
    },
    mounted () {}
  };

</script>


<style>

</style>
