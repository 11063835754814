var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout" }, [
    _c(
      "div",
      {
        staticClass: "timeSlotManager-component",
        attrs: { id: "timeSlotManager" },
      },
      [
        _vm.loadingEffectshow
          ? _c(
              "div",
              { staticClass: "higherPosition" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.loadingEffectshow
                      ? _c("LoadingCover", {
                          attrs: { "loading-cover-msg": "Loading..." },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "accordion" }, [
          _c(
            "h4",
            { staticClass: "form-section-title first accordion-toggle" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.string.timeSlotManager._title) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "accordion-content" }, [
            _c("div", { staticClass: "toggleSelection" }, [
              _c("label", { staticClass: "control control-checkbox" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.timeSlotManager._innertitle) +
                    "\n            "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showEditor,
                      expression: "showEditor",
                    },
                  ],
                  attrs: { id: "checkbox", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.showEditor)
                      ? _vm._i(_vm.showEditor, null) > -1
                      : _vm.showEditor,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.showEditor,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.showEditor = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.showEditor = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.showEditor = $$c
                        }
                      },
                      _vm.toggleTimeSlot,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "control_indicator" }),
              ]),
            ]),
            _vm._v(" "),
            _vm.timeSlotRawData.length > 0
              ? _c(
                  "div",
                  {
                    staticStyle: { "font-size": "18px", "margin-top": "15px" },
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn save",
                        on: { click: _vm.deleteAllSlots },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.string.timeSlotManager._deleteAllSlots) +
                            "\n          "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "ExportICS",
                      {
                        staticClass: "btn save",
                        attrs: { "get-data": _vm.fetchBookings },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.string.timeSlotManager._export_data) +
                            "\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showEditor
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "min-width": "200px",
                      "min-height": "200px",
                      "margin-top": "45px",
                    },
                  },
                  [
                    _vm.timeSlotRawData.length <= 0
                      ? _c("ICSUpload", {
                          attrs: {
                            "start-date": _vm.startDate,
                            "end-date": _vm.endDate,
                            "custom-field-source": _vm.customFieldSource,
                            "father-name": "timeSlotManager",
                          },
                          on: { onChange: _vm.onChange },
                        })
                      : _c(
                          "div",
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  float: "right",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "el-form-item_label" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.string.timeSlotManager
                                            ._maxLimitPerSlot
                                        ) +
                                        ":\n              "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("NumericFieldInput", {
                                  attrs: {
                                    "initial-value": _vm.maxLimitPerSlot,
                                  },
                                  on: { change: _vm.setMaxLimitPerSlot },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("data-table", {
                              ref: "timeSlotTable",
                              attrs: {
                                "get-data": _vm.getData,
                                "filter-enabled": false,
                                columns: _vm.timeSlotColumns,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        { staticClass: "p-table-cell" },
                                        [
                                          row.edit && !row.disabled
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model.trim",
                                                    value: _vm.editItem.title,
                                                    expression:
                                                      "editItem.title",
                                                    modifiers: { trim: true },
                                                  },
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.editItem.title,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editItem,
                                                      "title",
                                                      $event.target.value.trim()
                                                    )
                                                  },
                                                  blur: function ($event) {
                                                    return _vm.$forceUpdate()
                                                  },
                                                },
                                              })
                                            : _c("span", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      row.title +
                                                        (row.disabled
                                                          ? " " +
                                                            _vm.string
                                                              .timeSlotManager
                                                              ._disabled
                                                          : "")
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]),
                                        ]
                                      )
                                    },
                                  },
                                  {
                                    key: "startDate",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        { staticClass: "p-table-cell" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                new Date(row.startDate).format()
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    },
                                  },
                                  {
                                    key: "endDate",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        { staticClass: "p-table-cell" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                new Date(row.endDate).format()
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    },
                                  },
                                  {
                                    key: "maxLimit",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        {},
                                        [
                                          row.edit
                                            ? _c("NumericFieldInput", {
                                                staticClass: "p-table-cell",
                                                staticStyle: {
                                                  width: "150px !important",
                                                },
                                                attrs: {
                                                  min:
                                                    _vm.editItem.maxLimit === 0
                                                      ? _vm.editItem.maxLimit
                                                      : _vm.editItem.limit,
                                                  "initial-value":
                                                    typeof _vm.editItem
                                                      .maxLimit === "number"
                                                      ? _vm.editItem.maxLimit
                                                      : 0,
                                                },
                                                on: {
                                                  change: function (value) {
                                                    _vm.onMaxLimitChange(
                                                      row,
                                                      value
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("div", [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      typeof row.maxLimit ===
                                                        "number" &&
                                                        row.maxLimit > 0
                                                        ? (row.limit || 0) +
                                                            "/" +
                                                            row.maxLimit
                                                        : "-"
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]),
                                        ],
                                        1
                                      )
                                    },
                                  },
                                  {
                                    key: "Actions",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-align": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "action_link",
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                              on: {
                                                click: function () {
                                                  return row.edit === false
                                                    ? _vm.openRow(row)
                                                    : _vm.saveRow(row)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                class:
                                                  row.edit === false
                                                    ? "fa fa-pencil-square-o"
                                                    : "fa fa-check",
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "action_link",
                                              staticStyle: {
                                                "margin-right": "5px",
                                              },
                                              on: {
                                                click: function () {
                                                  return row.edit === false
                                                    ? _vm.deleteRow(row)
                                                    : _vm.closeRow(row)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                class:
                                                  row.edit === false
                                                    ? "fa fa-trash"
                                                    : "fa fa-times",
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    },
                                  },
                                ],
                                null,
                                false,
                                3642530068
                              ),
                            }),
                          ],
                          1
                        ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }