var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c(
      "div",
      { staticClass: "center-it container" },
      [
        _c("div", { staticClass: "error-title" }, [
          _vm._v(_vm._s(_vm.string.errorsNotFound._page_not_found)),
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("span", {
          staticClass: "error-description",
          domProps: {
            textContent: _vm._s(_vm.string.errorsNotFound._page_not_found_desc),
          },
        }),
        _vm._v(" "),
        _c("router-link", { attrs: { to: { path: "/" }, tag: "a" } }, [
          _c("b", [_vm._v(_vm._s(_vm.string.errorsNotFound._go_to_home))]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }