var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "report-component" }, [
    _c("h4", { staticClass: "form-section-title" }, [
      _vm._v("\n    " + _vm._s(_vm.string.report._title) + " "),
      _c("small", [_vm._v(_vm._s(_vm.string.report._subtitle))]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smallseparator" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "print-analytics-panel-class",
        attrs: { id: "print-analytics-panel" },
      },
      [
        _c("div", { staticClass: "row" }, [
          _c("h1", { staticClass: "main-title" }, [
            _vm._v("\n        " + _vm._s(_vm.event.name) + "\n      "),
          ]),
          _vm._v(" "),
          _c("h2", { staticClass: "main-title" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.string.report._subtitle) + "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c("div", { staticClass: "title-graph" }, [
                _c("h2", [_vm._v(_vm._s(_vm.string.report._attendeeChart))]),
              ]),
              _vm._v(" "),
              _vm._m(3),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "panel panel-default" }, [
      _c("div", { staticClass: "panel-body" }, [
        _c("div", { staticClass: "row data-export-icon" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c(
                "button",
                {
                  staticClass: "print-button button btn-warning",
                  attrs: { disabled: _vm.exportingReport },
                  on: { click: _vm.fetchDataAndPrint },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-download",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row data-export-excel-icon" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c(
                "button",
                {
                  staticClass: "print-button button btn-warning",
                  attrs: { disabled: _vm.exportingReport },
                  on: { click: _vm.fetchDataAndExportReport },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-file-excel-o",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.reportEnabled
          ? _c("div", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
                  [
                    _c("div", { staticClass: "content-panel" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.string.report._totalAttendeeStatus)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "panel-body text-center" }, [
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { id: "canvasHolderTotalAttendee" },
                          },
                          [_c("canvas", { ref: "totalAttendees" })]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
                  [
                    _c("div", { staticClass: "content-panel" }, [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.string.report._timeLineCheckInTitle)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "panel-body text-center" }, [
                        _c("div", { staticClass: "cont-didascalia" }, [
                          _c(
                            "div",
                            { staticClass: "didascalia well well-sm" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.string.report
                                      ._timeLineCheckinChartLegend
                                  ) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { id: "canvasHolderTimeline" },
                          },
                          [_c("canvas", { ref: "timelineChecked" })]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row mt" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
                  [
                    _c("div", { staticClass: "content-panel" }, [
                      _c("h4", [
                        _vm._v(
                          _vm._s(
                            _vm.string.report._timeLineAttendeeConfirmationTitle
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "panel-body text-center" }, [
                        _c("div", { staticClass: "cont-didascalia" }, [
                          _c(
                            "div",
                            { staticClass: "didascalia well well-sm" },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.string.report
                                      ._attendeeConfirmationChartLegend
                                  ) +
                                  "\n                  "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { id: "canvasHolderConfirmation" },
                          },
                          [
                            _c("canvas", {
                              ref: "timelineAttendeesConfirmation",
                            }),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ])
          : _c("div", { staticClass: "coming-soon" }, [
              _vm._v("\n        Coming soon\n      "),
            ]),
        _vm._v(" "),
        _c("div", { staticClass: "row data-export" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c(
                "a",
                {
                  staticClass: "button print-button btn-warning",
                  attrs: { disabled: _vm.exportingReport },
                  on: { click: _vm.fetchDataAndPrint },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-download",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.string.report._export) +
                      " PDF\n          "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c(
                "a",
                {
                  staticClass: "button print-button btn-warning",
                  attrs: { disabled: _vm.exportingReport },
                  on: { click: _vm.fetchDataAndExportReport },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-download",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.string.report._export) +
                      " XLSX\n          "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c("div", { staticClass: "title-graph" }, [
                _c("h4", [
                  _vm._v(_vm._s(_vm.$root.strings.virtualRoomSettings._title)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "img-placeholder",
                attrs: { id: "timeline-attendee-confirmation-image" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row data-export" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" },
            [
              _c(
                "a",
                {
                  staticClass: "button print-button btn-warning",
                  attrs: { disabled: _vm.exportingReport },
                  on: { click: _vm.downloadJoinLogs },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-download",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.$root.strings.virtualRoomSettings._downloadlogs
                      ) +
                      "\n          "
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" }, [
        _c("div", { staticClass: "title-graph" }, [
          _c("h2", [_vm._v("Total Attendee Status")]),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "img-placeholder first-img-placeholder",
          attrs: { id: "total-attendee-image" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" }, [
        _c("div", { staticClass: "title-graph" }, [
          _c("h2", [_vm._v("Timeline Attendee Checked")]),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "img-placeholder",
          attrs: { id: "timeline-checked-image" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12 col-md-12 col-sm-12 col-xs-12" }, [
        _c("div", { staticClass: "title-graph" }, [
          _c("h2", [_vm._v("Timeline of Attendee Confirmation")]),
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "img-placeholder",
          attrs: { id: "timeline-attendee-confirmation-image" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("table", { attrs: { id: "attendee-report-table" } }, [
        _c("thead", [
          _c("th", [_c("h3", [_vm._v("Name")])]),
          _vm._v(" "),
          _c("th", [_c("h3", [_vm._v("Status")])]),
          _vm._v(" "),
          _c("th", [_c("h3", [_vm._v("Check-in Time")])]),
        ]),
        _vm._v(" "),
        _c("tbody"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }