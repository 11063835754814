var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n        Quick Start\n      "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/en-home" } }, [
                _vm._v("\n            Support\n          "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v("\n            How to create a new event\n          "),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to upload a guest list \n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to activate RSVP buttons\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            ​​How to generate a QR code when a guest confirms\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to personalize invites with the name of the guest\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to create a personalized landing page\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          How to create a new event\n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          How to upload a guest list \n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          How to activate RSVP buttons\n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n          The rsvp and decline buttons allow your guests to accept or decline your invitations, watch the\n          video\n          to learn how these components are made.\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          ​​How to generate a QR code when a guest confirms\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to personalize invites with the name of the guest\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to create a personalized landing page\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              'To create a custom landing page you will need to go to the virtual room section..\n          Here the Virtual room section that will allow you to create a landing page dedicated to your event. First \n          click on “ activate your Penguin Pass room”. Then you can choose whether to create a new landing page from \n          scratch or using a template. Once created, templates can be reused on other events, however if it is your \n          first event, it is necessary to select  "create new".\n          The builder is very similar to what you find in the mail campaign section. To create the page just drag \n          the available contents from the panel on the right. We recommend using “rows” and dividers to have your \n          content in an orderly fashion.\n          If you have purchased virtual meeting or webinar rooms you will find them in the dynamic contents\n          once your room is ready, click on the "save" button at the top right.\n          At this point, if you have not entered virtual room modules, just click on save settings and publish the \n          page. After saving the settings a table with the url address of your page appears.\n          '
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/en-quickstart" } }, [
        _vm._v("\n            Quick Start\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          ​​From the Dashboard homepage, by clicking on the icon with the "+" symbol, you can create a new event.'
      ),
      _c("br"),
      _vm._v(
        '\n          You will need to choose whether it is a "private" or a "public" event. Regardless of the choice, to complete \n          the creation you must fill in the fields: "Category, Name, Start Date, End Date and Address", optionally the \n          "Poster and Description" fields.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          After you have entered the “guest list” section, it’s possible to add guests to the list in 2 ways:"
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("MANUALLY ")]),
      _vm._v(
        " inserting guest by guest through the “+ add manually” button, while filling the indicated \n          fields: “Name, Last name, Email, and Phone number”. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("AUTOMATICALLY  ")]),
      _vm._v(
        " uploading the filled-in excel template through the “upload list” button."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n          The excel file needs to be organized according to particular characteristics, it is necessary to use the \n          “Template Import File XLSX” under the button “Choose”. Once downloaded the template, it will just be a \n          matter of following the instructions present on the first 6 lines of the excel spreadsheet:"
      ),
      _c("br"),
      _vm._v("\n          Do not delete the first 6 lines"),
      _c("br"),
      _vm._v(
        "\n          Delete the template fake guest “Mario Rossi” (there as an example);"
      ),
      _c("br"),
      _vm._v(
        "\n          Fill in the fields for your guests paying attention to the specific fields and columns"
      ),
      _c("br"),
      _vm._v(
        "\n          The excel file containing your guest list needs to be selected through the “Choose” button \n          and afterward to proceed to the upload, press the “Upload” button"
      ),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          If you want to manage guest access to the event through the QR code, you will need to send a confirmation email containing the QR Code. \n          First, start by creating a confirmation email. To create a QR code, you will simply need to add a “dynamic content” module from the \n          email builder, along with everything else you would like to include in the confirmation email (for example information and event agenda).\n          This way, the guests will receive a personalized email containing the QR Code, after 5-10 minutes from the moment of confirmation. \n          "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("b", [
        _vm._v(
          "HOW TO DISPLAY THE QR CODE OR OTHER ELEMENTS IN THE CENTER OF THE EMAIL"
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        '\n          When inserting the "dynamic content" in the empty module in Email Builder, if you use a "single module" (the first in the modules section) \n          the QR code will be displayed on the left in the email. If you want it to be displayed in the center instead, you can use the module that is \n          divided into three sections (the seventh in the list of modules) and insert the dynamic content in the central one.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          When generating an email campaign it is essential to make your guests feel unique and special, \n          so it is necessary to ensure that each of them receives a personalized email with their name and surname."
      ),
      _c("br"),
      _vm._v(
        "\n          It’s very simple to create an email with the EMAIL BUILDER which reads the name of the guest. You will \n          simply need to enter a text module from the toolbar and when editing the text module, just click on “dynamic \n          fields” and select whether you would like to enter a first name, the last name, or both. Once the email is sent,\n          the dynamic field will simply turn in each guest’s name.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }