<template>
  <div class="row list-upload">
    <h4>{{ string.timeSlotManager._upload }}</h4>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <transition name="component-fade-fast" mode="out-in" appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.listUpload._loadingMessage"
        ></LoadingCover>
      </transition>
      <div id="uploadInteractionContainer">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            &nbsp;
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            <input
              :id="fileInputName"
              ref="fileinput"
              type="file"
              accept="text/calendar"
              @change="onFileChange"
            />
            <transition name="component-fade-fast" mode="out-in" appear>
              <a v-if="fileToSelect === true" :title="string.listUpload._browse" @click="open">
                <i aria-hidden="true" class="fa fa-folder-open"></i>
                {{ string.listUpload._browse }}
              </a>
              <div v-if="fileToSelect === false">
                <p>{{ selectedFileName }}</p>
                <a :title="string.listUpload._clear" class="clear" @click="clear">
                  <i aria-hidden="true" class="fa fa-times"></i>
                  {{ string.listUpload._clear }}
                </a>
              </div>
            </transition>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="formbutton">
        <a class="gray-btn save" :title="string.listUpload._load" @click="submit">
          {{ string.listUpload._load }}
        </a>
      </div>
    </div>
  </div>
</template>
<style>
#uploadInteractionContainer a {
  font-size: 16px;
  margin: 0 0 10px;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.4);
  width: 100%;
  display: block;
  cursor: pointer;
  text-align: center;
}

#uploadInteractionContainer a.simple {
  cursor: pointer;
  color: #fff !important;
  font-size: 12px !important;
  text-decoration: none;
  background-color: transparent !important;
}
#uploadInteractionContainer a.simple:hover {
  text-decoration: underline;
  background-color: transparent !important;
}

#uploadInteractionContainer a.clear {
  font-size: 16px;
  margin: 0 0 10px;
  padding: 8px;
  color: #333;
  background-color: transparent !important;
  cursor: pointer;
  width: 100%;
}

#uploadInteractionContainer a:hover {
  background-color: #dadada !important;
  color: #333333 !important;
}

.formbutton {
  position: relative;
}

.formbutton a {
  cursor: pointer !important;
}

#uploadInteractionContainer {
  padding: 60px 20px;
  border: solid 1px #333;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.4);
}

.list-upload .formbutton > a {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.list-upload label {
  color: #333;
  font-weight: bold;
  padding-left: 0 !important;
  text-transform: capitalize;
}

.list-upload h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import { UPLOAD_GUEST_LIST } from "@/graphql";
import * as IcalExpander from "ical-expander";
import { slugify } from "@/utils";
import { CFPrefix } from '@/types/enums';

export default {
  components: {
    LoadingCover
  },
  props: {
    eventType: String,
    eventId: String,
    fatherName: {
      type: String,
      default: ""
    },
    startDate: {
      type: String,
      default: ""
    },
    endDate: {
      type: String,
      default: ""
    },
    customFieldSource:{
      type: String,
      required: false,
      default: "GUEST"
    }
  },
  data() {
    return {
      clearButtonHide: true,
      fileToSelect: true,
      uploadURL: "",
      csvTemplateURL: "",
      xlsTemplateURL: "",
      string: {
        /*
                                    listUpload: {
                                        _title : "Carica una lista di partecipanti al tuo evento",
                                        _clear : "cancella",
                                        _load : "carica",
                                        _browse : "sfoglia",
                                        _loadingMessage : "Attendere...",
                                        _unManagedError : "Errore non gestito",
                                        _loadingComplete : "caricamento completato",
                                        _loadingError : "caricamento fallito",
                                        _itemImported : "importati",
                                        _mandatoryFields : "Nessun file selezionato",
                                        _unManagedError : "Errore non gestito",
                                    }
                                    */
      },
      selectedFile: "",
      selectedFileName: "",
      loadingEffectshow: false,
      fileInputName: this.fatherName + "fileinput"
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.xlsTemplateURL = "/download/guest_template.xlsx";
  },
  methods: {
    selectedFileValid: function() {
      return document.getElementById(this.fileInputName).value !== "";
    },
    warnErrorForIncompleteUpload: function(numberOfItemDiscarded, downloadToken) {
      const context = this;
      // Emit error
      this.$root.bus.emit("modalWarningView", "warning", [
        numberOfItemDiscarded,
        downloadToken
      ]); /** Emit di al gestore globale */
      this.$root.bus.emit(
        "simpleToast",
        context.string.listUpload._incompleteListUploadWarningTitle
      ); /** Emit di al gestore globale */
      this.loadingEffectshow = false;
    },
    warnErrorForMandatoryFields: function() {
      const context = this;
      this.$root.bus.emit(
        "simpleToast",
        context.string.listUpload._mandatoryFields
      ); /** Emit di al gestore globale */
      this.loadingEffectshow = false;
    },

    warnUnManagedError: function() {
      this.$root.bus.emit(
        "simpleToast",
        this.string.listUpload._unManagedError
      ); /** Emit di al gestore globale */
      this.loadingEffectshow = false;
    },
    async submit() {
      const MAX_ITERATION_PER_SLOT = 15;
      const MAX_SLOT = 10;
      const context = this;
      context.loadingEffectshow = true;

      if (this.selectedFileValid() === false) {
        context.warnErrorForMandatoryFields();
        return false;
      }
      const blob = new Blob([this.selectedFile]);
      let ics = "";
      if (typeof blob.text === "function") {
        ics = await blob.text();
      } else {
        ics = await this.readFile(this.selectedFile);
      }

      const icalExpander = new IcalExpander({ ics, maxIterations: MAX_ITERATION_PER_SLOT });
      const events = icalExpander.between();
      // const events = icalExpander.between(new Date(this.startDate), new Date(this.endDate));

      const mappedEvents = events.events.map(e => ({
        limit: 0,
        maxLimit: 1,
        startDate: e.startDate.toJSDate().toISOString(),
        endDate: e.endDate.toJSDate().toISOString(),
        title: e.summary
      }));
      const mappedOccurrences = events.occurrences.map(o => ({
        limit: 0,
        maxLimit: 1,
        startDate: o.startDate.toJSDate().toISOString(),
        endDate: o.endDate.toJSDate().toISOString(),
        title: o.item.summary
      }));
      const allEvents = [].concat(mappedEvents, mappedOccurrences);
      allEvents.sort((a, b) => (a.startDate >= b.startDate ? 1 : -1));
      const groupedEvents = {};
      for (const item of allEvents) {
        const newSlug = CFPrefix[this.customFieldSource]  + slugify(item.title);
        if (!groupedEvents[newSlug]) {
          groupedEvents[newSlug] = { title: item.title, timeSlots: [item] };
        } else {
          groupedEvents[newSlug].timeSlots = groupedEvents[newSlug].timeSlots
            ? [...groupedEvents[newSlug].timeSlots, item]
            : [item];
        }
      }
      context.loadingEffectshow = false;
      for (const [index, key] of Object.keys(groupedEvents).entries()) {
        // delete all slots exceeding slot limits
        if (index + 1 > MAX_SLOT) {
          delete groupedEvents[key];
        } else {
          // sort them
          const tempSlots = groupedEvents[key].timeSlots;
          tempSlots.sort((a, b) => (a.startDate >= b.startDate ? 1 : -1));
          groupedEvents[key].timeSlots = tempSlots;
        }
      }
      this.$emit("onChange", groupedEvents);
    },
    getSuccessStringResult: function(resultObject) {
      const context = this;
      return context.string.listUpload._loadingComplete.concat(
        ", ",
        context.string.listUpload._itemImported,
        " : ",
        context.apiAnswer.itemImported
      );
    },
    onFileChange: function(e) {
      this.fileToSelect = false;
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }

      this.selectedFile = files[0];
      this.selectedFileName = files[0].name;
    },
    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
          return resolve(e.target.result);
        };
        reader.onerror = e => {
          console.error("[ICSUpload]", "[readFile]", e.target.result);
          return reject(e.target.result);
        };
        reader.readAsText(file, "utf-8");
      });
    },
    clear: function() {
      const context = this;
      context.fileToSelect = true;
      document.getElementById(context.fileInputName).value = "";
    },
    open: function() {
      this.$refs.fileinput.click();
    },
    emptyFields: function() {
      const context = this;
      context.clear();
    }
  }
};
</script>
