<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Impostazioni
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/it-impostazioni">
              Impostazioni
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come gestire la privacy policy
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come impostare un limite massimo di conferme
            </a>
          </li>
          <!--li><a href="#article-3" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come usare un “endpoint di conferma esterno”</a></li -->
          <!--li><a href="#article-4" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come personalizzare i dati aziendali riportati in fondo alle email della campagna</a></li-->
          <!--<li><a href="#article-5" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come personalizzare ogni invito con nome e cognome dell’invitato</a></li> -->
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come collegare una mail o un dominio per l'invio delle mail
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come visualizzare le persone che si sono disiscritte dalla mia mailing list
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come aggiungere collaboratori al mio team
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come gestire la privacy policy
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Da Dashboard, nella sezione Impostazioni, è possibile creare una o più privacy policy che
            verranno visualizzate dall’invitato prima di poter effettuare RSVP. <br>
            Una privacy policy può essere attiva o disattiva, obbligatoria o non obbligatoria e “limitata a
            questo evento”; qui sotto la spiegazione del funzionamento. <br>
            - Se la privacy policy è obbligatoria, l’invitato deve per forza flaggarla per fare RSVP. <br>
            - Se la privacy policy è obbligatoria ma l’invitato l’ha già flaggata, l’invitato non vedrà, negli eventi
            successivi, la privacy policy. <br>
            - Se la privacy policy non è obbligatoria, l’invitato può decidere se flaggarla o no; se non la flagga,
            al prossimo evento non la rivedrà. <br>
            - Se la privacy policy non obbligatoria diventa obbligatoria, l’invitato non visualizzerà la privacy
            policy se questa è già stata flaggata; se l’invitato non l’ha flaggata, la vedrà e dovrà flaggarla
            obbligatoriamente per effettuare RSVP. <br>
            - Se la privacy policy è “limitata a questo evento” verrà visualizzata sia da chi l’ha flaggata, sia da
            chi non l’ha mai flaggata; la privacy policy verrà riproposta all’invitato anche se questa è stata, in
            eventi passati, obbligatoria. <br>
            - Se la privacy policy è “limitata a questo evento”, rimarrà in memoria solo nell’evento in cui è
            stata creata. <br><br>
            Il tool relativo alle privacy policy che state utilizzando è meramente uno strumento messo a
            vostra disposizione e dei team legal per facilitare la gestione. Penguinpass srl non si assume
            alcuna responsabilità per un utilizzo improprio e/o scorretto del tool.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come impostare un limite massimo di conferme
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nella sezione “impostazioni” è possibile porre un limite massimo al numero di individui che possono
            registrarsi/acquistare un biglietto per un evento pubblico attraverso l’apposita voce dove inserire
            il numero massimo. <br>
            Arrivato il numero di registrazioni all’evento a quello massimo impostato, inclusi i +1, il sistema impedirà
            ad ulteriori utenti di ottenere un ingresso.
          </p>
        </article>
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come usare un “endpoint di conferma esterno”<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Se l’evento è di tipo “content”, e si inserisce un link in questa sezione, i visitatori nella “guest
APP” non troveranno l’opzione “join” selezionando l’evento ma direttamente si aprirà il link
inserito nel campo “endpoint di conferma esterno”.</p>
        </article -->
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come personalizzare i dati aziendali riportati in fondo alle email della campagna<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Quando gli invitati ricevono l’email di invito, si trovano a chiusura di essa dopo la scritta
obbligatoria <br>
“Ricevi questa mail perché sei iscritto/a alla mailing list di NOME COGNOME.
Se non vuoi più ricevere sue comunicazioni clicca su unsubscribe from this list | disiscriviti da
questa lista.” <br>
Un campo riportante le informazioni immesse nella fase di iscrizione, “(NOME COGNOME, P.I
NAN, USERNAME)” <br>
qualora si voglia modificare o far riportare le info dell’azienda invece di un individuo si può
semplicemente andare a modificare da dashboard selezionando nella pagina (in alto nell’angolo
a destra) l’icona dell’user. Aprendo poi il proprio account si può andare a modificare i vari campi
che sono direttamente collegati a quelli riportati sulle email, alterando cosi ciò che verrà
riportato nelle email.</p>
        </article -->
        <!--
        <article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come personalizzare ogni invito con nome e cognome dell’invitato<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores
                alias consequatur aut perferendis doloribus asperiores repellat.</p>
        </article>
-->
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come collegare una mail o un dominio per l'invio delle mail
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Aprendo il menu laterale del dashboard è possibile accedere dai settings del proprio profilo cliccando
            sulla
            prima icona dell'elenco accanto alla quale troverete il nome con cui vi siete registrati al sistema. Così
            facendo accederete alla pagina delle impostazioni generali dell account, cliccando sul bottone "Dominio"
            potrete
            selezionare un metodo di identità tra "Dominio" e "Email". <br>
            Scegliendo di verificare la mail, potrete mandare mail solo dall'indirizzo verificato. Dovrete inserire la
            vostra mail nell'apposito spazio e cliccare sul bottone "Verifica mail", riceverete una mail all'indirizzo
            inserito con un link di verifica da cliccare. Verrete reindirizzati a una pagina web Penguinpass con la
            scritta
            success, a questo punto vi basterà refreshare la tabella che troverete nella sezione "Dominio" delle
            impostazioni e vedrete che l'icona accanto alla mail inserita passerà da arancione a verde. Andando su un
            qualsiasi evento vedrete che nella sezione Mail Marketing potrete selezionare l'indirizzo associato che i
            vostri
            ospiti vedranno come mittente dell'invito.<br>
            Scegliendo di verificare il dominio potrete inviare mail da tutti gli indirizzi associati a quel dominio
            (ex.
            marketing@dominio.com, info@dominio.com). Per procedere con l'associazione dovrete inserire il dominio
            nell'apposito spazio (ex. dominio.com), cliccando sul bottone "Verifica Dominio" si genererà automaticamente
            una
            tabella contenente quattro valori:<br>
            - Il valore TXT è per l'invio dell'autorizzazione per l'invio di e-mail per conto del tuo dominio.<br>
            - I tre valori CNAME sono per le impostazioni DKIM del tuo dominio.<br>
            Con queste due configurazioni, sarai in grado di inviare e-mail utilizzando Penguinpass Email Server senza
            perdere la capacità di inviare e-mail con il tuo server di posta originale.<br>
            I valori della tabella dovranno essere aggiunti e non sostituiti nelle configurazioni DNS del vostro
            dominio.
            Tutte le risposte alle e-mail inviate torneranno comunque al tuo server e-mail originale. Per ridurre la
            percentuale di spam, anche i valori CNAME sono contrassegnati come obbligatori.
            Dopo aver verificato il tuo dominio, sarai in grado di utilizzare qualsiasi nome utente nel tuo dominio come
            mittente e-mail come `any_username@yourdomain.com`.
            La tua richiesta di verifica scadrà dopo 48 ore. In tali casi, è necessario inviare una nuova richiesta di
            verifica. Il dominio sarà associato con successo e pronto all'utilizzo quando refreshando la tabella con i
            valori, accanto a ognuno di essi l'icona sarà diventata verde.<br>
            Analogamente alle mail, potrete selezionare il dominio associato dalla sezione marketing, la parte dopo la
            chiocciola (@) rimarrà bloccata ma potrete inserire la parte prima della chiocciola che vorrete (ex. info,
            marketing, sales ecc.).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come visualizzare le persone che si sono disiscritte dalla mia mailing list
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Aprendo il menu laterale del dashboard è possibile accedere dai settings del proprio profilo cliccando sulla
            prima icona dell'elenco accanto alla quale troverete il nome con cui vi siete registrati al sistema. Così
            facendo accederete alla pagina delle impostazioni generali dell account, cliccando sul bottone
            "Unsubscribers" visualizzerete una tabella con i contatti di tutti i vostri ospiti che dal footer delle
            vostre mail avranno cliccato sul link "disiscriviti dalla mailing list di..". Potrete esportare la lista in
            formato excel cliccando sul bottone "Scarica tutto l'elenco" che troverete sopra la tabella.<br>
            Nel momento in cui un ospite diventa "unsubscribed" lo potrete caricare nella guestlist del vostro evento ma
            il sistema lo escluderà automaticamente dalla lista degli ospiti a cui voi potrete inviare comunicazioni
            dalla sezione Mail marketing.<br>
            Gli ospiti potranno reiscriversi nello stesso modo in cui si sono iscritti, ovvero cliccando sul link nel
            footer di una comunicazione mail già ricevuta.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come aggiungere collaboratori al mio team
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Aprendo il menu laterale del dashboard è possibile accedere alla sezione chiamata "Il mio team" dove
            potrete aggiungere nuovi membri e visualizzare/gestire quelli già aggiunti.<br>
            Per aggiungere un nuovo membro al vostro team vi basterà inserire la sua mail nell'apposito spazio,
            selezionare un ruolo e cliccare sul bottone "Invia l'invito". <br>
            I ruoli sono i seguenti: <br>
            owner: ha pieni poteri ed è il proprietario dell'account.<br>
            staff manager: può creare nuovi eventi e visualizzare/modificare tutti gli eventi presenti sull'account.<br>
            staff: potrà modificare solo gli eventi che gli vengono assegnati da owner e staff manager.<br>
            L'invitato riceverà una mail per accettare l'invito al team, dovrà compilare nome, cognome e password,
            infine accettando le privacy policies e termini e condizioni potrà cliccare sul bottone di conferma. Una
            volta confermato l'invito il nuovo membro potrà accedere al dashboard con le proprie credenziali. <br>
            Dalla tabella, gli owners potranno disattivare e riattivare i propri team members. Sia staff manager che
            owners potranno invece selezionare i membri di tipo "staff", e cliccando sul bottone "Azione in blocco",
            potranno assegnare/togliere loro eventi che potranno visualizzare dal proprio dashboard.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskImpostazioniIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
