var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "empty-view-container" }, [
    _vm.$slots.emptyView
      ? _c("div", [_vm._t("emptyView")], 2)
      : _c("p", { staticClass: "empty-view-text" }, [
          _vm._v("\n    " + _vm._s(_vm.text) + "\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }