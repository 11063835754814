var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "description-component" },
    [
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.loadingEffectshow
            ? _c("div", { attrs: { id: "loadingCover" } }, [
                _c("div", { staticClass: "loadingCoverMessage" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.string.description._loadingMessage) +
                      "\n      "
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "description-component-section-container",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _vm.showHiddenForm
            ? _c("div", { staticClass: "hiddenForm" }, [
                _c(
                  "div",
                  {},
                  [
                    _c("section-title", {
                      attrs: { title: _vm.string.description._description },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row form-control-description" }, [
                      _c("div", { staticClass: "col-sm-12 col-md-12" }, [
                        this.event.descriptionIt != "" &&
                        this.eventDescription == ""
                          ? _c("div", [_vm._v(_vm._s(_vm.event.descriptionIt))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model:value",
                              value: _vm.eventDescription,
                              expression: "eventDescription",
                              arg: "value",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            rows: "5",
                            placeholder:
                              _vm.string.description
                                ._descriptionFieldPlaceHolder,
                          },
                          domProps: { value: _vm.eventDescription },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.eventDescription = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "row form-control-event-pad form-control-event-pad-button",
                      },
                      [
                        _c("div", { staticClass: "col-sm-6 col-md-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "butn delete",
                              attrs: { title: "" },
                              on: { click: _vm.cancel },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.string.description._clear) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6 col-md-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "butn save",
                              attrs: { title: "" },
                              on: { click: _vm.submit },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.string.description._save) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "description-component-section-container" }, [
        _vm.showHiddenForm
          ? _c("div", { staticClass: "hiddenForm" }, [
              _c(
                "div",
                {},
                [
                  _c("section-title", {
                    attrs: {
                      title: _vm.string.description._alternativeLanguageVersion,
                      subtitle:
                        _vm.string.description._alternativeLanguageVersionSmall,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "row form-control-description" }, [
                    _c("div", { staticClass: "col-sm-12 col-md-12" }, [
                      this.event.descriptionEn != "" &&
                      this.eventDescription == ""
                        ? _c("div", [_vm._v(_vm._s(_vm.event.descriptionIt))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model:value",
                            value: _vm.eventDescription_En,
                            expression: "eventDescription_En",
                            arg: "value",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          rows: "5",
                          placeholder:
                            _vm.string.description._descriptionFieldPlaceHolder,
                        },
                        domProps: { value: _vm.eventDescription_En },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.eventDescription_En = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row form-control-event-pad form-control-event-pad-button",
                    },
                    [
                      _c("div", { staticClass: "col-sm-6 col-md-6" }, [
                        _c(
                          "a",
                          {
                            staticClass: "butn delete",
                            attrs: { title: "" },
                            on: { click: _vm.cancel },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.string.description._clear) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6 col-md-6" }, [
                        _c(
                          "a",
                          {
                            staticClass: "butn save",
                            attrs: { title: "" },
                            on: { click: _vm.submit },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.string.description._save) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      !_vm.showHiddenForm
        ? _c(
            "div",
            { staticClass: "testo" },
            [
              _c("section-title", {
                attrs: { title: _vm.string.description._description },
              }),
              _vm._v("\n    " + _vm._s(_vm.eventDescription) + "\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showHiddenForm
        ? _c(
            "div",
            { staticClass: "testo_en" },
            [
              _c("section-title", {
                attrs: {
                  title: _vm.string.description._alternativeLanguageVersion,
                },
              }),
              _vm._v("\n    " + _vm._s(_vm.eventDescription_En) + "\n  "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.showHiddenForm
        ? _c(
            "div",
            {
              staticClass: "updateButtonContainer",
              on: {
                mouseover: _vm.mouseOver,
                mouseout: _vm.mouseOut,
                mousedown: _vm.mouseDown,
              },
            },
            [
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _vm.showUpdateButton
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-labeled btn-info",
                          attrs: { type: "button" },
                        },
                        [
                          _c("span", { staticClass: "btn-label" }, [
                            _c("i", {
                              staticClass: "fa fa-pencil",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                          _vm._v("\n        Update\n      "),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }