<template>
  <label class="custom-cb-control custom-cb-control-checkbox">
    <input :id="customId" type="checkbox" :checked="shouldBeChecked" :value="value" @change="updateInput" />
    <div class="custom-cb-control-indicator"></div>
    <label :for="customId" />
  </label>
</template>
<script>
/**
 * @see https://www.smashingmagazine.com/2017/08/creating-custom-inputs-vue-js/
 */
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: String,
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      default: false,
    },
      // eslint-disable-next-line vue/require-prop-types
    trueValue: {
      default: true,
    },
      // eslint-disable-next-line vue/require-prop-types
    falseValue: {
      default: false,
    },
  },
  data() {
    return {
      customId: "checkbox-" + Math.floor(Math.random() * 100000).toString(),
    };
  },
computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    }
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    }
  },
};
</script>

<style scoped>
.custom-cb-control {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.custom-cb-control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-cb-control-indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #616161;
  border: 0px solid #000000;
}
.custom-cb-control-radio .custom-cb-control-indicator {
  border-radius: 0;
}

.custom-cb-control:hover input ~ .custom-cb-control-indicator,
.custom-cb-control input:focus ~ .custom-cb-control-indicator {
  background: #cccccc;
}

.custom-cb-control input:checked ~ .custom-cb-control-indicator {
  background: #000;
  border: 1px solid #616161;
}
.custom-cb-control:hover input:not([disabled]):checked ~ .custom-cb-control-indicator,
.custom-cb-control input:checked:focus ~ .custom-cb-control-indicator {
  background: #000;
}
.custom-cb-control input:disabled ~ .custom-cb-control-indicator {
  background: #616161;
  opacity: 0.6;
  pointer-events: none;
}
.custom-cb-control-indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.custom-cb-control input:checked ~ .custom-cb-control-indicator:after {
  display: block;
}
.custom-cb-control-checkbox .custom-cb-control-indicator:after {
  left: 7px;
  top: 3px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-cb-control-checkbox input:disabled ~ .custom-cb-control-indicator:after {
  border-color: #7b7b7b;
}
</style>