var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "public-footer bg-dark text-white" }, [
    _c("div", { class: _vm.fullWidth ? "" : "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _c("div", { staticClass: "copyright small" }, [
            _c("p", [
              _vm._v(
                "\n            © " +
                  _vm._s(new Date().getFullYear()) +
                  " All rights reserved. Penguinpass Srl P.IVA\n            08866100962 - Milan, Italy\n          "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.strings.termsAndConditions._privacypolicyURL,
                    target: "_blank",
                  },
                },
                [_vm._v("\n              privacy policy\n            ")]
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "a",
                {
                  attrs: {
                    href: _vm.strings.termsAndConditions._linkTerms,
                    target: "_blank",
                  },
                },
                [_vm._v("\n              termini e condizioni\n            ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }