var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.joinStatus === 0
    ? _c(
        "div",
        { staticClass: "sample-text-area", attrs: { id: "display-result" } },
        [
          _c("i", {
            staticClass: "icon fa fa-check-circle fa-3x",
            staticStyle: { "font-weight": "900" },
            attrs: { "aria-hidden": "true" },
          }),
        ]
      )
    : _vm.joinStatus !== 0 && _vm.joinStatus !== 1
    ? _c("div", { staticClass: "sample-text-area" }, [
        _c("i", {
          staticClass: "icon fa fa-times-circle fa-3x",
          staticStyle: { "font-weight": "900" },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }