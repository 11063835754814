var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout fixflex" }, [
    _c(
      "div",
      { staticClass: "md-modal md-effect-2 md-show", attrs: { id: "modal-2" } },
      [
        _c("button", { staticClass: "md-close", on: { click: _vm.fadeout } }, [
          _c("i", {
            staticClass: "fa fa-times",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "md-content" }, [
          _c("div", { staticClass: "grid" }, [
            _c(
              "div",
              {
                staticClass: "event_type_select event_type_public",
                on: { click: _vm.triggerPublicEvent },
              },
              [
                _c("figure", { staticClass: "effect-kira" }, [
                  _c("figcaption", [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.strings.createSelectEvent._public)),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.publicboxtxt))]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "event_type_select event_type_private",
                on: { click: _vm.triggerPrivateEvent },
              },
              [
                _c("figure", { staticClass: "effect-kira" }, [
                  _c("figcaption", [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.strings.createSelectEvent._private)),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.strings.createSelectEvent._privateboxtxt)
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { attrs: { id: "createselectevent" }, on: { click: _vm.fadeout } },
      [_vm._v("\n           \n  ")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }