var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "section",
      { ref: "section-1", staticClass: "tm-margin-large-top tm-faq" },
      [
        _c("div", { staticClass: "uk-container" }, [
          _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
            _vm._v("\n        FAQ\n      "),
          ]),
          _vm._v(" "),
          _c("hr", { staticClass: "uk-margin-remove" }),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
            [
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-1")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            How to add collaborators to my team\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-2")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            What are dashboard, check-inApp and guestApp?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-3")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            What happens when I activate an event?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-4")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      '\n            What are the "confirm + 1" and "request name of + 1 / o notes" checkboxes for and how do they work?\n          '
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-5")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Where can I find the name and surname of the companions who entered my guest and how do I add them to the\n             list?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-6")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            How does the QR code work to access the event?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-7")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            Can I add custom details / attributes on my guests?\n          "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "hvr-forward",
                    attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("article-8")
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "uk-icon",
                        attrs: { "data-uk-icon": "icon: arrow-right" },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "20",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                              ratio: "1",
                            },
                          },
                          [
                            _c("polyline", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                points: "10 5 15 9.5 10 14",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              attrs: {
                                fill: "none",
                                stroke: "#000",
                                x1: "4",
                                y1: "9.5",
                                x2: "15",
                                y2: "9.5",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n            How to keep the risk of spam low\n          "
                    ),
                  ]
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-1",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Which type of subscription is best for me?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-2",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          What are dashboard, check-in APP and guestApp?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(1),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-3",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          What happens when I activate an event?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(2),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-4",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  '\n          What are the "confirm + 1" and "request name of + 1 / o notes" checkboxes for and how do they work?\n          '
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Under the section in which the email to be sent in the campaign is generated, there are two\n           check-box whose purpose, if activated, is to allow the guest (once the\n           RSVP) to enter the number and / or names of his companions.\n        "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-5",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Where can I find the name and surname of the companions who entered my guest and how do I add them to the\n           list?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                '\n          When the guest confirms their participation, they will have (if from the dashboard\n           the organizer allows it) the possibility of entering the "name, surname and email" of his / her\n           any accompanying persons. These names and data can be found simply by downloading\n           the excel sheet via "download the whole list" in the "guest list" section in the form of a note.\n           By taking these names and rearranging them in a new excel sheet on the appropriate format\n           "Template for xls import" and reloading it. By doing so, you can also send emails\n           to the companions of your guests if necessary, or simply they can\n           view via Check-inApp.\n        '
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-6",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          How does the QR code work to access the event?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(3),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-7",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          Can I add custom details / attributes on my guests?\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(4),
          ]),
          _vm._v(" "),
          _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
            _c(
              "h2",
              {
                ref: "article-8",
                staticClass:
                  "uk-article-title uk-margin-medium-bottom title-and-icon",
              },
              [
                _vm._v(
                  "\n          How to keep the risk of spam low\n          "
                ),
                _c(
                  "a",
                  {
                    staticClass:
                      "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                    attrs: { "data-uk-totop": "" },
                    on: {
                      click: function ($event) {
                        return _vm.scrollMeTo("section-1")
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "18",
                          height: "20",
                          viewBox: "0 0 18 20",
                          xmlns: "http://www.w3.org/2000/svg",
                          ratio: "1",
                        },
                      },
                      [
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            stroke: "#000",
                            "stroke-width": "1.2",
                            points: "1 9 9 1 17 9 ",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _vm._m(5),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          To choose the subscription that best suits your needs, we recommend that you contact us at\n          "
      ),
      _c("u", [
        _c("a", { attrs: { href: "info@penguinpass.it" } }, [
          _vm._v("\n              info@penguinpass.it\n            "),
        ]),
      ]),
      _vm._v(
        " so that we can identify together the best offer that best meets your needs.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Dashboard, guest and app check-in are the three tools on which the Penguinpass system is based;\n           The first is used by organizers via web access to create and manage events\n           thus having a complete and organized vision in a single instrument. "
      ),
      _c("br"),
      _vm._v(
        "\n           The two mobile apps are instead aimed at users of the service: the Check-inApp\n           allows hostesses to manage on-site access, which through this dynamic support\n           they can check and update guest lists in real time. While the GuestApp is\n           designed and developed for guests who, thanks to the app, can always stay informed and\n           updated on events and access locations easily and quickly.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          When an event is activated, the organizer will be able to use the mail marketing function\n           for\n           send invitations and communications to their guests.\n          "
      ),
      _c("br"),
      _vm._v(
        " Furthermore, once the event is activated it will be available on the GuestApps and\n           therefore, visible only to guests, in the case of a private event, to anyone if it is a public event.\n          "
      ),
      _c("br"),
      _vm._v(
        "\n          It is important to note that it is not possible to use the features of the Check-inApp if\n           the event was not activated.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Often there is a need or desire to manage the income to events via QR Code, this\n           it means that the guests who send their RSVPs will receive an email of theirs\n           confirmation containing additional details on the event and the personal QR Code to access.\n           According to this operating logic, it is therefore necessary to set sending from the dashboard\n           of a confirmation email, and insert the dynamic content (QR Code) in it."
      ),
      _c("br"),
      _vm._v("\n          center a QR code in the e-mail"),
      _c("br"),
      _vm._v(
        '\n          When inserting "dynamic content" into the empty "form" in Emailbuilder, if you are using\n           a "single module" (the first in the modules section) the QR Code will be displayed on the left side\n           of the email, if you want it to be displayed in the center it will be necessary\n           simply use not the "single module" but the "module divided into three sections" (the seventh\n           of the module list) and insert the dynamic content in the central one.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          To add a specific and custom attribute to the guest list, just edit\n           in the "template for xls import" (downloaded from the site as explained above) the "custom\n           note *** "by placing the name of the desired attribute in its place (eg. from" custom note *** "to" country\n           of origin "or" company ").'
      ),
      _c("br"),
      _vm._v(
        '\n           If you need more column-attributes, you can add more later to the “custom\n           Note***"'
      ),
      _c("br"),
      _vm._v(
        '\n          In case you forget to add the extra fields when loading guests, it is possible\n           add new columns by going to the event settings section, expanding the section "Edit custom\n           fields "it will be possible to add new attributes. Once added, simply select the Check-in item\n           app\n           to make the field visible in the check-in app.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Here are some tips for decreasing the risk of your email campaigns coming\n           classified as spam by mail servers. But first let's start with the ranking, which is one of the parameters of\n           various mail programs and servers to manage messages. Sending with Penguinpass the score is 89\n           out of 100 which is very high"
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          1. Mail programs if they read words like events, save the date, rsvp etc can classify in\n           automatic mails as "promotions" and insert them in the relative folder on gmail. So we recommend\n           to avoid such words in the subject of your campaign.'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          2. We recommend that the email address (the sender) does not have words such as events, marketing\n           etc. for example events@cliente.com. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          3. If you have the possibility, we recommend that you verify your domain instead of the single address\n           e-mail."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          4. Try to ensure that the emails sent do not have just an image (the invitation) and only the\n           "Kind name + surname". It is very important to load text and images in a balanced way (\n           emails also use this algorithm to classify emails, the relationship between text and images).\n           Basically you have to write some text, and then the images. The greater the proportion of text, the less the\n           risk of your campaign being classified as "spam" or "promotion".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          5. Image weight: this is also an important factor. The less weight the images are, the faster they are seen and\n           they are less "suspicious" to mail programs that protect against possible viruses or malware they need\n           a minimum weight. The ideal would be to attach images / invitations between 50kb and 150kb.'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          6. It is advisable to write in lowercase, or at least not all in capital letters. Writing in\n           block letters in the subject affect + 10% of the danger of being classified as "spam".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          7.One last tip is to send at least the first time you do a campaign, to the addresses you don't\n           you see the email open or who have not done rsvp (on Penguinpass there are special filters), an email to\n           part (not with Penguinpass or with other systems such as mailchimp etc), but with a normal mail client\n           pasting the addresses and which, in addition to being a reminder for the event, also invites them to check the\n           spam or in the other folders any mail received from you. Once the user, at your request, has\n           when the e-mail is moved to those received, the mail system understands that the next time from that address\n           the email must be displayed.\n         "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }