<template>
  <iframe v-if="fileType === 'html'" :src="source" class="cover-page"></iframe>
  <embed v-else-if="fileType === 'pdf'" :src="source" type="application/pdf"
  class="cover-page">

  <PublicLayout v-else>
    <div> This file type is not supported yet</div>
    </PublicLayout>
</template>

<script >
  import PublicLayout from '../layouts/PublicLayout';

  export default {
      components: {
          PublicLayout
      },
      props: {
          code: {
            type: String,
            default: "-"
          },
          fileType: {
            type: String,
            default: "html"
          },
        },
    data (){
      return {
        string: {
        },
        htmlData: ""
      };

    },
    metaInfo: {
      title: 'Email Preview - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    computed:{
      source: function() {
        const isDev = process.env.NODE_ENV === "development";
        const baseUrl = process.env.BASE_URL || "http://localhost:5000";
        const googleDrivePrefix = this.fileType === "pdf" && !isDev ? "https://docs.google.com/viewer?embedded=true&url=" : "";
        return googleDrivePrefix + baseUrl + `/guest/show-email/${this.code}/${this.fileType}`;
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      }, this);

    },
    methods: {

    }
  };

</script>

<style >
.cover-page {
  height: 100vh;
  width: 100%;
}
</style>
