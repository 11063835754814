var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "font-awesome-layers",
    { staticClass: "fa-lg", staticStyle: { "margin-left": "10px" } },
    [
      _c("font-awesome-icon", {
        style: { color: "#ff9c00" },
        attrs: { icon: ["fas", "circle"], transform: "grow-6" },
      }),
      _vm._v(" "),
      _c("font-awesome-icon", {
        style: { color: "white" },
        attrs: { icon: "sync", transform: "shrink-4", spin: "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }