var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row functionbar" }, [
      !_vm.isExhibitor
        ? _c("div", { staticClass: "col-md-3 col-sm-3" }, [
            _c("a", [
              _c(
                "div",
                {
                  staticClass: "box1 tooltips",
                  attrs: {
                    "data-placement": "top",
                    "data-original-title": _vm.strings.functionBar._new,
                  },
                  on: { click: _vm.goNewEvent },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus fa-4x",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("h3", [_vm._v(_vm._s(_vm.strings.functionBar._new))]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3 col-sm-3" }, [
        _c("a", [
          _c(
            "div",
            {
              staticClass: "box1 tooltips",
              attrs: {
                "data-placement": "top",
                "data-original-title": _vm.strings.functionBar._events,
              },
              on: { click: _vm.goEvents },
            },
            [
              _c("i", {
                staticClass: "fa fa-calendar fa-4x",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.strings.functionBar._events))]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3 col-sm-3" }, [
        _c("a", [
          _c(
            "div",
            {
              staticClass: "box1 tooltips",
              attrs: {
                "data-placement": "top",
                "data-original-title": _vm.strings.functionBar._archive,
              },
              on: { click: _vm.goArchive },
            },
            [
              _c("i", {
                staticClass: "fa fa-archive fa-4x",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.strings.functionBar._archive))]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3 col-sm-3" }, [
        _c("a", [
          _c(
            "div",
            {
              staticClass: "box1 tooltips",
              attrs: {
                "data-placement": "top",
                "data-original-title": _vm.strings.functionBar._search,
              },
              on: { click: _vm.goSearch },
            },
            [
              _c("i", {
                staticClass: "fa fa-search fa-4x",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("h3", [_vm._v(_vm._s(_vm.strings.functionBar._search))]),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }