var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isExhibitor
    ? _c(
        "div",
        { staticClass: "advancesettings-component accordion" },
        [
          _c("CategoryManager", {
            attrs: { event: _vm.event, "event-id": _vm.event.id },
          }),
          _vm._v(" "),
          _c("PrivacyPolicy", { attrs: { event: _vm.event } }),
          _vm._v(" "),
          _c("AttendeeMaxLimit", {
            attrs: { event: _vm.event, "event-id": _vm.event.id },
          }),
          _vm._v(" "),
          _c("CustomFieldsManager", {
            attrs: { event: _vm.event, "event-id": _vm.event.id },
            on: { customFieldsChanged: _vm.onCustomFieldsChanged },
          }),
          _vm._v(" "),
          _c("RSVPImageHeader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isPublicEvent,
                expression: "!isPublicEvent",
              },
            ],
            attrs: { event: _vm.event, "event-id": _vm.event.id },
          }),
          _vm._v(" "),
          _c("ShowOnApp", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isPublicEvent,
                expression: "isPublicEvent",
              },
            ],
            attrs: { event: _vm.event },
          }),
          _vm._v(" "),
          _c("C19Compliance", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.features.c19Compliance,
                expression: "features.c19Compliance",
              },
            ],
            attrs: { event: _vm.event },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }