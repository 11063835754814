<template>
    <PublicLayout>
          <div class="container">
              <div class="row wow fadeIn">
              <!-- Left column -->
              <div class="col-md-7">
                  <!-- Begin heading -->
                  <div class="heading heading-md heading-uppercase heading-hover">
                      <br>
                      <h2  class="heading-title text-white">
                          {{action && action === 'update' ? string.recoveryPassword._newPassword_title_update : string.recoveryPassword._newPassword_title}}
                      </h2>

                      <hr class="hr-width-1 hr-5x border-main">

                      <p id="description"
                          class="heading-tescription lead text-gray-2">
                          {{action && action === 'update' ? string.recoveryPassword. _newPassword_description_update : string.recoveryPassword. _newPassword_description }}
                      </p>
                  </div>
                  <!-- End heading -->
              </div> <!-- /.col -->

              <div class="row">
                  <!-- Begin contact form -->
                  <div
                          id="contact-form"
                          class="margin-top-80 text-white wow fadeIn"
                          data-wow-delay="0.3s">
                      <div class="row"
                            style="margin: 2px;">
                          <div class="col-lg-8">
                              <div class="form-group input-container">
                                  <input
                                          aria-label=""
                                          id="password1"
                                          v-model="check.password"
                                          type="password"
                                          class="form-control no-rounded"
                                          name="password"
                                          value=""
                                          placeholder="Password"
                                          required/>
                                        <i 
                                            class="fa password-toggle-icon" 
                                            :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" 
                                            @click="togglePasswordVisibility" 
                                            aria-hidden="true">
                                        </i>
                              </div>
                              <div class="form-group">
                                <div class="form-group input-container">
                                  <input
                                          aria-label=""
                                          id="password2"
                                          v-model="check.passwordConfirm"
                                          type="password"
                                          class="form-control no-rounded"
                                          name="password"
                                          value=""
                                          placeholder="Password Confirm"
                                          required />
                                        <i 
                                            class="fa password-toggle-icon" 
                                            :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" 
                                            @click="togglePasswordVisibility" 
                                            aria-hidden="true">
                                        </i>
                                </div>
                                <small>{{ string.commonActions.passwordPolicy }}</small>
                                </div>
                              <div class="form-group">
                                  <button
                                          v-on:click=" (e) => { e.preventDefault(); this.updatePassword();}"
                                          v-on:keyup.enter=" (e) => { e.preventDefault(); updatePassword();}"
                                          type="submit"
                                          class="btn btn-warning active btn-block btn-lg">
                                      {{ action && action === 'update' ? string.recoveryPassword._newPassword_summitButton_update : string.recoveryPassword._newPassword_summitButton}}
                                  </button>
                              </div>
                          </div>
                      </div>
                      <br />
                      <br />
                  </div>
                  <!-- End contact form -->
              </div> <!-- /.container -->
            </div> <!-- /.row -->

          </div>
    </PublicLayout>
</template>

<script >

    import PublicLayout  from '../layouts/PublicLayout';

    export default {
        components: {
            PublicLayout
        },
        data (){
            return {
                check:{
                    password:'',
                    passwordConfirm:'',
                },
                urlParameter:'',
                pageLoaded: false,
                string: {},
                passwordVisible: false,
            };

        },
        props: {
            t: String,
            type: String,
            tid: String,
        },
        metaInfo: {
            title: 'Password - Penguinpass',
            bodyAttrs: {
                class: 'login'
            }
        },
        created (){
            const context = this;
            this.string = this.$root.strings;
            this.$root.bus.addListener('setLanguage', () => {
                context.string = context.$root.strings;
            }, this);
        },
        mounted () {
        },
        computed: {
            action(){
                return this.$route.query.a || null;
            },
            token() {
                return this.$route.query.t || ''
            },
            userType() {
                return this.$route.query.type ? this.$route.query.type  : 'ORGANIZER';
            },
            teamId() {
                return this.$route.query.tid ? this.$route.query.tid  : '';
            }
        },
        methods: {
            updateOrganizerPassword () {
                const context = this;
                if (this.isDataValid()){
                    this.$root.axios
                    .post('/auth/organizer/reset-password', {
                        token: this.token,
                        password: this.check.password,
                        passwordVerify: this.check.passwordConfirm
                    })
                    .then(value => {
                        if(value.data.status === 'SUCCESS') {
                            context.$notify({
                                title: '',
                                message: 'Success!',
                                position: 'bottom-right',
                                type: 'success',
                                duration: 3000,
                            });
                            this.$router.push('/login');
                        } else {
                            if (value.data.status === "PASSWORD_NOT_MATCHING_POLICY") {
                                context.$notify({
                                    title: 'Oops !',
                                    message: context.string.commonActions.passwordPolicy,
                                    position: 'bottom-right',
                                    type: 'error',
                                    duration: 3000,
                                });
                            } else {
                                context.$notify({
                                    title: 'Oops !',
                                    message: value.data.message,
                                    position: 'bottom-right',
                                    type: 'error',
                                    duration: 3000,
                                });
                            }
                        }
                    })
                    .catch(reason => {
                        if (reason.data.status === "PASSWORD_NOT_MATCHING_POLICY") {
                            context.$notify({
                                title: 'Oops !',
                                message: context.string.commonActions.passwordPolicy,
                                position: 'bottom-right',
                                type: 'error',
                                duration: 3000,
                            });
                        } else {
                            context.$notify({
                                title: 'Oops !',
                                message: reason.data.message,
                                position: 'bottom-right',
                                type: 'error',
                                duration: 3000,
                            });
                        }
                    });
                }
            },
            updateGuestPassword () {
                const context = this;
                if (this.isDataValid()){
                    this.$root.axios
                    .post('/api/guest/password/reset', {
                        token: this.token,
                        password: this.check.password,
                        passwordVerify: this.check.passwordConfirm
                    }, { headers: { "x-team-id": this.teamId } })
                    .then(value => {
                        if (value.data.status === 'SUCCESS') {
                            context.$notify({
                                title: '',
                                message: 'Success!',
                                position: 'bottom-right',
                                type: 'success',
                                duration: 3000,
                            });
                            this.$router.push('/login');
                        } else {
                            if (value.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
                                context.$notify({
                                    title: 'Oops !',
                                    message: context.string.commonActions.passwordPolicy,
                                    position: 'bottom-right',
                                    type: 'error',
                                    duration: 3000,
                                });
                            } else {
                                context.$notify({
                                    title: 'Oops !',
                                    message: value.data.message,
                                    position: 'bottom-right',
                                    type: 'error',
                                    duration: 3000,
                                });
                            }
                        }
                    })
                    .catch(reason => {
                        if (reason.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
                            context.$notify({
                                title: 'Oops !',
                                message: context.string.commonActions.passwordPolicy,
                                position: 'bottom-right',
                                type: 'error',
                                duration: 3000,
                            });
                        } else {
                            context.$notify({
                                title: 'Oops !',
                                message: reason.data.message,
                                position: 'bottom-right',
                                type: 'error',
                                duration: 3000,
                            });
                        }
                    });
                }
            },
            updatePassword: function(){
                if (this.userType === "ORGANIZER") {
                    this.updateOrganizerPassword();
                } else  if (this.userType === "g"){
                    this.updateGuestPassword();
                }
            },
            isDataValid: function () {
                const context = this;
                if(this.check.password !== this.check.passwordConfirm) {
                    context.$notify({
                        title: 'Oops !',
                        message: this.string.recoveryPassword._newPassword_warning_message,
                        position: 'bottom-right',
                        type: 'warning',
                        duration: 3000,
                    });
                    return false;
                }else return true;
            },
            togglePasswordVisibility() {
                this.passwordVisible = !this.passwordVisible;
                const passwordField = document.getElementById('password1');
                const retypePasswordField = document.getElementById('password2');
                if (this.passwordVisible) {
                passwordField.type = 'text';
                retypePasswordField.type = 'text';
                } else {
                passwordField.type = 'password';
                retypePasswordField.type = 'password';  
                }
            },
        }
    };

</script>
<style >
  .input-container {
    position: relative;
  }

  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #FFFFFF; 
    opacity: 0.5; 
    transition: color 0.3s ease, opacity 0.3s ease;
  }

  .password-toggle-icon:hover {
    color: #FFFFFF;
    opacity: 1; 
  }
</style>
