<template>
  <div class="row insert-ticket-form">
    <h4>{{ string.insertTicketForm._title }}</h4>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <transition
        name="component-fade-fast"
        mode="out-in"
        appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.insertTicketForm._loadingMessage"></LoadingCover>
      </transition>
      <form
        class="form-horizontal style-form"
        method="get">
        <div
          v-if="app_settings.app_name === 'default'"
          class="form-group nomarginleft">
          <div class="col-sm-6">
            <select
              v-model="ticketType"
              name="ticketType"
              class="form-control"
              :aria-placeholder="string.insertTicketForm._type">
              <option value="pay">
                {{ string.insertTicketForm._pay }}
              </option>
              <option value="donation">
                {{ string.insertTicketForm._donation }}
              </option>
            </select>
          </div>

          <div class="col-sm-6">
            <input
              v-model="ticketName"
              type="text"
              class="form-control"
              :placeholder="string.insertTicketForm._name">
          </div>
        </div>

        <div class="form-group nomarginleft">
          <div class="col-sm-6">
            <input
              v-model="ticketAvailability"
              type="number"
              name="availability"
              min="0"
              max="999999998"
              :placeholder="string.insertTicketForm._nolimits"
              autocomplete="on"
              class="form-control">
          </div>

          <div class="col-sm-6">
            <input
              v-model="ticketPrice"
              type="number"
              name="price"
              step="0.50"
              min="0" 
              autocomplete="on" 
              class="form-control"
              :placeholder="string.insertTicketForm._price">
          </div>
        </div>
                
        <div class="formbutton">
          <a
            class="save"
            title=""
            @click="submit">
            {{ string.insertTicketForm._save }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>

    .formbutton{
        position: relative;
    }

    .formbutton > a{
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    .insert-ticket-form select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: transparent url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right center;
        border: 1px solid #333;
        background-position: 98%;
        border-radius: 0;
    }

    .insert-ticket-form{
        margin-bottom: 30px;
    }

    .insert-ticket-form .form-control {
        border-radius: 0;
        border-color: #333;
    }

    select{
        border-radius: 0 !important;
    }

    .insert-ticket-form label {
        color: #333;
        font-weight: bold;
        padding-left: 0 !important;
        text-transform: capitalize;
    }

    .insert-ticket-form h4 {
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
    }

</style>
<script>
import userdata from './../userdata.js';
import LoadingCover from './LoadingCover.vue';

export default {
        
    components:{

    },
    props: {
        eventId: String,
        fatherName: String
    },
    data (){
        return {
            componentName: 'insertTicketForm',
            string: {
                /*
                    insertTicketForm:{
                        _title : "",
                        _name : "descrizione",
                        _availability : "limite",
                        _save : "salva",
                        _price : "prezzo",
                        _type : "tipo",
                        _pay : "pagamento",
                        _donation : "donazione",
                        _nolimits : "nessun limite",
                        _mandatoryFields : "I campi contrassegnati con l'asterisco sono obbligatori",
                    },
                    */
            },
            loadingEffectshow : false,
            ticketType : 'pay',
            ticketPrice : null,
            ticketAvailability : null,
            ticketName : null,
            app_settings: userdata.app_settings
        };
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    methods:{
        submit: function (){
            const context = this;
                
            context.loadingEffectshow = false; // Loading Effect

            if (this.dataValid() === false){ // Error Effect
                context.warnErrorForMandatoryFields();
                return false;    
            }

            return this.$root.axios.post('/web/api/events/set/ticket', {
                client_security_code: userdata.client_security_code,
                event_id: context.eventId,
                name: context.ticketName,
                type: context.ticketType,
                price: context.ticketPrice,
                promo_price: 0,
                availability: (context.ticketAvailability == null) ? 999999999 : context.ticketAvailability,
                    
            }).then((response) => {
                const d = response.data.message;
                const isValid = response.data.isValid;
                    
                context.$emit('component_event', {
                    'ticket' : response.data.data.ticket,
                }); 
                /** Emit di evento al padre */
                    
                context.emptyFields();
                context.loadingEffectshow = false;

            }).catch((error) => {
                context.$root.bus.emit('simpleToast', error);  /** Emit di al gestore globale */
                context.warnUnManagedError();
                context.loadingEffectshow = false;
            });
        },
        emptyFields: function (){
            this.ticketType = 'pay';
            this.ticketPrice = null;
            this.ticketAvailability = null;
            this.ticketName = null;
        },
        dataValid: function (){
            if (typeof this.ticketPrice == 'undefined') {return false;}
            if (this.ticketName == null) {return false;}
            if (!this.ticketName.trim().length > 0) {return false;}
            if (this.ticketPrice == null) {return false;}
            return true;
        },
        warnErrorForMandatoryFields : function () {
            // Emit error
            this.$root.bus.emit(
                'modalView', 
                'error', 
                this.string.insertTicketForm._mandatoryFields
            );  /** Emit di al gestore globale */
            this.$root.bus.emit('simpleToast', this.string.insertTicketForm._mandatoryFields);  /** Emit di al gestore globale */
            this.loadingEffectshow = false;
        },
    }
};
</script>
