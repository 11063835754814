var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "guest-list-container", attrs: { direction: "vertical" } },
    [
      _c(
        "div",
        {
          staticClass: "group-manager-members",
          staticStyle: { "margin-top": "55px" },
        },
        [
          _c("h4", { staticClass: "form-section-title" }, [
            _vm._v("\n      " + _vm._s(_vm.string.sponsor._title) + "\n      "),
            _c("small", [_vm._v(_vm._s(_vm.string.sponsor._subtitle))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "smallseparator" }),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
              _c(
                "a",
                {
                  staticClass: "btn gray-btn import-man",
                  attrs: { title: _vm.string.guestlist._add },
                  on: { click: _vm.toggleInsertSingle },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.guestlist._add) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row tabs-content" },
            [
              _c(
                "transition",
                { attrs: { name: "component-fade-fast", mode: "out-in" } },
                [
                  _vm.tabValue === "insertsingle"
                    ? _c(
                        "div",
                        { key: "insertsingle" },
                        [
                          _c("ListInputSponsor", {
                            attrs: {
                              team: _vm.team,
                              "event-id": _vm.eventId,
                              "father-name": _vm.componentName,
                            },
                            on: { component_event: _vm.closeTab },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.uploadStatus !== "NULL"
            ? _c(
                "h4",
                { staticClass: "form-section-title second progress-title" },
                [
                  _vm._v("\n      " + _vm._s(_vm.uploadStatusMessage) + " "),
                  _vm.uploadStatus !== "PENDING"
                    ? _c("loading-icon")
                    : _vm._e(),
                  _vm._v(" "),
                  _c("small"),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadStatus !== "NULL"
            ? _c("el-progress", {
                staticClass: "progress-container",
                attrs: {
                  "stroke-width": 24,
                  percentage: _vm.uploadProgress,
                  "show-text": false,
                  color: "#f7a30c",
                  format: _vm.formatProgress,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "form-section-title second" }, [
            _vm._v("\n      " + _vm._s(_vm.string.guestlist._list) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "smallseparator" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "downloads_style",
              staticStyle: { "padding-bottom": "20px" },
            },
            [
              _c("i", {
                staticClass: "fa fa-users",
                staticStyle: { "margin-right": "15px" },
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v("\n      " + _vm._s(_vm.string.sponsor._numberOfSponsors)),
              _c("strong", [_vm._v(_vm._s(_vm.totalGuests))]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group-manager-members-list" },
            [
              _c("data-table", {
                ref: "table",
                attrs: {
                  lazy: true,
                  "get-data": _vm.getData,
                  columns: _vm.columns,
                  "page-size": 15,
                },
                on: {
                  loaded: function ($event) {
                    _vm.ready = true
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "Max Tickets",
                    fn: function (ref) {
                      var row = ref.row
                      return _c("span", {}, [
                        row.edit === true
                          ? _c(
                              "div",
                              {
                                attrs: {
                                  name: "component-fade-fast",
                                  mode: "out-in",
                                  appear: "",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: { min: 0, max: 100, step: 1 },
                                  on: {
                                    change: function ($event) {
                                      return _vm.markRowModified(row)
                                    },
                                  },
                                  model: {
                                    value: _vm.editItem.maxTickets,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.editItem, "maxTickets", $$v)
                                    },
                                    expression: "editItem.maxTickets",
                                  },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                attrs: {
                                  name: "component-fade-fast",
                                  mode: "out-in",
                                  appear: "",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(row.maxTickets) +
                                    "\n          "
                                ),
                              ]
                            ),
                      ])
                    },
                  },
                  {
                    key: "Banner",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        { staticStyle: { "text-align": "center" } },
                        [
                          row.banner
                            ? _c("img", {
                                staticStyle: { height: "30px" },
                                attrs: { src: row.banner },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn",
                              staticStyle: { "margin-top": "0" },
                              on: {
                                click: function ($event) {
                                  return _vm.onPickFile(row.sponsorId)
                                },
                              },
                            },
                            [_vm._v("\n            Upload\n          ")]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            ref: "fileInput",
                            staticStyle: { display: "none" },
                            attrs: {
                              id: row.sponsorId,
                              type: "file",
                              accept: "image/*",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.onFilePicked($event, row)
                              },
                            },
                          }),
                        ]
                      )
                    },
                  },
                  {
                    key: "Actions",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "action_link",
                              staticStyle: { "margin-right": "5px" },
                              on: {
                                click: function () {
                                  return row.edit === false
                                    ? _vm.openRow(row)
                                    : _vm.saveRow(row)
                                },
                              },
                            },
                            [
                              _c("i", {
                                class:
                                  row.edit === false
                                    ? "fa fa-pencil-square-o"
                                    : "fa fa-check",
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "action_link",
                              staticStyle: { "margin-right": "5px" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyRowLink(row)
                                },
                              },
                            },
                            [_c("i", { class: "fa fa-copy" })]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "action_link",
                              staticStyle: { "margin-right": "5px" },
                              on: {
                                click: function () {
                                  return row.edit === false
                                    ? _vm.deleteRow(row)
                                    : _vm.closeRow(row)
                                },
                              },
                            },
                            [
                              _c("i", {
                                class:
                                  row.edit === false
                                    ? "fa fa-trash"
                                    : "fa fa-times",
                              }),
                            ]
                          ),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }