<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.c19compliance._title }}
    </h4>
    <div class="form-container accordion-content">
      <!--      <small>{{ string.attendeeMaxLimit._subtitle }}</small>-->
      <el-row>
        <el-col :span="1">
            <custom-checkbox v-model="event.c19c" @change="toggleC19CApp" />
        </el-col>
        <el-col :span="23" class="">
          <p style="margin: 0; padding: 0">
            {{ string.c19compliance._description }}
          </p>
          </el-col>
      </el-row>
      <el-row>
        <el-col :span="23">
          <section-title
            :title="string.c19compliance.timePeriod"
            :subtitle="string.c19compliance.subTimePeriod"
          />
          <NumericFieldInput
            :initial-value="event.c19TH"
            @change="setC19TH"
          />
        </el-col>
      </el-row>
      <el-row v-if="event.c19TH !== c19TH">
        <el-col :span="18" :offset="3">
          <a class="btn gray-btn save" @click.prevent="submit">
            {{ string.attendeeMaxLimit._submit }}
          </a>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style scoped>
.external-puchase-component {
  margin-bottom: 30px;
}

/** Disable Classes */

.form-container {
  position: relative;
}

.disable-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #00000000;
}

/** /Disable Classes */

/** In Action Layer */
.inaction-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
/** In Action Layer */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
</style>
<script>
import CustomCheckbox from "@/components/ui/CustomCheckbox.vue";
import SectionTitle from "@/components/ui/SectionTitle.vue";
import NumericFieldInput from "@/components/NumericFieldInput.vue";

import { UPDATE_EVENT } from "@/graphql";

export default {
  components: {
    CustomCheckbox,
    SectionTitle,
    NumericFieldInput
  },
  props: {
    event: Object
  },
  data() {
    return {
      componentName: "C19Compliance",
      inAction: false,
      string: {},
      c19TH: 0
    };
  },
  watch: {
    event: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.c19TH = newVal.c19TH;
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    setC19TH: function(c19TH) {
      this.c19TH = c19TH;
    },
    submit: function () {
      const context = this;
      this.inAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              c19TH: parseInt(this.c19TH),
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            context.$root.bus.emit("simpleToast", this.string.organizerSettings._settingsUpdated);
            this.inAction = false;
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
    toggleC19CApp: function () {
      const context = this;
      this.inAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              c19c: this.event.c19c
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            context.$root.bus.emit("simpleToast", this.string.organizerSettings._settingsUpdated);
            this.inAction = false;
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
  },
};
</script>
