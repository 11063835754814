<template>
  <el-container direction="vertical" class="page-container">
    <!-- Main Component -->
    <!-- <div class="group-manager-title">
        <h1>{{string.teamPage._page_title}}</h1>
    </div> -->
    <div class="row createevent__title">
      <div class="col-xs-12 col-sm-12 col-lg-12">
        <div style="background: transparent; ">
          <h1 style="font-weight: bold; text-transform: uppercase">
            {{ string.teamPage._page_title }}
          </h1>
        </div>
      </div>
    </div>
    <h4 v-if="$can('create', 'new_user')" class="form-section-title" style="margin-top:30px">
      {{ string.teamPage._invite_title }}
      <small>{{ string.teamPage._invite_subtitle }}</small>
    </h4>
    <div v-if="$can('create', 'new_user')" class="smallseparator"></div>
    <el-row v-if="$can('create', 'new_user')" :gutter="20">
      <el-col :span="16">
        <div>
          <input
            v-model="newMember.email"
            type="text"
            :placeholder="string.teamPage._new_member"
            class="form-control"
            style="font-size: 22px; text-transform: none;"
          />
        </div>
      </el-col>
      <el-col :span="4">
        <el-select
          v-model="newMember.role"
          class="add-new-member"
          popper-class="default-popper"
          size="large"
          :placeholder="string.teamPage._role"
        >
          <el-option
            v-for="item in roles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
            :hidden="item.disabled"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button class="add-new-member" @click.prevent="sendInvitation">
          {{ string.teamPage._invite }}
        </el-button>
      </el-col>
    </el-row>
    <div class="group-manager-members" style="margin-top: 55px">
      <!-- USER LIST -->
      <h4 class="form-section-title">
        {{ string.teamPage._team_table_title }}
        <small>{{ string.teamPage._team_table_subtitle }}</small>
      </h4>
      <div class="smallseparator"></div>
      <div class="group-manager-members-list">
        <data-table ref="table" :get-data="getData" :columns="columns"
        selection-enabled :bulk-options="bulkOptions" :on-bulk-action="onBulkAction"
        :can-select-row="canSelectRow">
          <span slot="email" slot-scope="{ row }">
            {{ row.email }}
          </span>
          <span slot="role" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in"
            appear>
              <el-select
                v-model="row.role"
                class="add-new-member"
                popper-class="default-popper"
                size="large"
                :placeholder="string.teamPage._role"
                @change="e => updateRole(row, e)"
              >
                <el-option
                  v-for="item in roles"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                  :hidden="item.disabled"
                >
                </el-option>
              </el-select>
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in"
            appear>
              <span class="add-new-member">
                {{ row.role.formatEnum() }}
              </span>
            </transition>
          </span>
          <span slot="assignedEventsData" slot-scope="{ row }">
              <div class="event-list">
                <el-popover
                  :id="'listEvent_' + row.id"
                  width="250"
                  trigger="click"
                  placement="bottom"
                  popper-class="event-list-popper"
                >

                  <div
                    class="text-white"
                     v-for="item in row.assignedEventsData"
                    :key="item.id" 
                    >
                    {{ item.name }}
                  </div>
                
                  <div slot="reference" class="name-wrapper-2">
                    <el-tag class="text-white">{{ string.datagrid ? string.datagrid._list_events : "" }}</el-tag>
                  </div>
                </el-popover>
              </div>
          </span>
          <span slot="accountStatus" slot-scope="{ row }">
            <transition v-if="row.edit === true" name="component-fade-fast" mode="out-in"
            appear>
              <el-select
                v-model="row.accountStatus"
                class="add-new-member"
                popper-class="default-popper"
                size="large"
                :placeholder="string.teamPage._role"
                @change="e => updateStatus(row, e)"
              >
                <el-option
                  v-for="item in statuses"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                  :hidden="item.disabled"
                >
                </el-option>
              </el-select>
            </transition>
            <transition v-else name="component-fade-fast" mode="out-in"
            appear>
              <div class="add-new-member" style="text-align: left !important; line-height:48px; ">
                <loading-icon v-if="row.accountStatus === 'DISABLED'" style="margin-right: 10px;" />

                <font-awesome-layers v-else class="fa-lg" style="margin-right: 10px;">
                  <font-awesome-icon
                    :icon="['fas', 'circle']"
                    transform="grow-6"
                    :style="statusStyle[row.accountStatus]"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon
                    :icon="statusIcons[row.accountStatus]"
                    transform="shrink-4"
                    :style="{ color: 'white' }"
                  >
                  </font-awesome-icon>
                </font-awesome-layers>

                <span>{{ statusDescription[row.accountStatus] }}</span>
              </div>
            </transition>
          </span>
          <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
            <!-- <el-button-group> -->
            <a
              v-if="
                $can('update', $root.setSubject('team_member', { ...row })) && row.id !== user.id
              "
              :disabled="row.accountStatus === 'DISABLED'"
              class="delete_prospect_link"
              style="margin-right: 5px; color: white;"
              @click="toggleEditing(row)"
            >
              <i :class="row.edit === false ? 'fa fa-pencil-square-o' : 'fa fa-check'"></i>
            </a>

            <a
              v-if="
                $can('delete', $root.setSubject('team_member', { ...row })) && row.id !== user.id
              "
              :disabled="row.accountStatus === 'DISABLED'"
              class="delete_prospect_link"
              style="color: white;"
              @click="deleteRow(row)"
            >
              <i class="fa fa-trash"></i>
            </a>
            <!-- </el-button-group> -->
          </span>
        </data-table>
      </div>
    </div>
  </el-container>
</template>

<script>
import DataTable from "../../components/DataTable";
import {
  GET_MY_TEAM,
  SEND_INVITATION,
  UPDATE_USER,
  DELETE_USER,
  SUBSCRIBE_TO_TEAM_MEMBER_NEWS
} from "@/graphql";
import { subject } from "@casl/ability";
import { mapGetters } from "vuex";
import LoadingIcon from "../../components/LoadingIcon";
export default {
  name: "TeamMembersManager",
  components: { DataTable, LoadingIcon },
  data() {
    return {
      roles: [
        {
          value: "OWNER",
          label: "Owner",
          disabled: true
        },
        {
          value: "ORGANIZER",
          label: "Organizer",
          disabled: true
        },
        {
          value: "STAFF",
          label: "Staff"
        },
        {
          value: "STAFF_MANAGER",
          label: "Staff Manager"
        },
        {
          value: "HOSTESS",
          label: "Hostess"
        },
        {
          value: "HOSTESS_MANAGER",
          label: "Hostess Manager"
        }
      ],
      statuses: [
        {
          value: "INACTIVE",
          label: "Inactive"
        },
        {
          value: "ACTIVE",
          label: "Active"
        },
        {
          value: "DISABLED",
          label: "Disabled",
          disabled: true
        },
        {
          value: "PENDING",
          label: "Pending",
          disabled: true
        }
      ],
      newMember: {
        email: "",
        role: ""
      },
      columns: [
        {
          prop: "name",
          label: "Name",
          minWidth: "100",
          sortable: true
        },
        {
          prop: "surname",
          label: "Surname",
          minWidth: "100",
          sortable: true
        },
        {
          prop: "email",
          label: "Email",
          minWidth: "100",
          sortable: true
        },
        {
          prop: "role",
          label: "Role",
          minWidth: "100",
          sortable: true
        },
        {
          prop: "assignedEventsData",
          label: "Events",
          minWidth: "100",
          sortable: false
        },
        {
          prop: "accountStatus",
          label: "Status",
          minWidth: "100",
          sortable: true
        },
        {
          label: "Actions",
          width: "80"
        }
      ],
      string: {
        groupManager: {}
      },
      statusIcons: {
        PENDING: "exclamation",
        ACTIVE: "thumbs-up",
        DISABLED: "times",
        INACTIVE: "times"
      },
      statusStyle: {
        PENDING: { color: "#555555" },
        ACTIVE: { color: "#2980b9" },
        DISABLED: { color: "#DC143C" },
        INACTIVE: { color: "#DC143C" }
      },
      statusDescription: {
        PENDING: "pending",
        ACTIVE: "active",
        DISABLED: " deleting",
        INACTIVE: "inactive"
      },
      teamMembersSubscription: null,
      bulkOptions: [
        "addToEvent",
        "removeFromEvent"
      ]
    };
  },
  computed: {
    ...mapGetters(["user"])
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.subscribeToUpdates();
  },
  beforeDestroy() {
    this.unsubscribeFromUpdates();
  },
  methods: {
    onBulkAction(action, selectedRows = []) {
      if (action === "addToEvent") {
        this.$root.bus.emit("toggleModalBulkAction", {
          show: true,
          users: selectedRows,
          title: this.string.modalBulkAction.addToEvent,
          action
        });
      } else if (action === "removeFromEvent") {
        this.$root.bus.emit("toggleModalBulkAction", {
          show: true,
          users: selectedRows,
          title: this.string.modalBulkAction.removeFromEvent,
          action
        });
      }
    },
    sendInvitation() {
      if (this.canSendInvitation()) {
        const context = this;
        return this.$apollo
          .mutate({
            fetchPolicy: "no-cache",
            mutation: SEND_INVITATION,
            variables: {
              user: context.newMember
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              return;
            }
            if (response.data && response.data.createAndInviteUser) {
              const payload = response.data.createAndInviteUser;
              context.$refs.table.getTableData();
              context.$root.bus.emit("simpleToast", context.string.teamPage._invitation_sent);
              context.newMember.email = "";
              context.newMember.role = "";
            }
          })
          .catch(error => {
            if (
              error.graphQLErrors &&
              error.graphQLErrors.length &&
              error.graphQLErrors[0].message === "EMAIL_IN_USE"
            ) {
              context.$root.bus.emit("simpleToast", context.string.organizerSettings._email_exists);
            } else {
              console.log(error);
            }
          });
      }
    },
    canSendInvitation() {
      if (!this.newMember.email || !this.newMember.email.testEmail()) {
        this.$root.bus.emit("simpleToast", this.string.teamPage._error_email_invalid);
        return false;
      }
      if (!this.newMember.role || this.newMember.role === "") {
        this.$root.bus.emit("simpleToast", this.string.teamPage._error_role_invalid);
        return false;
      }
      return true;
    },
    getData({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      const input = {};
      if (sortParams && Object.keys(sortParams).length) {
        for (const cField of Object.keys(sortParams)) {
          input["sortField"] = cField;
          input["sortOrder"] = sortParams[cField];
          break;
        }
      }
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_MY_TEAM,
          variables: {
            filter: filter && filter,
            pageInfo: {
              pageSize: pageSize,
              currentPage: page,
              sortField: input["sortField"],
              sortOrder: input["sortOrder"]
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.$router.push("/").catch(_);
            return;
          }
          if (response.data && response.data.myTeamMembers) {
            const payload = response.data.myTeamMembers;
            return {
              data: payload.data,
              total: payload.total
            };
          }
        })
        .catch(error => {
          context.$router.push("/").catch(_);
          console.log(error);
        });
    },
    toggleEditing(row) {
      for (const [index, item] of this.$refs.table.tableData.entries()) {
        if (item.id !== row.id && item.edit) {
          item.edit = false;
        }
      }
      row.edit = !row.edit;
    },
    updateRole(row, newRole) {
      const context = this;
      return this.$apollo
        .mutate({
          fetchPolicy: "no-cache",
          mutation: UPDATE_USER,
          variables: {
            userId: row.id,
            updates: {
              role: newRole
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.updateUser) {
            context.$root.bus.emit("simpleToast", context.string.teamPage._updated);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateStatus(row, newStatus) {
      const context = this;
      return this.$apollo
        .mutate({
          fetchPolicy: "no-cache",
          mutation: UPDATE_USER,
          variables: {
            userId: row.id,
            updates: {
              accountStatus: newStatus
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.updateUser) {
            context.$root.bus.emit("simpleToast", context.string.teamPage._updated);
          }
        })
        .catch(error => {
          console.log(error);
        });
      // this.$confirm(`Updating Status of ${row.name} to ${newStatus} . This won't do anything`).catch(_ => {});
    },
    deleteRow(row) {
      this.$confirm(`${this.string.teamPage._warning_remove} ${row.email}?`)
        .then(_ => {
          const context = this;
          return this.$apollo
            .mutate({
              fetchPolicy: "no-cache",
              mutation: DELETE_USER,
              variables: {
                userId: row.id
              }
            })
            .then(response => {
              if (response.errors) {
                console.log(response.errors);
                return;
              }
              if (response.data && response.data.deleteUser) {
                row.accountStatus = "DISABLED";
                context.$root.bus.emit("simpleToast", context.string.teamPage._post_remove);
              }
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(_ => {});
    },
    subscribeToUpdates() {
      const context = this;
      this.teamMembersSubscription = this.$apollo
        .subscribe({
          query: SUBSCRIBE_TO_TEAM_MEMBER_NEWS,
          variables: { teamId: this.user.teamId }
        })
        .subscribe(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.teamMember) {
            const payload = data.teamMember;
            const updatedItems = payload.data;
            let tempArray = context.$refs.table.tableData;
            console.log("TeamMembersManager@subscribeToUpdates");
            console.log({ ...payload } );
            if ( payload.mutation === 'CREATED') {
                // let itExist = false;
                // for (const updatedItem of updatedItems) {
                //   for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
                //     if (existingItem.id === updatedItem.id) {
                //       itExist = true;
                //       break;
                //     }
                //   }
                //   if (!itExist) {
                //     tempArray = [updatedItem, ...tempArray];
                //     context.$refs.table.total++;
                //   }
                // }
                // context.$refs.table.tableData = [...tempArray];
                context.$refs.table.getTableData();
            } else if (payload.mutation === 'UPDATED') {
              for (const updatedItem of updatedItems) {
                  for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
                    if (existingItem.id === updatedItem.id) {
                      tempArray[index] = { ...tempArray[index], ...updatedItem };
                    }
                  }
                }
                context.$refs.table.tableData = [...tempArray];
            } else if (payload.mutation === 'DELETED') {
              for (const updatedItem of updatedItems) {
                  for (const [index, existingItem] of context.$refs.table.tableData.entries()) {
                    if (existingItem.id === updatedItem.id) {
                      delete tempArray[index];
                      context.$refs.table.total--;
                    }
                  }
                }
                context.$refs.table.tableData = [...tempArray];
            }
          }
        });
    },
    unsubscribeFromUpdates() {
      try {
        this.teamMembersSubscription.unsubscribe();
        this.teamMembersSubscription = null;
      } catch (e) {}
    },
    canSelectRow(row, index) {
      if (row.role === "OWNER") {
        return false;
      }
      if (this.user && row.id === this.user.id) {
        return false;
      }
      if (row.accountStatus === "DISABLED") {
        return false;
      }
      return true;
    }
  }
};
</script>

<style>
div.name-wrapper {
  text-align: center;
  width: 100%;
  height: 100%;
}
.text-white{
  color: #fff !important;
}
.el-popover.event-list-popper {
  background: #111213e0;
  border: 1px solid #f7a30c;
}
.status-pending {
  color: #eea236 !important;
  text-align: left;
  margin-right: 5px;
}
.status-active {
  color: #0ac407 !important;
  text-align: left;
  margin-right: 5px;
}
.status-inactive {
  color: crimson !important;
  text-align: left;
  margin-right: 5px;
}
.button-pair.first:hover,
.button-pair.second:hover {
  background: #fff !important;
  color: #333 !important;
}
.button-pair.second,
.button-pair.first {
  background: #333 !important;
  color: #f7a30c !important;
}
.page-container  {
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  opacity: 0.97;
  filter: alpha(opacity=97);
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  /* margin-top: 11vh; */
  padding: 20px !important;
  margin-bottom: 11vh;
  overflow: hidden;
}
.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 18px;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
.group-manager-title {
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
}
.group-manager-actions {
  padding-bottom: 30px;
}
.group-manager-actions-button {
  padding: 5px;
}
/* .group-manager-actions-button:first-child,
    .group-manager-actions-button:last-child{
        padding-left: 15px;
    } */
.group-manager-actions-button a {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  border-radius: unset !important;
  background-color: #333333;
  transition: 1s ease;
}
.group-manager-actions-button a:hover {
  background-color: #dadada;
  color: #333333;
}
.group-manager-members,
.group-manager-members-list,
.group-manager-members-list > div {
  width: 100%;
}
.group-manager-members-list-no-data {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.group-manager-members-list-no-data i {
  font-size: 130px;
  margin-left: 35px;
  color: #f7a30c;
}
.group-manager-members-list-no-data-message {
  font-size: 30px;
  text-transform: uppercase;
}
.group-manager-members-list-no-data-message .underline {
  border-bottom: 2px solid #f7a30c;
}
.group-manager-members-list-no-data-hint {
  font-size: 25px;
  font-style: italic;
}

/** class for the transition NO REMOVE THIS */
.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active,
.component-fade-leave-to {
  opacity: 0;
}
.add-new-member,
.add-new-member > div > input {
  height: 48px !important;
  text-align: center;
  width: 100%;
}
span.add-new-member {
  line-height: 48px !important;
}
.add-new-member > .el-button:focus,
.el-button:hover {
  color: black !important;
  background: white !important;
}
button.el-button.add-new-member.el-button--default {
  color: white !important;
  background: #f7a30c !important;
}
a.delete_prospect_link {
  transition: 1s ease;
  background-color: transparent !important;
  color: #dadada !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

a.delete_prospect_link:hover {
  color: #333333 !important;
}

/* a.actions_link {
  display: inline-block;
  cursor: pointer;
  transition: 1s ease;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 0px !important;
  margin-top: 0px !important;
}
a.actions_link:hover {
  background-color: transparent !important;
  color: #000 !important;
} */
</style>
