<template>
  <div>
    <NewEvent :prop-private="p"></NewEvent>
  </div>
</template>
<style>

</style>
<script>
import NewEvent from '../components/NewEvent.vue';
import store from "../store";
import { subject } from '@casl/ability';

export default {
    components:{
        NewEvent,
    },
    data (){
        return {
            p: true
        };
    },
    metaInfo: {
        title: 'Create Event - Penguinpass',
        bodyAttrs: {
            class: 'dashboard'
        }
    },
    beforeRouteEnter(to,from,next) {
      if (store.getters.ability.can('create', "new_event")) {
        next();
      } else {
        next('/');
      }
    }
};
</script>
