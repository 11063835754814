var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "heading-title text-white",
                  attrs: { id: "title" },
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.statusTitle) + "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "heading-tescription lead text-gray-2",
                  attrs: { id: "description" },
                },
                [
                  _vm._v("\n            " + _vm._s(_vm.statusSubtitle)),
                  _c("br"),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.joinEventExtraInfo) +
                      "\n          "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showForm
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "margin-top-80 text-white wow fadeIn",
                    attrs: { "data-wow-delay": "0.3s" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "row", staticStyle: { margin: "2px" } },
                      [
                        _c("div", { staticClass: "col-lg-8" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              {
                                staticClass: "sr-only",
                                attrs: { for: "email" },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$root.strings.verifyAccount
                                        ._email_label
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.email,
                                  expression: "email",
                                  modifiers: { trim: true },
                                },
                              ],
                              staticClass: "form-control no-rounded",
                              attrs: {
                                id: "email",
                                type: "email",
                                name: "email",
                                value: "",
                                placeholder: "Email Address",
                                required: "",
                              },
                              domProps: { value: _vm.email },
                              on: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.resendLink($event)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value.trim()
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-warning active btn-block btn-lg",
                                attrs: { type: "submit" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.resendLink($event)
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    $event.preventDefault()
                                    return _vm.resendLink($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.$root.strings.verifyAccount._send_link
                                    ) +
                                    "\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }