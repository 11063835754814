var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v("\n    " + _vm._s(_vm.string.RSVPImageHeader._title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "accordion-content" },
        [
          _c("el-row", [
            _c("small", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.$root.strings.RSVPImageHeader._subtitle) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ImageToggleSelection" }, [
              _c("label", { staticClass: "control control-checkbox" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.RSVPImageHeader._innertitle) +
                    "\n            "
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.showEditor,
                      expression: "showEditor",
                    },
                  ],
                  attrs: { id: "checkbox", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.showEditor)
                      ? _vm._i(_vm.showEditor, null) > -1
                      : _vm.showEditor,
                  },
                  on: {
                    change: [
                      function ($event) {
                        var $$a = _vm.showEditor,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.showEditor = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.showEditor = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.showEditor = $$c
                        }
                      },
                      _vm.toggleImage,
                    ],
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "control_indicator" }),
              ]),
            ]),
            _vm._v(" "),
            _vm.showEditor
              ? _c("div", { staticClass: "form-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "user-image",
                      on: {
                        mouseover: _vm.mouseOver,
                        mouseout: _vm.mouseOut,
                        mousedown: _vm.mouseDown,
                      },
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "component-fade-fast",
                            mode: "out-in",
                            appear: "",
                          },
                        },
                        [
                          _vm.loadingEffectshow
                            ? _c("LoadingCover", {
                                attrs: {
                                  "loading-cover-msg":
                                    _vm.string.coverImage._loadingMessage,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "updateButtonContainer" },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                                appear: "",
                              },
                            },
                            [
                              _vm.showUpdateButton === true
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-labeled btn-info",
                                      staticStyle: {
                                        float: "right !important",
                                      },
                                      attrs: { type: "button" },
                                    },
                                    [
                                      _c("span", { staticClass: "btn-label" }, [
                                        _c("i", {
                                          staticClass: "fa fa-pencil",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                      ]),
                                      _vm._v(
                                        "\n                  Update\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            {
                              attrs: {
                                name: "component-fade-fast",
                                mode: "out-in",
                                appear: "",
                              },
                            },
                            [
                              _vm.showUpdateButton === true
                                ? _c(
                                    "div",
                                    { staticClass: "updateButtonContainerBg" },
                                    [
                                      _c("div", { staticClass: "imgNotices" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.coverImage._imgNoticesTY
                                          )
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass:
                          "img-responsive thumbnail cover-image-constraints",
                        attrs: {
                          src: _vm.newImg || _vm.event.logoUrl,
                          alt: "Event cover Image",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        ref: "fileinput",
                        attrs: {
                          id: "fileinput",
                          type: "file",
                          accept: "image/*",
                        },
                        on: { change: _vm.onFileChange },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }