var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modal.visible,
            width: "30%",
            "custom-class": "default-popper",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.modal, "visible", $event)
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-title",
              attrs: { slot: "title" },
              slot: "title",
            },
            [_vm._v("\n        " + _vm._s(_vm.modal.title) + "\n      ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-description" }, [
            _vm._v(
              _vm._s(
                _vm.modal.users
                  ? _vm.modal.users.length +
                      " " +
                      _vm.string.modalBulkAction._user_selected
                  : ""
              )
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "filter-field" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter",
                },
              ],
              staticClass: "datagridsSearchBar",
              attrs: {
                type: "text",
                placeholder: _vm.string.modalBulkAction._search_event,
              },
              domProps: { value: _vm.filter },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.filter = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "options-container" },
            [
              _vm._l(_vm.options, function (item, $index) {
                return _c(
                  "li",
                  {
                    key: $index,
                    staticClass: "option-item",
                    class: [
                      _vm.modal.events[$index] === item.id ||
                      _vm.selectedEvent === item.id
                        ? "active"
                        : "",
                      "option-item",
                    ],
                    attrs: { "data-num": $index + 1 },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.selectedEvent =
                          _vm.selectedEvent === item.id ? null : item.id
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " + _vm._s(item.name) + "\n\n          "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c("infinite-loading", {
                attrs: { identifier: _vm.infiniteId },
                on: { infinite: _vm.infiniteHandler },
                scopedSlots: _vm._u([
                  {
                    key: "error",
                    fn: function (ref) {
                      var trigger = ref.trigger
                      return _c("div", {}, [
                        _vm._v("\n                Couldn't loaded. Click "),
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:;" },
                            on: { click: trigger },
                          },
                          [_vm._v("here")]
                        ),
                        _vm._v(" to retry\n              "),
                      ])
                    },
                  },
                ]),
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v(_vm._s(_vm.string.modalBulkAction._cancel)),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.onConfirm },
                },
                [_vm._v(_vm._s(_vm.string.modalBulkAction._confirm))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }