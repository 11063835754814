<template>
  <el-row class="section-content-container" :gutter="5">
    <el-col v-if="hasIcon" :span="hasIcon ? 1 : 0" class="section-icon-container">
      <progress-ring :style="'visibility:' + (iconText ? 'visible; ' : 'hidden; ')" class="section-icon" :progress="iconProgress" :text="iconText">
        <template v-if="iconProgress === 100" slot="icon">
          <font-awesome-icon icon="check" transform="shrink-8" :style="{ color: '#FF9B00' }" />
        </template>
      </progress-ring>
    </el-col>
    <el-col :span="hasIcon ? 23 : 24">
      <slot />
    </el-col>
  </el-row>
</template>

<script>
import ProgressRing from "./ProgressRing.vue";
export default {
  components: { ProgressRing },
  props: {
    hasIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconProgress: {
      type: Number,
      required: false,
      default: 0,
    },
    iconName: {
      type: String,
      required: false,
      default: "check",
    },
    iconText: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped>
.section-content-container {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 30px;
}
.section-icon-container {
  display: flex;
  flex-direction: row-reverse;
}
.section-icon {
  border-radius: 50%;
  background-color: transparent;
  /* border: 2px solid rgba(255, 255, 255, 0.2); */
  /* height: 35px;
  width: 35px; */
  margin: 10px 10px 20px 0;
}
</style>