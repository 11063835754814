var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-grid-container", staticStyle: { overflow: "auto" } },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.columns, function (columnObject) {
                return _c(
                  "th",
                  {
                    class: { active: false },
                    on: { mouseOver: function ($event) {} },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("capitalize")(columnObject.label)) +
                        "\n\n      "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.enableActions
                ? _c(
                    "th",
                    {
                      class: { active: false },
                      on: { mouseOver: function ($event) {} },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("capitalize")("Action")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.filteredData, function (entry) {
            return _c(
              "tr",
              { staticClass: "tableRowItem", attrs: { id: entry["id"] } },
              [
                _vm._l(_vm.columns, function (columnObject) {
                  return _c("td", {
                    domProps: {
                      innerHTML: _vm._s(_vm.getKey(entry, columnObject.key)),
                    },
                  })
                }),
                _vm._v(" "),
                _vm.enableActions
                  ? _c("td", { staticClass: "actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "actions_link delete_prospect_link",
                          attrs: {
                            "data-toggle": "tooltip_identity",
                            "data-placement": "top",
                            title: "Remove",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.removeIdentity(entry["identity"])
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash-o" }, [
                            _vm._v("\n             \n          "),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }