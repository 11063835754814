<template>
  <div class="advance-settings-component-container privacy-policy-component accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.urlPrivacyPolicy._title }}
    </h4>
    <div class="form-container accordion-content">
      <small>{{ string.urlPrivacyPolicy._management }}</small>

      <div
        v-if="componentDisabled"
        class="disable-layer"
        @click="disableLayerClicked">
&nbsp;
      </div>
      <div
        v-if="inAction"
        class="inaction-layer">
&nbsp;
      </div>

      <div class="component-menu row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="displayed_action_title">
            <transition
              name="component-fade-fast"
              mode="out-in">
              <a
                v-if="!createNew"
                class="btn action"
                @click="showCreateNewAction(true)">
                {{ string.privacyPolicy._createNew }}
              </a>
              <div
                v-else
                class="label">
                {{ string.privacyPolicy._createNew }}
              </div>
            </transition>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="displayed_action_title">
            <transition
              name="component-fade-fast"
              mode="out-in">
              <a
                v-if="createNew"
                class="btn action"
                @click="showCreateNewAction(false)">
                {{ string.privacyPolicy._chooseExisting }}
              </a>
              <div
                v-else
                class="label">
                {{ string.privacyPolicy._chooseExisting }}
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div class="component-actions">
        <div v-if="createNew">
          <CreatePolicy
            :event="event"
            @created="showCreateNewAction(false)"></CreatePolicy>
        </div>
        <div v-else>
          <UseExistingPolicy :event="event"></UseExistingPolicy>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

    .privacy-policy-component .component-menu .btn{
        width: 100%;
        color: white;
        font-family: inherit;
        font-weight: 400;
    }
    .privacy-policy-component .component-menu .label{
        background-color: #f7a30c;
        color: white;
        padding: 15px;
        border-width: 0px;
        text-transform: uppercase;
        margin-top: 20px;
    }
    .privacy-policy-component .component-actions {
        margin: 20px 0 20px 0;
    }
    .privacy-policy-component .component-menu{
        margin: 0 -15px 20px -15px;
    }

    .form-section-title{
        border-bottom: solid 1px #dddddd;
        padding: 20px 0;
        color: #f7a30c;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .form-section-title small{
        font-size: 12px;
        display: block;
        line-height: 1.5;
        margin-top: 5px;
        color: #e8e8e8;
    }

    .label {
        display: inline-block !important;
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 1.42857143 !important;
        text-align: center !important;
        vertical-align: middle !important;
        width: 100% !important;
        border: 1px solid transparent !important;
        border-radius: 0px;
    }

</style>
<script>
import CreatePolicy from '../../../event/settings/privacy/CreatePolicy.vue';
import UseExistingPolicy from '../../../event/settings/privacy/UseExistingPolicy.vue';

export default {
    components:{
        CreatePolicy,
        UseExistingPolicy
    },
    props: {
        event: Object,
    },
    data (){
        return {
            createNew : false,
            string: {},
            inAction: false,
            disableLayerClicked: false,
            componentDisabled: true, /** Disable Component */
        };
    },
    mounted (){
        this.isComponentDisabled();
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    methods: {
        showCreateNewAction : function (value){
            this.createNew = value;
        },
        isComponentDisabled : function (){
            if (this.$root.userPermissions.indexOf('togglePrivacyPolicy_Rule') >= 0){
                this.componentDisabled = false;
            }
        },
    }
};
</script>
