<template>
  <MainLayout>
    <el-container direction="vertical" class="page-container">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-12">
          <h1 style="font-weight: 300;">
            {{ string.recordings._page_title }}
          </h1>
          <h4 style="margin-top: 10px">
            {{ string.recordings._subtitle }}
          </h4>
          <div class="smallseparator" style="margin-top: 25px !important;"></div>
          <data-table ref="table" :get-data="getData" :columns="columns"
           :bulk-options="bulkOptions" :on-bulk-action="onBulkAction">
              <span slot="event" slot-scope="{ row }">
                {{ row.event[0].name }}
              </span>
              <span slot="date" slot-scope="{ row }">
                {{ moment.utc(row.lifespan.start, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY") }}
              </span>
              <span slot="time" slot-scope="{ row }">
                {{ moment.utc(row.lifespan.start, "YYYY-MM-DD HH:mm").format("HH:mm z") }}
              </span>
              <span slot="format" slot-scope="{ row }">
                {{ row.videoRoom[0].type }}
              </span>
              <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
                <!-- <el-button-group> -->
                <template v-for="(url, urlIndex) in row.urls">
                  <a
                    class="delete_prospect_link"
                    style="margin-right: 5px; color: white;"
                    @click="openInNewTab(url);"
                    :key="`url-${urlIndex}`"
                  >
                    <i class="fa fa-download"></i>
                  </a>
                  <a
                    class="delete_prospect_link"
                    style="color: white;"
                    @click="deleteRow(row, url);"
                    :key="`del-url-${urlIndex}`"
                  >
                    <i class="fa fa-trash"></i>
                  </a>
                </template>
                <!-- </el-button-group> -->
              </span>
            </data-table>
        </div>
      </div>
    </el-container>
  </MainLayout>
</template>

<script>
import MainLayout from '../layouts/Main.vue';
import DataTable from "../components/DataTable";
import store from "../store";
import {
  GET_MY_RECORDINGS,
  DELETE_RECORDING
} from "@/graphql";
const moment = require('moment');

export default {
    components: {
        MainLayout,
        DataTable
    },
    data() {
      return {
        string: {
        },
        recordings: [],
        columns: [
          {
            prop: "event",
            label: "Recording name",
            minWidth: "80",
            sortable: true
          },
          {
            prop: "date",
            label: "Date",
            minWidth: "80",
            sortable: true
          },
          {
            prop: "time",
            label: "Time",
            minWidth: "80",
            sortable: true
          },
          {
            prop: "format",
            label: "Format",
            minWidth: "80",
            sortable: true
          },
          {
            label: "Actions",
            width: "200"
          }
        ],
        bulkOptions: []
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener("setLanguage", () => {
        context.string = context.$root.strings;
      });
    },
    methods: {
    },
    computed: {
    },
    beforeRouteEnter(to,from,next) {
      if(store.getters.features && store.getters.features.virtualRoom) {
        console.log('[Recordings:beforeRouteEnter] Welcome to Recordings')
        next()
      }else {
        console.log('[Recordings:beforeRouteEnter] Access denied to Recordings')
        next('/');
      }
    },
    methods: {
      // onBulkAction(action, selectedRows = []) {
      //   console.log("action, selectedRows", action, selectedRows)
      //   switch(action) {
      //     case "deleteRecording":
      //       const filteredSelectedRows = this.recordings.filter(
      //         item => {
      //           return selectedRows.indexOf(item.id) > -1
      //         }
      //       )
      //       for(let item of filteredSelectedRows) {
      //         for(let item2 of item.urls){
      //           this.deleteRow(item, item2)
      //         }
      //       }
      //       break;
      //     default:
      //   }
      // },
      openInNewTab(url) {
        window.open(url, '_blank').focus();
      },
      deleteRow(row, url) {
        this.$confirm(`${this.string.recordings._warning_remove} ${row.event[0].name}?`)
        .then(() => {
          const context = this;
          return this.$apollo
            .mutate({
              fetchPolicy: "no-cache",
              mutation: DELETE_RECORDING,
              variables: {
                id: row.id,
                url: url,
                recordingId: url.split('/').length>2?url.split('/')[url.split('/').length - 2]:''
              }
            })
            .then(response => {
              if (response.errors) {
                console.log(response.errors);
                return;
              }
              if (response.data && response.data.deleteRecording) {
                context.getData({ filter: null, page: 0, query: null, sortParams: null, pageSize: 10 })
                context.$root.bus.emit("simpleToast", context.string.recordings._post_remove);
              }
            })
            .catch(error => {
              console.log(error);
            });
        })
        .catch(_ => {});
      },
      getData({ filter, page, query, sortParams, pageSize }) {
        const context = this;
        const input = {};
        // if (sortParams && Object.keys(sortParams).length) {
        //   for (const cField of Object.keys(sortParams)) {
        //     input["sortField"] = cField;
        //     input["sortOrder"] = sortParams[cField];
        //     break;
        //   }
        // }
        if(this.recordings.length === 0) {
          return this.$apollo
            .query({
              fetchPolicy: "no-cache",
              query: GET_MY_RECORDINGS,
              variables: {
                filter: filter && filter
              }
            })
            .then(response => {
              if (response.errors) {
                console.log(response.errors);
                context.$router.push("/").catch(_);
                return;
              }
              if (response.data && response.data.myRecordings) {
                const payload = response.data.myRecordings;
                this.recordings = payload;
                return {
                  data: payload,
                  total: payload.length
                };
              }
            })
            .catch(error => {
              context.$router.push("/").catch(_);
              console.log(error);
            });
        } else {
          if(!filter) {
            return {
              data: this.recordings,
              total: this.recordings.length
            };
          }
          const filteredResults = this.recordings.filter(item => {
            return item.event[0].name.indexOf(filter) > -1
          })
          return {
            data: filteredResults,
            total: filteredResults.length
          };
        }
      }
    },
    metaInfo: {
      title: 'Penguinpass - Video Rooms Recordings',
      bodyAttrs: {
          class: 'dashboard'
      }
    },
};
</script>
