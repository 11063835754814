var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input-number", {
    attrs: { id: "inputNumber", min: _vm.min, max: _vm.max },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.fieldValue,
      callback: function ($$v) {
        _vm.fieldValue = $$v
      },
      expression: "fieldValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }