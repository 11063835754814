var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "loadingCover" } }, [
    _c(
      "div",
      { staticClass: "loadingCoverMessage" },
      [
        _c("PulseLoader", {
          attrs: { loading: _vm.loading, color: _vm.color, size: _vm.size },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }