<template>
  <div>
    <div class="form-section-editor editor text-center">
      <quill-editor
          ref="messageEditor"
          v-model="contentChanged"
          class="quillEditor_style"
          :config="editorOption"
          @blur="onEditorBlur($event)"
          @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)"
          @change="onEditorChange($event)"
        >
        </quill-editor>
      <input
          type="button"
          class="save"
          name="submit"
          :value="string.commonActions.save"
          @click="save"
        />
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  props: {
    content: String,
  },
  data() {
    return {
      string: {},
      contentChanged: this.content || "",
      // editor-related options, see https://quilljs.com/docs/quickstart/
      editorOption: {
        placeholder: "content test",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "link"], // toggled buttons
            [{ header: 1 }, { header: 2 }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],
          ],
        },
      },
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    save() {
      this.$emit("save-content", this.contentChanged);
    },

    // editor related methods
    onEditorBlur(editor) {},
    onEditorFocus(editor) {},
    onEditorReady(editor) {},
    onEditorChange({ editor, html, text }) {},
  },
};
</script>

<style scoped>
.quill-editor >>> .ql-container.ql-snow .ql-editor {
  color: black;
  background-color: white;
}
</style>
