<template>
  <div>
    <NewEvent :prop-private="p"></NewEvent>
    <Redirect
      href="/"
      :start-redirect="redirectIntent"
    ></Redirect>
  </div>
</template>
<style>

</style>
<script>
import NewEvent from '../components/NewEvent.vue';
import Redirect from '../components/Redirect.vue';

import store from "../store";
import { subject } from '@casl/ability';

export default {
    components:{
        NewEvent,
        Redirect
    },
    data (){
        return {
            p: false,
            redirectIntent: false,
        };
    },
    created (){
        /** reroute - if userPermission has not updatePrivate */
        if (!(this.$root.userPermissions.indexOf('updatePrivate') >= 0)){
            this.redirectIntent = true;
        }
    },
    metaInfo: {
        title: 'Create Event - Penguinpass',
        bodyAttrs: {
            class: 'dashboard'
        }
    },
    beforeRouteEnter(to,from,next) {
      if (store.getters.ability.can('create', "new_event")) {
        next();
        } else {
        next('/');
        }
    }
};
</script>
