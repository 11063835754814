<template>
   <PublicLayout>
    <div class="container">
      <div class="row wow fadeIn">
        <div class="col-md-7">
          <div class="heading heading-md heading-uppercase heading-hover">
            <br>
            <h2
                id="title"
                class="heading-title text-white">
                {{string.verificationResult._failure_title}}
              </h2>
              <hr class="hr-width-1 hr-5x border-main">
              <p
                id="description"
                class="heading-tescription lead text-gray-2">
                {{ string.verificationResult._failure_message }}
                <a
                        href="mailto:info@penguinpass.it"
                        target="_blank">
                info@penguinpass.it
                </a>
              </p>
          </div>
        </div>
      </div>
    </div>
  </PublicLayout>
</template>

<script >
  import PublicLayout from "../layouts/PublicLayout"

  export default {
    components: {PublicLayout},
    data (){
      return {
        string: {
          verificationResult: {
            _failure_title: 'Sorry!',
            _failure_message: 'Something went wrong. Please try again or don\'t hesitate to contact us',
          }
        }
      };

    },
    metaInfo: {
      title: 'Verification - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      }, this);
    },
    mounted () {}
  };

</script>


<style scoped>

</style>
