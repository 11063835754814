<template>
  <MainLayout>
    <EventGroup></EventGroup>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main.vue";
import EventGroup from "@/components/settings/EventGroup.vue";
import { subject } from "@casl/ability";
import store from "@/store";
export default {
  components: {
    MainLayout,
    EventGroup,
  },
  computed: {
  },
  created() {
    //
  },
  methods: {
    //
  },
  beforeRouteEnter(to, from, next) {
    if (
      store.getters.features &&
      store.getters.features.teamMembers &&
      store.getters.ability.can("access", subject("team_members_page", { ...store.getters.team }))
    ) {
      // console.log('[TeamMembers:beforeRouteEnter] Welcome to Team Members')
      next();
    } else {
      // console.log('[TeamMembers:beforeRouteEnter] Access denied to Team Members')
      next("/");
    }
  },
  metaInfo: {
    title: "Penguinpass - Event Group",
    bodyAttrs: {
      class: "dashboard",
    },
  },
};
</script>
