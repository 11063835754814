<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Eventi
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/it-eventi">
              Eventi
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come creare, eliminare o archiviare un evento
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come modificare un evento
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Quale differenza c’è tra evento pubblico e privato
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come attivare un evento e cosa implica
            </a>
          </li>
          <!-- li><a href="#article-5" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Eventi “content” cosa sono</a></li -->
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come creare, eliminare o archiviare un evento
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dall’homepage del Dashboard, cliccando sull’icona con il simbolo “+” è possibile creare un nuovo evento.
            <br>
            Sarà necessario selezionare se si tratta di un evento “privato” o “pubblico”.
            Indipendentemente dalla scelta per completare la creazione si dovrà riempire
            obbligatoriamente i campi: “Categoria, Nome, Data Inizio, Data Fine e Indirizzo”,
            facoltativamente i campi "Venue, Virtual Venue e Descrizione (it e en)". <br>
            Nel caso si voglia eliminare o archiviare un evento è possibile effettuarlo andando prima a
            "disattivare" l’evento (se era stato precedentemente attivato), e successivamente accedendo alla sezione
            “eventi” da dashboard e semplicemente selezionando “elimina o archivia” sull’ evento stesso.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come modificare un evento
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dalla sezione “eventi” o direttamente da dashboard si può aprire l’evento interessato e
            modificarne i dettagli (Nome, Data Inizio, Data Fine, Indirizzo) andando a selezionare
            “update” nel riquadro riportante Nome e info sull’evento (in altro a sinistra). <br>
            Nel caso si voglia modificare la locandina dell’evento successivamente alla creazione basterà
            selezionare “update” sul riquadro apposito per la locandina (in alto a destra). <br>
            Se si è interessati a modificare la categoria dell’evento bisognerà invece accedere al button
            “impostazioni” (ultimo a destra) e selezionare la voce “modifica categoria evento”.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Quale differenza c’è tra evento pubblico e privato
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Un evento privato è un evento nel quale gli organizzatori da dashboard aggiungono una lista
            di invitati e si accede/ riceve l’invito all’evento, solo se precedentemente inseriti. A differenza
            di un evento privato, quello pubblico è caratterizzato dalla possibilità degli individui di
            registrarsi autonomamente all’evento tramite "GuestApp" o pagine web, si ottiene così una lista dinamica di
            invitati che si
            riempie man mano che le persone si registrano.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come attivare un evento e cosa implica
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Ogni evento che viene generato può essere attivato o disattivato, la differenza sta
            nella visibilità dell’evento da parte degli invitati e in alcune funzioni non fruibili dall'organizzatore in
            caso di evento non attivo. Attivando l'evento sarà possibile utilizzare la sezione di Mail marketing per
            inviare
            inviti e comunicazioni agli ospiti. Inoltre nel momento in cui un evento viene
            attivato, questo sarà disponibile sulle “GuestAPP” e quindi, visibile ai soli invitati in caso di
            evento privato, a chiunque se è un evento pubblico. <br>
            Non è possibile utilizzare le funzionalità della “Check-inApp” se l’evento non è
            stato prima attivato.
          </p>
        </article>
        <!-- <article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">Eventi “content” cosa sono<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>È possibile creare eventi di categoria “content”: a differenza delle altre tipologie questi sono
caratterizzati dalla possibilità di aggiungere contenuti per i propri invitati visualizzabili da
“GuestApp”. <br>
In particolare gli user non troveranno l’opzione “join” nell’evento ma direttamente verranno
reindirizzati ai file o link che l’organizzatore ha caricato per l’evento (per aggiungere file o
link vedi sezione BIGLIETTI E ALLEGATI).</p>
        </article -->
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskEventiIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
