var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MainLayout",
    [
      _c(
        "el-container",
        { staticClass: "page-container", attrs: { direction: "vertical" } },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-lg-12" },
              [
                _c("h1", { staticStyle: { "font-weight": "300" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.recordings._page_title) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("h4", { staticStyle: { "margin-top": "10px" } }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.recordings._subtitle) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "smallseparator",
                  staticStyle: { "margin-top": "25px !important" },
                }),
                _vm._v(" "),
                _c("data-table", {
                  ref: "table",
                  attrs: {
                    "get-data": _vm.getData,
                    columns: _vm.columns,
                    "bulk-options": _vm.bulkOptions,
                    "on-bulk-action": _vm.onBulkAction,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "event",
                      fn: function (ref) {
                        var row = ref.row
                        return _c("span", {}, [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.event[0].name) +
                              "\n            "
                          ),
                        ])
                      },
                    },
                    {
                      key: "date",
                      fn: function (ref) {
                        var row = ref.row
                        return _c("span", {}, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.moment
                                  .utc(row.lifespan.start, "YYYY-MM-DD HH:mm")
                                  .format("DD/MM/YYYY")
                              ) +
                              "\n            "
                          ),
                        ])
                      },
                    },
                    {
                      key: "time",
                      fn: function (ref) {
                        var row = ref.row
                        return _c("span", {}, [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.moment
                                  .utc(row.lifespan.start, "YYYY-MM-DD HH:mm")
                                  .format("HH:mm z")
                              ) +
                              "\n            "
                          ),
                        ])
                      },
                    },
                    {
                      key: "format",
                      fn: function (ref) {
                        var row = ref.row
                        return _c("span", {}, [
                          _vm._v(
                            "\n              " +
                              _vm._s(row.videoRoom[0].type) +
                              "\n            "
                          ),
                        ])
                      },
                    },
                    {
                      key: "Actions",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _vm._l(row.urls, function (url, urlIndex) {
                              return [
                                _c(
                                  "a",
                                  {
                                    key: "url-" + urlIndex,
                                    staticClass: "delete_prospect_link",
                                    staticStyle: {
                                      "margin-right": "5px",
                                      color: "white",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openInNewTab(url)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-download" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    key: "del-url-" + urlIndex,
                                    staticClass: "delete_prospect_link",
                                    staticStyle: { color: "white" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row, url)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-trash" })]
                                ),
                              ]
                            }),
                          ],
                          2
                        )
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }