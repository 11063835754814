var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "overview" }, [
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._total))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [_vm._v("\n      " + _vm._s(_vm.stats.TOTAL) + "\n    ")]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._pending))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.PENDING) + "\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._confirmed))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.CONFIRMED) + "\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._guestsAndCompanions))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [
              _vm._v(
                "\n      " + _vm._s(_vm.stats.CONFIRMED_PLUS_ONE) + "\n    "
              ),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._checkin))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.CHECK_IN) + "\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center overview-cell" },
      [
        _c("h3", [_vm._v(_vm._s(_vm.string.overview._checkinPlus))]),
        _vm._v(" "),
        _vm.loading
          ? _c("ClipLoader", {
              attrs: { loading: _vm.loading, color: "#f7a30c", size: "15px" },
            })
          : _c("h4", [
              _vm._v("\n      " + _vm._s(_vm.stats.CHECK_IN_PLUS) + "\n    "),
            ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.stats.PREDICTION > 0
      ? _c(
          "div",
          { staticClass: "text-center overview-cell" },
          [
            _c("h3", [_vm._v(_vm._s(_vm.string.overview._prediction))]),
            _vm._v(" "),
            _vm.loading
              ? _c("ClipLoader", {
                  attrs: {
                    loading: _vm.loading,
                    color: "#f7a30c",
                    size: "15px",
                  },
                })
              : _c("h4", [
                  _vm._v("\n      " + _vm._s(_vm.stats.PREDICTION) + "\n    "),
                ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }