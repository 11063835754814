<template>
  <div class="container-fluid">
    <div v-if="!pageLoaded" id="preloader">
      <div class="pulse"></div>
    </div>
    <div class="customised-body" v-html="virtualRoomHTML"></div>
    <div id="cover-background" class="coverBackground" :style="{backgroundImage: 'url('+eventBackground+')'}"></div>
    <div id="login-modal" @click.self="closeLoginForm()">
      <div class="login-form">
        <form class="form-signin" @submit.prevent="loginResetDo()">
          <h2 v-if="selectedForm === 'login'" class="form-signin-heading">Login</h2>
          <h2 v-if="selectedForm === 'reset'" class="form-signin-heading">Reset password</h2>
          <label for="inputEmail" class="sr-only">Email</label>
          <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required="true" autofocus="true" v-model="loginForm.email">
          <p style="margin-top: 10px; color: #ffffff;" v-if="selectedForm === 'reset'">You will receive an email from mailing@penguinpass.it to set a new password.</p>
          <div v-if="selectedForm === 'login'" class="form-group password-input-container">
          <label for="inputPassword" class="sr-only">Password</label>
          <div class="input-wrapper">
              <input type="password" id="inputPassword" class="form-control" placeholder="Password" required="true" v-model="loginForm.password">
              <i class="fa password-toggle-icon" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" @click="togglePasswordVisibility" aria-hidden="true"></i>
          </div>
      </div>
          <span v-if="loginForm.errors" style="color: red;">{{loginForm.errors}}</span>
          <span v-if="loginForm.info" style="color: green;">{{loginForm.info}}</span>
          <p v-if="selectedForm === 'login'" style="text-align: right; margin: 10px 0px; cursor: pointer; color: #ffffff;" @click.self="formSwitch('reset')">Forgot your password?</p>
          <p v-if="selectedForm === 'reset'" style="text-align: right; margin: 10px 0px; cursor: pointer; color: #ffffff;" @click.self="formSwitch('login')">Back to Login</p>
          <button v-if="selectedForm === 'login'" class="btn btn-lg btn-primary btn-block" type="submit">Login</button>
          <button v-if="selectedForm === 'reset'" class="btn btn-lg btn-primary btn-block" type="submit">Reset</button>
          <p style="text-align: center;"><a target="_blank" v-if="teamId === '5e9dcc42b9fadb7790b76a28'" href="https://events.penguinpass.it/room/809c2b8374b428fe20d03f1d9949c2a067eb739b317c34ee8eb60b780b385b6e768e34de0aadbf4437359f84f91575e7dc3d66761970e4c6576262a84d74d4dc">Crea un account</a></p>
        </form>
      </div>
    </div>
    <div id="signup-modal" @click.self="closeRegisterForm()">
      <div class="signup-form">
        <form class="form-signin">
          <h2 class="form-signin-heading">Register</h2>
          <label for="inputName" class="sr-only">Name</label>
          <input type="text" id="inputName" class="form-control" placeholder="Name" required="" autofocus="" v-model="signupForm.name">
          <label for="inputSurname" class="sr-only">Surname</label>
          <input type="text" id="inputSurname" class="form-control" placeholder="Surname" required="" autofocus="" v-model="signupForm.surname">
          <label for="inputEmail" class="sr-only">Email</label>
          <input type="email" id="inputEmail" class="form-control" placeholder="Email address" required="" autofocus="" v-model="signupForm.email">
          <label for="inputPassword" class="sr-only">Password</label>
          <div class="input-wrapper">
            <input type="password" id="inputPassword" class="form-control" placeholder="Password" required="" v-model="signupForm.password">
            <i class="fa password-toggle-icon" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" @click="togglePasswordVisibility" aria-hidden="true"></i>
          </div>
          <small>{{ string.commonActions.passwordPolicy }}</small>
          <button class="btn btn-lg btn-primary btn-block" type="button" @click="registerDo()">Register</button>
        </form>
      </div>
    </div>
    <footer style="margin-top: 5px;margin-bottom: 0px; background-color: transparent;">
      <p class="footer-text text-center" style="padding: 15px">
        Powered by Penguinpass. The smartest event management system for your events. |
        <a href="https://www.penguinpass.it" target="_blank" title="Penguinpass">
          www.penguinpass.it
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
import "html5shiv/dist/html5shiv.min";
import "bootstrap/dist/js/bootstrap.min";
import "wowjs/dist/wow.min";
import "owl.carousel/dist/owl.carousel.min";
import "magnific-popup/dist/jquery.magnific-popup.min";
import "isotope-layout/dist/isotope.pkgd.min";
import "imagesloaded/imagesloaded.pkgd.min";
import "smoothscroll-for-websites/SmoothScroll";
import "jquery.easing/jquery.easing.min";
import "../assets/js/v2/theme";
import Vue from 'vue'
import GuestList from "@/components/pages/GuestList";
import RegistrationForm from "@/components/pages/RegistrationForm";
import { IS_STAGING } from '../constants';
import { isLocalhost } from '@/utils';

export default {
  components: {
    GuestList,
    RegistrationForm
  },
  props: {
    code: String
  },
  data() {
    return {
      pageLoaded: false,
      teamId: '',
      virtualRoomHTML: "",
      eventBackground: null,
      resultData: null,
      postLoginRender: false,
      loggedInUser: null,
      widgetData: {},
      selectedForm: 'login',
      loginForm: {
        errors: null,
        info: null,
        email: '',
        password: ''
      },
      signupForm: {
        name: '',
        surname: '',
        country: '',
        telephone: '',
        email: '',
        password: ''
      },
      passwordVisible: false,
    };
  },
  computed: {
    // isLoggedIn : function (){ return this.$store.getters.isLoggedIn;}
  },
  metaInfo: {
    title: "Virtual Room - Penguinpass",
    bodyAttrs: {
      class: "virtualRoomPage"
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
    const language = window.navigator.language;
    if (language === "it-IT") {
      this.$root.strings = this.$root.messages_it;
      this.lang = "it";
      this.$root.currentLanguage = "it";
    } else if (language === 'en-GB' || language === 'en-US' || language === 'en'){
      this.$root.strings = context.$root.messages_en;
      this.lang = 'en';
      this.$root.currentLanguage = 'en';
    } else if (language === 'fr-FR' || language === 'fr'){
      this.$root.strings = context.$root.messages_fr;
      this.lang = 'fr';
      this.$root.currentLanguage = 'fr';
    } else if (language === "ar-AR" || language === "ar") {
      context.$root.strings = context.$root.messages_ar;
      context.lang = "ar";
      context.$root.currentLanguage = "ar";
    } else {
      this.$root.strings = context.$root.messages_en;
      this.lang = 'en';
      this.$root.currentLanguage = 'en';
    }
    this.$root.bus.emit("setLanguage");

    window.onmessage = (e) => {
      console.log("message coming", e);
      if (e.data.hasOwnProperty("frameHeight") && e.data.hasOwnProperty("frameId")) {
        console.log("ddd", e.data);
        document.getElementById(e.data.frameId).style.height = `${e.data.frameHeight + 30}px`;
      }
    };
  },
  mounted() {
    this.loadVirtualRoom(this.code);
  },
  methods: {
    formSwitch(formName) {
      this.selectedForm = formName;
    },
    initPenguinmeetings: function(meetingId, pin) {
      const tmpScript = document.createElement("iframe");
      tmpScript.setAttribute(
        "src",
        "https://virtual.events.penguinpass.it/vr/index.html?r=" + meetingId
      );
      tmpScript.setAttribute("style", "width: 100%; height: 100vh; border: none;");
      tmpScript.setAttribute("allow", "camera; microphone; display-capture;");
      const th = setInterval(() => {
        if (document.getElementById("embedPenguinmeetings")) {
          clearInterval(th);
          document.getElementById("embedPenguinmeetings").appendChild(tmpScript);
        }
      }, 500);
    },
    initPenguinwebinar: function(meetingId, pin) {
      const tmpScript = document.createElement("iframe");
      tmpScript.setAttribute(
        "src",
        "https://virtual.events.penguinpass.it/wvr/index.html?r=" + meetingId
      );
      tmpScript.setAttribute("style", "width: 100%; height: 100vh; border: none;");
      tmpScript.setAttribute("allow", "camera; microphone; display-capture;");
      const th = setInterval(() => {
        if (document.getElementById("embedPenguinmeetings")) {
          clearInterval(th);
          document.getElementById("embedPenguinmeetings").appendChild(tmpScript);
        }
      }, 500);
    },
    initPenguinrooms: function(videoRoomIds, allocatedVideoRoomsMap) {
        for(let videoRoomId of videoRoomIds) {
          if(typeof allocatedVideoRoomsMap[videoRoomId.roomId] === 'undefined') {
            continue;
          }

          let tmpScript = document.createElement('iframe')
          const timestampNow = Date.now();
          tmpScript.setAttribute("src", `https://virtual.events.penguinpass.it/${IS_STAGING?'vr-staging':'vr3'}/index.html?v=${timestampNow}#/` + allocatedVideoRoomsMap[videoRoomId.roomId])
          tmpScript.setAttribute("style", "position: relative; width: 100%; height: 100vh; border: none;");
          tmpScript.setAttribute("allow", "camera; microphone; display-capture;");
          let th = setInterval(function(){
            if(document.getElementById('penguinroom'+videoRoomId.roomId)) {
              clearInterval(th);
              document.getElementById('penguinroom'+videoRoomId.roomId).appendChild(tmpScript)
            }
          }.bind(this), 500);
        }
      },
      initBlueJeans: function(meetingId, pin) {
        window.BJN_CLIENT = {
          content: 'bjclient',
          meetingId,
          pin,
          height: '100%'
        };
        let tmpScript = document.createElement('script')
        tmpScript.setAttribute('id', 'bjn-embed')
        tmpScript.setAttribute('src', 'https://bluejeans.com/static/js/embed.js')
        document.head.appendChild(tmpScript)
      },
      initLivewebinar: function(meetingId) {
        window._options = {
            '_license_key':meetingId,
            '_role_token':'',
            '_registration_token':'',
            '_widget_containerID':'embedWidget',
            '_widget_width':'100%',
            '_widget_height':'100vh',
        }
        !function(i){
            i.Widget=function(c){
                'function'==typeof c&&i.Widget.__cbs.push(c),
                i.Widget.initialized&&(i.Widget.__cbs.forEach(function(i){i()}),
                i.Widget.__cbs=[])
            },
            i.Widget.__cbs=[]
        }(window);
        let ab = document.createElement('script');
        ab.type = 'text/javascript';
        ab.async = true;
        ab.src = 'https://embed.livewebinar.com/em?t='+window._options['_license_key']+'&'+Object.keys(window._options).reduce(function(a,k){
                a.push(k+'='+encodeURIComponent(window._options[k]));
                return a
            },[]).join('&');

      document.head.appendChild(ab);
    },
    replaceDynamicRoomsContents: function(source, videoRoomIds) {
      let tmp = source;

      for (const videoRoomId of videoRoomIds) {
        tmp = tmp.replace(
          `{penguinroom roomId="${videoRoomId.roomId}"}`,
          `<div id="penguinroom${videoRoomId.roomId}"></div>`
        );
      }

      return tmp;
    },
    replaceDynamicContents: function(source) {
      const tmp = source;
      return tmp
        .replace("{guestlist}", '<div id="guestListWidget"></div>')
        .replace("{registrationform}", '<div id="registrationFormWidget"></div>')
        .replace("{livewebinar}", '<div id="embedWidget"></div>')
        .replace("{penguinmeetings}", '<div id="embedPenguinmeetings"></div>')
        .replace("{penguinwebinar}", '<div id="embedPenguinmeetings"></div>')
        .replace("{login}", '<div class="login-area"><span id="profile-place"></span> <span class="btn btn-block btn-primary" id="logout-link" onclick="document.cookie = &#39;guest-token-' + this.teamId + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.penguinpass.it&#39;; document.location.reload();">Logout</span> <span id="login-place"><span class="btn btn-block btn-primary login-link" onclick="document.getElementById(\'login-modal\').style.display = \'flex\';document.getElementById(\'cover-background\').style.display = \'flex\';">Login</span> <span class="btn btn-block btn-primary signup-link" onclick="document.getElementById(\'signup-modal\').style.display = \'flex\';document.getElementById(\'cover-background\').style.display = \'flex\';">Sign Up</span></span></div>')
        // .replace("{login}", '<div class="login-area"><span id="profile-place"></span> <span class="btn btn-block btn-primary" id="logout-link" onclick="document.cookie = &#39;guest-token-' + this.teamId + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.penguinpass.it&#39;; document.location.reload();">Logout</span> <span id="login-place"><span class="btn btn-block btn-primary login-link" onclick="document.getElementById(\'login-modal\').style.display = \'flex\';document.getElementById(\'cover-background\').style.display = \'flex\';">Login</span></span></div>')
    },
    getAllRooms(html) {
      let attributes = []
      let matchResult = html.match(/{penguinroom [\w]+=".+?"}/g);
      if(matchResult !== null) {
        matchResult.forEach(function(attribute) {
          attribute = attribute.match(/([\w-]+)="(.+?)"/);
          if(attribute !== null) { attributes.push({roomId: attribute[2]}); }
        });
      }
      return attributes
    },
    loadVirtualRoom: function(code) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("virtualRoomLoad", { code })
        .then(async result => {
          if (result.data && result.data.virtualRoomHTML) {
            context.resultData = result.data;
            context.widgetData = result.data.widgetData;
            context.teamId = result.data.teamId;
            context.eventBackground = result.data.eventCover;

            if(result.data.authNeeded) {
              document.getElementById('cover-background').style.display = 'flex';
              document.getElementById('login-modal').style.display = 'flex';
              context.postLoginRender = true;
              context.pageLoaded = true;
            }

            await context.loginWithToken();

            if(context.getCookie('guest-token-' + context.teamId)) {
              context.getGuestProfile();
            }

            if(!context.postLoginRender) {
              context.renderPage();
            }
          } else {
            this.$router.push("/404").catch(error => {});
          }
        })
        .catch(error => {
          console.log("error", error);
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000
            });
          }
        });
    },
    renderPage() {
      let livewebinarAdded = false;
      let penguinmeetingsAdded = false;
      let penguinwebinarAdded = false;
      let penguinDynamicRoomsAdded = false;
      let guestListWidgetAdded = false;
      let registrationFormWidgetAdded = false;

      if (
        this.resultData.virtualRoomHTML.indexOf("{livewebinar}") > -1 &&
        this.resultData.virtualRoomId
      ) {
        livewebinarAdded = true;
      }
      if (
        this.resultData.virtualRoomHTML.indexOf("{penguinmeetings}") > -1 &&
        this.resultData.eventId
      ) {
        penguinmeetingsAdded = true;
      }
      if (
        this.resultData.virtualRoomHTML.indexOf("{penguinwebinar}") > -1 &&
        this.resultData.eventId
      ) {
        penguinwebinarAdded = true;
      }
      if (this.resultData.virtualRoomHTML.indexOf("{penguinroom") > -1) {
        penguinDynamicRoomsAdded = true;
      }
      if (
        this.resultData.virtualRoomHTML.indexOf("{guestlist}") > -1 &&
        this.widgetData['guestlist']
      ) {
        guestListWidgetAdded = true;
      }
      if (
        this.resultData.virtualRoomHTML.indexOf("{registrationform}") > -1 &&
        this.widgetData['registrationform']
      ) {
        registrationFormWidgetAdded = true;
      }

      this.virtualRoomHTML = this.replaceDynamicContents(this.resultData.virtualRoomHTML);

      //dynamic virtual rooms
      if (penguinDynamicRoomsAdded) {
        const dynamicRoomIds = this.getAllRooms(this.virtualRoomHTML);
        this.virtualRoomHTML = this.replaceDynamicRoomsContents(
          this.virtualRoomHTML,
          dynamicRoomIds
        );
        this.initPenguinrooms(dynamicRoomIds, this.resultData.allocatedVideoRoomsMap);
      }

      if (livewebinarAdded) {
        this.initLivewebinar(this.resultData.virtualRoomId);
      }
      if (penguinmeetingsAdded) {
        this.initPenguinmeetings(this.resultData.eventId);
      }
      if (penguinwebinarAdded) {
        this.initPenguinwebinar(this.resultData.eventId);
      }
      if (guestListWidgetAdded) {
        setTimeout(() => {
          const ComponentClass = Vue.extend(GuestList);
          const instance = new ComponentClass({
              propsData: { data: this.widgetData['guestlist'] }
          });
          instance.$mount(); // pass nothing
          document.getElementById('guestListWidget').appendChild(instance.$el);
        }, 0);
      }
      if (registrationFormWidgetAdded) {
        setTimeout(() => {
          const ComponentClass = Vue.extend(RegistrationForm);
          const instance = new ComponentClass({
              propsData: { data: this.widgetData['registrationform'], string: this.string, teamId: this.teamId, eventId: this.resultData.eventId, noPassword: this.resultData.noPassword, store: this.$store, root: this.$root, customThankYou: this.resultData.customThankYou, hideLoginBar: this.resultData.hideLoginBar, hasOptionalEmail: this.resultData.hasOptionalEmail,}
          });
          instance.$mount(); // pass nothing
          document.getElementById('registrationFormWidget').appendChild(instance.$el);
        }, 0);
      }

      //execute scripts
      // setTimeout(() => {
      //   let el = document.createElement( 'html' );
      //   el.innerHTML = this.virtualRoomHTML;
      //   let scripTags = el.getElementsByTagName( 'script' );
      //   for(let scriptTag of scripTags) {
      //     eval(scriptTag.innerHTML);
      //   }
      // }, 0);

      document.getElementById('login-modal').style.display = 'none';
      document.getElementById('cover-background').style.display = 'none';

      if (this.loggedInUser) {
        setTimeout(() => {
          if (document.getElementById('profile-place')) { document.getElementById('profile-place').innerHTML = this.loggedInUser.name + ' ' + this.loggedInUser.surname; }
          if (document.getElementById('login-place')) { document.getElementById('login-place').style.display = 'none'; }
          if (document.getElementById('logout-link')) { document.getElementById('logout-link').style.display = 'inline'; }          
        }, 0);
      }

      this.pageLoaded = true;
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      const expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + (isLocalhost() ? "" : "domain=.penguinpass.it");
    },
    getCookie(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    closeLoginForm() {
      if (!this.postLoginRender) {
        document.getElementById('login-modal').style.display = 'none';
        document.getElementById('cover-background').style.display = 'none';
      }
    },
    closeRegisterForm() {
      document.getElementById('signup-modal').style.display = 'none';
      document.getElementById('cover-background').style.display = 'none';
    },
    loginResetDo() {
      const context = this;

      if (this.selectedForm === 'login') {
       if (this.loginForm.email === '' || this.loginForm.password === '') {
          context.loginForm.errors = "Please fill all fields.";
          context.loginForm.info = null;
          return;
        }

        this.$store
        .dispatch("guestLogin", { teamId: this.teamId, source: document.location.href, ...this.loginForm })
        .then(result => {
          if (result.status && result.status === 'SUCCESS') {
            if (result.message === 'VERIFY_ACCOUNT') {
              context.loginForm.errors = "Account not verified, please check your inbox to verify you account.";
              context.loginForm.info = null;
            } else {
              context.setCookie("guest-token-" + context.teamId, result.data, 30);
              document.getElementById('login-modal').style.display = 'none';
              document.getElementById('cover-background').style.display = 'none';
              context.getGuestProfile();
            }
          } else {
            context.loginForm.errors = "Wrong email or password.";
            context.loginForm.info = null;
          }
        });
      }
      if (this.selectedForm === 'reset') {
       if (this.loginForm.email === '') {
          context.loginForm.errors = "Please fill the email field.";
          context.loginForm.info = null;
          return;
        }

        this.$store
        .dispatch("guestResetPassword", { teamId: this.teamId, ...this.loginForm })
        .then(result => {
          console.log("result", result)
          if (result.status && result.status === 'SUCCESS') {
            context.loginForm.info = "We have sent an email to reset your password.";
            context.formSwitch('login');
          } else {
            if (result.message && result.message === 'NOT_FOUND') {
              context.loginForm.errors = "Email not found.";
            } else {
              context.loginForm.errors = "Error in resetting your password, please contact the help support.";
            }
          }
        });
      }
    },
    registerDo() {
      const context = this;
      this.$store
      .dispatch("guestRegister", { teamId: this.teamId, ...this.signupForm, lang: context.lang })
      .then(result => {
        console.log("register result", result);
      })
      .catch(error => {
        if (error.data && error.data.message){
          if (error.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
            context.$notify({
              title: 'Oops !',
              message: context.string.commonActions.passwordPolicy,
              position: 'bottom-right',
              type: 'error',
              duration: 3000,
            });
          } else {
            context.$notify({
              title: 'Oops !',
              message: error.data.message,
              position: 'bottom-right',
              type: 'error',
              duration: 3000,
            });
          }
        }
      });
    },
    getGuestProfile() {
      const context = this;
      this.$store
      .dispatch("guestProfile", { teamId: this.teamId, source: document.location.href, token: this.getCookie('guest-token-' + this.teamId) })
      .then(result => {
        if (result === "Unauthorized") {
          document.cookie = `guest-token-${this.teamId}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${isLocalhost() ? "" : "domain=.penguinpass.it"}`;
          document.location.reload();
          return;
        }

        if (result.status && result.status === 'SUCCESS') {
          context.$root.bus.emit("guestProfile", result.data);

          context.loggedInUser = result.data;
          if (context.postLoginRender) {
            context.renderPage();
          } else {
            if (document.getElementById('profile-place')) {
              document.getElementById('profile-place').innerHTML = result.data.name + ' ' + result.data.surname;
            }
            if (document.getElementById('login-place')) {
              document.getElementById('login-place').style.display = 'none';
            }
            if (document.getElementById('logout-link')) {
              document.getElementById('logout-link').style.display = 'inline';
            }
          }
        }
      }).catch(result => {
        console.log("error", result)
      });
    },
    async loginWithToken() {
      const context = this;

      if (this.$route.query && this.$route.query.t) {
        return await this.$store
        .dispatch("guestLogin", { teamId: this.teamId, source: document.location.href, token: this.$route.query.t })
        .then(result => {
          if (result.status && result.status === 'SUCCESS') {
            context.setCookie("guest-token-" + context.teamId, result.data, 30);
            document.getElementById('login-modal').style.display = 'none';
            document.getElementById('cover-background').style.display = 'none';
            return result;
          } else {
            context.loginForm.errors = "Wrong email or password.";
            context.loginForm.info = null;
            return null;
          }
        });
      }
      return null;
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
      const passwordField = document.getElementById('inputPassword');
      if (this.passwordVisible) {
        passwordField.type = 'text';
      } else {
        passwordField.type = 'password';
      }
    },
  }
};
</script>
<style>
.customised-body {
  min-height: 100vh;
}
.bee-row-content {
  max-width: none !important;
}
.fullwidthOnMobile {
  max-width: none !important;
}
.bee-block ul li {
  list-style: initial;
}
.bee-row-content .bee-col-w3 {
  flex: 3
}
.bee-row-content .bee-col-w9 {
  flex: 9
}
.bee-row-content .bee-col-w12 {
  flex: 12
}
.bee-col {
  padding: 0 !important;
}
.virtualRoomPage {
  background-color: #ffffff !important;
}
#logout-link {
  display: none;
}
#profile-place {
  text-transform: capitalize;
  padding-right: 10px;
}
.login-area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.login-area .btn-primary {
  color: #fff!important;
  background-color: #000!important;
  border-color: #000!important;
  border: 0;
  padding: 10px 10px;
  width: 100px;
  border-radius: 5px;
}
.signup-link {
  /* display: none !important; */
}
</style>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../assets/css/v2/style.css";
@import "../assets/css/v2/custom.css";
@import "../assets/css/v2/join.css";
.coverBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: blur(5px);
  z-index: 10;
}
.footer-text {
  color: #555555;
  font-size: 13px;
}
.footer-text a {
  text-decoration: none;
}
#login-modal, #signup-modal {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
#login-modal .login-form, #signup-modal .signup-form {
  width: 400px;
  min-height: 330px;
  background: rgba(255, 255, 255, 0.2);
  padding: 15px;
  border-radius: 15px;
}
#login-modal .login-form .btn-primary, #signup-modal .signup-form .btn-primary {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
#login-modal .login-form input, #signup-modal .signup-form input {
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: #000000 !important;
}
#login-modal .login-form input::placeholder, #signup-modal .signup-form input::placeholder {
  color: #555555;
}
.form-signin {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.form-signin-heading {
  color: #ffffff;
  text-align: center;
}
.input-wrapper {
    position: relative;
}
.password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #000000; 
    opacity: 0.5; 
    transition: color 0.3s ease, opacity 0.3s ease;
}
.password-toggle-icon:hover {
    color: #000000;
    opacity: 1; 
}
</style>
