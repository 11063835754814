<template>
  <MainLayout>
    <TeamMembersManager :group="group"></TeamMembersManager>
  </MainLayout>
</template>

<script>
import MainLayout from '../../layouts/Main.vue';
import TeamMembersManager from '../../containers/team/TeamMembersManager.vue';
import { subject } from '@casl/ability';
import store from "../../store";
import ability from "../../store/ability";
export default {
    components: {
        MainLayout,
        TeamMembersManager
    },
    created (){

        //
    },
    methods: {
        //
    },
    computed: {
      group () {
        return this.$store.getters.team;
      }
    },
    beforeRouteEnter(to,from,next) {
      if(store.getters.features && store.getters.features.teamMembers && store.getters.ability.can('access', subject('team_members_page',{...store.getters.team}))) {
        // console.log('[TeamMembers:beforeRouteEnter] Welcome to Team Members')
        next()
      }else {
        // console.log('[TeamMembers:beforeRouteEnter] Access denied to Team Members')
        next('/');
      }
    },
    metaInfo: {
      title: 'Penguinpass - Team',
      bodyAttrs: {
          class: 'dashboard'
      }
    },
};
</script>
