var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "create-policy-component" }, [
    _c("div", { staticClass: "component-subtitle" }, [
      _vm._v("\n    " + _vm._s(_vm.string.createPolicy._subtitle) + "\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "component-form" }, [
      _c("div", { staticClass: "form-section name" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.policyName,
              expression: "policyName",
            },
          ],
          staticClass: "form-control",
          staticStyle: { width: "100%" },
          attrs: {
            "aria-label": "",
            id: "policyNameInput",
            type: "text",
            name: "policy_name",
            placeholder: _vm.string.createPolicy._policyName,
          },
          domProps: { value: _vm.policyName },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.policyName = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-section-editor editor" }, [
        _c("label", [
          _vm._v(_vm._s(_vm.string.createPolicy._policyText) + " *"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "editor-container" } },
          [
            _c("quill-editor", {
              ref: "messageEditor",
              staticClass: "quillEditor_style",
              attrs: { config: _vm.editorOption },
              on: {
                blur: function ($event) {
                  return _vm.onEditorBlur($event)
                },
                focus: function ($event) {
                  return _vm.onEditorFocus($event)
                },
                ready: function ($event) {
                  return _vm.onEditorReady($event)
                },
                change: function ($event) {
                  return _vm.onEditorChange($event)
                },
              },
              model: {
                value: _vm.policyContent,
                callback: function ($$v) {
                  _vm.policyContent = $$v
                },
                expression: "policyContent",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-section submit" }, [
        _c("input", {
          staticClass: "save",
          attrs: {
            type: "button",
            name: "submit",
            value: _vm.string.createPolicy._createPolicy,
          },
          on: { click: _vm.createPolicy },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }