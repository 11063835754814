var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v("\n    " + _vm._s(_vm.string.categoryManager._title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container accordion-content" },
        [
          _c("small", [_vm._v(_vm._s(_vm.string.categoryManager._subtitle))]),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18, offset: 3 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "aria-label": "",
                        "value-key": "key",
                        placeholder: "Category",
                        "popper-class": "default-popper",
                        size: "large",
                      },
                      model: {
                        value: _vm.customCategoryObj,
                        callback: function ($$v) {
                          _vm.customCategoryObj = $$v
                        },
                        expression: "customCategoryObj",
                      },
                    },
                    _vm._l(_vm.customCategories, function (category) {
                      return _c("el-option", {
                        key: category.key,
                        staticClass: "optionStyle",
                        attrs: {
                          hidden: !!category.deletedAt,
                          label: category.label,
                          value: category,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 18, offset: 3 } }, [
                _c(
                  "a",
                  {
                    staticClass: "btn gray-btn save",
                    on: {
                      click: function (e) {
                        e.preventDefault()
                        _vm.submit()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.string.categoryManager._submit) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }