var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n        Email Campaign\n      "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/en-home" } }, [
                _vm._v("\n            Support\n          "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to create a new email and / or template\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to send an email campaign \n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to filter the receivers of your email campaign \n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to activate the RSVP and DECLINE buttons\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to generate a QR when a guest RSVPs\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to request additional information from your guests\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-7")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to set up timeslots that can be chosen by your guests through the invitation email\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-8")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            How to keep the risk of spam low\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to create a new email and / or template\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          How to send an email campaign \n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to filter the receivers of your email campaign \n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to activate the RSVP and DECLINE buttons\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to generate a QR when a guest RSVPs\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to request additional information from your guests\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(6),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-7",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          How to set up timeslots that can be chosen by your guests through the invitation email\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(7),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-8",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          ​​How to keep the risk of spam low\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(8),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/en-campagneemail" } }, [
        _vm._v("\n            Email Campaign\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          In the "email" section click on the "create new email" button, you will be able to access the section where you must:\n          '
      ),
      _c("br"),
      _vm._v(
        '\n          - Fill in the fields "name (sender), email (sender)", these will be visible in the emails that the guests will receive.'
      ),
      _c("br"),
      _vm._v(
        '\n          - Create the email by clicking on the "create mail" button (or "edit email" if one has already been saved previously) '
      ),
      _c("br"),
      _vm._v(
        '\n          Through the EMAIL BUILDER, you will be able to create your own personalized invitation by inserting modules and content. \n          Once built, a template can also be saved for future use (by clicking on "Actions" and then "save as template"). After \n          creating the invitation and after clicking on "save" you will need to enter the subject of the email that your guests will receive.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          After having finished the organization phase of the event, it’s now time to invite your guests. To do this, \n          it’s sufficient to enter the “email” section, create the email to send to all guests through the button \n          “create email” and then save it. Once this is done, the dashboard will show a preview of the email which \n          will be sent. Under the preview, there will be 2 checkboxes that, if activated, will allow the receiver of \n          the email to insert the number of plus 1s and their info. "
      ),
      _c("br"),
      _vm._v(
        "\n          To send the email campaign, it will be necessary to select through the filters which guests you would like \n          to send the email to (for example all guests, only those who have confirmed, only those who still haven’t \n          received an invitation, etc…). Once selected the guests for the email campaign, press the “save changes'' \n          button and after the “send campaign” button."
      ),
      _c("br"),
      _vm._v(
        "\n          Once the campaign is sent, it will be possible to download a report of the status of the emails (check out GUEST LISTS, \n          “How to download RSVP and check-in reports”).\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          Before sending the email campaign by clicking on "send email" you can filter the recipients by:'
      ),
      _c("br"),
      _vm._v(
        '\n          - Selecting "pending guests" to send the invitation only to those who have not confirmed or declined'
      ),
      _c("br"),
      _vm._v(
        '\n          - Selecting "confirmed guests" to select those who have already accepted the invitation'
      ),
      _c("br"),
      _vm._v(
        '\n          - Selecting "guests who have not read the previous campaigns" for those who have received past emails \n          but have not opened them'
      ),
      _c("br"),
      _vm._v(
        "\n          - Selecting “guests not included in previous campaigns” to select those who have not yet received any previous email"
      ),
      _c("br"),
      _vm._v(
        '\n          - Selecting "use custom fields" to filter guests based on the extra fields in the list (for example Company)\n          '
      ),
      _c("br"),
      _vm._v(
        '\n          In addition to the filters, you can manually select the guests that need to receive the email campaign by using the check-boxes \n          on the right in each row of the guestlist.\n          To use the “guests who have not read the previous campaigns" filter, it is necessary to wait for the "mail status" to be updated. \n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          In order to create a correct email and giving the possibility for your guests to confirm their presence, you will \n          have to use the appropriate buttons and their "Special links”. '
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n          This can be done in two ways:"),
      _c("br"),
      _vm._v("\n          USING BUTTONS"),
      _c("br"),
      _vm._v(
        '\n          The guest will receive an email with two buttons, one for accepting the inviate and one to declining it. To do this you need to insert in the email then two buttons dragging the "button content" into an empty "form". Once the button is inserted it must be selected and then you must click on the "special links" item and choose in one of the two buttons the "RSVP" link (so when the guest presses on it he will confirm his presence) and on the other one the "decline" link (by clicking on it the guest will indicate his refusal).\n          '
      ),
      _c("br"),
      _c("br"),
      _vm._v("\n          USING A SPECIAL LINK"),
      _c("br"),
      _vm._v(
        '\n          If you want your guests to confirm their presence by pressing on a particular portion of text, just place in the mail a "text content" in an empty "form". Choose the part of text to be used as a confirmation link for RSVP or DECLINE, selecti it and press on "special links" in the bar word work (which opens when editing and / or interacts with the body of the text) and finally select whether to connect an RSVP (by clicking on the guest will confirm his presence) or a decline (clicking on it the guest will indicate his refusal).\n          '
      ),
      _c("br"),
      _vm._v(
        '\n          In the phase of adding a special link, if a specific part of the text is chosen to be the special link to rsvp, the selected text will itself become the link (eg, if I highlight the word "I accept" and I connect the link, it will become "accepted").\n          '
      ),
      _c("br"),
      _vm._v(
        '\n          If instead you do not highlight a particular piece of text and select the special link then the system will automatically add the link “SpecialLink to send a RSVP (do not modify the url) "on which the guests must then click.\n          '
      ),
      _c("br"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          If you want to manage guest access to the event through a QR code, you will need to send a confirmation \n          email containing the QR Code needed to access the event. When creating a confirmation email, in the "content" \n          section of the email builder, along with everything else you want to include in the email (for example info \n          and event recap), add a "dynamic content" in an empty module.'
      ),
      _c("br"),
      _vm._v(
        "\n          In doing so, the guests will receive, after 3-10 minutes from the moment of confirmation, an email containing \n          the QR Code.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          To request additional information from your guests, you can use the custom fields, by going to the settings \n          tab in the event section. In event settings, you will see the "Change Custom Fields" section at the bottom \n          of the list. There you will be able to see the extra fields you have inserted on the guestlist template when \n          uploading your guest list and you will also have the possibility to create new ones. \n          Under the existing custom fields, you will see three checkboxes, by checking the box next to "RSVP" your \n          guests will be able to fill in the additional data directly from the invitation email when clicking on the \n          button containing the special RSVP link.\n          '
      ),
      _c("br"),
      _vm._v(
        '\n          By selecting the “Mandatory” box next to "RSVP "you can also make it a mandatory field. The information you fill \n          in will automatically appear in your event\'s guest list.'
      ),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n          You can find "Time slots" in the settings tab of your event. By clicking on it, it will allow \n          you to upload .ics files to generate time slots that will then be selected by your guests from the RSVP email.'
      ),
      _c("br"),
      _vm._v(
        "\n          .ics files are standard format files for calendars, which can be exported from applications such as Google calendar \n          or Yahoo calendar. After uploading the file, a table will appear with all the time slots present in the file. Above the \n          table, you can set the maximum limit of people who will be able to select every single slot."
      ),
      _c("br"),
      _vm._v(
        '\n          Your guests will find the slots after clicking the RSVP button in the invitation email, on the additional information \n          page, you will find the time slots selected by the different guests from the columns of your guest list. Finally, you \n          can download the .ics file with all the bookings, from the "Export your bookings as .ics" button that you will find in the \n          time slots section.\n        '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Here are some tips to reduce the risk of your email campaigns being classified as spam by mail servers. First let's \n          start with the ranking, which is one of the parameters of the various mail programs and servers to manage messages. \n          Luckily, Penguinpass has a score of 89 out of 100 which is very high. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          1. If email servers detect words like events, save the date, rsvp, etc. i.e. they can automatically classify the \n          mails as "promotions" and insert them in the relative folder on Gmail. Therefore we recommend avoiding using such \n          words in the subject of your campaign.'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          2. We recommend that the email address (the sender) does not have words such as events, marketing, etc. for example \n          events@client.com."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          3.  If you can, we recommend that you verify your domain instead of the single email address."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          4. Try to ensure that the emails sent do not have only an image (the invitation) but also not only "Dear name + surname" as \n          text. It is very important to use text and images in a balanced way since email softwares use algorithms to classify emails \n          and use the relationship between text and images as a criterion. Some text is needed. The higher the proportion of text, the \n          lower the risk of your campaign being classified as "spam" or "promotion".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          5.  Image weight: this is a very important factor. A lower image weight allows for a faster recognition by email servers and \n          is less likely to be detected as spam. Ideally, you would include in your campaign images/invitations between 50kb and 150kb."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        '\n\n          6. It is advisable to write in lowercase, or at least not all in capital letters. Writing in block letters in the subject \n          increases by 10% the probability of being classified as "spam".'
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n\n          7. One last suggestion is to send a separate email (not with Penguinpass) to those who haven’t opened or RSVP, which in addition \n          to being a reminder for the event, also invites them to check spam or other folders for any e-mails received from you. Once the user, \n          at your request, has moved the email away from the spam/promotion folder, the mail system understands that the next time the email \n          should not end up in those folders.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }