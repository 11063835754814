<template>
  <div class="empty-view-container">
    <div v-if="$slots.emptyView">
      <slot name="emptyView"></slot>
    </div>
    <p v-else class="empty-view-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Really no data",
    }
  },
};
</script>

<style scoped>
.empty-view-container {
  width: 100%;
  height: 100%;
  outline: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  min-height: 80px;
}
.empty-view-text {
  color: #E5E5E5 !important;
  font-size: 18px !important;
  margin: 0 auto;
  text-align: center;

}
</style>