var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.app_settings.app_name === "gobella"
    ? _c("div", { staticClass: "gobella-layout" }, [
        _c("div", { staticClass: "data-grid-container" }, [
          _c("table", [
            _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.columns, function (key) {
                    return _c(
                      "th",
                      {
                        class: { active: _vm.sortKey == key },
                        on: {
                          click: function ($event) {
                            return _vm.sortBy(key)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("capitalize")(key)) +
                            "\n              "
                        ),
                        _c("span", {
                          staticClass: "arrow",
                          class: _vm.sortOrders[key] > 0 ? "asc" : "dsc",
                        }),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("th", [_vm._v(" ")]),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.filteredData, function (entry) {
                return _c(
                  "tr",
                  { staticClass: "tableRowItem" },
                  [
                    _vm._l(_vm.columns, function (key) {
                      return _c("td", {
                        domProps: { innerHTML: _vm._s(_vm.getKey(entry, key)) },
                      })
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "delete_tickets",
                          attrs: { "data-placement": "top", title: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(entry["id"])
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash-o" }, [
                            _vm._v("\n \n                "),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
      ])
    : _c("div", { staticClass: "default-layout" }, [
        _c("div", { staticClass: "data-grid-container" }, [
          _c("table", [
            _c("thead", [
              _c(
                "tr",
                [
                  _vm._l(_vm.columns, function (key) {
                    return _c(
                      "th",
                      {
                        class: { active: _vm.sortKey == key },
                        on: {
                          click: function ($event) {
                            return _vm.sortBy(key)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("capitalize")(key)) +
                            "\n              "
                        ),
                        _c("span", {
                          staticClass: "arrow",
                          class: _vm.sortOrders[key] > 0 ? "asc" : "dsc",
                        }),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c("th", [_vm._v(" ")]),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.filteredData, function (entry) {
                return _c(
                  "tr",
                  { staticClass: "tableRowItem" },
                  [
                    _vm._l(_vm.columns, function (key) {
                      return _c("td", {
                        domProps: { innerHTML: _vm._s(_vm.getKey(entry, key)) },
                      })
                    }),
                    _vm._v(" "),
                    _c("td", { staticClass: "actions" }, [
                      _c(
                        "a",
                        {
                          staticClass: "delete_tickets",
                          attrs: { "data-placement": "top", title: "" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(entry["id"])
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash-o" }, [
                            _vm._v("\n \n                "),
                          ]),
                        ]
                      ),
                    ]),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }