var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-image",
      on: {
        mouseover: _vm.mouseOver,
        mouseout: _vm.mouseOut,
        mousedown: _vm.mouseDown,
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: {
                  "loading-cover-msg": _vm.string.coverImage._loadingMessage,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.readOnly
        ? _c(
            "div",
            { staticClass: "updateButtonContainer" },
            [
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _vm.showUpdateButton === true
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-labeled btn-info",
                          staticStyle: { float: "right !important" },
                          attrs: { type: "button" },
                        },
                        [
                          _c("span", { staticClass: "btn-label" }, [
                            _c("i", {
                              staticClass: "fa fa-pencil",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                          _vm._v("\n        Update\n      "),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _vm.showUpdateButton === true
                    ? _c("div", { staticClass: "updateButtonContainerBg" }, [
                        _c("div", { staticClass: "imgNotices" }, [
                          _vm._v(_vm._s(_vm.string.coverImage._imgNotices)),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "img-responsive thumbnail cover-image-constraints",
        attrs: {
          src:
            _vm.srcImage ||
            "https://cdn.pixabay.com/photo/2021/08/02/20/35/architecture-6517841_1280.jpg",
          alt: "Event cover Image",
        },
      }),
      _vm._v(" "),
      _c("input", {
        ref: "fileinput",
        attrs: { id: "fileinput", type: "file", accept: "image/*" },
        on: { change: _vm.onFileChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }