<template>
  <PublicLayout>
      <div class="container">
        <div class="row wow fadeIn">
          <!-- Left column -->
          <div class="col-md-7">
            <!-- Begin heading -->
            <div class="heading heading-md heading-uppercase heading-hover">
              <br>
              <h2
                      id="title"
                      class="heading-title text-white">
                {{ string.loginPage._login_title }}
              </h2>

              <!-- Begin divider -->
              <hr class="hr-width-1 hr-5x border-main">
              <!-- End divider -->

              <p
                      id="description"
                      class="heading-tescription lead text-gray-2">
                {{ string.loginPage._login_description }}
              </p>
            </div>
            <!-- End heading -->
          </div> <!-- /.col -->

          <!-- Right column -->
          <div class="col-md-5 text-gray-2 padding-left-40">
            <h5 class="margin-top-40 margin-bottom-20"/>
          </div> <!-- /.col -->
        </div> <!-- /.row -->

        <div class="row">
          <div class="col-md-8" style="min-height: 230px;">
            <!-- <Timeline
                    id="penguinpass"
                    source-type="profile"
                    :options="{ theme: 'dark', width:'500', height:'200' }">
                <ClipLoader
                :loading="true"
                color="#f7a30c"
                size="15px" style="width: 20%;">
                </ClipLoader>
            </Timeline> -->
          </div>
        </div>

        <div class="row">
          <!-- Begin contact form -->
          <div
                  id="contact-form"
                  class="margin-top-80 text-white wow fadeIn"
                  data-wow-delay="0.3s">
            <div v-on:submit.prevent="()=> {}"
                    class="col-lg-12"
                    style="margin: 2px;padding-left: 0;">
              <div class="col-lg-4">
                <div class="form-group">
                  <label
                          for="email"
                          class="sr-only">
                    {{ string.loginPage._email_hint }}
                  </label>
                  <input
                          id="email"
                          v-model="user.email"
                          type="email"
                          class="form-control no-rounded"
                          name="email"
                          value=""
                          placeholder="Email Address"
                          required
                          v-on:keyup.enter=" (e) => { e.preventDefault(); login();}" />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group input-container">
                  <label for="password" class="sr-only">
                    {{ string.loginPage._password_hint }}
                  </label>
                  <input
                    id="password"
                    v-model="user.password"
                    type="password"
                    class="form-control no-rounded"
                    name="password"
                    placeholder="Password"
                    required
                    v-on:keyup.enter=" (e) => { e.preventDefault(); login();}" /> 
                    <i 
                    class="fa password-toggle-icon" 
                    :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'" 
                    @click="togglePasswordVisibility" 
                    aria-hidden="true">
                  </i>
                </div>
              </div>
              <div class="col-lg-4">
                <p
                        class="text-policy"
                        style="font-size: 14px; margin-top: 2px;">
                  {{ string.loginPage._password_recovery }}
                  <router-link
                          tag="a"
                          :to="{path: '/forgot-password'}"
                          title="click here">
                    {{ string.loginPage._click_here }}
                  </router-link>
                </p>
              </div>
            </div>
            <div class="col-lg-12" style="margin-bottom: 5rem">
              <button
                      type="submit"
                      class="btn btn-primary btn-block btn-warning active btn-lg"
                      v-on:click=" (e) => { e.preventDefault(); this.login();}"
                      v-on:keyup.enter=" (e) => { e.preventDefault(); login();}">
                {{ string.loginPage._submit }}
              </button>
              <!--<label
                      style="font-size: 12px;"
                      for="rememberMe">
                {{ string.loginPage._remember_me }}
              </label>
              <input
                      id="rememberMe"
                      v-model="user.remember_me"
                      type="checkbox">-->
              <label class="control control-checkbox">
                <label
                      style="font-size: 12px;color:#fff !important;"
                      for="rememberMe">
                     {{ string.loginPage._remember_me }}
                  </label>
                  <input
                      id="rememberMe"
                      v-model="user.remember_me"
                      type="checkbox">
                  <div class="control_indicator" style="top: 5px;"></div>
              </label>
            </div>
            <div class="col-lg-12" style="margin-bottom: 5rem">
              <button
                type="submit"
                class="btn btn-primary btn-block btn-warning active btn-lg"
                v-on:click=" (e) => { e.preventDefault(); handleSSO()}">
                {{ !sso || (domain.length < 2) ? string.loginPage._sso : string.loginPage._confirm}}
              </button>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                  <label
                          for="password"
                          class="sr-only">
                    {{ string.loginPage._password_hint }}
                  </label>
                  <input
                  v-if="sso"
                    id="domain"
                    v-model="domain"
                    type="text"
                    class="form-control no-rounded"
                    name="domain"
                    placeholder="domain"
                    required
                    />
                </div>
              </div>
            <br />
            <br />
          </div>
          <!-- End contact form -->
        </div> <!-- /.container -->
      </div>
   </PublicLayout>
</template>

<script>

  import { Timeline } from 'vue-tweet-embed';
  import { onLogin } from '@/vue-apollo';
  import PublicLayout from '../layouts/PublicLayout';
  import { whoAmI } from '../services';
  import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
  export default {
    components: {
      PublicLayout,
      Timeline,
      ClipLoader
    },
    data (){
      return {
        user: {
          email: '',
          password: '',
          remember_me: false,

        },
        sso: false,
        domain: '',
        string: {},
        emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
        passwordVisible: false
      };

    },
    computed : {
    },
    metaInfo: {
      title: 'Login - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        }, this);
    },
    mounted () {
    },
    methods: {
      login: function () {
        const loginContext = this;
        if (this.isDataValid()) {
          this.$store.dispatch('login', this.user)
                  .then(async (token) => {
                    await onLogin(loginContext.$apollo.getClient(), token);
                    await whoAmI(loginContext.$store,loginContext.$apolloProvider);
                    await loginContext.$router.push('/').catch(err => {});
                  })
                  .catch( async error => {
                    if(error.data && error.data.status && error.data.status === 'PASSWORD_EXPIRED'){
                      await loginContext.$router.push('/password-reset?a=update&t=' + error.data.token).catch(err => {});
                    }
                    if(error.data && error.data.status && error.data.status === 'FAILED'){
                      loginContext.$notify({
                        title: 'Oops !',
                        message: error.data.message,
                        position: 'bottom-right',
                        type: 'error',
                        duration: 3000,
                      });
                    }
                  });
        }
      },
      isDataValid: function () {
        const context = this;
        if(this.user.password === '') {
          context.$notify({
            title: 'Oops !',
              message: this.string.loginPage._password_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if(this.user.email === '' || !this.emailRegex.test(this.user.email)) {
          context.$notify({
            title: 'Oops !',
            message: this.string.loginPage._email_not_valid,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        return true;
      },
      handleSSO: function () {
        if(!this.sso){
          this.sso = true;
          return;
        }
        if(this.domain === 'icc'){
          window.location.replace(process.env.BASE_URL + '/auth/sso/login');
        } else {
          this.$notify({
            title: 'Oops !',
            message: 'Please insert a valid domain',
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return;
        }
      },
      togglePasswordVisibility() {
        this.passwordVisible = !this.passwordVisible;
        const passwordField = document.getElementById('password');
        if (this.passwordVisible) {
          passwordField.type = 'text';
        } else {
          passwordField.type = 'password';
        }
      },
    }
  };

</script>

<style>
  .input-container {
    position: relative;
  }

  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #FFFFFF; 
    opacity: 0.5; 
    transition: color 0.3s ease, opacity 0.3s ease;
  }

  .password-toggle-icon:hover {
    color: #FFFFFF;
    opacity: 1; 
  }
</style>