<template>
  <!-- <aside> -->
      <ul
        id="nav-accordion"
        class="sidebar-menu" :style="'height: ' + height +'px;'">
        <!-- Dashboard -->
        <li :class="'avatar-row ' + (activePath === 'settings' ? ' active': '')">
          <router-link
            :to="{ path: '/settings'}"
            replace>
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg sidebar-avatar-icon-bg "
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'user-circle']"
                :style="{ color: icon.iconForeground }" :size="icon.avatarIconSize"
                transform="shrink-4 down-1 left-1"
              >
              </font-awesome-icon>
            </font-awesome-layers>
              <span>
                {{ user && user.name ? user.name : '' }}
              </span>
          </router-link>
        </li>
        <li>
          <a
            title=""
            @click.prevent="onLogout"
          >
          <font-awesome-layers class="fa-lg">
            <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
            <font-awesome-icon
              :icon="['far', 'moon']"
              :style="{ color: icon.iconForeground }" :size="icon.iconSize"
              :transform="icon.shrink + ' rotate--25 ' + icon.down"
            >
            </font-awesome-icon>
          </font-awesome-layers>
            <span>{{ $root.strings.header._logout }}</span>
          </a>
        </li>
        <li><div class="menu-divider"></div></li>
        <li :class="activePath ==='home' ? ' active': ''">
          <router-link
            :to="{ path: '/'}"
            replace>
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'tachometer-alt']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' left-1 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._dashboard }}
            </span>
          </router-link>
        </li>
        <!-- New Event -->
        <li v-if="canShow('newEvent')" :class="activePath ==='newEvent' ? ' active': ''">
          <!-- /web/dashboard/new/event -->
          <a
            id="newEvent"
            @click="action_newEvent"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'plus']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' right-1 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._newevent }}
            </span>
          </a>
        </li>
        <!-- All Events -->
        <li v-if="canShow('allEvents')" :class="activePath ==='events' ? ' active': ''">
          <!-- /web/dashboard/all/events -->
          <a
            id="allEvents"
            @click="action_allEvents"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'calendar-alt']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' right-1 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._allevents }}
            </span>
          </a>
        </li>
        <!-- Email Template -->
        <li v-if="canShow('emailTemplate')" :class="activePath ==='emailTemplate' ? ' active': ''">
          <a
            id="emailTemplate"
            @click="action_emailTemplate"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['far', 'envelope']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' right-1 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._emailTemplate}}
            </span>
          </a>
        </li>
        <!-- Notify -->
        <li v-if="canShow('notify')">
          <a
            @click="action_notify"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg "
                   :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['far', 'paper-plane']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>Notify</span>
          </a>
        </li>
        <!-- Events Archived -->
        <li v-if="canShow('eventArchive')" :class="activePath ==='archive' ? ' active': ''">
          <!-- /web/dashboard/archive -->
          <a
            id="allArchivedEvents"
            @click="action_allEventArchived"
          >
            <font-awesome-layers class="fa-lg">
             <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'archive']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._eventArchived }}
            </span>
          </a>
        </li>
        <!-- Members -->
        <li v-if="canShow('team-members')" :class="activePath ==='teamMembers' ? ' active': ''">
          <a
            id="team-members"
            @click="action_team_members"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'users']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' left-2 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ strings.sidebar._members }}
            </span>
          </a>
        </li>
        <li v-if="canShow('eventGroup')" :class="activePath ==='eventGroup' ? ' active': ''">
          <a
            id="event-group"
            @click="action_eventGroup"
          >
             <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'calendar-alt']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' right-1 ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>
            {{ $root.strings.sidebar._eventGroup }}
            </span>
          </a>
        </li>
        <!-- FAQ -->
        <li v-if="!isExhibitor">
          <a
            @click="action_helpdesk"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg "
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'question-circle']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' ' +icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>HelpDesk</span>
          </a>
        </li>
        <!-- Download Penguinpass Checkin Link -->
        <li v-if="canShow('checkInLink') && !isExhibitor">
          <a
            target="_blank"
            :href="strings.sidebar._downloadpenguinpasscheckinURL_android"
            title="Check-in App Android"
          >
            <font-awesome-layers class="fa-lg">
             <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'download']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' '+ icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span style="margin-right: 0px;margin-top: -40px; margin-left: 50px;">{{ strings.sidebar._downloadpenguinpasscheckin_android }}</span>
          </a>
        </li>
        <li v-if="canShow('checkInLink') && !isExhibitor">
          <a
            target="_blank"
            :href="strings.sidebar._downloadpenguinpasscheckinURL_ios"
            title="Check-in App IOS"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'download']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' '+ icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>{{ strings.sidebar._downloadpenguinpasscheckin_ios }}</span>
          </a>
        </li>
        <!-- Download Penguinpass Guest App -->
        <!-- <li
          v-if="canShow('guestAppLink')"
          class="sub-menu dcjq-parent-li"
        >
          <a
            href="javascript:;"
            @click="toggleVisibleSubMenu()"
          >
            <i
              class="fa fa-download"
              aria-hidden="true"
            ></i>
            <span>{{ strings.sidebar._downloadpenguinpass }}</span>
          </a>
          <transition name="fade">
            <ul
              v-show="switchSideBar"
              class="sub"
            >
              <li>
                <a
                  :href="strings.sidebar._applestore"
                  title="App Store"
                  target="_blank"
                >
                  App Store
                </a>
              </li>
              <li>
                <a
                  :href="strings.sidebar._googlestore"
                  title="Google Play"
                  target="_blank"
                >
                  Google Play
                </a>
              </li>
            </ul>
          </transition>
        </li> -->
        <!-- Download Penguinpass Beacon -->
        <!-- <li v-if="canShow('beaconAppLink')">
          <a
            target="_blank"
            :href="strings.sidebar._downloadpenguinpassbeaconURL"
            title=""
            @click="downloadMenuInvisible()"
          >
            <i
              class="fa fa-download"
              aria-hidden="true"
            ></i>
            <span>{{ strings.sidebar._downloadpenguinpassbeacon }}</span>
          </a>
        </li> -->
        <!-- Contacts -->
        <li v-if="canShow('contacts') && !isExhibitor">
          <a
            target="_blank"
            :href="'mailto:help@penguinpass.co'"
            title=""
            @click="downloadMenuInvisible()"
          >
            <font-awesome-layers class="fa-lg">
              <div class="sidebar-icon-bg"
                :style="'background: '+ icon.iconBackground">
              </div>
              <font-awesome-icon
                :icon="['fas', 'phone']"
                :style="{ color: icon.iconForeground }" :size="icon.iconSize"
                :transform="icon.shrink + ' ' + icon.down"
              >
              </font-awesome-icon>
            </font-awesome-layers>
            <span>{{ strings.sidebar._contacts }}</span>
          </a>
        </li>
        <!-- Terms -->
        <!-- <li v-if="canShow('terms')">
          <a
            target="_blank"
            :href="strings.sidebar._linkTerms"
            title=""
            @click="downloadMenuInvisible()"
          >
            <i
              class="fa fa-globe"
              aria-hidden="true"
            ></i>
            <span>{{ strings.sidebar._terms }}</span>
          </a>
        </li> -->
      </ul>
  <!-- </aside> -->
</template>
<style>
    li a {
        cursor: pointer;
         }
         #newEvent, #allEvents, #allArchivedEvents, #allPrivacyPolicies, #emailTemplate{
  cursor:pointer
}

    @import '../assets/css/dashboard/main.css';
</style>
<script>

import userdata from './../userdata.js';
import VLink from './../components/VLink.vue';
import $ from 'jquery';
import { subject } from '@casl/ability';
import { mapGetters } from 'vuex';
import { onLogout } from '@/vue-apollo.js';

export default {
    name: 'SidebarComponent',
    props: {
        height: {type: Number, default: 700}
    },
    data (){
        return {
            componentName:'side-bar',
            appName: 'PENGUINPASS',
            logo: '/img/logopass.png',
            windowIsMobile: false,
            switchSideBar: false,
            linkContacts : 'http://www.penguinpass.it/#contatti',
            strings: {},
            app_settings: userdata.app_settings,
            icon: {
              iconForeground: 'white',
              iconBackground: '#ff9c00',//'#7A7E7E',
              avatarIconSize: '3x',
              iconSize: '2x',
              grow: 'grow-6',
              shrink: 'shrink-2',
              down: 'down-2'
            }
        };
    },
    watch: {
        sidebarOn: function () {
            this.selfDisplay();
        },
        height: {
          immediate: true,
          handler(newVal, oldVal) {
            // if (newVal <= 700) {
            //   // this.icon.avatarIconSize = "2x";
            //   // this.icon.iconSize = "1.5x";
            //   this.icon.grow = "";
            //   this.icon.shrink = "shrink-6";
            //   this.icon.down = "down-1";
            // } else {
              this.icon.avatarIconSize = "3x";
              this.icon.iconSize = "2x";
              this.icon.grow = "grow-6";
              this.icon.shrink = "shrink-2";
              this.icon.down = "down-2";

            // }
          }
        }
    },
    created (){
        const context = this;
        this.strings = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.strings = context.$root.strings;
        });
      this.init();
    },
    computed: {
      ...mapGetters(['features', 'team', 'isExhibitor', 'user', 'sidebarOn', 'activePath']),
    },
    mounted () {
        this.selfDisplay();
    },
    beforeDestroy(){
      window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        onLogout() {
          this.$confirm(this.$root.strings.sidebar._logoutConfirm).then(()=> {
            this.$store.dispatch('logout', this.user)
                    .then(async () => {
                      await onLogout(this.$apollo.getClient());
                      await this.$router.push('/login').catch(error =>{});
                    });
          }).catch(()=>{});

        },
        // handle MenuBar visibility
        init () {
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
        },
        handleResize () {

            const wSize = $(window).width();

            if (wSize <= 768) {
                this.windowIsMobile = true;
                this.$store.commit("switchSidebar", false);
            }

            if (this.windowIsMobile) {
              if (wSize > 768) {
                  this.$store.commit("switchSidebar", true);
                  this.windowIsMobile = false;
                }
            }

        },
        selfDisplay () {

            const container = $('#container');

            if (this.sidebarOn) {
                $('#sidebar > ul').show();
                // $('#main-content').css({ 'margin-left': '241px' });
                // $('#sidebar').css({ 'margin-left': '0' });
                container.removeClass('sidebar-closed');

            } else {
                // $('#main-content').css({ 'margin-left': '0px' });
                // $('#sidebar').css({ 'margin-left': '-241px' });
                container.addClass('sidebar-closed');
                $('#sidebar > ul').hide();

            }

        },
        toggleVisibleSubMenu () {
            this.switchSideBar = !this.switchSideBar;
        },
        downloadMenuInvisible () {
            this.switchSideBar = false;
        },
        // btn Actions
        action_newEvent () {
            this.$emit('component_event', this.componentName + '|newEvent'); /** Emit di evento al padre */
        },
        action_allEvents () {
            this.$emit('component_event', this.componentName + '|allEvents'); /** Emit di evento al padre */
        },
        allPrivacyPolicies () {
            this.$emit('component_event', this.componentName + '|allPrivacyPolicies'); /** Emit di evento al padre */
        },
        action_allEventArchived () {
            this.$emit('component_event', this.componentName + '|archived'); /** Emit di evento al padre */
        },
        action_team_members () {
            this.$emit('component_event', this.componentName + '|teamMembers');
        },
        action_eventGroup () {
            this.$emit('component_event', this.componentName + '|eventGroup');
        },
        action_helpdesk () {
            this.$emit('component_event', this.componentName + '|helpdesk');
        },
        action_recordings () {
            this.$emit('component_event', this.componentName + '|recordings');
        },
        action_notify () {
        this.$emit('component_event', this.componentName + '|notify');
        },
        action_emailTemplate() {
          this.$emit('component_event', 'modalEmailBuilder|open');
        },
        // btn Visibility
        canShow (link) {

            const userPermissions = this.$root.userPermissions;

            switch (link) {

                case 'team-members': {
                  return this.features && this.features.teamMembers && this.$can('access', subject('team_members_page',{ ...this.team }));
                }
                case 'eventGroup': {
                  return this.features && this.features.eventGroups && this.features.teamMembers && this.$can('access', subject('team_members_page',{ ...this.team }));
                }
                case 'privacyPolicy':
                    return userPermissions.includes('hasPrivacyPolicyManagement');
                case 'terms':
                    return false;
                case 'newEvent':
                  return this.$can('create', 'new_event');
                case 'recordings':
                  return this.features && this.features.virtualRoom;
                case 'notify':
                  return this.features && this.features.pushNotification && this.$can('access', subject('push_notifications_page',{ ...this.team }));
                case 'allEvents':
                case 'eventArchive':
                case 'contacts':
                case 'checkInLink':
                case 'guestAppLink':
                case 'beaconAppLink':
                case 'emailTemplate':
                    return true;

            }

            return true;
        }
    },
    ready (){}
};
</script>
