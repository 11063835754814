<!-- boilerplate vue component -->
<template>
  <div></div>
</template>

<script>
export default {
  name: "SSOCallback",
  components: {},
  props: {},
  data() {
    return { token: String };
  },
  computed: {},
  mounted() {
    this.token = this.$route.query.token;
    this.loginSSO();
  },
  methods: {
    async loginSSO() {
      const token = await this.$store.dispatch("loginSSO", this.token);
      if (token) {
        window.location.replace("/");
      }
    },
  },
};
</script>
