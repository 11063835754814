var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fileType === "html"
    ? _c("iframe", { staticClass: "cover-page", attrs: { src: _vm.source } })
    : _vm.fileType === "pdf"
    ? _c("embed", {
        staticClass: "cover-page",
        attrs: { src: _vm.source, type: "application/pdf" },
      })
    : _c("PublicLayout", [
        _c("div", [_vm._v(" This file type is not supported yet")]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }