var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-title-container" }, [
    _vm.title !== ""
      ? _c("h4", { staticClass: "section-title" }, [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots.subtitle
      ? _c("div", { staticClass: "section-subtitle" }, [_vm._t("subtitle")], 2)
      : _vm.subtitle !== ""
      ? _c("h5", { staticClass: "section-subtitle" }, [
          _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showSeparator
      ? _c("div", { staticClass: "small-separator" })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }