var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "header black-bg" },
    [
      _c("div", { staticClass: "sidebar-toggle-box" }, [
        _c("div", {
          staticClass: "fa fa-bars tooltips",
          attrs: { "data-placement": "right", "data-original-title": "menu" },
          on: { click: _vm.toogleSideBar },
        }),
      ]),
      _vm._v(" "),
      _c(
        "router-link",
        { staticClass: "logo", attrs: { to: { path: "/" }, tag: "a" } },
        [_c("b", [_vm._v(_vm._s(_vm.appNameLocal))])]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "containerHeaderRightMenu" },
        [
          _vm.notifications.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "el-dropdown",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { "hide-on-click": false, trigger: "click" },
                    },
                    [
                      _c("div", [
                        _c(
                          "svg",
                          {
                            staticStyle: { position: "relative" },
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              width: "24",
                              height: "24",
                              viewBox: "0 0 24 24",
                              "stroke-width": "2",
                              stroke: "#fff",
                              fill: "none",
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                stroke: "none",
                                d: "M0 0h24v24H0z",
                                fill: "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                d: "M10 5a2 2 0 1 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: { d: "M9 17v1a3 3 0 0 0 6 0v-1" },
                            }),
                            _vm._v(" "),
                            _c("circle", {
                              attrs: {
                                cx: "16",
                                cy: "6",
                                r: "4",
                                fill: "red",
                                stroke: "red",
                              },
                            }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-menu",
                        {
                          staticStyle: { "min-width": "250px" },
                          attrs: { slot: "dropdown" },
                          slot: "dropdown",
                        },
                        _vm._l(_vm.notifications, function (notification) {
                          return _c(
                            "el-dropdown-item",
                            { key: notification._id },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                    position: "relative",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearNotification(
                                            notification
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                x\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    notification.title
                                      ? _c("h3", [
                                          _vm._v(_vm._s(notification.title)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(_vm._s(notification.message)),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.handleCommand } },
            [
              _c("p", [_vm._v(_vm._s(this.langSet))]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "it" } }, [
                    _vm._v(_vm._s(_vm.string.header._italian)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "en" } }, [
                    _vm._v(_vm._s(_vm.string.header._english)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "fr" } }, [
                    _vm._v(_vm._s(_vm.string.header._francaise)),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "ar" } }, [
                    _vm._v(_vm._s(_vm.string.header._arab)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }