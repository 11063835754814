var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    staticClass: "custom-input-control",
    attrs: { id: _vm.customId, type: "text", placeholder: _vm.placeholder },
    domProps: { value: _vm.value },
    on: { input: _vm.onInput },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }