<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top tm-faq">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          FAQ
        </h1>
        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to add collaborators to my team
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What are dashboard, check-inApp and guestApp?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What happens when I activate an event?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What are the "confirm + 1" and "request name of + 1 / o notes" checkboxes for and how do they work?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Where can I find the name and surname of the companions who entered my guest and how do I add them to the
               list?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How does the QR code work to access the event?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Can I add custom details / attributes on my guests?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to keep the risk of spam low
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Which type of subscription is best for me?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To choose the subscription that best suits your needs, we recommend that you contact us at
            <u>
              <a href="info@penguinpass.it">
                info@penguinpass.it
              </a>
            </u> so that we can identify together the best offer that best meets your needs.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What are dashboard, check-in APP and guestApp?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dashboard, guest and app check-in are the three tools on which the Penguinpass system is based;
             The first is used by organizers via web access to create and manage events
             thus having a complete and organized vision in a single instrument. <br>
             The two mobile apps are instead aimed at users of the service: the Check-inApp
             allows hostesses to manage on-site access, which through this dynamic support
             they can check and update guest lists in real time. While the GuestApp is
             designed and developed for guests who, thanks to the app, can always stay informed and
             updated on events and access locations easily and quickly.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What happens when I activate an event?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            When an event is activated, the organizer will be able to use the mail marketing function
             for
             send invitations and communications to their guests.
            <br> Furthermore, once the event is activated it will be available on the GuestApps and
             therefore, visible only to guests, in the case of a private event, to anyone if it is a public event.
            <br>
            It is important to note that it is not possible to use the features of the Check-inApp if
             the event was not activated.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What are the "confirm + 1" and "request name of + 1 / o notes" checkboxes for and how do they work?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Under the section in which the email to be sent in the campaign is generated, there are two
             check-box whose purpose, if activated, is to allow the guest (once the
             RSVP) to enter the number and / or names of his companions.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Where can I find the name and surname of the companions who entered my guest and how do I add them to the
             list?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            When the guest confirms their participation, they will have (if from the dashboard
             the organizer allows it) the possibility of entering the "name, surname and email" of his / her
             any accompanying persons. These names and data can be found simply by downloading
             the excel sheet via "download the whole list" in the "guest list" section in the form of a note.
             By taking these names and rearranging them in a new excel sheet on the appropriate format
             "Template for xls import" and reloading it. By doing so, you can also send emails
             to the companions of your guests if necessary, or simply they can
             view via Check-inApp.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How does the QR code work to access the event?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Often there is a need or desire to manage the income to events via QR Code, this
             it means that the guests who send their RSVPs will receive an email of theirs
             confirmation containing additional details on the event and the personal QR Code to access.
             According to this operating logic, it is therefore necessary to set sending from the dashboard
             of a confirmation email, and insert the dynamic content (QR Code) in it.<br>
            center a QR code in the e-mail<br>
            When inserting "dynamic content" into the empty "form" in Emailbuilder, if you are using
             a "single module" (the first in the modules section) the QR Code will be displayed on the left side
             of the email, if you want it to be displayed in the center it will be necessary
             simply use not the "single module" but the "module divided into three sections" (the seventh
             of the module list) and insert the dynamic content in the central one.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Can I add custom details / attributes on my guests?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To add a specific and custom attribute to the guest list, just edit
             in the "template for xls import" (downloaded from the site as explained above) the "custom
             note *** "by placing the name of the desired attribute in its place (eg. from" custom note *** "to" country
             of origin "or" company ").<br>
             If you need more column-attributes, you can add more later to the “custom
             Note***"<br>
            In case you forget to add the extra fields when loading guests, it is possible
             add new columns by going to the event settings section, expanding the section "Edit custom
             fields "it will be possible to add new attributes. Once added, simply select the Check-in item
             app
             to make the field visible in the check-in app.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to keep the risk of spam low
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Here are some tips for decreasing the risk of your email campaigns coming
             classified as spam by mail servers. But first let's start with the ranking, which is one of the parameters of
             various mail programs and servers to manage messages. Sending with Penguinpass the score is 89
             out of 100 which is very high<br><br>

            1. Mail programs if they read words like events, save the date, rsvp etc can classify in
             automatic mails as "promotions" and insert them in the relative folder on gmail. So we recommend
             to avoid such words in the subject of your campaign.<br><br>

            2. We recommend that the email address (the sender) does not have words such as events, marketing
             etc. for example events@cliente.com. <br><br>

            3. If you have the possibility, we recommend that you verify your domain instead of the single address
             e-mail.<br><br>

            4. Try to ensure that the emails sent do not have just an image (the invitation) and only the
             "Kind name + surname". It is very important to load text and images in a balanced way (
             emails also use this algorithm to classify emails, the relationship between text and images).
             Basically you have to write some text, and then the images. The greater the proportion of text, the less the
             risk of your campaign being classified as "spam" or "promotion".<br><br>

            5. Image weight: this is also an important factor. The less weight the images are, the faster they are seen and
             they are less "suspicious" to mail programs that protect against possible viruses or malware they need
             a minimum weight. The ideal would be to attach images / invitations between 50kb and 150kb.<br><br>

            6. It is advisable to write in lowercase, or at least not all in capital letters. Writing in
             block letters in the subject affect + 10% of the danger of being classified as "spam".<br><br>

            7.One last tip is to send at least the first time you do a campaign, to the addresses you don't
             you see the email open or who have not done rsvp (on Penguinpass there are special filters), an email to
             part (not with Penguinpass or with other systems such as mailchimp etc), but with a normal mail client
             pasting the addresses and which, in addition to being a reminder for the event, also invites them to check the
             spam or in the other folders any mail received from you. Once the user, at your request, has
             when the e-mail is moved to those received, the mail system understands that the next time from that address
             the email must be displayed.
           </p>
        </article>

      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskFAQEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetParent.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
