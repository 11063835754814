<template xmlns:v-model="http://www.w3.org/1999/xhtml">
  <div class="row list-single-item">
    <h4>{{ string.sponsor._addSponsor }}</h4>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <transition name="component-fade-fast" mode="out-in" appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.listSingleItem._loadingMessage"
        />
      </transition>
      <form class="form-horizontal style-form" autocomplete="off">
        <div class="form-group no-margin-bottom nomarginleft">
          <div class="col-sm-6">
            <input
              :id="inputSponsorName"
              v-model="sponsorName"
              aria-label=""
              type="text"
              class="form-control"
              :placeholder="string.sponsor._sponsorName"
            />
          </div>

          <div class="col-sm-6">
            <input
              :id="inputSponsorEmail"
              v-model="sponsorEmail"
              aria-label=""
              type="email"
              class="form-control"
              :placeholder="string.sponsor._sponorEmail"
            />
          </div>
        </div>
        <div class="form-group no-margin-bottom nomarginleft">
          <div class="col-sm-6">
            <input
              :id="inputSponsorMaxTickets"
              v-model="sponsorMaxTickets"
              aria-label=""
              type="number"
              class="form-control"
              :placeholder="string.listSingleItem._maxTickets"
            />
          </div>
        </div>
        <div class="formbutton">
          <a class="btn gray-btn save" title="avanti" @click="submit">
            {{ string.listSingleItem._save }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.formbutton {
  position: relative;
}

.formbutton > a {
  margin: 0 auto;
  display: block;
  width: 100%;
}

.list-single-item .form-control {
  border-radius: 0;
  border-color: #333;
}

.list-single-item label {
  color: #333;
  font-weight: bold;
  padding-left: 0 !important;
  text-transform: capitalize;
}

.list-single-item h4 {
  margin-bottom: 20px;
  text-transform: uppercase;
  text-align: center;
}
</style>

<script>
import LoadingCover from "./LoadingCover.vue";
import { ADD_SPONSOR_TO_EVENT } from "@/graphql";

export default {
  components: {
    LoadingCover,
  },
  props: {
    eventId: String,
    fatherName: String,
    team: Object,
    // items,
  },
  data() {
    return {
      componentName: "list-input-sponsor",
      loadingEffectshow: false,
      string: {},
      sponsorName: "",
      sponsorEmail: "",
      sponsorMaxTickets: 1,
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  watch: {
    //items: function(){
    //    this.guests = this.items;
    //}
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    refetchData: function () {
      this.$root.bus.emit("newSponsor");
    },
    submit: function () {
      const context = this;

      context.loadingEffectshow = true; // Loading Effect

      if (!this.sponsorDataValid()) {
        // Error Effect
        context.warnErrorForMandatoryFields();
        return false;
      }

      if (this.sponsorEmail.length > 0) {
        if (!this.reg.test(this.sponsorEmail)) {
          context.warnEmailSyntax();
          return false;
        }
      }

      return this.$root.axios
        .post("/api/sponsors/create-sponsor", {
          name: this.sponsorName,
          eventId: this.eventId,
          email: this.sponsorEmail,
          maxTickets: parseInt(this.sponsorMaxTickets),
        })
        .then(async (response) => {
          if (response.data.status === "FAILED") {
            console.log(response.errors);
            this.$root.bus.emit("simpleToast", context.string.listSingleItem._emailAlreadyPresentError);
            return;
          }
          if (response.data) {
            context.$root.bus.emit("simpleToast", context.string.listSingleItem._sponsorAdded);
            context.emptyFields();
            this.$root.bus.emit("newSponsor");
            console.log(res);
          }
        })
        .catch((error) => {
           console.log(error);
        })
        .finally(() => {
          context.loadingEffectshow = false;
        });

      return this.$apollo
        .mutate({
          mutation: ADD_SPONSOR_TO_EVENT,
          variables: {
            name: this.sponsorName,
            eventId: this.eventId,
            email: this.sponsorEmail,
            maxTickets: parseInt(this.sponsorMaxTickets),
          },
        })
        .then(async (response) => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data) {
            context.$root.bus.emit("simpleToast", context.string.listSingleItem._sponsorAdded);
            context.emptyFields();
            this.$root.bus.emit("newSponsor");
            console.log(res);
            context.loadingEffectshow = false;
          }
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].message === "GUEST_EXIST"
          ) {
            context.warnEmailAlreadyPresent();
          } else if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].message === "GUEST_LIST_FULL"
          ) {
            context.$root.bus.emit(
              "simpleToast",
              context.string.guestlist._maxGuestSizeReached + context.team.guestListLimit
            );
          }
          context.loadingEffectshow = false;
        });
    },
    emptyFields: function () {
      const context = this;
      context.sponsorName = "";
      context.sponsorEmail = "";
      context.sponsorMaxTickets = 1;
    },
    sponsorDataValid: function () {
      const context = this;
      if (
        !context.sponsorName ||
        !context.sponsorEmail ||
        parseInt(context.sponsorMaxTickets) <= 0
      ) {
        return false;
      }
      return true;
    },
    warnEmailAlreadyPresent: function () {
      this.loadingEffectshow = false;
      this.$root.bus.emit("simpleToast", this.string.listSingleItem._emailAlreadyPresentError);
    },
    warnEmailSyntax: function () {
      this.loadingEffectshow = false;
      this.$root.bus.emit("simpleToast", this.string.listSingleItem._emailSyntaxError);
    },
    warnErrorForMandatoryFields: function () {
      this.loadingEffectshow = false;
      this.$root.bus.emit("simpleToast", this.string.listSingleItem._mandatoryFields);
    },
    warnUnManagedError: function () {
      this.loadingEffectshow = false;
      this.$root.bus.emit("simpleToast", this.string.listSingleItem._unManagedError);
    },
  },
};
</script>
