<template>
  <div class="default-layout">
    <div id="timeSlotManager" class="timeSlotManager-component">
      <div v-if="loadingEffectshow" class="higherPosition">
        <transition name="component-fade-fast" mode="out-in" appear>
          <LoadingCover v-if="loadingEffectshow" loading-cover-msg="Loading..." />
        </transition>
      </div>
      <div class="accordion">
        <h4 class="form-section-title first accordion-toggle">
          {{ string.timeSlotManager._title }}
        </h4>
        <div class="accordion-content">
          <div class="toggleSelection">
            <label class="control control-checkbox">
              {{ string.timeSlotManager._innertitle }}
              <input id="checkbox" v-model="showEditor" type="checkbox" @change="toggleTimeSlot" />
              <div class="control_indicator"></div>
            </label>
          </div>
          <div v-if="timeSlotRawData.length > 0" style="font-size: 18px; margin-top: 15px;">
            <!-- <i class="fa fa-times" aria-hidden="true" style="margin-right:5px; margin-top: 15px;" /> -->
            <button class="btn save" @click="deleteAllSlots">
              {{ string.timeSlotManager._deleteAllSlots }}
            </button>
            <ExportICS class="btn save" :get-data="fetchBookings">
              <!-- <button class="btn save"> -->
              {{ string.timeSlotManager._export_data }}
              <!-- </button> -->
            </ExportICS>
          </div>
          <div v-if="showEditor" style="min-width: 200px; min-height: 200px; margin-top: 45px;">
            <ICSUpload
              v-if="timeSlotRawData.length <= 0"
              :start-date="startDate"
              :end-date="endDate"
              :custom-field-source="customFieldSource"
              father-name="timeSlotManager"
              @onChange="onChange"
            />
            <div v-else>
              <div style="text-align: right; float: right; margin-bottom: 10px;">
                <label class="el-form-item_label">
                  {{ string.timeSlotManager._maxLimitPerSlot }}:
                </label>
                <NumericFieldInput :initial-value="maxLimitPerSlot" @change="setMaxLimitPerSlot" />
              </div>
              <data-table
                ref="timeSlotTable"
                :get-data="getData"
                :filter-enabled="false"
                :columns="timeSlotColumns"
              >
                <span slot="title" slot-scope="{ row }" class="p-table-cell">
                  <input
                    v-if="row.edit && !row.disabled"
                    v-model.trim="editItem.title"
                    type="text"
                    class="form-control"
                  />
                  <span v-else>
                    {{ row.title + (row.disabled ? " " + string.timeSlotManager._disabled : "") }}
                  </span>
                </span>
                <span slot="startDate" slot-scope="{ row }" class="p-table-cell">
                  {{ new Date(row.startDate).format() }}
                </span>
                <span slot="endDate" slot-scope="{ row }" class="p-table-cell">
                  {{ new Date(row.endDate).format() }}
                </span>
                <span slot="maxLimit" slot-scope="{ row }">
                  <!-- {{ typeof row.limit === "number" && row.limit > 0 ? row.limit : "-" }} -->
                  <NumericFieldInput
                    v-if="row.edit"
                    class="p-table-cell"
                    style="width:150px !important;"
                    :min="editItem.maxLimit === 0 ? editItem.maxLimit : editItem.limit"
                    :initial-value="typeof editItem.maxLimit === 'number' ? editItem.maxLimit : 0"
                    @change="
                      value => {
                        onMaxLimitChange(row, value);
                      }
                    "
                  />
                  <div v-else>
                    {{
                      typeof row.maxLimit === "number" && row.maxLimit > 0
                        ? `${row.limit || 0}/${row.maxLimit}`
                        : "-"
                    }}
                  </div>
                </span>
                <span slot="Actions" slot-scope="{ row }" style="text-align: center;">
                  <a
                    class="action_link"
                    style="margin-right: 5px;"
                    @click="() => (row.edit === false ? openRow(row) : saveRow(row))"
                  >
                    <i :class="row.edit === false ? 'fa fa-pencil-square-o' : 'fa fa-check'"></i>
                  </a>
                  <a
                    class="action_link"
                    style="margin-right: 5px;"
                    @click="() => (row.edit === false ? deleteRow(row) : closeRow(row))"
                  >
                    <i :class="row.edit === false ? 'fa fa-trash' : 'fa fa-times'"></i>
                  </a>
                </span>
              </data-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-control.el-color-picker {
  background: none !important;
}
.el-color-picker__panel input {
  color: #000000 !important;
}
.el-color-picker__panel .el-button {
  padding: 10px;
}
.mt-3 {
  margin-top: 3em;
}
.p-5 {
  padding: 5px;
}

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import ICSUpload from "./ICSUpload.vue";
import { mapGetters } from "vuex";
import { EXPORT_TIME_SLOT_BOOKINGS, UPDATE_EVENT } from "@/graphql";
import DataTable from "./DataTable.vue";
import NumericFieldInput from "./NumericFieldInput.vue";
import { slugify } from "@/utils";
import moment from "moment";
import ExportICS from "./ExportICS.vue";

export default {
  components: {
    LoadingCover,
    ICSUpload,
    DataTable,
    NumericFieldInput,
    ExportICS
  },
  props: {
    eventId: String,
    event: Object,
    customFieldSource: {
      type: String,
      required: false,
      default: "GUEST"
    }
  },
  data() {
    return {
      componentName: "TimeSlotManager",
      string: {},
      loadingEffectshow: false,
      showEditor: false,
      startDate: null,
      endDate: null,
      customFieldMap: {},
      timeSlotMap: {},

      timeSlotRawData: [],
      timeSlotColumns: [
        {
          prop: "title",
          label: "Title",
          sortable: true
        },
        {
          prop: "startDate",
          label: "Start Date",
          sortable: true
        },
        {
          prop: "endDate",
          label: "End date",
          sortable: true
        },
        {
          prop: "maxLimit",
          label: "Limit"
        }
        // {
        //   label: "Actions",
        //   width: "130"
        // }
      ],
      maxLimitPerSlot: 1,
      hasChanges: false,
      editItem: {
        label: "",
        limit: 0,
        maxLimit: 1,
        maxLimitDetached: false
      },
      exportData: []
    };
  },
  computed: {
    ...mapGetters(["team", "user", "features"])
  },
  watch: {
    event: {
      immediate: true,
      handler: function(newEvent, oldEvent) {
        this.startDate = newEvent ? newEvent.startDate : null;
        this.endDate = newEvent ? newEvent.endDate : null;
        this.showEditor =
          newEvent && typeof newEvent.timeSlotEnabled === "boolean"
            ? newEvent.timeSlotEnabled
            : false;
        this.timeSlotMap = newEvent.timeSlotMap;
        this.customFieldMap = newEvent.customFieldMap;
        const timeSlotArray = Object.entries(newEvent.timeSlotMap || {});
        if (timeSlotArray.length > 0) {
          const temp = [];
          for (const [key, value] of timeSlotArray) {
            const answers = Object.values(value.options.answers).map(answer => ({
              ...answer,
              customFieldSlug: key
            }));
            temp.push(...answers);
          }
          this.timeSlotRawData = temp;
        } else {
          this.timeSlotRawData = [];
        }
        this.maxLimitPerSlot =
          typeof newEvent.maxLimitPerSlot === "number" ? newEvent.maxLimitPerSlot : 1;
      }
    }
  },
  created() {
    const context = this;
    this.string = this.$root.strings;

    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    async fetchBookings() {
      let result = [];
      await this.$root.$apollo
        .query({
          query: EXPORT_TIME_SLOT_BOOKINGS,
          variables: {
            eventId: this.eventId
          }
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
          } else if (data && data.timeSlotBookings) {
            result = data.timeSlotBookings;
          } else {
            result = [];
          }
        })
        .catch(error => {
          console.error(error.message);
        });
        return result;
    },
    async saveRow(row) {
      if (this.hasChanges) {
        await this.saveCustomFieldObject(this.customFieldMap);
      }
      row.edit = false;
      this.hasChanges = false;
    },
    openRow(row) {
      // close open rows
      for (const [index, item] of this.$refs.timeSlotTable.tableData.entries()) {
        if (item.key !== row.key && item.edit) {
          item.edit = false;
        }
        if (item === row) {
          item.edit = true;
        }
      }
      this.editItem = {
        label: row.label,
        limit: typeof row.limit === "number" ? row.limit : 1,
        maxLimit: typeof row.maxLimit === "number" ? row.maxLimit : 1
      };
      row.edit = true;
    },
    async deleteRow(row) {},
    async closeRow(row) {
      row.edit = false;
      this.hasChanges = false;
      this.customFieldMap = this.event.customFieldMap;
      const timeSlotArray = Object.entries(this.event.timeSlotMap || {});
      if (timeSlotArray.length > 0) {
        const temp = [];
        for (const [key, value] of timeSlotArray) {
          const answers = Object.values(value.options.answers).map(answer => ({
            ...answer,
            customFieldSlug: key
          }));
          temp.push(...answers);
        }
        this.timeSlotRawData = temp;
      } else {
        this.timeSlotRawData = [];
      }
    },
    async deleteAllSlots() {
      this.$confirm(this.string.timeSlotManager._deleteAllSlotsWarning)
        .then(async _ => {
          const copy = {};
          for (const [slug, cField] of Object.entries(this.event.customFieldMap)) {
            if (cField.type !== "TIME_SLOT") {
              copy[slug] = cField;
            }
          }
          await this.saveCustomFieldObject(copy);
        })
        .catch(_ => {});
    },
    onMaxLimitChange(row, value) {
      if (row.customFieldSlug && this.customFieldMap[row.customFieldSlug]) {
        this.customFieldMap[row.customFieldSlug].options.answers[row.key].maxLimit = value;
        this.customFieldMap[row.customFieldSlug].options.answers[row.key].maxLimitDetached = true;
      }

      row.maxLimit = value;
      row.maxLimitDetached = true;
      this.hasChanges = true;
    },
    setMaxLimitPerSlot(value) {
      this.loadingEffectshow = true;
      const oldVal = this.maxLimitPerSlot;
      this.timeSlotRawData.map(timeSlot => {
        if (value >= timeSlot.limit && !timeSlot.maxLimitDetached) {
          timeSlot.maxLimit = value;
          this.customFieldMap[timeSlot.customFieldSlug].options.answers[
            timeSlot.key
          ].maxLimit = value;
        }
      });
      this.maxLimitPerSlot = value;
      this.hasChanges = true;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.eventId,
            updateEventInput: {
              maxLimitPerSlot: this.maxLimitPerSlot,
              customFieldMap: this.customFieldMap
            }
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors[0]);
          } else {
            this.$root.bus.emit("simpleToast", this.string.customFieldsManager._updateDone);
          }

          this.loadingEffectshow = false;
          this.$refs.timeSlotTable.getTableData();
        })
        .catch(error => {
          console.log(error.message);
          this.$root.bus.emit(
            "simpleToast",
            this.string.customFieldsManager._error + " " + error.message
          );

          this.loadingEffectshow = false;
        });
    },
    async onChange(data) {
      const tempData = [];
      const tempCustomFieldMap = this.event.customFieldMap;
      for (const [slug, timeSlotGroup] of Object.entries(data)) {
        if (!tempCustomFieldMap[slug]) {
          const tempAnswers = {};
          // always white list the data to be saved to get rid of runtime dynamic attributes
          for (const timeSlot of timeSlotGroup.timeSlots) {
            const start = moment(new Date(timeSlot.startDate));
            const end = moment(new Date(timeSlot.endDate));
            const label = start.isSame(end, "day")
              ? `${start.toDate().format()} - ${end.toDate().formatHours()}`
              : `${start.toDate().format()} - ${end.toDate().format()}`;
            const answerSlug = slugify(timeSlot.startDate);
            tempAnswers[answerSlug] = {
              key: answerSlug,
              label: label,
              title: timeSlotGroup.title,
              startDate: timeSlot.startDate,
              endDate: timeSlot.endDate,
              disabled: typeof timeSlot.disabled === "boolean" ? timeSlot.disabled : false,
              limit: typeof timeSlot.limit === "number" && timeSlot.limit >= 0 ? timeSlot.limit : 0,
              maxLimit:
                typeof timeSlot.maxLimit === "number" && timeSlot.maxLimit >= 0
                  ? timeSlot.maxLimit
                  : 1,
              maxLimitDetached:
                typeof timeSlot.maxLimitDetached === "boolean" ? timeSlot.maxLimitDetached : false
            };
          }
          tempCustomFieldMap[slug] = {
            type: "TIME_SLOT",
            slug,
            rsvp: true,
            rsvpMandatory: false,
            registrationForm: false,
            registrationFormMandatory: false,
            checkinapp: true,
            disabled: false,
            options: {
              question: timeSlotGroup.title,
              answers: tempAnswers,
              maxSelection: 1
            },
            source: this.customFieldSource
          };
        }
      }
      await this.saveCustomFieldObject(tempCustomFieldMap);
      const dataSize = Object.keys(data).length;
      if (dataSize > 0) {
        this.$root.bus.emit(
          "simpleToast",
          dataSize + " " + this.string.timeSlotManager._import_success
        );
      } else {
        this.$root.bus.emit("simpleToast", this.string.timeSlotManager._import_failure);
      }
    },
    async saveCustomFieldObject(customFieldObject) {
      try {
        const context = this;
        return this.$root.$apollo
          .mutate({
            mutation: UPDATE_EVENT,
            variables: {
              eventId: context.eventId,
              updateEventInput: {
                customFieldMap: customFieldObject
              }
            }
          })
          .then(value => {
            if (value.errors) {
              console.log("error " + value.errors[0]);
            } else {
              context.$emit("customFieldsChanged", null);
              context.$root.bus.emit("simpleToast", context.string.customFieldsManager._updateDone);
            }
          })
          .catch(error => {
            console.log(error.message);
            context.$root.bus.emit(
              "simpleToast",
              context.string.customFieldsManager._error + " " + error.message
            );
          });
      } catch (e) {
        console.log(e.message);
      }
    },
    toggleTimeSlot: function() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: context.eventId,
            updateEventInput: { timeSlotEnabled: context.showEditor }
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            //success
            context.loadingEffectshow = false;
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
        });
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      let data = [];
      const tempAll = this.timeSlotRawData;
      let sortField = "startDate";
      let sortOrder = 1;
      if (sortParams && Object.keys(sortParams).length > 0) {
        sortField = Object.keys(sortParams)[0];
        sortOrder = Object.values(sortParams)[0];
      }
      tempAll.sort((a, b) => {
        if (typeof a[sortField] === "number") {
          return (a[sortField] - b[sortField]) * sortOrder;
        }
        return (a[sortField] > b[sortField] ? 1 : -1) * sortOrder;
      });
      const reqPage = page || 1;
      const startIndex = pageSize * (reqPage - 1);
      const endIndex = pageSize * reqPage;
      const total = tempAll.length;
      data = tempAll.slice(startIndex, endIndex <= total ? endIndex : total);
      return Promise.resolve({
        data,
        total
      });
    }
  }
};
</script>
