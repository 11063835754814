var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("NewEvent", { attrs: { "prop-private": _vm.p } }),
      _vm._v(" "),
      _c("Redirect", {
        attrs: { href: "/", "start-redirect": _vm.redirectIntent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }