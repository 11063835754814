var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-section-editor editor text-center" },
      [
        _c("quill-editor", {
          ref: "messageEditor",
          staticClass: "quillEditor_style",
          attrs: { config: _vm.editorOption },
          on: {
            blur: function ($event) {
              return _vm.onEditorBlur($event)
            },
            focus: function ($event) {
              return _vm.onEditorFocus($event)
            },
            ready: function ($event) {
              return _vm.onEditorReady($event)
            },
            change: function ($event) {
              return _vm.onEditorChange($event)
            },
          },
          model: {
            value: _vm.contentChanged,
            callback: function ($$v) {
              _vm.contentChanged = $$v
            },
            expression: "contentChanged",
          },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "save",
          attrs: {
            type: "button",
            name: "submit",
            value: _vm.string.commonActions.save,
          },
          on: { click: _vm.save },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }