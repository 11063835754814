var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n        Liste Invitati\n      "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/it-home" } }, [
                _vm._v("\n            Support\n          "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come accedere e modificare una guest list\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come caricare una lista di invitati\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come scaricare i report RSVP e check-in\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    " Come impostare il numero massimo di accompagnatori per ospite\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Caso di upload di invitati già presenti nella lista\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come accedere e modificare una guest list\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come caricare una lista di invitati\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come scaricare i report RSVP e check-in\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come impostare il numero massimo di accompagnatori per ospite\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Caso di upload di invitati già presenti nella lista\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "helpdesk/it-listeinvitati" } }, [
        _vm._v("\n            Liste Invitati\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per visualizzare la lista clienti si deve aprire l’evento in questione dalla sezione “eventi” e\n          selezionare il button “guest”, si accede così alla “guest list” dove si\n          visualizzeranno: "
      ),
      _c("br"),
      _vm._v(
        "\n          nome, cognome, email, telefono, event status, ospiti, custom note e email status.\n          Nella sezione guest list è possibile: "
      ),
      _c("br"),
      _vm._v(
        "\n          - visualizzare il numero degli invitati presenti nella lista individuato dall’apposita voce\n          “numero invitati presenti in lista” "
      ),
      _c("br"),
      _vm._v(
        "\n          - Scaricare un foglio excel degli invitati totali presenti nella lista o solo di quelli che hanno\n          effettuato il check-in attraverso le apposite voci “download tutta la lista” e “download\n          solo i check-in” "
      ),
      _c("br"),
      _vm._v(
        "\n          - Eliminare la lista tramite la voce “cancella tutti gli invitati” "
      ),
      _c("br"),
      _vm._v(
        "\n          - Modificare manualmente uno specifico invitato selezionandolo dalla lista e “eliminarlo\n          o modificarlo” attraverso gli appositi button (a destra su ogni riga dell’invitato stesso).\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per popolare la lista è possibile inserire i dati degli\n          invitati agendo:"
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("MANUALMENTE")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "inserendo invitato dopo invitato attraverso il button “+ aggiungi\n          manualmente” riempendo i campi proposti: “Nome, Cognome, email e telefono”. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("AUTOMATICAMENTE")]),
      _c("br"),
      _vm._v(
        " caricando una lista precompilata su un foglio excel attraverso il\n          button “carica lista”."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n          Il foglio excel deve essere organizzato secondo particolari specifiche, infatti è necessario\n          utilizzare il “template per importazione xls” proposto sotto il botton “sfoglia”. Una volta\n          scaricato basterà seguire le indicazioni presenti nelle prime sei righe del foglio di lavoro excel:"
      ),
      _c("br"),
      _vm._v("\n          non eliminare le prime sei righe; "),
      _c("br"),
      _vm._v(
        " eliminare l’ospite fittizio “Mario Rossi” (posto come\n          esempio);"
      ),
      _c("br"),
      _vm._v(
        " inserire i dati dei propri ospiti rispettando le colonne assegnate ai vari campi. "
      ),
      _c("br"),
      _vm._v(
        "\n          Il foglio excel contenente la lista dovrà essere selezionato attraverso il button “sfoglia” e poi\n          in seguito per avviare il caricamento si dovrà selezionare il button “carica” "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("NEL CASO DI EVENTI PUBBLICI")]),
      _vm._v(
        " la lista sarà di tipo dinamico quindi si popolerà man\n          mano che gli ospiti stessi si registreranno/acquisteranno il ticket per l’evento, ciò comporta\n          che la lista sarà vuota al momento della creazione dell’evento. Rimane comunque la\n          possibilità di aggiungere manualmente degli ospiti, evitando la fase di registrazione/\n          acquisto biglietto.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Vi è la possibilità esportare dei fogli excel contenenti i dati relativi alla stato della\n          situazione, in particolare è possibile ottenere la lista completa di tutti gli invitati e il\n          relativo stato (es. se hanno ricevuto email, se hanno confermato etc…) attraverso la\n          voce “download tutta la lista”, oppure la lista di tutti gli invitati che hanno effettuato\n          il check-in attraverso l’apposita voce “download solo i check-in”. "
      ),
      _c("br"),
      _vm._v(
        " Inoltre è possibile\n          nella sezione “campagne email” ottenere dei report contenenti gli invitati a cui non\n          è stato possibile recapitare l’email, andando in fondo alla pagina dove si ha la lista\n          delle relative campagne inviate e relativi report. "
      ),
      _c("br"),
      _vm._v(
        "\n          In generale la raccolta dei dati riguardante la lettura o meno da parte di un invitato\n          dell’email non è immediata, si raccomanda quindi di aspettare del tempo prima di\n          aggiornare la pagine della “guest list” e scaricare i report.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Per selezionare un limite massimo di persone aggiuntive che ogni ospite può portare\n          all’evento con se, dalla sezione “guest list” si può aumentare o diminuire il numero nella\n          apposita voce “Numero accompagnatori per persona”. "
      ),
      _c("br"),
      _vm._v(
        "\n          Qualora vi siano situazioni eccezionali in cui uno specifico ospite presente sulla lista debba\n          avere un numero particolare di accompagnatori differente dal resto della lista, si può\n          modificare il numero massimo manualmente selezionando l’ospite nella lista e premendo su\n          “modifica invitato” (a destra sulla riga dell’invitato stesso). "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("COME FUNZIONA IL CONTEGGIO DEL +1")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n          Nella lista degli invitati è presente la voce “+1” che indica il numero di accompagnatori per\n          persona. Il dato viene presentato inizialmente in forma es.“0/2” in base al numero limite\n          che viene impostato. Il primo numero si aggiornerà automaticamente nel momento in cui\n          le hostess effettueranno il check-in agli accompagnatori dell’invitato, mentre Il secondo\n          numero si aggiornerà quando l’invitato comunicherà attraverso la pagina di conferma\n          dell’invito il numero dei suoi accompagnatori effettivi. "
      ),
      _c("br"),
      _vm._v(
        "\n          (es, se viene impostato un massimo di “3” ospiti per invitato, inizialmente si troverà “0/3”,\n          quando però l’invitato confermerà ed ad esempio indicherà “2” ospiti aggiuntivi nelle\n          apposite voci nella pagina di conferma, il dato si aggiornerà diventando “0/2”, poi in fase di\n          check-in il dato si aggiornerà diventando “1/2”o “2/2” con l’avvenuta presenza degli\n          accompagnatori all’evento).\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          È possibile che nella lista caricata siano presenti invitati ripetuti più volte o che si voglia\n          aggiungere un invitato già presente nella lista. Al verificarsi di questa situazione il sistema\n          riconoscerà i duplicati solo se presentano identica email, infatti si accettano casi di individui\n          omonomi. "
      ),
      _c("br"),
      _vm._v(
        "\n          Se questo accade, alla fine della fase di caricamento il sito aprirà un apposito banner che\n          notificherà l’avvenuta individuazione di duplicati, mostrerà il numero di individui non caricati\n          e proporrà un foglio excel contenente tutti i nominativi non inseriti nella lista.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }