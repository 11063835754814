var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.string.externalReference._title) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-container accordion-content" }, [
        _c("small", [_vm._v(_vm._s(_vm.string.externalReference._subtitle))]),
        _vm._v(" "),
        _vm.componentDisabled
          ? _c(
              "div",
              {
                staticClass: "disable-layer",
                on: { click: _vm.disableLayerClicked },
              },
              [_vm._v("\n \n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.inAction
          ? _c("div", { staticClass: "inaction-layer" }, [
              _vm._v("\n \n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-section" }, [
          _c("div", { staticClass: "form-section" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              staticClass: "form-control input-lg",
              attrs: {
                type: "url",
                placeholder: _vm.string.externalReference._placeholder,
                name: "purchase_url",
              },
              domProps: { value: _vm.inputValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-section" }, [
            _c(
              "button",
              {
                staticClass: "save",
                attrs: { type: "submit" },
                on: { click: _vm.submit },
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.externalReference._submit) +
                    "\n          "
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }