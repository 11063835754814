var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "form-section-title first" }, [
      _vm._v(
        "\n        " +
          _vm._s(_vm.string.organizerSettings._profileHeader) +
          "\n        "
      ),
      _c("small", [
        _vm._v(_vm._s(_vm.string.organizerSettings._profileSubHeader)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "smallseparator" }),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: {
          id: "user-data-form",
          onsubmit: "return handleSaveOrganizer()",
        },
      },
      [
        _c("h4", { staticClass: "form-section-title no-border-bottom" }, [
          _vm._v(_vm._s(_vm.string.organizerSettings._profilePersonalSection)),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.name,
              expression: "organizer.name",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.string.organizerSettings._placeholderName,
          },
          domProps: { value: _vm.organizer.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "name", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.surname,
              expression: "organizer.surname",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.string.organizerSettings._placeholderSurname,
          },
          domProps: { value: _vm.organizer.surname },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "surname", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("h4", { staticClass: "form-section-title no-border-bottom" }, [
          _vm._v(_vm._s(_vm.string.organizerSettings._profileCompanySection)),
        ]),
        _vm._v(" "),
        _c("small", { staticClass: "description" }, [
          _vm._v(_vm._s(_vm.string.organizerSettings._showInApp)),
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.company,
              expression: "organizer.company",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.string.organizerSettings._placeholderCompanyName,
          },
          domProps: { value: _vm.organizer.company },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "company", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.vatNumber,
              expression: "organizer.vatNumber",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.string.organizerSettings._placeholderVatNumber,
          },
          domProps: { value: _vm.organizer.vatNumber },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "vatNumber", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.site,
              expression: "organizer.site",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Website" },
          domProps: { value: _vm.organizer.site },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "site", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.email,
              expression: "organizer.email",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "E-mail" },
          domProps: { value: _vm.organizer.email },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "email", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.organizer.city,
              expression: "organizer.city",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            placeholder: _vm.string.organizerSettings._placeholderCity,
          },
          domProps: { value: _vm.organizer.city },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.organizer, "city", $event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "settings-btn-area" }, [
          _c(
            "a",
            {
              staticClass: "btn gray-btn save",
              attrs: {
                loading: _vm.savingChanges,
                id: "user-data-save-btn",
                type: "button",
              },
              on: {
                click: function (e) {
                  e.preventDefault()
                  _vm.handleSaveOrganizer()
                },
              },
            },
            [
              _vm._v(
                "\n               " +
                  _vm._s(_vm.string.organizerSettings._modify) +
                  "\n            "
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("h4", { staticClass: "form-section-title no-border-bottom" }, [
          _vm._v(
            _vm._s(_vm.string.organizerSettings._profileBackgroundSection)
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "Background" },
          [
            _c("BackgroundImage", {
              attrs: {
                image: _vm.organizer.backgroundImage,
                "organizer-id": this.organizer.id,
                type: "organizer",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("h4", { staticClass: "form-section-title no-border-bottom" }, [
          _vm._v(_vm._s(_vm.string.organizerSettings._profileBannerSection)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "banner" },
          [
            _c("BannerImage", {
              attrs: {
                image: _vm.organizer.profileImage,
                "organizer-id": this.organizer.id,
                type: "organizer",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }