<template>
  <!-- <PublicLayout> -->
    <div class="center-it container">
        <div class= "error-title" >{{string.oauth._redirecting_you}}</div>
        <br/>
        <!-- <span class= "error-description" v-text="string.errorsNotFound._page_not_found_desc"></span>
        <router-link
          :to="{path: '/'}"
          tag="a">
          <b>{{ string.errorsNotFound._go_to_home }}</b>
        </router-link> -->
     </div>
  <!-- </PublicLayout> -->
</template>

<script>
import PublicLayout from '../layouts/PublicLayout.vue';

export default {
    name: 'oauth-redirect',
    components: {
        PublicLayout
    },
    data (){
      return {
        componentName: 'oauth-redirect',
        string: {}
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });
    }
};
</script>

<style>
 .center-it {
    width: 100%;
    height: 100%;
    padding: 200px;
    color: #f1f1f1;
 }
 .error-title {
    font-size: 32px;
    text-align: center;
    width: 100%;
 }
 .error-description {
    font-size: 24px;
    text-align: left;
 }
</style>