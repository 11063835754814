var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "penguin-background-image", style: _vm.dynamicBackground },
    [
      !_vm.pageLoaded
        ? _c("div", { attrs: { id: "preloader" } }, [
            _c("div", { staticClass: "pulse" }),
          ])
        : _c(
            "el-container",
            {
              class: _vm.enableGradient
                ? "bg-transparent-gradient-1 bg-transparent-7-dark"
                : "bg-transparent-7-dark",
              staticStyle: { padding: "0" },
              attrs: { direction: "vertical" },
            },
            [
              _c(
                "el-header",
                { attrs: { height: "115px" } },
                [
                  _c("public-header", {
                    attrs: {
                      "logo-source": _vm.logoSource,
                      "enable-link": _vm.enableLink,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-main",
                { staticClass: "penguin-public-main" },
                [_vm._t("default"), _vm._v(" "), _c("public-footer")],
                2
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }