<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Settings
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/en-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/en-impostazioni">
              Settings
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to manage the privacy policy
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to set a maximum number of registrations 
            </a>
          </li>
          <!--li><a href="#article-3" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come usare un “endpoint di conferma esterno”</a></li -->
          <!--li><a href="#article-4" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come personalizzare i dati aziendali riportati in fondo alle email della campagna</a></li-->
          <!--<li><a href="#article-5" data-uk-scroll="duration: 400; offset: 140" class="hvr-forward"><span data-uk-icon="icon: arrow-right"></span> Come personalizzare ogni invito con nome e cognome dell’invitato</a></li> -->
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to set-up an existing email or domain to send verified email campaigns
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to check who has unsubscribed from your mailing list
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to add a new team member/user
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to manage the privacy policy
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            From Dashboard, in the Settings section, you can create one or more privacy policies that will be displayed 
            to the guests before being able to RSVP. <br>
            A privacy policy can be active or not active, mandatory or not mandatory, and "limited to this event".<br>
            - If the privacy policy is mandatory, the guest must necessarily flag it to RSVP. <br>
            - If the privacy policy is mandatory and the guest has already flagged it, the guest will not see the privacy 
              policy in subsequent events.<br>
            - If the privacy policy is not mandatory, the guest can decide whether to flag it or not; if the guest doesn't 
              flag it, he won't see it again at the next event.<br>
            - If the non-mandatory privacy policy becomes mandatory, the guest will not see the privacy policy if it has 
              already been flagged; if the guest has not flagged it, they will see it and must flag it to RSVP (mandatory).<br>
            - If the privacy policy is "limited to this event" it will be displayed both to those who have flagged it and to 
              those who have never flagged it; the privacy policy will be re-proposed to the guest even if this has been mandatory 
              in past events.<br>
            - If the privacy policy is "limited to this event", it will only be relevant for the event it was created for.<br><br>
            The privacy policy tool you are using is merely a tool made available to you and to legal teams to facilitate management. 
            Penguinpass Srl assumes no responsibility for improper and/or incorrect use of the tool.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to set a maximum number of registrations 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            In the "settings" section it is possible to set a maximum limit on the number of individuals who can register/purchase a ticket 
            for a public event through the appropriate item where you can set the maximum number. <br>
            Once the number of registrations for the event has reached the maximum set, including +1s, the system will prevent further 
            users from registering for a ticket.
          </p>
        </article>
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come usare un “endpoint di conferma esterno”<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Se l’evento è di tipo “content”, e si inserisce un link in questa sezione, i visitatori nella “guest
APP” non troveranno l’opzione “join” selezionando l’evento ma direttamente si aprirà il link
inserito nel campo “endpoint di conferma esterno”.</p>
        </article -->
        <!--article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come personalizzare i dati aziendali riportati in fondo alle email della campagna<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Quando gli invitati ricevono l’email di invito, si trovano a chiusura di essa dopo la scritta
obbligatoria <br>
“Ricevi questa mail perché sei iscritto/a alla mailing list di NOME COGNOME.
Se non vuoi più ricevere sue comunicazioni clicca su unsubscribe from this list | disiscriviti da
questa lista.” <br>
Un campo riportante le informazioni immesse nella fase di iscrizione, “(NOME COGNOME, P.I
NAN, USERNAME)” <br>
qualora si voglia modificare o far riportare le info dell’azienda invece di un individuo si può
semplicemente andare a modificare da dashboard selezionando nella pagina (in alto nell’angolo
a destra) l’icona dell’user. Aprendo poi il proprio account si può andare a modificare i vari campi
che sono direttamente collegati a quelli riportati sulle email, alterando cosi ciò che verrà
riportato nelle email.</p>
        </article -->
        <!--
        <article class="uk-article uk-margin-xlarge-top">
            <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">Come personalizzare ogni invito con nome e cognome dell’invitato<a href="#" data-uk-totop data-uk-scroll="duration: 400" class="tm-totop uk-float-right uk-margin-small-top"></a> <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
            <p>Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores
                alias consequatur aut perferendis doloribus asperiores repellat.</p>
        </article>
-->
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to set-up an existing email or domain to send verified email campaigns
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            By opening the side menu of the dashboard, it is possible to access your profile settings by clicking on the 
            first icon in the list. You will find the name with which you registered to the system. By clicking on the "Domain" 
            button you can choose between "Domain" and "Email".<br>
            By choosing to verify the email, you will only be able to send emails from the verified address. You will have to enter
            your email in the space provided and click on the "Verify email" button, you will receive an email to the address entered 
            with a verification link to click. You will be redirected to a Penguinpass web page with the word success, at this point 
            you just need to refresh the table that you will find in the "Domain" section of the settings and you will see that the icon 
            next to the email you’ve entered will change from orange to green. Going to any event you will see that in the Mail Marketing 
            section you can select the associated address that your guests will see as the sender of the invitation.<br>
            By choosing to verify the domain instead, you will be able to send emails from all the addresses associated with that domain 
            (ex. Marketing@dominio.com, info@dominio.com). To proceed with the association you must enter the domain in the appropriate space 
            (ex. Domain.com), by clicking on the "Check Domain" button, a table containing four values ​​will automatically be generated:<br>
            - The TXT value for sending permission to send an email on behalf of your domain.<br>
            - The three CNAME values ​​are for your domain's DKIM settings.<br>
            With these two configurations, you will be able to send emails using the Penguinpass Email Server without losing the ability 
            to send emails with your original mail server.<br>
            The table values ​​will need to be added and not replaced in your domain's DNS configurations. Any replies will be sent to your original 
            email server. To reduce the spam rate, CNAME values ​​are also marked as required. After verifying your domain, you will be able to use any 
            username in your domain as the email sender such as “any_username @ yourdomain.com”. <br>
            Your verification request will expire after 48 hours. In such cases, a new verification request must be submitted. The domain will be 
            successfully associated and ready to use if, when refreshing the table with the values, the icon will have turned green.<br>
            Similarly to emails, you can select the associated domain from the marketing section, the part after the at sign (@) will remain blocked but 
            you can change the part before the @ sign (ex. Info, marketing, sales, etc.).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to check who has unsubscribed from your mailing list
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Opening up the menu on the left side of the dashboard, it is possible to go to your profile’s settings by 
            clicking on the first icon of the menu. Here you can find the name you have registered with. By clicking on the 
            “unsubscribers” button, you can find a table with all the contacts of your guests that have clicked on the link 
            “unsubscribe from YOUR NAME’s mailing list..” in the footer of your emails. 
             It’s possible to export this list on an excel spreadsheet, by clicking on the “download all list” button, above the table.<br>
            Once the guests become “unsubscribers”, you will be able to include them in the guest list of your event but the system will 
            automatically exclude them from the list of the guests that can receive communications in the marketing mail section.<br>
            Guests will be able to re-subscribe in the same way in which they unsubscribed, by clicking on the link of the footer of an 
            email they have already received.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to add collaborators to my team
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Opening up the side menu, it is possible to access a section called “My team” where you can add new members and visualize/manage 
            those already added.<br>
            To add a new team member it will be possible to just add their email in the field, select a role for the new member and when finished, 
            click on the “Send invite” button.<br>
            The roles are the following:<br>
            owner: has full power and is the owner of the account. <br>
            staff manager: can create new events and visualize/modify all present events on the account.<br>
            staff: pcan modify only the events that are assigned to them by the owner or staff manager.<br>
            The person invited will receive an email and to accept the invite to the team, they will need to get registered with their name, last name, 
            and password. Finally, after accepting the privacy policies and the terms and conditions, click on the confirmation button. Once confirmed, 
            the new member will be able to access the dashboard with their credentials.<br>
            From the table, the owners will be able to de-activate and re-activate their team members. Both staff managers and the owners will be able 
            to select the “staff” members, and by clicking on the “Bulk Action” button, they will be able to assign/delete the events that staff members 
            can view and manage from their dashboard.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskImpostazioniEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
