var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n          Impostazioni\n        "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/it-home" } }, [
                _vm._v("\n              Support\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come gestire la privacy policy\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come impostare un limite massimo di conferme\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come collegare una mail o un dominio per l'invio delle mail\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-7")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come visualizzare le persone che si sono disiscritte dalla mia mailing list\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-8")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come aggiungere collaboratori al mio team\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come gestire la privacy policy\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come impostare un limite massimo di conferme\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come collegare una mail o un dominio per l'invio delle mail\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-7",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come visualizzare le persone che si sono disiscritte dalla mia mailing list\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-8",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come aggiungere collaboratori al mio team\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/it-impostazioni" } }, [
        _vm._v("\n              Impostazioni\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Da Dashboard, nella sezione Impostazioni, è possibile creare una o più privacy policy che\n            verranno visualizzate dall’invitato prima di poter effettuare RSVP. "
      ),
      _c("br"),
      _vm._v(
        "\n            Una privacy policy può essere attiva o disattiva, obbligatoria o non obbligatoria e “limitata a\n            questo evento”; qui sotto la spiegazione del funzionamento. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy è obbligatoria, l’invitato deve per forza flaggarla per fare RSVP. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy è obbligatoria ma l’invitato l’ha già flaggata, l’invitato non vedrà, negli eventi\n            successivi, la privacy policy. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy non è obbligatoria, l’invitato può decidere se flaggarla o no; se non la flagga,\n            al prossimo evento non la rivedrà. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy non obbligatoria diventa obbligatoria, l’invitato non visualizzerà la privacy\n            policy se questa è già stata flaggata; se l’invitato non l’ha flaggata, la vedrà e dovrà flaggarla\n            obbligatoriamente per effettuare RSVP. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy è “limitata a questo evento” verrà visualizzata sia da chi l’ha flaggata, sia da\n            chi non l’ha mai flaggata; la privacy policy verrà riproposta all’invitato anche se questa è stata, in\n            eventi passati, obbligatoria. "
      ),
      _c("br"),
      _vm._v(
        "\n            - Se la privacy policy è “limitata a questo evento”, rimarrà in memoria solo nell’evento in cui è\n            stata creata. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n            Il tool relativo alle privacy policy che state utilizzando è meramente uno strumento messo a\n            vostra disposizione e dei team legal per facilitare la gestione. Penguinpass srl non si assume\n            alcuna responsabilità per un utilizzo improprio e/o scorretto del tool.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Nella sezione “impostazioni” è possibile porre un limite massimo al numero di individui che possono\n            registrarsi/acquistare un biglietto per un evento pubblico attraverso l’apposita voce dove inserire\n            il numero massimo. "
      ),
      _c("br"),
      _vm._v(
        "\n            Arrivato il numero di registrazioni all’evento a quello massimo impostato, inclusi i +1, il sistema impedirà\n            ad ulteriori utenti di ottenere un ingresso.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            Aprendo il menu laterale del dashboard è possibile accedere dai settings del proprio profilo cliccando\n            sulla\n            prima icona dell\'elenco accanto alla quale troverete il nome con cui vi siete registrati al sistema. Così\n            facendo accederete alla pagina delle impostazioni generali dell account, cliccando sul bottone "Dominio"\n            potrete\n            selezionare un metodo di identità tra "Dominio" e "Email". '
      ),
      _c("br"),
      _vm._v(
        "\n            Scegliendo di verificare la mail, potrete mandare mail solo dall'indirizzo verificato. Dovrete inserire la\n            vostra mail nell'apposito spazio e cliccare sul bottone \"Verifica mail\", riceverete una mail all'indirizzo\n            inserito con un link di verifica da cliccare. Verrete reindirizzati a una pagina web Penguinpass con la\n            scritta\n            success, a questo punto vi basterà refreshare la tabella che troverete nella sezione \"Dominio\" delle\n            impostazioni e vedrete che l'icona accanto alla mail inserita passerà da arancione a verde. Andando su un\n            qualsiasi evento vedrete che nella sezione Mail Marketing potrete selezionare l'indirizzo associato che i\n            vostri\n            ospiti vedranno come mittente dell'invito."
      ),
      _c("br"),
      _vm._v(
        "\n            Scegliendo di verificare il dominio potrete inviare mail da tutti gli indirizzi associati a quel dominio\n            (ex.\n            marketing@dominio.com, info@dominio.com). Per procedere con l'associazione dovrete inserire il dominio\n            nell'apposito spazio (ex. dominio.com), cliccando sul bottone \"Verifica Dominio\" si genererà automaticamente\n            una\n            tabella contenente quattro valori:"
      ),
      _c("br"),
      _vm._v(
        "\n            - Il valore TXT è per l'invio dell'autorizzazione per l'invio di e-mail per conto del tuo dominio."
      ),
      _c("br"),
      _vm._v(
        "\n            - I tre valori CNAME sono per le impostazioni DKIM del tuo dominio."
      ),
      _c("br"),
      _vm._v(
        "\n            Con queste due configurazioni, sarai in grado di inviare e-mail utilizzando Penguinpass Email Server senza\n            perdere la capacità di inviare e-mail con il tuo server di posta originale."
      ),
      _c("br"),
      _vm._v(
        "\n            I valori della tabella dovranno essere aggiunti e non sostituiti nelle configurazioni DNS del vostro\n            dominio.\n            Tutte le risposte alle e-mail inviate torneranno comunque al tuo server e-mail originale. Per ridurre la\n            percentuale di spam, anche i valori CNAME sono contrassegnati come obbligatori.\n            Dopo aver verificato il tuo dominio, sarai in grado di utilizzare qualsiasi nome utente nel tuo dominio come\n            mittente e-mail come `any_username@yourdomain.com`.\n            La tua richiesta di verifica scadrà dopo 48 ore. In tali casi, è necessario inviare una nuova richiesta di\n            verifica. Il dominio sarà associato con successo e pronto all'utilizzo quando refreshando la tabella con i\n            valori, accanto a ognuno di essi l'icona sarà diventata verde."
      ),
      _c("br"),
      _vm._v(
        "\n            Analogamente alle mail, potrete selezionare il dominio associato dalla sezione marketing, la parte dopo la\n            chiocciola (@) rimarrà bloccata ma potrete inserire la parte prima della chiocciola che vorrete (ex. info,\n            marketing, sales ecc.).\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            Aprendo il menu laterale del dashboard è possibile accedere dai settings del proprio profilo cliccando sulla\n            prima icona dell\'elenco accanto alla quale troverete il nome con cui vi siete registrati al sistema. Così\n            facendo accederete alla pagina delle impostazioni generali dell account, cliccando sul bottone\n            "Unsubscribers" visualizzerete una tabella con i contatti di tutti i vostri ospiti che dal footer delle\n            vostre mail avranno cliccato sul link "disiscriviti dalla mailing list di..". Potrete esportare la lista in\n            formato excel cliccando sul bottone "Scarica tutto l\'elenco" che troverete sopra la tabella.'
      ),
      _c("br"),
      _vm._v(
        '\n            Nel momento in cui un ospite diventa "unsubscribed" lo potrete caricare nella guestlist del vostro evento ma\n            il sistema lo escluderà automaticamente dalla lista degli ospiti a cui voi potrete inviare comunicazioni\n            dalla sezione Mail marketing.'
      ),
      _c("br"),
      _vm._v(
        "\n            Gli ospiti potranno reiscriversi nello stesso modo in cui si sono iscritti, ovvero cliccando sul link nel\n            footer di una comunicazione mail già ricevuta.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            Aprendo il menu laterale del dashboard è possibile accedere alla sezione chiamata "Il mio team" dove\n            potrete aggiungere nuovi membri e visualizzare/gestire quelli già aggiunti.'
      ),
      _c("br"),
      _vm._v(
        "\n            Per aggiungere un nuovo membro al vostro team vi basterà inserire la sua mail nell'apposito spazio,\n            selezionare un ruolo e cliccare sul bottone \"Invia l'invito\". "
      ),
      _c("br"),
      _vm._v("\n            I ruoli sono i seguenti: "),
      _c("br"),
      _vm._v(
        "\n            owner: ha pieni poteri ed è il proprietario dell'account."
      ),
      _c("br"),
      _vm._v(
        "\n            staff manager: può creare nuovi eventi e visualizzare/modificare tutti gli eventi presenti sull'account."
      ),
      _c("br"),
      _vm._v(
        "\n            staff: potrà modificare solo gli eventi che gli vengono assegnati da owner e staff manager."
      ),
      _c("br"),
      _vm._v(
        "\n            L'invitato riceverà una mail per accettare l'invito al team, dovrà compilare nome, cognome e password,\n            infine accettando le privacy policies e termini e condizioni potrà cliccare sul bottone di conferma. Una\n            volta confermato l'invito il nuovo membro potrà accedere al dashboard con le proprie credenziali. "
      ),
      _c("br"),
      _vm._v(
        '\n            Dalla tabella, gli owners potranno disattivare e riattivare i propri team members. Sia staff manager che\n            owners potranno invece selezionare i membri di tipo "staff", e cliccando sul bottone "Azione in blocco",\n            potranno assegnare/togliere loro eventi che potranno visualizzare dal proprio dashboard.\n          '
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }