<template>
  <div>
    <div ref="container">
      <div class="layer">
        <section class="tm-margin-large-top">
          <div class="uk-container uk-text-center">
            <p class="uk-margin-remove-bottom tm-overtitle uk-margin-top uk-text-uppercase letter-spacing-l"
               data-uk-scrollspy="cls: uk-animation-slide-bottom-medium">
              Penguinpass support
            </p>
            <h1 class="uk-margin-small-top uk-heading-hero"
                data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 40">
              Come possiamo aiutarti?
            </h1>
            <div
              class="uk-grid-medium uk-child-width-1-3@m uk-grid-small uk-grid-match tm-margin-large-top uk-text-center uk-grid uk-scrollspy-inview uk-animation-slide-bottom-medium"
              data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; delay: 80">
              <div>
                <router-link to="/helpdesk/it-quickstart"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: home; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <polygon
                          points="18.65 11.35 10 2.71 1.35 11.35 0.65 10.65 10 1.29 19.35 10.65">
                        </polygon>
                        <polygon
                          points="15 4 18 4 18 7 17 7 17 5 15 5">
                        </polygon>
                        <polygon
                          points="3 11 4 11 4 18 7 18 7 12 12 12 12 18 16 18 16 11 17 11 17 19 11 19 11 13 8 13 8 19 3 19">
                        </polygon>
                      </svg>
                    </span>
                  </span>
                  <p>Quick Start</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/it-eventi"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: calendar; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <path
                          d="M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z">
                        </path>
                        <rect
                          width="1" height="3" x="6"
                          y="2">
                        </rect>
                        <rect width="1" height="3" x="13"
                              y="2">
                        </rect>
                      </svg>
                    </span>
                  </span>
                  <p>Eventi</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/it-virtualroom"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: tv; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <rect x="7"
                              y="16"
                              width="6"
                              height="1">
                        </rect>
                        <rect
                          fill="none" stroke="#000" x="0.5"
                          y="3.5" width="19" height="11">
                        </rect>
                      </svg>
                    </span>
                  </span>
                  <p>Virtual Room e Webinar</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/it-listeinvitati"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: list; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <rect x="6" y="4"
                              width="12"
                              height="1">
                        </rect>
                        <rect x="6" y="9"
                              width="12"
                              height="1">
                        </rect>
                        <rect x="6" y="14"
                              width="12"
                              height="1">
                        </rect>
                        <rect
                          x="2" y="4" width="2"
                          height="1"></rect> <rect x="2" y="9" width="2"
                                                   height="1"></rect> <rect x="2"
                                                                            y="14"
                                                                            width="2"
                                                                            height="1"></rect>
</svg>
</span>
</span>
                  <p>Liste Invitati</p>
                </router-link>
              </div>
              <div>
                <router-link to="/helpdesk/it-campagneemail"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: mail; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <polyline
                          fill="none" stroke="#000" points="1.4,6.5 10,11 18.6,6.5">
                        </polyline>
                        <path
                          d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z">
                        </path>
                      </svg>
                    </span>
                  </span>
                  <p>Campagne Email</p>
                </router-link>
              </div>
              <!--div>
                  <a href="bigliettieallegati.html" class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                      <span class="uk-icon-forder uk-border-rounded"><--span data-uk-icon="icon: tag; ratio: 2"></span></span>
                      <p>Biglietti e Allegati</p>
                  </a>
              <div -->
              <div>
                <router-link to="/helpdesk/it-impostazioni"
                             class="uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded">
                  <span class="uk-icon-forder uk-border-rounded">
                    <span data-uk-icon="icon: cog; ratio: 2"
                          class="uk-icon">
                      <svg width="40" height="40"
                           viewBox="0 0 20 20"
                           xmlns="http://www.w3.org/2000/svg"
                           ratio="2">
                        <circle
                          fill="none" stroke="#000" cx="9.997"
                          cy="10" r="3.31"></circle>
                        <path fill="none" stroke="#000"
                              d="M18.488,12.285 L16.205,16.237 C15.322,15.496 14.185,15.281 13.303,15.791 C12.428,16.289 12.047,17.373 12.246,18.5 L7.735,18.5 C7.938,17.374 7.553,16.299 6.684,15.791 C5.801,15.27 4.655,15.492 3.773,16.237 L1.5,12.285 C2.573,11.871 3.317,10.999 3.317,9.991 C3.305,8.98 2.573,8.121 1.5,7.716 L3.765,3.784 C4.645,4.516 5.794,4.738 6.687,4.232 C7.555,3.722 7.939,2.637 7.735,1.5 L12.263,1.5 C12.072,2.637 12.441,3.71 13.314,4.22 C14.206,4.73 15.343,4.516 16.225,3.794 L18.487,7.714 C17.404,8.117 16.661,8.988 16.67,10.009 C16.672,11.018 17.415,11.88 18.488,12.285 L18.488,12.285 Z">
                        </path>
                      </svg>
                    </span>
                  </span>
                  <p>Impostazioni</p>
                </router-link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <section ref="toc" class="uk-margin-xlarge-top">
      <div class="uk-container">
        <h2 class="uk-margin-medium-bottom">
          Domande più frequenti
        </h2>
        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Cosa sono dashboard, Check-inApp e GuestApp?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000"
                        x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Quando attivo un evento cosa succede?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come funziona il Qr Code per accedere all’ evento?
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Posso aggiungere dettagli/attributi personalizzati sui miei invitati?
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Cosa sono dashboard, check-inAPP e guestApp?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Dashboard, Guest e Check-in app sono I tre strumenti su cui si basa il sistema Penguinpass;
            Il primo è utilizzato dagli organizzatori tramite accesso web per creare e gestire gli eventi
            avendo così in unico strumento una visione completa e organizzata. <br>
            Le due app per mobile sono invece rivolte agli utilizzatori del servizio: la Check-inApp
            permette alle hostess di gestire gli accessi in loco, le quali tramite questo supporto dinamico
            possono controllare e aggiornare le liste ospiti in tempo reale. Mentre la GuestApp è pensata e sviluppata
            per gli invitati che grazie all’app possono rimanere sempre informati e
            aggiornati sugli eventi e accedere alle location con facilità e rapidità.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Quando attivo un evento cosa succede?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nel momento in cui un evento viene attivato l'organizzatore potrà utilizzare la funzione di mail marketing
            per inviare inviti e comunicazioni ai propri ospiti.
            <br> Inoltre una volta attivato l'evento sarà disponibile sulle GuestApp e quindi, visibile ai soli
            invitati, in caso di evento privato, a chiunque se è un evento pubblico.
            <br>
            È importante notare che non è possibile utilizzare le funzionalità della Check-inApp se l’evento non è stato
            attivato.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla
            lista?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Quando l’invitato confermerà la propria partecipazione avrà (se da dashboard
            l’organizzatore lo consente) la possibilià di inserire “nome, cognome e email” dei suoi
            eventuali accompagnatori. Questi nomi e dati si potranno trovare semplicemente scaricando
            il foglio excel tramite “donwload tutta la lista” nella sezione “guest list” sotto forma di nota.
            Prelevando questi nominativi e risistemandoli in un nuovo foglio excel sull’apposito formato
            “template per importazione xls” e ricaricandolo. Cosi facendo si potrà inviare email anche
            agli accompagnatori dei tuoi ospiti se necessario, oppure semplicemente si potranno
            visualizzare tramite check-inApp.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come funziona il Qr code per accedere all’ evento?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Spesso vi è la necessità o volontà di gestire le entrate agli eventi tramite QR Code, questo
            vuol dire che gli invitati che invieranno i propri RSVP riceveranno un email della loro
            conferma contenente dettagli aggiuntivi sull’evento e il QR Code personale per accedere.
            Secondo questa logica di funzionamento, è quindi necessario impostare da dashboard l’invio
            di una mail di conferma, e inserire in essa il contenuto dinamico (QR Code).
          </p>
          <h3>
            Centrare un Qr code nella e-mail
          </h3>
          <p>
            Quando si inserisce il “contenuto dinamico” nel “modulo” vuoto in Emailbuilder, se si utilizza
            un “modulo unico” (il primo nella sezione moduli) il Qr Code verrà visualizzato sul lato sinistro
            dell’email, se si desidera che invece venga visualizzato al centro sarà necessario
            semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in tre sezioni” (il settimo
            della lista moduli) e inserire il contenuto dinamico in quella centrale.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Posso aggiungere dettagli/attributi personalizzati sui miei invitati?
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('toc')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per inserire un attributo specifico e personalizzato alla lista degli invitati, sarà sufficiente modificare
            nel “template per importazione xls” (scaricato dal sito come sopra spiegato) la colonna “custom
            note***” ponendo al suo posto il nome dell’attributo desiderato (es. da “custom note***” a “paese
            d’origine” o “impresa”). <br>
            Se si necessita di più attributi-colonna, è possibile aggiungerne altri in seguito alla colonna “custom
            note***” <br>
            In caso ci si dimentichi di aggiungere i campi extra al momento del caricamento dei guest, è possibile
            aggiungere nuove colonne andando nella sezione impostazioni evento, espandendo la sezione "Modifica custom
            fields" sarà possibile aggiungere nuovi attributi. Una volta aggiunti basterà selezionare la voce Check-in
            app per rendere il campo visibile nella app di check-in.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskHomeIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["container"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
