<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top tm-faq">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Manuali
        </h1>
        <hr class="uk-margin-remove">
        <ul class="uk-list-large uk-margin-medium-top">
          <li>
            <a class="hvr-forward" href="/download/manuals/Onboarding_manual_ITA.pdf" target="_blank">
                <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" ratio="1">
                    <polyline
                        fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                    </polyline>
                    <line fill="none" stroke="#000" x1="4"
                            y1="9.5" x2="15"
                            y2="9.5">
                    </line>
                    </svg>
                </span>
              Manuale On Boarding
            </a>
          </li>
          <li>
            <a class="hvr-forward" href="/download/manuals/Check_in_app_manual_ITA.pdf" target="_blank">
                <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" ratio="1">
                    <polyline
                        fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                    </polyline>
                    <line fill="none" stroke="#000" x1="4"
                            y1="9.5" x2="15"
                            y2="9.5">
                    </line>
                    </svg>
                </span>
              Manuale Check-in app 
            </a>
          </li>
          <li>
            <a class="hvr-forward" href="/download/manuals/Badge_printing_ITA.pdf" target="_blank">
                <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                    <svg width="20" height="20" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg" ratio="1">
                    <polyline
                        fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                    </polyline>
                    <line fill="none" stroke="#000" x1="4"
                            y1="9.5" x2="15"
                            y2="9.5">
                    </line>
                    </svg>
                </span>
              Manuale Badge Printing 
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "ManualsIT",
  data() {
    return {};
  },
};
</script>
