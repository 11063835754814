export default function fromObjectLiteralToArray(objectLiteral = {}) {
  let outputArray = [];
  try {
    for (const [key, value] of Object.entries(objectLiteral)) {
      outputArray.push([key, value]);
    }
    console.log("OutputARRAY + " + JSON.stringify(outputArray));
  } catch (error) {
    console.log(error.message);
    outputArray = [];
  }
  return outputArray;
}
