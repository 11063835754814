<template>
  <button :disabled="loading" @click="exportICS">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    getData: {
      type: Function,
      required: false,
      default: () => Promise.resolve([])
    },
    fileName: {
      type: String,
      required: false,
      default: "calendar"
    }
  },
  data() {
    return {
      name: "export-ics",
      componentName: "export-ics",
      loading: false
    };
  },
  methods: {
    /**
     * ! Event data in $ics plugin(vue-ics) is stored globally. To be able to reuse this component(ExportICS), store data in this component(ExportICS) and populate $ics plugin(vue-ics) only when its required to download
     */
    async exportICS() {
      this.loading = true;
      this.getData()
        .then(data => {
          this.loading = false;
          if (!data || data.length < 0) {
            this.$notify({
              title: "Oops !",
              message: this.$root.timeSlotManager._nothing_to_export,
              position: "bottom-right",
              type: "warning",
              duration: 3000
            });
            return;
          }
          this.$ics.removeAllEvents();

          for (const event of data) {
            const { title, description, startDate, endDate } = event;
            this.$ics.addEvent(
              "", //language,
              title, //subject,
              description,
              "", //location,
              startDate, //begin,
              endDate, // stop,
              "", // url,
              null, // organizer
            );
          }

          this.$ics.download(this.fileName);
        })
        .catch(error => {
          console.error("[ExportICS]", error.message);
          this.loading = false;
          this.$notify({
            title: "Oops !",
            message: "Export Failure",
            position: "bottom-right",
            type: "warning",
            duration: 3000
          });
        });
    }
  }
};
</script>

<style></style>
