var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "page-container", attrs: { direction: "vertical" } },
    [
      _c("div", { staticClass: "row createevent__title" }, [
        _c("div", { staticClass: "col-xs-12 col-sm-12 col-lg-12" }, [
          _c("div", { staticStyle: { background: "transparent" } }, [
            _c(
              "h1",
              {
                staticStyle: {
                  "font-weight": "bold",
                  "text-transform": "uppercase",
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.string.teamPage._page_title) +
                    "\n        "
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.$can("create", "new_user")
        ? _c(
            "h4",
            {
              staticClass: "form-section-title",
              staticStyle: { "margin-top": "30px" },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.string.teamPage._invite_title) + "\n    "
              ),
              _c("small", [
                _vm._v(_vm._s(_vm.string.teamPage._invite_subtitle)),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("create", "new_user")
        ? _c("div", { staticClass: "smallseparator" })
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("create", "new_user")
        ? _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c("el-col", { attrs: { span: 16 } }, [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newMember.email,
                        expression: "newMember.email",
                      },
                    ],
                    staticClass: "form-control",
                    staticStyle: {
                      "font-size": "22px",
                      "text-transform": "none",
                    },
                    attrs: {
                      type: "text",
                      placeholder: _vm.string.teamPage._new_member,
                    },
                    domProps: { value: _vm.newMember.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newMember, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "add-new-member",
                      attrs: {
                        "popper-class": "default-popper",
                        size: "large",
                        placeholder: _vm.string.teamPage._role,
                      },
                      model: {
                        value: _vm.newMember.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.newMember, "role", $$v)
                        },
                        expression: "newMember.role",
                      },
                    },
                    _vm._l(_vm.roles, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled,
                          hidden: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "add-new-member",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.sendInvitation($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.string.teamPage._invite) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "group-manager-members",
          staticStyle: { "margin-top": "55px" },
        },
        [
          _c("h4", { staticClass: "form-section-title" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.string.teamPage._team_table_title) +
                "\n      "
            ),
            _c("small", [
              _vm._v(_vm._s(_vm.string.teamPage._team_table_subtitle)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "smallseparator" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group-manager-members-list" },
            [
              _c("data-table", {
                ref: "table",
                attrs: {
                  "get-data": _vm.getData,
                  columns: _vm.columns,
                  "selection-enabled": "",
                  "bulk-options": _vm.bulkOptions,
                  "on-bulk-action": _vm.onBulkAction,
                  "can-select-row": _vm.canSelectRow,
                },
                scopedSlots: _vm._u([
                  {
                    key: "email",
                    fn: function (ref) {
                      var row = ref.row
                      return _c("span", {}, [
                        _vm._v(
                          "\n          " + _vm._s(row.email) + "\n        "
                        ),
                      ])
                    },
                  },
                  {
                    key: "role",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        {},
                        [
                          row.edit === true
                            ? _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "component-fade-fast",
                                    mode: "out-in",
                                    appear: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "add-new-member",
                                      attrs: {
                                        "popper-class": "default-popper",
                                        size: "large",
                                        placeholder: _vm.string.teamPage._role,
                                      },
                                      on: {
                                        change: function (e) {
                                          return _vm.updateRole(row, e)
                                        },
                                      },
                                      model: {
                                        value: row.role,
                                        callback: function ($$v) {
                                          _vm.$set(row, "role", $$v)
                                        },
                                        expression: "row.role",
                                      },
                                    },
                                    _vm._l(_vm.roles, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                          disabled: item.disabled,
                                          hidden: item.disabled,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "component-fade-fast",
                                    mode: "out-in",
                                    appear: "",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "add-new-member" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.role.formatEnum()) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    },
                  },
                  {
                    key: "assignedEventsData",
                    fn: function (ref) {
                      var row = ref.row
                      return _c("span", {}, [
                        _c(
                          "div",
                          { staticClass: "event-list" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  id: "listEvent_" + row.id,
                                  width: "250",
                                  trigger: "click",
                                  placement: "bottom",
                                  "popper-class": "event-list-popper",
                                },
                              },
                              [
                                _vm._l(row.assignedEventsData, function (item) {
                                  return _c(
                                    "div",
                                    { key: item.id, staticClass: "text-white" },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(item.name) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "name-wrapper-2",
                                    attrs: { slot: "reference" },
                                    slot: "reference",
                                  },
                                  [
                                    _c(
                                      "el-tag",
                                      { staticClass: "text-white" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.string.datagrid
                                              ? _vm.string.datagrid._list_events
                                              : ""
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ])
                    },
                  },
                  {
                    key: "accountStatus",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        {},
                        [
                          row.edit === true
                            ? _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "component-fade-fast",
                                    mode: "out-in",
                                    appear: "",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "add-new-member",
                                      attrs: {
                                        "popper-class": "default-popper",
                                        size: "large",
                                        placeholder: _vm.string.teamPage._role,
                                      },
                                      on: {
                                        change: function (e) {
                                          return _vm.updateStatus(row, e)
                                        },
                                      },
                                      model: {
                                        value: row.accountStatus,
                                        callback: function ($$v) {
                                          _vm.$set(row, "accountStatus", $$v)
                                        },
                                        expression: "row.accountStatus",
                                      },
                                    },
                                    _vm._l(_vm.statuses, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                          disabled: item.disabled,
                                          hidden: item.disabled,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "transition",
                                {
                                  attrs: {
                                    name: "component-fade-fast",
                                    mode: "out-in",
                                    appear: "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "add-new-member",
                                      staticStyle: {
                                        "text-align": "left !important",
                                        "line-height": "48px",
                                      },
                                    },
                                    [
                                      row.accountStatus === "DISABLED"
                                        ? _c("loading-icon", {
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                          })
                                        : _c(
                                            "font-awesome-layers",
                                            {
                                              staticClass: "fa-lg",
                                              staticStyle: {
                                                "margin-right": "10px",
                                              },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                style:
                                                  _vm.statusStyle[
                                                    row.accountStatus
                                                  ],
                                                attrs: {
                                                  icon: ["fas", "circle"],
                                                  transform: "grow-6",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("font-awesome-icon", {
                                                style: { color: "white" },
                                                attrs: {
                                                  icon: _vm.statusIcons[
                                                    row.accountStatus
                                                  ],
                                                  transform: "shrink-4",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.statusDescription[
                                              row.accountStatus
                                            ]
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                        ],
                        1
                      )
                    },
                  },
                  {
                    key: "Actions",
                    fn: function (ref) {
                      var row = ref.row
                      return _c(
                        "span",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _vm.$can(
                            "update",
                            _vm.$root.setSubject(
                              "team_member",
                              Object.assign({}, row)
                            )
                          ) && row.id !== _vm.user.id
                            ? _c(
                                "a",
                                {
                                  staticClass: "delete_prospect_link",
                                  staticStyle: {
                                    "margin-right": "5px",
                                    color: "white",
                                  },
                                  attrs: {
                                    disabled: row.accountStatus === "DISABLED",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleEditing(row)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    class:
                                      row.edit === false
                                        ? "fa fa-pencil-square-o"
                                        : "fa fa-check",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$can(
                            "delete",
                            _vm.$root.setSubject(
                              "team_member",
                              Object.assign({}, row)
                            )
                          ) && row.id !== _vm.user.id
                            ? _c(
                                "a",
                                {
                                  staticClass: "delete_prospect_link",
                                  staticStyle: { color: "white" },
                                  attrs: {
                                    disabled: row.accountStatus === "DISABLED",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteRow(row)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                            : _vm._e(),
                        ]
                      )
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }