var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: _vm.animationId,
        viewBox: "0 0 " + _vm.UNITS + " " + _vm.UNITS,
        height: _vm.size || "100%",
        width: _vm.size || "100%",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform:
              "rotate(" +
              _vm.rotate +
              "," +
              _vm.UNITS / 2 +
              "," +
              _vm.UNITS / 2 +
              ") " +
              (_vm.reverse
                ? "scale(1,-1) translate(0, -" + _vm.UNITS + ")"
                : ""),
          },
        },
        [
          _c("circle", {
            attrs: {
              cx: _vm.UNITS / 2,
              cy: _vm.UNITS / 2,
              r: _vm.getRadius(),
              stroke: _vm.stroke,
              "stroke-width": _vm.getStrokeWidth(_vm.strokeWidth),
              fill: "none",
              "stroke-dasharray": _vm.getLengths(),
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "progress-path",
            attrs: {
              d: _vm.describeArc(
                _vm.UNITS / 2,
                _vm.UNITS / 2,
                _vm.getRadius(),
                0,
                _vm.activeEnd()
              ),
              fill: "none",
              stroke: _vm.activeStroke,
              "stroke-width": _vm.getStrokeWidth(_vm.activeWidth),
              "stroke-dasharray": _vm.getLengths(),
            },
          }),
        ]
      ),
      _vm._v(" "),
      _vm._t("icon"),
      _vm._v(" "),
      !_vm.$slots.icon
        ? _c(
            "text",
            {
              staticStyle: { "font-size": "8rem" },
              attrs: {
                fill: "currentColor",
                x: "50%",
                y: "50%",
                "text-anchor": "middle",
                "dominant-baseline": "middle",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }