<template xmlns:v-model="http://www.w3.org/1999/xhtml">
  <div class="user-pad">
    <div class="higherPosition">
      <transition name="component-fade-fast" mode="out-in" appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.eventPad._loadingMessage"
        />
      </transition>
    </div>
    <div
      v-if="!readOnly"
      class="updateButtonContainer"
      @mouseover="mouseOver"
      @mouseout="mouseOut"
      @mousedown="mouseDown"
    >
      <transition name="component-fade-fast" mode="out-in" appear>
        <button v-if="showUpdateButton" type="button" class="btn btn-labeled btn-info">
          <span class="btn-label">
            <i aria-hidden="true" class="fa fa-pencil" />
          </span>
          Update
        </button>
      </transition>
    </div>
    <div v-if="showHiddenForm" class="hiddenForm">
      <div id="pad-form" class="form-panel">
        <div class="row form-group">
          <div class="col-sm-12 col-md-12">
            <input
              v-model="eventTitle"
              type="text"
              :placeholder="string.eventPad._eventname"
              class="form-control"
            />
            <!-- <span class="alert-error">&nbsp;</span> -->
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-12 col-md-12">
            <el-date-picker
              v-model="dateTimeRange"
              type="datetimerange"
              class="form-control"
              :editable="false"
              :clearable="false"
              align="left"
              size="large"
              :start-placeholder="string.eventPad._startdate"
              :end-placeholder="string.eventPad._enddate"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy HH:mm"
              value-format="timestamp"
              v-on:change="onDateTimeRangeChanged"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-12 col-md-12">
            <Places
              id="addressInput"
              class="form-control"
              :model-value="eventLocation"
              :place-holder="string.eventPad._location"
              @place-changed="onAddressChanged"
            />
          </div>
        </div>

        <div class="row form-group">
          <div class="col-sm-12 col-md-12">
            <input
              v-model="eventVenue"
              type="text"
              :placeholder="string.eventPad._venue"
              class="form-control"
            />
            <!-- <span class="alert-error">&nbsp;</span> -->
          </div>
        </div>
        <!--        <div class="row form-control-event-pad">-->
        <!--          <div class="col-sm-12 col-md-12">-->
        <!--            <input-->
        <!--              :value="eventLocationNameEn"-->
        <!--              type="text"-->
        <!--              placeholder="Location (Eng)"-->
        <!--              class="form-control"-->
        <!--            />-->
        <!--            &lt;!&ndash;  class="alert-error">&nbsp;</span> &ndash;&gt;-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="row form-group">
          <div class="col-sm-4 col-md-4">
            <a
              class="butn delete btnepad"
              title
              style="margin-bottom:2px !important"
              @click="cancel"
              >{{ string.eventPad._clear }}</a
            >
          </div>
          <div class="col-sm-4 col-md-4">
            <a class="butn save btnepad" title @click="duplicate" >{{ string.eventPad._duplicate }}</a>
          </div>
          <div class="col-sm-4 col-md-4">
            <a class="butn save btnepad" title @click="submit">{{ string.eventPad._save }}</a>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="padding-left: 15px">
      <h4>{{ eventType }} | {{ eventCategory }}</h4>
      <h3>{{ eventTitle }}</h3>
      <h4 class="white">
        <i class="fa fa-map-marker" aria-hidden="true" />
        {{ eventLocation }}
      </h4>
      <h4 class="white">
        <i class="fa fa-building-o" aria-hidden="true" />
        {{ eventVenue }}
      </h4>
      <h4 class="white">
        <i class="fa fa-calendar-check-o" aria-hidden="true" />
        {{ eventStartDateString }}
        {{ eventEndDateString }}
      </h4>
      <h4 class="white">
        <i class="fa fa-clock-o" aria-hidden="true" />
        {{ eventStartTime }}
        {{ eventEndTime }}
      </h4>
    </div>
  </div>
</template>
<style scoped>
#pad-form {
  background-color: #000;
  height: 100%;
  /*display: grid;*/
}

.user-pad {
  /*padding: 0 0 0 10px;*/
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: cover;
}

.no-pad h3 {
  word-break: break-all;
  font-family: "Roboto", sans-serif;
}

div.user-menu-container h4 {
  color: #ffffff !important;
  font-family: "Roboto", sans-serif;
  margin-top: 15px;
  margin-bottom: 15px;
}

.butn {
  font-size: 16px;
  margin: 0;
  margin-bottom: 10px;
  padding: 8px;
  color: #fff;
  text-transform: uppercase;
  background-color: #333333;
  display: block;
  text-align: center;
  cursor: pointer !important;
  margin-top: 0px !important;
}
.butn:hover {
  color: #ffffff !important;
  background-color: #f7a30c !important;
}

.form-control-event-pad input,
.form-control-event-pad {
  border-radius: 0;
  border-color: #333;
  font-size: 1.2vw;
  height: auto !important;
  margin-bottom: 8px !important;
}

.form-status-msg {
  height: 18px !important;
}
p.form-status-msg {
  margin: 0;
}

/*.hiddenForm > .form-panel {*/
/*   // background-color: transparent !important;*/
/*}*/

.updateButtonContainer {
  //position: relative;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.hiddenForm {
  width: 100%;
  height: calc(100% + 59px);
  /*position: absolute;*/
  top: 0;
  left: 0;
  /*z-index: 2;*/
  background: #000d;
}

#loadingCover {
  z-index: 3;
  color: #fff !important;
}

.user-pad button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
}

.user-pad button:hover {
  background-color: #fff;
  color: #333;
}
.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}
</style>
<script>
import LoadingCover from "./LoadingCover.vue";
import Places from "../components/Places.vue";
import { UPDATE_EVENT, DUPLICATE_EVENT } from "@/graphql";
import moment from "moment";
import Vue from "vue";

Vue.prototype.moment = moment;

export default {
  components: {
    LoadingCover,
    Places
  },
  props: {
    event: Object,
    title: String,
    location: String,
    venue: String,
    startDate: String,
    endDate: String,
    startTime: String,
    endTime: String,
    readOnly: Boolean
  },
  data() {
    return {
      eventLocationName: "",
      eventLocationNameEn: "",
      eventTitle: "",
      startdate: "",
      enddate: "",
      eventType: "",
      eventCategory: "",
      eventLocation: "",
      eventVenue: "",
      locationCanChangePermission: false,
      fatherCanUpdate: true,
      fatherCanUpdateUpdate: true,
      locationErrorMessage: "",
      loadingEffectshow: false,
      eventStartDateString: "",
      eventStartDate: "",
      eventEndDate: "",
      eventEndDateString: "",
      eventStartTime: "",
      eventEndTime: "",
      showUpdateButton: false,
      showHiddenForm: false,
      startdateRome: "",
      enddateRome: "",
      today: "",
      canModifyEndDate: true,
      canBindRomeDate: true,
      resolvingAddress: false,

      string: {},

      eventLocationObject: {
        city: "",
        address: "",
        postalcode: "",
        latitude: "",
        longitude: "",
        country: "",
        inputValue: ""
      },
      pickerOptions: {
        disabledDate(picker) {
          return picker.getTime() < Date.now() - 8.64e7;
        },
        firstDayOfWeek: 1
      },
      dateTimeRange: [],
      // Payload for creating event
      paramDateTimeStart: "",
      paramDateTimeEnd: "",
      //details to send to parent
      updateEventDetails: { address: "", startDate: "", endDate: "", startTime: "" },
      categoryChanged: null
    };
  },
  watch: {
    event: function() {
      this.setValueToProp();
    }
  },
  beforeDestroy() {
    try {
      if (this.categoryChanged) {
        this.categoryChanged.remove();
        this.categoryChanged = null;
      }
    } catch {}
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.categoryChanged = this.$root.bus.addListener(
      "categoryChanged",
      item => {
        context.eventCategory = item.customCategoryLabel;
      },
      context
    );
  },
  methods: {
    setValueToProp: function() {
      //console.log(this);
      if (this.fatherCanUpdate) {
        this.eventTitle = this.title;
        this.eventLocation = this.location;
        this.eventVenue = this.venue;
        this.eventStartDateString = this.startDate;
        this.eventEndDateString = this.endDate;
        this.eventStartTime = this.startTime;
        this.eventEndTime = this.endTime;
        this.fatherCanUpdate = false;
      }

      this.eventCategory = this.event.customCategoryLabel;

      this.eventType =
        this.event.genre === "PRIVATE"
          ? this.string.eventPad._privateEvent
          : this.string.eventPad._publicEvent;

      if (this.event.address) {
        const formattedAddress = JSON.parse(this.event.address);
        this.eventLocationName = formattedAddress.name;
        this.eventLocationNameEn = formattedAddress.name;
      }
    },
    setValueToEventEdited: function(event) {
      this.eventTitle = event.name;

      this.eventCategory = event.customCategoryLabel;

      //console.log(event);

      this.eventLocation = this.getLocationFormatted(event);
      this.eventStartDateString = this.getDateFormatted(new Date(event.startDate));
      this.eventEndDateString = this.getDateFormatted(new Date(event.endDate));
      this.eventStartTime = this.getTimeFormatted(new Date(event.startDate));
    },
    getLocationFormatted: function(item) {
      if (!item.address) {
        return "";
      }
      const parsedAddress = JSON.parse(item.address);
      const streetNumber =
        typeof parsedAddress.street_number == "string" && parsedAddress.street_number.length > 0
          ? " " + parsedAddress.street_number
          : "";
      console.log(streetNumber);
      console.log(parsedAddress);
      return (
        parsedAddress.address +
        streetNumber +
        ", " +
        parsedAddress.postalcode +
        " " +
        parsedAddress.city
      );
    },
    getDateFormatted: function(d) {
      const day = d.getDate();
      const mon = this.fixNumberPresentation(d.getMonth() + 1);
      const yea = d.getFullYear();
      return day + "/" + mon + "/" + yea;
    },
    moment: function(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getTimeFormatted: function(d) {
      const h = this.fixNumberPresentation(d.getHours());
      const m = this.fixNumberPresentation(d.getMinutes());
      return h + ":" + m;
    },
    cancel: function() {
      // rollback changes to props
      this.setValueToProp();
      this.showHiddenForm = false;
    },
    duplicate: function() {
      this.$root.bus.emit("simpleToast", this.string.eventPad._loadingCoverMessage);
      this.$apollo.mutate({
        mutation: DUPLICATE_EVENT,
        variables: {
          eventId: this.event.id
        }
      }).then(({ data }) => {
          const route = this.$router.resolve({ path: "/event/" + data.duplicateEvent.id });
          window.open(route.href);  
      }).catch(error => {
        this.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
      });
    },
    submit: function() {
      const context = this;

      if (!this.isDataValid()) {
        this.$root.bus.emit("simpleToast", this.string.eventPad._mandatoryFields);
        return false;
      }

      //if address has not been changed, keep the old input
      if (this.eventLocationObject.city === " ") {
        this.onAddressChanged(JSON.parse(this.event.address));
      }

      context.loadingEffectshow = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              name: this.eventTitle.trim(),
              address: JSON.stringify(this.eventLocationObject),
              venue: this.eventVenue,
              startDate: this.paramDateTimeStart,
              endDate: this.paramDateTimeEnd
            }
          }
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            if (value.data && value.data.updateEvent) {
              context.$root.bus.emit("simpleToast", context.string.eventPad._successmessage);

              //update eventpad fields
              this.eventLocation =
                this.eventLocationObject.address + " ," + this.eventLocationObject.city;
              this.eventStartDateString = this.moment(this.paramDateTimeStart);
              this.eventEndDateString = this.moment(this.paramDateTimeEnd);
              this.eventStartTime = this.formatTimeRange(this.dateTimeRange[0]);
              this.eventEndTime = this.formatTimeRange(this.dateTimeRange[1]);
              //this.$emit('location',location);
              this.$emit("venue", this.eventVenue);
              //this.$emit('startDate', this.moment(this.paramDateTimeStart));
              //this.$emit('endDate', this.moment(this.paramDateTimeEnd));
              //this.$emit('startTime', this.formatTimeRange(this.dateTimeRange[0]));
              context.showHiddenForm = false;
              context.loadingEffectshow = false;
            }
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },

    isDataValid: function() {
      const title = this.eventTitle.trim();
      // const startdate = this.eventStartdate;
      // const enddate = this.eventEnddate;

      return (
        title.length > 0 && this.paramDateTimeStart.length > 0 && this.paramDateTimeEnd.length > 0
      );
    },
    setupForm: function() {
      const dateRangeStart = new Date(this.event.startDate).getTime();
      const dateRangeEnd = new Date(this.event.endDate).getTime();
      this.dateTimeRange = [dateRangeStart, dateRangeEnd];
      this.paramDateTimeStart = new Date(this.dateTimeRange[0]).toISOString();
      this.paramDateTimeEnd = new Date(this.dateTimeRange[1]).toISOString();
      this.showHiddenForm = true;
    },
    mouseDown: function() {
      this.setupForm();
    },
    mouseOver: function() {
      if (!this.showUpdateButton) {
        this.showUpdateButton = true;
      }
    },
    mouseOut: function() {
      if (this.showUpdateButton) {
        this.showUpdateButton = false;
      }
      //this.showHiddenForm = false; /** TEMP */
    },
    updateDone: function() {
      const context = this;
      context.$root.bus.emit(
        "simpleToast",
        context.string.eventPad._updateDone
      ); /** Emit di al gestore globale */
    },
    onDateTimeRangeChanged: function() {
      if (this.dateTimeRange && this.dateTimeRange.length > 0) {
        this.paramDateTimeStart = new Date(this.dateTimeRange[0]).toISOString();
        this.paramDateTimeEnd = new Date(this.dateTimeRange[1]).toISOString();
      }
    },
    onAddressChanged: function(p) {
      // console.log('onAddressChanged@  ' + JSON.stringify(p));
      this.eventLocationObject.city = p.city;
      this.eventLocationObject.address = p.address;
      this.eventLocationObject.postalcode =
        typeof p.postalcode == "undefined" && p.postalcode !== "undefined" ? "" : p.postalcode;
      this.eventLocationObject.latitude = p.lat;
      this.eventLocationObject.longitude = p.lng;
      this.eventLocationObject.country = p.country;
      this.eventLocationObject.inputValue = p.inputValue;
    },
    formatTimeRange: function(time) {
      var h = new Date(time).getHours();
      var m = new Date(time).getMinutes();
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      console.log(h + ":" + m);
      return h + ":" + m;
    }
  }
};
</script>
