<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Guest Lists
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/en-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="helpdesk/en-listeinvitati">
              Guest Lists
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to access or modify a guestlist 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to upload a guest list 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to download RSVP and Check-in reports 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span> How to set the maximum number of plus ones (+1s) per guest 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              What happens when a guest was put on the guestlist twice
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to access or modify a guestlist 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To visualize the guest list, first open the event from the “Events” section and click on the “Guest” button, you will then be able to access the guests list where you will be able to see the name, last name, email, phone number, event status, guests, custom notes and email status.
            In the guest list section, it will be possible to: <br>
                -  Visualize the number of guests present on the list “Number of guests in the list” <br>
                -  Download an excel report of all guests present on the list “Download all guests”  or those that have gone through the check-in procedure “Download the check-in list”. <br>
                -  Delete the list through the “Delete list” button <br>
                -  Manually modify or delete a specific guest by selecting it from the list through the button on the right each every row of each guest <br>

          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to upload a guest list 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            After you have entered the “guest list” section, it’s possible to add guests to the list in 2 ways:<br>
            <b>MANUALLY</b> <br>inserting guest by guest through the “+ add manually” button, while filling the indicated 
            fields: “Name, Last name, Email, and Phone number”.<br>
            <b>AUTOMATICALLY</b><br> uploading the filled-in excel template through the “upload list” button.<br><br>
            The excel file needs to be organized according to particular characteristics. It is necessary to use the 
            “Template Import File XLSX” under the button “Choose”. Once downloaded the template, it will just be a matter 
            of following the instructions present on the first 6 lines of the excel spreadsheet:<br>
            Do not delete the first 6 lines;<br> Delete the template fake guest “Mario Rossi” (there as an example);<br> 
            Fill in the fields for your guests paying attention to the specific fields and columns. <br>
            The excel file containing your guest list needs to be selected through the “Choose” button and afterward to 
            proceed to the upload, press the “Upload” button<br><br>
            <b>IN CASE OF PUBLIC EVENTS</b> the list will be dynamic hence it will fill up as guests register / buy the 
            tickets for the event. This means that the list will be completely empty once you create the event. There is 
            still however the possibility of manually adding guests, skipping the registration/acquisition of the ticket.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to download RSVP and Check-in reports 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            It is possible to download Excel spreadsheets containing reports relative to the guest status, 
            in particular, it is possible to obtain the complete list of all guests and their status through the 
            “Download all guests” button (for example: whether they have received the email, whether they have confirmed, 
            etc…). It is also possible to download the list of all guests that have gone through the check-in procedure 
            through the button “Download Check-in list”.<br> Furthermore, in the section “email campaign”, at the bottom 
            of the page, it’s possible to obtain the reports containing the guests who were not able to receive the email 
            and the status of the emails. The status of the emails is not immediately updated, it is advisable to wait for a 
            while before reloading the guest list page and downloading the reports.<br>
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to set the maximum number of plus ones (+1s) per guest 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To set the maximum limit of additional people each guest can bring to an event, you can go to the 
            “guest list” section and add or decrease the number of “partners per person”.<br>
            You are also able to manually modify the number of  accompanying guests a particular guest can bring 
            if some guests were to require more or less plus ones than others. You can modify this through the modify button at the left of each row of each guest on the guest list.<br><br>
            <b>HOW ARE +1s ACCOUNTED FOR </b> <br>
            In the guest list, there is a +1s box that states the number of +1s per person. This number initially presents itself in the form of, for example, 0/2, depending on the 
            number of +1s allowed. This number will automatically update when the hostess will check-in guest’s +1s.<br>
            If, for example, the manager sets the maximum number of +1s to be 3, initially you will see 0/3 on the guestlist. 
            When the guest confirms that he is only bringing 2 additional people, the initial 0/3 will update and become 0/2. 
            Afterward, during the check-in phase, this number will update to ½ or 2/2  with the presence of the +1s at the event.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            What happens when a guest was put on the guestlist twice
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Whenever by mistake a guest is inserted twice, the system will be able to catch it and not repeat the same name twice only if 
            they have the same email. The system allows guest lists with homonymous people as long as they have different emails.<br>
            Double guests inserted by accident will be reported by the system which will open a banner that notifies that duplicates have 
            been found with the possibility of downloading a spreadsheet report containing the names that were not inserted on the list.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskListeInvitatiEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
