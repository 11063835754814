var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n        Quick Start\n      "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/it-home" } }, [
                _vm._v("\n            Support\n          "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come creare un nuovo evento\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come caricare una lista di invitati\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come attivare pulsanti RSVP\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come generare un QR code quando un invitato conferma\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come personalizzare ogni invito con nome e cognome dell’invitato\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-6")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n            Come creare una landing page personalizzata\n          "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          Come creare un nuovo evento\n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come caricare una lista di invitati\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n          Come attivare i pulsanti RSVP\n          "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n          I bottoni di rsvp e decline permettono ai tuoi ospiti di accettare o rifiutare i tuoi inviti, guarda il\n          video\n          per imparare come si creano questi componenti.\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come generare un QR code quando un invitato conferma\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come personalizzare ogni invito con nome e cognome dell’invitato\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n          Quando si genera una campagna email è spesso fondamentale far sentire i propri\n          ospiti unici e speciali, per questo è necessario far si che ognuno di essi riceva una\n          email personalizzata con il proprio Nome e Cognome. "
            ),
            _c("br"),
            _vm._v(
              "\n          Farlo con l’EMAILBUILDER durante la creazione dell’email è semplice e immediato,\n          basta inserire in un “contenuto testo” attraverso la barra di lavoro (che si apre\n          quando si interagisce con il corpo del testo) e scegliere “campi dinamici”, selezionare\n          se inserire il nome, il cognome o entrambi. All’interno del testo compariranno quindi\n          voci del tipo “" +
                _vm._s(_vm.name) +
                "”, queste si trasformeranno poi nei nomi dei singoli\n          invitati quando verrà lanciata la campagna.\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-6",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n          Come creare una landing page personalizzata\n          "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Impara come creare la landing page personalizzata per il tuo evento in pochi semplici passi."
            ),
          ]),
          _vm._v(" "),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/it-quickstart" } }, [
        _vm._v("\n            Quick Start\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Dall’homepage del Dashboard, cliccando sull’icona con il simbolo “+” è possibile creare un nuovo evento."
      ),
      _c("br"),
      _vm._v(
        "\n          Sarà necessario selezionare se si tratta di un evento “privato” o “pubblico”.\n          Indipendentemente dalla scelta per completare la creazione si dovrà riempire\n          obbligatoriamente i campi: “Categoria, Nome, Data Inizio, Data Fine e Indirizzo”,\n          facoltativamente i campi “Locandina e Descrizione”.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Dopo essere entrati nella sezione “guest list”, per popolare la lista è possibile inserire i dati degli\n          invitati agendo:"
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("MANUALMENTE")]),
      _vm._v(
        " inserendo invitato dopo invitato attraverso il button “+ aggiungi\n          manualmente” riempendo i campi proposti: “Nome, Cognome, email e telefono”. "
      ),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("AUTOMATICAMENTE")]),
      _vm._v(
        " caricando una lista precompilata su un foglio excel attraverso il\n          button “carica lista”."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "\n          Il foglio excel deve essere organizzato secondo particolari specifiche, infatti è necessario\n          utilizzare il “template per importazione xls” proposto sotto il botton “sfoglia”. Una volta\n          scaricato basterà seguire le indicazioni presenti nelle prime sei righe del foglio di lavoro excel:"
      ),
      _c("br"),
      _vm._v("\n          non eliminare le prime sei righe; "),
      _c("br"),
      _vm._v(
        " eliminare l’ospite fittizio “Mario Rossi” (posto come\n          esempio);"
      ),
      _c("br"),
      _vm._v(
        "\n          inserire i dati dei propri ospiti rispettando le colonne assegnate ai vari campi. "
      ),
      _c("br"),
      _vm._v(
        "\n          Il foglio excel contenente la lista dovrà essere selezionato attraverso il button “sfoglia” e poi\n          in seguito per avviare il caricamento si dovrà selezionare il button “carica”\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("video", { attrs: { width: "100%", controls: "" } }, [
      _c("source", {
        attrs: {
          src: "https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4",
          type: "video/mp4",
        },
      }),
      _vm._v(
        "\n          Your browser does not support the video tag.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n          Nel caso in cui si voglia gestire l’accesso degli ospiti attraverso Qr code, sarà necessario\n          inviare una email di conferma contenente il QR Code necessario per accedere all’evento.\n          Semplicemente dopo aver generato una email di conferma nella sezione “impostazioni”\n          insieme a tutto quello che si desidera inserire nella email (es. info e event recap), andrà\n          aggiunto un “contenuto dinamico” in un modulo vuoto. "
      ),
      _c("br"),
      _vm._v(
        "\n          Cosi facendo gli invitati riceveranno, trascorsi 5-10 minuti dal momento della conferma, una\n          email personalizzata come in EMAILBUILDER in precedente e contenente il QR Code. "
      ),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("b", [_vm._v("COME VISUALIZZARE IL QR CODE ALCENTRO NELLA MAIL")]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n          Quando si inserisce il “contenuto dinamico” nel modulo vuoto in Emailbuilder, se si\n          utilizza un “modulo unico” (il primo nella sezione moduli) il Qr code verrà visualizzato decentrata a\n          sinistra nell’email, se si desidera che invece venga visualizzato al centro sarà\n          necessario semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in\n          tre sezioni” (il settimo nella lista dei moduli) e inserire il contenuto dinamico in quella\n          centrale.\n        "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("video", { attrs: { width: "100%", controls: "" } }, [
      _c("source", {
        attrs: {
          src: "https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4",
          type: "video/mp4",
        },
      }),
      _vm._v(
        "\n          Your browser does not support the video tag.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }