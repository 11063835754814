<template>
  <PublicLayout>
   <div class="container">
      <div class="row wow fadeIn">
        <!-- Left column -->
        <div class="col-md-7">
          <!-- Begin heading -->
          <div class="heading heading-md heading-uppercase heading-hover">
            <br>
            <h2 v-if="registerSuccess === false"
                    id="title"
                    class="heading-title text-white">
              {{ string.registerPage._register_title }}
            </h2>

            <!-- Begin divider -->
            <hr class="hr-width-1 hr-5x border-main">
            <!-- End divider -->
          </div>
          <!-- End heading -->
        </div> <!-- /.col -->

        <!-- Right column -->
        <div class="col-md-5 text-gray-2 padding-left-40">
          <h5 class="margin-top-40 margin-bottom-20"/>
        </div> <!-- /.col -->
      </div> <!-- /.row -->

      <div v-if="registerSuccess === false" class="row">
        <div class="col-md-12">

          <!-- Begin contact form -->
          <form id="contact-form"  autocomplete="off" class="margin-top-80 text-white wow fadeIn" data-wow-delay="0.3s">

            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="text" class="form-control no-rounded" aria-autocomplete="new-password" v-model="user.name"
                          :placeholder="string.registerPage._p_name" required autofocus>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="text" class="form-control no-rounded" aria-autocomplete="new-password" name="organizer_surname" v-model="user.surname"
                          :placeholder="string.registerPage._p_surname" required>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input type="email" class="form-control no-rounded"  readonly aria-autocomplete="new-password" name="organizer_email" value=""
                          :placeholder="string.registerPage._p_email" v-model="user.email">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4">
                <div class="form-group">
                  <input autocomplete="off" type="password" class="form-control no-rounded" name="password" v-model="user.password"
                          :placeholder="string.registerPage._p_password" required>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <input autocomplete="off" type="password" class="form-control no-rounded" name="password_confirmation" v-model="passwordConfirm"
                          :placeholder="string.registerPage._p_password_confirm" required>
                </div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 20px;">
              <div class="col-lg-12">
                  <small>{{ string.commonActions.passwordPolicy }}</small>
              </div>
            </div>
            <div class="col-lg-4">
              <!--<label class="checkeddd">
                <input type="checkbox" v-model="user.privacyCondition" name="disclaimer">
                <span class="checkmark"/>
              </label>-->
              <label class="control control-checkbox" style="margin-bottom: 30px;">
                <input
                    v-model="user.privacyCondition"
                    type="checkbox"
                    name="disclaimer">
                <div class="control_indicator"></div>
              </label>
              <p class="text-policy">
                  {{ string.termsAndConditions._privacy_accept }}
                  <a :href="string.termsAndConditions._privacypolicyURL" :title="string.termsAndConditions._privacy_title" target="_blank">
                      {{ string.termsAndConditions._privacy_title }}
                  </a>
                  {{ string.termsAndConditions._terms_accept }}
                  <a :href="string.termsAndConditions._linkTerms" :title="string.termsAndConditions._terms_title" target="_blank">
                      {{ string.termsAndConditions._terms_title }}
                  </a>
                  {{ string.termsAndConditions._mandatory }}
              </p>
            </div>

            <div class="col-lg-4">
              <!--<label class="checkeddd">
                <input type="checkbox"  v-model="user.dataCondition">
                <span class="checkmark"/>
              </label>-->
              <label class="control control-checkbox" style="margin-bottom: 30px;">
                <input
                    v-model="user.dataCondition"
                    type="checkbox">
                <div class="control_indicator"></div>
              </label>
              <p class="text-policy">{{ string.termsAndConditions._updates }}</p>
            </div>

            <div class="col-lg-4">
              <!--<label class="checkeddd">
                <input type="checkbox"  v-model="user.processingCondition">
                <span class="checkmark"/>
              </label>-->
              <label class="control control-checkbox" style="margin-bottom: 30px;">
                <input
                    v-model="user.processingCondition"
                    type="checkbox">
                <div class="control_indicator"></div>
              </label>
              <p class="text-policy">{{ string.termsAndConditions._marketing }}</p>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <button type="submit" v-on:click.prevent="register" class="btn btn-primary btn-warning active btn-block btn-lg">{{ string.registerPage._submit }}</button>
              </div>
            </div>
          </form>
          <!-- End contact form -->
        </div> <!-- /.col-->
      </div> <!-- /.row -->
      <div v-else class="row">
        <div class="col-md-12">
          <p class="heading-tescription lead text-gray-2">
            {{ registerSuccessMessage }}
          </p>
        </div>
      </div>
    </div>
  </PublicLayout>
</template>

<script >
  import PublicLayout from '../../layouts/PublicLayout';

  export default {
    components: {
        PublicLayout
    },
    props: {
      code: {
        type: String,
        false: true,
        default: ''
      }
    },
    data (){
      return {
        string: {
          registerPage: {
            _p_name: 'Name*',
            _p_surname: 'Surname*',
            _p_email: 'E-mail*',
            _p_password: 'Password* (minimum 8 characters)',
            _p_password_confirm: 'Password Confirm*',
          }
        },
        user: {
          email: '',
          name: '',
          surname: '',
          password: '',
          privacyCondition: false,
          dataCondition: false,
          processingCondition: false,
          role: ''
        },
        requestPayload: {
            code: '',
            userPayload: null,
        },
        passwordConfirm: '',

         /**
         * 0 WAITING_INPUT
         * 1 OK // Joined
         * 2 ALREADY_JOINED
         * 3 ERROR_BAD_TOKEN
         * 4 ERROR_INVITATON_NOT_FOUND / ERROR_INVITATION_EXPIRED
         * 5 ERROR_REGISTER_PAYLOAD_NOT_VALID
         * 6 ERROR_UNHANDLED
         */
        joinStatus: 0,

        toastMessage: '',
        toastError:'',
        registerSuccess: false
      };

    },
    metaInfo: {
      title: 'Register - Penguinpass',
      bodyAttrs: {
        class: 'login'
      }
    },
    computed: {
      registerSuccessMessage() {
        return this.joinStatus === 2 ? 'You are already joined' : 'Welcome to the team !';
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        }, this);
        this.$root.bus.emit('setLanguage');
        this.findInvitation(this.code, null);
    },
    methods: {
      findInvitation(code, userPayload) {
        const context = this;
        this.pageLoaded = false;
        this.$store.dispatch('teamJoin', { code, userPayload }).then((result) => {
          if (result.status === 'SUCCESS') {
            if (result.data) {
              const data = result.data;
                context.joinStatus = data.joinStatus;

                if (data.joinStatus === 0 ) {
                    //populate user fields
                    context.user.email = data.user.email;
                    context.user.name = data.user.name;
                    context.user.surname = data.user.surname;
                    context.user.role = data.user.role;
                }

                context.pageLoaded = true;

                if (data.joinStatus === 1 || data.joinStatus === 2) {
                    context.registerSuccess = true;
                }
                if (data.joinStatus === 1) {
                    context.$router.push({ path: '/login' }).catch(err => {});
                }
            } else {
              if ( result.message && result.message === "PASSWORD_NOT_MATCHING_POLICY" ){
                context.$notify({
                  title: 'Oops !',
                  message: context.string.commonActions.passwordPolicy,
                  position: 'bottom-right',
                  type: 'error',
                  duration: 3000,
                });
              }  
            }
          } else {
            if ( result.data && result.data.message && result.data.message === "PASSWORD_NOT_MATCHING_POLICY" ){
              context.$notify({
                title: 'Oops !',
                message: context.string.commonActions.passwordPolicy,
                position: 'bottom-right',
                type: 'error',
                duration: 3000,
              });
            }  
          }
        }).catch(error => {
          if ( error.data && error.data.message) {
            if (error.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
              context.$notify({
                title: 'Oops !',
                message: context.string.commonActions.passwordPolicy,
                position: 'bottom-right',
                type: 'error',
                duration: 3000,
              });
            } else {
              context.$notify({
                title: 'Oops !',
                message: error.data.message,
                position: 'bottom-right',
                type: 'error',
                duration: 3000,
              });
            }
          }
        });
      },
      register: function () {
        const context = this;
        // do body validation
        if (this.isDataValid()){
          this.findInvitation(this.code, this.user)
        }
      },
      isDataValid: function () {
        const context = this;
        if (this.passwordConfirm === ''  || this.user.password === '') {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._password_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.passwordConfirm !== this.user.password) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._passwords_not_match,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.name === '' || this.user.surname === '' ) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._name_surname_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        if (this.user.privacyCondition === false) {
          context.$notify({
            title: 'Oops !',
            message: this.string.registerPage._privacy_policy_mandatory,
            position: 'bottom-right',
            type: 'warning',
            duration: 3000,
          });
          return false;
        }
        return true;
      }

    }
  };

</script>
<style >
</style>
