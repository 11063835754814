var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c("div", { attrs: { id: "wrapper" } }, [
      _c("div", { attrs: { id: "page-content-wrapper" } }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "event-wrapper" }, [
            _c(
              "div",
              { staticClass: "createevent__header" },
              [
                _c(
                  "el-row",
                  {
                    staticClass: "createevent__title",
                    attrs: { type: "flex", align: "middle" },
                  },
                  [
                    _c("el-col", { attrs: { span: 8 } }, [
                      _c(
                        "div",
                        { staticStyle: { background: "transparent" } },
                        [
                          _c("h1", { staticStyle: { color: "white" } }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.string.organizerSettings._pageTitle
                                ) +
                                "\n                  "
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "text-align": "end" },
                        attrs: { span: 6, offset: 10 },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn gray-btn save",
                            staticStyle: {
                              margin: "0px",
                              padding: "10px 20px",
                              display: "inline-block",
                              "align-content": "end",
                            },
                            on: { click: _vm.goBack },
                          },
                          [_c("i", { staticClass: "fa fa-arrow-left" })]
                        ),
                        _vm._v(" "),
                        _vm.team.uniqueName !== "" && _vm.features.publicPages
                          ? _c(
                              "a",
                              {
                                staticClass: "btn gray-btn save",
                                staticStyle: {
                                  margin: "0px",
                                  padding: "10px 20px",
                                  display: "inline-block",
                                  "align-content": "end",
                                },
                                on: { click: _vm.openPublicPage },
                              },
                              [_c("i", { staticClass: "fa fa-external-link" })]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "createevent__navbar",
                    attrs: { type: "flex", align: "middle" },
                  },
                  [
                    _c("el-col", { attrs: { span: 24 } }, [
                      _c(
                        "nav",
                        {
                          staticClass: "nav-tabs",
                          attrs: { id: "navigation" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "navigation-container",
                              attrs: { id: "navigationtab", role: "tablist" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "navigation-item",
                                  attrs: {
                                    id: "info-tab",
                                    role: "presentation",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn gray-btn save",
                                      attrs: {
                                        href: "#info",
                                        "aria-controls": "info",
                                        role: "tab",
                                        "data-toggle": "tab",
                                      },
                                      on: {
                                        click: function () {
                                          return _vm.changeTab("info-tab")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._tabProfile
                                          ) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "navigation-item",
                                  attrs: {
                                    id: "edit-password-tab",
                                    role: "presentation",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn gray-btn save",
                                      attrs: {
                                        href: "#edit-password",
                                        "aria-controls": "edit-password-tab",
                                        role: "tab",
                                        "data-toggle": "tab",
                                      },
                                      on: {
                                        click: function () {
                                          return _vm.changeTab(
                                            "edit-password-tab"
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._tabPassword
                                          ) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.$can("access", _vm.marketingIdentityTab)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "navigation-item",
                                      attrs: {
                                        id: "event-setup-tab",
                                        role: "presentation",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn gray-btn save",
                                          attrs: {
                                            href: "#event-setup",
                                            "aria-controls": "event-setup-tab",
                                            role: "tab",
                                            "data-toggle": "tab",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.changeTab(
                                                "event-setup-tab"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.string.organizerSettings
                                                  ._tabEventSettings
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("access", _vm.marketingIdentityTab)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "navigation-item",
                                      attrs: {
                                        id: "custom-categories-tab",
                                        role: "presentation",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn gray-btn save",
                                          attrs: {
                                            href: "#custom-categories",
                                            "aria-controls":
                                              "custom-categories-tab",
                                            role: "tab",
                                            "data-toggle": "tab",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.changeTab(
                                                "custom-categories-tab"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.string.organizerSettings
                                                  ._tabCustomCategories
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("access", _vm.marketingIdentityTab)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "navigation-item",
                                      attrs: {
                                        id: "domain-setup-tab",
                                        role: "presentation",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn gray-btn save",
                                          attrs: {
                                            href: "#domain-setup",
                                            "aria-controls": "domain-setup-tab",
                                            role: "tab",
                                            "data-toggle": "tab",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.changeTab(
                                                "domain-setup-tab"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.string.organizerSettings
                                                  ._tabDomain
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.$can("read", _vm.unsubscribersTab)
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "navigation-item",
                                      attrs: {
                                        id: "unsubscribers-tab",
                                        role: "presentation",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn gray-btn save",
                                          attrs: {
                                            href: "#unsubscribers",
                                            "aria-controls":
                                              "unsubscribers-tab",
                                            role: "tab",
                                            "data-toggle": "tab",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.changeTab(
                                                "unsubscribers-tab"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.string.organizerSettings
                                                  ._tabUnsubscribers
                                              ) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "navigation-item",
                                  attrs: {
                                    id: "delete-account-tab",
                                    role: "presentation",
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "btn gray-btn save",
                                      attrs: {
                                        href:
                                          "mailto:info@penguinpass.co?subject=Elimina Account Penguinpass (" +
                                          _vm.organizer.email +
                                          ")&body=Vorrei eliminare il mio account Penguinpass.",
                                        target: "_blank",
                                        "aria-controls": "delete-account-tab",
                                        role: "tab",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(
                                            _vm.string.organizerSettings
                                              ._tabDeleteAccount
                                          ) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "createevent__body col-xs-12 col-sm-12 col-lg-12",
              },
              [
                _c("div", { staticClass: "tab-content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade active",
                      attrs: { id: "info", role: "tabpanel" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "panel panel-default",
                          attrs: { id: "panel" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "panel-body" },
                            [_c("UserInfo")],
                            1
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-pane fade",
                      attrs: { id: "edit-password", role: "tabpanel" },
                    },
                    [
                      _c("div", { staticClass: "panel panel-default" }, [
                        _c("div", { staticClass: "panel-body" }, [
                          _c(
                            "h4",
                            { staticClass: "form-section-title first" },
                            [
                              _vm._v(
                                "\n                      " +
                                  _vm._s(
                                    _vm.string.organizerSettings._passwordHeader
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "smallseparator" }),
                          _vm._v(" "),
                          _c(
                            "form",
                            {
                              attrs: {
                                id: "form-settings-edit-password",
                                onsubmit: "return handleSavePassword()",
                              },
                            },
                            [
                              _c("div", { staticClass: "input-container" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organizerPassword,
                                      expression: "organizerPassword",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "settings-modify-password",
                                    type: "password",
                                    placeholder: "Modifica password",
                                    required: "",
                                  },
                                  domProps: { value: _vm.organizerPassword },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.organizerPassword =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "fa password-toggle-icon",
                                  class: _vm.passwordVisible
                                    ? "fa-eye"
                                    : "fa-eye-slash",
                                  attrs: { "aria-hidden": "true" },
                                  on: { click: _vm.togglePasswordVisibility },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-container" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.organizerPasswordConfirm,
                                      expression: "organizerPasswordConfirm",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "settings-confirm-password",
                                    type: "password",
                                    placeholder: "Conferma password",
                                    required: "",
                                  },
                                  domProps: {
                                    value: _vm.organizerPasswordConfirm,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.organizerPasswordConfirm =
                                        $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "fa password-toggle-icon",
                                  class: _vm.passwordVisible
                                    ? "fa-eye"
                                    : "fa-eye-slash",
                                  attrs: { "aria-hidden": "true" },
                                  on: { click: _vm.togglePasswordVisibility },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("small", [
                                _vm._v(
                                  _vm._s(
                                    _vm.string.commonActions.passwordPolicy
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "settings-btn-area" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "btn gray-btn save",
                                    attrs: { loading: _vm.savingChanges },
                                    on: {
                                      click: function (e) {
                                        e.preventDefault()
                                        _vm.handleSavePassword()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                          " +
                                        _vm._s(
                                          _vm.string.organizerSettings._modify
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.$can("access", _vm.marketingIdentityTab)
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade active",
                          attrs: { id: "event-setup", role: "tabpanel" },
                        },
                        [
                          _c("div", { staticClass: "panel panel-default" }, [
                            _c(
                              "div",
                              { staticClass: "panel-body" },
                              [_c("EventSettings")],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("access", _vm.marketingIdentityTab)
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade active",
                          attrs: { id: "custom-categories", role: "tabpanel" },
                        },
                        [
                          _c("div", { staticClass: "panel panel-default" }, [
                            _c(
                              "div",
                              { staticClass: "panel-body" },
                              [_c("CustomCategories")],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("access", _vm.marketingIdentityTab)
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade active",
                          attrs: { id: "domain-setup", role: "tabpanel" },
                        },
                        [
                          _c("div", { staticClass: "panel panel-default" }, [
                            _c(
                              "div",
                              { staticClass: "panel-body" },
                              [_c("IdentitySettings")],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$can("read", _vm.unsubscribersTab)
                    ? _c(
                        "div",
                        {
                          staticClass: "tab-pane fade active",
                          attrs: { id: "unsubscribers", role: "tabpanel" },
                        },
                        [
                          _c("div", { staticClass: "panel panel-default" }, [
                            _c(
                              "div",
                              { staticClass: "panel-body" },
                              [_c("Unsubscribers")],
                              1
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br"),
          _c("br"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }