var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row insert-ticket-form" }, [
    _c("h4", [_vm._v(_vm._s(_vm.string.insertTicketForm._title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-12 col-lg-12" },
      [
        _c(
          "transition",
          {
            attrs: { name: "component-fade-fast", mode: "out-in", appear: "" },
          },
          [
            _vm.loadingEffectshow
              ? _c("LoadingCover", {
                  attrs: {
                    "loading-cover-msg":
                      _vm.string.insertTicketForm._loadingMessage,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "form-horizontal style-form",
            attrs: { method: "get" },
          },
          [
            _vm.app_settings.app_name === "default"
              ? _c("div", { staticClass: "form-group nomarginleft" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ticketType,
                            expression: "ticketType",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          name: "ticketType",
                          "aria-placeholder": _vm.string.insertTicketForm._type,
                        },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.ticketType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "pay" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.string.insertTicketForm._pay) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "donation" } }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.string.insertTicketForm._donation) +
                              "\n            "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ticketName,
                          expression: "ticketName",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: _vm.string.insertTicketForm._name,
                      },
                      domProps: { value: _vm.ticketName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.ticketName = $event.target.value
                        },
                      },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group nomarginleft" }, [
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ticketAvailability,
                      expression: "ticketAvailability",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    name: "availability",
                    min: "0",
                    max: "999999998",
                    placeholder: _vm.string.insertTicketForm._nolimits,
                    autocomplete: "on",
                  },
                  domProps: { value: _vm.ticketAvailability },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.ticketAvailability = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.ticketPrice,
                      expression: "ticketPrice",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "number",
                    name: "price",
                    step: "0.50",
                    min: "0",
                    autocomplete: "on",
                    placeholder: _vm.string.insertTicketForm._price,
                  },
                  domProps: { value: _vm.ticketPrice },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.ticketPrice = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "formbutton" }, [
              _c(
                "a",
                {
                  staticClass: "save",
                  attrs: { title: "" },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.insertTicketForm._save) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }