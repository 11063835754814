<template>
  <el-container direction="vertical" class="guest-list-container">
    <div class="group-manager-members" style="margin-top: 55px">
      <!-- USER LIST -->
      <h4 class="form-section-title">
        {{ string.sponsor._title }}
        <small>{{ string.sponsor._subtitle }}</small>
      </h4>
      <div class="smallseparator"></div>
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <a
            class="btn gray-btn import-man"
            :title="string.guestlist._add"
            @click="toggleInsertSingle"
          >
            <i class="fa fa-plus" aria-hidden="true" />
            {{ string.guestlist._add }}
          </a>
        </div>
      </div>

      <!-- Tab Content -->
      <div class="row tabs-content">
        <transition name="component-fade-fast" mode="out-in">
          <div v-if="tabValue === 'insertsingle'" key="insertsingle">
            <ListInputSponsor
              :team="team"
              :event-id="eventId"
              :father-name="componentName"
              @component_event="closeTab"
            />
          </div>
        </transition>
      </div>

      <h4 v-if="uploadStatus !== 'NULL'" class="form-section-title second progress-title">
        {{ uploadStatusMessage }} <loading-icon v-if="uploadStatus !== 'PENDING'" />
        <small />
      </h4>
      <el-progress
        v-if="uploadStatus !== 'NULL'"
        class="progress-container"
        :stroke-width="24"
        :percentage="uploadProgress"
        :show-text="false"
        :color="'#f7a30c'"
        :format="formatProgress"
      />
      <h4 class="form-section-title second">
        {{ string.guestlist._list }}
      </h4>
      <div class="smallseparator"></div>
      <div class="downloads_style" style="padding-bottom: 20px">
        <i class="fa fa-users" aria-hidden="true" style="margin-right: 15px" />
        {{ string.sponsor._numberOfSponsors }}<strong>{{ totalGuests }}</strong>
      </div>

      <div class="group-manager-members-list">
        <data-table
          ref="table"
          :lazy="true"
          :get-data="getData"
          :columns="columns"
          :page-size="15"
          @loaded="ready = true"
        >
          <span slot="Max Tickets" slot-scope="{ row }">
            <div v-if="row.edit === true" name="component-fade-fast" mode="out-in" appear>
              <el-input-number
                v-model="editItem.maxTickets"
                :min="0"
                :max="100"
                :step="1"
                @change="markRowModified(row)"
              />
            </div>
            <div v-else name="component-fade-fast" mode="out-in" appear>
              {{ row.maxTickets }}
            </div>
          </span>
          <span slot="Banner" slot-scope="{ row }" style="text-align: center">
            <img :src="row.banner" style="height: 30px" v-if="row.banner" />
            <button class="btn" style="margin-top: 0" @click="onPickFile(row.sponsorId)">
              Upload
            </button>
            <input
              :id="row.sponsorId"
              type="file"
              style="display: none"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked($event, row)"
            />
          </span>
          <span slot="Actions" slot-scope="{ row }" style="text-align: center">
            <!-- <el-button-group> -->
            <a
              class="action_link"
              style="margin-right: 5px"
              @click="() => (row.edit === false ? openRow(row) : saveRow(row))"
            >
              <i :class="row.edit === false ? 'fa fa-pencil-square-o' : 'fa fa-check'"></i>
            </a>
            <a class="action_link" style="margin-right: 5px" @click="copyRowLink(row)">
              <i :class="'fa fa-copy'"></i>
            </a>
            <a
              class="action_link"
              style="margin-right: 5px"
              @click="() => (row.edit === false ? deleteRow(row) : closeRow(row))"
            >
              <i :class="row.edit === false ? 'fa fa-trash' : 'fa fa-times'"></i>
            </a>
            <!-- </el-button-group> -->
          </span>
        </data-table>
      </div>
    </div>
  </el-container>
</template>
<script>
import DataTable from "../../components/DataTable";
import LoadingCover from "../../components/LoadingCover.vue";
import ListInputSponsor from "../../components/ListInputSponsor.vue";
import ListUpload from "../../components/ListUpload.vue";
import NumericFieldInput from "../../components/NumericFieldInput.vue";
import LoadingIcon from "../../components/LoadingIcon.vue";
import {
  DELETE_ALL,
  DELETE_ONE_SPONSOR,
  UPDATE_MEMBER_BY_ID,
  UPDATE_GUEST_BY_ID,
  GET_SPONSOR_LIST,
  EDIT_SPONSOR,
} from "@/graphql";
import { mapGetters } from "vuex";
import isEmail from "validator/lib/isEmail";
import { customFieldAnswerToString } from "@/utils";
import CustomFieldFilter from "../CustomFieldFilter.vue";
import AudienceOptionGroup from "../AudienceOptionGroup.vue";
import CustomCheckbox from "@/components/ui/CustomCheckbox.vue";

export default {
  name: "SponsorListContainer",
  components: {
    DataTable,
    LoadingIcon,
    ListInputSponsor,
    ListUpload,
    NumericFieldInput,
    AudienceOptionGroup,
    CustomFieldFilter,
    CustomCheckbox,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventId: {
      type: String,
      required: true,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxGuestsPerEvent: {
      type: Number,
      required: false,
      default: 0,
    },
    maxGuestsPerMember: {
      type: Number,
      required: false,
      default: 0,
    },
    eventName: {
      type: String,
      required: false,
      default: "",
    },
    badgePrintingEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    timeSlotMap: {
      type: Object,
      required: false,
      default: () => {},
    },
    timeSlotEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      componentName: "sponsor-list-container",
      string: {
        groupManager: {},
      },
      autoPrintBadge: false,
      localPrintBadge: false,
      updateEmailList: false,
      newEmail: "",
      editItem: {
        maxTickets: 0,
      },
      editItemCopy: {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        listEmails: [],
        status: "",
        answerList: {},
        memberAnswerList: {},
      },
      tabValue: "",
      columns: [
        {
          prop: "displayName",
          label: "Sponsor Name",
          minWidth: "150",
          fixed: "left",
        },
        {
          prop: "email",
          label: "Sponsor Email",
          minWidth: "150",
          fixed: "left",
        },
        {
          label: "Max Tickets",
          minWidth: "200",
        },
        {
          label: "Banner",
          minWidth: "200",
        },
        {
          label: "Actions",
          width: "130",
          fixed: "right",
        },
      ],
      banner: "",
      guestNewsSubscription: null,
      guestListImportTaskSubscription: null,
      bulkOptions: [],
      filter: "",
      // upload management
      uploadStatus: "NULL",
      uploadStatusMessage: "",
      uploadProgress: 0,
      totalGuests: 0,
      newHubiloEventId: this.hubiloEventId,
      reportData: [],
      exportingReport: false,
      activityReportData: [],
      //audience management
      audienceSize: 0,
      initialOption: "ALL_GUESTS",
      audienceOption: "ALL_GUESTS",
      audienceOptionFilter: undefined,
      audienceOptions: [
        {
          label: "_selectAllGuests",
          value: "ALL_GUESTS",
        },
        {
          label: "_selectPending",
          value: "GUESTS_PENDING",
        },
        {
          label: "_selectConfirmed",
          value: "GUESTS_CONFIRMED",
        },
        {
          label: "_selectCheckedIn",
          value: "GUESTS_CHECK_IN",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user", "team", "features", "isExhibitor", "printedGuests"]),
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.$root.bus.addListener("newSponsor", async () => {
      await this.$refs.table.getTableData();
    });
  },
  methods: {
    onPickFile(sponsorId) {
      const input = document.getElementById(sponsorId);
      input.click();
    },
    async onFilePicked(event, row) {
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      console.log(files[0]);
      fileReader.readAsDataURL(files[0]);
      this.banner = files[0];
      console.log(event);
      const context = this;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      data.append("fileToUpload", files[0]);
      try {
        const result = await this.$root.axios.post("/upload/bg", data, config);
        await this.$root.axios.post("/api/sponsors/edit-sponsor", {
          sponsorListId: row.sponsorListId,
          banner: result.data.filepath,
        });
        context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
      } catch (error) {
        console.log(error.message);
        context.$root.bus.emit(
          "simpleToast",
          context.string.attachments._uploadfailed + " " + error.message
        );
      }
      this.$refs.table.getTableData();
    },
    onFilterChanged(filter) {
      this.filter = filter;
      this.$refs.table.getTableData();
    },
    customFieldAnswerToString(...args) {
      return customFieldAnswerToString(...args);
    },
    onBulkAction: function (action, selectedRows = []) {
      console.log({ action, selectedRows });
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      const context = this;
      const input = {};
      return this.$root.axios
        .post("/api/sponsors/sponsors", {
          eventId: this.eventId,
          filter: filter,
          pageInfo: {
            pageSize: pageSize,
            currentPage: page,
            sortField: input["sortField"],
            sortOrder: input["sortOrder"],
          },
        })
        .then((response) => {
          const payload = response.data;
          console.log(payload);
          context.totalGuests = payload.total;

          return {
            data: payload.data,
            total: payload.total,
          };
        });
    },
    closeRow(row) {
      row.loading = false;
      row.edit = false;
      this.editItem = {
        memberName: "",
        memberSurname: "",
        memberEmail: "",
        memberTelephone: "",
        maxAcquaintance: 0,
        listEmails: [],
        status: "",
        customFieldDataMap: {},
        answerList: {},
        memberCustomFieldDataMap: {},
        memberAnswerList: {},
      };
      this.editItemCopy = { ...this.editItem };
      this.updateCustomFields = false;
      this.updateEmailList = false;
    },
    openRow(row) {
      // close open rows
      console.log(this);
      for (const [index, item] of this.$refs.table.tableData.entries()) {
        if (item.sponsorId !== row.sponsorId && item.edit) {
          item.edit = false;
        }
      }
      this.editItem.maxTickets = row.maxTickets;
      row.edit = true;
    },
    async saveRow(row) {
      if (row.shouldUpdate) {
        row.loading = true;
        this.$root.axios
          .post("/api/sponsors/edit-sponsor", {
            sponsorListId: row.sponsorListId,
            maxTickets: this.editItem.maxTickets,
          })
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.$root.bus.emit("simpleToast", this.string.sponsor._updated);
              this.$refs.table.getTableData();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$root.bus.emit("simpleToast", "Error");
          })
          .finally(() => {
            row.loading = false;
          });
        // this.$apollo
        //   .mutate({
        //     mutation: EDIT_SPONSOR,
        //     variables: {
        //       sponsorListId: row.sponsorListId,
        //       maxTickets: this.editItem.maxTickets,
        //     },
        //   })
        //   .then((response) => {
        //     if (response.errors) {
        //       console.log(response.errors);
        //       this.$root.bus.emit("simpleToast", "Error");
        //     } else {
        //       this.$root.bus.emit("simpleToast", "Updated");
        //       this.$refs.table.getTableData();
        //     }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     this.$root.bus.emit("simpleToast", "Error");
        //   })
        //   .finally(() => {
        //     row.loading = false;
        //   });
      }
      row.edit = false;
    },
    async copyRowLink(row) {
      const link = `${window.location.origin}/sponsor?s=${row.sponsorListId}`;
      try {
        await navigator.clipboard.writeText(link);
        alert("Copied");
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    toggleInsertSingle() {
      this.tabVisible = true;
      this.tabValue = "insertsingle";
    },
    toggleUploadList() {
      this.tabVisible = true;
      this.tabValue = "uploadlist";
    },
    closeTab(e) {
      this.tabVisible = false;
      this.tabValue = "";
    },
    markRowModified(row, options = {}) {
      row.shouldUpdate = true;
    },
    addNewEmail(row) {
      if (this.newEmail) {
        if (!isEmail(this.newEmail)) {
          return this.$root.bus.emit("simpleToast", "Email not valid");
        }
        for (const emailObj of this.editItem.listEmails) {
          if (emailObj.title === this.newEmail) {
            return this.$root.bus.emit("simpleToast", "Email already present");
          }
        }
        this.editItem = {
          ...this.editItem,
          listEmails: [...this.editItem.listEmails, { title: this.newEmail, edit: false }],
        };
        this.newEmail = "";
        this.markRowModified(row, { updateEmailList: true });
      }
    },
    deleteEmail(email) {
      const deleteIndex = this.editItem.listEmails.indexOf(email);
      const tempListEmail = this.editItem.listEmails.splice(deleteIndex, 1);
      this.listEmails = tempListEmail;
    },
    formatProgress(percentage) {
      return `${percentage}%` + (this.uploadStatusMessage ? " " + this.uploadStatusMessage : " ");
    },
    // api calls
    deleteListAllFunction() {
      if (confirm(this.string.guestlist._sure)) {
        const context = this;
        context.loadingEffectshow = true;
        return this.$apollo
          .mutate({
            mutation: DELETE_ALL,
            variables: {
              eventId: this.eventId,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              context.loadingEffectshow = false;
            } else {
              context.loadingEffectshow = false;
              context.$root.bus.emit("simpleToast", context.string.guestlist._itemCancellato);
              // context.$emit("guestListDeleted", {});
              context.$refs.table.tableData = [];
              context.$refs.table.total = 0;
              this.$emit("customFieldsChanged", null);
            }
          })
          .catch((reason) => {
            console.log(reason.message);
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", "failed");
          });
      }
    },
    checkRow(row) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_GUEST_BY_ID,
          variables: {
            guestId: row.id,
            eventId: this.eventId,
            guestInput: {
              status: "CHECK_IN",
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.loadingEffectshow = false;
            context.$root.bus.emit("simpleToast", context.string.datagrid._guest_checkin);
            // context.$emit("guestDataChanged", {});
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", "failed");
          context.loadingEffectshow = false;
        });
    },
    deleteRow(row) {
      console.log(row);
      this.$confirm(
        `${this.string.teamPage._warning_remove} ${
          row.member
            ? row.member.email.includes("internal.penguinpass.it")
              ? ""
              : row.member.email
            : ""
        }?`
      ).then((_) => {
        const context = this;
        context.loadingEffectshow = true;
        this.$root.axios
          .post("/api/sponsors/delete-sponsor", {
            sponsorListId: row.sponsorListId,
          })
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              this.$root.bus.emit("simpleToast", this.string.sponsor._sponsorremoved);
              this.$refs.table.getTableData();
            }
          })
          .catch((error) => {
            console.log(error);
            this.$root.bus.emit("simpleToast", "Error");
          })
          .finally(() => {
            row.loading = false;
          })
          .catch((_) => {});
      });
    },
    async onBeforeUpdateGuest({ controlLimit, ...rest }) {
      if (
        controlLimit &&
        this.maxGuestsPerEvent > 0 &&
        this.confirmationPlusOne >= this.maxGuestsPerEvent
      ) {
        return this.$root.bus.emit("simpleToast", this.string.guestlist._maxAttendeeExceeded);
      }
      return this.onUpdateGuest(rest);
    },
    async onUpdateGuest({ memberId, guestId, guestUpdates, memberUpdates }) {
      // console.log("GuestListContainer@onUpdateGuest: ");
      // console.log({ memberId, guestId, guestUpdates, memberUpdates });
      const context = this;
      // context.loadingEffectshow = true;
      let hasError = false;
      let message = "";
      if (Object.keys(memberUpdates).length > 0) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_MEMBER_BY_ID,
            variables: {
              memberId,
              memberInput: memberUpdates,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              hasError = true;
              message = "Unexpected Error";
            } else {
              message = context.string.guestlist._guestupdated;
            }
          })
          .catch((error) => {
            // context.loadingEffectshow = false;
            const errorMessage =
              error.graphQLErrors && error.graphQLErrors.length > 0
                ? error.graphQLErrors[0].message
                : "";
            if (errorMessage === "MEMBER_EXISTS") {
              hasError = true;
              message = this.string.listSingleItem._emailAlreadyPresentError;
            }
          });
      }
      if (Object.keys(guestUpdates).length > 0 && !hasError) {
        await this.$apollo
          .mutate({
            mutation: UPDATE_GUEST_BY_ID,
            variables: {
              guestId,
              eventId: context.eventId,
              guestInput: guestUpdates,
            },
          })
          .then((value) => {
            if (value.errors) {
              console.log("error " + value.errors);
              hasError = true;
              message = "Unexpected Error";
            } else {
              message = context.string.guestlist._guestupdated;
              context.$emit("guestDataChanged", {});
            }
          })
          .catch((error) => {
            const errorMessage =
              error.graphQLErrors && error.graphQLErrors.length > 0
                ? error.graphQLErrors[0].message
                : "";
            if (errorMessage === "CUSTOM_FIELD_LIMITS") {
              hasError = true;
              message = "Selected time slot's limit has been reached.";
            }
          });
      }
      // context.loadingEffectshow = false;
      context.$root.bus.emit("simpleToast", message);
    },
  },
};
</script>

<style>
label.el-form-item_label {
  vertical-align: bottom;
  font-size: 20px;
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  margin: 0;
}
.guest-list-container {
  padding: 0;
}
.status-pending {
  color: #eea236 !important;
  text-align: left;
  margin-right: 5px;
}
.status-active {
  color: #0ac407 !important;
  text-align: left;
  margin-right: 5px;
}
.status-inactive {
  color: crimson !important;
  text-align: left;
  margin-right: 5px;
}
.button-pair.first:hover,
.button-pair.second:hover {
  background: #fff !important;
  color: #333 !important;
}
.button-pair.second,
.button-pair.first {
  background: #333 !important;
  color: #f7a30c !important;
}
.form-section-title {
  padding-bottom: 5px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-size: 18px;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
.group-manager-title {
  color: #fff;
  font-size: 22px;
  margin-bottom: 20px;
}
.group-manager-actions {
  padding-bottom: 30px;
}
.group-manager-actions-button {
  padding: 5px;
}
/* .group-manager-actions-button:first-child,
    .group-manager-actions-button:last-child{
        padding-left: 15px;
    } */
.group-manager-actions-button a {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  border-radius: unset !important;
  background-color: #333333;
  transition: 1s ease;
}
.group-manager-actions-button a:hover {
  background-color: #dadada;
  color: #333333;
}
.group-manager-members,
.group-manager-members-list,
.group-manager-members-list > div {
  width: 100%;
}
.group-manager-members-list-no-data {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
}
.group-manager-members-list-no-data i {
  font-size: 130px;
  margin-left: 35px;
  color: #f7a30c;
}
.group-manager-members-list-no-data-message {
  font-size: 30px;
  text-transform: uppercase;
}
.group-manager-members-list-no-data-message .underline {
  border-bottom: 2px solid #f7a30c;
}
.group-manager-members-list-no-data-hint {
  font-size: 25px;
  font-style: italic;
}

/** class for the transition NO REMOVE THIS */
.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}
.component-fade-enter,
.component-fade-leave-active,
.component-fade-leave-to {
  opacity: 0;
}
.p-table-cell {
  display: flex;
  align-items: center;
}
.p-table-cell,
.p-table-cell > div > input {
  height: 45px !important;
  text-align: center;
  width: 100%;
  color: white;
}
span.p-table-cell {
  line-height: 45px !important;
}
.p-table-cell > .el-button:focus,
.el-button:hover {
  color: black !important;
  background: white !important;
}
button.el-button.p-table-cell.el-button--default {
  color: white !important;
  background: #f7a30c !important;
}
a.action_link {
  transition: 1s ease;
  background-color: transparent !important;
  color: #dadada !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 5px !important;
}

a.action_link:hover {
  color: #f7a30c !important;
}

/* a.actions_link {
  display: inline-block;
  cursor: pointer;
  transition: 1s ease;
  background-color: transparent !important;
  color: #ffffff !important;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 0px !important;
  margin-top: 0px !important;
}
a.actions_link:hover {
  background-color: transparent !important;
  color: #000 !important;
} */

.downloads_style {
  padding-left: 15px;
  font-size: 18px;
}
.downloads_style > i {
  padding-right: 15px;
}
.linkStyle {
  display: inline;
  cursor: pointer;
}
button.linkStyle {
  background: transparent !important;
  border: transparent !important;
  cursor: pointer;
  padding: 1px 0;
}
.downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
button > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.downloads_style > div > a:hover {
  color: #f7a30c !important;
}
a.simple {
  cursor: pointer;
  color: #333 !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.simple:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
</style>
<style>
div.name-wrapper-2-borderless > .el-tag,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag {
  padding: 0;
}

div.name-wrapper-2.el-popover__reference > .el-tag,
div.name-wrapper-2 > .el-tag,
div.name-wrapper-2-borderless > .el-tag,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag {
  border: 0px;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  text-align: center;
}
div.name-wrapper-2.el-popover__reference > .el-tag:hover,
div.name-wrapper-2 > .el-tag:hover,
div.name-wrapper-2-borderless > .el-tag:hover,
div.name-wrapper-2-borderless.el-popover__reference > .el-tag:hover {
  color: #000;
}
.name-wrapper-2.el-popover__reference,
.name-wrapper-2 {
  text-align: center;
  border: 1px solid #f7a30c;
}
.name-wrapper-2-borderless.el-popover__reference,
.name-wrapper-2-borderless {
  text-align: center;
  border: 0px !important;
}
.audience-filter-area {
  margin: 20px 0;
}
</style>
