var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "data-grid-container", staticStyle: { overflow: "auto" } },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.columns, function (key) {
              return _c(
                "th",
                {
                  key: key,
                  class: { active: _vm.sortKey === key },
                  on: {
                    click: function ($event) {
                      return _vm.sortBy(key)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm._f("capitalize")(key)) +
                      "\n          "
                  ),
                  _c("span", {
                    staticClass: "arrow",
                    class: _vm.sortOrders[key] > 0 ? "asc" : "dsc",
                  }),
                ]
              )
            }),
            0
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.filteredData, function (entry) {
            return _c(
              "tr",
              {
                key: entry["id"],
                class: [
                  entry["status"] === "QUEUED"
                    ? "tableRowItem-queued"
                    : "tableRowItem",
                ],
                attrs: { id: entry["id"] },
              },
              _vm._l(_vm.columns, function (key) {
                return _c("td", { key: key }, [
                  entry["status"] === "QUEUED" && key === "queued"
                    ? _c(
                        "div",
                        [
                          _vm._v("\n              " + _vm._s(entry["queued"])),
                          _c("loading-icon"),
                        ],
                        1
                      )
                    : entry["status"] === "QUEUED" && key === "date"
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "action_link",
                            on: {
                              click: function ($event) {
                                return _vm.onCancelCampaign(entry)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-times" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.string.directEmailMarketing
                                    ._cancel_campaign
                                ) +
                                "\n              "
                            ),
                          ]
                        ),
                      ])
                    : _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.getKey(entry, key)) },
                      }),
                ])
              }),
              0
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }