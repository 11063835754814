<template>
  <div
    class="default-layout fixflex">
    <div
      id="modal-2"
      class="md-modal md-effect-2 md-show">
      <button
        class="md-close"
        @click="fadeout">
        <i
          class="fa fa-times"
          aria-hidden="true"
        ></i>
      </button>
      <!--<transition name="component-fade" mode="out-in" appear> Attivazione transition aggiuntiva-->
      <div class="md-content">
        <div class="grid">
          <div
            class="event_type_select event_type_public"
            @click="triggerPublicEvent">
            <figure class="effect-kira">
              <figcaption>
                <h2>{{ strings.createSelectEvent._public }}</h2>
                <p>{{ publicboxtxt }}</p>
              </figcaption>
            </figure>
          </div>
          <div
            class="event_type_select event_type_private"
            @click="triggerPrivateEvent">
            <figure class="effect-kira">
              <figcaption>
                <h2>{{ strings.createSelectEvent._private }}</h2>
                <p>{{ strings.createSelectEvent._privateboxtxt }}</p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <!--</transition>-->
    </div>
    <div
      id="createselectevent"
      @click="fadeout">
            &nbsp;
    </div>
  </div>
</template>
<style scoped>
    .fixflex {
    align-items: center;
    display: flex;
    }
    .default-layout .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .9s ease;
    }
    .default-layout .component-fade-enter, .component-fade-leave-active {
        opacity: 0;
    }
    
    .default-layout .event_type_select{
        width: 320px;
        height: 240px;
        
    }
    
    .default-layout .event_type_private{
        background-color: #f7a30c;
        margin-left: 30px;
    }
    
    .default-layout .event_type_public{
        background-color: #ffffff;
        margin-right: 30px;
    }
    
    #test{
        color:#ff00ff;
        position: absolute;
        top:250px;
        left:550px;
    }
    
    .default-layout #createselectevent{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1003;
        opacity:0.6;
        background-color:rgb(51,51,51);
    }
    
    /* La transizione vera e propria */
    .default-layout .expand-transition {
        transition: all .3s ease;
        opacity: 0.77;
        background-color: #eee;
    }
    
    /* .expand-enter è lo stato iniziale del elemento */
    .default-layout .expand-enter, .expand-leave {
        opacity: 1;
    }
    
    .gobella-layout .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .9s ease;
    }
    .gobella-layout .component-fade-enter, .component-fade-leave-active {
        opacity: 0;
    }
    
    .gobella-layout .event_type_select{
        width: 320px;
        height: 240px;
        
    }
    
    .gobella-layout .event_type_private{
        background-color: #f7a30c;
        margin-left: 30px;
    }
    
    .gobella-layout .event_type_public{
        background-color: #333333;
        margin-right: 30px;
        margin-left: 30px;
        
    }
    
    .gobella-layout #createselectevent{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1003;
        opacity:0.6;
        background-color:rgb(51,51,51);
    }
    
    /* La transizione vera e propria */
    .gobella-layout .expand-transition {
        transition: all .3s ease;
        opacity: 0.77;
        background-color: #eee;
    }
    
    /* .expand-enter è lo stato iniziale del elemento */
    .gobella-layout .expand-enter, .expand-leave {
        opacity: 1;
    }
</style>
<script>
import * as userdata from '../userdata';

export default {
    components:{
    },
    props: {
        propShow: {
            type: Boolean
        },
        propMsg: {
            type: String
        }
    },
    data (){
        return {
            componentName: 'create-select-event',
            app_settings: userdata.app_settings,
            show: this.propShow,
            msg: this.propMsg,
            publicboxtxt: '',
            strings: {
                /*
                        createSelectEvent:{
                            _public: "Pubblico",
                            _publicboxtxt: "Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa.",
                            _private: "Privato",
                            _privateboxtxt: "Lorem Ipsum è un testo segnaposto utilizzato nel settore della tipografia e della stampa.",
                            _notauthorized: "Contattaci per attivare questa opzione",
                        }*/
            },

        };
    },
    created (){
        const context = this;
        this.strings = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.strings = context.$root.strings;
        });
    },
    mounted () {
        // if (this.$root.userPermissions.indexOf('updatePrivate') >= 0){
        this.publicboxtxt = this.strings.createSelectEvent._publicboxtxt;
        // } else {
        //     this.publicboxtxt = this.strings.createSelectEvent._notauthorized;
        // }
    },
    methods: {
        fadeout: function () {
            this.$emit('component_event', this.componentName+'|0'); /** Emit di evento al padre */
        },
        triggerPrivateEvent: function () {
            this.$emit('component_event', this.componentName+'|trigNewPrivateEvent'); /** Emit di evento al padre */
        },
        triggerPublicEvent: function () {
            // if (this.$root.userPermissions.indexOf('updatePrivate') >= 0){
            this.$emit('component_event', this.componentName+'|trigNewPublicEvent'); /** Emit di evento al padre */
            // } else {
            //     this.$root.bus.emit('notAuthorizedOptionToast');  /** Emit di al gestore globale */
            // }
        }

    }
};
</script>
