var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "section-content-container", attrs: { gutter: 5 } },
    [
      _vm.hasIcon
        ? _c(
            "el-col",
            {
              staticClass: "section-icon-container",
              attrs: { span: _vm.hasIcon ? 1 : 0 },
            },
            [
              _c(
                "progress-ring",
                {
                  staticClass: "section-icon",
                  style:
                    "visibility:" + (_vm.iconText ? "visible; " : "hidden; "),
                  attrs: { progress: _vm.iconProgress, text: _vm.iconText },
                },
                [
                  _vm.iconProgress === 100
                    ? _c(
                        "template",
                        { slot: "icon" },
                        [
                          _c("font-awesome-icon", {
                            style: { color: "#FF9B00" },
                            attrs: { icon: "check", transform: "shrink-8" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-col",
        { attrs: { span: _vm.hasIcon ? 23 : 24 } },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }