<template>
  <div v-if="!isExhibitor" class="advancesettings-component accordion">
    <CategoryManager
        :event="event"
        :event-id="event.id"/>
<!--    <ExternalReference-->
<!--            :event="event"-->
<!--            :event-id="Number(event.id)"/>-->
    <PrivacyPolicy :event="event"/>
<!--    <PromoteEvent-->
<!--            :event="event"-->
<!--            :event-id="Number(event.id)"/>-->
    <AttendeeMaxLimit
        :event="event"
        :event-id="event.id"/>
<!--    <EventPayable-->
<!--            :event="event"-->
<!--            :event-id="Number(event.id)"/>-->
<!--    <ExternalPurchaseEndpoint-->
<!--            :event="event"-->
<!--            :event-id="Number(event.id)"/>-->
    <CustomFieldsManager
        :event="event"
        :event-id="event.id"
        @customFieldsChanged="onCustomFieldsChanged"
    />
    <RSVPImageHeader v-show="!isPublicEvent"
        :event="event"
        :event-id="event.id"
    />
    <ShowOnApp v-show="isPublicEvent" :event="event"/>
    <C19Compliance v-show="features.c19Compliance" :event="event"></C19Compliance>
  </div>
</template>
<style scoped>

    .btn:hover {
        color: #333333 !important;
        background-color: #dadada !important;
        border-color: #dadada !important;
    }

    .advancesettings-component .form-section input{
        border-radius: 0;
        border-color: #333;
        width: 50%;
        margin:0 auto;

    }
    .advancesettings-component .form-section button{
        margin: 20px auto;
        border: solid 1px #333;
        text-transform: uppercase;
        display: block;
        font-size:16px;
        width: 530px;
    }

    small{
        width:100%;
        font-size:12px;
        text-transform:uppercase;
        color:#e8e8e8;
        display:block;
    }

    .inMailToggleSelection{
        display:flex;
    }

    .inMailToggleSelection .textLabel{
        padding-top: 0;
        padding-right: 0;
        padding-left: 20px !important;
        padding-bottom: 0;
    }
</style>
<script>

import CategoryManager from '../../event/settings/category_manager/CategoryManager.vue';
import CustomFieldsManager from '../../event/settings/custom_fields_manager/CustomFieldsManager.vue';
import ExternalReference from '../../event/settings/external_reference/ExternalReference.vue';
import AttendeeMaxLimit from '../../event/settings/attendee_limit/AttendeeMaxLimit.vue';
import ShowOnApp from '../../event/settings/show_on_app/ShowOnApp.vue';
import PrivacyPolicy from '../../event/settings/privacy/PrivacyPolicy.vue';
import PromoteEvent from '../../event/settings/promote/PromoteEvent.vue';
import ExternalPurchaseEndpoint from '../../event/settings/external_purchase/ExternalPurchaseEndpoint.vue';
import EventPayable from '../../event/settings/event_payable/EventPayable.vue';
import C19Compliance from '../../event/settings/c19_compliance/C19Compliance.vue';
import RSVPImageHeader from '../../event/settings/RSVPImageHeader/RSVPImageHeader.vue'


import { mapGetters } from "vuex";

export default {
    components:{
        ExternalReference,
        AttendeeMaxLimit,
        ShowOnApp,
        PrivacyPolicy,
        PromoteEvent,
        ExternalPurchaseEndpoint,
        EventPayable,
        CategoryManager,
        CustomFieldsManager,
        C19Compliance,
        RSVPImageHeader
    },
    computed: {
        ...mapGetters(["features", "user", "isExhibitor"]),
        isPublicEvent: function() {
            return this.event.genre === "PUBLIC";
        }
    },
    props: {
        event: Object,
    },
    methods: {
        onCustomFieldsChanged: function() {
            this.$emit('customFieldsChanged', null );
        }
    }
};
</script>
