var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _c("h2", { staticClass: "heading-title text-white" }, [
                _vm._v(
                  "\n                      " +
                    _vm._s(
                      _vm.action && _vm.action === "update"
                        ? _vm.string.recoveryPassword._newPassword_title_update
                        : _vm.string.recoveryPassword._newPassword_title
                    ) +
                    "\n                  "
                ),
              ]),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "heading-tescription lead text-gray-2",
                  attrs: { id: "description" },
                },
                [
                  _vm._v(
                    "\n                      " +
                      _vm._s(
                        _vm.action && _vm.action === "update"
                          ? _vm.string.recoveryPassword
                              ._newPassword_description_update
                          : _vm.string.recoveryPassword._newPassword_description
                      ) +
                      "\n                  "
                  ),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "margin-top-80 text-white wow fadeIn",
              attrs: { id: "contact-form", "data-wow-delay": "0.3s" },
            },
            [
              _c(
                "div",
                { staticClass: "row", staticStyle: { margin: "2px" } },
                [
                  _c("div", { staticClass: "col-lg-8" }, [
                    _c("div", { staticClass: "form-group input-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.check.password,
                            expression: "check.password",
                          },
                        ],
                        staticClass: "form-control no-rounded",
                        attrs: {
                          "aria-label": "",
                          id: "password1",
                          type: "password",
                          name: "password",
                          value: "",
                          placeholder: "Password",
                          required: "",
                        },
                        domProps: { value: _vm.check.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.check, "password", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "fa password-toggle-icon",
                        class: _vm.passwordVisible ? "fa-eye" : "fa-eye-slash",
                        attrs: { "aria-hidden": "true" },
                        on: { click: _vm.togglePasswordVisibility },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-group input-container" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.check.passwordConfirm,
                              expression: "check.passwordConfirm",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            "aria-label": "",
                            id: "password2",
                            type: "password",
                            name: "password",
                            value: "",
                            placeholder: "Password Confirm",
                            required: "",
                          },
                          domProps: { value: _vm.check.passwordConfirm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.check,
                                "passwordConfirm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa password-toggle-icon",
                          class: _vm.passwordVisible
                            ? "fa-eye"
                            : "fa-eye-slash",
                          attrs: { "aria-hidden": "true" },
                          on: { click: _vm.togglePasswordVisibility },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(_vm._s(_vm.string.commonActions.passwordPolicy)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-warning active btn-block btn-lg",
                          attrs: { type: "submit" },
                          on: {
                            click: function (e) {
                              e.preventDefault()
                              this$1.updatePassword()
                            },
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return (function (e) {
                                e.preventDefault()
                                _vm.updatePassword()
                              })($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                  " +
                              _vm._s(
                                _vm.action && _vm.action === "update"
                                  ? _vm.string.recoveryPassword
                                      ._newPassword_summitButton_update
                                  : _vm.string.recoveryPassword
                                      ._newPassword_summitButton
                              ) +
                              "\n                              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }