var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row list-single-item" }, [
    _c("h4", [_vm._v(_vm._s(_vm.string.sponsor._addSponsor))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-12 col-lg-12" },
      [
        _c(
          "transition",
          {
            attrs: { name: "component-fade-fast", mode: "out-in", appear: "" },
          },
          [
            _vm.loadingEffectshow
              ? _c("LoadingCover", {
                  attrs: {
                    "loading-cover-msg":
                      _vm.string.listSingleItem._loadingMessage,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "form-horizontal style-form",
            attrs: { autocomplete: "off" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group no-margin-bottom nomarginleft" },
              [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sponsorName,
                        expression: "sponsorName",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputSponsorName,
                      "aria-label": "",
                      type: "text",
                      placeholder: _vm.string.sponsor._sponsorName,
                    },
                    domProps: { value: _vm.sponsorName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sponsorName = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sponsorEmail,
                        expression: "sponsorEmail",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputSponsorEmail,
                      "aria-label": "",
                      type: "email",
                      placeholder: _vm.string.sponsor._sponorEmail,
                    },
                    domProps: { value: _vm.sponsorEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sponsorEmail = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group no-margin-bottom nomarginleft" },
              [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.sponsorMaxTickets,
                        expression: "sponsorMaxTickets",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputSponsorMaxTickets,
                      "aria-label": "",
                      type: "number",
                      placeholder: _vm.string.listSingleItem._maxTickets,
                    },
                    domProps: { value: _vm.sponsorMaxTickets },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.sponsorMaxTickets = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "formbutton" }, [
              _c(
                "a",
                {
                  staticClass: "btn gray-btn save",
                  attrs: { title: "avanti" },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.string.listSingleItem._save) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }