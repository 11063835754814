/**
 * @example: "htmlinput {{token1}} token2 {token3} {{token1}} {{token4}} {{"  will return  ["token1","token4"]
 *
 * @param {string} str string to be inspected
 * @param {boolean} addMustache  if you want to wrap token with {{ }}
 *
 *
 */
export default function findMustacheTokens(str = "", addMustache = false) {
  const resultArray = [];
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "{" && str[i + 1] && str[i + 1] === "{") {
      const subStr = str.substr(i + 2);
      for (let j = 0; j < subStr.length; j++) {
        if (subStr[j] === "}" && subStr[j + 1] && subStr[j + 1] === "}") {
          const token = str.substr(i + 2, j);
          let exists = false;
          resultArray.map(existingToken => {
            if (existingToken === token) {
              exists = true;
            }
          });
          if (!exists) {
            resultArray.push(addMustache ? `{{${token}}}` : token);
          }
          break;
        }
      }
    }
  }
  return resultArray;
}
