<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.RSVPImageHeader._title }}
    </h4>
    <div class="accordion-content">
      <el-row>
          <small>
            {{ $root.strings.RSVPImageHeader._subtitle }}
          </small>
          <div class="ImageToggleSelection">
            <label class="control control-checkbox">
              {{ string.RSVPImageHeader._innertitle }}
              <input id="checkbox" v-model="showEditor" type="checkbox" @change="toggleImage" />
              <div class="control_indicator"></div>
            </label>
          </div>
          <div v-if="showEditor" class="form-container">
            <div class="user-image" @mouseover="mouseOver" @mouseout="mouseOut" @mousedown="mouseDown">
              <transition name="component-fade-fast" mode="out-in" appear>
                <LoadingCover v-if="loadingEffectshow" :loading-cover-msg="string.coverImage._loadingMessage" />
              </transition>
              <div class="updateButtonContainer">
                <transition name="component-fade-fast" mode="out-in" appear>
                  <button v-if="showUpdateButton === true" type="button" class="btn btn-labeled btn-info"
                    style="float:right !important;">
                    <span class="btn-label">
                      <i aria-hidden="true" class="fa fa-pencil" />
                    </span>
                    Update
                  </button>
                </transition>
                <transition name="component-fade-fast" mode="out-in" appear>
                  <div v-if="showUpdateButton === true" class="updateButtonContainerBg">
                    <div class="imgNotices">{{ string.coverImage._imgNoticesTY }}</div>
                  </div>
                </transition>
              </div>
              <img :src="newImg || event.logoUrl" class="img-responsive thumbnail cover-image-constraints"
                alt="Event cover Image" />
              <input id="fileinput" ref="fileinput" type="file" accept="image/*" @change="onFileChange" />
            </div>
          </div>
      </el-row>
    </div>
  </div>
</template>
<style scoped>
.external-puchase-component {
  margin-bottom: 30px;
}

/** Disable Classes */

.form-container {
  margin-top: 30px;
  position: relative;
  width: 100%;
}

.disable-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #00000000;
}

/** /Disable Classes */

/** In Action Layer */
.inaction-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

/** In Action Layer */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}

.rsvp-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.image-upload {
  margin-top: 20px;
}

.cover-image-constraints {
  width: 100%;
  height: 350px;
}

.imgNotices {
  position: absolute;
  top: 40%;
  left: 30px;
  right: 30px;
  border: 1px solid #fff;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.updateButtonContainer {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

.updateButtonContainerBg {
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.user-image {
  color: #333 !important;
  background-color: #333333 !important;
}

.user-image button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 1;
  color: #fff;
}

.user-image button:hover {
  background-color: #fff;
  color: #333;
}

.component-fade-fast-enter-active,
.component-fade-fast-leave-active {
  transition: opacity 0.3s ease;
}

.component-fade-fast-enter,
.component-fade-fast-leave-active {
  opacity: 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.9s ease;
}

.component-fade-enter,
.component-fade-leave-active {
  opacity: 0;
}
</style>
<script>
import { UPDATE_EVENT } from "@/graphql";


export default {
  components: {
  },
  props: {
    eventId: String,
    event: Object,
  },
  data() {
    return {
      componentName: "RSVPImageHeader",
      string: {},
      showEditor: false,
      selectedFile: null,
      newImg: "",
      loadingEffectshow: false,
      showUpdateButton: false,
      fileList: [],
    };
  },
  watch: {
    event: function () {
        this.showEditor = this.event.logoEnabled;
        this.eventCover = this.event.logoUrl;
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
        mouseDown: function() {
      this.open();
    },
    mouseOver: function() {
      this.showUpdateButton = true;
    },
    mouseOut: function() {
      this.showUpdateButton = false;
    },
    open: function() {
      this.$refs.fileinput.click();
    },
    toggleImage: function() {
      const context = this;
      context.loadingEffectshow = true;
      return this.$root.$apollo
      .mutate({
        mutation: UPDATE_EVENT,
        variables: {
          eventId: context.eventId,
          updateEventInput: { logoEnabled: context.showEditor }
        }
      })
      .then(value => {
        if (value.errors) {
          context.loadingEffectshow = false;
        } else {
          //success
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
        }
      })
      .catch(reason => {
        context.loadingEffectshow = false;
        context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed);
      });
    },
    onFileChange(file) {
      this.fileList = file.target.files[0];
      this.submit();
    },
      submit: function() {
      const context = this;
      context.loadingEffectshow = true;
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const data = new FormData();
      if (context.fileList !== null) {
        data.append("fileToUpload", context.fileList);
      }
      data.append("eventId", context.eventId);
      return this.$root.axios
        .post("/upload/cover", data, config)
        .then(response => {
          console.log(response);
          context.$root.bus.emit("simpleToast", context.string.coverImage._updateDone);
          context.updateImage(response.data.filepath);
        })
        .catch(error => {
          console.log(error.message);
          context.loadingEffectshow = false;
          context.$root.bus.emit("simpleToast", context.string.attachments._uploadfailed + " " + error.message );
        });
    },
        updateImage: function(path) {
      const context = this;
      return this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,            
          variables: {
            eventId: context.eventId,
              updateEventInput: {
                logoUrl: path
              }
            } 
        })
        .then(value => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.loadingEffectshow = false;
          } else {
            context.newImg = path;
            context.loadingEffectshow = false;
          }
        })
        .catch(reason => {
          console.log(reason.message);
          context.loadingEffectshow = false;
        });
    },
  }
};
</script>
