const Script = function () {
    jQuery('.panel .tools .fa-chevron-down').click(function () {
        const el = jQuery(this).parents('.panel').children('.panel-body');
        if (jQuery(this).hasClass('fa-chevron-down')) {
            jQuery(this).removeClass('fa-chevron-down').addClass('fa-chevron-up');
            el.slideUp(200);
        } else {
            jQuery(this).removeClass('fa-chevron-up').addClass('fa-chevron-down');
            el.slideDown(200);
        }
    });

    jQuery('.panel .tools .fa-times').click(function () {
        jQuery(this).parents('.panel').parent().remove();
    });


    //    tool tips

    $('.tooltips').tooltip();

    //    popovers

    $('.popovers').popover();


    // custom bar chart

    if ($('.custom-bar-chart')) {
        $('.bar').each(function () {
            const i = $(this).find('.value').html();
            $(this).find('.value').html('');
            $(this).find('.value').animate({
                height: i
            }, 2000);
        });
    }

    // $('.datagridsSearchBar').on('keyup', function () {
    //     const input = $(this);
    //     if (input.val().length === 0) {
    //         input.addClass('empty');
    //     } else {
    //         input.removeClass('empty');
    //     }
    // });

}();
