var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v("\n    " + _vm._s(_vm.string.attendeeMaxLimit._title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-container accordion-content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 18, offset: 3 } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.inputValue,
                      expression: "inputValue",
                    },
                  ],
                  staticClass: "form-control input-lg",
                  attrs: {
                    id: "purchasesLimit",
                    type: "number",
                    min: "0",
                    name: "purchases_limit",
                    placeholder: _vm.string.attendeeMaxLimit._placeholder,
                  },
                  domProps: { value: _vm.inputValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.inputValue = $event.target.value
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 18, offset: 3 } }, [
                _c(
                  "a",
                  {
                    staticClass: "btn gray-btn save",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.string.attendeeMaxLimit._submit) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }