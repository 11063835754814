<template>
  <TransitionGroup
    name="list"
    tag="ul"
    class="event-list"
  >
    <li
      v-for="item in outputEvents"
      :key="item.id"
      @click="goSingleEvent(item.id, item.status)"
    >
      <div
        v-if="item.status === 'INACTIVE' || archive === true"
        class="notPublishedLayerBg"
      >
        &nbsp;
      </div>
      <div
        v-if="item.status === 'INACTIVE' || archive === true"
        class="notPublishedLayerFg"
      >
        <!-- Cosa vuoi fare ora?  -->
        <div class="containerButtonLayer">
          <a
            v-if="archive === false && item.status !== 'ARCHIVED'"
            class="btn-public"
            :title="string.eventList._publish"
            @click.stop="publishSingleEvent(item)"
          >
            <i class="fa fa-check"/>
          </a>
          <a
            class="btn-edit"
            :title="string.eventList._edit"
            @click.stop="editSingleEvent(item.id)"
          >
            <i class="fa fa-pencil"/>
          </a>
          <a
            v-if="archive === false && item.status !== 'ARCHIVED'"
            class="btn-archive"
            :title="string.eventList._duplicate"
            @click.stop="duplicateSingleEvent(item)"
          >
            <i class="fa fa-files-o"/>
          </a>
          <a
            v-if="archive === false && item.status !== 'ARCHIVED'"
            class="btn-archive"
            :title="string.eventList._archive"
            @click.stop="archiveSingleEvent(item)"
          >
            <i class="fa fa-archive"/>
          </a>
          <a
            v-if="archive === true && item.status === 'ARCHIVED'"
            class="btn-archive"
            :title="string.eventList._unarchive"
            @click.stop="unArchiveSingleEvent(item)"
          >
            <i class="fa fa-archive"/>
          </a>
          <a
            class="btn-delete"
            :title="string.eventList._delete"
            @click.stop="deleteSingleEvent(item)"
          >
            <i class="fa fa-trash"/>
          </a>
        </div>
      </div>
      <time
        :datetime="item.fulldate"
        :class="item.smartBg"
      >
        <span
          class="type-event category"
          :class="item.smartBg"
        >
          {{ item.privateLabel }}
        </span>
        <span class="day">
          {{ item.startdate_gg }}
        </span>
        <span class="month">
          {{ item.startdate_mm }}
        </span>
        <span class="year">
          {{ item.startdate_yy }}
        </span>
      </time>
      <img
        v-if="item.image != null"
        :alt="item.name"
        :src="item.image"
      /> <!-- event image -->
      <img
        v-if="item.image == null"
        :alt="item.name"
        :src="defaultImage"
      /> <!-- Default image -->
      <div class="info">
        <h2 class="title">
          {{ item.name }}
        </h2>
        <h3 class="category">
          {{ item.customCategoryLabel }}
        </h3>
        <p
          v-if="item.place != null && item.place.address !== 'undefined'"
          class="desc"
        >
          <i
            class="fa fa-map-marker"
            aria-hidden="true"
          />
          {{ item.place.address }}, {{ item.place.postalcode }} {{ item.place.city }}
        </p>
        <ul>
          <!--<li class="tooltips" data-placement="top" data-original-title="informazioni" class="checkin"><i class="fa fa-info" aria-hidden="true"></i></li>-->
          <li
            class="tooltips confermati"
            data-placement="top"
            data-original-title="confermati"
          >
            {{ item.confirmed }} <span class="glyphicon glyphicon-ok" />
          </li>
          <li
            class="tooltips checkin"
            data-placement="top"
            data-original-title="check-in"
          >
            {{ item.checkedIn }} <span class="glyphicon glyphicon-ok" />
            <span
              data-v-00b34a85=""
              class="glyphicon glyphicon-ok"
              style="margin-left:-8px;"
            />
          </li>
          <!-- <li class="tooltips" data-placement="top" data-original-title="Notifiche">103 <span class="fa fa-envelope" aria-hidden="true"></span></li> -->
        </ul>
      </div>

      <!--
      <div class="social bg2">
        <ul>
          <li class="facebook"><a href="#facebook"><span class="fa fa-facebook" aria-hidden="true"></span></a></li>
          <li class="twitter"><a href="#twitter"><span class="fa fa-twitter" aria-hidden="true"></span></a></li>
          <li class="google-plus"><a href="#google-plus"><span class="fa fa-google-plus" aria-hidden="true"></span></a></li>
        </ul>
      </div>
      -->
    </li>
  </TransitionGroup>
</template>
<style scoped>

  .event-list > li > .info > .category, .event-list > li > .info > .desc {
    padding: 0 10px;
    margin: 0 0 3px;
    font-size: 16px;
    text-transform: uppercase;
  }


  .notPublishedLayerBg{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: rgba(3, 3, 3, 0.80);
    z-index: 3;
  }
  .notPublishedLayerFg{
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    color: #ffffff;
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .notPublishedLayerFg h6{
    font-size: 28px;
    text-align: center;
    letter-spacing: 2px;
    margin: 0;
  }

  .notPublishedLayerFg .btn-public{
    width: auto;
    height: auto;
    padding:  12px 16px;
    border-radius: 200px;
    letter-spacing: 1px;
    color: #ffffff;
    font-size: 15px;
    background: rgba(247, 163, 12, 0.6);
    text-transform: uppercase;
    margin: 0 15px;
    transition: all ease .2s;
    text-align: center;
  }

  .notPublishedLayerFg .btn-edit{
    width: auto;
    height: auto;
    padding:  12px 16px;
    border-radius: 200px;
    letter-spacing: 1px;
    color: #ffffff;
    font-size: 15px;
    background: rgba(247, 163, 12, 0.7);
    text-transform: uppercase;
    transition: all ease .2s;
    text-align: center;
  }

  .notPublishedLayerFg .btn-delete{
    width: auto;
    height: auto;
    padding:  12px 16px;
    border-radius: 200px;
    letter-spacing: 1px;
    color: #ffffff;
    font-size: 15px;
    background: rgba(247, 163, 12, 0.8);
    text-transform: uppercase;
    margin: 0 15px;
    transition: all ease .2s;
    text-align: center;
  }

  .notPublishedLayerFg .btn-archive{
    width: auto;
    height: auto;
    padding:  12px 16px;
    border-radius: 200px;
    letter-spacing: 1px;
    color: #ffffff;
    font-size: 15px;
    background: rgba(247, 163, 12, 0.7);
    text-transform: uppercase;
    transition: all ease .2s;
    margin-left: 15px;
    text-align: center;
  }

  .notPublishedLayerFg .btn-public:hover{
    background: rgba(92, 184, 92, 0.68);
  }
  .notPublishedLayerFg .btn-edit:hover{
    background: rgba(102, 102, 102, 0.58);
  }
  .notPublishedLayerFg .btn-archive:hover{
    background: rgba(102, 102, 102, 0.58);
  }
  .notPublishedLayerFg .btn-delete:hover{
    background: rgba(255, 0, 0, 0.55);
  }

  .containerButtonLayer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0 0 0;
  }

  .event-list > li > time.privabg{
    color: #ffffff;
    background-color: #f7a30c;
    display: flex;
    justify-content: center;
  }

  span.publibg{
    background-color: #000000;
    color: #ffffff;
  }

  span.privabg{
    background-color: #f7a30c;
  }

  .event-list > li > time.publibg{
    color: #000000;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
  }

  .list-item {
    margin-right: 10px;
  }
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-active {
    opacity: 0;
    transform: translateY(30px);
  }
  @import '../assets/css/dashboard/main.css';
  @import '../assets/css/dashboard/event-list.css';

</style>
<script>
  import { UPDATE_EVENT, DELETE_EVENT, DUPLICATE_EVENT, MY_PAGINATED_EVENTS } from '@/graphql';


  export default {
    props: {
      items: {
        type: Array,
        default: function () {
          return { items: [] };
        }
      },
      required:{
        type: Boolean,
        default: true
      },
      archive: {
        type: Boolean,
        default: false
      },
      eventFilter: {
        type: Object,
        default: null
      }
    },
    data () {
      return {
        defaultImage : '/assets/img/event_default.png', /** Immagine di default */
        string:{
          /*
              eventList:{
              _private: "privato",
              _public: "pubblico",
              _what: "Cosa vuoi fare adesso ?",
              _publish: "Pubblica",
              _edit: "Modifica",
              _delete: "Elimina",
            }*/
        },
        months: [],
        events: [],
        outputEvents: [],
        notPublishedLayerVisible : true,
      };
    },
    watch: {
      items: function (){
        this.bindEvents();
      },
      eventFilter: {
        handler: function () {
          if (this.eventFilter.title) {
            this.outputEvents = this.filteredData(this.events, this.eventFilter);
          } else
          {this.bindEvents();}
        },
        deep: true
      }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
        context.bindEvents();
      });
    },
    mounted (){
      this.bindEvents();
    },
    methods: {
      sortDescending () {
        this.outputEvents = this.outputEvents.sort((a, b) => {
          if (a.startDate > b.startDate) {return -1;}
          if (a.startDate < b.startDate) {return 1;}
          return 0;
        });
      },
      sortAscending () {
        this.outputEvents = this.outputEvents.sort((a, b) => {
          if (a.startDate > b.startDate) {return 1;}
          if (a.startDate < b.startDate) {return -1;}
          return 0;
        });
      },
      filteredData (data, eventFilter) {
        const list = [];

        for (let i = 0; i < data.length; i++){
          if (data[i].title.toLowerCase().indexOf(eventFilter.title.toLowerCase()) > -1) {
            list.push(data[i]);
          } else if (eventFilter.description && data[i].description.toLowerCase().indexOf(eventFilter.title.toLowerCase()) > -1) {
            list.push(data[i]);
          } else if (data[i].place != null) {
            if ((data[i].place.name.toLowerCase().indexOf(eventFilter.title.toLowerCase()) > -1) ||
                    (data[i].place.address.toLowerCase().indexOf(eventFilter.title.toLowerCase()) > -1) ||
                    (data[i].place.city.toLowerCase().indexOf(eventFilter.title.toLowerCase()) > -1)){
              list.push(data[i]);
            }
          }
        }
        return list;
      },
      buildMonths (){
        this.months = [];
        this.months.push(this.string.eventList._jan); // "Gen"
        this.months.push(this.string.eventList._feb); // "Feb",
        this.months.push(this.string.eventList._mar); // "Mar",
        this.months.push(this.string.eventList._apr); // "Apr",
        this.months.push(this.string.eventList._may); // "May",
        this.months.push(this.string.eventList._jun); // "Giu",
        this.months.push(this.string.eventList._jul); // "Lug",
        this.months.push(this.string.eventList._aug); // "Ago",
        this.months.push(this.string.eventList._sep); // "Set",
        this.months.push(this.string.eventList._oct); // "Ott",
        this.months.push(this.string.eventList._nov); // "Nov",
        this.months.push(this.string.eventList._dec); // "Dic",
      },
      bindEvents (){
        const context = this;
        this.buildMonths();
        this.events = this.items.map((item) => {
          item.smartBg = (item.genre === 'PRIVATE') ? 'privabg' : 'publibg';
          item.startdate_gg = new Date(item.startDate).getDate();
          item.enddate_gg = new Date(item.endDate).getDate();
          item.startdate_mm = context.months[new Date(item.startDate).getMonth()];
          item.enddate_mm = context.months[new Date(item.endDate).getMonth()];
          item.startdate_yy = new Date(item.startDate).getFullYear();
          item.enddate_yy = new Date(item.endDate).getFullYear();
          item.fulldate = item.startdate_yy+'-'+(new Date(item.startDate).getMonth()+1)+'-'+item.startdate_gg;
          item.privateLabel = (item.genre === 'PRIVATE') ? context.string.eventList._private : context.string.eventList._public;
          return item;
        });

        this.outputEvents = this.events;
      },
      goSingleEvent (itemId, status) {
        if (status === 'ACTIVE'){
          this.$router.push({ path:'/event/' + itemId.toString() , props: { eventId: itemId.toString() }  }).catch(error =>{});
        }
      },
      editSingleEvent (itemId) {
        this.$router.push({ path:'/event/' + itemId.toString() , props: { eventId: itemId.toString() }  }).catch(error =>{});
      },
      async duplicateSingleEvent (item) {
        const context = this;
        const index = this.events.indexOf(item);
        if (index > -1) {
          return this.$apollo
            .mutate({
              mutation: DUPLICATE_EVENT,
              variables: {
                eventId: item.id
              },
            })
            .then(value => {
              if (value.errors){
                console.log('error ' + value.errors);
              } else {
                const newEvent = value.data.duplicateEvent;
                this.items.splice(index+1, 0, { ...this.items[index], id: newEvent.id, name: newEvent.name });
                context.bindEvents();
                context.$root.bus.emit('simpleToast', context.string.visibilityManager._uploadsuccess);
              }
            })
            .catch(reason => {
              console.log(reason.message);
              context.$root.bus.emit('simpleToast', 'Failed');
            });
        }
      },
      publishSingleEvent (item){
        const context = this;
        const index = this.events.indexOf(item);
        if (index > -1) {
          return this.$apollo
                  .mutate({
                    mutation: UPDATE_EVENT,
                    variables: {
                      eventId: item.id,
                      updateEventInput: {status: 'ACTIVE'}
                    }
                  })
                  .then(value => {
                    if(value.errors){
                      console.log('error ' + value.errors)
                    }else {
                      item.status = 'ACTIVE';
                      context.bindEvents();
                      context.$root.bus.emit('simpleToast', context.string.visibilityManager._uploadsuccess);
                    }
                  })
                  .catch(reason => {
                    console.log(reason.message);
                    context.$root.bus.emit('simpleToast', 'Failed');
                  });

        }
      },
      deleteSingleEvent (i){
        const context = this;
        if (confirm(this.string.guestlist._sure)){
          const index = this.events.indexOf(i);
          if (index > -1) {
            return this.$apollo
                    .mutate({
                      mutation: DELETE_EVENT,
                      variables: {eventId:i.id}
                    })
                    .then(value => {
                      if(value.errors){
                        console.log('error ' + value.errors)
                      }else {
                        context.items.splice(index, 1);
                        context.bindEvents();
                        context.$root.bus.emit('simpleToast', context.string.eventList._deletesuccess);
                      }
                    })
                    .catch(reason => {
                      console.log(reason.message);
                      context.$root.bus.emit('simpleToast', 'Failed');
                    });
          }
        }
      },
      unArchiveSingleEvent (item){
        const context = this;
        const index = this.events.indexOf(item);
        if (index > -1) {
          return this.$apollo
                  .mutate({
                    mutation: UPDATE_EVENT,
                    variables: {
                      eventId: item.id,
                      updateEventInput: {status: 'INACTIVE'}
                    }
                  })
                  .then(value => {
                    if(value.errors){
                      console.log('error ' + value.errors)
                    }else {
                      item.status = 'INACTIVE';
                      context.items.splice(index, 1);
                      context.bindEvents();
                      context.$root.bus.emit('simpleToast', context.string.visibilityManager._uploadsuccess);
                    }
                  })
                  .catch(reason => {
                    console.log(reason.message);
                    context.$root.bus.emit('simpleToast', 'Failed');
                  });
        }
      },
      archiveSingleEvent (item){
        const context = this;
        const index = this.events.indexOf(item);
        if (index > -1) {
          return this.$apollo
                  .mutate({
                    mutation: UPDATE_EVENT,
                    variables: {
                      eventId: item.id,
                      updateEventInput: {status: 'ARCHIVED'}
                    }
                  })
                  .then(value => {
                    if(value.errors){
                      console.log('error ' + value.errors)
                    }else {
                      item.status = 'ARCHIVED';
                      context.items.splice(index, 1);
                      context.bindEvents();
                      context.$root.bus.emit('simpleToast', context.string.visibilityManager._uploadsuccess);
                    }
                  })
                  .catch(reason => {
                    console.log(reason.message);
                    context.$root.bus.emit('simpleToast', error);
                  });

        }
      }
    }
  };
</script>
