var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "template-list-content" } }, [
    _c(
      "div",
      {
        staticClass: "row selector-container",
        attrs: { id: "template-selector-container" },
      },
      [
        _vm.templateList.length > 0
          ? _c(
              "div",
              { staticClass: "template-list-list " },
              _vm._l(_vm.templateList, function (template) {
                return _c(
                  "div",
                  { key: template.id, staticClass: "card-container" },
                  [
                    _c("div", { staticClass: "card-body" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.templateSelected(template.code)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "template-icon" }, [
                            _c(
                              "div",
                              { staticClass: "template-name-first-letter" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(template.displayName.charAt(0)) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-footer" }, [
                      _c("div", { staticClass: "template-name" }, [
                        _c("p", { staticClass: "card-title" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(template.displayName) +
                              "\n            "
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }