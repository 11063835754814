var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "banner-area", class: { "dark-mode": _vm.isDarkMode } },
    [
      _c(
        "div",
        {
          staticClass: "mode-toggle",
          on: {
            click: function ($event) {
              return _vm.$emit("toggleDarkMode")
            },
          },
        },
        [_c("i", { class: _vm.isDarkMode ? "fa fa-sun-o" : "fa fa-moon-o" })]
      ),
      _vm._v(" "),
      _vm.logoEnabled
        ? _c("img", {
            staticClass: "center banner-img",
            attrs: {
              src: _vm.srcImage,
              "event-id": _vm.eventId,
              alt: "logo Image",
              width: "100%",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "sample-text- area-black" }, [
        _c(
          "div",
          {
            staticClass:
              "row justify-content-center generic-height align-items-center",
          },
          [
            _c("div", { staticClass: "banner-content text-center" }, [
              _vm.teamUniqueName === "lamborghini"
                ? _c("img", {
                    staticStyle: { "margin-bottom": "50px" },
                    attrs: {
                      src: "https://penguinpass-media.s3.eu-west-1.amazonaws.com/Logo_della_Lamborghini.png",
                      width: "150",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.teamUniqueName === "buccellati"
                ? _c("img", {
                    staticStyle: { "margin-bottom": "50px" },
                    attrs: {
                      src: "https://penguinpass-media.s3.eu-west-1.amazonaws.com/Buccellati_white_logo.png",
                      width: "343",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "text top text-uppercase" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _c("br"),
              _c("br"),
              _vm._v(" "),
              _vm.joinStatus === 0 || _vm.joinStatus === 1
                ? _c("h1", { staticClass: "guest-name text-uppercase" }, [
                    _vm._v(
                      _vm._s(_vm.guestName) + " " + _vm._s(_vm.guestSurname)
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }