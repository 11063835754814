export default {
  commonActions: {
    save: "حفظ",
    saveSettings: "احفظ التغييرات",
    submit: "إرسال",
    send: "إرسال",
    edit: "تعديل",
    upload: "تحميل",
    download: "تحميل",
    open: "افتح",
    close: "أغلق",
    delete: "حذف",
    passwordPolicy: "يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ويجب أن تحتوي على أحرف وأرقام وأحرف خاصة."
  },
  useExistingPolicy: {
    _labelActive: "تفعيل",
    _labelMandatory: "إلزامي",
    _labelNotMandatory: "ليس إلزاميا",
    _labelOnlyThisEvent: "يقتصر على هذا الحدث",
    _noPolicies: "لم يتم تحميل أي سياسات",
    _name: "اسم نهج الخصوصية:"
  },
  createPolicy: {
    _small: "إدارة سياسة خصوصية الحدث",
    _subtitle: "",
    _policyName: "اسم السياسة",
    _policyText: "نص السياسة",
    _createPolicy: "خلق"
  },
  privacyPolicy: {
    _createNew: "إنشاء سياسة الخصوصية الخاصة بك",
    _chooseExisting: "إنشاء سياسة الخصوصية"
  },
  groupManager: {
    _noDataMessage: "لا يوجد مستخدمين في هذه المجموعة حتى الان",
    _noDataHint: "أدخل من هنا"
  },
  modalError: {
    _title: "هل هناك خطب ما...",
    _buttonClose: "قريب",
    _unManagedError: "خطأ غير معالج"
  },
  modalWarning: {
    _title: "الحذر",
    _buttonClose: "قريب",
    _warningNotImportedMessage0:
      "لم يكن من المستحيل استيراد بعض العناصر من قائمتك.",
    _warningNotImportedMessage1:
      "في بعض الأحيان يكون هذا بسبب أخطاء في التنسيق ، أو يوجد بالفعل ضيف آخر بنفس البريد الإلكتروني في قائمة المدعوين.",
    _warningNotImportedMessage2: "تحقق من قائمتك ، وحاول مرة أخرى.",
    _warningNotImportedLinkDownload: "قم بتنزيل قائمة العناصر غير المستوردة",
    _itemNotImportedRecap: "الرقم غير مستورد",
    _unManagedError: "خطأ غير معالج"
  },
  functionBar: {
    _new: "جديد",
    _events: "الأحداث",
    _archive: "أرشيف",
    _search: "قرب",
    _tutorial: "درس تعليمي",
    _report: "نقل"
  },
  attachments: {
    _selectattachments: "حدد المرفق الخاص بك",
    _putattachmentstoevent: "قم بإرفاق المستندات بالحدث الذي تريد عرضه في التطبيق",
    _help: "في هذا القسم ، يمكنك تحميل مستندات PDF فقط.",
    _attachmentTypeSelect: "حدد نوع المرفق",
    _map: "قياس الكواكب",
    _brochure: "كراسة",
    _invite: "دعوة",
    _press: "خبر صحفى",
    _upload: "تحميل",
    _loadingMessage: "أنتظر لأجل",
    _mandatoryFields: "حدد نوع المستند والملف من الأرشيف الخاص بك",
    _filetype: "نوع المستند *",
    _uploadsuccess: "تم الرفع",
    _uploadfailed: "التحميل فشل",
    _deleteAttachment: "حذف المرفق"
  },
  attendeeMaxLimit: {
    _title: "الحد الأقصى للتأكيدات",
    _subtitle: "قم بتعيين الحد الأقصى لعدد التأكيدات المقبولة لهذا الحدث",
    _placeholder: "الحد الأقصى لعدد الحجوزات",
    _submit: "حفظ الإعداد",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج"
  },
  coverImage: {
    _updateDone: "تم التحديث",
    _loadingMessage: "أنتظر لأجل",
    _imgNotices: "الحجم الموصى به 600 × 350 بكسل ، بحد أقصى 1 ميجابايت",
    _imgSizeExceed: "تجاوز الحد الأقصى للحجم 1 ميغا بايت!"
  },
  backgroundImage: {
    _updateDone: "تم التحديث",
    _loadingMessage: "أنتظر لأجل",
    _imgNotices: "الحجم الموصى به 600 × 350 بكسل ، بحد أقصى 1 ميجابايت",
    _imgSizeExceed: "تجاوز الحد الأقصى للحجم 1 ميغا بايت!"
  },
  createSelectEvent: {
    _public: "عام",
    _publicboxtxt: "الترويج لحدثك. سيراه جميع المستخدمين في أي مكان",
    _private: "نشر",
    _privateboxtxt: "إنشاء الحدث الخاص بك وإدارة قوائم الضيوف الخاصة بك. بسرعة وبساطة!",
    _notauthorized: "اتصل بنا لتفعيل هذا الخيار"
  },
  description: {
    _descriptionFieldPlaceHolder: "أدخل وصفا لهذا الحدث الخاص بك",
    _save: "حفظ",
    _create: "يخلق",
    _created: "خلقت",
    _modified: "تم التعديل",
    _successmessage: "تم تعديل الحدث بنجاح",
    _event: "حدث",
    _pagetitlePrivate: "حدث خاص",
    _pagetitlePublic: "حدث عام",
    _eventtype: "نوع الحدث *",
    _eventname: "اسم الحدث *",
    _startdate: "تاريخ البدء *",
    _enddate: "تاريخ الانتهاء *",
    _location: "موقعك",
    _flyer: "نشرة إعلانية للحدث",
    _description: "وصف",
    _alternativeLanguageVersion: "النسخة الإنجليزية",
    _alternativeLanguageVersionSmall:
      "أدخل النص الخاص بك هنا إذا كان التطبيق الخاص بك يدعم الحدث متعدد اللغات",
    _locationErrorMessage: "عنوان خاطئ",
    _locationStatusSarching: "تحقق في التقدم..",
    _undo: "حذف",
    _clear: "حذف",
    _next: "يخلق", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "تصفح",
    _loadingCoverMessage: "جاري إنشاء الحدث..",
    _creationFailed: "الخلق الفاشل",
    _modificationFailed: "فشل التعديل",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة",
    _loadingMessage: "أنتظر لأجل"
  },
  eventList: {
    _unarchive: "يعيد",
    _private: "نشر",
    _public: "عام",
    _what: "ماذا تريد ان تفعل الآن ؟",
    _publish: "تفعيل",
    _edit: "تعديل",
    _delete: "حذف",
    _archive: "أرشيف",
    _duplicate: "ينسخ",
    _jan: "كانون الثاني", // "Gen"
    _feb: "شهر فبراير", // "Feb",
    _mar: "مارس", // "Mar",
    _apr: "أبريل", // "Apr",
    _may: "قد", // "Mag",
    _jun: "يونيو", // "Giu",
    _jul: "تموز", // "Lug",
    _aug: "أغسطس", // "Ago",
    _sep: "شهر تسعة", // "Set",
    _oct: "اكتوبر", // "Ott",
    _nov: "شهر نوفمبر", // "Nov",
    _dec: "ديسمبر", // "Dic",
    _deletesuccess: "عنصر محذوف"
  },
  months: ["كانون الثاني", "شهر فبراير", "مارس", "أبريل", "قد", "يونيو", "تموز", "أغسطس", "شهر تسعة", "اكتوبر", "شهر نوفمبر", "ديسمبر"],
  timeSlotManager: {
    _title: "فتحات الوقت",
    _upload: "قم بتحميل التقويم الخاص بك (.ics)",
    _deleteAllSlotsWarning:
      "هل تريد حقًا التخلص من كل فتحاتك؟ هذا العمل لا رجوع فيه.",
    _deleteAllSlots: "احذف جميع الفتحات",
    _openTableEditor: "افتح المحرر",
    _closeTableEditor: "لا تحفظ تغييراتك",
    _saveTableEditor: "احفظ التغييرات",
    _innertitle: "قم بتمكين الفترات الزمنية",
    _maxLimitPerSlot: "الحد حسب الفترة الزمنية",
    _import_failure:
      "تعذر العثور على أي إطار زمني مناسب للاستيراد. الرجاء معاودة المحاولة في وقت لاحق",
    _import_success: " تم استيراد الفترات الزمنية بنجاح",
    _disabled: "(معاق)",
    _nothing_to_export: "لا توجد بيانات للتصدير",
    _export_data: "تصدير حجوزاتك بصيغة .ics"
  },
  modalEmailBuilder: {
    _title: "هل هناك خطب ما...",
    _buttonClose: "قريب",
    _unManagedError: "خطأ غير معالج",
    _templateCreationError: "خطأ: لم يتم إنشاء النموذج",
    _templateSaved: "تم حفظ النموذج",
    _emailCreationError: "خطأ: لم يتم إنشاء النموذج",
    _emailSaved: "تم حفظ البريد الإلكتروني",
    _save: "يحفظ",
    _confirm: "تأكيد",
    _update: "تحديث",
    _cancel: "يلغي",
    _templateName: "اسم القالب",
    _templateSubject: "موضوع البريد الإلكتروني",
    _backToTemplates: "القوالب",
    _nameIsMandatory: "اسم النموذج والموضوع مطلوبان",
    _areyousure: "هل أنت متأكد أنك تريد حذف هذا النموذج",
    _areyousure_cloning: "هل تريد حقًا إنشاء نموذج جديد باستخدام هذا؟",
    _areyousure_discard: "هل تريد حقًا الخروج قبل حفظ التغييرات؟",
    _notemplatesave: "لم يتم حفظ النموذج",
    _templateDelete: "تم حذف النموذج",
    _notemplate: "لم يتم إنشاء أي قوالب",
    _create: "يخلق",
    _template_sent: "أرسل البريد الإلكتروني!",
    _template_sent_error: "لم يتم إرسال البريد الإلكتروني بشكل صحيح!",
    _use_save_button: "بدلاً من ذلك ، استخدم زر حفظ على اليمين"
  },
  eventPad: {
    _save: "حفظ",
    _duplicate: "ينسخ",
    _create: "يخلق",
    _created: "خلقت",
    _modified: "تم التعديل",
    _successmessage: "تم تعديل الحدث بنجاح",
    _privateEvent: "حدث خاص",
    _publicEvent: "حدث عام",
    _event: "حدث",
    _pagetitlePrivate: "حدث خاص",
    _pagetitlePublic: "حدث عام",
    _eventtype: "نوع الحدث *",
    _eventname: "اسم الحدث *",
    _startdate: "تاريخ البدء *",
    _enddate: "تاريخ الانتهاء *",
    _location: "موقعك",
    _venue: "مكان",
    _flyer: "نشرة إعلانية للحدث",
    _description: "وصف",
    _locationErrorMessage: "عنوان خاطئ",
    _locationStatusSarching: "تحقق في التقدم..",
    _undo: "حذف",
    _clear: "حذف",
    _next: "يخلق", // Modifica temporanea finche' non possiamo gestire il back
    _browse: "تصفح",
    _loadingCoverMessage: "جاري إنشاء الحدث..",
    _creationFailed: "الخلق الفاشل",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة",
    _loadingMessage: "أنتظر لأجل",
    _resolvingAddressAlertMessage: "انتظر التحقق من العنوان"
  },
  eventPayable: {
    _title: "حدث مدفوع على الأنظمة الأخرى",
    _subtitle: "إشارات إلى أن الحدث يتم دفعه على أنظمة أخرى للمستخدمين",
    _submit: "حفظ الإعداد",
    _label: "هل الحدث الذي تروج فيه لحدث مدفوع؟",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج"
  },
  externalReference: {
    _title: "مرجع خارجي",
    _subtitle: "أدخل مرجع الحدث في النظام الخارجي أدناه",
    _placeholder: "معرف المرجع الخارجي",
    _submit: "سالفا إمبوستازيوني",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج"
  },
  externalPurchaseEndpoint: {
    _title: "نقطة نهاية التأكيد الخارجية",
    _subtitle: "أدخل مرجع URL لصفحة التأكيد الخارجية أدناه",
    _placeholder: "شراء URL",
    _submit: "حفظ الإعداد",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج"
  },
  guestlist: {
    _refresh_table: "تحديث قائمة المدعوين",
    _title: "قائمة الضيوف",
    _subtitle: "من هنا إدارة قائمة الضيوف الخاصة بك",
    _loadingMessage: "أنتظر لأجل...",
    _add: "إضافة يدويا",
    _download: "قائمة التنزيل",
    _upload: "قائمة تحميل",
    _list: "قائمة",
    _guestnumber: "عدد الضيوف في القائمة: ",
    _removelist: "حذف كافة المدعوين",
    _nodata: "لا ضيوف",
    _guestadded: "أضاف ضيف",
    _listadded: "القائمة المستوردة",
    _itemCancellato: "ضيف إزالة",
    _listCancellata: "القائمة المحذوفة",
    _downloadlistAll: "قم بتنزيل القائمة بأكملها",
    _downloadlist: "تنزيل تسجيلات الوصول فقط",
    _downloadPlusOneList: "تنزيل +1",
    _downloadActivities: "قم بتنزيل تقرير النشاط",
    _downloadAttachments: "تحميل المرفقات",
    _sure:
      "ستفقد المتابعة جميع معلومات ضيوفك بشكل لا رجعة فيه ، بما في ذلك الردود على الدعوة التي تم الحصول عليها بالفعل. هل تريد المتابعة؟",
    _sureForSingleItem:
      "في حالة المتابعة ، ستفقد معلومات المدعو بشكل لا رجعة فيه ، بما في ذلك الرد على الدعوة. هل تريد المتابعة؟",
    _guestupdated: "الضيف المحدث",
    _errorInEditItems:
      "لا يمكن المضي قدما. خطأ في تحديث بيانات المدعو.",
    _cannotProceed: "لا يمكن المضي قدما",
    _canDeleteGuestWithPurchase:
      "لا يمكن المضي قدما. اشترى المدعو عنوانًا للوصول إلى الحدث.",
    _loadInProgress: "جار التحميل...",
    _attendeePartnersMax: "عدد المرافقين لكل شخص",
    _attendeePartnersMaxUpdate: "تم التحديث",
    _listEmailsUpdated: "تم تحديث قائمة البريد الإلكتروني",
    _maxAttendeeExceeded: "عذرا ، القائمة ممتلئة!",
    _maxGuestSizeReached: "لا يمكنك إضافة المزيد من المدعوين ، لقد وصلت إلى الحد الأقصى لعدد المدعوين لكل حدث. ",
    _upload_success: "استيراد قائمة المدعوين الآن",
    _upload_failure: "فشل تحميل قائمة المدعوين",
    _discard_message:
      "لم يتم استيراد بعض ضيوفك بشكل صحيح. انقر لمعرفة السبب",
    _audience_title: "تصفية الضيوف",
    _audience_subtitle: "حدد أحد الخيارات أدناه لتصفية ضيوفك",
    _autoPrint: "طباعة الشارات تلقائيًا عند تسجيل الوصول."
  },
  header: {
    _language: "لغة",
    _italian: "إيطالي",
    _english: "إنجليزي",
    _francaise: "فرنسي",
    _arab: "عرب",
    _logout: "أخرج",
    _unmanagederror: "خطأ غير معالج",
    _languagechanged: "الإعداد المحفوظ"
  },
  insertTicketForm: {
    _title: "",
    _name: "وصف *",
    _availability: "حد",
    _save: "حفظ",
    _price: "السعر *",
    _type: "شاب *",
    _pay: "دفع",
    _donation: "هبة",
    _nolimits: "لا حدود",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة"
  },
  listSingleItemPurchaseList: {
    _title: "أدخل تفاصيل أحد المشاركين",
    _name: "الاسم الاول *",
    _surname: "اسم العائلة *",
    _email: "بريد الالكتروني",
    _tel: "هاتف",
    _company: "وكالة",
    _nationality: "جنسية",
    _job: "عمل",
    _typology: "نوع المضيف",
    _save: "حفظ",
    _loadingMessage: "أنتظر لأجل...",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة",
    _unManagedError: "خطأ غير معالج",
    _emailAlreadyPresentError: "لم يتم إدراج جهة الاتصال. البريد الإلكتروني بالفعل في القائمة",
    _emailAlreadyPresentErrorToast: "الاتصال غير مدرج"
  },
  listSingleItem: {
    _title: "أدخل تفاصيل أحد المشاركين",
    _name: "الاسم الاول *",
    _surname: "اسم العائلة *",
    _email: "بريد الالكتروني",
    _tel: "هاتف",
    _clear: "حذف",
    _save: "حفظ",
    _add_n_save: "أضف واحفظ",
    _sureRemove: "هل أنت على وشك حذف العنصر المحدد ، هل أنت متأكد أنك تريد المتابعة؟",
    _loadingMessage: "أنتظر لأجل...",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة",
    _unManagedError: "خطأ غير معالج",
    _emailAlreadyPresentError: "لم يتم إدراج جهة الاتصال. البريد الإلكتروني بالفعل في القائمة",
    _emailAlreadyPresentErrorToast: "الاتصال غير مدرج",
    _emailSyntaxError: "يجب أن يكون البريد الإلكتروني مثل email@example.com",
    _emailSyntaxErrorToast: "البريد الإلكتروني غير صالح"
  },
  listUpload: {
    _title: "قم بتحميل قائمة بالحضور إلى الحدث الخاص بك",
    _clear: "حذف",
    _load: "حمل",
    _browse: "تصفح",
    _loadingMessage: "أنتظر لأجل...",
    _loadingComplete: "اكتمل التحميل",
    _loadingError: "فشل التحميل",
    _itemImported: "مستورد",
    _mandatoryFields: "لم يتم اختيار اي ملف",
    _unManagedError: "خطأ غير معالج",
    _downloadInstruction: "تحميل نموذج للاستيراد",
    _incompleteListUploadWarningTitle: "استيراد غير مكتمل", //  Controlla la lista degli elementi scartati.
    _incompleteListUploadWarning: "لم يتم استيراد بعض الأسماء." //  Controlla la lista degli elementi scartati.
  },
  messages: {
    _uploadsuccess: "تم التحديث",
    _loadingMessage: "أنتظر لأجل",
    _inappmessagesimple: "شكرا للتأكيد",
    _inappmessagemodified:
      "شكرا للتأكيد. هذا التأكيد إعلامي ولا يشمل شراء و / أو حجز تذكرة دخول.",
    _inmailmessageintent:
      "أرسل بريدًا إلكترونيًا للتأكيد إلى المستخدم في وقت التأكيد / الشراء",
    _savesettings: "احفظ التغييرات",
    _title: "البريد الإلكتروني للتأكيد",
    _subtitle:
      "حدد وخصص الرسالة التي سيتلقاها المستخدم عبر البريد الإلكتروني بعد الحصول على عنوان الوصول إلى الحدث الخاص بك",
    _confirmmessagetitle: "رسالة المستخدم في التطبيق",
    _confirmmessagesubtitle:
      "حدد الرسالة التي سيراها المستخدمون بعد تأكيد مشاركتهم",
    _name: "اسم المرسل (الافتراضي: Penguinpass)",
    _email: "البريد الإلكتروني (الافتراضي: transaction@mypenguinpass.com)",
    _subject: "الكائن (الافتراضي: تأكيد الطلب)",
    _customizemsg: "إضفاء الطابع الشخصي على الرسالة",
    _editmsg: "قم بتحرير الرسالة",
    _subjectExplanation: "قم بتحرير القالب الخاص بك لتغيير موضوع رسالة البريد الإلكتروني للتأكيد",
    _registrationFormTitle: "إستمارة تسجيل",
    _registrationFormDescription: "أرسل بريدًا إلكترونيًا للتأكيد بمجرد تسجيل ضيوفك عبر النموذج الخاص بك"
  },
  buttonTabs: {
    INTERNAL_VIRTUAL_ROOM: "تفعيل غرفة Penguinpass الخاصة بك",
    INTERNAL_REGISTRATION_FORM: "تفعيل غرفة Penguinpass الخاصة بك",
    EXTERNAL_VIRTUAL_ROOM: "تنشيط الغرفة الافتراضية الخارجية",
    createNewTemplate: "خلق جديد إبداع جديد",
    useExistingTemplate: "حدد نموذجا",
    CONFIRMATION_EMAIL_ENABLED: "نعم ، قم بإنشاء بريد إلكتروني للتأكيد",
    CONFIRMATION_EMAIL_DISABLED: "لا ، هذا غير مطلوب",
    SHOW_CATEGORY_FORM:"خلق جديد إبداع جديد",
    SHOW_CATEGORY_TABLE:"إدارة الموجودة",
    SHOW_EVENT_GROUP_FORM:"خلق جديد إبداع جديد",
    SHOW_EVENT_GROUP_TABLE:"إدارة الموجودة",
  },
  virtualRoomSettings: {
    _title: "غرفة افتراضية",
    _subtitle: "قم بتنشيط غرفة Penguinpass الافتراضية الخاصة بك أو قم بدعوة الضيوف إلى غرف افتراضية خارجية أخرى.",
    _externalRoomTitle: "خارج الغرفة",
    _externalRoomSubtitle: "إضافة عنوان URL مخصص للغرفة (Zoom ، و Microsoft Teams ، و Google Hangouts ، وما إلى ذلك)",
    _internalRoomTitle: "قم بإنشاء صفحة مقصودة لغرفتك الافتراضية",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "حدد موعد الاجتماع",
    _instantMeeting: "لقاء فوري",
    _meetingTimeSubtitle: "يمكنك تحديد اجتماع فوري لضبط الوقت تلقائيًا بساعة",
    _meetingTimeSubtitle2: "أو ضبط الوقت يدويًا لوقت لاحق",
    _createdRoomsTitle: "تفاصيل الغرفة الافتراضية",
    _createdRoomsSubtitle: "",
    _previewTitle: "معاينة",
    _hour: "الآن",
    _hours: "ساعات",
    _scheduleRoom: "حدد موعدًا للغرفة",
    _moreSettingsTitle: "إعدادات الغرفة الافتراضية الأخرى",
    _authNeededLabel: "يستلزم التوثيق",
    _moderatedChatLabel: "محادثة معتدلة",
    _emailRequiredLabel: "البريد الإلكتروني عند التسجيل",
    _coverImageLabel: "تحميل صورة الغلاف",
    _authNeededExplanation: "جعل الصفحة متاحة فقط للمستخدمين المسجلين.",
    _moderatedChatExplanation: "تسمح هذه الميزة للمنسق بالموافقة على أو رفض جميع الرسائل المرسلة من قبل الضيوف والمقدمين",
    _emailRequiredExplanation: "تقوم هذه الميزة بتنشيط وإلغاء تنشيط حقل بريد إلكتروني إلزامي في صفحة التسجيل",
    _coverImageExplanation: "إضفاء الطابع الشخصي على صفحتك المقصودة مع صورة غلاف لعلامتك التجارية.",
    _uploadImage: "تحميل الصورة (1 ميجا بايت كحد أقصى)",
    _openRoom: "غرفة مفتوحة",
    _cuesdayoom: "قم بإنشاء صفحة هبوط جديدة",
    _customizeroom: "إضفاء الطابع الشخصي على غرفتك الافتراضية",
    _authneeded: "يستلزم التوثيق",
    _moderatedchat: "محادثة معتدلة",
    _uploadsuccess: "محدث",
    _loadingMessage: "أرجو الإنتظار",
    _savesettings: "احفظ التغييرات",
    _showPreview: "عرض المعاينة",
    _hidePreview: "إخفاء المعاينة",
    _selectTemplate: "تطبيق قالب",
    _enableCustomVRoom: "تفعيل غرفة افتراضية مخصصة",
    _roomscheduling: "جدولة الغرفة",
    _roomschedulingsubtitle: "هنا يمكنك جدولة موعد بدء الاجتماع",
    _instantmeeting: "لقاء فوري",
    _uploadCover: "تحميل صورة الغلاف",
    _publishpage: "نشر الصفحة",
    _unpublishpage: "إلغاء نشر الصفحة",
    _downloadlogs: "سجل التنزيل",
    _goToPage: "افتح الصفحة في علامة تبويب جديدة",
    _pickNewerDate: "يجب أن يكون تاريخ ووقت البدء في المستقبل",
    _instantMeetingWarning: "أوقف الغرف الافتراضية النشطة أولاً.",
    _pageSlug: "URL مخصص",
    _applyPageSlug: "يتقدم",
    _pageSlugInUse: "عنوان URL المخصص قيد الاستخدام بالفعل",
    _pageSlugExplanation: "تخصيص عنوان صفحتك",
    _scheduleRoomHalfAdvance: "سيتمكن المنسقون والمقدمون (الندوة عبر الويب فقط) من الدخول قبل نصف ساعة. تحقق من حالة الغرفة ، ستكون نشطة قبل نصف ساعة من الوقت المحدد."
  },
  registrationFormSettings: {
    _title: "غرفة افتراضية",
    _subtitle: "قم بتنشيط غرفة Penguinpass الافتراضية الخاصة بك أو قم بدعوة الضيوف إلى غرف افتراضية خارجية أخرى.",
    _externalRoomTitle: "خارج الغرفة",
    _externalRoomSubtitle: "إضافة عنوان URL مخصص للغرفة (Zoom ، و Microsoft Teams ، و Google Hangouts ، وما إلى ذلك)",
    _internalRoomTitle: "قم بإنشاء صفحة مقصودة لغرفتك الافتراضية",
    _internalRoomSubtitle: "",
    _meetingTimeTitle: "حدد موعد الاجتماع",
    _instantMeeting: "لقاء فوري",
    _meetingTimeSubtitle: "يمكنك تحديد اجتماع فوري لضبط الوقت تلقائيًا بساعة",
    _meetingTimeSubtitle2: "أو ضبط الوقت يدويًا لوقت لاحق",
    _createdRoomsTitle: "تفاصيل الغرفة الافتراضية",
    _createdRoomsSubtitle: "",
    _previewTitle: "معاينة",
    _hour: "الآن",
    _hours: "ساعات",
    _scheduleRoom: "حدد موعدًا للغرفة",
    _moreSettingsTitle: "إعدادات الغرفة الافتراضية الأخرى",
    _authNeededLabel: "يستلزم التوثيق",
    _moderatedChatLabel: "محادثة معتدلة",
    _emailRequiredLabel: "البريد الإلكتروني عند التسجيل",
    _coverImageLabel: "تحميل صورة الغلاف",
    _authNeededExplanation: "جعل الصفحة متاحة فقط للمستخدمين المسجلين.",
    _moderatedChatExplanation: "تسمح هذه الميزة للمنسق بالموافقة على أو رفض جميع الرسائل المرسلة من قبل الضيوف والمقدمين",
    _emailRequiredExplanation: "تقوم هذه الميزة بتنشيط وإلغاء تنشيط حقل بريد إلكتروني إلزامي في صفحة التسجيل",
    _coverImageExplanation: "إضفاء الطابع الشخصي على صفحتك المقصودة مع صورة غلاف لعلامتك التجارية.",
    _uploadImage: "تحميل الصورة (1 ميجا بايت كحد أقصى)",
    _openRoom: "غرفة مفتوحة",
    _cuesdayoom: "قم بإنشاء صفحة هبوط جديدة",
    _customizeroom: "إضفاء الطابع الشخصي على غرفتك الافتراضية",
    _authneeded: "يستلزم التوثيق",
    _moderatedchat: "محادثة معتدلة",
    _uploadsuccess: "محدث",
    _loadingMessage: "أرجو الإنتظار",
    _savesettings: "احفظ التغييرات",
    _showPreview: "عرض المعاينة",
    _hidePreview: "إخفاء المعاينة",
    _selectTemplate: "تطبيق قالب",
    _enableCustomVRoom: "تفعيل غرفة افتراضية مخصصة",
    _roomscheduling: "جدولة الغرفة",
    _roomschedulingsubtitle: "هنا يمكنك جدولة موعد بدء الاجتماع",
    _instantmeeting: "لقاء فوري",
    _uploadCover: "تحميل صورة الغلاف",
    _publishpage: "نشر الصفحة",
    _unpublishpage: "إلغاء نشر الصفحة",
    _downloadlogs: "سجل التنزيل",
    _goToPage: "افتح الصفحة في علامة تبويب جديدة",
    _pickNewerDate: "يجب أن يكون تاريخ ووقت البدء في المستقبل",
    _instantMeetingWarning: "أوقف الغرف الافتراضية النشطة أولاً.",
    _pageSlug: "URL مخصص",
    _applyPageSlug: "يتقدم",
    _pageSlugInUse: "عنوان URL المخصص قيد الاستخدام بالفعل",
    _pageSlugExplanation: "تخصيص عنوان صفحتك",
    _scheduleRoomHalfAdvance: "سيتمكن المنسقون والمقدمون (الندوة عبر الويب فقط) من الدخول قبل نصف ساعة. تحقق من حالة الغرفة ، ستكون نشطة قبل نصف ساعة من الوقت المحدد."
  },
  newEvent: {
    _create: "يخلق",
    _created: "خلقت",
    _event: "حدث",
    _pagetitlePrivate: "حدث خاص",
    _pagetitlePublic: "حدث عام",
    _eventtype: "فئة *",
    _eventname: "الاسم الاول *",
    _startdate: "تاريخ البدء *",
    _enddate: "تاريخ الانتهاء *",
    _location: "تبوك *",
    _venue: "مكان",
    _virtualVenue: "مكان افتراضي",
    _descriptionIt: "وصف (It)",
    _descriptionEn: "وصف (En)",
    _undo: "كانسيلا",
    _clear: "كانسيلا",
    _next: "كريا", // Modifica temporanea finche' non possiamo gestire il back
    _loadingCoverMessage: "جاري إنشاء الحدث ..",
    _creationFailed: "الخلق الفاشل",
    _mandatoryFields: "الحقول التي عليها علامة النجمة (*) مطلوبة",
    _placeNotValid: "قيمة حقل العنوان غير صالحة"
  },
  overview: {
    _pending: "ريثما",
    _confirmed: "تم تأكيد",
    _checkin: "تحقق في",
    _total: "مجموع",
    _guestsAndCompanions: "مؤكدة و +1"
  },
  promoteEvent: {
    _title: "ترويج الحدث",
    _subtitle:
      "روّج لحدثك وانشره دائمًا فوق جميع الأحداث المتاحة في كل موقع",
    _submit: "حفظ الإعداد",
    _label: "روّج لهذا الحدث",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج"
  },
  purchaselist: {
    _title: "قائمة الحجز",
    _subtitle:
      "من هنا قم بإدارة قائمة المستخدمين الذين حجزوا للمشاركة في الحدث الخاص بك",
    _loadingMessage: "أنتظر لأجل...",
    _add: "إضافة يدويا",
    _download: "قائمة التنزيل",
    _upload: "قائمة تحميل",
    _list: "قائمة",
    _guestnumber: "عدد الحجوزات:",
    _removelist: "حذف كافة المدعوين",
    _nodata: "لا يوجد حجوزات",
    _guestadded: "أضاف ضيف",
    _listadded: "القائمة المستوردة",
    _itemCancellato: "ضيف إزالة",
    _listCancellata: "القائمة المحذوفة",
    _downloadlistAll: "قم بتنزيل القائمة بأكملها",
    _downloadlist: "تحميل تسجيلات الوصول فقط"
  },
  report: {
    _title: "إحصائيات",
    _subtitle: "تحليل البيانات التي تم جمعها",
    _timeLineCheckinChartLegend: "يوضح الرسم البياني التطور والتدفق بالساعة عند نقطة تسجيل الوصول أثناء الحدث.",
    _attendeeConfirmationChartLegend: "يوضح الرسم البياني رقم rsvp اليومي",
    _export: "تحميل",
    _attendeeChart: "قائمة المشاركين",
    _timeLineAttendeeConfirmationTitle: "الجدول الزمني لتأكيد الحضور",
    _timeLineCheckInTitle: "الجدول الزمني في الاختيار",
    _totalAttendeeStatus: "إجمالي حالة الحضور",
    _export_request_received: "تم قبول طلبك. سنبلغك قريبا",
    _export_request_finished: "تقريرك جاهز. أضغط هنا للتحميل",
    _export_failed: "فشلت العملية",
    _export_empty: "التقرير الذي تحاول تنزيله لا يحتوي على بيانات"
  },
  sidebar: {
    _dashboard: "لوحة القيادة",
    _newevent: "حدث جديد",
    _downloadpenguinpass: "Guest App",
    _privacypolicy: "سياسة خاصة    ",
    _contacts: "جهات الاتصال",
    _allevents: "الأحداث",
    _emailTemplate: "قوالب البريد الإلكتروني",
    _eventArchived: "أرشيف",
    _terms: "الأحكام والشروط",
    _linkTerms: "https://www.penguinpass.it/terms-and-conditions-it.html",
    _linkContacts: "https://www.penguinpass.it/italian/contact-us-italian.html",
    _faq: "/help/it/index.html",
    _privacypolicyURL: "https://www.penguinpass.it/privacy-policy-it.html", //
    _applestore: "https://itunes.apple.com/it/app/penguinpass/id1050702093?mt=8",
    _googlestore: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckinURL:
      "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight",
    _downloadpenguinpasscheckin: "Check-In App",
    _downloadpenguinpasscheckinURL_android: "https://play.google.com/store/apps/details?id=com.penguinpass.checkinlight&hl=it",
    _downloadpenguinpasscheckinURL_ios: "https://apps.apple.com/it/app/penguin-check-in-light/id1572985276",
    _downloadpenguinpasscheckin_android: "Check-In App Android",
    _downloadpenguinpasscheckin_ios: "Check-In App IOS",
    _downloadpenguinpassbeacon: "Beacon App",
    _downloadpenguinpassbeaconURL:
      "https://itunes.apple.com/it/app/vbpenguinpass/id1204226558?mt=8",
    _members: "فريقي",
    _settings: "إعدادات",
    _logoutConfirm: "هل تريد حقًا قطع الاتصال؟",
    _eventGroup:"مجموعات الحدث"
  },
  tickets: {
    _title: "تذاكر",
    _subtitle: "إدارة قائمة التذاكر المرتبطة بالحدث الخاص بك",
    _nodata: "حدث مجاني",
    _nodata_payable: "حدث مدفوع على الأنظمة الأخرى",
    _add: "أضف تذكرة",
    _confirmDelete: "أنت حقا تريد حذف هذا العنوان",
    _itemCancellato: "تمت إزالة العنوان",
    _itemAdded: "تمت إضافة العنوان",
    _test: ""
  },
  urlPrivacyPolicy: {
    _title: "سياسة الخصوصية",
    _management: "إدارة سياسة خصوصية الحدث",
    _subtitle:
      "أدخل مرجع عنوان URL خارجيًا لسياسة الخصوصية التي تريد أن يراها المستخدمون",
    _placeholder: "سياسة URL",
    _submit: "حفظ الإعداد",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج",
    _sure:
      "بالمتابعة ستفقد بشكل لا رجعة فيه جميع المعلومات الواردة في سياسة الخصوصية هذه. هل تريد المتابعة؟"
  },
  visibilityManager: {
    _publish: "تفعيل الحدث",
    _hidden: "تعطيل الحدث",
    _uploadsuccess: "تم التحديث",
    _archivesuccess: "حدث مؤرشف",
    _unarchivesuccess: "استعادة الحدث",
    _error: "خطأ: أدخل البيانات المطلوبة"
  },
  main: {
    _notauthorizedoption: "تعطيل الخيار"
  },
  home: {
    _titleEventList: "الأحداث النشطة"
  },
  eventCreateList: {
    _event: "حدث",
    _createString: "قم بإنشاء قائمة الضيوف الأولى الخاصة بك",
    _prospectList: "تم تحميل قائمة الضيوف",
    _add: "إضافة يدويا",
    _download: "قائمة التنزيل",
    _upload: "قائمة تحميل",
    _next: "إلى الأمام",
    _loadingMessage: "أنتظر لأجل...",
    _sure: "هل انت متأكد انك تريد المتابعة؟",
    _itemCancellato: "ضيف إزالة"
  },
  events: {
    _noEvents: "لم يتم العثور على أحداث.",
    _progress: "جار التحميل...",
    _all_events: "كل الأحداث",
    _my_events: "تذاكري",
    _favourites: "المفضلة",
    _archived_events: "الأحداث المؤرشفة",
    _search_result: "نتائج البحث"
  },
  singleEvent: {
    _event: "حدث",
    _createString: "قم بإنشاء قائمة الضيوف الأولى الخاصة بك",
    _prospectList: "تم تحميل قائمة الضيوف",
    _add: "إضافة يدويا",
    _download: "قائمة التنزيل",
    _upload: "قائمة تحميل",
    _next: "إلى الأمام",
    _insertPlace: "أدخل مكانًا",
    _loadingMessage: "أنتظر لأجل...",
    _description: "وصف",
    _emailMarketingWarning: "قم بتنشيط الحدث الخاص بك لاستخدام ميزة التسويق عبر البريد الإلكتروني"
  },
  datagrid: {
    _pending: "قيد الانتظار",
    _confirmed: "مؤكد",
    _checkin: "تحقق في",
    _declined: "انخفاض",
    _will_be_back: "سوف نعود",
    _checkout: "الدفع",
    _search: "قرب",
    _selected: "الضيوف المختارون",
    _selectAllGuests: "الجميع",
    _selectAllMarketingGuests: "جميع الضيوف المؤهلين",
    _selectPending: "الضيوف ينتظرون",
    _selectDecline: "الضيوف الذين انخفضوا",
    _selectNotRead: "الضيوف الذين لم يقرؤوا الحملات السابقة",
    _selectNotSend: "الضيوف غير المدرجة في الحملات السابقة",
    _selectNotSendConfirmed: "تم تأكيد الضيوف الذين لم يتم تضمينهم في الحملات السابقة",
    _selectUnRead: "حدد فقط الضيوف الذين لم يقرؤوا الرسائل البريدية السابقة",
    _selectUnSent: "حدد فقط الضيوف غير المدرجة في المراسلات السابقة",
    _selectConfirmed: "الضيوف المؤكدون",
    _selectCheckedIn: "الضيوف المسجّلون",
    _selectCustom: "حدد مخصص من القائمة",
    _selectCustomField: "استخدم الحقول المخصصة",
    _selectTimeSlot: "استخدم فترات زمنية",
    _edit_emails: "تحرير البريد الإلكتروني",
    _list_emails: "عرض القائمة",
    _post_confirmation_blocked:
      "آسف ، الضيف تم تأكيده بالفعل. لا يمكن تغيير بعض المعلومات",
    _guest_name: "الاسم الاول",
    _guest_surname: "اسم العائلة"
  },
  emailBuilderSelectMode: {
    _selectTemplateTitle: "نموذج",
    _selectTemplateText: "ابدأ في إنشاء رسالتك باستخدام قالب",
    _new: "جديد",
    _newText: "أنشئ رسالة جديدة"
  },
  directEmailMarketing: {
    _updatingCampaignStats: "جارٍ تحديث الإحصائيات",
    _refreshStats: "تحديث الإحصائيات",
    _title: "حملة البريد الإلكتروني",
    _subtitle:
      "يمكنك إنشاء رسائل بريد إلكتروني لدعوة الضيوف إلى الحدث الخاص بك ، وكذلك تلقي ملخصات لإدارة رسائل البريد الإلكتروني الخاصة بك.",
    _warnBeforeDelete: "هل تريد حقًا التراجع عن تغييراتك وحذف حملتك؟",
    _warnBeforeSend: "هل أنت متأكد أنك تريد إرسال حملتك؟",
    _deleteCampaign: "حذف الحملة",
    _recap: "ملخص رسائل البريد الإلكتروني المرسلة",
    _recapsubtitle: "تتبع رسائل البريد الإلكتروني المرسلة ، وابق على اطلاع دائم بالحالة",
    _name: "الاسم (المرسل)",
    _email: "البريد الإلكتروني (المرسل)",
    _subject: "هدف",
    _send: "إرسال الحملة",
    _save_changes: "احفظ التغييرات الخاصة بك",
    _create: "إنشاء / تحرير حملة",
    _startfromtemplate: "حدد قالب",
    _nocampaign: "لا يزال عدم الخضوع",
    _message: "رسالة",
    _contacts: "إمضاء",
    _logo: "شعار",
    _flyer: "فلاير",
    _clear: "حذف",
    _browse: "تصفح",
    _undo: "حذف",
    _join: "قم بالتأكيد عن طريق البريد الإلكتروني",
    _join_explain:
      "من خلال تحديد الحقل ، سيتمكن الضيوف من تأكيد مشاركتهم عبر البريد الإلكتروني",
    _setpartnermax: "تأكيد +1",
    _setpartnermax_explain:
      "من خلال تحديد الحقل ، يمكن للضيوف الإشارة إلى عدد معارفهم ، دائمًا ضمن الحد الأقصى الذي قمت بتعيينه. يرجى ملاحظة أنه إذا كان الضيف قد أشار بالفعل إلى عدد معارفه ، فمن المستحسن عدم تعديل البيانات المعروضة في قسم القائمة.",
    _setnote: "طلب اسم +1 و / أو الملاحظات",
    _setnote_explain:
      "من خلال تحديد الحقل ، يمكن لضيوفك إدخال أسماء معارفهم أو طلباتهم الخاصة. هناك ستجد في حقل الملاحظات في ملف Excel من التصدير الذي يمكنك القيام به في أي وقت من لوحة القيادة.",
    _imgNoticesLogo: "تحميل ملفات .jpg / .png ، بحد أقصى 1 ميجا بايت",
    _imgNoticesFlyer: "تحميل ملفات .jpg / .png ، بحد أقصى 1 ميجا بايت",
    _personalizedLabel: "يعدل أو يكيف",
   // _personalizedExplain:
     // "حدد لتخصيص الإرسال بالرسالة التالية \"عزيزي الاسم + اللقب \"",
    _createEmail: "إنشاء بريد إلكتروني",
    _modifyEmail: "تحرير البريد الإلكتروني",
    _username: "اسم االمستخدم",
    _senderName: "الاسم الاول",
    _save: "يحفظ",
    _modify: "تعديل",
    _selectOneVerifiedEmail: "حدد عنوان بريد إلكتروني تم التحقق منه",
    _selectOneVerifiedDomain: "حدد مجالا تم التحقق منه",
    _defaultEmailExplanation1: "لتغيير عنوان بريدك الإلكتروني ، يجب عليك أولاً التحقق من اسم المجال أو عنوان البريد الإلكتروني.",
    _goToProfileSettings: "انتقل إلى إعدادات الملف الشخصي",
    _defaultEmailExplanation2: "لتغيير عنوان البريد الإلكتروني",
    _editSettings: "قم بتغيير الإعدادات",
    _subjectExplanation: "قم بتحرير القالب الخاص بك لتغيير موضوع الحملة",
    _campaign_sent: "تم أخذ الطلب. نحن الآن نعالج حملتك",
    _recap_name: "الاسم الاول: ",
    _recap_email: "بريد الالكتروني: ",
    _recap_subject: "موضوع: ",
    _recap_total_recipient: "إجمالي المستفيدين (س): ",
    _warning_email: "يرجى تحديد عنوان بريد إلكتروني تم التحقق منه أولاً",
    _warning_email_2: "تحقق من عنوان البريد الإلكتروني أولاً",
    _warning_domain: "تحقق و / أو حدد المجال واسم المستخدم أولاً",
    _warning_default_email: "تعذر إرسال الحملة لأن عنوان البريد الإلكتروني غير صالح",
    _warning_recipient: "حدد مستلمًا واحدًا أو أكثر",
    _warning_template: "يرجى تحديد أو إنشاء قالب بريد إلكتروني أولاً",
    _sending_campaign: "يدخل...",
    _cancel_campaign: "يلغي",
    _audience_title: "جمهور التسويق",
    _audience_subtitle: "تصفية القائمة لحملة البريد الإلكتروني الخاص بك.",
    _emailBuilderTitle: "إنشاء بريد إلكتروني",
    _emailBuilderSubtitle: "استخدم المنشئ لإنشاء التصميم الخاص بك.",
    _additionalSettingsTitle: "إعدادات البريد الأخرى",
    _customFields_title: "الحقول المخصصة",
    _customPolicy_title: "سياسة الخصوصية",
    _customFields_explain1: "للحصول على مزيد من المعلومات من ضيوفك ، يمكنك إضافة حقول مخصصة.",
    _customFields_explain2: "لإدارة الحقول المخصصة",
    _customPolicy_explain1: "قم بتعيين سياسة الخصوصية لتلقي المعلومات من ضيوفك.",
    _customPolicy_explain2: "لإدارة سياسات الخصوصية.",
    _goToSettings: "اذهب للاعدادات",
    _confirmationEmail_title: "البريد الإلكتروني للتأكيد",
    _confirmationEmail_subtitle: "بمجرد تأكيد المضيف أنه سيحضر الحدث أو رد RSVP ، يمكنك إرسال بريد إلكتروني للتأكيد للتأكد من الاعتراف برده.",
    _noCampaigns: "لم يتم إرسال أي رسالة حتى الآن"
  },
  customFieldFilter: {
    _custom_field_filter_header: "منقي",
    _custom_field_filter_question_choose:
      "حدد أحد الحقول التي تريد استخدامها كعامل تصفية",
    _custom_field_filter_answer_choose: "اختر إحدى الإجابات (اختياري)",
    _custom_field_filter_answer_input: "اكتب الإجابة التي تبحث عنها (اختياري)",
    _custom_field_filter_not_valid: "المرشحات غير صالحة. حدد حقلاً وجوابًا"
  },
  searchEvent: {
    _eventType: "فئة",
    _eventName: "الاسم الاول",
    _search: "قرب",
    _description: "وصف",
    _address: "تبوك ",
    _sDate: "تاريخ البدء",
    _eDate: "تاريخ الانتهاء"
  },
  categoryManager: {
    _title: "تغيير فئة الحدث",
    _subtitle: "",
    _eventType: "الفئة",
    _submit: "حفظ الإعداد"
  },
  customFieldsManager: {
    _selectMax: "خيارات قابلة للتحديد كحد أقصى",
    _insertAnswers: "أدخل الردود",
    _title: "تحرير الحقول المخصصة",
    _subtitle: "إدارة أحداث الحقول المخصصة",
    _submit: "يحفظ",
    _noCustomFields: "لم يتم تعيين حقول مخصصة",
    _name: "الاسم الاول: ",
    _label: "اسم الحقل المخصص",
    _answer: "إجابه ",
    _create: "يخلق",
    _sure:
      "بالمتابعة ستفقد جميع المعلومات في هذا الحقل المخصص بشكل لا رجعة فيه. هل تريد المتابعة؟",
    _createNew: "أنشئ مجالًا مخصصًا",
    _chooseExisting: "إنشاء حقل مخصص",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج",
    _alreadyExists: "حقل مخصص موجود بالفعل",
    _checkInApp: "إظهار في تطبيق Check In",
    _RSVP: "عرض في RSVP",
    _registrationFormPage: "تظهر في استمارة التسجيل",
    _hideStaff: "إخفاء من الموظفين",
    _validateField: "التحقق الميداني",
    _mandatory: "إلزامي",
    OPEN_ANSWER: "سؤال مفتوح",
    MULTIPLE_CHOICE: "متعدد الخيارات",
    MATRICOLA: "رقم سري",
    _maxSelection: "أقصى عدد من الردود للاختيار",
    _type: "الشاب",
    _limitReached: "تم الوصول إلى الحد الأقصى للإجابة المحددة.",
    _validationWithInvalid: "قيمة غير صالحة للحقل.",
    _fieldName: "حقل",
    _questionTitle: "طلب",
    _answerTitle: "إجابه",
    _limitAction: "يرجى تغيير إجابتك أو تحديث الصفحة",
    _add_csv:
      "أدخل الإجابات هنا بتقسيمها بفاصلات (على سبيل المثال ، الإجابة 1 ، الإجابة 2 ، الإجابة 3)",
    _selectTimeSlotWarning: "حدد فترة زمنية واحدة على الأقل",
    _confirm_disabling_option:
      "لقد اختار بعض ضيوفك هذه الإجابة بالفعل. للمتابعة ، لن تكون الإجابة قابلة للتحديد ، ولكن لن تكون هناك عواقب بالنسبة للضيوف الذين اختاروا الإجابة بالفعل. هل ترغب في المتابعة؟"
  },
  teamCustomFieldsManager: {
    _selectMax: "خيارات قابلة للتحديد كحد أقصى",
    _insertAnswers: "أدخل الردود",
    _title: "إدارة البيانات الشخصية المطلوبة",
    _subtitle: "إدارة طلبات البيانات الشخصية من الضيوف",
    _submit: "يحفظ",
    _noCustomFields: "لا توجد مجموعة بيانات شخصية",
    _name: "الاسم الاول: ",
    _label: "اسم الحقل المخصص",
    _answer: "إجابه ",
    _create: "يخلق",
    _sure:
      "من خلال المتابعة ، ستفقد جميع المعلومات الموجودة في هذه البيانات الشخصية بشكل لا رجعة فيه. هل تريد المتابعة؟",
    _createNew: "قم بإنشاء حقل بيانات شخصي",
    _chooseExisting: "إنشاء حقول البيانات الشخصية",
    _updateDone: "تم التحديث",
    _error: "خطأ غير معالج",
    _alreadyExists: "البيانات الشخصية موجودة بالفعل",
    _checkInApp: "إظهار في تطبيق Check-In",
    _RSVP: "عرض في RSVP",
    _landingPage: "عرض على الهبوط",
    _hideStaff: "إخفاء من الموظفين",
    _mandatory: "إلزامي",
    OPEN_ANSWER: "افتح إجابة",
    MULTIPLE_CHOICE: "متعدد الخيارات",
    _maxSelection: "أقصى عدد من الردود للاختيار",
    _type: "الشاب",
    _limitReached: "تم الوصول إلى الحد الأقصى للإجابة المحددة.",
    _questionTitle: "طلب",
    _answerTitle: "إجابه",
    _limitAction: "يرجى تغيير إجابتك أو تحديث الصفحة",
    _add_csv:
      "أدخل الإجابات هنا بتقسيمها بفاصلات (على سبيل المثال ، الإجابة 1 ، الإجابة 2 ، الإجابة 3)",
    _selectTimeSlotWarning: "حدد فترة زمنية واحدة على الأقل",
    _confirm_disabling_option:
      "لقد اختار بعض ضيوفك هذه الإجابة بالفعل. للمتابعة ، لن تكون الإجابة قابلة للتحديد ، ولكن لن تكون هناك عواقب بالنسبة للضيوف الذين اختاروا الإجابة بالفعل. هل ترغب في المتابعة؟"
  },
  loginPage: {
    _login_title: "مرحبا!",
    _login_description: " ",
    _email_hint: "عنوان البريد الالكترونى",
    _password_hint: "كلمة المرور",
    _click_here: "انقر هنا",
    _password_recovery: "هل نسيت كلمة المرور الخاصة بك؟",
    _submit: "وصول",
    _remember_me: "تذكرنى",
    _password_mandatory: "كلمة المرور مطلوبة",
    _email_not_valid: "البريد الإلكتروني غير صالح"
  },
  registerPage: {
    _register_title: "تسجيل الدخول",
    _register_title_success: "مرحبا!",
    _register_description:
      "اشترك مجانًا لبدء تجربة Penguinpass واكتب لنا إذا كنت بحاجة إلى أي معلومات أو طلبات خاصة لحدثك. سنكون سعداء لشرح مدى ثورية نظامنا.",
    _email_hint: "عنوان البريد الالكترونى",
    _password_hint: "كلمة المرور",
    _click_here: "انقر هنا",
    _password_recovery: "هل نسيت كلمة المرور الخاصة بك؟",
    _submit: "إرسال",
    _remember_me: "تذكرنى",
    _privacy_policy_mandatory:
      "يجب عليك قبول سياسة الخصوصية الخاصة بنا وشروط وأحكام التسجيل",
    _email_not_valid: "البريد الإلكتروني غير صالح",
    _name_surname_mandatory: "الاسم واللقب مطلوبان",
    _password_mandatory: "كلمة المرور مطلوبة",
    _passwords_not_match: "كلمة السر غير متطابقة",
    _p_name: "الاسم الاول*",
    _p_surname: "اسم العائلة*",
    _p_email: "بريد الالكتروني*",
    _p_company: "اسم الشركة",
    _p_vat_number: "ضريبة القيمة المضافة",
    _p_site: "موقع إلكتروني",
    _p_city: "مدينة",
    _p_nation: "جنسية",
    _p_password: "كلمة المرور *",
    _p_password_confirm: "تأكيد كلمة المرور*",
    _p_description: "إذا كنت ترغب في ذلك ، يرجى إدخال رسالة لنا",
    _after_register_title: "قبل البدء ، نحتاج إلى التحقق من بريدك الإلكتروني.",
    _after_register_description_1:
      "سيتصل بك فريقنا لتنشيط حسابك بشكل فعال ، عادةً في غضون ساعات قليلة وتهيئته ليناسب احتياجاتك على أفضل وجه",
    _after_register_description_2: "إذا كنت ترغب في التواصل معنا ، فاكتب إلينا"
  },
  organizerSettings: {
    _pageTitle: "إعدادات",
    _tabProfile: "الملف الشخصي",
    _tabPayment: "بيانات الدفع",
    _tabPassword: "تغيير كلمة المرور",
    _tabCustomCategories: "التصنيفات",
    _tabEventSettings: "الأحداث",
    _tabDomain: "اختصاص",
    _tabUnsubscribers: "غير مشترك",
    _profileHeader: "معلومات التسجيل",
    _profileSubHeader: "ملخص من الموظفين",
    _profilePersonalSection: "معلومات شخصية",
    _profileCompanySection: "معلومات الشركة",
    _profileBackgroundSection: "خلفية",
    _placeholderName: "الاسم الاول",
    _placeholderSurname: "اسم العائلة",
    _placeholderCompanyName: "وكالة",
    _placeholderVatNumber: "ظريبه الشراء",
    _placeholderCap: "الرمز البريدي",
    _placeholderCity: "مدينة",
    _modify: "تعديل",
    _submit: "إرسال",
    _add_new_email: "التحقق من البريد الإلكتروني",
    _add_new_domain: "تحقق من المجال",
    _passwordHeader: "غير كلمة المرور الخاصة بك ",
    _domainHeader: "التحقق من الهوية",
    _select_identity_method: "حدد طريقة هويتك",
    _newDomainSection: "اسم المجال للتحقق (example.com)",
    _newEmailSection: "عنوان البريد الإلكتروني للتحقق (name@example.com)",
    _email: "بريد الالكتروني",
    _domain: "اختصاص",
    _placeholderEmail: "عنوان البريد الإلكتروني للتحقق (name@example.com)",
    _placeholderDomain: "اسم المجال للتحقق (example.com)",
    _selectIdentityType: "يختار",
    _default: "افتراضي (بدون تحقق)",
    _email_recap: "هويات البريد الإلكتروني",
    _domain_recap: "المجالات المخصصة",
    _recap: "هوية المجال",
    _recapSubtitle: "هنا يمكنك التحقق من عناوين البريد الإلكتروني المخصصة أو المجالات",
    _email_recap_subtitle:
      "هنا يمكنك التحقق من حالة عناوين بريدك الإلكتروني المستخدمة في التسويق عبر البريد الإلكتروني",
    _mail_from_desc_1:
      "يمكن أن يساعدك البريد المخصص من الإعداد في زيادة سمعة تسليم البريد الإلكتروني والعلامة التجارية. تحتاج إلى التمكين والتكوين فقط إذا سمح لك مزود DNS بتغيير قيم MX لنطاق فرعي مخصص (على سبيل المثال ، aruba.it لا يسمح لك بتغييرها)",
    _mail_from_desc_2:
      "- قيمة MX مخصصة لإرسال رسائل البريد الإلكتروني باستخدام المجال المخصص الخاص بك.",
    _mail_from_desc_3: "- قيمة TXT مخصصة لتكوين إطار عمل سياسة المرسل (SPF).",
    _domain_desc_1:
      "تستخدم Penguinpass خدمات Amazon Web Services (AWS) ، وهي واحدة من أكثر الحلول الموثوقة في السوق ، لإرسال رسائل البريد الإلكتروني. لإرسال رسائل بريد إلكتروني نيابة عن مجالك المخصص ؛",
    _domain_desc_2:
      "- قيمة TXT مخصصة لإرسال إذن لإرسال بريد إلكتروني نيابة عن مجالك.",
    _domain_desc_3: "- قيم CNAME خاصة بإعدادات DKIM لنطاقك.",
    _domain_desc_4:
      "باستخدام هذين التكوينين ، ستتمكن من إرسال رسائل البريد الإلكتروني باستخدام خادم البريد الإلكتروني Penguinpass دون فقد القدرة على إرسال رسائل البريد الإلكتروني باستخدام خادم البريد الأصلي.",
    _domain_desc_5:
      "ستظل أي ردود على رسائل البريد الإلكتروني المرسلة تعود إلى خادم البريد الإلكتروني الأصلي. لتقليل معدل البريد العشوائي ، يتم أيضًا تمييز قيم CNAME على أنها مطلوبة.",
    _domain_desc_6:
      "بعد التحقق من المجال الخاص بك ، ستتمكن من استخدام أي اسم مستخدم في المجال الخاص بك كمرسل البريد الإلكتروني مثل 'any_username @ yourdomain.com'.",
    _domain_desc_7:
      "ستنتهي صلاحية طلب التحقق الخاص بك بعد 48 ساعة. في مثل هذه الحالات ، يجب تقديم طلب تحقق جديد.",
    _read_more: "اقرأ أكثر",
    _read_less: "تظهر أقل",
    _step: "خطوة",
    _step_1: "إنشاء المجال",
    _step_2: "قم بتغيير تكوين DNS",
    _step_3: "وقت الانتظار يصل إلى 48 ساعة بعد تغيير تكوين DNS",
    _refreshIdentities: "انقر للتحديث",
    _updatingIdentities: "جار التحميل",
    _no_data: "لا توجد بيانات حتى الآن",
    _settingsUpdated: "تم تحديث الإعدادات!",
    _profileUpdated: "تم تحديث معلومات الملف الشخصي!",
    _password_empty: "كلمة المرور فارغة!",
    _newPassword_warning_message: "كلمة السر غير متطابقة!",
    _password_updated: "تم تحديث كلمة السر!",
    _same_password: "حدد كلمة مرور مختلفة من السابقة",
    _email_exists: "البريد الإلكتروني موجود بالفعل!",
    _unsubscribersHeader: "غير مشترك",
    _recap_unsubscribers: "غير مشترك",
    _recapSubtitle_unsubscribers:
      "هنا يمكنك التحقق من عناوين البريد الإلكتروني غير المشتركين في فريقك",
    _unsubscriber_number: "Nعدد غير المشتركين: ",
    _download_unsubscribers: "قم بتنزيل القائمة بأكملها",
    _remove_existing: "قم بإزالة هوياتك الحالية أولاً",
    _download_record_set: "تم تعيين سجل التنزيل كـ XLSX",
    _deleteDomain: "احذف المجال",
    _warn_before_delete_domain: "هل تريد حقًا حذف المجال الخاص بك؟",
    _domain_exists:
      "تم التحقق من هذا المجال بالفعل من قبل مستخدم آخر. اتصل بنا إذا كنت مالك هذا المجال",
    _email_verification_exists:
      "تم التحقق من هذا البريد الإلكتروني بالفعل من قبل مستخدم آخر. يرجى الاتصال بنا إذا كنت تملك هذا البريد الإلكتروني",
    _email_syntax_error: "البريد الإلكتروني غير صالح",
    _domain_syntax_error: "المجال غير صالح",
    _email_domain_verification_exists:
      "تم التحقق من مجال هذا البريد الإلكتروني بالفعل من قبل مستخدم آخر. اتصل بنا إذا كنت مالك هذا المجال.",
    _eventSettingsHeader: "إدارة إعدادات الحدث الخاص بك",
    _eventSettingsSubtitle: "هنا يمكنك إدارة المعلومات الشخصية لسؤال ضيوفك عند التسجيل في الأحداث الخاصة بك.",
    _customCategoriesHeader: "إدارة الفئات المخصصة الخاصة بك",
    _customCategoriesSubtitle: "هنا يمكنك إدارة الفئات المخصصة الخاصة بك لتجميع الأحداث الخاصة بك كما تريد.",
    _eventGroupTitle: "إدارة مجموعات الحدث الخاص بك",
    _eventGroupSubtitle: "هنا يمكنك إدارة مجموعات الأحداث الخاصة بك للتحكم في إدارة الوصول لحدث واحد أو أكثر."
  },
  recoveryPassword: {
    _forgot_password_heading_text: "استرجع كلمة المرور",
    _forgot_password_input_text: "أدخل عنوان البريد الإلكتروني المخصص للطوارئ",
    _forgot_password_reset_text:
      "أدخل بريدك الإلكتروني أدناه ، وسنرسل لك رابطًا لاستعادته ، شكرًا لك.",
    _forgot_password_button_text: "إعادة تعيين",
    _successRecovery_title: "بدء التعافي",
    _successecovery_message:
      "لقد أرسلنا لك رابطًا لاستعادة كلمة المرور الخاصة بك. إذا كنت لا تزال تواجه مشاكل في الكتابة إلى ",
    _newPassword_title: "اعد ضبط كلمه السر",
    _newPassword_title_update: "قم بتحديث كلمة المرور الخاصة بك",
    _newPassword_description: "أدخل كلمة المرور الجديدة ، ثم كررها أدناه للتأكيد.",
    _newPassword_description_update:
      "انتهت صلاحية كلمة المرور الخاصة بك أدخل كلمة المرور الجديدة ، ثم كررها أدناه للتأكيد.",
    _newPassword_summitButton: "اعد ضبط كلمه السر",
    _newPassword_summitButton_update: "قم بتحديث كلمة المرور الخاصة بك",
    _newPassword_warning_message: "كلمة المرور غير مطابقة"
  },
  verifyAccount: {
    _send_link: "إرسال",
    _email_label: "أدخل بريدك الإلكتروني",
    _resend_success_title: "نجاح!",
    _resend_success_subtitle: "تحقق من بريدك الوارد. نأمل أن تكون هذه هي المرة الأخيرة!",
    _verification_success_title: "نجاح!",
    _verification_success_guest: "اكتمل التحقق! يمكنك العودة إلى سؤالك وتسجيل الدخول",
    _verification_success_organizer: "اكتمل التحقق! يمكنك الذهاب إلى صفحة تسجيل الدخول واستخدام لوحة القيادة",
    _verification_failed_title: "فشل!",
    _verification_failed: "فشل التحقق",
    _verification_expired: "انتهت صلاحية رابط التحقق. إذا كنت ترغب في التحقق مرة أخرى ، يرجى إدخال البريد الإلكتروني الخاص بك",
    _joinOk: "تم التسجيل للحدث بنجاح!",
    _joinErr: "عذرًا ، لم يكن التسجيل للحدث ناجحًا ، قم بتسجيل الدخول وحاول مرة أخرى."
  },
  termsAndConditions: {
    _terms_accept: "وأنا أقبل",
    _privacy_accept: "أنا أقرا",
    _privacy_title: "سياسة الخصوصية",
    _terms_title: "شروط وأحكام الخدمة",
    _mandatory: "(إلزامي)",
    _linkTerms: "https://www.penguinpass.it/terms-and-conditions-it.html",
    _privacypolicyURL: "https://www.penguinpass.it/privacy-policy-it.html",
    _updates:
      "أفوض بمعالجة بياناتي الشخصية للبقاء على اطلاع بتحديثات المنتج",
    _marketing:
      "أفوض بمعالجة بياناتي الشخصية لأغراض تسويقية ، بما في ذلك تلك الخاصة بأطراف ثالثة"
  },
  eventCategories: {
    _contentEvent: "CONTENT EVENT",
    _pressDay: "PRESS DAY",
    _fashionShow: "FASHION SHOW",
    _corporateEvent: "CORPORATE EVENT",
    _fashionEvent: "FASHION EVENT",
    _onInvitation: "ON INVITATION",
    _cocktailParty: "COCKTAIL PARTY"
  },
  eventCategoriesAsOnDB: {
    _contentEvent: "CONTENT_EVENT",
    _pressDay: "PRESS_DAY",
    _fashionShow: "FASHION_SHOW",
    _corporateEvent: "CORPORATE_EVENT",
    _fashionEvent: "FASHION_EVENT",
    _onInvitation: "ON_INVITATION",
    _cocktailParty: "COCKTAIL_PARTY"
  },
  verificationResult: {
    _success_title: "نجاح!",
    _success_message:
      "شكرًا للتحقق. يمكنك الآن استخدام أسلوب التسويق عبر البريد الإلكتروني باستخدام عنوان بريدك الإلكتروني!",
    _failure_title: "آسف!",
    _failure_message: "هناك خطأ ما. يرجى المحاولة مرة أخرى أو لا تتردد في الاتصال بنا"
  },
  joinPage: {
    _join_title_accepted: "نشكرك على تأكيدك أن هذه الدعوة محجوزة لـ:",
    _join_title_declined: "لقد أكدت أنك لن تحضر الحدث",
    _join_title_already_accept: "لقد أكدت بالفعل أنك ستحضر الحدث.",
    _join_title_already_decline: "لقد أكدت بالفعل أنك لن تحضر الحدث.",
    _join_reservedfor: "هذه الدعوة محجوزة لـ:",
    _join_description:
      "اقرأ سياسات الخصوصية والشروط والأحكام وأكدها لإكمال التأكيد.",
    _join_confirm: "تأكيد",
    _join_confirm_decline: "رفض",
    _join_alert_policy: "السياسة الإلزامية غير مقبولة.",
    _join_alert_fields: "الحقول المطلوبة غير مدرجة.",
    _privacy_policy: "سياسة الخصوصية",
    _custom_fields: "معلومة اضافية",
    _mandatory: "إلزامي",
    _join_error_invalid_invitation: "الدعوة غير موجودة أو غير صالحة",
    _join_error_event_not_found: "الحدث غير موجود",
    _join_error_event_full:
      "نأسف لإبلاغك بإغلاق قوائم هذا الحدث.",
    _join_error_guest_not_found: "الدعوة غير موجودة أو غير صالحة",
    _join_error_policies_not_accepted:
      "يرجى قراءة سياسة الخصوصية والشروط والأحكام الخاصة بنا وتأكيدها من أجل إكمال العملية.",
    _join_error_unhandled: "الدعوة غير موجودة أو غير صالحة",
    _number_of_guests: "حدد عدد رفاقك",
    _info_of_guests: "الرجاء إدخال أسماء ضيوفك",
    _guest: "زائر",
    _guest_name: "الاسم الاول",
    _guest_surname: "اسم العائلة"
  },
  registrationPage: {
    _eventFull: "عفوًا ، تم حجز الحدث بالكامل الآن. تحقق لاحقًا إذا كان هناك أي أماكن متاحة مرة أخرى.",
    _eventFull2: "عفوًا ، تم حجز الحدث بالكامل الآن. تحقق لاحقًا إذا كانت المقاعد متاحة مرة أخرى ، أو قلل عدد الضيوف الذين ستحضرهم معك إذا تمت إضافتهم.",
    _title: "سجل لحضور الحدث",
    _loggedInAs: "أنت مسجل دخولك باسم",
    _logout: "أخرج",
    _alreadyAccount: "هل لديك حساب بالفعل؟",
    _login: "تسجيل الدخول",
    _signup: "تسجيل الدخول",
    _prefillData: "لملء بياناتك مسبقًا",
    _mandatory: "(إلزامي)",
    _firstName: "الاسم الاول",
    _lastName: "اسم العائلة",
    _password: "كلمة المرور",
    _retypePassword: "أعد إدخال كلمة السر",
    _alreadyJoined: "لقد قمت بالتسجيل بالفعل لهذا الحدث.",
    _takeSomeone: "هل تريد اصطحاب شخص ما معك؟",
    _personsWithYou: "الناس ليأخذوا معك",
    _personsUpTo: "يمكنك أن تأخذ ما يصل إلى",
    _persons: "اشخاص.",
    _person: "شخص",
    _register: "تسجيل الدخول",
    _thankYouWaiting: "شكرًا لك على التسجيل في هذا الحدث ، لقد أرسلنا لك بريدًا إلكترونيًا لتأكيد حسابك وإكمال تسجيلك في الحدث.",
    _thankYouWaiting2: "شكرًا لك على التسجيل ، لقد أرسلنا لك بريدًا إلكترونيًا لتأكيد حسابك.",
    _emailInUse: "البريد الإلكتروني مسجل بالفعل ، قم بتسجيل الدخول للمشاركة في الحدث.",
    _emailInUse2: "البريد الإلكتروني مسجل مسبقا.",
    _thankYou: "شكرا لك على التسجيل في هذا الحدث.",
    _basicInformation: "معلومات اساسية",
    _setPassword: "ضبط كلمة السر",
    _moreInformation: "معلومات اكثر",
    _bookTimeSlots: "حدد الفترة الزمنية"
  },
  unsubscribePage: {
    _title: "القائمة البريدية",
    _action_confirm: "انقر أدناه إذا كنت تريد إيقاف تلقي رسائل البريد الإلكتروني من هذا المنظم. يمكنك إعادة التسجيل في أي وقت من هذه الصفحة.",
    _unsubscribe_confirm: "انقر لإلغاء الاشتراك",
    _thank_you_for_unsubscription: "تمت إزالتك من القائمة البريدية للمنظم ، إذا كنت ترغب في الاشتراك مرة أخرى ، فانقر فوق الزر أدناه. للمزيد من المعلومات الرجاء الاتصال",
    _resubscribe_reverse: "انقر للاشتراك مرة أخرى",
    _thank_you_for_resubscribe: "شكرا لك على التسجيل!",
    _unsubscribe_reverse: "إلغاء التغييرات",
    _resubscribe_confirm: "انقر للاشتراك"
  },
  ElementTable: {
    name: "الاسم الاول",
    surname: "اسم العائلة",
    email: "بريد الالكتروني"
  },
  teamPage: {
    _new_member: "إضافة بريد إلكتروني جديد للعضو",
    _role: "دور",
    _invite: "أرسل الدعوة",
    _invite_title: "إضافة عضو",
    _invite_subtitle:
      "هنا يمكنك إضافة أعضاء جدد إلى فريقك. سوف يتلقون دعوة عبر البريد الإلكتروني",
    _team_table_title: "أعضاء الفريق",
    _team_table_subtitle: "هنا يمكنك فحص / تعديل أعضاء فريقك",
    _page_title: "فريقي",
    _invitation_sent: "دعوة التي وجهت!",
    _error_email_invalid: "مطلوب بريد إلكتروني صحيح",
    _error_role_invalid: "اختر دورًا",
    _updated: "محدث",
    _warning_remove: "هل تريد حقًا الإزالة",
    _post_remove: "سيتم حذف المستخدم قريبا"
  },
  modalBulkAction: {
    _user_selected: "مستخدم (مستخدمون) محددون",
    _cancel: "يلغي",
    _confirm: "تأكيد",
    _search_event: "قرب",
    _users_added: "تمت إضافة المستخدمين بنجاح",
    _users_removed: "تمت إزالة المستخدمين بنجاح",
    _failed: "فشلت العملية",
    addToEvent: "تعيين لحدث",
    removeFromEvent: "إزالة من الحدث",
    clearSelection: "تحديد واضح",
    _bulk_action: "العمل الجماعي",
    _options: "خيارات",
    _warning_select_users: "حدد بعض المستخدمين أولاً",
    _warning_select_event: "الرجاء تحديد حدث أولا"
  },
  errorsNotFound: {
    _page_not_found: "الصفحة غير موجودة",
    _page_not_found_desc:
      "قد تكون هذه الصفحة خاصة أو ربما لم تطلب الإذن لمشاهدتها.",
    _go_to_home: "اذهب إلى الصفحة الرئيسية"
  },
  badgePrinting: {
    _loadingMessage: "أنتظر لأجل",
    _title: "شارات",
    _innertitle: "تفعيل طباعة الشارات",
    _save: "يحفظ",
    _saved: "تم حفظ الشارة",
    _reset: "إعادة ضبط",
    _formTitle: "عنوان",
    _formDescription: "وصف",
    _formPageW: "عرض الصفحة",
    _formPageH: "ارتفاع الصفحة",
    _formFileUpload: "انقر للتحميل",
    _formFileUploadTips: "jpg / png بحجم أقل من 500 كيلو بايت",
    _formTextColor: "لون الخط",
    _formBorderColor: "لون الحدود",
    _formField1: "حقل 1",
    _formField2: "حقل 2",
    _formCustomLayout: "كود التخطيط"
  },
  recordings: {
    _page_title: "التسجيلات",
    _subtitle: "في هذا القسم يمكنك تنزيل جميع التسجيلات.",
    _warning_remove: "هل تريد حقًا الإزالة",
    _post_remove: "تم حذف التسجيل"
  },
  customCategories: {
    _label: "اسم التصنيف",
    _submit: "يخلق",
    _created: "خلقت",
    _failed: "فشل",
    _warnBeforeDelete: "بعض الأحداث الخاصة بك تستخدم هذه الفئة. لذلك ، سيؤدي هذا إلى تحديد الفئة على أنها محذوفة وتعطيل الاستخدام الإضافي لمواصلة دعمها. هل تريد الاستمرار؟",
  },
  eventGroup: {
    _newGroup: "الاسم الفريد للمجموعة",
    _groupName: "أسم المجموعة",
    _removeFromGroup: "إزالة",
    _markAsController: "وضع علامة على أنها وحدة تحكم",
    _markedAsController: "تم وضع علامة تحكم",
    _submit: "يخلق",
    _created: "خلقت",
    _save: "يحفظ",
    _editGroup: "تحرير مجموعة الحدث",
    _cancel: "يلغي",
    _fallito: "فشل",
    _addEvent: "أضف حدثًا",
    _searchEvents: "ابحث عن الأحداث",
    _eventGroupExist: "اسم مجموعة الأحداث هذه موجود بالفعل ، يرجى تجربة شيء جديد",
    _selectController: "حدد حدث تحكم واحد على الأقل",
    _selectNonController: "حدد حدثًا واحدًا على الأقل غير متعلق بوحدة التحكم",
    _eventListEmpty: "أضف الحدث أولاً",
    _groupNameMissing: "اسم المجموعة مفقود",
    _warnBeforeDelete: "هل أنت متأكد أنك تريد حذف مجموعة الأحداث هذه؟ لن يؤثر حذف مجموعة الحدث هذه على الأحداث الفردية في هذه المجموعة.",
    _warnBeforeDeletingLastEvent: "لا يمكنك إزالة جميع الأحداث داخل المجموعة. ضع في اعتبارك حذف المجموعة بدلاً من ذلك.",
  },
  notify: {
    _page_title: "دفع الإخطارات",
    _subtitle: "إنشاء إخطارات لأحداث معينة أو لإبلاغ التحديثات للضيوف",
    _no_notifications: "لم يتم إرسال أي إخطار",
    _create: "خلق جديد إبداع جديد",
    _create_section_title: "إنشاء إشعار دفع جديد",
    _title: "عنوان",
    _title_placeholder: "أدخل العنوان",
    _body: "المحتوى",
    _body_placeholder: "أدخل المحتوى",
    _recap_section_title: "تقرير الإخطار",
    _user_group_section_title: "اختر المستخدمين",
    _title_recommendation: "يوصى باستخدام 10 كلمات كحد أقصى",
    _submit: "إرسال",
    _all_users: "جميع المدعوين من جميع المناسبات ",
    _subtitle_user_group: "الإخطارات محجوزة للمستخدمين الذين قاموا بتنزيل تطبيق الضيف وسيشاركون في الحدث",
    _warnBeforeSend: "هل أنت متأكد أنك تريد إرسال الإخطار؟",
    _sending: "جاري الإرسال",
  },
  showOnApp: {
    _title: "إظهار في التطبيق",
    _description: "إظهار هذا الحدث في تطبيق الضيف"
  },
  publicPages: {
    _book_test: "احجز موعدًا للاختبار",
    _download_ticket: "قم بتنزيل التذكرة",
    _certify_test: "التصديق على نتيجة الاختبار",
    _join: "شارك",
    _reset_password: "إعادة تعيين كلمة المرور",
    _reset_message: "ستتلقى بريدًا إلكترونيًا من mailing@penguinpass.it لتعيين كلمة مرور جديدة.",
    _password: "كلمة المرور",
    _forgot_password: "هل نسيت كلمة السر؟",
    _back_login: "العودة إلى تسجيل الدخول",
    _reset: "إعادة ضبط",
    _register: "تسجيل الدخول",
    _name: "الاسم الاول",
    _surname: "اسم العائلة",
    _email: "بريد الالكتروني",
    _test_not_verified: "لم يتم التحقق من الاختبار.",
    _test_not_verified_sub: "يجب عليك إجراء الاختبار لعرض التذكرة ، إذا كنت قد أجريت الاختبار ، فتأكد من أنك في الإطار الزمني المطلوب قبل الحدث."
  },
  c19compliance: {
    _title: "لائحة COVID-19",
    _description: "تمكين التحكم",
    timePeriod: "حدد الفترة الزمنية",
    subTimePeriod: "عيّن الفاصل الزمني قبل الحدث الذي يعتبر الاختبار خلاله صالحًا"
  },
  oauth: {
    _redirecting_you: "انتظر ، نحن نعيد توجيه طلبك"
  }
};
