<template>
  <div class="container-fluid" :class="{ 'public-profile-landing-tracking': trackingId }">
    <div v-if="!pageLoaded" id="preloader">
      <div class="pulse"></div>
    </div>
    <div class="topnav" :class="{ 'bb-shadow': singleEventData }">
      <div class="topnav__home">
        <router-link
          class="back-to-list"
          :to="{ name: 'OrganizerPublicProfile', params: { code: code } }"
        >
          HOME
        </router-link>
      </div>
      <div class="topnav__buttons">
        <span class="topnav__username" v-if="selectedForm === 'logout'">
          <!-- <router-link
            class="back-to-list"
            :to="{ name: 'OrganizerPublicLayout', params: { code: code } }"
          > -->
            <!-- <i class="fa fa-user-circle fa-2x profile__icon"></i>  -->
            {{ loggedInUser.name }}
            {{ loggedInUser.surname }}
          <!-- </router-link> -->
        </span>
        <button
          v-if="selectedForm === 'logout'"
          class="btn btn-secondary logout-btn"
          @click="logoutDo()"
        >
          {{ string.registrationPage._logout }}
        </button>
        <div v-if="selectedForm === null">
          <div class="btn btn-secondary" @click="loginDo()">
            {{ string.registrationPage._login }}
          </div>
          <div class="btn btn-secondary" @click="signUpDo()">
            {{ string.registrationPage._signup }}
          </div>
        </div>
      </div>
    </div>
    <div class="public-profile-body">
      <div v-if="!trackingId" class="heroImage"
        :style="{
          'background-image': `url('${profileData.bannerUrl}')`,
        }"
        :class="{ singleEvent: singleEventData }"  
      >
        <h1>{{ profileData.displayName }}</h1>
      </div>
      <!-- List of event -->
      <div v-if="!singleEventData" class="container">
        <div v-if="loggedInUser" class="filters">
          <a
            href=""
            :class="{ active: eventType === 'ALL' }"
            @click.prevent.stop="changeEventType('ALL')"
            >{{ string.events._all_events }}</a
          >
          <span class="separator">&centerdot;</span>
          <a
            href=""
            :class="{ active: eventType === 'MY_EVENTS' }"
            @click.prevent.stop="changeEventType('MY_EVENTS')"
            >{{ string.events._my_events }}</a
          >
          <span class="separator">&centerdot;</span>
          <a
            href=""
            :class="{ active: eventType === 'FAVOURITES' }"
            @click.prevent.stop="changeEventType('FAVOURITES')"
            >{{ string.events._favourites }}</a
          >
        </div>
        <div v-if="publicEvents.data.length === 0" class="no_events">
          {{ string.events._noEvents }}
        </div>
        <el-row v-for="event in publicEvents.data" :key="event._id" :gutter="20" class="card-container">
          <el-col :span="24">
            <el-card :body-style="{ padding: '0px', display: 'flex' }" shadow="hover">
              <img v-if="event.image" :src="event.image" class="card-image" />
              <img
                v-else
                src="https://cdn.pixabay.com/photo/2021/08/02/20/35/architecture-6517841_1280.jpg"
                class="card-image"
              />
              <i
                v-if="loggedInUser"
                class="image-favourite fa"
                :class="[isFavourite(event._id) ? 'fa-star' : 'fa-star-o']"
                @click="eventLike(event, isFavourite(event._id) ? 'unline' : 'like')"
              ></i>
              <div class="card-content">
                <div class="event-description">
                  <span class="title">{{ event.name }}</span>
                  <span class="datetime">{{ eventWhenWhere(event) }}</span>
                  <span class="description">{{
                    lang === "it" ? event.descriptionIt : event.descriptionEn
                  }}</span>
                </div>
                <div v-if="loggedInUser || event.noPassword" class="event-actions">
                  <a v-if="event.c19c && getTicketQRCode(event._id) === '0'" href=""
                    >{{ string.publicPages._book_test }} <i class="fa fa-external-link"></i
                  ></a>
                  <div v-if="getTicket(event._id)" class="event-actions-row">
                    <el-button
                      :disabled="getTicketQRCode(event._id) === '0'"
                      type="text"
                      class="button"
                      :class="{ disabled: getTicketQRCode(event._id) === '0' }"
                      @click="downloadTicket(event, false)"
                      >{{ string.publicPages._download_ticket }}</el-button
                    >
                    <el-button
                      v-if="event.c19c && getTicketQRCode(event._id) === '0'"
                      type="text"
                      class="button"
                      @click="checkC19Test(event)"
                      >{{ string.publicPages._certify_test }}</el-button
                    >
                  </div>
                  <div v-else class="event-actions-row">
                    <router-link
                      :to="{
                        name: 'OrganizerPublicProfileSingleEvent',
                        params: { code: code, eventId: event._id },
                      }"
                      custom
                    >
                      <el-button type="text" class="button">{{
                        string.publicPages._join
                      }}</el-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-pagination
          :page-size="publicEvents.pageSize"
          layout="prev, pager, next"
          :total="publicEvents.total"
          @current-change="goToPage"
        >
        </el-pagination>
      </div>
      <!-- Single event -->
      <div v-else-if="trackingId && singleEventData" class="container single-event tracking">
        <!-- <router-link
          class="back-to-list"
          :to="{ name: 'OrganizerPublicProfile', params: { code: code }}"
        >
          Back to list of events
        </router-link> -->

            <img class="cover-image" :src="singleEventData.image" />
            <h1>{{ singleEventData.name }}</h1>
            <p>{{ eventWhenWhere(singleEventData) }}</p>
            <p>
              {{ lang === "it" ? singleEventData.descriptionIt : singleEventData.descriptionEn }}
            </p>
            <template v-if="qrCode">
              <vue-qrcode :value="qrCode" />
              <div class="event-actions-row">
                <el-button
                  type="text"
                  class="button"
                  @click="downloadTicket(singleEventData, true)"
                  >{{ string.publicPages._download_ticket }}</el-button
                >
              </div>
            </template>
            <template v-else>
              <h2>{{ errorMessage }}</h2>
              <p>{{ subErrorMessage }}</p>
            </template>
        </el-row>
      </div>
      <!-- Single event -->
      <div v-else class="container single-event">
        <router-link
          class="back-to-list"
          :to="{ name: 'OrganizerPublicProfile', params: { code: code } }"
        >
          Back to list of events
        </router-link>
        <el-row v-if="singleEventData && eventId" :gutter="20">
            <h1>{{ singleEventData.name }}</h1>
            <p>{{ eventWhenWhere(singleEventData) }}</p>
            <p>
              {{ lang === "it" ? singleEventData.descriptionIt : singleEventData.descriptionEn }}
            </p>
            <RegistrationForm
              :team-id="teamId"
              :event-id="eventId"
              :data="singleEventData"
              :string="string"
              :store="$store"
              :root="$root"
              :logged-in-user="loggedInUser"
              :hide-login-bar="true"
              :no-password="singleEventData.noPassword"
            ></RegistrationForm>
        </el-row>
      </div>
    </div>
    <div
      v-if="selectedForm === 'login' || selectedForm === 'reset'"
      id="cover-background"
      class="coverBackground"
    ></div>
    <div
      v-if="selectedForm === 'login' || selectedForm === 'reset'"
      id="login-modal"
      @click.self="closeLoginForm()"
    >
      <div class="login-form">
        <form class="form-signin" @submit.prevent="loginResetDo()">
          <h2 v-if="selectedForm === 'login'" class="form-signin-heading">
            {{ string.registrationPage._login }}
          </h2>
          <h2 v-if="selectedForm === 'reset'" class="form-signin-heading">
            {{ string.publicPages._reset_password }}
          </h2>
          <label for="inputEmail" class="sr-only">{{ string.registrationPage._email }}</label>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="Email address"
            required="true"
            autofocus="true"
            v-model="loginForm.email"
          />
          <p style="margin-top: 10px; color: #ffffff" v-if="selectedForm === 'reset'">
            {{ string.publicPages._reset_message }}
          </p>
          <label v-if="selectedForm === 'login'" for="inputPassword" class="sr-only">{{
            string.publicPages._password
          }}</label>
          <input
            v-if="selectedForm === 'login'"
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required="true"
            v-model="loginForm.password"
          />
          <span v-if="loginForm.errors" style="color: red">{{ loginForm.errors }}</span>
          <span v-if="loginForm.info" style="color: green">{{ loginForm.info }}</span>
          <p
            v-if="selectedForm === 'login'"
            style="text-align: right; margin: 10px 0px; cursor: pointer; color: #ffffff"
            @click.self="formSwitch('reset')"
          >
            {{ string.publicPages._forgot_password }}
          </p>
          <p
            v-if="selectedForm === 'reset'"
            style="text-align: right; margin: 10px 0px; cursor: pointer; color: #ffffff"
            @click.self="formSwitch('login')"
          >
            {{ string.publicPages._back_login }}
          </p>
          <button
            v-if="selectedForm === 'login'"
            class="btn btn-lg btn-primary btn-block"
            type="submit"
          >
            {{ string.registrationPage._login }}
          </button>
          <button
            v-if="selectedForm === 'reset'"
            class="btn btn-lg btn-primary btn-block"
            type="submit"
          >
            {{ string.publicPages._reset }}
          </button>
        </form>
      </div>
    </div>
    <div v-if="selectedForm === 'signup'" id="signup-modal" @click.self="closeRegisterForm()">
      <div class="signup-form">
        <form v-if="thankYouMessage === ''" class="form-signin">
          <h2 class="form-signin-heading">{{ string.registrationPage._register }}</h2>
          <label for="inputName" class="sr-only">{{ string.registrationPage._name }}</label>
          <input
            type="text"
            id="inputName"
            class="form-control"
            placeholder="Name"
            required=""
            autofocus=""
            v-model="signupForm.name"
          />
          <label for="inputSurname" class="sr-only">{{ string.registrationPage._surname }}</label>
          <input
            type="text"
            id="inputSurname"
            class="form-control"
            placeholder="Surname"
            required=""
            autofocus=""
            v-model="signupForm.surname"
          />
          <label for="inputEmail" class="sr-only">{{ string.registrationPage._email }}</label>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="Email address"
            required=""
            autofocus=""
            v-model="signupForm.email"
          />
          <label for="inputPassword" class="sr-only">{{ string.registrationPage._password }}</label>
          <input
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required=""
            v-model="signupForm.password"
          />
          <div class="error-message" v-html="joinMessage"></div>
          <small>{{ string.commonActions.passwordPolicy }}</small>
          <button class="btn btn-lg btn-primary btn-block" type="button" @click="registerDo()">
            {{ string.registrationPage._register }}
          </button>
        </form>
        <div v-else class="thankyou">
          <i class="icon fa fa-check-circle fa-3x" style="font-weight: 900" aria-hidden="true"></i>
          <h2>{{ thankYouMessage }}</h2>
        </div>
      </div>
    </div>
    <footer style="margin-top: 5px; margin-bottom: 0px; background-color: transparent">
      <p class="footer-text text-center" style="padding: 15px">
        Powered by Penguinpass. The smartest event management system for your events. |
        <a href="https://www.penguinpass.it" target="_blank" title="Penguinpass">
          www.penguinpass.it
        </a>
      </p>
    </footer>
  </div>
</template>

<script>
import "html5shiv/dist/html5shiv.min";
import "bootstrap/dist/js/bootstrap.min";
import "wowjs/dist/wow.min";
import "owl.carousel/dist/owl.carousel.min";
import "magnific-popup/dist/jquery.magnific-popup.min";
import "isotope-layout/dist/isotope.pkgd.min";
import "imagesloaded/imagesloaded.pkgd.min";
import "smoothscroll-for-websites/SmoothScroll";
import "jquery.easing/jquery.easing.min";
import "../assets/js/v2/theme";
import RegistrationForm from "@/components/pages/RegistrationForm";
import moment from "moment";
import VueQrcode from "vue-qrcode";
import { isLocalhost } from "@/utils";

export default {
  components: {
    VueQrcode,
    RegistrationForm,
  },
  props: {
    trackingId: String,
    code: String,
    eventId: String,
  },
  data() {
    return {
      pageLoaded: false,
      teamId: "",
      selectedForm: null,
      loggedInUser: null,
      profileData: {
        displayName: "",
      },
      loginForm: {
        errors: null,
        info: null,
        email: "",
        password: "",
      },
      signupForm: {
        name: "",
        surname: "",
        country: "",
        telephone: "",
        email: "",
        password: "",
      },
      publicEvents: {
        currentPage: 1,
        data: [],
        pageSize: 10,
        total: 0,
      },
      singleEventData: null,
      eventType: "ALL",
      currentPage: 1,
      joinMessage: "",
      thankYouMessage: "",
      errorMessage: "",
      subErrorMessage: "",
      qrCode: "",
    };
  },
  computed: {},
  metaInfo: {
    title: "Penguinpass",
    bodyAttrs: {
      class: "organizerPublicPage",
    },
  },
  watch: {
    eventId: function (val) {
      if (val) {
        this.loadSingleEvent();
      } else {
        this.singleEventData = null;
        if (this.getCookie("guest-token-" + this.teamId)) {
          this.getGuestProfile();
        } else {
          this.loadEvents();
        }
      }
    },
    trackingId: function (val) {
      if (val) {
        if (this.teamId) {
          this.loadTicketData();
        }
      } else {
        this.singleEventData = null;
      }
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener(
      "setLanguage",
      () => {
        context.string = context.$root.strings;
      },
      this
    );
    const language = window.navigator.language;
    if (language === "it-IT") {
      this.$root.strings = this.$root.messages_it;
      this.lang = "it";
      this.$root.currentLanguage = "it";
    } else if (language === "en-GB" || language === "en-US" || language === "en") {
      this.$root.strings = context.$root.messages_en;
      this.lang = "en";
      this.$root.currentLanguage = "en";
    } else if (language === "fr-FR" || language === "fr") {
      this.$root.strings = context.$root.messages_fr;
      this.lang = "fr";
      this.$root.currentLanguage = "fr";
    } else {
      this.$root.strings = context.$root.messages_en;
      this.lang = "en";
      this.$root.currentLanguage = "en";
    }
    this.$root.bus.emit("setLanguage");
  },
  mounted() {
    if (this.code) {
      this.loadOrganizerPublicProfileData();
    }
  },
  methods: {
    eventLike(event, action) {
      this.$store
        .dispatch("eventLike", {
          action,
          teamId: this.teamId,
          token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
          id: event._id,
        })
        .then((result) => {
          if (result.status === "SUCCESS" && result) {
            this.getGuestProfile();
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    downloadTicket(event, useTracking) {
      this.$store
        .dispatch("getTicket", {
          trackingId: useTracking ? this.trackingId : null,
          teamId: this.teamId,
          token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
          id: event._id,
        })
        .then((result) => {
          if (result.status === "SUCCESS" && result.pdf) {
            const arr = new Uint8Array(result.pdf.data);
            const blob = new Blob([arr], { type: "application/pdf" });
            const href = window.URL.createObjectURL(blob, { type: "application/pdf" });
            const theLink = document.createElement("a");
            theLink.href = href;
            theLink.download = event.name + "ticket.pdf";
            document.body.appendChild(theLink);
            theLink.click();
            document.body.removeChild(theLink);
          }
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            this.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    checkC19Test(event) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("checkC19Test", {
          teamId: this.teamId,
          token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
          id: event._id,
        })
        .then(async (result) => {
          if (result.status === "SUCCESS") {
            if (result.message === "SUCCESS") {
              context.getGuestProfile();
            } else if (result.message === "TEST_NOT_VERIFIED") {
              context.$notify({
                title: "Oops !",
                message: context.string.publicPages._test_not_verified,
                position: "bottom-right",
                type: "error",
                duration: 30000,
              });
            }
          }
          context.pageLoaded = true;
        })
        .catch((error) => {
          console.log("error", error);
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    getTicket(eventId) {
      if (this.loggedInUser) {
        return this.loggedInUser.myEventAccesses.filter((item) => {
          return item.eventId === eventId;
        }).length;
      }
      return null;
    },
    getTicketQRCode(eventId) {
      if (this.loggedInUser) {
        const ticket = this.loggedInUser.myEventAccesses.filter((item) => {
          return item.eventId === eventId;
        });
        return ticket && ticket.length && ticket[0].qrCode;
      }
      return null;
    },
    isFavourite(eventId) {
      if (this.loggedInUser) {
        return this.loggedInUser.favouriteEvents.filter((item) => {
          return item === eventId;
        }).length;
      }
      return null;
    },
    goToPage(page) {
      this.currentPage = page;
      this.loadEvents();
    },
    eventWhenWhere(event) {
      let eventAddress = '';
      const eventParsed = JSON.parse(event.address);
      if (event.venue){
        eventAddress = event.venue;
      } else {
        eventAddress = eventParsed.address;
      }
      return `${moment.utc(event.startDate).format("DD/MM/YYYY HH:mm")} - ${moment.utc(event.endDate).format("DD/MM/YYYY HH:mm")} ${eventParsed.city}, ${
        eventAddress
      }`;
    },
    loadOrganizerPublicProfileData: function (code) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("teamPublicProfile", { id: this.code })
        .then(async (result) => {
          if (result.status === "SUCCESS") {
            context.profileData = result.data;
            context.teamId = result.data.id;

            if (result.data && !result.data.publicPageEnabled) {
              context.$router.push({ path: "/" }).catch((error) => {});
              return;
            }

            if (context.getCookie("guest-token-" + context.teamId)) {
              context.getGuestProfile();
            } else {
              context.loadEvents();
              context.loadSingleEvent();
            }

            if (context.trackingId) {
              context.loadTicketData();
            }

            await context.loginWithToken();
          } else {
            context.$router.push({ path: "/" }).catch((error) => {});
          }
          context.pageLoaded = true;
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
            context.$router.push({ path: "/" }).catch((error) => {});
          }
        });
    },
    loadTicketData: function (code) {
      const context = this;
      this.pageLoaded = false;
      this.$store
        .dispatch("getTicketFromTrakingId", { id: this.trackingId, teamId: this.teamId })
        .then(async (result) => {
          if (result.status === "SUCCESS") {
            if (result.message === "TEST_NOT_VERIFIED") {
              context.errorMessage = context.string.publicPages._test_not_verified;
              context.subErrorMessage = context.string.publicPages._test_not_verified_sub;
              context.$notify({
                title: "Oops !",
                message: context.string.publicPages._test_not_verified,
                position: "bottom-right",
                type: "error",
                duration: 30000,
              });
            }
            context.singleEventData = result.data.event;
            context.qrCode = result.data.qrCode;
          }
          context.pageLoaded = true;
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    loadEvents: function () {
      const context = this;
      this.$store
        .dispatch(this.loggedInUser ? "teamEvents" : "teamPublicEvents", {
          teamId: this.teamId,
          pageInfo: {
            currentPage: this.currentPage,
            sortOrder: 1,
            sortField: "startDate",
          },
          queryType: this.eventType,
          token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
        })
        .then(async (result) => {
          if (result.status === "SUCCESS") {
            context.publicEvents = result.data;
          }
          await context.loginWithToken();
        })
        .catch((error) => {
          if (error.data && error.data.status && error.data.status === "FAILED") {
            context.$notify({
              title: "Oops !",
              message: error.data.message,
              position: "bottom-right",
              type: "error",
              duration: 3000,
            });
          }
        });
    },
    loadSingleEvent() {
      const context = this;
      if (!this.loggedInUser){
         this.$store
          .dispatch("eventDetailsNoPassword", {
            teamId: this.teamId,
            token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
            id: this.eventId,
          })
          .then(async (result) => {
            if (result.status === "SUCCESS") {
              context.singleEventData = result.data;
              context.singleEventData.privacyPolicies = result.data.joinInfo.privacyPolicies;
              context.singleEventData.timeSlotMap = result.data.joinInfo.timeSlotMap;
              context.singleEventData.customFieldMap = result.data.joinInfo.customFieldMap;
              context.singleEventData.memberCustomFieldMap =
                result.data.joinInfo.memberCustomFieldMap;
            }
          })
          .catch((error) => {
            console.log("error", error);
            if (error.data && error.data.status && error.data.status === "FAILED") {
              context.$notify({
                title: "Oops !",
                message: error.data.message,
                position: "bottom-right",
                type: "error",
                duration: 3000,
              });
            }
          });
      }
      if (this.loggedInUser) {
        this.$store
          .dispatch("eventDetails", {
            teamId: this.teamId,
            token: this.loggedInUser ? this.getCookie("guest-token-" + this.teamId) : undefined,
            id: this.eventId,
          })
          .then(async (result) => {
            if (result.status === "SUCCESS") {
              context.singleEventData = result.data;
              context.singleEventData.privacyPolicies = result.data.joinInfo.privacyPolicies;
              context.singleEventData.timeSlotMap = result.data.joinInfo.timeSlotMap;
              context.singleEventData.customFieldMap = result.data.joinInfo.customFieldMap;
              context.singleEventData.memberCustomFieldMap =
                result.data.joinInfo.memberCustomFieldMap;
            }
          })
          .catch((error) => {
            console.log("error", error);
            if (error.data && error.data.status && error.data.status === "FAILED") {
              context.$notify({
                title: "Oops !",
                message: error.data.message,
                position: "bottom-right",
                type: "error",
                duration: 3000,
              });
            }
          });
      }
    },
    changeEventType(type) {
      this.eventType = type;
      this.currentPage = 1;
      this.loadEvents();
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + d.toUTCString();
      document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        expires +
        ";path=/;" +
        (isLocalhost() ? "" : "domain=.penguinpass.it");
    },
    getCookie(cname) {
      const name = cname + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    closeLoginForm() {
      if (!this.postLoginRender) {
        this.selectedForm = null;
      }
    },
    closeRegisterForm() {
      this.selectedForm = null;
    },
    logoutDo() {
      this.selectedForm = null;
      document.cookie = `guest-token-${
        this.teamId
      }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${
        isLocalhost() ? "" : "domain=.penguinpass.it"
      }`;
      document.location.reload();
    },
    loginDo() {
      this.selectedForm = "login";
    },
    signUpDo() {
      this.selectedForm = "signup";
    },
    loginResetDo() {
      const context = this;

      if (this.selectedForm === "login") {
        if (this.loginForm.email === "" || this.loginForm.password === "") {
          context.loginForm.errors = "Please fill all fields.";
          context.loginForm.info = null;
          return;
        }

        this.$store
          .dispatch("guestLogin", {
            teamId: this.teamId,
            source: document.location.href,
            ...this.loginForm,
          })
          .then((result) => {
            if (result.status && result.status === "SUCCESS") {
              if (result.message === "VERIFY_ACCOUNT") {
                context.loginForm.errors =
                  "Account not verified, please check your inbox to verify you account.";
                context.loginForm.info = null;
              } else {
                context.setCookie("guest-token-" + context.teamId, result.data, 30);
                context.selectedForm = null;
                context.getGuestProfile();
              }
            } else {
              context.loginForm.errors = "Wrong email or password.";
              context.loginForm.info = null;
            }
          });
      }
      if (this.selectedForm === "reset") {
        if (this.loginForm.email === "") {
          context.loginForm.errors = "Please fill the email field.";
          context.loginForm.info = null;
          return;
        }

        this.$store
          .dispatch("guestResetPassword", { teamId: this.teamId, ...this.loginForm })
          .then((result) => {
            if (result.status && result.status === "SUCCESS") {
              context.loginForm.info = "We have sent an email to reset your password.";
              context.formSwitch("login");
            } else {
              if (result.message && result.message === "NOT_FOUND") {
                context.loginForm.errors = "Email not found.";
              } else {
                context.loginForm.errors =
                  "Error in resetting your password, please contact the help support.";
              }
            }
          });
      }
    },
    formSwitch(formName) {
      this.selectedForm = formName;
    },
    registerDo() {
      const context = this;
      this.$store
        .dispatch("guestRegister", { teamId: this.teamId, ...this.signupForm })
        .then((result) => {
          if (result && result.status === "SUCCESS" && result.message === "VERIFY_ACCOUNT") {
            context.thankYouMessage = context.string.registrationPage._thankYouWaiting2;
          } else if (
            result &&
            result.status === "SUCCESS" &&
            result.data &&
            result.data.joinStatus === "OK"
          ) {
            context.thankYouMessage = context.string.registrationPage._thankYouWaiting2;
          } else if (result && result.status === "ERROR" && result.message === "EMAIL_IN_USE") {
            context.joinMessage = context.string.registrationPage._emailInUse2;
          } else if (
            result &&
            result.status === "ERROR" &&
            result.message === "PASSWORD_NOT_MATCHING_POLICY"
          ) {
            context.joinMessage = context.string.commonActions.passwordPolicy;
          }
        })
        .catch((error) => {
          if (error.data && error.data.message) {
            if (error.data.message === "PASSWORD_NOT_MATCHING_POLICY") {
              context.$notify({
                title: "Oops !",
                message: context.string.commonActions.passwordPolicy,
                position: "bottom-right",
                type: "error",
                duration: 3000,
              });
            } else {
              context.$notify({
                title: "Oops !",
                message: error.data.message,
                position: "bottom-right",
                type: "error",
                duration: 3000,
              });
            }
          }
        });
    },
    getGuestProfile() {
      const context = this;
      this.$store
        .dispatch("guestProfile", {
          teamId: this.teamId,
          source: document.location.href,
          token: this.getCookie("guest-token-" + this.teamId),
        })
        .then((result) => {
          if (result === "Unauthorized") {
            document.cookie = `guest-token-${
              this.teamId
            }=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${
              isLocalhost() ? "" : "domain=.penguinpass.it"
            }`;
            document.location.reload();
            return;
          }

          if (result.status && result.status === "SUCCESS") {
            context.$root.bus.emit("guestProfile", result.data);
            context.loggedInUser = result.data;
            context.selectedForm = "logout";
            if (context.eventId) {
              context.loadSingleEvent();
            } else {
              context.loadEvents();
            }
          }
        })
        .catch((result) => {
          console.log("error", result);
        });
    },
    async loginWithToken() {
      const context = this;

      if (this.$route.query && this.$route.query.t) {
        return await this.$store
          .dispatch("guestLogin", {
            teamId: this.teamId,
            source: document.location.href,
            token: this.$route.query.t,
          })
          .then((result) => {
            if (result.status && result.status === "SUCCESS") {
              context.setCookie("guest-token-" + context.teamId, result.data, 30);
              context.selectedForm = null;
              return result;
            } else {
              context.loginForm.errors = "Wrong email or password.";
              context.loginForm.info = null;
              return null;
            }
          });
      }
      return null;
    },
  },
};
</script>
<style>
.public-profile-landing-tracking {
  background: black;
}
.public-profile-body {
  min-height: 100vh;
}
.public-profile-body .no_events {
  padding: 30px;
  font-size: 16px;
  color: #aaaaaa;
  text-align: center;
}
.public-profile-body .filters {
  text-align: center;
  font-size: 18px;
}
.public-profile-body .filters a.active {
  text-decoration: underline;
  font-weight: bold;
}
.public-profile-body .heroImage {
  display: flex;
  position: relative;
  height: 50vh;
  padding: 60px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://media.penguinpass.it/cover/2023/1/11/1673439772806.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom:35px;
}
.public-profile-body .heroImage.singleEvent {
  height: 90px;
  padding: 15px;
  transition: all 1s;
}
.public-profile-body .heroImage h1 {
  color: #ffffff;
}
.public-profile-body .el-row {
  margin-bottom: 15px;
}
.public-profile-body .card-content {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  position: relative;
}
.public-profile-body .card-content .event-description {
  flex: 1;
  padding: 15px;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: space-between;
}
.public-profile-body .card-image {
  width: 343px;
  height: 220px;
  object-fit: cover;
}
.image-favourite {
  position: absolute;
  top: 0px;
  left: 15px;
  color: #ffffff;
  text-shadow: 1px 2px 3px #000000;
  font-size: 40px;
  opacity: 0;
  transition: all 1s;
  cursor: pointer;
}
.public-profile-body .card-image:hover + .image-favourite,
.image-favourite:hover {
  top: 15px;
  opacity: 1;
  transition: all 1s;
}
.public-profile-body .card-content .event-description .title {
  color: #231f20;
  font-size: 24px;
}
.public-profile-body .card-content .event-description .datetime {
  color: #7a7e7e;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 15px;
}
.public-profile-body .card-content .event-description .description {
  color: #000000;
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.public-profile-body .card-content .event-actions {
  flex: 1;
  height: 100%;
  padding: 15px;
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: space-between;
}
.public-profile-body .card-content .event-actions-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: space-between;
}
.public-profile-body .card-content .event-actions-row .el-button,
.event-details .event-actions-row .el-button {
  background: #626061 !important;
  border-color: #626061 !important;
  color: #ffffff !important;
  padding: 10px 15px;
  border-radius: 5px;
}
.public-profile-body .card-content .event-actions-row .el-button.disabled {
  background: #ffffff !important;
  border: 1px solid #aaaaaa !important;
  color: #aaaaaa !important;
}
.public-profile-body .el-pagination {
  text-align: center;
}
.public-profile-body .el-pagination .el-pager li.active {
  color: #000000;
}
.card-container{
  margin-top: 35px;
}
.event-actions-row{
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.bee-row-content {
  max-width: none !important;
}
.fullwidthOnMobile {
  max-width: none !important;
}
.bee-block ul li {
  list-style: initial;
}
.bee-row-content .bee-col-w3 {
  flex: 3;
}
.bee-row-content .bee-col-w9 {
  flex: 9;
}
.bee-row-content .bee-col-w12 {
  flex: 12;
}
.bee-col {
  padding: 0 !important;
}
.organizerPublicPage {
  background-color: #ffffff !important;
}
#profile-place {
  text-transform: capitalize;
  padding-right: 10px;
}
.login-area,
#login-place {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.login-area .btn-primary {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
  border: 0;
  padding: 10px 10px;
  width: 100px;
  border-radius: 5px;
}
.signup-link {
  margin: 0px !important;
  margin-left: 10px !important;
}
.single-event .cover-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.single-event.tracking {
  margin-top: 30px;
  padding: 30px;
  background: white;
}
</style>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~animate.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../assets/css/v2/style.css";
@import "../assets/css/v2/custom.css";
@import "../assets/css/v2/join.css";
.coverBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  filter: blur(5px);
  z-index: 10;
}
a {
  color: #626061;
}
.footer-text {
  color: #555555;
  font-size: 13px;
}
.footer-text a {
  text-decoration: none;
}
#logout-link {
  display: inline-block;
}
#login-modal,
#signup-modal {
  display: flex;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
#login-modal .login-form,
#signup-modal .signup-form {
  width: 400px;
  min-height: 330px;
  background: rgba(255, 255, 255, 0.7);
  padding: 15px;
  border-radius: 15px;
}
#login-modal .login-form .btn-primary,
#signup-modal .signup-form .btn-primary {
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}
#login-modal .login-form input,
#signup-modal .signup-form input {
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  color: #000000 !important;
}
#login-modal .login-form input::placeholder,
#signup-modal .signup-form input::placeholder {
  color: #555555;
}
.form-signin {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.form-signin-heading {
  color: #ffffff;
  text-align: center;
}
.form-signin .btn {
  margin-top: 10px;
}
.error-message {
  color: red;
  margin-top: 12px;
  margin-bottom: 24px;
}
.signup-form .thankyou {
  text-align: center;
  padding-top: 50px;
}
.back-to-list {
  line-height: 45px;
  font-size: 16px;
}

.topnav {
  margin-top: 0;
  padding: 1rem;
  background-color: #fff;

  display: flex;
}

.topnav__home {
  flex: 1;
  line-height: 2;
}

.topnav__home a {
  text-decoration: none;
  color: black;
  padding-left: 2rem;
  font-weight: 500;
}

.topnav__buttons {
  margin-right: 2rem;
  line-height: 4rem;
}

.topnav__username {
  font-weight: 500;
}

.topnav__username a {
  text-decoration: none;
  color: black;
}

.topnav__buttons .btn-secondary {
  margin-left: 2rem;
}

.btn-secondary {
  background-color: white;
  border: 1px solid black;
}

.bb-shadow {
  box-shadow: 0px 2px 5px -2px rgba(150, 150, 150, 0.75);
  -webkit-box-shadow: 0px 2px 5px -2px rgba(150, 150, 150, 0.75);
  -moz-box-shadow: 0px 2px 5px -2px rgba(150, 150, 150, 0.75);
}

.mb-2 {
  margin-bottom: 2rem;
}

.event-list__bar {
  display: flex;
  padding: 1rem 0;
}

.event-list__categories {
  flex: 1;
}

.event-list__items .el-row {
  /* Necessary to overwrite el-row inline style */
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.search-addon {
  color: rgba(150, 150, 150, 0.75);
  background: #f2f2f2;
  border-width: 0;
}

.search-addon.glyphicon {
  color: rgba(150, 150, 150, 0.75);
  background: #f2f2f2;
  border-width: 0;
  top: 0px;
}

.event-list__searchbar input[type="text"] {
  background-color: #f2f2f2;
  min-width: 300px;
  /* Necessary to overwrite createevent.css */
  border-width: 0 !important;
  color: #6d6d6d !important;
}

.public-profile-body .filters {
  text-align: left;
}

.event-list__categories .filters a {
  color: black;
  padding-bottom: 1rem;
}

.public-profile-body .filters a.active,
.public-profile-body .filters a:hover {
  text-decoration: none;
  border-bottom: 3px solid #626061 !important;
}

.public-profile-body .card-content {
  background-color: #fff;
  align-items: flex-start;
}

.public-profile-body .el-card .is-always-shadow {
  box-shadow: 2px 2px 5px 0px rgba(150, 150, 150, 0.75);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(150, 150, 150, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(150, 150, 150, 0.75);
}
.public-profile-body-v2 .card-content .event-actions {
  padding-left: 0;
  justify-content: flex-end;
}
.public-profile-body-v2 .card-content .event-description {
  padding: 0 1rem;
  height: 100%;
}

.public-profile-body-v2 .card-content .event-description .description {
  color: #6d6d6d;
}

.public-profile-body-v2 .card-content .event-description .datetime {
  color: #6d6d6d;
  display: flex;
  width: 100%;
  font-weight: 500;
}

.public-profile-body-v2 .card-content .event-description .location {
  color: #6d6d6d;
}

.event__time-location {
  flex: 1;
  line-height: 5rem;
}

.event__time-location .glyphicon {
  color: #626061;
}

.event__date {
  border: 1px solid #f2f2f2;
  padding: 0.5rem;
  text-align: center;
  min-width: 6rem;
}

.event__date__day {
  font-size: 120%;
}

.event__date__month {
  font-weight: 400;
  font-size: 90%;
  text-transform: capitalize;
}

.header__description {
  padding: 5rem 0 2rem 0;
  max-width: 70%;
}

.footer-text a {
  color: #000;

  font-weight: 500;
}

.separator {
  padding-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.profile__icon {
  color: #000;
  vertical-align: middle;
}
</style>
