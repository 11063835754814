var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    !_vm.resetDone
      ? _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row wow fadeIn" }, [
            _c("div", { staticClass: "col-md-7" }, [
              _c(
                "div",
                {
                  staticClass:
                    "heading heading-md heading-uppercase heading-hover",
                },
                [
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "h2",
                    {
                      staticClass: "heading-title text-white",
                      attrs: { id: "title" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.string.recoveryPassword
                              ._forgot_password_heading_text
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass: "heading-tescription lead text-gray-2",
                      attrs: { id: "description" },
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.string.recoveryPassword
                              ._forgot_password_reset_text
                          ) +
                          "\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "margin-top-80 text-white wow fadeIn",
                attrs: { id: "contact-form", "data-wow-delay": "0.3s" },
              },
              [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { margin: "2px" } },
                  [
                    _c("div", { staticClass: "col-lg-8" }, [
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "label",
                          { staticClass: "sr-only", attrs: { for: "email" } },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.string.recoveryPassword
                                    ._forgot_password_input_text
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email,
                              expression: "email",
                            },
                          ],
                          staticClass: "form-control no-rounded",
                          attrs: {
                            id: "email",
                            type: "email",
                            name: "email",
                            value: "",
                            placeholder: "Email Address",
                            required: "",
                          },
                          domProps: { value: _vm.email },
                          on: {
                            keyup: function ($event) {
                              var this$1 = this
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return (function (e) {
                                e.preventDefault()
                                this$1.resetPassword()
                              })($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.email = $event.target.value
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-warning active btn-block btn-lg",
                            attrs: { type: "submit" },
                            on: {
                              click: function (e) {
                                e.preventDefault()
                                this$1.resetPassword()
                              },
                              keyup: function ($event) {
                                var this$1 = this
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return (function (e) {
                                  e.preventDefault()
                                  this$1.resetPassword()
                                })($event)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.string.recoveryPassword
                                    ._forgot_password_button_text
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
              ]
            ),
          ]),
        ])
      : _c(
          "div",
          {
            staticClass: "container",
            staticStyle: { "padding-bottom": "150px" },
          },
          [
            _c("div", { staticClass: "row wow fadeIn" }, [
              _c("div", { staticClass: "col-md-7" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "heading heading-md heading-uppercase heading-hover",
                  },
                  [
                    _c("br"),
                    _vm._v(" "),
                    _c("h2", { staticClass: "heading-title text-white" }, [
                      _vm._v(
                        "\n                          " +
                          _vm._s(
                            _vm.string.recoveryPassword._successRecovery_title
                          ) +
                          "\n                      "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "heading-tescription lead text-gray-2" },
                      [
                        _vm._v(
                          "\n                          " +
                            _vm._s(
                              _vm.string.recoveryPassword
                                ._successecovery_message
                            ) +
                            "\n                          "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "mailto:info@penguinpass.it",
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                              info@penguinpass.it\n                          "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }