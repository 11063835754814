var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { ref: "container" }, [
      _c("div", { staticClass: "layer" }, [
        _c("section", { staticClass: "tm-margin-large-top" }, [
          _c("div", { staticClass: "uk-container uk-text-center" }, [
            _c(
              "p",
              {
                staticClass:
                  "uk-margin-remove-bottom tm-overtitle uk-margin-top uk-text-uppercase letter-spacing-l",
                attrs: {
                  "data-uk-scrollspy": "cls: uk-animation-slide-bottom-medium",
                },
              },
              [_vm._v("\n              Penguinpass support\n            ")]
            ),
            _vm._v(" "),
            _c(
              "h1",
              {
                staticClass: "uk-margin-small-top uk-heading-hero",
                attrs: {
                  "data-uk-scrollspy":
                    "cls: uk-animation-slide-bottom-medium; delay: 40",
                },
              },
              [_vm._v("\n              Come possiamo aiutarti?\n            ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "uk-grid-medium uk-child-width-1-3@m uk-grid-small uk-grid-match tm-margin-large-top uk-text-center uk-grid uk-scrollspy-inview uk-animation-slide-bottom-medium",
                attrs: {
                  "data-uk-scrollspy":
                    "cls: uk-animation-slide-bottom-medium; delay: 80",
                },
              },
              [
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-quickstart" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: home; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("polygon", {
                                      attrs: {
                                        points:
                                          "18.65 11.35 10 2.71 1.35 11.35 0.65 10.65 10 1.29 19.35 10.65",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("polygon", {
                                      attrs: {
                                        points: "15 4 18 4 18 7 17 7 17 5 15 5",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("polygon", {
                                      attrs: {
                                        points:
                                          "3 11 4 11 4 18 7 18 7 12 12 12 12 18 16 18 16 11 17 11 17 19 11 19 11 13 8 13 8 19 3 19",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Quick Start")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-eventi" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: calendar; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M 2,3 2,17 18,17 18,3 2,3 Z M 17,16 3,16 3,8 17,8 17,16 Z M 17,7 3,7 3,4 17,4 17,7 Z",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        width: "1",
                                        height: "3",
                                        x: "6",
                                        y: "2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        width: "1",
                                        height: "3",
                                        x: "13",
                                        y: "2",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Eventi")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-virtualroom" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: { "data-uk-icon": "icon: tv; ratio: 2" },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "7",
                                        y: "16",
                                        width: "6",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        x: "0.5",
                                        y: "3.5",
                                        width: "19",
                                        height: "11",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Virtual Room e Webinar")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-listeinvitati" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: list; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "4",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "9",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "6",
                                        y: "14",
                                        width: "12",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "4",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "9",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("rect", {
                                      attrs: {
                                        x: "2",
                                        y: "14",
                                        width: "2",
                                        height: "1",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Liste Invitati")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-campagneemail" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: mail; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("polyline", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        points: "1.4,6.5 10,11 18.6,6.5",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        d: "M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Campagne Email")]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "uk-card uk-card-box uk-card-body uk-box-shadow-medium uk-border-rounded",
                        attrs: { to: "/helpdesk/it-impostazioni" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "uk-icon-forder uk-border-rounded" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "uk-icon",
                                attrs: {
                                  "data-uk-icon": "icon: cog; ratio: 2",
                                },
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      width: "40",
                                      height: "40",
                                      viewBox: "0 0 20 20",
                                      xmlns: "http://www.w3.org/2000/svg",
                                      ratio: "2",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        cx: "9.997",
                                        cy: "10",
                                        r: "3.31",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        fill: "none",
                                        stroke: "#000",
                                        d: "M18.488,12.285 L16.205,16.237 C15.322,15.496 14.185,15.281 13.303,15.791 C12.428,16.289 12.047,17.373 12.246,18.5 L7.735,18.5 C7.938,17.374 7.553,16.299 6.684,15.791 C5.801,15.27 4.655,15.492 3.773,16.237 L1.5,12.285 C2.573,11.871 3.317,10.999 3.317,9.991 C3.305,8.98 2.573,8.121 1.5,7.716 L3.765,3.784 C4.645,4.516 5.794,4.738 6.687,4.232 C7.555,3.722 7.939,2.637 7.735,1.5 L12.263,1.5 C12.072,2.637 12.441,3.71 13.314,4.22 C14.206,4.73 15.343,4.516 16.225,3.794 L18.487,7.714 C17.404,8.117 16.661,8.988 16.67,10.009 C16.672,11.018 17.415,11.88 18.488,12.285 L18.488,12.285 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("p", [_vm._v("Impostazioni")]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { ref: "toc", staticClass: "uk-margin-xlarge-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h2", { staticClass: "uk-margin-medium-bottom" }, [
          _vm._v("\n          Domande più frequenti\n        "),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Cosa sono dashboard, Check-inApp e GuestApp?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Quando attivo un evento cosa succede?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come funziona il Qr Code per accedere all’ evento?\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-5")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Posso aggiungere dettagli/attributi personalizzati sui miei invitati?\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Cosa sono dashboard, check-inAPP e guestApp?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Quando attivo un evento cosa succede?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Dove trovo il nome e cognome degli accompagnatori che ha inserito il mio invitato e come li aggiungo alla\n            lista?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n            Quando l’invitato confermerà la propria partecipazione avrà (se da dashboard\n            l’organizzatore lo consente) la possibilià di inserire “nome, cognome e email” dei suoi\n            eventuali accompagnatori. Questi nomi e dati si potranno trovare semplicemente scaricando\n            il foglio excel tramite “donwload tutta la lista” nella sezione “guest list” sotto forma di nota.\n            Prelevando questi nominativi e risistemandoli in un nuovo foglio excel sull’apposito formato\n            “template per importazione xls” e ricaricandolo. Cosi facendo si potrà inviare email anche\n            agli accompagnatori dei tuoi ospiti se necessario, oppure semplicemente si potranno\n            visualizzare tramite check-inApp.\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come funziona il Qr code per accedere all’ evento?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n            Spesso vi è la necessità o volontà di gestire le entrate agli eventi tramite QR Code, questo\n            vuol dire che gli invitati che invieranno i propri RSVP riceveranno un email della loro\n            conferma contenente dettagli aggiuntivi sull’evento e il QR Code personale per accedere.\n            Secondo questa logica di funzionamento, è quindi necessario impostare da dashboard l’invio\n            di una mail di conferma, e inserire in essa il contenuto dinamico (QR Code).\n          "
            ),
          ]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(
              "\n            Centrare un Qr code nella e-mail\n          "
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n            Quando si inserisce il “contenuto dinamico” nel “modulo” vuoto in Emailbuilder, se si utilizza\n            un “modulo unico” (il primo nella sezione moduli) il Qr Code verrà visualizzato sul lato sinistro\n            dell’email, se si desidera che invece venga visualizzato al centro sarà necessario\n            semplicemente utilizzare non il “modulo unico” ma il “modulo suddiviso in tre sezioni” (il settimo\n            della lista moduli) e inserire il contenuto dinamico in quella centrale.\n          "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-5",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Posso aggiungere dettagli/attributi personalizzati sui miei invitati?\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("toc")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Dashboard, Guest e Check-in app sono I tre strumenti su cui si basa il sistema Penguinpass;\n            Il primo è utilizzato dagli organizzatori tramite accesso web per creare e gestire gli eventi\n            avendo così in unico strumento una visione completa e organizzata. "
      ),
      _c("br"),
      _vm._v(
        "\n            Le due app per mobile sono invece rivolte agli utilizzatori del servizio: la Check-inApp\n            permette alle hostess di gestire gli accessi in loco, le quali tramite questo supporto dinamico\n            possono controllare e aggiornare le liste ospiti in tempo reale. Mentre la GuestApp è pensata e sviluppata\n            per gli invitati che grazie all’app possono rimanere sempre informati e\n            aggiornati sugli eventi e accedere alle location con facilità e rapidità.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Nel momento in cui un evento viene attivato l'organizzatore potrà utilizzare la funzione di mail marketing\n            per inviare inviti e comunicazioni ai propri ospiti.\n            "
      ),
      _c("br"),
      _vm._v(
        " Inoltre una volta attivato l'evento sarà disponibile sulle GuestApp e quindi, visibile ai soli\n            invitati, in caso di evento privato, a chiunque se è un evento pubblico.\n            "
      ),
      _c("br"),
      _vm._v(
        "\n            È importante notare che non è possibile utilizzare le funzionalità della Check-inApp se l’evento non è stato\n            attivato.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Per inserire un attributo specifico e personalizzato alla lista degli invitati, sarà sufficiente modificare\n            nel “template per importazione xls” (scaricato dal sito come sopra spiegato) la colonna “custom\n            note***” ponendo al suo posto il nome dell’attributo desiderato (es. da “custom note***” a “paese\n            d’origine” o “impresa”). "
      ),
      _c("br"),
      _vm._v(
        "\n            Se si necessita di più attributi-colonna, è possibile aggiungerne altri in seguito alla colonna “custom\n            note***” "
      ),
      _c("br"),
      _vm._v(
        '\n            In caso ci si dimentichi di aggiungere i campi extra al momento del caricamento dei guest, è possibile\n            aggiungere nuove colonne andando nella sezione impostazioni evento, espandendo la sezione "Modifica custom\n            fields" sarà possibile aggiungere nuovi attributi. Una volta aggiunti basterà selezionare la voce Check-in\n            app per rendere il campo visibile nella app di check-in.\n          '
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }