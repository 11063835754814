<template>
  <div class="button-tab-container">
    <a v-for="tab in tabs" :key="tab.key"
      :class="'button-tab-item btn gray-btn save ' + (currentTab === tab.key ? ' active ' : '')"
      aria-controls="info"
      role="tab"
      data-toggle="tab"
      @click.prevent="() => changeTab(tab.key)">
      {{ $root.strings.buttonTabs[tab.key] || tab.label }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    initialTab: {
      type: String,
      default: "",
    },
    persistSelection: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {
      currentTab: this.initialTab
    };
  },
  watch:{
    initialTab: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.currentTab = newVal;
      }
    }
  },
  methods: {
    changeTab(tab) {
      let newTab = "";
        if (this.currentTab !== tab) {
          newTab = tab;
        } else {
          newTab = "";
        }

      if (this.persistSelection) {
        this.currentTab = newTab;
      }
      this.$emit("tabChanged", newTab);
    }
  }
};
</script>

<style scoped>
.button-tab-container {
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.button-tab-item {
    margin: 5px;
    flex: 1;
}
.button-tab-item:first-child {
  margin-left: 0;
}
.button-tab-item:last-child {
  margin-right: 0;
}
.button-tab-item.active {
  background-color: #FF9C00 !important;
}
</style>