var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c("div", { staticClass: "default-layout" }, [
      _c(
        "div",
        { staticClass: "edit-page" },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.loadingEffectshow
                ? _c("LoadingCover", {
                    attrs: {
                      "loading-cover-msg":
                        _vm.string.singleEvent._loadingMessage,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "user-menu-container square" }, [
            _c("div", { staticClass: " user-details" }, [
              _c("div", { staticClass: "white coralbg", class: _vm.smartBg }, [
                _c(
                  "div",
                  { staticClass: "col-sm-16 col-md-8 no-pad" },
                  [
                    _c("EventPad", {
                      attrs: {
                        event: _vm.event,
                        title: _vm.eventTitle,
                        location: _vm.eventLocation,
                        venue: _vm.eventVenue,
                        "start-date": _vm.eventStartDate,
                        "end-date": _vm.eventEndDate,
                        "start-time": _vm.eventStartTime,
                        "end-time": _vm.eventEndTime,
                        "start-date-object": _vm.eventStartDateObject,
                        "end-date-object": _vm.eventStartDateObject,
                        "read-only": _vm.isExhibitor,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-16 col-md-8 no-pad bgColored" },
                  [
                    _c("CoverImage", {
                      attrs: {
                        src: _vm.eventCover,
                        "event-id": this.eventId,
                        "read-only": _vm.isExhibitor,
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                !_vm.isExhibitor
                  ? _c("VisibilityManager", {
                      attrs: { "event-id": this.eventId, event: _vm.event },
                      on: { visibilityChanged: _vm.onVisibilityChanged },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {},
              [
                _c("Overview", {
                  attrs: {
                    "event-id": _vm.eventId,
                    "is-private": _vm.eventIsPrivate,
                  },
                  on: { changeConfirmedCounter: _vm.updateCounter },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "user-menu-btns" }, [
              _c(
                "div",
                {
                  staticClass: "btn-group-vertical square",
                  attrs: { id: "responsive" },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-block btn-default active",
                      staticStyle: { padding: "25px !important" },
                      attrs: { id: "description" },
                      on: {
                        click: function (e) {
                          e.preventDefault()
                          _vm.changeTab("description")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-file fa-2x",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.event.genre === "PRIVATE" &&
                  _vm.features &&
                  _vm.features.delegation
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-block btn-default",
                          staticStyle: { padding: "25px !important" },
                          attrs: { id: "sponsor" },
                          on: {
                            click: function (e) {
                              e.preventDefault()
                              this$1.changeTab("sponsor")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-address-card fa-2x",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-block btn-default",
                      staticStyle: { padding: "25px !important" },
                      attrs: { id: "guestlist" },
                      on: {
                        click: function (e) {
                          e.preventDefault()
                          _vm.changeTab("guestlist")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-users fa-2x",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.showMarketingSection && !_vm.isExhibitor
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              _vm.string.singleEvent._emailMarketingWarning,
                            placement: "bottom",
                          },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-default",
                              staticStyle: { padding: "25px !important" },
                              on: {
                                click: function (e) {
                                  e.preventDefault()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-envelope-o fa-2x",
                                attrs: { "aria-hidden": "true" },
                              }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showMarketingSection && !_vm.isExhibitor
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-default",
                          staticStyle: { padding: "25px !important" },
                          attrs: { id: "marketing" },
                          on: {
                            click: function (e) {
                              e.preventDefault()
                              _vm.changeTab("marketing")
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-envelope-o fa-2x",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.features &&
                  _vm.features.textMessages &&
                  _vm.event.genre === "PRIVATE"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-default",
                          staticStyle: { padding: "25px !important" },
                          attrs: { id: "whatsapp" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (function () {
                                return _vm.changeTab("whatsapp")
                              })($event)
                            },
                          },
                        },
                        [
                          _c("div", { staticStyle: { "{display": "flex}" } }, [
                            _c("i", {
                              staticClass: "fa fa-commenting fa-2x",
                              attrs: { "aria-hidden": "true" },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa fa-whatsapp fa-2x",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.features && _vm.features.virtualRoom
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-default",
                          staticStyle: { padding: "25px !important" },
                          attrs: { id: "virtual-room" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (function () {
                                return _vm.changeTab("virtual-room")
                              })($event)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-desktop fa-2x",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.features &&
                  _vm.features.registrationForm &&
                  !_vm.features.virtualRoom
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-default",
                          staticStyle: { padding: "25px !important" },
                          attrs: { id: "registration-form" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return (function () {
                                return _vm.changeTab("registration-form")
                              })($event)
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-desktop fa-2x",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-default",
                      staticStyle: { padding: "25px !important" },
                      attrs: { id: "report" },
                      on: {
                        click: function (e) {
                          e.preventDefault()
                          _vm.changeTab("report")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-bar-chart fa-2x",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-default",
                      staticStyle: { padding: "25px !important" },
                      attrs: { id: "settings" },
                      on: {
                        click: function (e) {
                          e.preventDefault()
                          _vm.changeTab("settings")
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-cog fa-2x",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "user-menu user-pad fix-width" }, [
              _c(
                "div",
                {
                  staticClass: "user-menu-content active",
                  attrs: { id: "tab-description" },
                },
                [
                  _c("Description", {
                    attrs: {
                      event: _vm.event,
                      "event-id": _vm.eventId,
                      description: _vm.event.descriptionIt,
                      "description-en": _vm.event.descriptionEn,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-sponsor" },
                },
                [
                  _vm.currentSelectedTab === "sponsor"
                    ? _c("SponsorListContainer", {
                        attrs: {
                          event: _vm.event,
                          "is-private": _vm.event.genre === "PRIVATE",
                          "event-id": _vm.eventId,
                          "confirmation-plus-one":
                            _vm.counterConfirmationPlusOne,
                          "custom-field-map": _vm.event.customFieldMap,
                          "max-guests-per-event": _vm.event.maxGuests,
                          "max-guests-per-member": _vm.event.maxGuestsPerMember,
                          "event-name": _vm.event.name,
                          "badge-printing-enabled":
                            _vm.event.badgePrintingEnabled,
                          "time-slot-enabled": _vm.event.timeSlotEnabled,
                          "time-slot-map": _vm.event.timeSlotMap,
                          "custom-field-map-filtered":
                            _vm.event.combinedCustomFields,
                          "hubilo-active": _vm.features.hubilo,
                          "hubilo-event-id": _vm.event.hubiloEventId,
                        },
                        on: { customFieldsChanged: _vm.onCustomFieldsChanged },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-guestlist" },
                },
                [
                  _vm.currentSelectedTab === "guestlist"
                    ? _c("GuestListContainer", {
                        attrs: {
                          event: _vm.event,
                          "is-private": _vm.event.genre === "PRIVATE",
                          "event-id": _vm.eventId,
                          "confirmation-plus-one":
                            _vm.counterConfirmationPlusOne,
                          "custom-field-map": _vm.event.customFieldMap,
                          "max-guests-per-event": _vm.event.maxGuests,
                          "max-guests-per-member": _vm.event.maxGuestsPerMember,
                          "event-name": _vm.event.name,
                          "badge-printing-enabled":
                            _vm.event.badgePrintingEnabled,
                          "time-slot-enabled": _vm.event.timeSlotEnabled,
                          "time-slot-map": _vm.event.timeSlotMap,
                          "custom-field-map-filtered":
                            _vm.event.combinedCustomFields,
                          "hubilo-active": _vm.features.hubilo,
                          "hubilo-event-id": _vm.event.hubiloEventId,
                        },
                        on: { customFieldsChanged: _vm.onCustomFieldsChanged },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-marketing-inactive" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.string.singleEvent._emailMarketingWarning) +
                      "\n          "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-whatsapp" },
                },
                [
                  _vm.currentSelectedTab === "whatsapp" && !_vm.isExhibitor
                    ? _c("TextMarketing", {
                        attrs: { event: _vm.event, "event-id": _vm.eventId },
                        on: { changeTab: _vm.changeTab },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-marketing" },
                },
                [
                  _vm.currentSelectedTab === "marketing" && !_vm.isExhibitor
                    ? _c("DirectEmailMarketing", {
                        attrs: { event: _vm.event, "event-id": _vm.eventId },
                        on: { changeTab: _vm.changeTab },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.features && _vm.features.virtualRoom
                ? _c(
                    "div",
                    {
                      staticClass: "user-menu-content",
                      attrs: { id: "tab-virtual-room" },
                    },
                    [
                      _vm.currentSelectedTab === "virtual-room"
                        ? _c("RegistrationFormContainer", {
                            attrs: {
                              event: _vm.event,
                              "event-id": _vm.eventId,
                            },
                            on: { "event-modified": _vm.onCustomFieldsChanged },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.features &&
              _vm.features.registrationForm &&
              !_vm.features.virtualRoom
                ? _c(
                    "div",
                    {
                      staticClass: "user-menu-content",
                      attrs: { id: "tab-registration-form" },
                    },
                    [
                      _vm.currentSelectedTab === "registration-form"
                        ? _c("VirtualRoomContainer", {
                            attrs: {
                              event: _vm.event,
                              "event-id": _vm.eventId,
                            },
                            on: { "event-modified": _vm.onCustomFieldsChanged },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-report" },
                },
                [
                  _vm.currentSelectedTab === "report"
                    ? _c("Report", {
                        attrs: { event: _vm.event, "event-id": this.eventId },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "user-menu-content",
                  attrs: { id: "tab-settings" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "advance-settings-container" },
                    [
                      !_vm.isExhibitor
                        ? _c("Messages", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isPrivate,
                                expression: "!isPrivate",
                              },
                            ],
                            attrs: {
                              event: _vm.event,
                              "event-id": _vm.eventId,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.features && _vm.features.badgePrinting
                        ? _c("BadgePrinting", {
                            attrs: {
                              event: _vm.event,
                              "event-id": this.eventId,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("TimeSlotManager", {
                        attrs: { event: _vm.event, "event-id": _vm.eventId },
                        on: { customFieldsChanged: _vm.onCustomFieldsChanged },
                      }),
                      _vm._v(" "),
                      _c("AdvanceSettings", {
                        attrs: { event: _vm.event },
                        on: { customFieldsChanged: _vm.onCustomFieldsChanged },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }