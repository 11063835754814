var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { ref: "section-1", staticClass: "tm-margin-large-top" }, [
      _c("div", { staticClass: "uk-container" }, [
        _c("h1", { staticClass: "uk-margin-medium-bottom uk-heading-hero" }, [
          _vm._v("\n          Virtual Room e Webinar\n        "),
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "uk-breadcrumb uk-margin-medium-bottom" }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/helpdesk/it-home" } }, [
                _vm._v("\n              Support\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
        ]),
        _vm._v(" "),
        _c("hr", { staticClass: "uk-margin-remove" }),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "uk-list uk-list-large uk-margin-medium-top" },
          [
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-1")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come creare una landing page personalizzata\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-2")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come programmare una Virtual Room\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-3")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Come funziona il modello Webinar\n            "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "hvr-forward",
                  attrs: { "data-uk-scroll": "duration: 400; offset: 140" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("article-4")
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "uk-icon",
                      attrs: { "data-uk-icon": "icon: arrow-right" },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "20",
                            height: "20",
                            viewBox: "0 0 20 20",
                            xmlns: "http://www.w3.org/2000/svg",
                            ratio: "1",
                          },
                        },
                        [
                          _c("polyline", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              points: "10 5 15 9.5 10 14",
                            },
                          }),
                          _vm._v(" "),
                          _c("line", {
                            attrs: {
                              fill: "none",
                              stroke: "#000",
                              x1: "4",
                              y1: "9.5",
                              x2: "15",
                              y2: "9.5",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(
                    "\n              Cos'è la Virtual Venue?\n            "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-1",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come creare una landing page personalizzata\n            "
              ),
              _c(
                "svg",
                {
                  attrs: {
                    width: "18",
                    height: "10",
                    viewBox: "0 0 18 10",
                    xmlns: "http://www.w3.org/2000/svg",
                    ratio: "1",
                  },
                },
                [
                  _c("polyline", {
                    attrs: {
                      fill: "none",
                      stroke: "#000",
                      "stroke-width": "1.2",
                      points: "1 9 9 1 17 9 ",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Impara come creare la landing page personalizzata per il tuo evento in pochi semplici passi."
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-2",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come programmare una Virtual Room\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Impara come programmare una virtual room di tipo meeting o webinar dal tuo dashboard."
            ),
          ]),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-3",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v(
                "\n            Come funziona il modello Webinar\n            "
              ),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(3),
        ]),
        _vm._v(" "),
        _c("article", { staticClass: "uk-article uk-margin-xlarge-top" }, [
          _c(
            "h2",
            {
              ref: "article-4",
              staticClass:
                "uk-article-title uk-margin-medium-bottom title-and-icon",
            },
            [
              _vm._v("\n            Cos'è la Virtual Venue?\n            "),
              _c(
                "a",
                {
                  staticClass:
                    "tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon",
                  attrs: { "data-uk-totop": "" },
                  on: {
                    click: function ($event) {
                      return _vm.scrollMeTo("section-1")
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "20",
                        viewBox: "0 0 18 20",
                        xmlns: "http://www.w3.org/2000/svg",
                        ratio: "1",
                      },
                    },
                    [
                      _c("polyline", {
                        attrs: {
                          fill: "none",
                          stroke: "#000",
                          "stroke-width": "1.2",
                          points: "1 9 9 1 17 9 ",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm._m(4),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("span", { attrs: { href: "/helpdesk/it-virtualroom" } }, [
        _vm._v("\n              Virtual Room e Webinar\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("video", { attrs: { width: "100%", controls: "" } }, [
      _c("source", {
        attrs: {
          src: "https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/come_creare_una_landing_personalizzata.mp4",
          type: "video/mp4",
        },
      }),
      _vm._v(
        "\n            Your browser does not support the video tag.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("video", { attrs: { width: "100%", controls: "" } }, [
      _c("source", {
        attrs: {
          src: "https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/programmare_una_room.mp4",
          type: "video/mp4",
        },
      }),
      _vm._v(
        "\n            Your browser does not support the video tag.\n          "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            Il modello Webinar è costituito da tre tipi diversi di ruoli:"
      ),
      _c("br"),
      _vm._v(
        "\n            - Moderatore: può moderare la chat all'interno del Webinar, può avviare, mettere in pausa e terminare il\n            Webinar\n            e può infine richiedere l'attivazione di, o rimuovere, webcam e microfono ai Presenters e Guests."
      ),
      _c("br"),
      _vm._v(
        "\n            -Presenter: può attivare e disattivare in autonomia la propria webcam e il proprio microfono, deve accedere\n            inserendo il pin, anche prima dell'effettivo inizio del webinar."
      ),
      _c("br"),
      _vm._v(
        '\n            -Guest: entra con microfono e webcam spenta, può accedere solo dopo l\'inizio ufficiale del Webinar. Il Guest\n            può\n            "salire sul palco" attivando webcam e microfono solo se invitato da un moderatore.'
      ),
      _c("br"),
      _vm._v(
        "\n            Il Webinar può ospitare fino a 250 guests e fino a 3 tra presenters e moderatori. "
      ),
      _c("br"),
      _vm._v(
        "\n            - assicurarsi che i browser utilizzati abbiano il consenso per accedere a webcam e microfono."
      ),
      _c("br"),
      _vm._v(
        "\n            - Entrate prima come Moderatori e Presenter inserendo i pin indicati nel dashboard. Dopodichè selezionate\n            microfono e telecamera e accedete al webinar."
      ),
      _c("br"),
      _vm._v(
        "\n            - N.B. una volta selezionata la webcam e il microfono il webinar potrebbe metterci tra i 30 e 60 secondi ad\n            avviarsi."
      ),
      _c("br"),
      _vm._v(
        "\n            - Quando sarete pronti a far partire il webinar cliccate sul tasto play così gli ospiti potranno accedere\n            all'interno webinar."
      ),
      _c("br"),
      _vm._v(
        '\n            - Quando l\'evento sarà terminato, cliccate sul bottone "End Call" che terminerà manualmente il webinar. Il\n            webinar si chiuderà automaticamente al termine della durata impostata in fase di programmazione.\n          '
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n            La virtual venue è una landing page esterna, non creata su Penguinpass. Inserendo il link nell'apposito\n            spazio all'interno della sezione Virtual room, i vostri ospiti dopo aver fatto RSVP e aver confermato\n            vedranno un bottone \"Accedi alla Virtual Venue\" per accedere alla pagina collegata."
      ),
      _c("br"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }