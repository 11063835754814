var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advance-settings-component-container accordion" },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v("\n      " + _vm._s(_vm.string.promoteEvent._title) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-container accordion-content" }, [
        _c("small", [_vm._v(_vm._s(_vm.string.promoteEvent._subtitle))]),
        _vm._v(" "),
        _vm.componentDisabled
          ? _c(
              "div",
              {
                staticClass: "disable-layer",
                on: { click: _vm.disableLayerClicked },
              },
              [_vm._v("\n \n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.inAction
          ? _c("div", { staticClass: "inaction-layer" }, [
              _vm._v("\n \n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "inMailToggleSelection" }, [
          _c("div", { staticClass: "form-section squaredThree" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              attrs: { id: "promote", type: "checkbox", name: "promote" },
              domProps: {
                checked: Array.isArray(_vm.inputValue)
                  ? _vm._i(_vm.inputValue, null) > -1
                  : _vm.inputValue,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("label", {
              staticClass: "promote-event",
              attrs: { for: "promote" },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "textLabel" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.string.promoteEvent._label) +
                "\n        "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-section" }, [
          _c(
            "button",
            {
              staticClass: "save",
              attrs: { type: "submit" },
              on: { click: _vm.submit },
            },
            [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.string.promoteEvent._submit) +
                  "\n        "
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }