var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "custom-cb-control custom-cb-control-checkbox" },
    [
      _c("input", {
        attrs: { id: _vm.customId, type: "checkbox" },
        domProps: { checked: _vm.shouldBeChecked, value: _vm.value },
        on: { change: _vm.updateInput },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "custom-cb-control-indicator" }),
      _vm._v(" "),
      _c("label", { attrs: { for: _vm.customId } }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }