var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PublicLayout", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row wow fadeIn" }, [
        _c("div", { staticClass: "col-md-7" }, [
          _c(
            "div",
            {
              staticClass: "heading heading-md heading-uppercase heading-hover",
            },
            [
              _c("br"),
              _vm._v(" "),
              _c(
                "h2",
                {
                  staticClass: "heading-title text-white",
                  attrs: { id: "title" },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.verificationResult._failure_title) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("hr", { staticClass: "hr-width-1 hr-5x border-main" }),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "heading-tescription lead text-gray-2",
                  attrs: { id: "description" },
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.string.verificationResult._failure_message) +
                      "\n              "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "mailto:info@penguinpass.it",
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n              info@penguinpass.it\n              "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }