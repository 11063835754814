<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Email Campaign
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/en-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/en-campagneemail">
              Email Campaign
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to create a new email and / or template
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to send an email campaign 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to filter the receivers of your email campaign 
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to activate the RSVP and DECLINE buttons
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to generate a QR when a guest RSVPs
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to request additional information from your guests
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to set up timeslots that can be chosen by your guests through the invitation email
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              How to keep the risk of spam low
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to create a new email and / or template
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            In the "email" section click on the "create new email" button, you will be able to access the section where you must:
            <br>
            - Fill in the fields "name (sender), email (sender)", these will be visible in the emails that the guests will receive.<br>
            - Create the email by clicking on the "create mail" button (or "edit email" if one has already been saved previously) <br>
            Through the EMAIL BUILDER, you will be able to create your own personalized invitation by inserting modules and content. 
            Once built, a template can also be saved for future use (by clicking on "Actions" and then "save as template"). After 
            creating the invitation and after clicking on "save" you will need to enter the subject of the email that your guests will receive.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to send an email campaign 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            After having finished the organization phase of the event, it’s now time to invite your guests. To do this, 
            it’s sufficient to enter the “email” section, create the email to send to all guests through the button 
            “create email” and then save it. Once this is done, the dashboard will show a preview of the email which 
            will be sent. Under the preview, there will be 2 checkboxes that, if activated, will allow the receiver of 
            the email to insert the number of plus 1s and their info. <br>
            To send the email campaign, it will be necessary to select through the filters which guests you would like 
            to send the email to (for example all guests, only those who have confirmed, only those who still haven’t 
            received an invitation, etc…). Once selected the guests for the email campaign, press the “save changes'' 
            button and after the “send campaign” button.<br>
            Once the campaign is sent, it will be possible to download a report of the status of the emails (check out GUEST LISTS, 
            “How to download RSVP and check-in reports”).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to filter the receivers of your email campaign 
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Before sending the email campaign by clicking on "send email" you can filter the recipients by:<br>
            - Selecting "pending guests" to send the invitation only to those who have not confirmed or declined<br>
            - Selecting "confirmed guests" to select those who have already accepted the invitation<br>
            - Selecting "guests who have not read the previous campaigns" for those who have received past emails 
            but have not opened them<br>
            - Selecting “guests not included in previous campaigns” to select those who have not yet received any previous email<br>
            - Selecting "use custom fields" to filter guests based on the extra fields in the list (for example Company)
            <br>
            In addition to the filters, you can manually select the guests that need to receive the email campaign by using the check-boxes 
            on the right in each row of the guestlist.
            To use the “guests who have not read the previous campaigns" filter, it is necessary to wait for the "mail status" to be updated. 
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to activate the RSVP and DECLINE buttons
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            In order to create a correct email and giving the possibility for your guests to confirm their presence, you will 
            have to use the appropriate buttons and their "Special links”. <br><br>
            This can be done in two ways:<br>
            USING BUTTONS<br>
            The guest will receive an email with two buttons, one for accepting the inviate and one to declining it. To do this you need to insert in the email then two buttons dragging the "button content" into an empty "form". Once the button is inserted it must be selected and then you must click on the "special links" item and choose in one of the two buttons the "RSVP" link (so when the guest presses on it he will confirm his presence) and on the other one the "decline" link (by clicking on it the guest will indicate his refusal).
            <br><br>
            USING A SPECIAL LINK<br>
            If you want your guests to confirm their presence by pressing on a particular portion of text, just place in the mail a "text content" in an empty "form". Choose the part of text to be used as a confirmation link for RSVP or DECLINE, selecti it and press on "special links" in the bar word work (which opens when editing and / or interacts with the body of the text) and finally select whether to connect an RSVP (by clicking on the guest will confirm his presence) or a decline (clicking on it the guest will indicate his refusal).
            <br>
            In the phase of adding a special link, if a specific part of the text is chosen to be the special link to rsvp, the selected text will itself become the link (eg, if I highlight the word "I accept" and I connect the link, it will become "accepted").
            <br>
            If instead you do not highlight a particular piece of text and select the special link then the system will automatically add the link “SpecialLink to send a RSVP (do not modify the url) "on which the guests must then click.
            <br><br>
            <!--<video width="100%" controls>
              <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4"
                      type="video/mp4">
              Your browser does not support the video tag.
            </video>-->
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to generate a QR when a guest RSVPs
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            If you want to manage guest access to the event through a QR code, you will need to send a confirmation 
            email containing the QR Code needed to access the event. When creating a confirmation email, in the "content" 
            section of the email builder, along with everything else you want to include in the email (for example info 
            and event recap), add a "dynamic content" in an empty module.<br>
            In doing so, the guests will receive, after 3-10 minutes from the moment of confirmation, an email containing 
            the QR Code.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to request additional information from your guests
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            To request additional information from your guests, you can use the custom fields, by going to the settings 
            tab in the event section. In event settings, you will see the "Change Custom Fields" section at the bottom 
            of the list. There you will be able to see the extra fields you have inserted on the guestlist template when 
            uploading your guest list and you will also have the possibility to create new ones. 
            Under the existing custom fields, you will see three checkboxes, by checking the box next to "RSVP" your 
            guests will be able to fill in the additional data directly from the invitation email when clicking on the 
            button containing the special RSVP link.
            <br>
            By selecting the “Mandatory” box next to "RSVP "you can also make it a mandatory field. The information you fill 
            in will automatically appear in your event's guest list.<br>
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            How to set up timeslots that can be chosen by your guests through the invitation email
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            You can find "Time slots" in the settings tab of your event. By clicking on it, it will allow 
            you to upload .ics files to generate time slots that will then be selected by your guests from the RSVP email.<br>
            .ics files are standard format files for calendars, which can be exported from applications such as Google calendar 
            or Yahoo calendar. After uploading the file, a table will appear with all the time slots present in the file. Above the 
            table, you can set the maximum limit of people who will be able to select every single slot.<br>
            Your guests will find the slots after clicking the RSVP button in the invitation email, on the additional information 
            page, you will find the time slots selected by the different guests from the columns of your guest list. Finally, you 
            can download the .ics file with all the bookings, from the "Export your bookings as .ics" button that you will find in the 
            time slots section.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            ​​How to keep the risk of spam low
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Here are some tips to reduce the risk of your email campaigns being classified as spam by mail servers. First let's 
            start with the ranking, which is one of the parameters of the various mail programs and servers to manage messages. 
            Luckily, Penguinpass has a score of 89 out of 100 which is very high. <br><br>

            1. If email servers detect words like events, save the date, rsvp, etc. i.e. they can automatically classify the 
            mails as "promotions" and insert them in the relative folder on Gmail. Therefore we recommend avoiding using such 
            words in the subject of your campaign.<br><br>

            2. We recommend that the email address (the sender) does not have words such as events, marketing, etc. for example 
            events@client.com.<br><br>

            3.  If you can, we recommend that you verify your domain instead of the single email address.<br><br>

            4. Try to ensure that the emails sent do not have only an image (the invitation) but also not only "Dear name + surname" as 
            text. It is very important to use text and images in a balanced way since email softwares use algorithms to classify emails 
            and use the relationship between text and images as a criterion. Some text is needed. The higher the proportion of text, the 
            lower the risk of your campaign being classified as "spam" or "promotion".<br><br>

            5.  Image weight: this is a very important factor. A lower image weight allows for a faster recognition by email servers and 
            is less likely to be detected as spam. Ideally, you would include in your campaign images/invitations between 50kb and 150kb.<br><br>

            6. It is advisable to write in lowercase, or at least not all in capital letters. Writing in block letters in the subject 
            increases by 10% the probability of being classified as "spam".<br><br>

            7. One last suggestion is to send a separate email (not with Penguinpass) to those who haven’t opened or RSVP, which in addition 
            to being a reminder for the event, also invites them to check spam or other folders for any e-mails received from you. Once the user, 
            at your request, has moved the email away from the spam/promotion folder, the mail system understands that the next time the email 
            should not end up in those folders.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskCampagneEmailEN",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
