var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("section-title", {
        attrs: {
          title: _vm.$root.strings.organizerSettings._customCategoriesHeader,
          subtitle:
            _vm.$root.strings.organizerSettings._customCategoriesSubtitle,
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "loader-section",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: { "loading-cover-msg": _vm.loadingMessage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            id: "add-new-identity",
            name: "component-fade-fast",
            mode: "out-in",
            appear: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "team-custom-fields-manager advance-settings-component-container",
            },
            [
              _c("button-tab", {
                staticStyle: { "margin-bottom": "30px" },
                attrs: { tabs: _vm.tabs, "initial-tab": _vm.selectedTab },
                on: { tabChanged: _vm.onTabChanged },
              }),
              _vm._v(" "),
              _vm.selectedTab === "SHOW_CATEGORY_FORM"
                ? _c("div", { staticClass: "component-form" }, [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.trim",
                            value: _vm.newCustomCategory,
                            expression: "newCustomCategory",
                            modifiers: { trim: true },
                          },
                        ],
                        staticClass: "form-control",
                        staticStyle: { width: "100%" },
                        attrs: {
                          "aria-label": "",
                          type: "text",
                          name: "policy_name",
                          placeholder:
                            _vm.$root.strings.customCategories._label,
                        },
                        domProps: { value: _vm.newCustomCategory },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newCustomCategory = $event.target.value.trim()
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-section submit" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn gray-btn save",
                          attrs: { type: "button", name: "submit" },
                          on: { click: _vm.preCreateCategory },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$root.strings.customCategories._submit
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm.selectedTab === "SHOW_CATEGORY_TABLE"
                ? _c(
                    "div",
                    { staticClass: "custom-fields-list" },
                    [
                      _c("data-table", {
                        ref: "table",
                        attrs: {
                          "get-data": _vm.getData,
                          "filter-enabled": false,
                          "show-pagination": false,
                          columns: _vm.columns,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _vm.rowInEdit === row.key
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.editItem.label,
                                          expression: "editItem.label",
                                          modifiers: { trim: true },
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: { value: _vm.editItem.label },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            _vm.editItem,
                                            "label",
                                            $event.target.value.trim()
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    })
                                  : _c("div", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.label) +
                                          "\n            "
                                      ),
                                    ]),
                              ])
                            },
                          },
                          {
                            key: "deletedAt",
                            fn: function (ref) {
                              var row = ref.row
                              return _c("span", {}, [
                                _c("div", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        row.deletedAt
                                          ? "" +
                                              new Date(row.deletedAt).format()
                                          : "-"
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ])
                            },
                          },
                          {
                            key: "Actions",
                            fn: function (ref) {
                              var row = ref.row
                              return _c(
                                "span",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  !row.deletedAt
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "action_link",
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            title:
                                              _vm.rowInEdit === row.key
                                                ? "save changes"
                                                : "open editor",
                                          },
                                          on: {
                                            click: function () {
                                              return _vm.rowInEdit === row.key
                                                ? _vm.saveRow(row)
                                                : _vm.openRow(row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class:
                                              _vm.rowInEdit === row.key
                                                ? "fa fa-check"
                                                : "fa fa-pencil",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !row.deletedAt
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "action_link",
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: {
                                            title:
                                              _vm.rowInEdit === row.key
                                                ? "discard changes"
                                                : "remove option",
                                          },
                                          on: {
                                            click: function () {
                                              _vm.rowInEdit === row.key
                                                ? _vm.closeRow(row)
                                                : _vm.preDeleteCategory(row)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            class:
                                              _vm.rowInEdit === row.key
                                                ? "fa fa-times"
                                                : "fa fa-trash",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  row.deletedAt
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "action_link",
                                          staticStyle: {
                                            "margin-right": "5px",
                                          },
                                          attrs: { title: "enable option" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.undoDeleteCustomCategory(
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_c("i", { staticClass: "fa fa-undo" })]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }