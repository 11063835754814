<template>
  <div id="email-builder-select-mode-container">
    <div class="Aligner-item">
      <div class="row">
        <div class="col-md-6 item">
          <div
            class="item-content"
            @click="selectTemplate"
          >
            <div class="icon-item">
              <img
                src="../../../assets/img/dashboard/emailbuilder/template.png"
                alt="List Templates">
            </div>
            <div class="title-item">
              {{ string.emailBuilderSelectMode._selectTemplateTitle }}
            </div>
            <div class="subtitle-item">
              {{ string.emailBuilderSelectMode._selectTemplateText }}
            </div>
          </div>
        </div>

        <div class="col-md-6 item">
          <div
            class="item-content"
            @click="newTemplate"
          >
            <div class="icon-item">
              <img
                src="../../../assets/img/dashboard/emailbuilder/new.png"
                alt="New Template">
            </div>
            <div class="title-item">
              {{ string.emailBuilderSelectMode._new }}
            </div>
            <div class="subtitle-item">
              {{ string.emailBuilderSelectMode._newText }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EmailBuilderSelectMode',
    props: {
      canCreateTemplate: {
        type: Boolean,
        default: true
      }
    },
    data () {
        return {
            string: {},
        };
    },
    created (){
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    mounted (){
        //
    },
    methods: {
        newTemplate: function () {
            if (this.canCreateTemplate) {
              this.$emit('newTemplate');
            } else {
              this.$root.bus.emit(
                "simpleToast",
                `You have reached your email template limit. Please contact to us for more or reuse your existing templates`
              );
            }
        },
        selectTemplate: function () {
            this.$emit('selectTemplate');
        }
    }

};
</script>

<style lang="less" scoped>
    * {
        color: #ffffff;
    }

    .item:hover {
        background-color: #333;
        cursor: pointer;
    }

    .item .title-item {
        font-size: 22px;
    }

    .item {
        text-align: center;
        text-transform: uppercase;
    }

    .item img {
        margin-bottom: 13px;
    }

    #email-builder-select-mode-container{
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .Aligner-item {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .Aligner-item--top {
        align-self: flex-start;
    }

    .Aligner-item--bottom {
        align-self: flex-end;
    }

</style>
