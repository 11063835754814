var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal fade in",
        staticStyle: { display: "block" },
        attrs: {
          id: "modalPageBuilder",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "myModalLabel",
          "aria-hidden": "false",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-hidden": "true" },
                  on: { click: _vm.fadeout },
                },
                [_vm._v("\n            ×\n          ")]
              ),
              _vm._v(" "),
              _c(
                "h4",
                { staticClass: "modal-title", attrs: { id: "myModalLabel" } },
                [
                  _vm._v(
                    "\n            PENGUINPASS VIRTUAL ROOM BUILDER\n          "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.templateSaving === true
                      ? _c("div", { attrs: { id: "template_save_area" } }, [
                          _c(
                            "div",
                            { attrs: { id: "template-save-command-area" } },
                            [
                              _vm.templateSaving === true
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      attrs: { id: "email-template-save" },
                                      on: { click: _vm.saveAsTemplateApi },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.string.modalEmailBuilder
                                              ._confirm
                                          ) +
                                          "\n                "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-default",
                                  attrs: { id: "email-template-cancel" },
                                  on: { click: _vm.templateSavingAbort },
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.string.modalEmailBuilder._cancel
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast-email-builder",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.pageBuilder
                      ? _c("div", { attrs: { id: "bee-plugin-container" } })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modalBackgroundColor",
            on: { click: _vm.warnThenClose },
          },
          [_vm._v("\n       \n    ")]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }