var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row list-single-item" }, [
    _c("h4", [_vm._v(_vm._s(_vm.string.listSingleItem._title))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-12 col-md-12 col-lg-12" },
      [
        _c(
          "transition",
          {
            attrs: { name: "component-fade-fast", mode: "out-in", appear: "" },
          },
          [
            _vm.loadingEffectshow
              ? _c("LoadingCover", {
                  attrs: {
                    "loading-cover-msg":
                      _vm.string.listSingleItem._loadingMessage,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "form",
          {
            staticClass: "form-horizontal style-form",
            attrs: { autocomplete: "off" },
          },
          [
            _c(
              "div",
              { staticClass: "form-group no-margin-bottom nomarginleft" },
              [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model:value",
                        value: _vm.privateGuestName,
                        expression: "privateGuestName",
                        arg: "value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputPrivateName,
                      autocomplete: "new-password",
                      "aria-label": "",
                      type: "text",
                      placeholder: _vm.string.listSingleItem._name,
                    },
                    domProps: { value: _vm.privateGuestName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.privateGuestName = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model:value",
                        value: _vm.privateGuestSurname,
                        expression: "privateGuestSurname",
                        arg: "value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputPrivateSurname,
                      autocomplete: "new-password",
                      "aria-label": "",
                      type: "text",
                      placeholder: _vm.string.listSingleItem._surname,
                    },
                    domProps: { value: _vm.privateGuestSurname },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.privateGuestSurname = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group no-margin-bottom nomarginleft" },
              [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model:value",
                        value: _vm.privateGuestEmail,
                        expression: "privateGuestEmail",
                        arg: "value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputPrivateEmail,
                      autocomplete: "new-password",
                      "aria-label": "",
                      type: "email",
                      placeholder: _vm.string.listSingleItem._email,
                    },
                    domProps: { value: _vm.privateGuestEmail },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.privateGuestEmail = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model:value",
                        value: _vm.privateGuestTel,
                        expression: "privateGuestTel",
                        arg: "value",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: _vm.inputPrivateTel,
                      autocomplete: "new-password",
                      "aria-label": "",
                      type: "text",
                      placeholder: _vm.string.listSingleItem._tel,
                    },
                    domProps: { value: _vm.privateGuestTel },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.privateGuestTel = $event.target.value
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "formbutton" }, [
              _c(
                "a",
                {
                  staticClass: "btn gray-btn save",
                  attrs: { title: "avanti" },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.string.listSingleItem._save) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }