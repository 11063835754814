<template>
  <div class="advance-settings-component-container accordion">
    <h4 class="form-section-title first accordion-toggle">
      {{ string.showOnApp._title }}
    </h4>
    <div class="form-container accordion-content">
      <!--      <small>{{ string.attendeeMaxLimit._subtitle }}</small>-->
      <el-row>
        <el-col :span="1">
          <custom-checkbox v-model="event.showOnApp" @change="toggleShowOnApp" />
        </el-col>
        <el-col :span="23" class="">
          <p style="margin: 0; padding: 0">
            {{ string.showOnApp._description }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1">
          <custom-checkbox v-model="event.noPassword" @change="toggleNoPassword" />
        </el-col>
        <el-col :span="23" class="">
          <p style="margin: 0; padding: 0">
            {{ string.showOnApp._no_password }}
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style scoped>
.external-puchase-component {
  margin-bottom: 30px;
}

/** Disable Classes */

.form-container {
  position: relative;
}

.disable-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  background-color: #00000000;
}

/** /Disable Classes */

/** In Action Layer */
.inaction-layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
/** In Action Layer */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
</style>
<script>
import CustomCheckbox from "@/components/ui/CustomCheckbox.vue";

import { UPDATE_EVENT } from "@/graphql";

export default {
  components: {
    CustomCheckbox,
  },
  props: {
    event: Object,
  },
  data() {
    return {
      componentName: "showOnApp",
      inAction: false,
      string: {},
    };
  },
  watch: {
    event: function () {
      try {
        this.inputValue = this.event.maxGuests;
      } catch (e) {
        // Nothing
      }
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    toggleShowOnApp: function () {
      console.log(this.event);
      const context = this;
      this.inAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              showOnApp: this.event.showOnApp,
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            context.$root.bus.emit("simpleToast", this.string.organizerSettings._settingsUpdated);
            this.inAction = false;
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
    toggleNoPassword: function () {
      const context = this;
      this.inAction = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_EVENT,
          variables: {
            eventId: this.event.id,
            updateEventInput: {
              noPassword: this.event.noPassword,
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors);
            context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
          } else {
            context.$root.bus.emit("simpleToast", this.string.organizerSettings._settingsUpdated);
            this.inAction = false;
          }
        })
        .catch((reason) => {
          console.log(reason.message);
          context.$root.bus.emit("simpleToast", this.string.eventPad._creationFailed);
        });
    },
  },
};
</script>
