var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "default-layout" }, [
    _c(
      "div",
      { staticClass: "virtualroom-component", attrs: { id: "virtualroom" } },
      [
        _vm.loadingEffectshow
          ? _c(
              "div",
              { staticClass: "higherPosition" },
              [
                _c(
                  "transition",
                  {
                    attrs: {
                      name: "component-fade-fast",
                      mode: "out-in",
                      appear: "",
                    },
                  },
                  [
                    _vm.loadingEffectshow
                      ? _c("LoadingCover", {
                          attrs: {
                            "loading-cover-msg":
                              _vm.string.virtualRoomSettings._loadingMessage,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "vroom accordion" }, [
          _c(
            "h4",
            { staticClass: "form-section-title second accordion-toggle" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.string.virtualRoomSettings._title) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "accordionContent", staticClass: "accordion-content" },
            [
              !_vm.isExhibitor
                ? _c("div", { staticClass: "vroomToggleSelection" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("small", { staticClass: "col-sm-12 text-left" }, [
                          _vm._v(
                            "\n              Virtual venue\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.virtualVenue,
                              expression: "virtualVenue",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            placeHolder: "Custom Virtual Room URL",
                          },
                          domProps: { value: _vm.virtualVenue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.virtualVenue = $event.target.value
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "label",
                          { staticClass: "control control-checkbox" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.string.virtualRoomSettings._customizeroom
                                ) +
                                "\n                "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.showEditor,
                                  expression: "showEditor",
                                },
                              ],
                              attrs: { id: "checkbox", type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.showEditor)
                                  ? _vm._i(_vm.showEditor, null) > -1
                                  : _vm.showEditor,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.showEditor,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.showEditor = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.showEditor = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.showEditor = $$c
                                    }
                                  },
                                  _vm.toggleCustomizeVirtualRoom,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "control_indicator" }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _vm.pages &&
                        _vm.pages[_vm.selectedPageIndex] &&
                        _vm.features &&
                        _vm.features.pageAuthRequired
                          ? _c(
                              "label",
                              { staticClass: "control control-checkbox" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.string.virtualRoomSettings._authneeded
                                    ) +
                                    "\n                "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.pages[_vm.selectedPageIndex]
                                          .authNeeded,
                                      expression:
                                        "pages[selectedPageIndex].authNeeded",
                                    },
                                  ],
                                  attrs: { id: "checkbox", type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.pages[_vm.selectedPageIndex]
                                        .authNeeded
                                    )
                                      ? _vm._i(
                                          _vm.pages[_vm.selectedPageIndex]
                                            .authNeeded,
                                          null
                                        ) > -1
                                      : _vm.pages[_vm.selectedPageIndex]
                                          .authNeeded,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.pages[_vm.selectedPageIndex]
                                              .authNeeded,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.pages[
                                                  _vm.selectedPageIndex
                                                ],
                                                "authNeeded",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.pages[
                                                  _vm.selectedPageIndex
                                                ],
                                                "authNeeded",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.pages[_vm.selectedPageIndex],
                                            "authNeeded",
                                            $$c
                                          )
                                        }
                                      },
                                      _vm.toggleAuthNeeded,
                                    ],
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "control_indicator" }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.pages &&
                        _vm.pages[_vm.selectedPageIndex] &&
                        _vm.features &&
                        _vm.features.pageModeratedChat
                          ? _c(
                              "label",
                              { staticClass: "control control-checkbox" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.string.virtualRoomSettings
                                        ._moderatedchat
                                    ) +
                                    "\n                "
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.pages[_vm.selectedPageIndex]
                                          .roomConfig.chat.moderated,
                                      expression:
                                        "pages[selectedPageIndex].roomConfig.chat.moderated",
                                    },
                                  ],
                                  attrs: { id: "checkbox", type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.pages[_vm.selectedPageIndex]
                                        .roomConfig.chat.moderated
                                    )
                                      ? _vm._i(
                                          _vm.pages[_vm.selectedPageIndex]
                                            .roomConfig.chat.moderated,
                                          null
                                        ) > -1
                                      : _vm.pages[_vm.selectedPageIndex]
                                          .roomConfig.chat.moderated,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.pages[_vm.selectedPageIndex]
                                              .roomConfig.chat.moderated,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.pages[_vm.selectedPageIndex]
                                                  .roomConfig.chat,
                                                "moderated",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.pages[_vm.selectedPageIndex]
                                                  .roomConfig.chat,
                                                "moderated",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.pages[_vm.selectedPageIndex]
                                              .roomConfig.chat,
                                            "moderated",
                                            $$c
                                          )
                                        }
                                      },
                                      _vm.toggleModeratedChat,
                                    ],
                                  },
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "control_indicator" }),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "vroom-message-intent" }, [
                _vm.showEditor && !_vm.isExhibitor
                  ? _c("div", { staticClass: "email-settings" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.LoadTemplateBuilder },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings._createroom
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.LoadTemplateSelector },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings
                                      ._selectTemplate
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c(
                            "small",
                            { staticClass: "text-left has-subtitle" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings
                                      ._roomscheduling
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-left subtitle" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.string.virtualRoomSettings
                                    ._roomschedulingsubtitle
                                ) +
                                "\n                "
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-3" },
                          [
                            _c("el-date-picker", {
                              staticClass: "form-control",
                              attrs: {
                                id: "startDate",
                                type: "date",
                                align: "left",
                                size: "large",
                                placeholder: "Start date",
                                format: "dd/MM/yyyy",
                                "value-format": "dd/MM/yyyy",
                              },
                              on: { change: _vm.onDateSelected },
                              model: {
                                value: _vm.virtualRoomStartDate,
                                callback: function ($$v) {
                                  _vm.virtualRoomStartDate = $$v
                                },
                                expression: "virtualRoomStartDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-3" },
                          [
                            _c("el-time-select", {
                              staticClass: "form-control",
                              attrs: {
                                "popper-class": "virtual-room-timeselect",
                                "picker-options": {
                                  start: "08:30",
                                  step: "00:05",
                                  end: "19:30",
                                },
                                placeholder: "Start time",
                              },
                              model: {
                                value: _vm.virtualRoomStartTime,
                                callback: function ($$v) {
                                  _vm.virtualRoomStartTime = $$v
                                },
                                expression: "virtualRoomStartTime",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-3" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "form-control",
                                attrs: { placeholder: "Duration" },
                                model: {
                                  value: _vm.virtualRoomDuration,
                                  callback: function ($$v) {
                                    _vm.virtualRoomDuration = $$v
                                  },
                                  expression: "virtualRoomDuration",
                                },
                              },
                              _vm._l(_vm.durationList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-sm-3" },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "form-control",
                                attrs: {
                                  filterable: "",
                                  placeholder: "Timezone",
                                },
                                model: {
                                  value: _vm.virtualRoomTimezone,
                                  callback: function ($$v) {
                                    _vm.virtualRoomTimezone = $$v
                                  },
                                  expression: "virtualRoomTimezone",
                                },
                              },
                              _vm._l(_vm.timezonesList, function (item, index) {
                                return _c("el-option", {
                                  key: "timezoneIndx" + index,
                                  attrs: {
                                    label: item.text,
                                    value: "timezoneIndx" + index,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.instantMeeting },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings
                                      ._instantmeeting
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.openFileSelection },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings._uploadCover
                                  ) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            ref: "fileinputVROOM",
                            attrs: {
                              id: "fileinput",
                              type: "file",
                              accept: "image/*",
                            },
                            on: { change: _vm.onFileChange },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.allocatedVirtualRooms.length > 0
                  ? _c("div", { staticClass: "data-grid-container" }, [
                      _c("div", { staticClass: "data-grid-table-container" }, [
                        _c("table", [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("Locked")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("VirtualRoom")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Moderator PIN")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Presenter PIN")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Room Status")]),
                              _vm._v(" "),
                              _c("th", [_vm._v("Call Status")]),
                              _vm._v(" "),
                              _vm.isExhibitor
                                ? _c("th", [_vm._v("Go to room")])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              _vm._l(
                                _vm.allocatedVirtualRooms,
                                function (allocatedRoom) {
                                  return _c("tr", [
                                    _c(
                                      "td",
                                      [
                                        allocatedRoom.locked
                                          ? _c("el-button", {
                                              attrs: {
                                                icon: "el-icon-lock",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleRoomLock(
                                                    allocatedRoom.id,
                                                    false
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !allocatedRoom.locked
                                          ? _c("el-button", {
                                              attrs: {
                                                icon: "el-icon-unlock",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleRoomLock(
                                                    allocatedRoom.id,
                                                    true
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoRoomsById[
                                            allocatedRoom.videoRoomId
                                          ].customSessionId
                                        ) +
                                          " (" +
                                          _vm._s(
                                            _vm.videoRoomsById[
                                              allocatedRoom.videoRoomId
                                            ].type
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(allocatedRoom.moderatorPin)
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoRoomsById[
                                            allocatedRoom.videoRoomId
                                          ].type !== "MEETING" ||
                                            _vm.videoRoomsById[
                                              allocatedRoom.videoRoomId
                                            ].type !== "VIRTUALSHOWROOM"
                                            ? allocatedRoom.publisherPin
                                            : ""
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.videoRoomStatusEnumType[
                                            allocatedRoom.status
                                          ]
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _vm.isExhibitor &&
                                        _vm.videoCallStatusEnumTypeArray[
                                          allocatedRoom.callStatus
                                        ]
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm
                                                    .videoCallStatusEnumTypeArray[
                                                    allocatedRoom.callStatus
                                                  ]
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    allocatedRoom.callStatus
                                                  )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.isExhibitor
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "Select",
                                                  disabled:
                                                    allocatedRoom.callStatus ===
                                                      "ENDED" ||
                                                    allocatedRoom.status ===
                                                      "TO_BE_CREATED",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.updateRoomCallStatus(
                                                      allocatedRoom.id,
                                                      allocatedRoom.callStatus
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    allocatedRoom.callStatus,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      allocatedRoom,
                                                      "callStatus",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "allocatedRoom.callStatus",
                                                },
                                              },
                                              _vm._l(
                                                _vm.videoCallStatusEnumTypeArray,
                                                function (item) {
                                                  return _c("el-option", {
                                                    key: item.key,
                                                    attrs: {
                                                      label: item.value,
                                                      value: item.key,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.isExhibitor
                                      ? _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://virtual.events.penguinpass.it/vr3/index.html#/" +
                                                  allocatedRoom.id,
                                                target: "_blank",
                                              },
                                            },
                                            [_vm._v("Open room")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                }
                              ),
                              _vm._v(" "),
                              !_vm.isExhibitor
                                ? _c("tr", [
                                    _c("td", { attrs: { colspan: "6" } }, [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.virtualRoomLink,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.virtualRoomLink))]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.allocatedVirtualRooms.length === 0 && _vm.virtualRoomLink
                  ? _c("div", { staticClass: "data-grid-container" }, [
                      _c("div", { staticClass: "data-grid-table-container" }, [
                        _c("table", [
                          _vm._m(1),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            [
                              !_vm.isExhibitor
                                ? _c("tr", [
                                    _c("td", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.virtualRoomLink,
                                            target: "_blank",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.virtualRoomLink))]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isExhibitor
                                ? _vm._l(
                                    _vm.allocatedVirtualRooms,
                                    function (allocatedVirtualRoom) {
                                      return _c("tr", [
                                        _c("td", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  "https://virtual.events.penguinpass.it/vr3/index.html#/" +
                                                  allocatedVirtualRoom.id,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  allocatedVirtualRoom.sessionId
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ])
                                    }
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isExhibitor
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c("small", { staticClass: "col-sm-12 text-left" }, [
                          _vm._v(
                            "\n              Contact Email\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.contactEmail,
                              expression: "contactEmail",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text", placeHolder: "Contact Email" },
                          domProps: { value: _vm.contactEmail },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.contactEmail = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            on: { click: _vm.saveContactEmail },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.string.virtualRoomSettings._savesettings
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "btn",
                            on: { click: _vm.downloadFairboothLogs },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.string.virtualRoomSettings._downloadlogs
                                ) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              !_vm.isExhibitor
                ? _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-sm-6" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn",
                          on: { click: _vm.saveVirtualRoomSettings },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.string.virtualRoomSettings._savesettings
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-6" }, [
                      _vm.pages[_vm.selectedPageIndex] &&
                      _vm.pages[_vm.selectedPageIndex].pageStatus === "DRAFT"
                        ? _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.toggleEnableVirtualRoom },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings._publishpage
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pages[_vm.selectedPageIndex] &&
                      _vm.pages[_vm.selectedPageIndex].pageStatus ===
                        "PUBLISHED"
                        ? _c(
                            "a",
                            {
                              staticClass: "btn",
                              on: { click: _vm.toggleEnableVirtualRoom },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.string.virtualRoomSettings
                                      ._unpublishpage
                                  ) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("small", { staticClass: "text-left" }, [
          _vm._v("\n                OR\n                "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [_c("tr", [_c("th", [_vm._v("Virtual Room URL")])])])
  },
]
render._withStripped = true

export { render, staticRenderFns }