var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.inputValue,
        expression: "inputValue",
      },
    ],
    class: _vm.attrClass,
    attrs: { id: _vm.id, type: "text", placeholder: _vm.placeholderText },
    domProps: { value: _vm.inputValue },
    on: {
      input: function ($event) {
        if ($event.target.composing) {
          return
        }
        _vm.inputValue = $event.target.value
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }