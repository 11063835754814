<template>    
  <input
    :id="id"
    v-model="inputValue"
    :class="attrClass"
    type="text"
    :placeholder="placeholderText" />
  <!-- Debug
         <p>Address: {{ myInstanceAddress.address }}</p>
         <p>City: {{ myInstanceAddress.city }}</p>
         <p>State: {{ myInstanceAddress.state }}</p>
         <p>ZipCode: {{ myInstanceAddress.zipcode }}</p>
         <p>Country: {{ myInstanceAddress.country }}</p>
         <p>Lat: {{ myInstanceAddress.lat }}</p>
         <p>Lng: {{ myInstanceAddress.lng }}</p>
         <p>Sentinella: {{ sentinella }}</p>
         -->
<!--  <VueGoogleAutocomplete-->
<!--    :id="id"-->
<!--    :class="attrClass"-->
<!--    type="text"-->
<!--    :placeholder="placeholderText"-->
<!--    @placechanged="getAddressData"-->
<!--  >-->
<!--  </VueGoogleAutocomplete>-->
</template>
<script>
// import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default {
    components:{
        // VueGoogleAutocomplete
    },
    props: {
        id: String,
        placeHolder: String,
        attrClass: String,
        modelValue: String,
    },
    data (){
        return {
            placeholderText: '',
            googleAutoCompleteInput: this.id,
            googleAddress: {
                street_number: null,
                street_name: null,
                city: null,
                state: null,
                zipcode: null,
                country: null,
                url: null,
                lat:null,
                lng:null,
                formattedAddress:null,
                autocomplete: null
            },
            inputElement: '',
            inputValue: '',
            myInstanceAddress: {},
            sentinella:'',
            count:0,
            address: ''
  
        };
    },
    watch: {
        inputValue: function (){
            //console.log("trig_address_components"); // Debug
            this.getAddressComponents();

            if (!(this.inputValue.length > 0)){
                this.setUpGoogle();
                this.reNewVariables();
            }
        }
    },
    mounted (){
        this.init();

        // modelValue eredità della proprietà esterna
        if (this.modelValue.length > 0){
            this.inputValue = this.modelValue;
        }
    },
    methods: {
        init: function (){
            // get placeholder from the props
            this.placeholderText = this.placeHolder;

            //get DOM input element where users will start typing addresses
            this.inputElement = document.getElementById(this.googleAutoCompleteInput);
            this.inputElement.placeholder = this.placeholderText;

            // set up google autocomplete
            this.setUpGoogle();
        },
        setUpGoogle: function (){
        //create new google maps object
            if (google){
                this.googleAddress.autocomplete = new google.maps.places.Autocomplete(this.inputElement, { types: [ 'geocode' ] });
                //add event listener to trigger method getAddressComponents when user select an address
                this.googleAddress.autocomplete.addListener('place_changed', this.getAddressComponents);
            }
        },
        reNewVariables: function () {
            this.googleAddress.street_number = '';
            this.googleAddress.street_name = '';
            this.googleAddress.city = '';
            this.googleAddress.state = '';
            this.googleAddress.zipcode = '';
            this.googleAddress.country = '';
            this.googleAddress.lat = '';
            this.googleAddress.lng = '';
            this.googleAddress.formattedAddress = '';
            this.googleAddress.url = '';
        },
        getAddressComponents: function () {
        // Get the place details from the autocomplete object.
            const place = this.googleAddress.autocomplete.getPlace();

            this.reNewVariables();

            if (typeof place != 'undefined' && typeof place.address_components != 'undefined'){

                //console.log("place");
                //console.log(place);

                this.googleAddress.street_name = place.name;
                // Get each component of the address from the place details
                for (let i = 0; i < place.address_components.length; i++) {

                    const addressType = place.address_components[i].types[0];

                    switch (addressType) {

                        case 'street_number':
                            this.googleAddress.street_number = place.address_components[i]['short_name'];
                            break;
                        case 'route':
                            if (this.googleAddress.street_name === ''){
                                this.googleAddress.street_name = place.address_components[i]['short_name'];
                            }
                            break;
                        case 'locality':
                            this.googleAddress.city = place.address_components[i]['long_name'];
                            break;
                        case 'administrative_area_level_1':
                            this.googleAddress.state = place.address_components[i]['short_name'];
                            break;
                        case 'postal_code':
                            this.googleAddress.zipcode = place.address_components[i]['short_name'];
                            break;
                        case 'country':
                            this.googleAddress.country = place.address_components[i]['short_name'];
                            break;
                    }
                }

                this.googleAddress.lat = place.geometry.location.lat();
                this.googleAddress.lng = place.geometry.location.lng();
                this.googleAddress.formattedAddress = place.formatted_address;
                this.googleAddress.url = place.url;

            }

            //call instance or component method. it will be used to update its addresses properties
            this.updateAddress();

        },
        updateAddress: function (){
        //assign required values to my instance property
            this.count++;
            const street_number = (this.googleAddress.street_number == null) ? '' : this.googleAddress.street_number;

            this.myInstanceAddress.address = this.googleAddress.street_name;
            this.myInstanceAddress.street_number = street_number;
            this.myInstanceAddress.city = this.googleAddress.city;
            this.myInstanceAddress.state = this.googleAddress.state;
            this.myInstanceAddress.postalcode = this.googleAddress.zipcode;
            this.myInstanceAddress.country = this.googleAddress.country;
            this.myInstanceAddress.lat = this.googleAddress.lat;
            this.myInstanceAddress.lng = this.googleAddress.lng;
            this.myInstanceAddress.formattedAddress = this.googleAddress.formattedAddress;
            if (this.myInstanceAddress.address === ''){
            //this.myInstanceAddress.address = this.googleAddress.formattedAddress;
                this.myInstanceAddress.city = ' ';//this.googleAddress.formattedAddress.split(",")[0];
            }
            this.myInstanceAddress.inputValue = this.inputValue;
            this.sentinella = 'test' + this.count;

            //event - emit
            this.$emit('place-changed', this.myInstanceAddress); /** Emit di evento al padre */
        }

    }
};
</script>
