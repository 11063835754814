<template>
  <div>
    <!-- <h4 class="form-section-title first">{{string.organizerSettings._domainHeader}}</h4> -->
    <h4 class="form-section-title first">
      {{ string.organizerSettings._domainHeader }}
      <small>{{ string.organizerSettings._recapSubtitle }}</small>
    </h4>
    <div class="smallseparator"></div>
    <transition id="loader-section" name="component-fade-fast" mode="out-in" appear>
      <LoadingCover v-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />
    </transition>
    <transition id="add-new-identity" name="component-fade-fast" mode="out-in" appear>
      <section class="add-identity-section">
        <h4 class="form-section-title">{{ string.organizerSettings._select_identity_method }}</h4>

        <div hidden style="color: white;">
          {{ listenStoreChanges }}
        </div>
        <el-tooltip
          effect="dark"
          :disabled="emailIdentities.length <= 0 && domainIdentityTabs.length <= 0"
          :content="string.organizerSettings._remove_existing"
          placement="right-end"
        >
          <el-select
            v-model="identityType"
            :disabled="emailIdentities.length > 0 || domainIdentityTabs.length > 0"
            popper-class="default-popper"
            clearable
            size="large"
            :placeholder="string.organizerSettings._selectIdentityType"
            @change="
              e => {
                onOptionChanged(e);
              }
            "
          >
            <el-option
              v-for="item in identityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            >
            </el-option>
          </el-select>
          <span v-if="updatingOption">
            <loading-icon />
          </span>
        </el-tooltip>

        <br />
        <div v-if="identityType === 'DOMAIN'">
          <h4 class="form-section-title">{{ string.organizerSettings._newDomainSection }}</h4>
          <input
            type="text"
            class="form-control"
            :placeholder="string.organizerSettings._domain"
            v-model="inputDomainIdentity"
          />
          <a
            id="add-identity-form-submit-domain"
            class="settings-btn-area btn gray-btn btn-primary"
            v-on:click.prevent="addNewDomain()"
          >
            {{ string.organizerSettings._add_new_domain }}
          </a>
        </div>

        <div v-if="identityType === 'EMAIL'">
          <h4 class="form-section-title">{{ string.organizerSettings._newEmailSection }}</h4>
          <input
            type="text"
            class="form-control"
            :placeholder="string.organizerSettings._email"
            v-model="inputEmailIdentity"
          />
          <button
            id="add-identity-form-submit-domain"
            class="settings-btn-area btn btn-primary"
            v-on:click.prevent="addNewEmail()"
          >
            <span class="save-btn">{{ string.organizerSettings._add_new_email }}</span>
          </button>
        </div>
      </section>
    </transition>
    <transition id="email-identities" name="component-fade-fast" mode="out-in" appear>
      <section
        class="identity-section"
        v-if="identityType === 'EMAIL' && emailIdentities.length > 0"
      >
        <h4 class="form-section-title first">
          {{ string.organizerSettings._email_recap }}
          <small>{{ string.organizerSettings._email_recap_subtitle }}</small>
        </h4>
        <div class="smallseparator"></div>
        <transition name="component-fade-fast" mode="out-in" appear>
          <template v-if="emailIdentities.length">
            <div class="refresh-stats-button">
              <a class="settings" v-on:click.prevent="handleUpdateIdentities()">
                <i
                  aria-hidden="true"
                  class="fa fa-refresh"
                  :class="!!updatingEmailIdentities ? 'rotate-center' : ''"
                />
                {{ string.organizerSettings._refreshIdentities }}
              </a>
            </div>
          </template>
        </transition>

        <transition name="component-fade-fast" mode="out-in" appear>
          <div
            v-if="identityType === 'EMAIL'"
            :class="!!updatingEmailIdentities ? 'datatable no-data' : ''"
          >
            <template v-if="!!updatingEmailIdentities">
              <div class="data-grid-container-status-message">
                {{ string.organizerSettings._updatingIdentities }}
              </div>
            </template>
            <template v-if="!updatingEmailIdentities">
              <DataGridReport
                :data="emailIdentities"
                :columns="emailIdentityColumns"
                :enable-actions="true"
                @deleteIdentity="deleteIdentity"
              />
            </template>
            <template v-else-if="!updatingEmailIdentities">
              <div class="data-grid-container-status-message">
                {{ string.organizerSettings._no_data }}
              </div>
            </template>
          </div>
        </transition>
      </section>
    </transition>
    <transition id="domain-identities" name="component-fade-fast" mode="out-in" appear>
      <el-tabs type="border-card" v-if="identityType === 'DOMAIN' && domainIdentityTabs.length > 0">
        <el-tab-pane v-for="domain of domainIdentityTabs" v-bind:key="domain" :label="domain">
          <section class="identity-section">
            <h4 class="form-section-title first">
              <small style="text-transform: none;"
                >{{ string.organizerSettings._domain_desc_1 }}
                <a v-on:click.prevent="setShowMore(!showMore)" class="show-more">{{
                  !showMore
                    ? string.organizerSettings._read_more
                    : string.organizerSettings._read_less
                }}</a></small
              >
              <div v-if="showMore">
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_2
                }}</small>
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_3
                }}</small>
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_4
                }}</small>
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_5
                }}</small>
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_6
                }}</small>
                <small style="text-transform: none;">{{
                  string.organizerSettings._domain_desc_7
                }}</small>
              </div>
            </h4>
            <el-steps style="padding:10px;" :space="'33.33%'" align-center>
              <el-step
                :title="`${string.organizerSettings._step} 1`"
                :description="string.organizerSettings._step_1"
              ></el-step>
              <el-step
                :title="`${string.organizerSettings._step} 2`"
                :description="string.organizerSettings._step_2"
              ></el-step>
              <el-step
                :title="`${string.organizerSettings._step} 3`"
                :description="string.organizerSettings._step_3"
              ></el-step>
            </el-steps>
            <transition name="component-fade-fast" mode="out-in" appear>
              <template
                v-if="
                  identityType === 'DOMAIN' &&
                    domainIdentityContents[domain] &&
                    domainIdentityContents[domain].length
                "
              >
                <div class="refresh-stats-button">
                  <a class="settings" v-on:click.prevent="handleUpdateIdentities()">
                    <i
                      aria-hidden="true"
                      class="fa fa-refresh"
                      :class="!!updatingDomainIdentities ? 'rotate-center' : ''"
                    />
                    {{ string.organizerSettings._refreshIdentities }}
                  </a>
                </div>
              </template>
            </transition>
            <transition name="component-fade-fast" mode="out-in" appear>
              <div
                v-if="identityType === 'DOMAIN'"
                :class="!!updatingDomainIdentities ? 'datatable no-data' : ''"
              >
                <template v-if="!!updatingDomainIdentities">
                  <div class="data-grid-container-status-message">
                    {{ string.organizerSettings._updatingIdentities }}
                  </div>
                </template>
                <template
                  v-else-if="
                    domainIdentityContents[domain] &&
                      domainIdentityContents[domain].length &&
                      !updatingDomainIdentities
                  "
                >
                  <DataGridReport
                    :data="domainIdentityContents[domain]"
                    :columns="domainIdentityColumns"
                    :enable-actions="false"
                  />
                </template>
                <template v-else>
                  <div class="data-grid-container-status-message">
                    {{ string.organizerSettings._no_data }}
                  </div>
                </template>
              </div>
            </transition>
            <div
              class="row guestList_links data"
              style="padding: 10px 0px;"
              v-if="domainIdentityContents[domain] && domainIdentityContents[domain].length > 0"
            >
              <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0;">
                <div class="downloads_style">
                  <i class="fa fa-cloud-download" aria-hidden="true" />
                  <vue-excel-xlsx
                    class="linkStyle"
                    :data="domainIdentityContents[domain]"
                    :columns="domainIdentitiesReportColumns"
                    filename="dns_records"
                    sheetname="Sheet 1"
                  >
                    {{ string.organizerSettings._download_record_set }}
                  </vue-excel-xlsx>
                </div>
                <div class="downloads_style" style="margin-top: 10px">
                  <el-switch
                    v-model="mailFromEnabled[domain]"
                    :active-text="
                      (mailFromEnabled[domain] ? 'Deactivate' : 'Activate') +
                        ' custom MAIL FROM option (Optional but recommended if possible)'
                    "
                    active-color="#f7a30c"
                    v-on:change="
                      e => {
                        toggleMailFrom(e, domain);
                      }
                    "
                  >
                  </el-switch>
                </div>
              </div>
            </div>

            <div v-if="mailFromEnabled[domain]" style="margin-top: 40px; margin-bottom: 10px;">
              <h4 class="form-section-title first" style="margin-bot: 20px">
                <small style="text-transform: none;"
                  >{{ string.organizerSettings._mail_from_desc_1 }}
                  <a
                    v-on:click.prevent="setShowMoreMailFrom(!showMoreMailFrom)"
                    class="show-more"
                    >{{
                      !showMoreMailFrom
                        ? string.organizerSettings._read_more
                        : string.organizerSettings._read_less
                    }}</a
                  ></small
                >
                <div v-if="showMoreMailFrom">
                  <small style="text-transform: none;">{{
                    string.organizerSettings._mail_from_desc_2
                  }}</small>
                  <small style="text-transform: none;">{{
                    string.organizerSettings._mail_from_desc_3
                  }}</small>
                </div>
              </h4>
              <transition name="component-fade-fast" mode="out-in" appear>
                <template
                  v-if="
                    mailFromEnabled[domain] &&
                      mailFromContents[domain] &&
                      mailFromContents[domain].length
                  "
                >
                  <div class="refresh-stats-button">
                    <a class="settings" v-on:click.prevent="handleUpdateIdentities()">
                      <i
                        aria-hidden="true"
                        class="fa fa-refresh"
                        :class="!!updatingDomainIdentities ? 'rotate-center' : ''"
                      />
                      {{ string.organizerSettings._refreshIdentities }}
                    </a>
                  </div>
                </template>
              </transition>
              <transition name="component-fade-fast" mode="out-in" appear>
                <div
                  v-if="mailFromContents[domain] && mailFromContents[domain].length"
                  :class="!!updatingDomainIdentities ? 'datatable no-data' : ''"
                >
                  <template v-if="!!updatingDomainIdentities">
                    <div class="data-grid-container-status-message">
                      {{ string.organizerSettings._updatingIdentities }}
                    </div>
                  </template>
                  <template
                    v-else-if="
                      mailFromContents[domain] &&
                        mailFromContents[domain].length &&
                        !updatingDomainIdentities
                    "
                  >
                    <DataGridReport
                      :data="mailFromContents[domain]"
                      :columns="domainIdentityColumns"
                      :enable-actions="false"
                    />
                  </template>
                  <template v-else>
                    <div class="data-grid-container-status-message">
                      {{ string.organizerSettings._no_data }}
                    </div>
                  </template>
                </div>
              </transition>
              <div
                class="row guestList_links data"
                style="padding: 10px 0px;"
                v-if="mailFromContents[domain].length > 0"
              >
                <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0;">
                  <div class="downloads_style">
                    <i class="fa fa-cloud-download" aria-hidden="true" />
                    <vue-excel-xlsx
                      class="linkStyle"
                      :data="mailFromContents[domain]"
                      :columns="domainIdentitiesReportColumns"
                      filename="mail_from_dns_records"
                      sheetname="Sheet 1"
                    >
                      {{ string.organizerSettings._download_record_set }}
                    </vue-excel-xlsx>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row guestList_links data"
              style="padding: 40px 0px;"
              v-if="domainIdentityContents[domain] && domainIdentityContents[domain].length > 0"
            >
              <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0;">
                <el-button
                  class="delete-domain"
                  v-on:click.prevent="deleteDomainIdentity(domain)"
                  >{{ string.organizerSettings._deleteDomain }}</el-button
                >
              </div>
            </div>
          </section>
        </el-tab-pane>
      </el-tabs>
    </transition>
  </div>
</template>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../../assets/css/bootstrap-toggle.css";
@import "../../assets/css/customsidebar.css";
@import "../../assets/css/formstyle.css";
@import "../../assets/css/preloader.css";
@import "../../assets/less/style.css";
@import "../../assets/less/createevent.css";
@import "../../assets/less/settings.css";


#loadingCover {
  background-color: rgba(107, 104, 102, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingCover .loadingCoverMessage {
  width: auto;
  text-transform: uppercase;
  font-size: 30px;
}
.refresh-stats-button {
  margin-bottom: 15px;
}
a.settings {
  cursor: pointer;
  color: #f7a30c !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.settings:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
.pagination > li {
  color: white;
}
#select-identities {
  color: white;
}
option:disabled.settings-option {
  color: white;
}
.panel-body .smallseparator {
  margin-top: -10px;
}
.dark-layer {
  background-color: rgba(0, 7, 10, 0.8);
}

.data-grid-container tbody {
  font-size: 15px !important;
}

.row.tabs-content h4 {
  text-align: center;
}

.list-upload a {
  text-align: center !important;
}

.table {
  margin-top: 50px;
  font-size: 14px;
}

.data {
  font-size: 20px;
}

.no-data {
  padding: 0 30px 30px;
  text-align: center;
  font-size: 40px;
  text-transform: uppercase;
  color: #e8e8e8;
}

button.btn,
a.btn {
  cursor: pointer;
  display: block;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  border: 0 !important;
  margin: 10px 0 30px 0;
  font-family: inherit;
  font-weight: 400;
}

.datatable-container {
  margin-top: 30px;
}

.btn:hover {
  background-color: #dadada !important;
  color: #333;
  cursor: pointer;
  border: 0 !important;
}

small.textSmall {
  font-size: 12px;
}
.downloads_style {
  padding-left: 15px;
}
.downloads_style > i {
  padding-right: 15px;
}
.linkStyle {
  display: inline;
  cursor: pointer;
}
button.linkStyle {
  background: transparent !important;
  border: transparent !important;
  cursor: pointer;
  padding: 1px 0;
}
.row.guestList_links > div > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.row.guestList_links > button > .downloads_style > .linkStyle:hover {
  color: #f7a30c;
  text-decoration: underline;
}
.downloads_style > div > a:hover {
  color: #f7a30c !important;
}
.add-identity-section {
  margin: 15px 15px 30px 15px;
  padding: 10px;
  max-width: 500px;
  /* border: 1px solid #a8a8a8; */
  border-radius: 5px;
}

.identity-section {
  margin: 15px;
  padding: 0 0 20px 0;
}
button.delete-domain {
  border: 1px solid #f7a30c !important;
  background: grey;
  color: #f7a30c;
}
</style>
<script>
import LoadingCover from "@/components/LoadingCover.vue";
import DataGridReport from "@/components/DataGridReport";
import LoadingIcon from "@/components/LoadingIcon.vue";

import {
  CREATE_IDENTITY,
  DELETE_IDENTITY,
  MY_IDENTITIES,
  MY_TEAM,
  UPDATE_MY_TEAM,
  UPDATE_IDENTITIES,
  TOOGLE_MAIL_FROM
} from "@/graphql";

export default {
  components: {
    DataGridReport,
    LoadingCover,
    LoadingIcon
  },
  data() {
    return {
      name: "IdentitySettings",
      string: {},
      identityType: "",
      inputEmailIdentity: "",
      emailIdentities: [],

      inputDomainIdentity: "",
      domainIdentityContents: [],
      domainIdentityTabs: [],
      mailFromEnabled: [],
      mailFromContents: [],

      updatingEmailIdentities: false,
      updatingDomainIdentities: false,
      updatingOption: false,
      gridFilterKey: "",
      emailIdentityColumns: [
        { key: "status", label: "Status" },
        { key: "email", label: "Email" },
        { key: "updatedAt", label: "Last Update" }
      ],
      domainIdentityColumns: [
        { key: "status", label: "Status" },
        { key: "type", label: "type" },
        { key: "name", label: "name" },
        { key: "value", label: "value" },
        { key: "updatedAt", label: "Last Update" }
      ],
      domainIdentitiesReportColumns: [
        {
          label: "Record Name",
          field: "name"
        },
        {
          label: "Record Type",
          field: "type"
        },
        {
          label: "Record Value",
          field: "value"
        }
      ],
      identityOptions: [
        {
          value: "DEFAULT",
          label: "Default (No verification)",
          disabled: true,
          hidden: true
        },
        {
          value: "EMAIL",
          label: "Email"
        },
        {
          value: "DOMAIN",
          label: "Domain"
        }
      ],
      loadingEffectshow: false,
      loadingMessage: "",
      showMore: false,
      showMoreMailFrom: false,
      beforePageCloseListener: null
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });

    this.beforePageCloseListener = this.$root.bus.addListener("beforePageClose", () => {
      if (context.getIdentityOption === "EMAIL") {
        if (!context.emailIdentities || context.emailIdentities.length < 1) {
          context.onOptionChanged("DEFAULT", true);
        }
      } else if (context.getIdentityOption === "DOMAIN") {
        if (!context.domainIdentityTabs || context.domainIdentityTabs.length < 1) {
          context.onOptionChanged("DEFAULT", true);
        }
      }
    });
  },
  beforeDestroy() {
    try {
      if (this.beforePageCloseListener) {
        this.beforePageCloseListener.remove();
        this.beforePageCloseListener = null;
      }
    } catch {}
  },
  computed: {
    listenStoreChanges: function() {
      // just to update identity after store changes in other async functions

      this.identityType = this.$store.getters.identity;
      return this.$store.getters.identity;
    },
    getIdentityOption() {
      return this.$store.getters.identity;
    }
  },
  watch: {
    identityType: function(newVal, oldVal) {
      this.emailIdentities = [];
      this.domainIdentityContents = [];
      this.domainIdentityTabs = [];

      if (newVal === "EMAIL") {
        this.getEmailIdentities();
      } else if (newVal === "DOMAIN") {
        this.mailFromEnabled = [];
        this.getDomainIdentities();
      }
    }
  },
  methods: {
    setShowMore: function(showMore) {
      this.showMore = showMore;
    },
    setShowMoreMailFrom: function(showMoreMailFrom) {
      this.showMoreMailFrom = showMoreMailFrom;
    },
    myTeam: function() {
      let context = this;
      context.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_TEAM
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myTeam) {
            const payload = response.data.myTeam;
            this.identityType = payload.identityOption;
          }
        });
    },
    onOptionChanged: function(newValue, hideMessage) {
      let context = this;
      if (this.canUpdate()) {
        context.updatingOption = true;
        this.$apollo
          .mutate({
            mutation: UPDATE_MY_TEAM,
            variables: {
              updates: {
                identityOption: newValue || "DEFAULT"
              }
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              context.updatingOption = false;
              return;
            }
            if (response.data && response.data.updateMyTeam) {
              context.$store.commit("SET_TEAM_FIELD", { identityOption: newValue });
              if (!hideMessage) {
                context.$root.bus.emit("simpleToast", "Updated");
              }
              context.updatingOption = false;
            }
          })
          .catch(error => {
            console.log(error);
            context.updatingOption = false;
          });
      }
    },
    canUpdate: function() {
      return true;
    },
    getEmailIdentities: function() {
      let context = this;
      if (!context.updatingEmailIdentities) context.loadingEffectshow = true;
      context.loadingMessage = "";
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_IDENTITIES,
          variables: {
            identityType: "EMAIL"
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            context.loadingEffectshow = false;
            context.updatingEmailIdentities = false;
            context.emailIdentities = payload;
          }
        })
        .catch(error => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    getDomainIdentities: function() {
      let context = this;
      if (!context.updatingDomainIdentities) context.loadingEffectshow = true;
      context.loadingMessage = "";
      context.domainIdentityContents = [];
      context.domainIdentityTabs = [];
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_IDENTITIES,
          variables: {
            identityType: "DOMAIN"
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.myMarketingIdentities) {
            const payload = response.data.myMarketingIdentities;
            context.loadingEffectshow = false;
            context.updatingDomainIdentities = false;
            for (const domain of payload) {
              let output = [];
              context.domainIdentityTabs = [domain.identity, ...context.domainIdentityTabs];
              let row1 = {};
              row1.verificationStatus = domain.verificationStatus;
              row1.type = "TXT";
              row1.name = "_amazonses." + domain.identity;
              row1.value = domain.verificationToken;
              row1.updatedAt = domain.updatedAt;
              output = [...output, row1];

              for (const dkimToken of domain.dkimTokens) {
                let row = {};
                row.verificationStatus = domain.dkimVerificationStatus;
                row.type = "CNAME";
                row.name = dkimToken + "._domainkey." + domain.identity;
                row.value = dkimToken + ".dkim.amazonses.com";
                row.updatedAt = domain.updatedAt;
                output = [...output, row];
              }
              let mailFromOutput = [];
              if (domain.mailFromEnabled) {
                let mxRow = {};
                mxRow.verificationStatus = domain.mailFromStatus;
                mxRow.type = "MX";
                mxRow.name = domain.mailFromDomain;
                mxRow.value = "10 feedback-smtp.eu-west-1.amazonses.com";
                mxRow.updatedAt = domain.updatedAt;
                mailFromOutput = [...mailFromOutput, mxRow];

                let spfRow = {};
                spfRow.verificationStatus = domain.mailFromStatus;
                spfRow.type = "TXT";
                spfRow.name = domain.mailFromDomain;
                spfRow.value = '"v=spf1 include:amazonses.com ~all"';
                spfRow.updatedAt = domain.updatedAt;
                mailFromOutput = [...mailFromOutput, spfRow];
              }
              console.log(`${domain.identity} mail from enabled: ${domain.mailFromEnabled}`);
              context.mailFromEnabled[domain.identity] = domain.mailFromEnabled;
              context.mailFromContents[domain.identity] = mailFromOutput;

              context.domainIdentityContents[domain.identity] = output;
            }
          }
        })
        .catch(error => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    handleUpdateIdentities: function() {
      let context = this;
      context.updatingEmailIdentities = true;
      context.updatingDomainIdentities = true;
      return this.$apollo
        .mutate({
          mutation: UPDATE_IDENTITIES
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.updatingEmailIdentities = false;
            context.updatingDomainIdentities = false;
            return;
          }
          if (response.data && response.data.updateMarketingIdentities) {
            if (context.getIdentityOption === "EMAIL") {
              context.getEmailIdentities();
            } else {
              context.mailFromEnabled = [];
              context.getDomainIdentities();
            }
          }
        })
        .catch(error => {
          console.log(error);
          context.updatingEmailIdentities = false;
          context.updatingDomainIdentities = false;
        });
    },
    addNewEmail: function() {
      const context = this;
      context.loadingEffectshow = true;
      context.loadingMessage = context.inputEmailIdentity;
      return this.$apollo
        .mutate({
          mutation: CREATE_IDENTITY,
          variables: {
            identityInput: {
              identity: context.inputEmailIdentity,
              identityType: context.identityType
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.createMarketingIdentity) {
            const payload = response.data.createMarketingIdentity;
            context.emailIdentities = [payload, ...context.emailIdentities];
            context.loadingEffectshow = false;
            context.inputEmailIdentity = "";
          }
        })
        .catch(error => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            let message = null;
            if (error.graphQLErrors[0].message === "EMAIL_EXISTS") {
              message = context.string.organizerSettings._email_verification_exists;
            } else if (error.graphQLErrors[0].message === "EMAIL_NOT_VALID") {
              message = context.string.organizerSettings._email_syntax_error;
            } else if (error.graphQLErrors[0].message === "DOMAIN_OF_EMAIL_EXIST") {
              message = context.string.organizerSettings._email_domain_verification_exists;
            }

            if (message) {
              context.$root.bus.emit("longerToast", message);
            } else {
              console.log(error);
            }
          } else {
            console.log(error);
          }
          context.loadingEffectshow = false;
        });
    },
    addNewDomain: function() {
      const context = this;
      context.loadingEffectshow = true;
      context.loadingMessage = context.inputDomainIdentity;
      return this.$apollo
        .mutate({
          mutation: CREATE_IDENTITY,
          variables: {
            identityInput: {
              identity: context.inputDomainIdentity,
              identityType: context.identityType
            }
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.createMarketingIdentity) {
            const payload = response.data.createMarketingIdentity;
            context.loadingEffectshow = false;
            context.mailFromEnabled = [];
            context.getDomainIdentities();
            context.inputDomainIdentity = "";
          }
        })
        .catch(error => {
          if (error.graphQLErrors && error.graphQLErrors.length) {
            let message = null;
            if (error.graphQLErrors[0].message === "DOMAIN_EXISTS") {
              message = context.string.organizerSettings._domain_exists;
            } else if (error.graphQLErrors[0].message === "DOMAIN_NOT_VALID") {
              message = context.string.organizerSettings._domain_syntax_error;
            }

            if (message) {
              context.$root.bus.emit("longerToast", message);
            } else {
              console.log(error);
            }
          } else {
            console.log(error);
          }
          context.loadingEffectshow = false;
        });
    },
    deleteIdentity: function(data) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: DELETE_IDENTITY,
          variables: {
            identity: data.identity
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.deleteMarketingIdentity) {
            context.getEmailIdentities();
          }
        })
        .catch(error => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
    deleteDomainIdentity: function(domain) {
      const context = this;
      this.$confirm(this.string.organizerSettings._warn_before_delete_domain)
        .then(_ => {
          context.loadingEffectshow = true;
          return this.$apollo
            .mutate({
              mutation: DELETE_IDENTITY,
              variables: {
                identity: domain
              }
            })
            .then(response => {
              if (response.errors) {
                console.log(response.errors);
                context.loadingEffectshow = false;
                return;
              }
              if (response.data && response.data.deleteMarketingIdentity) {
                context.mailFromEnabled = [];
                context.getDomainIdentities();
              }
            })
            .catch(error => {
              console.log(error);
              context.loadingEffectshow = false;
            });
        })
        .catch(_ => {});
    },
    toggleMailFrom: function(newValue, identity) {
      const context = this;
      context.loadingEffectshow = true;
      return this.$apollo
        .mutate({
          mutation: TOOGLE_MAIL_FROM,
          variables: {
            enable: newValue,
            identity: identity
          }
        })
        .then(response => {
          if (response.errors) {
            console.log(response.errors);
            context.loadingEffectshow = false;
            return;
          }
          if (response.data && response.data.toggleMailFrom === "SUCCESS") {
            context.getDomainIdentities();
          } else {
            context.$root.bus.emit("simpleToast", "Operation unsuccessful");
          }
        })
        .catch(error => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    }
  }
};
</script>
