<template>
  <div>
    <section-title
      :title="$root.strings.organizerSettings._eventGroupTitle"
      :subtitle="$root.strings.organizerSettings._eventGroupSubtitle"
    />
    <transition id="loader-section" name="component-fade-fast" mode="out-in" appear>
      <LoadingCover v-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />
    </transition>
    <transition id="add-new-identity" name="component-fade-fast" mode="out-in" appear>
      <div class="team-custom-fields-manager advance-settings-component-container">
        <button-tab
          style="margin-bottom: 30px"
          :tabs="tabs"
          :initial-tab="selectedTab"
          @tabChanged="onTabChanged"
        />
        <div v-if="selectedTab === 'SHOW_EVENT_GROUP_FORM'" class="component-form">
          <el-row
            :gutter="30"
            class="form-field-row zero-margin-row"
            style="display: flex; margin-bottom: 10px"
          >
            <el-col :span="6" style="align-self: start; padding: 10px !important">
              <span> {{ $root.strings.eventGroup._groupName }} </span>
            </el-col>
            <el-col :span="18">
              <custom-input
                v-model.trim="newGroupName"
                type="text"
                :placeholder="$root.strings.eventGroup._newGroup"
                style="width: 100%"
              />
            </el-col>
          </el-row>
          <el-row
            :gutter="30"
            class="form-field-row zero-margin-row"
            style="display: flex; margin-bottom: 10px"
          >
            <el-col :span="6" style="align-self: start; padding: 10px !important">
              <span> {{ $root.strings.eventGroup._addEvent }} </span>
            </el-col>
            <el-col :span="18">
              <el-row
                v-for="selectedEvent in selectedEvents"
                :key="selectedEvent.id"
                :gutter="20"
                class="form-field-row zero-margin-row"
                style="display: flex; flex-direction: row; margin-bottom: 10px"
              >
                <el-col :span="20">
                  <el-input v-model="selectedEvent.name" readonly class="input-group-control">
                    <div
                      slot="suffix"
                      style="display: flex; height: 100%; align-items: center; margin-right: 5px"
                    >
                      <i
                        v-if="selectedEvent.isEventGroupController"
                        class="el-icon-check el-input__icon"
                        style="color: #ff9c00; margin-right: 3px"
                      />
                      <a
                        :style="itemStyle(selectedEvent)"
                        @click="() => toggleControllerEvent(selectedEvent)"
                      >
                        {{
                          selectedEvent.isEventGroupController
                            ? $root.strings.eventGroup._markedAsController
                            : $root.strings.eventGroup._markAsController
                        }}
                      </a>
                    </div>
                  </el-input>
                </el-col>
                <el-col :span="4">
                  <a
                    type="button"
                    class="full-column-button btn gray-btn save"
                    @click="() => removeNewEvent(selectedEvent)"
                  >
                    {{ $root.strings.eventGroup._removeFromGroup }}
                  </a>
                </el-col>
              </el-row>
              <div>
                <el-autocomplete
                  v-model.trim="addEventFilter"
                  class="input-group-control"
                  popper-class="default-popper"
                  size="large"
                  type="text"
                  :fetch-suggestions="findEvents"
                  :debounce="500"
                  :placeholder="$root.strings.eventGroup._searchEvents"
                  style="text-align: start; width: 100%"
                  @select="addNewEvent"
                >
                  <i slot="prefix" class="el-icon-plus el-input__icon"> </i>
                  <i slot="suffix" class="el-icon-arrow-down el-input__icon"> </i>
                  <template slot-scope="{ item }">
                    <el-row
                      :gutter="10"
                      class="form-field-row zero-margin-row"
                      style="margin-bottom: 5px"
                    >
                      <el-col :span="16">
                        <span class="value" style="flex: 1">
                          {{ item.name }}
                        </span>
                      </el-col>
                      <el-col :span="4">
                        <span class="link">
                          {{ item.genre.formatEnum() }}
                        </span>
                      </el-col>
                      <el-col :span="4">
                        <span class="link">
                          {{ new Date(item.startDate).format() }}
                        </span>
                      </el-col>
                    </el-row>
                  </template>
                </el-autocomplete>
              </div>
            </el-col>
          </el-row>
          <div class="form-section submit">
            <a class="btn gray-btn save" @click="() => preCreateEventGroup()">
              {{ $root.strings.eventGroup._submit }}
            </a>
          </div>
        </div>
        <div v-else-if="selectedTab === 'SHOW_EVENT_GROUP_TABLE'" class="custom-fields-list">
          <data-table
            ref="table"
            :get-data="getData"
            :filter-enabled="false"
            :show-pagination="false"
            :columns="columns"
            :span-method="groupRows"
            :row-class-name="rowClassName"
          >
            <span slot="eventGroupId" slot-scope="{ row }">
              <div>
                {{ row.eventGroupId }}
              </div>
            </span>
            <span slot="name" slot-scope="{ row }">
              <div>
                {{ row.name }}
              </div>
            </span>
            <span slot="isEventGroupController" slot-scope="{ row }">
              <div v-if="row.isEventGroupController">
                <i class="el-icon-check el-input__icon" style="color: #ff9c00; margin-right: 3px" />
                <span style="text-decoration: none; color: #ff9c00">
                  {{ $root.strings.eventGroup._markedAsController }}
                </span>
              </div>
              <div v-else></div>
            </span>
            <span slot="createdAt" slot-scope="{ row }">
              <div>
                {{ row.createdAt ? `${new Date(row.createdAt).format()}` : "-" }}
              </div>
            </span>
            <span slot="Actions" slot-scope="{ row }" style="text-align: center">
              <a
                title="open editor"
                class="action_link"
                style="margin-right: 5px"
                @click="() => openRow(row)"
              >
                <i class="fa fa-pencil"></i>
              </a>
              <a
                title="remove option"
                class="action_link"
                style="margin-right: 5px"
                @click="() => preDeleteGroup(row)"
              >
                <i class="fa fa-trash"></i>
              </a>
            </span>
          </data-table>
          <el-dialog
            :visible.sync="editItem.visible"
            width="90vw"
            custom-class="default-popper"
            append-to-body
          >
            <span slot="title" class="dialog-title" style="text-transform: uppercase">
              {{ $root.strings.eventGroup._editGroup }}
            </span>
            <el-row
              :gutter="30"
              class="form-field-row zero-margin-row"
              style="display: flex; margin-bottom: 10px; color: white"
            >
              <el-col :span="4" style="align-self: start; padding: 10px !important">
                <span> {{ $root.strings.eventGroup._groupName }} </span>
              </el-col>
              <el-col :span="20">
                <custom-input
                  v-model.trim="editItem.eventGroupId"
                  type="text"
                  :placeholder="$root.strings.eventGroup._newGroup"
                  style="width: 100%"
                />
              </el-col>
            </el-row>
            <el-row
              :gutter="30"
              class="form-field-row zero-margin-row"
              style="display: flex; margin-bottom: 10px; color: white"
            >
              <el-col :span="4" style="align-self: start; padding: 10px !important">
                <span>{{ $root.strings.eventGroup._addEvent }} </span>
              </el-col>
              <el-col :span="20">
                <el-row
                  v-for="selectedEvent in editItem.selectedEvents"
                  :key="selectedEvent.id"
                  :gutter="20"
                  class="form-field-row zero-margin-row"
                  style="display: flex; flex-direction: row; margin-bottom: 10px"
                >
                  <el-col :span="20">
                    <el-input v-model="selectedEvent.name" readonly class="input-group-control">
                      <div
                        slot="suffix"
                        style="display: flex; height: 100%; align-items: center; margin-right: 5px"
                      >
                        <i
                          v-if="selectedEvent.isEventGroupController"
                          class="el-icon-check el-input__icon"
                          style="color: #ff9c00; margin-right: 3px"
                        />
                        <a
                          :style="itemStyle(selectedEvent)"
                          @click="() => toggleControllerEvent(selectedEvent)"
                        >
                          {{
                            selectedEvent.isEventGroupController
                              ? $root.strings.eventGroup._markedAsController
                              : $root.strings.eventGroup._markAsController
                          }}
                        </a>
                      </div>
                    </el-input>
                  </el-col>
                  <el-col :span="4">
                    <a
                      type="button"
                      class="full-column-button btn gray-btn save"
                      @click="() => removeNewEventFromExistingGroup(selectedEvent)"
                    >
                      {{ $root.strings.eventGroup._removeFromGroup }}
                    </a>
                  </el-col>
                </el-row>
                <div>
                  <el-autocomplete
                    v-model.trim="addEventFilter"
                    class="input-group-control"
                    popper-class="default-popper"
                    size="large"
                    type="text"
                    :fetch-suggestions="findEvents"
                    :debounce="500"
                    placeholder="Add Event"
                    style="text-align: start; width: 100%"
                    @select="addNewEventToExistingGroup"
                  >
                    <i slot="prefix" class="el-icon-plus el-input__icon"> </i>
                    <i slot="suffix" class="el-icon-arrow-down el-input__icon"> </i>
                    <template slot-scope="{ item }">
                      <el-row
                        :gutter="10"
                        class="form-field-row zero-margin-row"
                        style="margin-bottom: 5px"
                      >
                        <el-col :span="16">
                          <span>
                            {{ item.name }}
                          </span>
                        </el-col>
                        <el-col :span="4">
                          <span>
                            {{ item.genre.formatEnum() }}
                          </span>
                        </el-col>
                        <el-col :span="4">
                          <span>
                            {{ new Date(item.createdAt).format() }}
                          </span>
                        </el-col>
                      </el-row>
                    </template>
                  </el-autocomplete>
                </div>
              </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
              <el-row
                :gutter="30"
                class="form-field-row zero-margin-row"
                style="display: flex; margin-bottom: 10px"
              >
                <el-col :span="4" style="align-self: start; padding: 10px !important"> </el-col>
                <el-col :span="20">
                  <el-row
                    :gutter="30"
                    class="form-field-row zero-margin-row"
                    style="display: flex; margin-bottom: 10px"
                  >
                    <el-col :span="12">
                      <a
                        class="full-column-button btn gray-btn save"
                        @click="() => closeRow(editItem)"
                      >
                        {{ $root.strings.eventGroup._cancel }}
                      </a>
                    </el-col>
                    <el-col :span="12">
                      <a
                        class="full-column-button btn gray-btn save primary"
                        @click="() => saveRow(editItem)"
                      >
                        {{ $root.strings.eventGroup._save }}
                      </a>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </span>
          </el-dialog>
        </div>
      </div>
    </transition>
  </div>
</template>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../../assets/css/bootstrap-toggle.css";
@import "../../assets/css/customsidebar.css";
@import "../../assets/css/formstyle.css";
@import "../../assets/css/preloader.css";
@import "../../assets/less/style.css";
@import "../../assets/less/createevent.css";
@import "../../assets/less/settings.css";

.form-field-col-label {
  align-self: center;
}
#loadingCover {
  background-color: rgba(107, 104, 102, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingCover .loadingCoverMessage {
  width: auto;
  text-transform: uppercase;
  font-size: 30px;
}
a.settings {
  cursor: pointer;
  color: #f7a30c !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.settings:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
.team-custom-fields-manager {
  color: #ffffff;
}
.custom-fields-list {
  width: 100%;
}
.custom-fields-list .field {
  padding: 10px 0 10px 0;
  margin: 0 0 5px 0;
}
.custom-fields-list .field.custom-field-name {
  border-right: 1px solid #ddd;
  padding-right: 40px;
}
.custom-fields-list .field.custom-field-mandatory {
  padding-left: 40px;
}
.custom-fields-list .field i {
  font-size: 20px;
}
.custom-fields-list .field i:hover {
  cursor: pointer;
  color: #f7a30c;
}
.custom-fields-list .custom-field {
  margin-bottom: 40px;
}
.custom-fields-list .custom-field-recap {
  border-bottom: 1px solid #dddddd;
}
.custom-fields-list .custom-field-name {
  display: inline-block;
  width: 29%;
}
.custom-fields-list .custom-field-edit {
  display: inline-block;
  width: 70%;
  padding-left: 15px;
}
.custom-fields-list .actions {
  display: inline-block;
  width: 29%;
  text-align: left;
  vertical-align: top;
  padding-right: 40px;
}
.custom-fields-list .text {
  display: inline-block;
  width: 59%;
}
.custom-fields-list .custom-field-pagination {
  text-align: center;
}
.custom-fields-list .custom-field-pagination .pagination li a {
  color: white !important;
}
.custom-fields-list .custom-field-result-download {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.custom-fields-list .custom-field-result-download .btn {
  text-transform: uppercase;
  color: #ffffff;
}

/*pagination color*/

.pagination > li > a,
.pagination > li > span {
  color: white !important;
}

/*checkbox*/

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}
.control-radio .control_indicator {
  border-radius: 0;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

/** Disable Classes */

.form-container {
  position: relative;
}

/** /Disable Classes */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
select.form-control::placeholder {
  color: #fff !important;
}
.form-control option.optionStyle {
  background: #000d;
}

.form-section button {
  margin: 0 !important;
}
.form-section.submit {
  text-align: center;
  margin-top: 20px;
}
.component-menu .btn {
  width: 100%;
  color: white;
  font-family: inherit;
  font-weight: 400;
}
.component-menu .label {
  background-color: #f7a30c;
  color: white;
  padding: 15px;
  border-width: 0px;
  text-transform: uppercase;
  margin-top: 20px;
}
.component-actions {
  margin: 20px 0 20px 0;
}
.component-menu {
  margin: 0 -15px 20px -15px;
}
.label {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.42857143 !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 100% !important;
  border: 1px solid transparent !important;
  border-radius: 0px;
}

.single-action {
  display: flex;
}
.single-action .control {
  flex-basis: 50%;
}
.custom-field-edit-action {
  margin-right: 10px;
}
</style>
<style>
.input-group-control input {
  height: 45px;
  width: 100%;
  margin: 0 auto;
  padding: 13px 21px;
  outline: solid 1px rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.1);
}
.el-table .row-without-hover {
  background: #000000;
  min-height: 60px;
  height: 60px;
  color: white;
}
.el-table .row-without-hover > td {
  vertical-align: top;
}
.el-table .row-without-hover.first-group-row > td {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
.row-without-hover.hover-row,
.row-without-hover .is-hover,
.row-without-hover:hover,
.row-without-hover:focus,
.row-without-hover.hover-row > td,
.row-without-hover .is-hover > td,
.row-without-hover:hover > td,
.row-without-hover:focus > td {
  background-color: #000000 !important;
  color: #fff;
}
</style>
<script>
import LoadingCover from "@/components/LoadingCover.vue";

import { MY_PAGINATED_EVENTS, MY_TEAM, UPDATE_MY_TEAM, UPDATE_MANY_EVENTS } from "@/graphql";
import { mapGetters } from "vuex";
import SectionTitle from "../ui/SectionTitle.vue";
import ButtonTab from "../ui/ButtonTab.vue";
import DataTable from "../DataTable.vue";
import CustomInput from "../ui/CustomInput.vue";

export default {
  components: {
    LoadingCover,
    SectionTitle,
    ButtonTab,
    DataTable,
    CustomInput,
  },
  data() {
    return {
      name: "EventGroup",
      loadingEffectshow: false,
      loadingMessage: "",
      newGroupName: "",
      addEventFilter: "",
      showAddNew: false,
      componentDisabled: false /** Disable Component */,
      tabs: [{ key: "SHOW_EVENT_GROUP_FORM" }, { key: "SHOW_EVENT_GROUP_TABLE" }],
      selectedTab: "SHOW_EVENT_GROUP_TABLE",
      columns: [
        {
          prop: "eventGroupId",
          label: "Group Name",
        },
        {
          prop: "name",
          label: "Event Title",
          minWidth: "130",
        },
        {
          prop: "isEventGroupController",
          label: "Mark as controller",
          minWidth: "130",
        },
        {
          prop: "createdAt",
          label: "Date created",
        },
        {
          label: "Actions",
          width: "130",
        },
      ],
      selectedEvents: [],
      editItem: {
        visible: false,
        eventGroupId: "",
        selectedEvents: [],
      },
      editItemCopy: {
        visible: false,
        eventGroupId: "",
        selectedEvents: [],
      },
      uniqueSpanInfo: {},
    };
  },
  computed: {
    ...mapGetters(["user", "team", "features"]),
  },
  created() {
    if (!this.features.eventGroups) {
      this.$router.push({ path:'/' }).catch(error => {});
    }
  },
  methods: {
    rowClassName({ row, rowIndex }) {
      if (
        row.eventGroupId &&
        this.uniqueSpanInfo[row.eventGroupId] &&
        this.uniqueSpanInfo[row.eventGroupId].index === rowIndex
      ) {
        return "row-without-hover first-group-row";
      } else {
        return "row-without-hover";
      }
    },
    groupRows({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 4) {
        if (this.uniqueSpanInfo[row.eventGroupId].index === rowIndex) {
          return {
            rowspan: this.uniqueSpanInfo[row.eventGroupId].count,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    itemStyle(item) {
      return (
        "text-decoration: none; cursor:pointer; " +
        (!item.isEventGroupController ? " color: white;" : "color: #FF9C00;")
      );
    },
    findEvents(queryString, callback) {
      this.$root.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_PAGINATED_EVENTS,
          variables: {
            filter: queryString || "",
            eventGroupId: null,
            exclude: this.selectedEvents.map((e) => e.id),
            statuses: ["ACTIVE", "INACTIVE"],
            pageInfo: {
              pageSize: 12,
              sortField: "createdAt",
              sortOrder: -1,
              currentPage: 1,
            },
          },
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
            return callback([]);
          } else {
            if (data && data.myPaginatedEvents) {
              return callback(data.myPaginatedEvents.data);
            }
          }
          return callback([]);
        })
        .catch((error) => {
          console.log("error " + error);
          return callback([]);
        });
    },
    toggleControllerEvent(row) {
      row.isEventGroupController = !row.isEventGroupController;
    },
    removeNewEvent(event) {
      this.selectedEvents = this.selectedEvents.filter((item) => item.id !== event.id);
    },
    addNewEvent(row) {
      this.selectedEvents = [...this.selectedEvents, row];
    },
    async removeNewEventFromExistingGroup(row) {
      if (this.editItem.selectedEvents.length < 2) {
        this.$root.bus.emit(
          "simpleToast",
          this.$root.strings.eventGroup._warnBeforeDeletingLastEvent
        );
        return;
      }
      await this.updateEvents(
        {
          ids: [row.id],
        },
        { eventGroupId: null, isEventGroupController: false }
      );
      this.editItem.selectedEvents = this.editItem.selectedEvents.filter(
        (item) => item.id !== row.id
      );
    },
    async addNewEventToExistingGroup(row) {
      await this.updateEvents(
        {
          ids: [row.id],
        },
        { eventGroupId: this.editItemCopy.eventGroupId, isEventGroupController: false }
      );
      this.editItem.selectedEvents = [...this.editItem.selectedEvents, row];
    },
    onTabChanged(tabV) {
      this.selectedTab = tabV;
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      return this.$root.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_PAGINATED_EVENTS,
          variables: {
            onlyEventGroup: true,
            filter: filter || "",
            statuses: ["ACTIVE", "INACTIVE"],
            pageInfo: {
              pageSize: 100,
              sortField: "eventGroupId",
              sortOrder: -1,
              currentPage: page,
            },
          },
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
            return { total: 0, data: [] };
          } else {
            if (data && data.myPaginatedEvents) {
              const uniqueSpanInfo = {};
              data.myPaginatedEvents.data.map((item, index) => {
                if (!uniqueSpanInfo[item.eventGroupId]) {
                  uniqueSpanInfo[item.eventGroupId] = {
                    index,
                    count: 1,
                  };
                } else {
                  uniqueSpanInfo[item.eventGroupId].count++;
                }
              });
              this.uniqueSpanInfo = uniqueSpanInfo;
              return { total: data.myPaginatedEvents.total, data: data.myPaginatedEvents.data };
            }
          }
          return { total: 0, data: [] };
        })
        .catch((error) => {
          console.log("error " + error);
          return { total: 0, data: [] };
        });
    },
    async preDeleteGroup(row) {
      this.$confirm(this.$root.strings.eventGroup._warnBeforeDelete)
        .then(() => {
          this.deleteGroup(row);
        })
        .catch(() => {});
    },
    async preCreateEventGroup() {
      if (!this.newGroupName) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._groupNameMissing);
        return;
      }

      if (this.selectedEvents.length < 1) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._eventListEmpty);
        return;
      }
      const hasController = this.selectedEvents.some(
        (item) => item.isEventGroupController === true
      );
      const hasNonController = this.selectedEvents.some((item) => !item.isEventGroupController);
      if (!hasController) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._selectController);
        return;
      }
      if (!hasNonController) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._selectNonController);
        return;
      }
      const isUnique = await this.isGroupNameUnique(this.newGroupName);
      if (typeof isUnique === "boolean") {
        if (!isUnique) {
          this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._eventGroupExist);
        } else {
          await this.createEventGroup();
        }
      }
    },
    async createEventGroup() {
      await this.updateEvents(
        { ids: this.selectedEvents.map((event) => event.id) },
        { eventGroupId: this.newGroupName }
      );
      await this.updateEvents(
        {
          ids: this.selectedEvents
            .filter((event) => event.isEventGroupController)
            .map((event) => event.id),
        },
        { isEventGroupController: true }
      );
      this.newGroupName = "";
      this.selectedEvents = [];
      this.onTabChanged("SHOW_EVENT_GROUP_TABLE");
      this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._created);
      if (this.$refs.table) {
        this.$refs.table.getTableData();
      }
    },
    openRow(row) {
      this.editItem = {
        visible: true,
        selectedEvents: this.$refs.table.tableData.filter(
          (item) => item.eventGroupId === row.eventGroupId
        ),
        eventGroupId: row.eventGroupId,
      };
      this.editItemCopy = {
        ...this.editItem,
      };
    },
    closeRow(row) {
      this.editItem = {
        visible: false,
        selectedEvents: [],
        eventGroupId: "",
      };
      this.editItemCopy = {
        ...this.editItem,
      };
    },
    async saveRow(row) {
      if (!this.editItem.eventGroupId || !this.editItem.eventGroupId.toString().trim()) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._groupNameMissing);
        return;
      }

      if (this.editItem.selectedEvents.length < 1) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._eventListEmpty);
        return;
      }

      const hasController = this.editItem.selectedEvents.some(
        (item) => item.isEventGroupController === true
      );
      const hasNonController = this.editItem.selectedEvents.some(
        (item) => !item.isEventGroupController
      );
      if (!hasController) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._selectController);
        return;
      }
      if (!hasNonController) {
        this.$root.bus.emit("simpleToast", this.$root.strings.eventGroup._selectNonController);
        return;
      }

      if (this.editItemCopy.eventGroupId !== this.editItem.eventGroupId) {
        await this.updateEvents(
          {
            eventGroupId: this.editItemCopy.eventGroupId,
          },
          { eventGroupId: this.editItem.eventGroupId }
        );
      }

      await this.updateEvents(
        {
          ids: this.editItem.selectedEvents
            .filter((event) => event.isEventGroupController)
            .map((event) => event.id),
        },
        { isEventGroupController: true }
      );
      await this.updateEvents(
        {
          ids: this.editItem.selectedEvents
            .filter((event) => !event.isEventGroupController)
            .map((event) => event.id),
        },
        { isEventGroupController: false }
      );

      if (this.$refs.table) {
        this.$refs.table.getTableData();
      }
      this.closeRow();
    },
    async isGroupNameUnique(groupName) {
      return this.$root.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_PAGINATED_EVENTS,
          variables: {
            eventGroupId: groupName,
            pageInfo: {
              pageSize: 5,
              sortField: "createdAt",
              sortOrder: -1,
              currentPage: 1,
            },
          },
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
            return null;
          } else {
            if (data && data.myPaginatedEvents) {
              return data.myPaginatedEvents.total < 1;
            }
          }
          return null;
        })
        .catch((error) => {
          console.log("error " + error);
          return null;
        });
    },
    async deleteGroup(row) {
      if (row.eventGroupId) {
        await this.updateEvents(
          {
            eventGroupId: row.eventGroupId,
          },
          { isEventGroupController: false, eventGroupId: null }
        );
        if (this.$refs.table) {
          this.$refs.table.getTableData();
        }
      }
    },
    async updateEvents(where, updates) {
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_MANY_EVENTS,
          variables: {
            where,
            updates,
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors[0]);
          } else {
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>
