<template>
  <div class="overview">
    <div class="text-center overview-cell">
      <h3>{{ string.overview._total }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.TOTAL }}
      </h4>
    </div>
    <div class="text-center overview-cell">
      <h3>{{ string.overview._pending }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.PENDING }}
      </h4>
    </div>
    <div class="text-center overview-cell">
      <h3>{{ string.overview._confirmed }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.CONFIRMED }}
      </h4>
    </div>
    <div class="text-center overview-cell">
      <h3>{{ string.overview._guestsAndCompanions }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.CONFIRMED_PLUS_ONE }}
      </h4>
    </div>
    <div class="text-center overview-cell">
      <h3>{{ string.overview._checkin }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.CHECK_IN }}
      </h4>
    </div>
    <div class="text-center overview-cell">
      <h3>{{ string.overview._checkinPlus }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.CHECK_IN_PLUS }}
      </h4>
    </div>
    <div v-if="stats.PREDICTION > 0" class="text-center overview-cell">
      <h3>{{ string.overview._prediction }}</h3>
      <ClipLoader v-if="loading" :loading="loading" color="#f7a30c" size="15px" />
      <h4 v-else>
        {{ stats.PREDICTION }}
      </h4>
    </div>
  </div>
</template>
<style>

</style>
<style scoped>

.overview {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}
.overview h3,
.overview h4 {
  color: white;
}
.overview h3 {
  line-height: 1.5;
  text-transform: uppercase;
  /* font-family: "Roboto", sans-serif; */
}
.overview-cell {
  flex: 1
}
@media (max-width: 767px) {
  .overview {
    flex-direction: column;
  }
}
</style>
<script>
import { GET_GUEST_STAT, SUBSCRIBE_TO_STAT_NEWS } from "@/graphql";
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  components: { ClipLoader },
  props: {
    eventId: {
      type: String,
      required: true,
    },
    isPrivate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      componentName: "overview",
      string: {
        /*
                    overview: {
                        _pending : "in attesa",
                        _confirmed : "confermati",
                        _checkin : "checkin",
                        _total : "totale",
                    }*/
      },
      classCol: "col-md-2",
      classMedium: "col-md-3",
      classColBig: "col-md-4",
      pendingNum: 0,
      confirmedNum: 0,
      checkinNum: 0,
      totalNum: 0,
      guestsAndCompanionsNum: 0,
      stats: {
        PENDING: 0,
        CONFIRMED: 0,
        CONFIRMED_PLUS_ONE: 0,
        CHECK_IN: 0,
        CHECK_IN_PLUS: 0,
        PREDICTION: 0,
        TOTAL: 0,
      },
      statNewsSubscription: null,
      loading: false,
    };
  },
  watch: {
    isPrivate: function () {
      this.classCol = this.isPrivate === true ? "col-md-2" : "col-md-4";
    },
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
    this.subscribeToUpdates();
  },
  async mounted() {
    this.loading = true;
    this.getData(this.eventId).then(() => (this.loading = false));
  },
  beforeDestroy() {
    this.unsubscribeFromUpdates();
  },
  methods: {
    subscribeToUpdates() {
      const context = this;
      this.statNewsSubscription = this.$apollo
        .subscribe({
          query: SUBSCRIBE_TO_STAT_NEWS,
          variables: { eventId: this.eventId },
        })
        .subscribe(({ data, errors }) => {
          if (errors) {
            console.log(errors);
            return;
          }
          if (data && data.statisticsNews) {
            const changes = data.statisticsNews.data;
            // console.log("Overview@subscribeToUpdates");
            // console.log({ ...changes } );
            const tempStats = { ...context.stats };
            for (const change of changes) {
              if (typeof tempStats[change.key] !== "undefined") {
                tempStats[change.key] += change.value;
              } else {
                tempStats[change.key] = 0;
              }
              if (change.key === "CONFIRMED_PLUS_ONE") {
                context.$emit("changeConfirmedCounter", tempStats.CONFIRMED_PLUS_ONE);
              }
            }
            context.stats = { ...tempStats };
          }
        });
    },
    unsubscribeFromUpdates() {
      try {
        if (this.statNewsSubscription) {
          this.statNewsSubscription.unsubscribe();
          this.statNewsSubscription = null;
        }
      } catch (e) {}
    },
    async getData() {
      const context = this;
      await this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: GET_GUEST_STAT,
          variables: { eventId: this.eventId },
        })
        .then((response) => {
          context.loadInProgress = false;
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.Event) {
            const payload = response.data.Event.overview;
            context.stats = { ...payload, PREDICTION: response.data.Event.prediction };
            context.$emit("changeConfirmedCounter", context.stats.CONFIRMED_PLUS_ONE);
          }
        })
        .catch((error) => {
          console.log(error);
          context.loadingEffectshow = false;
        });
    },
  },
};
</script>
