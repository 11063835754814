var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MainLayout", [
    _c(
      "div",
      { staticClass: "new-event-box " },
      [
        _c(
          "transition",
          {
            attrs: { name: "component-fade-fast", mode: "out-in", appear: "" },
          },
          [
            _vm.loadingEffectshow
              ? _c("LoadingCover", {
                  attrs: { "loading-cover-msg": _vm.loadingMessage },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row", attrs: { id: "new-event-box-row" } }, [
          _c(
            "div",
            {
              staticClass:
                "col-md-1\n                          col-sm-1\n                          col-xs-1\n                          no-padding\n                          function-selector",
              attrs: { id: "function-selector-id" },
            },
            [
              _c("div", { staticClass: "label-function-selector" }, [
                _c(
                  "i",
                  {
                    staticClass: "fa fa-calendar-o fa-2x",
                    attrs: { "aria-hidden": "true" },
                  },
                  [_vm._v("\n             \n          ")]
                ),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.string.newEvent._create) +
                    "\n          "
                ),
                _c("br"),
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.string.newEvent._event) +
                    "\n        "
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-md-11\n                          col-sm-11\n                          col-xs-11\n                          no-padding\n                          event-box-form-container",
            },
            [
              _c("div", { staticClass: "tab-content" }, [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane active",
                    attrs: { id: "home", role: "tabpanel" },
                  },
                  [
                    _c("div", { staticClass: "form-panel" }, [
                      _c("h4", { staticClass: "mb" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm._pagetitle) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "smallseparator",
                        staticStyle: { "margin-top": "-10px" },
                      }),
                      _vm._v(" "),
                      _c(
                        "form",
                        { staticClass: "form-horizontal style-form" },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramName,
                                      expression: "paramName",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    id: "eventNameId",
                                    type: "text",
                                    placeholder: _vm.string.newEvent._eventname,
                                  },
                                  domProps: { value: _vm.paramName },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paramName = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.categoryObj,
                                        expression: "categoryObj",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "selectCat",
                                      "aria-label": "",
                                    },
                                    on: {
                                      click: _vm.changecolor,
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.categoryObj = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        attrs: { disabled: "", hidden: "" },
                                        domProps: {
                                          value: { key: "", label: "" },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.string.newEvent._eventtype
                                            ) +
                                            "\n                      "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.customCategories,
                                      function (category) {
                                        return _c("option", {
                                          key: category.key,
                                          attrs: {
                                            label: category.label,
                                            hidden: !!category.deletedAt,
                                          },
                                          domProps: { value: category },
                                        })
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-sm-5" },
                                [
                                  _c("Places", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "addressInput",
                                      "place-holder": _vm.addressPlaceholder,
                                      "model-value": "",
                                    },
                                    on: {
                                      "place-changed": _vm.onAddressChanged,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-5" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramVenue,
                                      expression: "paramVenue",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    type: "text",
                                    placeholder: _vm.string.newEvent._venue,
                                  },
                                  domProps: { value: _vm.paramVenue },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paramVenue = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramVirtualVenue,
                                      expression: "paramVirtualVenue",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    type: "text",
                                    placeholder:
                                      _vm.string.newEvent._virtualVenue,
                                  },
                                  domProps: { value: _vm.paramVirtualVenue },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paramVirtualVenue =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c(
                                "div",
                                { staticClass: "col-sm-10" },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "form-control",
                                    attrs: {
                                      id: "startDateId",
                                      type: "datetimerange",
                                      editable: false,
                                      clearable: false,
                                      align: "left",
                                      size: "large",
                                      "start-placeholder":
                                        _vm.string.newEvent._startdate,
                                      "end-placeholder":
                                        _vm.string.newEvent._enddate,
                                      "picker-options": _vm.pickerOptions,
                                      format: "yyyy-MM-dd HH:mm",
                                      "value-format": "timestamp",
                                    },
                                    on: { change: _vm.onDateTimeRangeChanged },
                                    model: {
                                      value: _vm.dateTimeRange,
                                      callback: function ($$v) {
                                        _vm.dateTimeRange = $$v
                                      },
                                      expression: "dateTimeRange",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramDescriptionIt,
                                      expression: "paramDescriptionIt",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    rows: "5",
                                    placeholder:
                                      _vm.string.newEvent._descriptionIt,
                                  },
                                  domProps: { value: _vm.paramDescriptionIt },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paramDescriptionIt =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-group no-margin-bottom" },
                            [
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.paramDescriptionEn,
                                      expression: "paramDescriptionEn",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    rows: "5",
                                    placeholder:
                                      _vm.string.newEvent._descriptionEn,
                                  },
                                  domProps: { value: _vm.paramDescriptionEn },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.paramDescriptionEn =
                                        $event.target.value
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "formbutton" }, [
                        _c(
                          "a",
                          {
                            staticClass: "save",
                            attrs: { title: _vm.string.newEvent._next },
                            on: { click: _vm.submit },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.string.newEvent._next) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "save",
                            attrs: { title: _vm.string.newEvent._clear },
                            on: { click: _vm.cancel },
                          },
                          [
                            _vm._v(
                              "\n                  " +
                                _vm._s(_vm.string.newEvent._clear) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }