<template>
  <div class="section-title-container">
    <h4 v-if="title !== ''" class="section-title">
      {{ title }}
    </h4>
    <div v-if="$slots.subtitle" class="section-subtitle">
      <slot name="subtitle"></slot>
    </div>
    <h5 v-else-if="subtitle !== ''" class="section-subtitle">
      {{ subtitle }}
    </h5>

    <div v-if="showSeparator" class="small-separator"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    showSeparator: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.section-title {
  color: #e8e8e8 !important;
  text-transform: uppercase;
  font-size: 18px !important;
}
.section-subtitle {
  font-size: 14px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  font-weight: 400;
  color: #e8e8e8;
}
.small-separator {
  border-bottom-width: 5px;
  border-bottom-color: #f7a30c;
  border-bottom-style: solid;
  width: 5%;
  margin-top: 20px;
}
.section-title-container {
  margin: 20px 0;
}
.section-title-container h3, .section-title-container h4 {
  font-weight: 400 !important;
}
</style>