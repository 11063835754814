<template>
  <div class="row list-upload">
    <h4>{{ string.listUpload._title }}</h4>
    <div class="col-sm-12 col-md-12 col-lg-12">
      <transition
        name="component-fade-fast"
        mode="out-in"
        appear>
        <LoadingCover
          v-if="loadingEffectshow"
          :loading-cover-msg="string.listUpload._loadingMessage"></LoadingCover>
      </transition>
      <div id="uploadInteractionContainer">
        <div class="row">
          <div class="col-sm-12 col-md-4 col-lg-4 left">
&nbsp;
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
            <input
              :id="fileInputName"
              ref="fileinput"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFileChange" />
            <transition
              name="component-fade-fast"
              mode="out-in"
              appear>
              <a
                v-if="fileToSelect === true"
                :title="string.listUpload._browse"
                @click="open">
                <i
                  aria-hidden="true"
                  class="fa fa-folder-open"></i>
                {{ string.listUpload._browse }}
              </a>
              <div v-if="fileToSelect === false">
                <p>{{ fileListName }}</p>
                <a
                  :title="string.listUpload._clear"
                  class="clear"
                  @click="clear">
                  <i
                    aria-hidden="true"
                    class="fa fa-times"></i>
                  {{ string.listUpload._clear }}
                </a>
              </div>
            </transition>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4 left">
&nbsp;
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 left">
            <div class="template-link-container">
              <a
                :href="xlsTemplateURL"
                class="simple">
                <i class="fa fa-download" aria-hidden="true"  style="margin-right: 5px;"/>
                {{ string.listUpload._downloadInstruction }} XLSX
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="formbutton">
        <a
          class="btn save"
          :title="string.listUpload._load"
          @click="submit">
          {{ string.listUpload._load }}
        </a>
      </div>
    </div>
  </div>
</template>
<style>
    #uploadInteractionContainer a {
        font-size: 16px;
        margin: 0 0 10px;
        padding: 8px;
        color: #fff;
        text-transform: uppercase;
        background-color: rgba(255,255,255,0.4);
        width: 100%;
        display: block;
        cursor: pointer;
        text-align: center;
    }

    #uploadInteractionContainer a.simple {
        cursor: pointer;
        color: #fff !important;
        font-size: 12px !important;
        text-decoration: none;
        background-color: transparent !important;
    }
    #uploadInteractionContainer a.simple:hover {
        text-decoration: underline;
        background-color: transparent !important;
    }

    #uploadInteractionContainer a.clear {
        font-size: 16px;
        margin: 0 0 10px;
        padding: 8px;
        color: #333;
        background-color: transparent !important;
        cursor: pointer;
        width: 100%;
    }

    #uploadInteractionContainer a:hover {
        background-color: #dadada !important;
        color: #333333 !important;
    }

    .formbutton {
        position: relative;
    }

    .formbutton a {
        cursor: pointer !important;
    }

    #uploadInteractionContainer {
        padding: 60px 20px;
        border: solid 1px #333;
        margin-bottom: 20px;
        background-color: rgba(255,255,255,0.4);
    }

    .list-upload .formbutton > a {
        margin: 0 auto;
        display: block;
        width: 100%;
    }

    .list-upload label {
        color: #333;
        font-weight: bold;
        padding-left: 0 !important;
        text-transform: capitalize;
    }

    .list-upload h4 {
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
    }

</style>
<script>
import LoadingCover from './LoadingCover.vue';
import { UPLOAD_GUEST_LIST } from '@/graphql';

export default {
    components: {
        LoadingCover
    },
    props: {
        eventType: String,
        eventId: String,
        fatherName: String
    },
    data () {
        return {
            clearButtonHide: true,
            fileToSelect: true,
            uploadURL: '',
            csvTemplateURL: '',
            xlsTemplateURL: '',
            string: {
                /*
                                    listUpload: {
                                        _title : "Carica una lista di partecipanti al tuo evento",
                                        _clear : "cancella",
                                        _load : "carica",
                                        _browse : "sfoglia",
                                        _loadingMessage : "Attendere...",
                                        _unManagedError : "Errore non gestito",
                                        _loadingComplete : "caricamento completato",
                                        _loadingError : "caricamento fallito",
                                        _itemImported : "importati",
                                        _mandatoryFields : "Nessun file selezionato",
                                        _unManagedError : "Errore non gestito",
                                    }
                                    */
            },
            fileList: '',
            fileListName: '',
            apiAnswer: {
                itemImported: 0
            },
            loadingEffectshow: false,
            fileInputName: this.fatherName + 'fileinput'
        };
    },
    created () {
        const context = this;
        this.string = this.$root.strings;
        this.$root.bus.addListener('setLanguage', () => {
            context.string = context.$root.strings;
        });
    },
    mounted () {
        this.xlsTemplateURL = '/download/guest_template.xlsx';
    },
    methods: {
        fileListValid: function () {
            return document.getElementById(this.fileInputName).value !== '';
        },
        warnErrorForIncompleteUpload: function (
            numberOfItemDiscarded,
            downloadToken
        ) {
            const context = this;
            // Emit error
            this.$root.bus.emit('modalWarningView', 'warning', [
                numberOfItemDiscarded,
                downloadToken
            ]); /** Emit di al gestore globale */
            this.$root.bus.emit(
                'simpleToast',
                context.string.listUpload._incompleteListUploadWarningTitle
            ); /** Emit di al gestore globale */
            this.loadingEffectshow = false;
        },
        warnErrorForMandatoryFields: function () {
            const context = this;
            this.$root.bus.emit(
                'simpleToast',
                context.string.listUpload._mandatoryFields
            ); /** Emit di al gestore globale */
            this.loadingEffectshow = false;
        },

        warnUnManagedError: function () {
            this.$root.bus.emit(
                'simpleToast',
                this.string.listUpload._unManagedError
            ); /** Emit di al gestore globale */
            this.loadingEffectshow = false;
        },
        submit: function () {
            const context = this;
            context.loadingEffectshow = true;

            if (this.fileListValid() === false) {
                context.warnErrorForMandatoryFields();
                return false;
            }
            return this.$apollo
                .mutate({
                    mutation: UPLOAD_GUEST_LIST,
                    variables: {
                        file: context.fileList,
                        eventId: context.eventId
                    }
                })
                .then(value => {
                    if (value.errors) {
                        context.loadingEffectshow = false;
                        console.log("error " + value.errors);
                        context.$root.bus.emit('simpleToast', this.string.guestlist._upload_failure);
                    } else {
                        context.$emit('component_event', {
                            itemImported: {}
                        });
                        context.loadingEffectshow = false;
                        context.emptyFields();
                        context.$root.bus.emit('simpleToast', this.string.guestlist._upload_success);
                    }
                })
                .catch(error => {
                    context.loadingEffectshow = false;
                    if(error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].message === 'FILE_UPLOAD_FAILED') {
                        context.$root.bus.emit('simpleToast', this.string.guestlist._upload_failure);
                    }
                    context.emptyFields();
                });

            // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            // const data = new FormData();
            // // data.append('client_security_code', userdata.client_security_code);
            // if (context.fileList !== null) {
            //     data.append('fileToUpload', context.fileList);
            // }
            // data.append('eventId', context.eventId);
            //
            // return this.$root.axios
            //     .post('/upload/guestlist', data, config)
            //     .then((response) => {
            //         context.$emit('component_event', {
            //             itemImported: {}
            //         });
            //         context.loadingEffectshow = false;
            //         context.emptyFields();
            //         context.$root.bus.emit('simpleToast', response.data.message);
            //     })
            //     .catch((error) => {
            //         const message = error.data && error.data.message ? error.data.message : 'Upload failed';
            //         context.loadingEffectshow = false;
            //         context.$root.bus.emit(
            //             'simpleToast',
            //             message
            //         );
            //         context.emptyFields();
            //     });
        },
        getSuccessStringResult: function (resultObject) {
            const context = this;
            return context.string.listUpload._loadingComplete.concat(
                ', ',
                context.string.listUpload._itemImported,
                ' : ',
                context.apiAnswer.itemImported
            );
        },
        onFileChange: function (e) {
            this.fileToSelect = false;
            const files = e.target.files || e.dataTransfer.files;
            if (!files.length) {return;}

            this.fileList = files[0];
            this.fileListName = files[0].name;
        },
        clear: function () {
            const context = this;
            context.fileToSelect = true;
            document.getElementById(context.fileInputName).value = '';
        },
        open: function () {
            this.$refs.fileinput.click();
        },
        emptyFields: function () {
            const context = this;
            context.clear();
        }
    }
};
</script>
