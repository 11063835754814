<template>
  <div>
    <transition
            name="component-fade-fast"
            mode="out-in"
            appear>
        <LoadingCover
                v-if="loadingEffectshow"
                :loading-cover-msg="loadingMessage"/>
    </transition>
    <transition
            name="component-fade-fast"
            mode="out-in"
            appear>
      <div >
          <h4 class="form-section-title first">
              {{ string.organizerSettings._recap_unsubscribers }}
              <small>{{ string.organizerSettings._recapSubtitle_unsubscribers }}</small>
          </h4>
          <div class="smallseparator"></div>
          <div class="row guestList_links data" style="padding: 10px 0px;">
              <div class="col-sm-12 col-md-12 col-lg-12" style="padding: 0;">
                  <div class="downloads_style">
                      <i
                              class="fa fa-users"
                              aria-hidden="true"
                              style="margin-right:15px;"/>
                      {{ string.organizerSettings._unsubscriber_number }}<strong>{{ unsubscribers ? unsubscribers.length : 0 }}</strong>
                  </div>

                  <div class="downloads_style">
                      <i class="fa fa-cloud-download" aria-hidden="true" style="margin-right:15px;" />
                      <vue-excel-xlsx
                              class="linkStyle"
                              :data="unsubscribers"
                              :columns="unsubscriberReportColumns"
                              filename="unsubscriber_list_report"
                              sheetname="Sheet 1">
                          {{ string.organizerSettings._download_unsubscribers }}
                      </vue-excel-xlsx>
                  </div>
              </div>
          </div>
          <transition
                  name="component-fade-fast"
                  mode="out-in"
                  appear>
              <template>
                  <div class="refresh-stats-button">
                      <a
                              class="settings"
                              @click="(e) => {e.preventDefault(); handleUpdateUnsubscribers();}">
                          <i
                                  aria-hidden="true"
                                  class="fa fa-refresh"
                                  :class="updatingUnsubscribers ? 'rotate-center' : ''"/>
                          {{ string.organizerSettings._refreshIdentities }}
                      </a>
                  </div>
              </template>
          </transition>

          <transition
                  name="component-fade-fast"
                  mode="out-in"
                  appear>
              <div
                      :class="!!updatingUnsubscribers ? 'datatable no-data' : ''">
                  <template v-if="updatingUnsubscribers">
                      <div class="data-grid-container-status-message">
                          {{ string.organizerSettings._updatingIdentities }}
                      </div>
                  </template>
                  <template v-if="!updatingUnsubscribers">
                      <DataGridReport
                              v-if="unsubscribers.length > 0"
                              :data="unsubscribers"
                              :columns="unsubscriberColumns"/>
                      <div class="data-grid-container-status-message" v-else>
                          {{ string.organizerSettings._no_data }}
                      </div>
                  </template>
                  <template v-else-if="!updatingUnsubscribers">
                      <div class="data-grid-container-status-message">
                          {{ string.organizerSettings._no_data }}
                      </div>
                  </template>
              </div>
          </transition>
      </div>

    </transition>
  </div>
</template>
<style scoped>
 .smallseparator{
    margin-top: -10px !important;
  }


  .data-grid-container tbody {
      font-size: 15px !important;
  }

  .row.tabs-content h4 {
      text-align: center;
  }

  .list-upload a {
      text-align: center !important;
  }

  .table {
      margin-top: 50px;
      font-size: 14px;
  }

  .data {
      font-size: 20px;
  }

  .no-data {
      padding: 0 30px 30px;
      text-align: center;
      font-size: 40px;
      text-transform: uppercase;
      color: #e8e8e8;
  }

  a.btn {
      cursor: pointer;
      display: block;
      text-transform: uppercase;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      color: #ffffff;
      border: 0 !important;
      margin-bottom: 30px;
      font-family: inherit;
      font-weight: 400;
  }

  .datatable-container {
      margin-top: 30px;
  }

  .btn:hover {
      background-color: #dadada !important;
      color: #333;
      cursor: pointer;
      border: 0 !important;
  }

  small.textSmall{
      font-size: 12px;
  }
  .downloads_style{
      padding-left: 15px;
  }
  .downloads_style > i {
      padding-right: 15px;
  }
  .linkStyle {
      display: inline;
      cursor: pointer;
  }
  button.linkStyle {
      background: transparent !important;
      border: transparent !important;
      cursor: pointer;
      padding: 1px 0;
  }
  .row.guestList_links>div>.downloads_style>.linkStyle:hover {
      color: #f7a30c;
      text-decoration: underline;
  }
  .row.guestList_links>button>.downloads_style>.linkStyle:hover {
      color: #f7a30c;
      text-decoration: underline;
  }
  .downloads_style>div>a:hover{
      color: #f7a30c !important;
  }
</style>
<script>
import LoadingCover from '@/components/LoadingCover.vue';
import DataGridReport from '@/components/DataGridReport';
import {
   MY_UNSUBSCRIBERS
} from '@/graphql';

export default {
    components: {
      DataGridReport,
      LoadingCover
    },
    data (){
     return {
        name:'Unsubscribers',
        string: {},
        updatingUnsubscribers: false,
        unsubscribers: [],
        unsubscriberColumns: [
            { key: 'memberEmail', label: 'email'},
            { key: 'updatedAt', label: 'date'}
        ],
        unsubscriberReportColumns: [
            {
                label: "Email",
                field: "memberEmail",
                dataFormat: (value) => { return value.includes('internal.penguinpass.it') ? ''  : value }
            },
            {
                label: "Date of Unsubscription",
                field: "createdAt",
                dataFormat: (value) => { return (new Date (value)).format();}
            }
        ],

        loadingEffectshow: false,
        loadingMessage: '',
     }
    },
    created (){
      const context = this;
      this.string = this.$root.strings;
      this.$root.bus.addListener('setLanguage', () => {
        context.string = context.$root.strings;
      });

      this.getUnsubscribers();
    },
    methods: {
      getUnsubscribers: function () {
          let context = this;
          if(!context.updatingUnsubscribers)
              context.loadingEffectshow = true;
          context.loadingMessage = '';
          return this.$apollo.query({
              fetchPolicy: 'no-cache',
              query: MY_UNSUBSCRIBERS
          }).then(response => {
              if (response.errors){
                  console.log(response.errors);
                  context.loadingEffectshow = false;
                  return;
              }
              if(response.data && response.data.myUnsubscribers) {
                  const payload = response.data.myUnsubscribers;
                  context.loadingEffectshow = false;
                  context.updatingUnsubscribers = false;
                  context.unsubscribers = payload;
              }
          }).catch(error => {
              console.log(error);
              context.loadingEffectshow = false;
          });
      },
      handleUpdateUnsubscribers: function () {
        this.updatingUnsubscribers = true;
        return this.getUnsubscribers();
      }
    }
}
</script>