<template>
  <div>
    <div 
      class="modal fade in" 
      tabindex="-1"
      role="dialog" 
      aria-labelledby="myModalLabel" 
      aria-hidden="false" 
      style="display: block;"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-hidden="true"
              @click="fadeout">
              ×
            </button>
            <h4
              id="myModalLabel"
              class="modal-title">
              {{ string.modal._title }}
            </h4>
          </div>
          <div class="modal-body">
            Hi there, I am a Modal Example for Dashgum Admin Panel.
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-default modal-btn-close"
              @click="fadeout">
              {{ string.modal._buttonClose }}
            </button>
            <button
              type="button"
              class="btn btn-default modal-btn-action">
              {{ string.modal._buttonClose }}
            </button>
          </div>
        </div>
      </div>

      <div
        id="modalbackgroundcolor"
        @click="false && fadeout">
&nbsp;
      </div>
    </div>
  </div>
</template>
<style scoped>
    
    #modalbackgroundcolor {
        background-color: transparent;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: fixed;
        z-index: 0;
    }

    .modal-dialog {
        display: block !important;
        margin-top: 10%;
        position: fixed;
        left: 0;
        right: 0;
        z-index :1;
        height: auto !important;
    }

    .modal {
        background-color: rgba(51,51,51,0.6);
    }

    .modal-content {
        border: none;
        background-color: #ffffff;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
    }
    

    .modal-header .close {
        margin-top: 5px !important;
    }

    .btn-default{
        border-radius: 0 !important;     
        text-transform: uppercase;
        border: 0;   
    }

    .btn-default:hover{
        background-color: #dadada !important;
        color: #333 !important;   
    }

    .modal-btn-close{
       background-color: #333333;
       color: #fff !important;
    }
    .modal-btn-action{
        background-color: #f7a30c;
        color: #fff !important;
    }

</style>
<script>

export default {
    data (){
        return {
            componentName: 'modal-test',

            string : {
                modal : {
                    _title: 'Modal Titolo',
                    _buttonClose: 'Chiudi'
                }
            }    
            
        };
    },

    mounted () {
        console.log('MODAL MOUNTED: ' + this.componentName);        
    },

    methods: {
        fadeout: function () {
            this.$root.bus.emit('modalView', 'fadeout');  /** Emit di al gestore globale */
        }               
    }

};
</script>
