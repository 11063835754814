var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "advance-settings-component-container privacy-policy-component accordion",
    },
    [
      _c("h4", { staticClass: "form-section-title first accordion-toggle" }, [
        _vm._v(
          "\n      " + _vm._s(_vm.string.urlPrivacyPolicy._title) + "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-container accordion-content" }, [
        _c("small", [_vm._v(_vm._s(_vm.string.urlPrivacyPolicy._management))]),
        _vm._v(" "),
        _vm.componentDisabled
          ? _c(
              "div",
              {
                staticClass: "disable-layer",
                on: { click: _vm.disableLayerClicked },
              },
              [_vm._v("\n \n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.inAction
          ? _c("div", { staticClass: "inaction-layer" }, [
              _vm._v("\n \n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "component-menu row" }, [
          _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "displayed_action_title" },
              [
                _c(
                  "transition",
                  { attrs: { name: "component-fade-fast", mode: "out-in" } },
                  [
                    !_vm.createNew
                      ? _c(
                          "a",
                          {
                            staticClass: "btn action",
                            on: {
                              click: function ($event) {
                                return _vm.showCreateNewAction(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.string.privacyPolicy._createNew) +
                                "\n              "
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.string.privacyPolicy._createNew) +
                              "\n              "
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
            _c(
              "div",
              { staticClass: "displayed_action_title" },
              [
                _c(
                  "transition",
                  { attrs: { name: "component-fade-fast", mode: "out-in" } },
                  [
                    _vm.createNew
                      ? _c(
                          "a",
                          {
                            staticClass: "btn action",
                            on: {
                              click: function ($event) {
                                return _vm.showCreateNewAction(false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.string.privacyPolicy._chooseExisting
                                ) +
                                "\n              "
                            ),
                          ]
                        )
                      : _c("div", { staticClass: "label" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.string.privacyPolicy._chooseExisting) +
                              "\n              "
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "component-actions" }, [
          _vm.createNew
            ? _c(
                "div",
                [
                  _c("CreatePolicy", {
                    attrs: { event: _vm.event },
                    on: {
                      created: function ($event) {
                        return _vm.showCreateNewAction(false)
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [_c("UseExistingPolicy", { attrs: { event: _vm.event } })],
                1
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }