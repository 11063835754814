var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MainLayout",
    [
      _c(
        "el-container",
        { staticClass: "page-container", attrs: { direction: "vertical" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xs-12 col-sm-12 col-lg-12" }, [
              _c("h1", { staticStyle: { "font-weight": "300" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$root.strings.notify._page_title) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("h4", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$root.strings.notify._subtitle) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass: "smallseparator",
                staticStyle: { "margin-top": "25px !important" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "section-content",
            { attrs: { "has-icon": true } },
            [
              _c("section-title", {
                attrs: {
                  title: _vm.$root.strings.notify._create_section_title,
                },
              }),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _vm.inputVisible === false
                    ? _c(
                        "button",
                        {
                          staticClass: "button save",
                          staticStyle: { width: "100% !important" },
                          on: { click: _vm.createNotification },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$root.strings.notify._create) +
                              "\n        "
                          ),
                        ]
                      )
                    : _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.$root.strings.notify._title
                                        ) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("custom-input", {
                                    attrs: {
                                      placeholder:
                                        _vm.$root.strings.notify
                                          ._title_placeholder,
                                    },
                                    on: { change: function () {} },
                                    model: {
                                      value: _vm.inputName,
                                      callback: function ($$v) {
                                        _vm.inputName =
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                      },
                                      expression: "inputName",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "suggestion-row" }, [
                                    _c("div", { staticClass: "suggestion" }, [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$root.strings.notify
                                              ._title_recommendation
                                          ) +
                                          "\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "suggestion right" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.inputName.length) +
                                            " / " +
                                            _vm._s(_vm.maxChars)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-row",
                            {
                              staticClass: "form-field-row zero-margin-row",
                              attrs: { gutter: 20 },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-label",
                                  attrs: { span: 3 },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$root.strings.notify._body) +
                                        "\n              "
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "form-field-col-field",
                                  attrs: { span: 21 },
                                },
                                [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: _vm.inputBody,
                                        expression: "inputBody",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      rows: "5",
                                      placeholder:
                                        _vm.$root.strings.notify
                                          ._body_placeholder,
                                    },
                                    domProps: { value: _vm.inputBody },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.inputBody =
                                          $event.target.value.trim()
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.inputVisible
            ? _c(
                "section-content",
                { attrs: { "has-icon": true } },
                [
                  _c("section-title", {
                    attrs: {
                      title: _vm.$root.strings.notify._user_group_section_title,
                    },
                  }),
                  _vm._v(" "),
                  _c("h4", { staticStyle: { "margin-top": "10px" } }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$root.strings.notify._subtitle_user_group) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("custom-checkbox", { on: { change: _vm.toggleAll } }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$root.strings.notify._all_users) +
                      "\n      "
                  ),
                  _c(
                    "div",
                    { staticClass: "group-manager-members-list" },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            name: "component-fade-fast",
                            mode: "out-in",
                            appear: "",
                          },
                        },
                        [
                          _c("data-table", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.all,
                                expression: "!all",
                              },
                            ],
                            key: "events-table",
                            ref: "tableEvents",
                            attrs: {
                              "show-pagination": true,
                              "page-size": 25,
                              "get-data": _vm.getDataEvent,
                              columns: _vm.columnsEvent,
                              "selection-enabled": true,
                              "on-selection-change": _vm.onSelectionChange,
                              "filter-enabled": false,
                              "no-data-text":
                                _vm.$root.strings.notify._no_notifications,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.name) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "startDate",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row["startDate"]
                                              ? new Date(
                                                  row["startDate"]
                                                ).format()
                                              : " - "
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "endDate",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row["endDate"]
                                              ? new Date(
                                                  row["endDate"]
                                                ).format()
                                              : " - "
                                          ) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                                {
                                  key: "id",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return _c("span", {}, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.id) +
                                          "\n            "
                                      ),
                                    ])
                                  },
                                },
                              ],
                              null,
                              false,
                              3976818662
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button save",
                      staticStyle: { width: "100% !important" },
                      on: { click: _vm.sendNotification },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$root.strings.notify._submit) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "section-content",
            { attrs: { "has-icon": true } },
            [
              _c("section-title", {
                attrs: { title: _vm.$root.strings.notify._recap_section_title },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group-manager-members-list" },
                [
                  _c("PulseLoader", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadingRecap,
                        expression: "loadingRecap",
                      },
                    ],
                    attrs: { color: "#f7a30c", size: "15px" },
                  }),
                  _vm._v(" "),
                  _c("data-table", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loadingRecap,
                        expression: "!loadingRecap",
                      },
                    ],
                    key: "recap-table",
                    ref: "recapTable",
                    attrs: {
                      "get-data": _vm.getNotifications,
                      columns: _vm.columnsRecap,
                      "selection-enabled": false,
                      "filter-enabled": false,
                      "no-data-text":
                        _vm.$root.strings.notify._no_notifications,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.title) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "description",
                        fn: function (ref) {
                          var row = ref.row
                          return _c(
                            "span",
                            {},
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    effect: "dark",
                                    content: row.description,
                                    placement: "bottom",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          row.description
                                            ? row.description.length > 10
                                              ? row.description.substring(
                                                  0,
                                                  10
                                                ) + "..."
                                              : row.description
                                            : "-"
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        },
                      },
                      {
                        key: "total",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.total) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "queued",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.queued) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "send",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.send) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "failed",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.failed) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "scheduledTo",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row["scheduledTo"]
                                    ? new Date(row["scheduledTo"]).format()
                                    : " - "
                                ) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                      {
                        key: "sentAt",
                        fn: function (ref) {
                          var row = ref.row
                          return _c("span", {}, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  row["sentAt"]
                                    ? new Date(row["sentAt"]).format()
                                    : " - "
                                ) +
                                "\n          "
                            ),
                          ])
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }