<template>
  <div>
    <section ref="section-1" class="tm-margin-large-top">
      <div class="uk-container">
        <h1 class="uk-margin-medium-bottom uk-heading-hero">
          Campagne Email
        </h1>
        <ul class="uk-breadcrumb uk-margin-medium-bottom">
          <li>
            <router-link to="/helpdesk/it-home">
              Support
            </router-link>
          </li>
          <li>
            <span href="/helpdesk/it-campagneemail">
              Campagne Email
            </span>
          </li>
        </ul>

        <hr class="uk-margin-remove">
        <ul class="uk-list uk-list-large uk-margin-medium-top">
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-1')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come creare una nuova e-mail e/o un template
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-2')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come inviare una campagna e-mail
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-3')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come utilizzare correttamente i filtri sulla lista dei destinatari della campagna
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-4')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come attivare i bottoni RSVP e DECLINE
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-5')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come generare un QR quando un invitato conferma
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-6')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come richiedere informazioni aggiuntive ai propri invitati
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-7')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come impostare dei timeslots selezionabili dai propri invitati attraverso la mail di invito
            </a>
          </li>
          <li>
            <a data-uk-scroll="duration: 400; offset: 140" class="hvr-forward" @click="scrollMeTo('article-8')">
              <span
                data-uk-icon="icon: arrow-right" class="uk-icon">
                <svg width="20" height="20" viewBox="0 0 20 20"
                     xmlns="http://www.w3.org/2000/svg" ratio="1">
                  <polyline
                    fill="none" stroke="#000" points="10 5 15 9.5 10 14">
                  </polyline>
                  <line fill="none" stroke="#000" x1="4"
                        y1="9.5" x2="15"
                        y2="9.5">
                  </line>
                </svg>
              </span>
              Come tenere basso il rischio di spam
            </a>
          </li>
        </ul>

        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-1" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come creare una nuova e-mail e/o un template
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nella sezione “email” attraverso il button “crea nuova email” si accede alla sezione in cui si deve:
            <br>
            - Compilare i campi “nome (mittente), email (mittente)” che i vostri invitati si
            ritroveranno come dati caratterizzanti le email che gli verranno inviate. <br>
            - Creare l’email da inviare attraverso il campo “crea mail” (“modifica email” se ne è già stata
            salvata una in precedenza) <br>
            Attraverso EMAILBUILDER, si genererà il proprio invito personalizzato attraverso l’inserimento di
            “moduli” e “contenuti”, avendo anche la possibilità di salvare alcuni modelli come Template
            (“azioni” e poi ”salva come template”) riutilizzabili anche in futuro modificandoli caso per caso. Dopo aver
            creato l'invito e cliccato "salva" dovrete inserire l'oggetto della mail che riceveranno i vostri invitati.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-2" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come inviare una campagna e-mail
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Finita la fase di organizazzione dell’evento è il momento di invitare i propri ospiti, per
            farlo è sufficiente entrare nella sezione “email”, creare la mail da inviare a tutti
            attraverso il button “crea nuova email” e salvarla, una volta effettuato queto il
            dsahboard proporrà una preview dell’email che sarà inviata, sotto sono presenti due
            checkbox che se attivate permetteranno all’invitato di inserire numero e nominativi dei
            suoi accompagnatori in fase di conferma. <br>
            Per inviare la campagna basterà quindi selezionare i destinatari attraverso gli appositi
            filtri, (es. tutti gli invitati, solo chi ha confermato, chi non ha ancora ricevuto nessun
            invito, etc…) e premere sul button “invia email”. Potrete filtrare i vostri invitati anche in base alle
            custom
            fields create, ovvero le informazioni aggiuntive presenti nella guestlist. <br>
            Una volta finito il processo di invio sarà possibile avere report sullo stato della situazione
            (vedi sezione LISTE INVITATI, Come scaricare i report RSVP e check-in).
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-3" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come utilizzare correttamente i filtri sulla lista dei destinatari della campagna
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Prima di avviare la campagna premendo “invia email” si possono selezionare specifici destinatari
            attraverso i filtri: <br>
            - “seleziona solo invitati in attesa” per inviare a coloro che non hanno ne confermato ne
            declinato l’invito<br>
            - “seleziona solo gli invitati confermati” per selezionare coloro che hanno già accettato
            l’invito<br>
            - “seleziona solo gli invitati che non hanno letto i precedenti invii” per coloro che hanno
            ricevuto le mail passate ma non le hanno aperte<br>
            - “seleziona solo invitati non inclusi in invii precedenti” per selezionare chi non ha ancora
            ricevuto nessuna mail<br>
            - "usa custom fields" per filtrare gli invitati in base ai campi extra presenti nella lista (ex. Azienda)
            <br>
            In aggiunta ai filtri si può selezionare e modificare manualmente la lista dei selezionati per l’invio,
            utilizzando le check-box a destra in ogni riga degli invitati. <br>
            Per utilizzare il filtro “seleziona solo gli invitati che non hanno letto i precedenti invii” è necessario
            attendere l’aggiornamento del “mail status” questa azione può richiedere dai 30-45 minuti.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-4" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come attivare i bottoni RSVP e DECLINE
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Guarda come attivare i bottoni di RSVP e Decline nelle tue mail di invito. <br>
            <video width="100%" controls>
              <source src="https://penguinpass-assets.s3-eu-west-1.amazonaws.com/video/Crea_bottone_rsvp.mp4"
                      type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-5" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come generare un QR quando un invitato conferma
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Nel caso in cui si voglia gestire l’accesso degli ospiti attraverso Qr code, sarà necessario inviare
            una email di conferma contenente il QR Code necessario per accedere all’evento. Semplicemente
            dopo aver generato una email di conferma nella sezione “impostazioni” insieme a tutto quello
            che si desidera inserire nella email (es. info e event recap), andrà aggiunto un “contenuto
            dinamico” in un modulo vuoto. <br>
            Cosi facendo gli invitati riceveranno, trascorsi 3-10 minuti dal momento della conferma, una
            email contenente il QR Code e strutturata come in EMAILBUILDER precedentemente.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-6" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come richiedere informazioni aggiuntive ai propri invitati
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Per richiedere informazioni aggiuntive ai propri ospiti è possibile utilizzare i custom fields<br>
            Andando nel tab delle impostazioni, all'interno dell'evento, vedrete in fondo alla lista la sezione
            "Modifica
            Custom Fields", li vedrete i campi extra inseriti dal template al momento dell'upload della vostra guestlist
            e
            avrete anche la possibilità di crearne di nuovi.<br>
            Sotto ai custom fields esistenti vedrete tre checkboxes, selezionando quella accanto a "RSVP" i vostri
            ospiti
            potranno compilare i dati aggiuntivi direttamente dalla mail di invito dopo aver cliccato sul bottone
            contenente
            il link speciale di RSVP, selezionando anche la seconda casella accanto a "RSVP" potrete rendere la
            compilazione
            dell'informazione aggiuntiva obbligatoria.<br>
            Le informazioni compilate compariranno automaticamente nella guestlist del vostro evento.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-7" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come impostare dei timeslots selezionabili dai propri invitati attraverso la mail di invito
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            "Fasce Orarie" che troverete nel tab di impostazioni all'interno del vostro evento, vi permetterà di
            caricare
            file .ics per generare dei timeslots che saranno poi selezionabili dai vostri ospiti dalla mail di RSVP.<br>
            I file .ics sono file in formato standard per calendari, esportabili da applicazioni come Google calendar o
            da
            calendario Yahoo. In seguito al caricamento si genererà una tabella con tutti gli slot presenti nel file.
            Sopra
            la tabella potrete impostare il limite massimo di persone che potranno selezionare ogni singolo slot.<br>
            I vostri ospiti troveranno gli slot dopo aver cliccato il bottone di RSVP nella mail di invito, nella stessa
            pagina delle informazioni aggiuntive, voi troverete gli slot selezionati dai vari ospiti tra le colonne
            della
            vostra guestlist. Potrete infine scaricare il file .ics di tutte le prenotazioni effettuate dal bottone
            "Esporta
            le tue prenotazioni come .ics" che troverai nella sezione fasce orarie.
          </p>
        </article>
        <article class="uk-article uk-margin-xlarge-top">
          <h2 ref="article-8" class="uk-article-title uk-margin-medium-bottom title-and-icon">
            Come tenere basso il rischio di spam
            <a data-uk-totop="" class="tm-totop uk-float-right uk-margin-small-top uk-totop uk-icon"
               @click="scrollMeTo('section-1')">
              <svg width="18" height="20" viewBox="0 0 18 20"
                   xmlns="http://www.w3.org/2000/svg" ratio="1">
                <polyline fill="none" stroke="#000" stroke-width="1.2"
                          points="1 9 9 1 17 9 ">
                </polyline>
              </svg>
            </a>
          </h2>
          <p>
            Di seguito vi diamo alcuni consigli per diminuire il rischio che le vostre campagne email vengano
            classificate come spam dai server di posta. Prima però partiamo dal ranking, che è uno dei parametri dei
            vari programmi di posta e dei server per gestire i messaggi. Inviando con Penguinpass il punteggio è di 89
            su 100 che è molto alto <br><br>

            1. I programmi di posta se leggono parole come events, save the date, rsvp etc possono classificare in
            automatico le mail come “promozioni” e le inseriscono nella cartella relativa su gmail. Quindi consigliamo
            di evitare tali parole nell’oggetto della vostra campagna. <br><br>

            2. Consigliamo che l’indirizzo di invio della mail (il mittente) non abbia parole come events, marketing
            etc. per esempio events@cliente.com. <br><br>

            3. Se ne avete la possibilità vi consigliamo di verificare il vostro dominio al posto del singolo indirizzo
            email.<br><br>

            4. Cercate di fare in modo che le mail inviate non abbiano solo un immagine (l’invito) e come testo solo il
            “gentile nome + cognome”. E' molto importante caricare in modo equilibrato testo e immagini (i software di
            posta elettronica usano anche questo algoritmo per classificare le mail, il rapporto tra testo e immagini).
            In sostanza si deve scrivere del testo, e poi le immagini. Maggiore sarà la proporzione di testo minore il
            rischio che la vostra campagna venga classificata come "spam" o "promozione".<br><br>

            5. Peso immagini: anche questo è un fattore importante. Meno pesano le immagini più velocemente si vedono e
            sono meno “sospette” ai programmi di posta che proteggono da possibili virus o malware che hanno bisogno di
            un peso minimo. L’ideale sarebbe allegare immagini/inviti tra i 50kb e i 150kb. <br><br>

            6. Si consiglia di scrivere in minuscolo, o almeno non tutto in lettere capitali. La scrittura in
            stampatello nell’oggetto condiziona + del 10% del pericolo di essere classificati come "spam".<br><br>

            7. Un ultimo suggerimento è mandare almeno la prima volta che si fa una campagna, agli indirizzi di cui non
            vedete aperta la mail o che non hanno fatto rsvp (su Penguinpass ci sono i filtri appositi), una mail a
            parte (non con Penguinpass né con altri sistemi tipo mailchimp etc), ma con un client di posta normale
            incollando gli indirizzi e che oltre a fare da reminder per l’evento li invita anche a controllare nello
            spam o nelle altre cartelle eventuali mail ricevute da voi. Una volta che l’utente, su vostra richiesta, ha
            spostato la mail in quelle ricevute il sistema di posta capisce che la volta successiva da quell’indirizzo
            la mail dev’essere visualizzata.
          </p>
        </article>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "HelpDeskCampagneEmailIT",
  data() {
    return {};
  },
  methods: {
    scrollMeTo(refName) {
      const element = this.$refs[refName];
      const top = this.$refs["section-1"].offsetTop + element.offsetTop;
      this.$parent.$parent.$parent.$parent.$parent.$refs["main-container"].$el.scrollTo({
        left: 0,
        top: top,
        behavior: "smooth"
      });
    }
  }
};
</script>
