<template>
  <div>
    <div
      id="modalTemplatePageBuilder"
      class="modal fade in"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="false"
      style="display: block"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" aria-hidden="true" @click="fadeout">×</button>
            <h4 id="myModalLabel" class="modal-title">
              PENGUINPASS PAGE BUILDER - SELECT TEMPLATE FROM THE OTHER EVENTS
            </h4>
          </div>
          <div class="modal-body">
            <transition name="component-fade-fast-list-template" mode="out-in" appear>
              <div id="template-list">
                <div v-if="!templateLoaded" class="loader-container">
                <PulseLoader color="#f7a30c" />
                </div>
                <PageBuilderSelectTemplates
                  v-else
                  :template-list="templateList"
                  @templateSelected="templateSelected"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
      <div class="modalBackgroundColor" @click="false && fadeout">&nbsp;</div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.btn-primary {
  color: #fff !important;
  background-color: #f7a30c !important;
  border-color: #f7a30c !important;
}

#template-list {
  height: 100%;
}
.loader-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

#start-menu {
  display: block;
  height: 100%;
}

#template_save_area {
  background-color: #505659;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;

  #template-save-command-area {
    width: 450px;
    margin: auto;
    position: absolute;
    top: 35%;
    left: 0;
    bottom: 0;
    right: 0;

    input {
      font-size: 30px;
      color: #565656;
      display: block;
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
    }

    button#email-template-save {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-update {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    button#email-template-cancel {
      padding: 10px;
      display: block;
      width: 100%;
      font-size: 30px;
      text-transform: uppercase;
    }
  }
}

#modalTemplatePageBuilder {
  background-color: rgba(51, 51, 51, 0.6);

  .modal-header {
    margin-top: 5px !important;
  }

  .modal-dialog {
    width: 90%;
    height: 90%;
    padding: 0;
    background-color: #ffffff;
    z-index: 1;
  }

  .modal-body {
    background-color: #505659;
    height: calc(100% - 61px);
  }

  .modal-content {
    height: 100%;
    border-radius: 0;
  }

  .modal-btn-close {
    background-color: #333333;
    color: #fff !important;
  }

  .modal-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .modalBackgroundColor {
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 0;
  }
}
</style>
<script>
import PageBuilderSelectTemplates from "./ModalPageBuilder/PageBuilderSelectTemplates.vue";

import { MY_EVENTS } from "@/graphql";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

String.prototype.replaceAll = function (find, replace) {
  return this.replace(new RegExp(find, "g"), replace);
};
export default {
  components: {
    PageBuilderSelectTemplates,
    PulseLoader
  },
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      mainContext: this,
      componentName: "modal-template-page-builder",
      string: {
        modalTemplatePageBuilder: {},
      },
      templateList: [],
      templateLoaded: false,
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  mounted() {
    this.getTemplates();
  },
  methods: {
    getTemplates: function () {
      if (this.templateLoaded) {
        return;
      }

      const context = this;
      return this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: MY_EVENTS,
          variables: { statuses: ["ACTIVE", "INACTIVE"] },
        })
        .then((response) => {
          context.templateLoaded = true;
          if (response.errors) {
            console.log(response.errors);
            return;
          }
          if (response.data && response.data.myEvents) {
            //backward compatibility, get pages created when the page was stored into the event - To be removed in future
            response.data.myEvents.forEach((event) => {
              if (event.virtualRoomJSON !== null) {
                context.templateList.push({
                  id: event.id,
                  code: event.virtualRoomJSON,
                  displayName: event.name,
                });
              }
            });

            //Get pages from the virtualRoomPages document
            response.data.myEvents.forEach((event) => {
              if (
                event.virtualRoomPages &&
                event.virtualRoomPages.length > 0 &&
                event.id !== context.eventId
              ) {
                event.virtualRoomPages.forEach((virtualRoomPage, pageNumber) => {
                  context.templateList.push({
                    id: event.id + "-" + pageNumber,
                    code: virtualRoomPage.json,
                    displayName: `${event.name} - Page ${pageNumber}`,
                  });
                });
              }
            });

          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    templateSelected: function (data) {
      this.$emit("templateSelected", data);
    },
    fadeout: function () {
      this.$root.bus.emit("modalView", "fadeout");
      /** Emit di al gestore globale */
    },
  },
};
</script>
