var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attachments-component", attrs: { id: "attachments" } },
    [
      _c("h4", { staticClass: "form-section-title first" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.string.attachments._selectattachments) +
            "\n      "
        ),
        _c("small", [
          _vm._v(_vm._s(_vm.string.attachments._putattachmentstoevent)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "smallseparator" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-section upload" },
        [
          _c(
            "transition",
            {
              attrs: {
                name: "component-fade-fast",
                mode: "out-in",
                appear: "",
              },
            },
            [
              _vm.loadingEffectshow
                ? _c("LoadingCover", {
                    attrs: {
                      "loading-cover-msg":
                        _vm.string.attachments._loadingMessage,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "alert alert-warning", attrs: { role: "alert" } },
            [_vm._v(_vm._s(_vm.string.attachments._help))]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedAttachmentType,
                  expression: "selectedAttachmentType",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "select-attachments", name: "select-attachments" },
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedAttachmentType = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  _vm.onSelectAttachment,
                ],
              },
            },
            _vm._l(_vm.filetypes, function (type) {
              return _c(
                "option",
                {
                  key: type.id,
                  domProps: { value: type.id, selected: type.selected },
                },
                [_vm._v(_vm._s(type.name))]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
            _c("input", {
              staticClass: "btn btn-default uploadfilefield",
              attrs: {
                id: "fileToUploadAttachments",
                type: "file",
                name: "fileToUpload",
                accept: "application/pdf",
              },
              on: { change: _vm.onFileChange },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-1" }, [
              _c(
                "button",
                {
                  staticClass: "save",
                  attrs: { id: "pdf-attachments-upload-btn", type: "save" },
                  on: { mousedown: _vm.submit },
                },
                [_vm._v(_vm._s(_vm.string.attachments._upload))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.documentUploaded.length > 0
            ? _c("div", { staticClass: "attachmentsTable" }, [
                _c("h4", { staticClass: "form-section-title second" }, [
                  _vm._v("Lista allegati caricati"),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.documentUploaded, function (document) {
                    return _c(
                      "li",
                      { key: document.Key, staticClass: "list-group-item" },
                      [
                        _c("span", { staticClass: "attachment-category" }, [
                          _vm._v(_vm._s(document.Key) + " -"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "attachment-item",
                            attrs: { href: document.Key, target: "_blank" },
                          },
                          [_vm._v(_vm._s(document.Key))]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "delete-attachment",
                            staticStyle: { "margin-top": "-12px" },
                            attrs: {
                              "data-toggle": "tooltip_attachment",
                              "data-placement": "top",
                              title: _vm.string.attachments._deleteAttachment,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteItem(document.id)
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fa fa-times" }, [
                              _vm._v(" "),
                            ]),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }