<template>
  <div>
    <section-title
      :title="$root.strings.organizerSettings._customCategoriesHeader"
      :subtitle="$root.strings.organizerSettings._customCategoriesSubtitle"
    />
    <transition id="loader-section" name="component-fade-fast" mode="out-in" appear>
      <LoadingCover v-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />
    </transition>
    <transition id="add-new-identity" name="component-fade-fast" mode="out-in" appear>
      <div class="team-custom-fields-manager advance-settings-component-container">
        <button-tab
          style="margin-bottom: 30px"
          :tabs="tabs"
          :initial-tab="selectedTab"
          @tabChanged="onTabChanged"
        />
        <div v-if="selectedTab === 'SHOW_CATEGORY_FORM'" class="component-form">
          <div>
            <input
              v-model.trim="newCustomCategory"
              aria-label=""
              type="text"
              name="policy_name"
              :placeholder="$root.strings.customCategories._label"
              class="form-control"
              style="width: 100%"
            />
          </div>
          <div class="form-section submit">
            <a type="button" class="btn gray-btn save" name="submit" @click="preCreateCategory">
              {{ $root.strings.customCategories._submit }}
            </a>
          </div>
        </div>
        <div v-else-if="selectedTab === 'SHOW_CATEGORY_TABLE'" class="custom-fields-list">
          <data-table
            ref="table"
            :get-data="getData"
            :filter-enabled="false"
            :show-pagination="false"
            :columns="columns"
          >
            <span slot="label" slot-scope="{ row }">
              <input
                v-if="rowInEdit === row.key"
                v-model.trim="editItem.label"
                type="text"
                class="form-control"
              />
              <div v-else>
                {{ row.label }}
              </div>
            </span>
            <span slot="deletedAt" slot-scope="{ row }">
              <div>
                {{ row.deletedAt ? `${new Date(row.deletedAt).format()}` : "-" }}
              </div>
            </span>
            <span slot="Actions" slot-scope="{ row }" style="text-align: center">
              <a
                v-if="!row.deletedAt"
                :title="rowInEdit === row.key ? 'save changes' : 'open editor'"
                class="action_link"
                style="margin-right: 5px"
                @click="() => (rowInEdit === row.key ? saveRow(row) : openRow(row))"
              >
                <i :class="rowInEdit === row.key ? 'fa fa-check' : 'fa fa-pencil'"></i>
              </a>
              <a
                v-if="!row.deletedAt"
                :title="rowInEdit === row.key ? 'discard changes' : 'remove option'"
                class="action_link"
                style="margin-right: 5px"
                @click="
                  () => {
                    rowInEdit === row.key ? closeRow(row) : preDeleteCategory(row);
                  }
                "
              >
                <i :class="rowInEdit === row.key ? 'fa fa-times' : 'fa fa-trash'"></i>
              </a>
              <a
                v-if="row.deletedAt"
                title="enable option"
                class="action_link"
                style="margin-right: 5px"
                @click="undoDeleteCustomCategory(row)"
              >
                <i class="fa fa-undo"></i>
              </a>
            </span>
          </data-table>
        </div>
      </div>
    </transition>
  </div>
</template>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../../assets/css/bootstrap-toggle.css";
@import "../../assets/css/customsidebar.css";
@import "../../assets/css/formstyle.css";
@import "../../assets/css/preloader.css";
@import "../../assets/less/style.css";
@import "../../assets/less/createevent.css";
@import "../../assets/less/settings.css";

#loadingCover {
  background-color: rgba(107, 104, 102, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingCover .loadingCoverMessage {
  width: auto;
  text-transform: uppercase;
  font-size: 30px;
}
a.settings {
  cursor: pointer;
  color: #f7a30c !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.settings:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
.team-custom-fields-manager {
  color: #ffffff;
}
.custom-fields-list {
  width: 100%;
}
.custom-fields-list .field {
  padding: 10px 0 10px 0;
  margin: 0 0 5px 0;
}
.custom-fields-list .field.custom-field-name {
  border-right: 1px solid #ddd;
  padding-right: 40px;
}
.custom-fields-list .field.custom-field-mandatory {
  padding-left: 40px;
}
.custom-fields-list .field i {
  font-size: 20px;
}
.custom-fields-list .field i:hover {
  cursor: pointer;
  color: #f7a30c;
}
.custom-fields-list .custom-field {
  margin-bottom: 40px;
}
.custom-fields-list .custom-field-recap {
  border-bottom: 1px solid #dddddd;
}
.custom-fields-list .custom-field-name {
  display: inline-block;
  width: 29%;
}
.custom-fields-list .custom-field-edit {
  display: inline-block;
  width: 70%;
  padding-left: 15px;
}
.custom-fields-list .actions {
  display: inline-block;
  width: 29%;
  text-align: left;
  vertical-align: top;
  padding-right: 40px;
}
.custom-fields-list .text {
  display: inline-block;
  width: 59%;
}
.custom-fields-list .custom-field-pagination {
  text-align: center;
}
.custom-fields-list .custom-field-pagination .pagination li a {
  color: white !important;
}
.custom-fields-list .custom-field-result-download {
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}
.custom-fields-list .custom-field-result-download .btn {
  text-transform: uppercase;
  color: #ffffff;
}

/*pagination color*/

.pagination > li > a,
.pagination > li > span {
  color: white !important;
}

/*checkbox*/

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
}
.control-radio .control_indicator {
  border-radius: 0;
}

.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #cccccc;
}

.control input:checked ~ .control_indicator {
  background: #000;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #000;
}
.control input:disabled ~ .control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: "";
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #7b7b7b;
}

/** Disable Classes */

.form-container {
  position: relative;
}

/** /Disable Classes */

.form-section-title {
  border-bottom: solid 1px #dddddd;
  padding: 20px 0;
  color: #f7a30c;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.form-section-title small {
  font-size: 12px;
  display: block;
  line-height: 1.5;
  margin-top: 5px;
  color: #e8e8e8;
}
select.form-control::placeholder {
  color: #fff !important;
}
.form-control option.optionStyle {
  background: #000d;
}

.form-section button {
  margin: 0 !important;
}
.form-section.submit {
  text-align: center;
  margin-top: 20px;
}
.component-menu .btn {
  width: 100%;
  color: white;
  font-family: inherit;
  font-weight: 400;
}
.component-menu .label {
  background-color: #f7a30c;
  color: white;
  padding: 15px;
  border-width: 0px;
  text-transform: uppercase;
  margin-top: 20px;
}
.component-actions {
  margin: 20px 0 20px 0;
}
.component-menu {
  margin: 0 -15px 20px -15px;
}
.label {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.42857143 !important;
  text-align: center !important;
  vertical-align: middle !important;
  width: 100% !important;
  border: 1px solid transparent !important;
  border-radius: 0px;
}

.single-action {
  display: flex;
}
.single-action .control {
  flex-basis: 50%;
}
.custom-field-edit-action {
  margin-right: 10px;
}
</style>
<script>
import LoadingCover from "@/components/LoadingCover.vue";

import { MY_PAGINATED_EVENTS, MY_TEAM, UPDATE_MY_TEAM, UPDATE_MANY_EVENTS } from "@/graphql";
import { mapGetters } from "vuex";
import SectionTitle from "../ui/SectionTitle.vue";
import ButtonTab from "../ui/ButtonTab.vue";
import DataTable from "../DataTable.vue";
import { slugify } from "@/utils";

export default {
  components: {
    LoadingCover,
    SectionTitle,
    ButtonTab,
    DataTable,
  },
  data() {
    return {
      name: "CustomCategoryContainer",
      loadingEffectshow: false,
      loadingMessage: "",
      newCustomCategory: "",
      showAddNew: false,
      componentDisabled: false /** Disable Component */,
      tabs: [{ key: "SHOW_CATEGORY_FORM" }, { key: "SHOW_CATEGORY_TABLE" }],
      selectedTab: "SHOW_CATEGORY_TABLE",
      columns: [
        {
          prop: "label",
          label: "Name",
        },
        {
          prop: "deletedAt",
          label: "Deleted At",
        },
        {
          label: "Actions",
          width: "230",
        },
      ],
      editItem: {
        label: "",
      },
      rowInEdit: "",
    };
  },
  computed: {
    ...mapGetters(["user", "team", "customCategories"]),
  },
  created() {},
  methods: {
    onTabChanged(tabV) {
      this.selectedTab = tabV;
    },
    async getData({ filter, page, query, sortParams, pageSize }) {
      return Promise.resolve({ data: this.customCategories, total: this.customCategories.length });
    },
    async preCreateCategory() {
      const label = this.newCustomCategory;
      if (!label) {
        this.$root.bus.emit("simpleToast", "Write a category name");
        return;
      }
      const key = slugify(label);
      const exists = this.customCategories.some((item) => item.key === key);
      if (exists) {
        this.$root.bus.emit("simpleToast", "This category already exists, try something new");
        return;
      }
      return this.createCategory({ key, label });
    },
    async createCategory({ key, label }) {
      const newCategories = [
        ...this.customCategories.map((item) => ({
          key: item.key,
          label: item.label,
          deletedAt: item.deletedAt,
        })),
        { key, label },
      ];
      this.saveTeam(newCategories).then(() => {
        this.newCustomCategory = "";
        this.onTabChanged("SHOW_CATEGORY_TABLE");
        this.$root.bus.emit("simpleToast", this.$root.strings.customCategories._created);
        if (this.$refs.table) {
          this.$refs.table.getTableData();
        }
      });
    },

    openRow(row) {
      this.editItem = {
        label: row.label,
      };
      this.rowInEdit = row.key;
    },
    closeRow(row) {
      this.rowInEdit = "";
      this.editItem = {
        label: "",
      };
    },
    async saveRow(row) {
      const newRow = { label: this.editItem.label, key: row.key, deletedAt: row.deletedAt };
      const newCategories = this.customCategories.map((item) => {
        if (item.key === row.key) {
          return newRow;
        }
        return { label: item.label, key: item.key, deletedAt: item.deletedAt };
      });
      this.saveTeam(newCategories).then(() => {
        if (this.$refs.table) {
          this.$refs.table.getTableData();
        }
        this.closeRow(row);
      });
      this.updateEvents({ customCategory: [row.key], status: ["ACTIVE", "INACTIVE"] }, { customCategoryLabel: newRow.label });
    },
    async preDeleteCategory(row) {
      const safeToDelete = await this.isSafeToDelete(row);
      if (typeof safeToDelete === "boolean") {
        if (!safeToDelete) {
          // ask for confirm
          this.$confirm(this.$root.strings.customCategories._warnBeforeDelete)
            .then(() => {
              this.deleteCategory(row, false);
            })
            .catch(() => {});
        } else {
          this.deleteCategory(row, true);
        }
      }
    },
    async isSafeToDelete(row) {
      return this.$root.$apollo
        .query({
          query: MY_PAGINATED_EVENTS,
          variables: {
            customCategories: [row.key],
            statuses: ["ACTIVE", "INACTIVE"],
            pageInfo: {
              pageSize: 5,
              sortField: "createdAt",
              sortOrder: -1,
              currentPage: 1,
            },
          },
        })
        .then(({ data, errors }) => {
          if (errors) {
            console.log("error " + errors[0]);
            return null;
          } else {
            if (data && data.myPaginatedEvents) {
              return data.myPaginatedEvents.total < 1;
            }
          }
          return null;
        })
        .catch((error) => {
          console.log("error " + error);
          return null;
        });
    },
    async deleteCategory(row, hard) {
      let newCategories = this.customCategories.map((item) => {
        if (item.key === row.key) {
          return { label: row.label, key: row.key, deletedAt: new Date() };
        }
        return { label: item.label, key: item.key, deletedAt: item.deletedAt };
      });
      if (hard) {
        newCategories = newCategories.filter((item) => item.key !== row.key);
      }
      this.saveTeam(newCategories).then(() => {
        if (this.$refs.table) {
          this.$refs.table.getTableData();
        }
      });
    },
    async undoDeleteCustomCategory(row) {
      const newCategories = this.customCategories.map((item) => {
        if (item.key === row.key) {
          return { label: row.label, key: row.key, deletedAt: null };
        }
        return { label: item.label, key: item.key, deletedAt: item.deletedAt };
      });
      this.saveTeam(newCategories).then(() => {
        if (this.$refs.table) {
          this.$refs.table.getTableData();
        }
      });
    },
    async saveTeam(updatedCategories) {
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_MY_TEAM,
          variables: {
            updates: {
              customCategories: updatedCategories,
            },
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors[0]);
          } else {
            this.$store.commit("SET_TEAM_FIELD", { customCategories: updatedCategories });
          }
        })
        .catch((error) => {
          console.log(error.message);
          this.$root.bus.emit("simpleToast", this.$root.strings.customCategories._failed);
        });
    },
    async updateEvents(where, updates) {
      return this.$root.$apollo
        .mutate({
          mutation: UPDATE_MANY_EVENTS,
          variables: {
            where,
            updates,
          },
        })
        .then((value) => {
          if (value.errors) {
            console.log("error " + value.errors[0]);
          } else {
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    },
  },
};
</script>
