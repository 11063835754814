var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "guest-list-container", attrs: { direction: "vertical" } },
    [
      _c(
        "div",
        {
          staticClass: "group-manager-members",
          staticStyle: { "margin-top": "55px" },
        },
        [
          !_vm.isExhibitor
            ? _c("h4", { staticClass: "form-section-title" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.string.guestlist._title) + "\n      "
                ),
                _c("small", [_vm._v(_vm._s(_vm.string.guestlist._subtitle))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isExhibitor
            ? _c("div", { staticClass: "smallseparator" })
            : _vm._e(),
          _vm._v(" "),
          !_vm.isExhibitor
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn gray-btn import-man",
                      attrs: { title: _vm.string.guestlist._add },
                      on: { click: _vm.toggleInsertSingle },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-plus",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.guestlist._add) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn gray-btn upload",
                      attrs: { title: _vm.string.guestlist._upload },
                      on: { click: _vm.toggleUploadList },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-upload",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.guestlist._upload) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.hubiloActive
                  ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn gray-btn upload",
                          attrs: { title: _vm.string.guestlist._hubiloEvent },
                          on: { click: _vm.toggleInsertHubilo },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.guestlist._hubiloEvent) +
                              "\n        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.hubiloActive && _vm.newHubiloEventId
                  ? _c("div", { staticClass: "col-sm-12 col-md-6 col-lg-6" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn gray-btn upload",
                          attrs: { title: _vm.string.guestlist._reload },
                          on: { click: _vm.reloadHubiloEvent },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.string.guestlist._reload) +
                              "\n        "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row tabs-content" },
            [
              _c(
                "transition",
                { attrs: { name: "component-fade-fast", mode: "out-in" } },
                [
                  _vm.tabValue === "uploadlist"
                    ? _c(
                        "div",
                        { key: "uploadlist" },
                        [
                          _c("ListUpload", {
                            attrs: {
                              "event-id": _vm.eventId,
                              "father-name": _vm.componentName,
                            },
                            on: { component_event: _vm.closeTab },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tabValue === "insertsingle"
                    ? _c(
                        "div",
                        { key: "insertsingle" },
                        [
                          _c("ListSingleItem", {
                            attrs: {
                              team: _vm.team,
                              "event-id": _vm.eventId,
                              "father-name": _vm.componentName,
                            },
                            on: { component_event: _vm.closeTab },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.tabValue === "inserthubilo"
                    ? _c("div", { key: "inserthubilo" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.newHubiloEventId,
                              expression: "newHubiloEventId",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.newHubiloEventId },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.newHubiloEventId = $event.target.value
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-default col-sm-12 col-md-6 col-lg-6",
                            on: {
                              click: function ($event) {
                                return _vm.saveHubiloId()
                              },
                            },
                          },
                          [_vm._v("\n            Save\n          ")]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.uploadStatus !== "NULL"
            ? _c(
                "h4",
                { staticClass: "form-section-title second progress-title" },
                [
                  _vm._v("\n      " + _vm._s(_vm.uploadStatusMessage) + " "),
                  _vm.uploadStatus !== "PENDING"
                    ? _c("loading-icon")
                    : _vm._e(),
                  _vm._v(" "),
                  _c("small"),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.uploadStatus !== "NULL"
            ? _c("el-progress", {
                staticClass: "progress-container",
                attrs: {
                  "stroke-width": 24,
                  percentage: _vm.uploadProgress,
                  "show-text": false,
                  color: "#f7a30c",
                  format: _vm.formatProgress,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h4", { staticClass: "form-section-title second" }, [
            _vm._v("\n      " + _vm._s(_vm.string.guestlist._list) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "smallseparator" }),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-users",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v("\n      " + _vm._s(_vm.string.guestlist._guestnumber)),
            _c("strong", [_vm._v(_vm._s(_vm.totalGuests))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-download",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "linkStyle",
                attrs: { disabled: _vm.exportingReport },
                on: {
                  click: function ($event) {
                    return _vm.fetchDataAndExportReport(false)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._downloadlistAll) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-download",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "linkStyle",
                attrs: { disabled: _vm.exportingReport },
                on: {
                  click: function ($event) {
                    return _vm.fetchDataAndExportReport(true)
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._downloadlist) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-download",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "linkStyle",
                attrs: { disabled: _vm.exportingReport },
                on: {
                  click: function ($event) {
                    return _vm.fetchDataAndExportPlusOnes()
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._downloadPlusOneList) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-download",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "linkStyle",
                attrs: { disabled: _vm.exportingReport },
                on: { click: _vm.fetchActivitiesAndExport },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._downloadActivities) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "downloads_style" }, [
            _c("i", {
              staticClass: "fa fa-download",
              staticStyle: { "margin-right": "15px" },
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "linkStyle",
                attrs: { disabled: _vm.exportingReport },
                on: { click: _vm.fetchAttachmentsAndExport },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._downloadAttachments) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          !_vm.isExhibitor
            ? _c("div", { staticClass: "downloads_style" }, [
                _c("i", {
                  staticClass: "fa fa-times",
                  staticStyle: { "margin-right": "20px" },
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "simple",
                    staticStyle: { padding: "0" },
                    on: { click: _vm.deleteListAllFunction },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.string.guestlist._removelist) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.badgePrintingEnabled === true
            ? _c(
                "div",
                { staticClass: "downloads_style" },
                [
                  _c("custom-checkbox", {
                    staticStyle: { "margin-right": "18px" },
                    model: {
                      value: _vm.autoPrintBadge,
                      callback: function ($$v) {
                        _vm.autoPrintBadge = $$v
                      },
                      expression: "autoPrintBadge",
                    },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.string.guestlist._autoPrint) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.badgePrintingEnabled === true
            ? _c(
                "div",
                { staticClass: "downloads_style" },
                [
                  _c("custom-checkbox", {
                    staticStyle: { "margin-right": "18px" },
                    model: {
                      value: _vm.localPrintBadge,
                      callback: function ($$v) {
                        _vm.localPrintBadge = $$v
                      },
                      expression: "localPrintBadge",
                    },
                  }),
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.string.guestlist._localPrint) +
                      "\n    "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "audience-filter-area" },
            [
              _c("h4", { staticClass: "form-section-title first" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.string.guestlist._audience_title) +
                    "\n        "
                ),
                _c("small", [
                  _vm._v(_vm._s(_vm.string.guestlist._audience_subtitle)),
                ]),
              ]),
              _vm._v(" "),
              _c("audience-option-group", {
                attrs: {
                  "hide-counter": true,
                  "stop-calculate-eligible-recipient-count": true,
                  "audience-options": _vm.audienceOptions,
                  "event-id": _vm.eventId,
                  "custom-field-filter": _vm.customFieldFilter,
                  "on-audience-option-changed": _vm.onAudienceOptionChanged,
                  "on-audience-size-changed": _vm.onAudienceSizeChanged,
                  "initial-value": _vm.initialOption,
                },
              }),
              _vm._v(" "),
              _vm.audienceOption === "CUSTOM_FIELD"
                ? _c("custom-field-filter", {
                    key: "CUSTOM_FIELD",
                    attrs: {
                      "custom-field-map": _vm.customFieldMapFiltered,
                      "on-filter-changed": _vm.onFilterChanged,
                    },
                  })
                : _vm.audienceOption === "TIME_SLOT"
                ? _c("custom-field-filter", {
                    key: "TIME_SLOT",
                    attrs: {
                      "custom-field-map": _vm.timeSlotMap,
                      "on-filter-changed": _vm.onFilterChanged,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "bottom-margin": "3px" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "text-align": "right",
                    "padding-bottom": "20px",
                  },
                  attrs: { span: 24 },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "el-form-item_label",
                      staticStyle: { "line-height": "40px" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.string.guestlist._attendeePartnersMax) +
                          ":\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("NumericFieldInput", {
                    attrs: { "initial-value": _vm.maxGuestsPerMember },
                    on: { change: _vm.setEventMaxAcquaintance },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group-manager-members-list" },
            [
              _c("data-table", {
                ref: "table",
                attrs: {
                  lazy: true,
                  "get-data": _vm.getData,
                  columns: _vm.columns,
                  "selection-enabled": "",
                  "bulk-options": _vm.bulkOptions,
                  "on-bulk-action": _vm.onBulkAction,
                  "page-size": 15,
                },
                on: {
                  loaded: function ($event) {
                    _vm.ready = true
                  },
                  "sort-change": _vm.onSortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "memberName",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editItem.memberName,
                                          expression: "editItem.memberName",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.columns[0].label,
                                      },
                                      domProps: {
                                        value: _vm.editItem.memberName,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editItem,
                                              "memberName",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.markRowModified(row)
                                          },
                                        ],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.member.name) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "memberSurname",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editItem.memberSurname,
                                          expression: "editItem.memberSurname",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.columns[1].label,
                                      },
                                      domProps: {
                                        value: _vm.editItem.memberSurname,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editItem,
                                              "memberSurname",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.markRowModified(row)
                                          },
                                        ],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.member.surname) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "memberEmail",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editItem.memberEmail,
                                          expression: "editItem.memberEmail",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.columns[2].label,
                                      },
                                      domProps: {
                                        value: _vm.editItem.memberEmail,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editItem,
                                              "memberEmail",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.markRowModified(row)
                                          },
                                        ],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.member.email.includes(
                                              "internal.penguinpass.it"
                                            )
                                              ? ""
                                              : row.member.email
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "listEmails",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-control email-list",
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              id: "newEmail_" + row.id,
                                              "popper-class":
                                                "email-edit-popper",
                                              width: "567",
                                              trigger: "click",
                                              placement: "bottom",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { attrs: { gutter: 20 } },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 20 } },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName:
                                                            "v-model.trim",
                                                          value: _vm.newEmail,
                                                          expression:
                                                            "newEmail",
                                                          modifiers: {
                                                            trim: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        "aria-label": "",
                                                        type: "email",
                                                        placeholder:
                                                          "Add email",
                                                      },
                                                      domProps: {
                                                        value: _vm.newEmail,
                                                      },
                                                      on: {
                                                        keyup: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.addNewEmail(
                                                            row
                                                          )
                                                        },
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.newEmail =
                                                            $event.target.value.trim()
                                                        },
                                                        blur: function (
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                    attrs: { span: 4 },
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "action_link",
                                                        staticStyle: {
                                                          "font-size": "32px",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.addNewEmail(
                                                              row
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-plus-circle",
                                                        }),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.editItem.listEmails,
                                              function (email) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: email.title,
                                                    staticStyle: {
                                                      padding: "5px 0",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-row",
                                                      { attrs: { gutter: 20 } },
                                                      [
                                                        _c(
                                                          "el-col",
                                                          {
                                                            attrs: { span: 20 },
                                                          },
                                                          [
                                                            email.edit
                                                              ? _c("input", {
                                                                  directives: [
                                                                    {
                                                                      name: "model",
                                                                      rawName:
                                                                        "v-model.trim",
                                                                      value:
                                                                        email.title,
                                                                      expression:
                                                                        "email.title",
                                                                      modifiers:
                                                                        {
                                                                          trim: true,
                                                                        },
                                                                    },
                                                                    {
                                                                      name: "focus",
                                                                      rawName:
                                                                        "v-focus",
                                                                    },
                                                                  ],
                                                                  staticClass:
                                                                    "form-control",
                                                                  staticStyle: {
                                                                    padding:
                                                                      "10px",
                                                                    "text-align":
                                                                      "left",
                                                                    "background-color":
                                                                      "transparent",
                                                                    color:
                                                                      "white",
                                                                  },
                                                                  attrs: {
                                                                    type: "email",
                                                                    "aria-label":
                                                                      "",
                                                                  },
                                                                  domProps: {
                                                                    value:
                                                                      email.title,
                                                                  },
                                                                  on: {
                                                                    keyup:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !$event.type.indexOf(
                                                                            "key"
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter",
                                                                            13,
                                                                            $event.key,
                                                                            "Enter"
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        return (function () {
                                                                          email.edit = false
                                                                          _vm.$emit(
                                                                            "update"
                                                                          )
                                                                        })(
                                                                          $event
                                                                        )
                                                                      },
                                                                    input: [
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          email,
                                                                          "title",
                                                                          $event.target.value.trim()
                                                                        )
                                                                      },
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.markRowModified(
                                                                          row,
                                                                          {
                                                                            updateEmailList: true,
                                                                          }
                                                                        )
                                                                      },
                                                                    ],
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$forceUpdate()
                                                                    },
                                                                  },
                                                                })
                                                              : _c(
                                                                  "p",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        color:
                                                                          "white",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        " +
                                                                        _vm._s(
                                                                          email.title
                                                                        ) +
                                                                        "\n                      "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                            },
                                                            attrs: { span: 2 },
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "action_link",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      _
                                                                    ) {
                                                                      email.edit =
                                                                        !email.edit
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  class:
                                                                    email.edit ===
                                                                    false
                                                                      ? "fa fa-pencil-square-o"
                                                                      : "fa fa-check",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-col",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "center",
                                                            },
                                                            attrs: { span: 2 },
                                                          },
                                                          [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "action_link",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "16px",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      _
                                                                    ) {
                                                                      _vm.deleteEmail(
                                                                        email
                                                                      )
                                                                      _vm.markRowModified(
                                                                        row,
                                                                        {
                                                                          updateEmailList: true,
                                                                        }
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-trash-o",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "name-wrapper-2-borderless",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _c("el-tag", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.string.datagrid
                                                        ._edit_emails
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "email-list" },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              id: "listEmail_" + row.id,
                                              width: "250",
                                              trigger: "click",
                                              placement: "bottom",
                                              "popper-class":
                                                "email-list-popper",
                                            },
                                          },
                                          [
                                            _vm._l(
                                              row["member"]["listEmails"],
                                              function (email) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: email,
                                                    staticClass:
                                                      "row list-email-row",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(email) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "name-wrapper-2",
                                                attrs: { slot: "reference" },
                                                slot: "reference",
                                              },
                                              [
                                                _c("el-tag", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.string.datagrid
                                                        ? _vm.string.datagrid
                                                            ._list_emails
                                                        : ""
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "memberTelephone",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.editItem.memberTelephone,
                                          expression:
                                            "editItem.memberTelephone",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "text",
                                        placeholder: _vm.columns[4].label,
                                      },
                                      domProps: {
                                        value: _vm.editItem.memberTelephone,
                                      },
                                      on: {
                                        input: [
                                          function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.editItem,
                                              "memberTelephone",
                                              $event.target.value
                                            )
                                          },
                                          function ($event) {
                                            return _vm.markRowModified(row)
                                          },
                                        ],
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(row.member.telephone) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "acquaintance",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          { staticStyle: { "text-align": "center" } },
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("NumericFieldInput", {
                                      staticClass: "p-table-cell",
                                      attrs: {
                                        "initial-value": row.maxAcquaintance,
                                      },
                                      on: {
                                        change: function (val) {
                                          _vm.setMaxAcquaintance(val)
                                          _vm.markRowModified(row)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.acquaintance +
                                              "/" +
                                              row.maxAcquaintance
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "deliveryStatus",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        "aria-label": "",
                                        type: "text",
                                        readonly: "true",
                                      },
                                      domProps: {
                                        value: row.deliveryStatus.toLowerCase(),
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    [
                                      "SEND",
                                      "DELIVERED",
                                      "OPENED",
                                      "CLICKED",
                                      "BOUNCED",
                                      "REJECTED",
                                      "COMPLAINED",
                                    ].indexOf(row.deliveryStatus) !== -1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                              "line-height": "48px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content: row.deliveryStatus
                                                    ? row.deliveryStatus ===
                                                      "COMPLAINED"
                                                      ? "unsubscribed"
                                                      : row.deliveryStatus.toLowerCase()
                                                    : "-",
                                                  placement: "bottom",
                                                },
                                              },
                                              [
                                                _c(
                                                  "font-awesome-layers",
                                                  { staticClass: "fa-lg" },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["fas", "circle"],
                                                        transform: "grow-6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("font-awesome-icon", {
                                                      style: { color: "black" },
                                                      attrs: {
                                                        icon: _vm
                                                          .deliveryStatusIcons[
                                                          row.deliveryStatus
                                                        ],
                                                        transform: "shrink-4",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                              "line-height": "48px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              -\n            "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "status",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "p-table-cell",
                                        attrs: {
                                          "popper-class": "default-popper",
                                          size: "large",
                                          placeholder: "Status",
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.markRowModified(row)
                                          },
                                        },
                                        model: {
                                          value: _vm.editItem.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editItem,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "editItem.status",
                                        },
                                      },
                                      _vm._l(
                                        _vm.statusOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                              disabled: item.disabled,
                                              hidden: item.disabled,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "p-table-cell",
                                        staticStyle: {
                                          "text-align": "left !important",
                                          "line-height": "48px",
                                        },
                                      },
                                      [
                                        _c(
                                          "font-awesome-layers",
                                          {
                                            staticClass: "fa-lg",
                                            staticStyle: {
                                              "margin-right": "10px",
                                            },
                                          },
                                          [
                                            _c("font-awesome-icon", {
                                              style:
                                                _vm.statusStyle[row.status],
                                              attrs: {
                                                icon: ["fas", "circle"],
                                                transform: "grow-6",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("font-awesome-icon", {
                                              style: { color: "white" },
                                              attrs: {
                                                icon: _vm.statusIcons[
                                                  row.status
                                                ],
                                                transform: "shrink-4",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.statusDescription[row.status]
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "virtualCheckInAt",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        "aria-label": "",
                                        type: "text",
                                        readonly: "true",
                                      },
                                      domProps: {
                                        value: row.virtualCheckInAt
                                          ? new Date(
                                              row.virtualCheckInAt
                                            ).format()
                                          : "-",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    !!row.virtualCheckInAt
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                              "line-height": "48px",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-tooltip",
                                              {
                                                attrs: {
                                                  effect: "dark",
                                                  content: row.virtualCheckInAt
                                                    ? new Date(
                                                        row.virtualCheckInAt
                                                      ).format()
                                                    : "-",
                                                  placement: "bottom",
                                                },
                                              },
                                              [
                                                _c(
                                                  "font-awesome-layers",
                                                  { staticClass: "fa-lg" },
                                                  [
                                                    _c("font-awesome-icon", {
                                                      attrs: {
                                                        icon: ["fas", "circle"],
                                                        transform: "grow-6",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("font-awesome-icon", {
                                                      style: { color: "black" },
                                                      attrs: {
                                                        icon: "check",
                                                        transform: "shrink-4",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass: "p-table-cell",
                                            staticStyle: {
                                              "text-align": "left !important",
                                              "line-height": "48px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n              -\n            "
                                            ),
                                          ]
                                        ),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    {
                      key: "isRegistered",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          {},
                          [
                            row.edit === true
                              ? _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        "aria-label": "",
                                        type: "text",
                                        readonly: "true",
                                      },
                                      domProps: {
                                        value:
                                          row.member.isRegistered === true
                                            ? "Yes"
                                            : "No",
                                      },
                                    }),
                                  ]
                                )
                              : _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: "component-fade-fast",
                                      mode: "out-in",
                                      appear: "",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            row.member.isRegistered
                                              ? "Yes"
                                              : "No"
                                          ) +
                                          "\n            "
                                      ),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      },
                    },
                    _vm._l(_vm.memberCustomFieldMapArray, function (ref) {
                      var customFslug = ref[0]
                      var cField = ref[1]
                      return {
                        key: customFslug,
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              { key: customFslug },
                              [
                                row.edit === true
                                  ? _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "component-fade-fast",
                                          mode: "out-in",
                                          appear: "",
                                        },
                                      },
                                      [
                                        cField.disabled
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.member
                                                        .memberCustomFieldDataMap[
                                                        customFslug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          : cField.type === "OPEN_ANSWER"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editItem
                                                      .memberCustomFieldDataMap[
                                                      customFslug
                                                    ],
                                                  expression:
                                                    "editItem.memberCustomFieldDataMap[customFslug]",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                "aria-label": "",
                                                type: "text",
                                                placeholder:
                                                  cField.options.question,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.editItem
                                                    .memberCustomFieldDataMap[
                                                    customFslug
                                                  ],
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editItem
                                                        .memberCustomFieldDataMap,
                                                      customFslug,
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.markRowModified(
                                                      row,
                                                      {
                                                        updateCustomFields: true,
                                                      }
                                                    )
                                                  },
                                                ],
                                              },
                                            })
                                          : cField.type === "MULTIPLE_CHOICE"
                                          ? _c(
                                              "el-select",
                                              {
                                                staticClass: "p-table-cell",
                                                attrs: {
                                                  "popper-class":
                                                    "default-popper",
                                                  "aria-autocomplete":
                                                    "password",
                                                  size: "large",
                                                  multiple: "",
                                                  "collapse-tags": "",
                                                  filterable: "",
                                                  "multiple-limit":
                                                    cField.options.maxSelection,
                                                  placeholder:
                                                    cField.options.question,
                                                },
                                                on: {
                                                  change: function (value) {
                                                    return _vm.onUpdateMultipleChoiceMember(
                                                      row,
                                                      _vm.slug,
                                                      value
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editItem
                                                      .memberAnswerList[
                                                      customFslug
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editItem
                                                        .memberAnswerList,
                                                      customFslug,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editItem.memberAnswerList[customFslug]",
                                                },
                                              },
                                              _vm._l(
                                                Object.entries(
                                                  cField.options.answers
                                                ),
                                                function (ref) {
                                                  var answerSlug = ref[0]
                                                  var answer = ref[1]
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: answerSlug,
                                                      attrs: {
                                                        hidden:
                                                          answer.disabled ||
                                                          (answer.maxLimit >
                                                            0 &&
                                                            answer.limit >=
                                                              answer.maxLimit),
                                                        label: answer.label,
                                                        value: answerSlug,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(answer.label) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : cField.type === "TIME_SLOT"
                                          ? _c(
                                              "el-select",
                                              {
                                                staticClass: "p-table-cell",
                                                attrs: {
                                                  "popper-class":
                                                    "default-popper",
                                                  clearable: "",
                                                  "aria-autocomplete":
                                                    "password",
                                                  size: "large",
                                                  placeholder:
                                                    cField.options.question,
                                                },
                                                on: {
                                                  change: function (value) {
                                                    return _vm.onUpdateTimeSlotMember(
                                                      row,
                                                      _vm.slug,
                                                      value
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editItem
                                                      .memberAnswerList[
                                                      customFslug
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editItem
                                                        .memberAnswerList,
                                                      customFslug,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editItem.memberAnswerList[customFslug]",
                                                },
                                              },
                                              _vm._l(
                                                Object.entries(
                                                  cField.options.answers
                                                ),
                                                function (ref) {
                                                  var answerSlug = ref[0]
                                                  var answer = ref[1]
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: answerSlug,
                                                      attrs: {
                                                        hidden:
                                                          answer.disabled ||
                                                          (answer.maxLimit >
                                                            0 &&
                                                            answer.limit >=
                                                              answer.maxLimit),
                                                        label: answer.label,
                                                        value: answerSlug,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(answer.label) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.member
                                                        .memberCustomFieldDataMap[
                                                        customFslug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "component-fade-fast",
                                          mode: "out-in",
                                          appear: "",
                                        },
                                      },
                                      [
                                        _vm
                                          .customFieldAnswerToString(
                                            row.member.memberCustomFieldDataMap[
                                              customFslug
                                            ]
                                          )
                                          .indexOf("://") === -1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.member
                                                        .memberCustomFieldDataMap[
                                                        customFslug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          : _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  "<a href='" +
                                                    _vm.customFieldAnswerToString(
                                                      row.member
                                                        .memberCustomFieldDataMap[
                                                        customFslug
                                                      ]
                                                    ) +
                                                    "'><i class='fa fa-download' aria-hidden='true'></i></a>"
                                                ),
                                              },
                                            }),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      }
                    }),
                    _vm._l(_vm.customFieldArray, function (ref) {
                      var slug = ref[0]
                      var cField = ref[1]
                      return {
                        key: slug,
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "span",
                              { key: slug },
                              [
                                row.edit === true
                                  ? _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "component-fade-fast",
                                          mode: "out-in",
                                          appear: "",
                                        },
                                      },
                                      [
                                        cField.disabled
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.customFieldDataMap[
                                                        slug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          : cField.type === "OPEN_ANSWER"
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.editItem
                                                      .customFieldDataMap[slug],
                                                  expression:
                                                    "editItem.customFieldDataMap[slug]",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                "aria-label": "",
                                                type: "text",
                                                placeholder:
                                                  cField.options.question,
                                              },
                                              domProps: {
                                                value:
                                                  _vm.editItem
                                                    .customFieldDataMap[slug],
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.editItem
                                                        .customFieldDataMap,
                                                      slug,
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.markRowModified(
                                                      row,
                                                      {
                                                        updateCustomFields: true,
                                                      }
                                                    )
                                                  },
                                                ],
                                              },
                                            })
                                          : cField.type === "MULTIPLE_CHOICE"
                                          ? _c(
                                              "el-select",
                                              {
                                                staticClass: "p-table-cell",
                                                attrs: {
                                                  "popper-class":
                                                    "default-popper",
                                                  "aria-autocomplete":
                                                    "password",
                                                  size: "large",
                                                  multiple: "",
                                                  "collapse-tags": "",
                                                  filterable: "",
                                                  "multiple-limit":
                                                    cField.options.maxSelection,
                                                  placeholder:
                                                    cField.options.question,
                                                },
                                                on: {
                                                  change: function (value) {
                                                    return _vm.onUpdateMultipleChoice(
                                                      row,
                                                      slug,
                                                      value
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editItem.answerList[
                                                      slug
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editItem.answerList,
                                                      slug,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editItem.answerList[slug]",
                                                },
                                              },
                                              _vm._l(
                                                Object.entries(
                                                  cField.options.answers
                                                ),
                                                function (ref) {
                                                  var answerSlug = ref[0]
                                                  var answer = ref[1]
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: answerSlug,
                                                      attrs: {
                                                        hidden:
                                                          answer.disabled ||
                                                          (answer.maxLimit >
                                                            0 &&
                                                            answer.limit >=
                                                              answer.maxLimit),
                                                        label: answer.label,
                                                        value: answerSlug,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(answer.label) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : cField.type === "TIME_SLOT"
                                          ? _c(
                                              "el-select",
                                              {
                                                staticClass: "p-table-cell",
                                                attrs: {
                                                  "popper-class":
                                                    "default-popper",
                                                  clearable: "",
                                                  "aria-autocomplete":
                                                    "password",
                                                  size: "large",
                                                  placeholder:
                                                    cField.options.question,
                                                },
                                                on: {
                                                  change: function (value) {
                                                    return _vm.onUpdateTimeSlot(
                                                      row,
                                                      slug,
                                                      value
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value:
                                                    _vm.editItem.answerList[
                                                      slug
                                                    ],
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editItem.answerList,
                                                      slug,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editItem.answerList[slug]",
                                                },
                                              },
                                              _vm._l(
                                                Object.entries(
                                                  cField.options.answers
                                                ),
                                                function (ref) {
                                                  var answerSlug = ref[0]
                                                  var answer = ref[1]
                                                  return _c(
                                                    "el-option",
                                                    {
                                                      key: answerSlug,
                                                      attrs: {
                                                        hidden:
                                                          answer.disabled ||
                                                          (answer.maxLimit >
                                                            0 &&
                                                            answer.limit >=
                                                              answer.maxLimit),
                                                        label: answer.label,
                                                        value: answerSlug,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(answer.label) +
                                                          "\n                "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.customFieldDataMap[
                                                        slug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "transition",
                                      {
                                        attrs: {
                                          name: "component-fade-fast",
                                          mode: "out-in",
                                          appear: "",
                                        },
                                      },
                                      [
                                        _vm
                                          .customFieldAnswerToString(
                                            row.customFieldDataMap[slug]
                                          )
                                          .indexOf("://") === -1
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.customFieldAnswerToString(
                                                      row.customFieldDataMap[
                                                        slug
                                                      ]
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ])
                                          : _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  "<a href='" +
                                                    _vm.customFieldAnswerToString(
                                                      row.customFieldDataMap[
                                                        slug
                                                      ]
                                                    ) +
                                                    "'><i class='fa fa-download' aria-hidden='true'></i></a>"
                                                ),
                                              },
                                            }),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ]
                        },
                      }
                    }),
                    {
                      key: "Actions",
                      fn: function (ref) {
                        var row = ref.row
                        return _c(
                          "span",
                          { staticStyle: { "text-align": "center" } },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "action_link",
                                staticStyle: { "margin-right": "5px" },
                                on: {
                                  click: function () {
                                    return row.edit === false
                                      ? _vm.openRow(row)
                                      : _vm.saveRow(row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  class:
                                    row.edit === false
                                      ? "fa fa-pencil-square-o"
                                      : "fa fa-check",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "action_link",
                                staticStyle: { "margin-right": "5px" },
                                on: {
                                  click: function () {
                                    return row.edit === false
                                      ? _vm.deleteRow(row)
                                      : _vm.closeRow(row)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  class:
                                    row.edit === false
                                      ? "fa fa-trash"
                                      : "fa fa-times",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            row.edit === false &&
                            _vm.badgePrintingEnabled === true
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "action_link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadBadge(row)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-id-badge" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.edit === false &&
                            _vm.badgePrintingEnabled === true
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "action_link",
                                    on: {
                                      click: function ($event) {
                                        return _vm.printBadge(row)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-print" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            row.edit === false
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "action_link",
                                    staticStyle: { "margin-right": "5px" },
                                    on: {
                                      click: function () {
                                        return _vm.checkRow(row)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-check-square-o",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }