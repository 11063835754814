var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _vm.loadingEffectshow
            ? _c("LoadingCover", {
                attrs: { "loading-cover-msg": _vm.loadingMessage },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "component-fade-fast", mode: "out-in", appear: "" } },
        [
          _c(
            "div",
            [
              _c("h4", { staticClass: "form-section-title first" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.string.organizerSettings._recap_unsubscribers) +
                    "\n            "
                ),
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.string.organizerSettings._recapSubtitle_unsubscribers
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "smallseparator" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "row guestList_links data",
                  staticStyle: { padding: "10px 0px" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "col-sm-12 col-md-12 col-lg-12",
                      staticStyle: { padding: "0" },
                    },
                    [
                      _c("div", { staticClass: "downloads_style" }, [
                        _c("i", {
                          staticClass: "fa fa-users",
                          staticStyle: { "margin-right": "15px" },
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.string.organizerSettings._unsubscriber_number
                            )
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.unsubscribers ? _vm.unsubscribers.length : 0
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "downloads_style" },
                        [
                          _c("i", {
                            staticClass: "fa fa-cloud-download",
                            staticStyle: { "margin-right": "15px" },
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" "),
                          _c(
                            "vue-excel-xlsx",
                            {
                              staticClass: "linkStyle",
                              attrs: {
                                data: _vm.unsubscribers,
                                columns: _vm.unsubscriberReportColumns,
                                filename: "unsubscriber_list_report",
                                sheetname: "Sheet 1",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.string.organizerSettings
                                      ._download_unsubscribers
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  [
                    _c("div", { staticClass: "refresh-stats-button" }, [
                      _c(
                        "a",
                        {
                          staticClass: "settings",
                          on: {
                            click: function (e) {
                              e.preventDefault()
                              _vm.handleUpdateUnsubscribers()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-refresh",
                            class: _vm.updatingUnsubscribers
                              ? "rotate-center"
                              : "",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.string.organizerSettings._refreshIdentities
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ]),
                  ],
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: {
                    name: "component-fade-fast",
                    mode: "out-in",
                    appear: "",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      class: !!_vm.updatingUnsubscribers
                        ? "datatable no-data"
                        : "",
                    },
                    [
                      _vm.updatingUnsubscribers
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-grid-container-status-message",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.organizerSettings
                                        ._updatingIdentities
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.updatingUnsubscribers
                        ? [
                            _vm.unsubscribers.length > 0
                              ? _c("DataGridReport", {
                                  attrs: {
                                    data: _vm.unsubscribers,
                                    columns: _vm.unsubscriberColumns,
                                  },
                                })
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "data-grid-container-status-message",
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.string.organizerSettings._no_data
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                ),
                          ]
                        : !_vm.updatingUnsubscribers
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "data-grid-container-status-message",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.string.organizerSettings._no_data
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }