import store from '@/store';
import NotFoundPage from '@/views/404';
import UserNotFound from '@/views/SSO404';
import Archive from '@/views/Archive';
import EmailVerificationFailure from '@/views/EmailVerificationFailure';
import EmailVerificationSuccess from '@/views/EmailVerificationSuccess';
import Events from '@/views/Events';
import ForgotPassword from '@/views/ForgotPassword';
import Home from '@/views/Home';
import JoinEvent from '@/views/JoinEvent';
import LoginPage from '@/views/LoginPage';
import NewPassword from '@/views/NewPassword';
import SSOCallback from '@/views/SSOCallback';
import AccountVerification from '@/views/AccountVerification';
import NewPrivateEvent from '@/views/NewPrivateEvent';
import NewPublicEvent from '@/views/NewPublicEvent';
import Notify from '@/views/Notify';
import OrganizerSettings from '@/views/OrganizerSettings';
import PrivacyPolicies from '@/views/PrivacyPolicies';
import RegisterPage from '@/views/RegisterPage';
import Search from '@/views/Search';
import SingleEvent from '@/views/SingleEvent';
import TeamJoin from '@/views/team/TeamJoin';
import TeamMembers from '@/views/team/TeamMembers';
import Recordings from '@/views/Recordings';
import UnsubscribePage from '@/views/UnsubscribePage';
import VirtualRoom from '@/views/VirtualRoom';
import EventGroups from '@/views/EventGroups';
import OrganizerPublicProfile from '@/views/OrganizerPublicProfile';
import ShowEmail from '@/views/ShowEmail';
import OAuthRedirect from '@/views/oauth';
import Vue from 'vue';
import Meta from 'vue-meta';
import Router from 'vue-router';

//helpdesk
import HelpDesk from '@/views/helpdesk/index';
import HelpDeskEN from '@/views/helpdesk/indexEN';
import HelpDeskHomeIT from '@/views/helpdesk/home';
import HelpDeskHomeEN from '@/views/helpdesk/homeEN';
import HelpDeskQuickstartIT from '@/views/helpdesk/quickstart';
import HelpDeskQuickstartEN from '@/views/helpdesk/quickstartEN';
import HelpDeskEventiIT from '@/views/helpdesk/eventi';
import HelpDeskEventiEN from '@/views/helpdesk/eventiEN';
import HelpDeskFAQIT from '@/views/helpdesk/faq';
import HelpDeskFAQEN from '@/views/helpdesk/faqEN';
import HelpDeskVirtualroomIT from '@/views/helpdesk/virtualroom';
import HelpDeskVirtualroomEN from '@/views/helpdesk/virtualroomEN';
import HelpDeskListeInvitatiIT from '@/views/helpdesk/listeinvitati';
import HelpDeskListeInvitatiEN from '@/views/helpdesk/listeinvitatiEN';
import HelpDeskCampagneEmailIT from '@/views/helpdesk/campagneemail';
import HelpDeskCampagneEmailEN from '@/views/helpdesk/campagneemailEN';
import HelpDeskImpostazioniIT from '@/views/helpdesk/impostazioni';
import HelpDeskImpostazioniEN from '@/views/helpdesk/impostazioniEN';
import ManualsIT from '@/views/helpdesk/manuals';
import ManualsEN from '@/views/helpdesk/manualsEN';

Vue.use(Router);
Vue.use(Meta, {
    // optional pluginOptions
    refreshOnceOnNavigation: true
});
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: Home,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/events',
            name: 'EventsPage',
            component: Events,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/events/archive',
            name: 'ArchivesPage',
            component: Archive,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/events/create/public',
            name: 'NewPublicEventPage',
            component: NewPublicEvent,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/events/create/private',
            name: 'NewPrivateEventPage',
            component: NewPrivateEvent,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/events/search',
            name: 'SearchEventPage',
            component: Search,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/event/:eventId',
            name: 'EventDetailPage',
            component: SingleEvent,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            name: 'OrganizerSettingsPage',
            component: OrganizerSettings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/privacy-policies',
            name: 'PrivacyPolicyPage',
            component: PrivacyPolicies,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/sponsor',
            name: 'Sponsor',
            component: () => import("@/views/SponsorPage.vue")
        },
        {
            path: '/login',
            name: 'Login',
            component: LoginPage
        },
        {
            path: '/register',
            name: 'Register',
            component: RegisterPage
        },
        {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword
        },
        {
            path: '/sso/callback',
            name: 'SSOCallback',
            component: SSOCallback
        },
        {
            path: '/marketing/:code/:action',
            name: 'unsubscribePage',
            component: UnsubscribePage,
            props: true
        },
        {
            path: '/password-reset',
            name: 'newPassword',
            component: NewPassword,
            props: true
        },
        {
            path: '/verify-account',
            name: 'verifyAccount',
            component: AccountVerification,
            props: true
        },
        {
            path: '/verify-success',
            name: 'verifySuccess',
            component: EmailVerificationSuccess
        },
        {
            path: '/verify-failure',
            name: 'verifyFailure',
            component: EmailVerificationFailure
        },
        {
            path: '/join/:code/:action',
            name: 'JoinEvent',
            component: JoinEvent,
            props: true
        },
        {
            path: '/room/:code',
            name: 'VirtualRoom',
            component: VirtualRoom,
            props: true
        },
        {
            path: '/p/:code',
            name: 'OrganizerPublicProfile',
            component: OrganizerPublicProfile,
            props: true
        },
        {
            path: '/p/:code/:eventId',
            name: 'OrganizerPublicProfileSingleEvent',
            component: OrganizerPublicProfile,
            props: true
        },
        {
            path: '/p/:code/ticket/:trackingId',
            name: 'TicketPage',
            component: OrganizerPublicProfile,
            props: true
        },
        {
            path: '/recordings',
            name: 'Recordings',
            component: Recordings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/notify',
            name: 'Notify',
            component: Notify,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/event-groups',
            name: 'EventGroups',
            component: EventGroups,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/team/members',
            name: 'TeamMembers',
            component: TeamMembers,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/team/join/:code',
            name: 'TeamJoin',
            component: TeamJoin,
            props: true
        },
        {
            path: '/email/:code/:fileType',
            name: 'ShowEmail',
            component: ShowEmail,
            props: true
        },
        {
            path: '/helpdesk',
            name: 'HelpDesk',
            component: HelpDesk,
            meta: {
                requiresAuth: true
            },
            children: [{
                path: 'it-home',
                component: HelpDeskHomeIT
            },
            {
                path: 'it-quickstart',
                component: HelpDeskQuickstartIT
            },
            {
                path: 'it-eventi',
                component: HelpDeskEventiIT
            },
            {
                path: 'it-faq',
                component: HelpDeskFAQIT
            },
            {
                path: 'it-virtualroom',
                component: HelpDeskVirtualroomIT
            },
            {
                path: 'it-listeinvitati',
                component: HelpDeskListeInvitatiIT
            },
            {
                path: 'it-campagneemail',
                component: HelpDeskCampagneEmailIT
            },
            {
                path: 'it-impostazioni',
                component: HelpDeskImpostazioniIT
            },
            {
                path: 'it-manuali',
                component: ManualsIT
            },]
        },
        {
            path: '/helpdesk',
            name: 'HelpDeskEN',
            component: HelpDeskEN,
            meta: {
                requiresAuth: true
            },
            children: [{
                path: 'it-home',
                component: HelpDeskHomeIT
            },
            {
                path: 'it-quickstart',
                component: HelpDeskQuickstartIT
            },
            {
                path: 'it-eventi',
                component: HelpDeskEventiIT
            },
            {
                path: 'it-faq',
                component: HelpDeskFAQIT
            },
            {
                path: 'it-virtualroom',
                component: HelpDeskVirtualroomIT
            },
            {
                path: 'it-listeinvitati',
                component: HelpDeskListeInvitatiIT
            },
            {
                path: 'it-campagneemail',
                component: HelpDeskCampagneEmailIT
            },
            {
                path: 'it-impostazioni',
                component: HelpDeskImpostazioniIT
            },
            {
                path: 'it-manuali',
                component: ManualsIT
            },
            {
                path: 'en-home',
                component: HelpDeskHomeEN
            },
            {
                path: 'en-quickstart',
                component: HelpDeskQuickstartEN
            },
            {
                path: 'en-eventi',
                component: HelpDeskEventiEN
            },
            {
                path: 'en-faq',
                component: HelpDeskFAQEN
            },
            {
                path: 'en-virtualroom',
                component: HelpDeskVirtualroomEN
            },
            {
                path: 'en-listeinvitati',
                component: HelpDeskListeInvitatiEN
            },
            {
                path: 'en-campagneemail',
                component: HelpDeskCampagneEmailEN
            },
            {
                path: 'en-impostazioni',
                component: HelpDeskImpostazioniEN
            },
            {
                path: 'en-manuali',
                component: ManualsEN
            }
        ]
        },
        {
            path: '/oauthredirect',
            name: 'oauthredirect',
            component: OAuthRedirect
        },
        {
            path: '/user-not-found',
            name: 'UserNotFound',
            component: UserNotFound
        },
        {
            path: '*',
            name: '404',
            component: NotFoundPage
        }
    ]
});
const resetActivePath = () => {
    store.commit('setActivePath', '');
};
const updateActivePath = (to) => {
    if (to.path === '/' || to.path === '') {
      store.commit('setActivePath', 'home');
    } else if (to.path.startsWith('/settings')) {
      store.commit('setActivePath', 'settings');
    } else if (to.path === '/events') {
      store.commit('setActivePath', 'events');
    } else if (to.path.startsWith('/events/create')) {
      store.commit('setActivePath', 'newEvent');
    } else if (to.path === '/team/members') {
        store.commit('setActivePath', 'recordings');
    } else if (to.path === '/recordings') {
        store.commit('setActivePath', 'recordings');
    } else if (to.path === '/events/archive') {
      store.commit('setActivePath', 'archive');
    } else if (to.path === '/notify') {
        store.commit('setActivePath', 'notify');
    } else if (to.path === '/event-groups') {
        store.commit('setActivePath', 'eventGroup');
    }

};

router.beforeEach((to, from, next) => {
    resetActivePath();
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            if (store.getters.ability.can('access', 'dashboard')) {
                updateActivePath(to);
                next();
                return;
            }
            store.dispatch('notifications/standaloneError', "Your account type doesn’t have access to the dashboard");
        }

        next('/login');
    } else {
        updateActivePath(to);
        next();
    }

});


export default router;

