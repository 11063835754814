export default function fromMapToObjectLiteral(map = new Map()) {
  let outputObjectLiteral = {};
  try {
    outputObjectLiteral = Object.fromEntries(Array.from(map.entries()));
  } catch (error) {
    console.log(error.message);
    outputObjectLiteral = {};
  }
  return outputObjectLiteral;
}
