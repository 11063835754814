var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "site-footer" }, [
    _c("div", { staticClass: "text-center" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            "© " +
              new Date().getFullYear() +
              " All rights reserved. Penguinpass Srl P.IVA 08866100962 - Milan, Italy"
          ) +
          "\n    "
      ),
      _c("span", { staticClass: "privacypolicy" }, [
        _vm._v("\n       - "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: _vm.strings.termsAndConditions._privacypolicyURL,
              title: "privacy",
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.strings.sidebar._privacypolicy))])]
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "termsandcond" }, [
        _vm._v("\n       - "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: _vm.strings.termsAndConditions._linkTerms,
              title: "terms",
            },
          },
          [_c("span", [_vm._v(_vm._s(_vm.strings.sidebar._terms))])]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }