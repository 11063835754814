<template>
  <div>
    <!-- <h4 class="form-section-title first">{{string.organizerSettings._domainHeader}}</h4> -->
    <h4 class="form-section-title first">
      {{ string.organizerSettings._eventSettingsHeader }}
      <small>{{ string.organizerSettings._eventSettingsSubtitle }}</small>
    </h4>
    <div class="smallseparator"></div>
    <transition id="loader-section" name="component-fade-fast" mode="out-in" appear>
      <LoadingCover v-if="loadingEffectshow" :loading-cover-msg="loadingMessage" />
    </transition>
    <transition id="add-new-identity" name="component-fade-fast" mode="out-in" appear>
      <TeamCustomFieldsManager></TeamCustomFieldsManager>
    </transition>
  </div>
</template>
<style scoped>
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~font-awesome/css/font-awesome.min.css";
@import "../../assets/css/bootstrap-toggle.css";
@import "../../assets/css/customsidebar.css";
@import "../../assets/css/formstyle.css";
@import "../../assets/css/preloader.css";
@import "../../assets/less/style.css";
@import "../../assets/less/createevent.css";
@import "../../assets/less/settings.css";


#loadingCover {
  background-color: rgba(107, 104, 102, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 500;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
#loadingCover .loadingCoverMessage {
  width: auto;
  text-transform: uppercase;
  font-size: 30px;
}
a.settings {
  cursor: pointer;
  color: #f7a30c !important;
  font-size: 20px !important;
  text-decoration: none;
  background-color: transparent !important;
}
a.settings:hover {
  cursor: pointer;
  color: #f7a30c;
  font-size: 20px !important;
  text-decoration: underline;
}
</style>
<script>
import LoadingCover from "@/components/LoadingCover.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import TeamCustomFieldsManager from "@/components/settings/team_custom_fields_manager/TeamCustomFieldsManager.vue";

import {
  MY_TEAM,
  UPDATE_MY_TEAM
} from "@/graphql";

export default {
  components: {
    LoadingCover,
    LoadingIcon,
    TeamCustomFieldsManager
  },
  data() {
    return {
      name: "EventSettings",
      string: {},
      loadingEffectshow: false,
      loadingMessage: "",
      showMore: false,
      showMoreMailFrom: false
    };
  },
  created() {
    const context = this;
    this.string = this.$root.strings;
    this.$root.bus.addListener("setLanguage", () => {
      context.string = context.$root.strings;
    });
  },
  methods: {
    onOptionChanged: function(newValue, hideMessage) {
      const context = this;
      if (this.canUpdate()) {
        context.updatingOption = true;
        this.$apollo
          .mutate({
            mutation: UPDATE_MY_TEAM,
            variables: {
              updates: {
                identityOption: newValue || "DEFAULT"
              }
            }
          })
          .then(response => {
            if (response.errors) {
              console.log(response.errors);
              context.updatingOption = false;
              return;
            }
            if (response.data && response.data.updateMyTeam) {
              context.$store.commit("SET_TEAM_FIELD", { identityOption: newValue });
              if (!hideMessage) {
                context.$root.bus.emit("simpleToast", "Updated");
              }
              context.updatingOption = false;
            }
          })
          .catch(error => {
            console.log(error);
            context.updatingOption = false;
          });
      }
    },
    canUpdate: function() {
      return true;
    }
  }
};
</script>
